import React from 'react'
import { InfoRowsBlock, getAvailableTranslation } from '@aidsupply/components'
import { useTranslation } from 'react-i18next'

import { DEFAULT_LANGUAGE } from '../../../locales'
import { useMappedState } from '../../../hooks'
import { selectAllSystemCollections } from '../../../redux/selectors'

const FAQsTableCard = ({ className, data, initialData }) => {
  const { state } = data

  const system = useMappedState(selectAllSystemCollections)
  const organization = Object.keys(system.organizations).find(
    (key) => system.organizations[key].id === initialData.organization_id
  )
  const organizationName = system.organizations[organization].name
  const {
    i18n: { language: lng },
    t,
  } = useTranslation()

  const title = getAvailableTranslation(initialData.translations, DEFAULT_LANGUAGE, lng)

  return (
    <InfoRowsBlock
      className={className}
      data={[
        {
          question: {
            text: title,
            width: 'calc(100% - 36px)',
            hideBorder: true,
            padding: '0 0 0 10px',
          },
          state: {
            customElement: state,
            width: '36px',
            padding: '17px 10px',
          },
        },
        {
          organization: {
            iconProps: {
              name: 'warehouse',
            },
            text: organizationName,
            width: '100%',
            padding: '10px',
          },
        },
      ]}
    />
  )
}

export default FAQsTableCard
