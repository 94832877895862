import React from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Button } from '@aidsupply/components'

import { sidebarUpsert } from '../../../redux/actions'

const ChangeStatusButton = ({ className, disabled, type, status, sidebarFormId, style }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation('table')

  const onClick = () => {
    const dataToSend = {
      id: sidebarFormId,
      requestBody: { status },
      type,
    }
    dispatch(sidebarUpsert(dataToSend, navigate))
  }
  return (
    <Button
      style={style}
      variant="primary"
      className={className}
      onClick={onClick}
      margin="0 0 20px 0"
      disabled={disabled}
    >
      {t(status)}
    </Button>
  )
}

export default ChangeStatusButton
