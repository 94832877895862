import { PropTypes as T } from 'prop-types'
import React, { useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { withTheme } from 'styled-components'

import { Icon, TextPanel, Typography } from '@aidsupply/components'

import { StyledDropButton, StyledDropZone } from './styled'
// import { popupAlertShow } from '../../redux-saga/actions'

const DropZone = (props) => {
  const {
    className,
    currentFiles,
    fileType,
    fileTypeText,
    isSimpleButton,
    maxFiles,
    maxSize,
    setFiles,
    simpleButtonProps,
    theme: { color },
    fileGroup,
    noClick,
    onFileDrop,
    setIsDropZoneActive,
    isUploadInProgress,
    isMultipleUploadDisabled,
  } = props
  const { t } = useTranslation('forms')

  const getNewFiles = (newFiles) => {
    if (!setFiles) {
      return
    }
    setFiles(
      newFiles.map((file) =>
        Object.assign(file.file, {
          preview: URL.createObjectURL(file.file),
        })
      )
    )

    // dispatch(
    //   popupAlertShow({
    //     contentKey: 'only10Files',
    //     type: 'error',
    //     timeout: 10000,
    //     withCloseButton: true,
    //     iconName: 'statusDeleted',
    //   })
    // )
  }

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, acceptedFiles } =
    useDropzone({
      accept: fileType,
      noClick: noClick,
      onDropRejected: (fileRejections) => {
        if (!acceptedFiles.length && fileRejections.length > maxFiles) {
          const newFiles = fileRejections.slice(0, maxFiles)
          getNewFiles(newFiles)
        }

        const maxNewFiles = maxFiles - acceptedFiles.length
        if (acceptedFiles.length && fileRejections.length > maxNewFiles) {
          const newFiles = fileRejections.slice(0, maxNewFiles)
          getNewFiles(newFiles)
        }

        fileRejections.map((file) => {
          if (file.errors[0].code === 'file-too-large') {
            // dispatch(
            //   popupAlertShow({
            //     contentKey: 'file-too-large',
            //     contentParams: { fileName: file.file.name },
            //     type: 'error',
            //     timeout: 10000,
            //     withCloseButton: true,
            //     iconName: 'statusDeleted',
            //   })
            // )
          }
        })
      },
      onDrop: (acceptedFiles) => {
        if (!acceptedFiles?.length) {
          return
        }

        const maxNewFiles = maxFiles - (currentFiles?.length || 0)
        if (acceptedFiles.length > maxNewFiles) {
          acceptedFiles.splice(maxNewFiles)

          // dispatch(
          //   popupAlertShow({
          //     contentKey: 'only10Files',
          //     type: 'error',
          //     timeout: 10000,
          //     withCloseButton: true,
          //     iconName: 'statusDeleted',
          //   })
          // )
        }

        const newFiles = currentFiles
          ? acceptedFiles.reduce((prev, curr) => {
              if (currentFiles.findIndex((currFile) => currFile.name === curr.name) === -1) {
                return [...prev, curr]
              } else {
                return prev
              }
            }, [])
          : acceptedFiles

        if (onFileDrop) {
          onFileDrop(newFiles)
        }

        if (setFiles) {
          setFiles(
            newFiles.map((file) =>
              Object.assign(file, {
                preview: URL.createObjectURL(file),
              })
            )
          )
        }
      },
      maxFiles,
      maxSize,
    })

  useEffect(() => {
    if (setIsDropZoneActive) {
      setIsDropZoneActive(isDragActive)
    }
  }, [isDragActive])

  if (isSimpleButton) {
    return (
      <StyledDropButton
        {...simpleButtonProps}
        {...getRootProps({ isDragActive, isDragAccept, isDragReject })}
        maxSize={maxSize}
        className={className}
      >
        <input {...getInputProps()} id={fileGroup} />
      </StyledDropButton>
    )
  }

  const getFileTypeText = (fileType) => {
    if (!fileType) return ''

    const allowedExtensions = Object.values(fileType)
      .flat()
      .map((extension) => extension.slice(1).toUpperCase())

    return allowedExtensions.join(', ')
  }

  if (isUploadInProgress) {
    return (
      <>
        <div style={{ height: 16 }} />
        <TextPanel content={t('uploadInProgress')} />
      </>
    )
  }

  return isMultipleUploadDisabled ? (
    <Typography type="body2" textAlign="center" color={color.general.darker} text={t('oneFileAllowed')} />
  ) : (
    <StyledDropZone
      {...getRootProps({ isDragActive, isDragAccept, isDragReject })}
      maxSize={maxSize}
      className={className}
    >
      <input {...getInputProps()} id={fileGroup} />
      <Icon name="upload" fill={color.general.dark} wrapperWidth={20} wrapperHeight={20} />
      <Typography type="body1" textAlign="center" color={color.general.gray5}>
        {t('dropOrSelect')}
      </Typography>
      {(fileType || fileTypeText) && (
        <Typography
          type="body2"
          textAlign="center"
          color={color.general.gray3}
          text={fileTypeText || getFileTypeText(fileType)}
        />
      )}
    </StyledDropZone>
  )
}

export default withTheme(DropZone)

DropZone.propTypes = { noClick: T.bool }
DropZone.defaultTypes = { noClick: false }
