import React from 'react'

import {
  ADVERTISING_SELECT_TABLE_CONFIG,
  buildImagePath,
  IMAGEKIT_PARAMS_CONFIG,
} from '@aidsupply/components'
import { Color, Icon, Typography } from '@aidsupply/components'

import { getBackendValueFromEnumerations } from '../utils/forms'
import { getTableValueFromRowId, getThumbWithTextCell, headerRenderer } from '../utils/table'

export const MULTI_SELECT_TABLE_CONFIG = (type, lng) => {
  const config = {
    ...ADVERTISING_SELECT_TABLE_CONFIG,
    properties: {
      key: 'attributes',
      labelKey: [`translations.${lng}`, 'name'],
    },
    characteristics: {
      key: 'attributes',
      labelKey: [`translations.${lng}`, 'name'],
    },
    deliveryOptions: {
      key: 'attributes',
      labelKey: [`translations.label.${lng}`, 'name'],
    },
  }
  return config[type]
}

const getItemAttributeColumn = (lng) => ({
  key: 'translation',
  title: 'Name',
  dataKey: `translations.${lng}`,
  width: 0,
  flexGrow: 1,
  flexShrink: 0,
  cellRenderer: (tableProps) => {
    const icon = getBackendValueFromEnumerations({
      blockValuesKey: 'enumeration_id',
      valueToGetKey: 'extras.icon',
      ...tableProps,
      cellData: tableProps.rowData.extras?.icon,
    })
    const iconImage = getBackendValueFromEnumerations({
      blockValuesKey: 'enumeration_id',
      valueToGetKey: 'extras.icon_image',
      ...tableProps,
      cellData: tableProps.rowData.extras.icon_image,
    })
    const color = getBackendValueFromEnumerations({
      blockValuesKey: 'enumeration_id',
      valueToGetKey: 'extras.hex_color',
      ...tableProps,
      cellData: tableProps.rowData.extras.hex_color,
    })
    const translation = getBackendValueFromEnumerations({
      blockValuesKey: 'enumeration_id',
      valueToGetKey: [`translations.${lng}`, 'name'],
      ...tableProps,
    })

    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {color && <Color color={color} size={20} style={{ marginRight: 10 }} />}
        {(icon || iconImage) && (
          <Icon
            name={icon?.id || icon}
            style={{ marginRight: 10 }}
            height={20}
            width={20}
            borderRadius="50%"
            backgroundImage={iconImage}
            backgroundSize="120%"
            imagekitParams={IMAGEKIT_PARAMS_CONFIG.crm.icon.texture}
          />
        )}
        <Typography type="body2">{translation}</Typography>
      </div>
    )
  },
})

export const getSelectImagesColumns = (lng, t) => ({
  banners: [
    {
      key: 'linkToPage',
      dataKey: 'page',
      labelKey: 'url',
      noTranslation: true,
      optionsData: ['pages'],
      width: 0,
      flexGrow: 1.5,
      isLink: true,
      headerRenderer: headerRenderer(t),
      cellRenderer: ({ cellData, rowData, column, container }) => {
        const text = getTableValueFromRowId('banners')({
          cellData,
          rowData,
          column,
          container,
          returnTextOnly: true,
        })

        return getThumbWithTextCell(
          'banners',
          MULTI_SELECT_TABLE_CONFIG('banners', lng).srcKey,
          lng,
          text
        )({ rowData, column, container })
      },
    },
    {
      key: 'title',
      dataKey: `seo.title.${lng}`,
      width: 0,
      flexGrow: 1,
      headerRenderer: headerRenderer(t),
      cellRenderer: getTableValueFromRowId('banners'),
    },
  ],
  categories: [
    {
      key: 'name',
      title: 'Name',
      dataKey: `label.${lng}`,
      width: 0,
      flexGrow: 1.5,
      headerRenderer: headerRenderer(t),
      cellRenderer: getThumbWithTextCell('categories', MULTI_SELECT_TABLE_CONFIG('categories', lng).srcKey),
    },
    {
      key: 'industry',
      title: 'Industry',
      dataKey: 'general.industry',
      width: 0,
      flexGrow: 1,
      headerRenderer: headerRenderer(t),
      cellRenderer: getTableValueFromRowId('categories'),
    },
  ],
  categoriesRecommended: [
    {
      key: 'name',
      title: 'Name',
      dataKey: `label.${lng}`,
      width: 0,
      flexGrow: 1.5,
      headerRenderer: headerRenderer(t),
      cellRenderer: getThumbWithTextCell('categories', MULTI_SELECT_TABLE_CONFIG('categories', lng).srcKey),
    },
    {
      key: 'industry',
      title: 'Industry',
      dataKey: 'general.industry',
      width: 0,
      flexGrow: 1,
      headerRenderer: headerRenderer(t),
      cellRenderer: getTableValueFromRowId('categories'),
    },
  ],
  bestsellers: [
    {
      key: 'model',
      dataKey: 'general.model',
      width: 0,
      flexGrow: 1.7,
      headerRenderer: headerRenderer(t),
      cellRenderer: getThumbWithTextCell('items', MULTI_SELECT_TABLE_CONFIG('bestsellers', lng).srcKey, lng),
    },
    {
      key: 'brand',
      dataKey: 'general.brand.name',
      width: 0,
      flexGrow: 1,
      headerRenderer: headerRenderer(t),
      cellRenderer: getTableValueFromRowId('items'),
    },
    {
      key: 'category',
      title: 'Category',
      dataKey: 'general.category',
      width: 0,
      flexGrow: 1,
      headerRenderer: headerRenderer(t),
      cellRenderer: getTableValueFromRowId('items'),
    },
  ],
  featuredProducts: [
    {
      key: 'model',
      dataKey: 'general.model',
      width: 0,
      flexGrow: 1.7,
      headerRenderer: headerRenderer(t),
      cellRenderer: getThumbWithTextCell(
        'items',
        MULTI_SELECT_TABLE_CONFIG('featuredProducts', lng).srcKey,
        lng
      ),
    },

    {
      key: 'brand',
      dataKey: 'general.brand.name',
      width: 0,
      flexGrow: 1,
      headerRenderer: headerRenderer(t),
      cellRenderer: getTableValueFromRowId('items'),
    },
    {
      key: 'category',
      title: 'Category',
      dataKey: 'general.category',
      width: 0,
      flexGrow: 1,
      headerRenderer: headerRenderer(t),
      cellRenderer: getTableValueFromRowId('items'),
    },
  ],
  brands: [
    {
      key: 'name',
      title: 'Name',
      dataKey: 'general.name',
      width: 0,
      flexGrow: 1,
      headerRenderer: headerRenderer(t),
      cellRenderer: getThumbWithTextCell('brands', MULTI_SELECT_TABLE_CONFIG('brands', lng).srcKey),
    },
    {
      key: 'industry',
      title: 'Industry',
      dataKey: 'general.industry',
      width: 0,
      flexGrow: 0.5,
      headerRenderer: headerRenderer(t),
      cellRenderer: getTableValueFromRowId('brands'),
    },
  ],
  characteristics: [getItemAttributeColumn(lng)],
  properties: [getItemAttributeColumn(lng)],
  deliveryOptions: [getItemAttributeColumn(lng)],
})
