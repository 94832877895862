import { Outlet } from 'react-router-dom'
import { withTheme } from 'styled-components'

import TableBlock from '../../../components/Table/TableBlock'
import GridLayout from '../../Layout/GridLayout'
import FAQsTableCard from './FAQsTableCard'

const FAQs = ({ theme }) => {
  return (
    <GridLayout RightPanelComponent={<Outlet context={{}} />}>
      <TableBlock isMainTable TableCardContent={FAQsTableCard} tableCardHeight={90} />
    </GridLayout>
  )
}

export default withTheme(FAQs)
