import React, { useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { PropTypes as T } from 'prop-types'
import Cookies from 'universal-cookie'
import { withTheme } from 'styled-components'

import { ScreenContext, Select } from '@aidsupply/components'

import { LANGUAGES_FULL_NAME } from '../../locales'
import { StyledSelect } from './styled'

const LanguageSelect = ({ theme, minWidth, iconLeftProps, isFullSize, isIntroScreensSelect }) => {
  const { width, sm, lg } = useContext(ScreenContext) || {}
  const isMobile = width && width < sm
  const isNearMobile = width && width < '640'

  const {
    i18n,
    i18n: {
      language: currentLng,
      options: { languages },
      t,
    },
  } = useTranslation()

  useEffect(() => {
    const cookies = new Cookies()
    const lngFromCookies = cookies.get('lng', currentLng, { path: '/' })
    if (!lngFromCookies) {
      cookies.set('lng', currentLng, { path: '/' })
    }
  }, [])

  const onChange = (lang) => {
    i18n.changeLanguage(lang.id)
    const cookies = new Cookies()
    cookies.set('lng', lang.id, { path: '/' })
  }

  const getLanguagesList = () => {
    return languages.map((lang) => ({
      id: lang,
      label: !isFullSize ? LANGUAGES_FULL_NAME[lang].slice(0, 3) : LANGUAGES_FULL_NAME[lang],
    }))
  }

  const value = {
    id: currentLng,
    label: !isFullSize ? LANGUAGES_FULL_NAME[currentLng].slice(0, 3) : LANGUAGES_FULL_NAME[currentLng],
  }

  if (isIntroScreensSelect) {
    return (
      <StyledSelect minWidth={minWidth} className={isFullSize && 'isFullSize'}>
        <Select
          iconLeftProps={!isMobile && isFullSize && { name: 'planet', fill: theme.color.general.dark }}
          onChange={onChange}
          options={getLanguagesList()}
          value={value}
          variant="primary"
          withBorder
          withoutValidation
          isSearchable={false}
        />
      </StyledSelect>
    )
  }

  return (
    <Select
      iconLeftProps={iconLeftProps}
      onChange={onChange}
      options={getLanguagesList()}
      value={value}
      variant="primary"
      withoutValidation
      fullWidth
    />
  )
}

export default withTheme(LanguageSelect)

LanguageSelect.propTypes = {
  theme: T.object,
  width: T.string,
}
