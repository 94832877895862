import React, { Fragment, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import {
  Button,
  EmptyBasket,
  ScreenContext,
  buildImagePath,
  IMAGEKIT_PARAMS_CONFIG,
  isObjectEmpty,
  MobileBasketItem,
  CategoryCharacteristicsChosen,
} from '@aidsupply/components'

import { FORM_CONFIGS } from '../../config/forms'
import styled, { withTheme } from 'styled-components'
import { INSTANCE_CREATE } from '../../redux/constants'
import { UNITS_PIECES_ID } from '../../data'
import { DEFAULT_VALUES_DATA } from '../../data/defaultValues'
import { updateBasket } from '../../redux/actions'
import { useMappedState } from '../../hooks'
import {
  selectAllSystemCollections,
  selectBasketItems,
  selectSidebarUpsertInProgress,
  selectUserDetails,
} from '../../redux/selectors'
import Form from '../../components/Form'
import { StyledItemImage } from '../../components/RightPanel/styled'
import { IMAGEKIT_URL } from '../../constants'

const StyledDemandsForm = styled(Form)`
  padding-bottom: 75px;
  margin-top: -5px;

  hr {
    height: 1px;
    width: 100%;
    border-width: 0;
    color: ${({ theme: { color } }) => color.general.gray2};
    background-color: ${({ theme: { color } }) => color.general.gray2};
    margin-bottom: 0;
  }
`

const DemandsRightPanel = ({ theme: { color, size } }) => {
  const dispatch = useDispatch()
  const { currentBreakpoint } = useContext(ScreenContext) || {}
  const {
    t,
    i18n: {
      language,
      options: { defaultLng },
    },
  } = useTranslation('productsForm')

  const formData = FORM_CONFIGS.inquiry_items

  const system = useMappedState(selectAllSystemCollections)
  const user = useMappedState(selectUserDetails)
  const basketItems = useMappedState(selectBasketItems)
  const inProgress = useMappedState(selectSidebarUpsertInProgress)

  if (!basketItems || !basketItems.length) {
    return <EmptyBasket t={t} iconName="emptyBasket" iconProps={{ fill: color.primary.main }} />
  }

  const onRemoveItem = (id) => () => {
    dispatch(updateBasket(basketItems.filter((item) => item.basketId !== id)))
  }

  const onChangeQuantity = (newItemId) => (value) => {
    const newItems = basketItems.map((item) =>
      item.basketId === newItemId ? { ...item, quantity: +value } : item
    )
    dispatch(updateBasket(newItems))
  }

  const onFormSubmit = (formValuesChanged) => {
    // console.log(formValuesChanged)
    const dataToSend = {
      requestBody: {
        recipient_id: formValuesChanged.recipient_id?.id || formValuesChanged['recipient_id.id'],
        comment: formValuesChanged.comment,
        inquiry_items: basketItems.map((item) => ({
          category_id: item.id,
          qty_new: item.quantity,
          characteristics_keys: Object.keys(item.characteristicsChosen || {}),
          characteristics_values: Object.values(item.characteristicsChosen || {}),
        })),
      },
      type: 'inquiries',
    }

    // console.log(dataToSend)
    dispatch({ type: INSTANCE_CREATE, data: dataToSend })
  }

  const getFormButtons = (isDraft) => {
    return (
      <div className="buttonsWrapper">
        <Button
          disabled={inProgress || !isDraft}
          type="submit"
          variant="primary"
          fullWidth
          text={t('submitOrder')}
        />
      </div>
    )
  }

  const getItemImage = (item) => {
    if (!item?.photoSrc) {
      return null
    }

    return (
      <StyledItemImage
        height={80}
        width={80}
        radius={size.border.radius.main}
        src={item.photoSrc}
        imagekitParams={IMAGEKIT_PARAMS_CONFIG.platform.checkout.thumbnail}
        imagekitUrl={IMAGEKIT_URL}
      />
    )
  }

  return (
    <StyledDemandsForm
      initialValuesChanged={{ recipient_id: user.organization, comment: '' }}
      initialValues={DEFAULT_VALUES_DATA.inquiry_items}
      currentBreakpoint={currentBreakpoint}
      formConfig={formData}
      optionsData={{ ...system, user }}
      validationRules={formData.validationRules}
      id="new"
      type="demands"
      labelType="top"
      labelKey="label"
      onSubmit={onFormSubmit}
      getFormButtons={getFormButtons}
      buttonsAreSticky
    >
      {basketItems.map((item) => {
        const unitFromSystem = system.units?.[item.unit_id || UNITS_PIECES_ID]

        return (
          <Fragment key={item.basketId}>
            <MobileBasketItem
              additionToTitle={
                unitFromSystem && `, ${unitFromSystem.translations?.[language] || unitFromSystem.code}`
              }
              item={{
                itemInfo: {
                  texts: { title: item.translations },
                  photoSrc: item.photo_url,
                },
              }}
              id={item.basketId}
              quantity={item.quantity}
              onChangeQuantity={onChangeQuantity}
              t={t}
              lng={language}
              onRemoveItem={onRemoveItem}
              getItemImage={getItemImage}
              defaultLng={defaultLng}
              inputVariant="secondary"
            >
              {!isObjectEmpty(item.characteristicsChosen) && (
                <CategoryCharacteristicsChosen
                  characteristicsChosen={item.characteristicsChosen}
                  className="inquiryItemsForm"
                  language={language}
                  system={system}
                  t={t}
                  unitsPiecesId={UNITS_PIECES_ID}
                />
              )}
            </MobileBasketItem>
          </Fragment>
        )
      })}
    </StyledDemandsForm>
  )
}

export default withTheme(DemandsRightPanel)
