import { select, put } from 'redux-saga/effects'
import Cookies from 'universal-cookie'

import { popupAlertShow } from './redux/actions'
import { API_METHODS, CRM_TYPES_WITH_POSTED_STATE, GET_CRM_API_URLS } from './apiConstants'
import { selectUserDetails, selectUserToken } from './redux/selectors'
import { doRefreshToken } from './redux/sagas/user'

/**
 * Constructs URL from given params
 * @param { string } type API type
 * @param { string } id Entity ID
 * @param { array } queryParams Url Params
 * @param { boolean } withFacets
 */
export const buildURL = ({
  type,
  id = '',
  queryParams = [],
  operation,
  withFacets,
  apiUrlParam,
  apiMethod,
}) => {
  const apiUrlsObjectOrString = GET_CRM_API_URLS(apiUrlParam)?.[type]

  const urlPath =
    typeof apiUrlsObjectOrString === 'string'
      ? apiUrlsObjectOrString
      : apiUrlsObjectOrString[operation || 'read']

  if (urlPath === undefined) {
    throw new Error(`No API present for given key: ${type}`)
  }

  let url = `${process.env.REACT_APP_API_URL}${urlPath}${
    withFacets ? (urlPath.endsWith('/') ? '' : '/') + 'facets' : ''
  }`

  if (id !== '') {
    url += `${urlPath.endsWith('/') ? '' : '/'}${id}`
  }

  if ((operation === 'read' || apiMethod === 'GET') && !withFacets && !id) {
    if (CRM_TYPES_WITH_POSTED_STATE.includes(type)) {
      queryParams.push({ key: 'state', value: 'posted' })
    }
  }

  if (queryParams.length) {
    const filters =
      queryParams
        .filter(
          (param) => !['sort', 'limit', 'skip', 'columns', 'searchValue', 'searchFields'].includes(param.key)
        )
        .map((val) => `${val.key}=${val.value}`) || []

    const getParamValue = (key) => queryParams.find((param) => param.key === key)?.value

    const searchFields = getParamValue('searchFields')
    const searchValueParsedToFilters = searchFields
      ? [`${searchFields}__like=${getParamValue('searchValue')}`]
      : []

    const filtersAll = filters.concat(searchValueParsedToFilters).join(',')

    const urlParamsForPostgresTable = {
      filters: filtersAll,
      sort: getParamValue('sort')?.replace('_asc', '=asc').replace('_desc', '=desc'),
      limit: getParamValue('limit'),
      skip: getParamValue('skip'),
    }
    const urlParamsForPostgresFacets = {
      filters: filtersAll,
      columns: getParamValue('columns'),
    }

    const urlParams = withFacets ? urlParamsForPostgresFacets : urlParamsForPostgresTable
    const urlParamsFiltered = Object.keys(urlParams).filter(
      (key) => !!urlParams[key] || urlParams[key] === 0 || !!urlParams[key]?.length
    )

    if (urlParamsFiltered.length) {
      url += `?${urlParamsFiltered.map((urlParam) => `${urlParam}=${urlParams[urlParam]}`).join('&')}`
    }
  }

  return url
}

/**
 * Verifies token validity and refreshes on expiration
 */
export function* verifyToken(tokenArg, exp) {
  const cookies = new Cookies()
  // verify validity of the token
  const access_token = yield select(selectUserToken)
  const details = yield select(selectUserDetails)
  const expFromCookies = cookies.get('tokenExp', { path: '/' })

  const expirationDate = exp || details?.exp || expFromCookies
  const tokenVerified = tokenArg || access_token

  const isTokenExpired = !tokenVerified || !expirationDate || (expirationDate && Date.now() > +expirationDate)

  if (isTokenExpired) {
    const newAccessToken = yield doRefreshToken()
    return newAccessToken
  }
  return tokenVerified
}
/**
 * Builds request object
 * @param { string } id entity id
 * @param { object } requestBody entity data
 * @param { string } operation desired CRUD operation
 * @param { string } type entity type
 * @param { array } queryParams Url Params
 *  @param { string } platform
 *  @param { boolean } withFacets
 * @returns { Request }
 */
export function* buildRequest({
  id,
  requestBody,
  operation,
  queryParams,
  apiUrlParam,
  apiMethod,
  token: tokenArg,
  exp: tokenExpArg,
  type,
  withFacets,
}) {
  let token
  if (type !== 'refreshToken' && type !== 'signIn' && type !== 'signUp') {
    token = yield verifyToken(tokenArg, tokenExpArg)
  }

  const isMultipartFormData = (type === 'filesUpload' && !id) || type === 'ordersParseFile'

  const headers = {
    Origin: window.location.origin,
  }

  if (token) {
    headers.Authorization = `Bearer ${token}`
  }

  if (!isMultipartFormData) {
    headers['Content-Type'] = 'application/json'
  }

  const options = {
    headers: new Headers(headers),
    method: apiMethod || API_METHODS[operation],
  }

  if (requestBody) {
    options['body'] = isMultipartFormData ? requestBody : JSON.stringify(requestBody)
  }

  return new Request(
    buildURL({ type, id, queryParams, operation, withFacets, apiUrlParam, apiMethod }),
    options
  )
}

export function* fetchData(request) {
  const response = yield fetch(request)

  return yield response.json()
}

export function* showError(response, withoutPopups) {
  if (!response || (response?.detail && Object.keys(response).length === 1)) {
    if (response?.detail) {
      const regEx = new RegExp(/^(Auth0:\d\d\d\s+[\s\S]*)$/)
      const errorText = response.detail?.message || response.detail //: response.detail?.[0]?.msg

      let currentError = typeof errorText === 'string' && errorText
      if (regEx.test(errorText)) {
        const authText = errorText.split(' ', 1)[0]
        currentError = errorText.replace(`${authText} `, '')
      }

      if (errorText === 'Missing bearer token') {
        // yield put(userLogout())
      }

      if (!withoutPopups) {
        yield put(
          popupAlertShow({
            contentKey: currentError || 'somethingWentWrong',
            type: 'error',
            timeout: 10000,
            // withCloseButton: true,
          })
        )
      }
    }
    throw new Error(response?.detail || 'Something went wrong')
  }
}
