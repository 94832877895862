import styled from 'styled-components'

export const StyledNotFound = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  @media only screen and (max-height: 700px) {
    svg {
      height: 400px;
    }
  }
`

export const StyledErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  padding: 15px;

  button {
    margin-bottom: 20px;
  }

  span {
    margin: 0 auto;
  }
`
