import React, { useContext, useEffect } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { ErrorBoundary, isObjectEmpty, ScreenContext, usePrevious } from '@aidsupply/components'

import { ENTITIES } from '../../config'
// import HeaderMobile from '../../components/HeaderMobile/HeaderMobile'
import { StyledMainWrapper } from './styled'
import NavMenu from '../../components/NavMenu/NavMenu'
import { cleanBasket, popupAlertHideAll, setDataType, toggleNavMenuExtended } from '../../redux/actions'
import { FILTERS_BACK_TO_INITIAL_STATE } from '../../redux/constants'
import HeaderMobile from '../../components/HeaderMobile'
import { isAccessAllowed } from '../../utils/user'
import { useMappedState } from '../../hooks'
import { selectUserDetails } from '../../redux/selectors'
import { useWebsockets } from '../../hooks/useWebsockets'

const getEntityByRoute = (pathname) => {
  const getEntity = (configObject) => {
    const array = Array.isArray(configObject) ? configObject : Object.values(configObject).flat()
    const parentEntity = array.find(
      (entity) => entity.route !== '/' && pathname.includes(entity.key.replace('_', '-'))
    )

    return parentEntity?.items
      ? parentEntity.items.find((entity) => pathname.includes(entity.route))
      : parentEntity
  }

  return pathname === '/' ? ENTITIES[0] : getEntity(ENTITIES)
}

const MainWrapper = () => {
  const dispatch = useDispatch()

  const { width, md, xl, height } = useContext(ScreenContext)
  const isTablet = width <= xl && width > md
  const isRevertView = width > height
  const isDesktop = width > xl

  const { pathname } = useLocation()
  const typeData = getEntityByRoute(pathname)
  const prevType = usePrevious(typeData?.key)

  const currMenuState = JSON.parse(localStorage.getItem('menuState'))
  const hasLocalStorageState = currMenuState?.isExtended !== undefined

  const user = useMappedState(selectUserDetails)
  const isForbidden =
    pathname !== '/' && !isObjectEmpty(typeData) && !isAccessAllowed(typeData.accessLevel, user?.role) //ROUTES_FORBIDDEN[role]?.some((path) => location.pathname.includes(path))

  useWebsockets()

  useEffect(() => {
    if (typeData && typeData.key !== prevType) {
      dispatch(popupAlertHideAll())
      dispatch(setDataType(typeData)) // initWs: !prevType && forceInitWs

      if (['products', 'inquiry_items'].includes(typeData.key)) {
        dispatch(cleanBasket())
      }

      dispatch({ type: FILTERS_BACK_TO_INITIAL_STATE })
    }
  }, [pathname])

  useEffect(() => {
    if (isDesktop && !hasLocalStorageState) {
      dispatch(toggleNavMenuExtended(isDesktop))
    } else if (hasLocalStorageState) {
      dispatch(toggleNavMenuExtended(currMenuState.isExtended))
    }
  }, [isDesktop])

  if (isForbidden) {
    return <Navigate replace to="/404" />
  }

  if (width <= xl) {
    return (
      <StyledMainWrapper>
        <ErrorBoundary>
          <HeaderMobile isTablet={isTablet} isRevert={isRevertView} />
        </ErrorBoundary>

        <Outlet />
      </StyledMainWrapper>
    )
  }

  return (
    <StyledMainWrapper>
      <ErrorBoundary>
        <NavMenu />
      </ErrorBoundary>

      <Outlet
        context={
          {
            // isRightPanelOpened,
            // setIsRightPanelOpened,
            // isLeftPanelOpened,
            // setIsLeftPanelOpened,
          }
        }
      />
    </StyledMainWrapper>
  )
}

export default MainWrapper
