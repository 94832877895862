import { Icon, Typography } from '@aidsupply/components'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { withTheme } from 'styled-components'
import FilePanel from '../../../components/FilePanel'
import UploadFiles from '../../../components/UploadFiles'
import { StyledContainerUpload, StyledFilePanel, StyledFilePanelHeader } from './styled'

const ItemsUploadRightPanel = () => {
  const { t } = useTranslation(['notifications', 'general'])
  const title = t('general:template')

  return (
    <StyledContainerUpload>
      <Typography type="h4">{t('general:fileUpload')}</Typography>
      <StyledFilePanel>
        <StyledFilePanelHeader>
          <Icon name="warning" className="icon" />
          <Typography type="body2" fontWeight={600}>
            {t('uploadItems')}
          </Typography>
        </StyledFilePanelHeader>
        <FilePanel
          extension="xls"
          title={title}
          href="https://docs.google.com/spreadsheets/d/1RWTJ9dMDoAs8ZsmMjWl-V6Il5ztxqEMJmDuJvHtkNHc/edit#gid=0"
        >
          <Icon name="download" className="iconDownload" width={25} height={20} />
        </FilePanel>
      </StyledFilePanel>
      <UploadFiles filesType="files" />
    </StyledContainerUpload>
  )
}

export default withTheme(ItemsUploadRightPanel)
