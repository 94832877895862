import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { withTheme } from 'styled-components'
// import ReactCrop, { centerCrop, makeAspectCrop, Crop, PixelCrop } from 'react-image-crop'
import { useTranslation } from 'react-i18next'

import { Userpic, isObjectEmpty } from '@aidsupply/components'
import { IMAGEKIT_URL } from '../../constants'
import { StyledProfilePicture } from '../../containers/UserProfile/styled'
import { useMappedState } from '../../hooks'
import { deletePhoto, uploadAvatar } from '../../redux/actions'
import { selectUserDetails } from '../../redux/selectors'
import UploadFiles from '../UploadFiles'
import { StyledIcon } from './styled'
// import { isObjectEmpty } from '../../utils/isObjectEmpty'
// import { getUploadedData, upsertSidebarItem } from '../../redux-saga/actions'

// const centerAspectCrop = (mediaWidth, mediaHeight, aspect) => {
//   return centerCrop(
//     makeAspectCrop(
//       {
//         unit: '%',
//         width: 90,
//       },
//       aspect,
//       mediaWidth,
//       mediaHeight
//     ),
//     mediaWidth,
//     mediaHeight
//   )
// }

const ProfilePicture = ({ theme }) => {
  const { t } = useTranslation('general')
  const dispatch = useDispatch()
  const location = useLocation()

  const user = useMappedState(selectUserDetails)

  const [avatar, setAvatar] = useState(user.profile_pic)

  useEffect(() => {
    setAvatar(user.profile_pic)
  }, [user.profile_pic])
  // const [crop, setCrop] = useState()
  // const [completedCrop, setCompletedCrop] = useState()
  // const [scale, setScale] = useState(1)
  // const [rotate, setRotate] = useState(0)
  // const [aspect, setAspect] = useState(1 / 1)
  // const imgRef = useRef(null)

  // function onImageLoad(e) {
  //   if (aspect) {
  //     const { width, height } = e.currentTarget
  //     setCrop(centerAspectCrop(width, height, aspect))
  //   }
  // }

  const fnSet = (newFile) => {
    if (!isObjectEmpty(newFile)) {
      dispatch(
        uploadAvatar(newFile[0], {
          entityId: user.id,
          entityType: 'users',
          fileType: 'image',
        })
      )
    }
  }

  const onRemoveAvatar = () => {
    dispatch(
      deletePhoto({
        photoId: user.profile_pic_id,
        entityType: 'users',
        primaryPhotoIdKey: 'logo_photo_id',
        entityId: user.id,
      })
    )
  }

  return (
    <StyledProfilePicture>
      {/* <ReactCrop
        crop={crop}
        onChange={(_, percentCrop) => setCrop(percentCrop)}
        onComplete={(c) => setCompletedCrop(c)}
        aspect={aspect}
      > */}
      <Userpic
        imagekitUrl={IMAGEKIT_URL}
        height="64px"
        width="64px"
        src={avatar}
        theme={theme}
        fullName={user.full_name}
      />
      {/* </ReactCrop> */}
      <div style={{ width: '100%' }}>
        <UploadFiles
          entityType="user"
          entityId={user.id}
          fileGroup="noGroup"
          onFnSet={fnSet}
          withImagePreview={false}
        />
      </div>
      <StyledIcon
        name="trashBin"
        wrapperHeight={40}
        wrapperWidth={40}
        onClick={onRemoveAvatar}
        borderRadius="6px"
      />
    </StyledProfilePicture>
  )
}

export default withTheme(ProfilePicture)
