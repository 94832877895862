import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Tabs } from '@aidsupply/components'

import { StyledOrderWrapper } from './styled'
import { StyledTabsWrapper } from '../RightPanelForms/styled'
import Comments from './Comments'

const OrderFormActivity = ({ updateInput, formValues, formValuesChanged }) => {
  const { t } = useTranslation('forms')

  const [activeIndex, setActiveIndex] = useState(0)

  return (
    <StyledOrderWrapper>
      <StyledTabsWrapper>
        {/*<Tabs*/}
        {/*  value={activeIndex}*/}
        {/*  onChange={(e, value) => {*/}
        {/*    setActiveIndex(value)*/}
        {/*  }}*/}
        {/*  indicatorColor="secondary"*/}
        {/*  textColor="secondary"*/}
        {/*  // variant="scrollable"*/}
        {/*  // scrollButtons="auto"*/}
        {/*>*/}
          {/*<Tab key="comments" label={t('comments')} />*/}
          {/*<Tab key="history" label={t('history')} />*/}
        {/*</Tabs>*/}
        <Comments
          updateInput={updateInput}
          // aria-labelledby={`scrollable-auto-tab-${0}`}
          hidden={activeIndex !== 0}
          // id={`scrollable-auto-tabpanel-${0}`}
          role="tabpanel"
          formValuesChanged={formValuesChanged}
        />
        {/*<Typography hidden={activeIndex !== 1} type="body2" text="In process of development" />*/}
      </StyledTabsWrapper>
    </StyledOrderWrapper>
  )
}

export default OrderFormActivity
