import React from 'react'
import { object, pick } from 'dot-object'
import { isObject } from '@aidsupply/components'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import EditForm from '../../../components/RightPanelForms'
import { sidebarUpsert } from '../../../redux/actions'
import {
  selectAllSystemCollections,
  selectSidebarInitialData,
  selectUserDetails,
} from '../../../redux/selectors'
import { useMappedState } from '../../../hooks'
import { FORM_CONFIGS } from '../../../config/forms'
import { withTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { getEditableTableColumns } from '../../../config/columnsEditableTable'
import { EDITABLE_TABLE_NAVBAR_FORM_CONFIG } from '../../../config/editableTableNavbarForms'
import { getColumns } from '../../../config/columns'
import CardTableItem from '@aidsupply/components/build/molecules/CardTableItem'
import ShipmentsTableCard from './ShipmentsTableCard'
import OrderFormActivity from '../../../components/OrderForm'
import EditableTableFormPart from '../../../components/RightPanelForms/EditableTableFormPart'
import ChangeStatusButton from '../../../components/RightPanelForms/components/ChangeStatusButton'

const FormBlockWrapper = withTheme(
  ({
    customBlockValues,
    optionsData,
    children,
    blockKey,
    fields,
    formFieldsProps,
    formValues,
    formValuesChanged,
    getPanelHeader,
    id,
    isReadOnly,
    setTextsChosenLng,
    theme,
    updateInput,
    updateSelect,
  }) => {
    const {
      t,
      i18n: { language },
    } = useTranslation(['table', 'forms', 'general'])
    const system = useMappedState(selectAllSystemCollections)
    const user = useMappedState(selectUserDetails)

    const editableTableColumns = getEditableTableColumns('shipments.shipment_items', language, t)

    const editableTableConfig = EDITABLE_TABLE_NAVBAR_FORM_CONFIG['shipments.shipment_items']

    const additionalFormValues = editableTableConfig?.optionsFromValuesDependencies?.reduce(
      (acc, curr) => ({ ...acc, [curr]: pick(curr, formValues) }),
      {}
    )

    const columns = getColumns('shipments', language, t)

    return (
      <>
        {children}
        {blockKey === 'noTitle' && (
          <>
            <CardTableItem
              data={formValues}
              CardContent={ShipmentsTableCard}
              cellProps={{
                system,
                custom_block_values: customBlockValues,
                lng: language,
                id,
                user,
                is_read_only: isReadOnly,
                // additional_form_values: additionalFormValues,
                container: { props: { cellProps: { system, lng: language } } },
              }}
              index={formValues.id}
              // style={}
              columns={columns}
              // margin={} rowHeight={}
              rowIndex={formValues.id}
              rowKey={formValues.id}
              t={t}
              theme={theme}
              type="shipments"
            />
            <ChangeStatusButton
              type="shipments"
              status="received"
              sidebarFormId={formValues.id}
              style={{ marginTop: '20px', width: '100%' }}
            />
          </>
        )}

        {blockKey === 'comments' && (
          <OrderFormActivity updateInput={updateInput} formValuesChanged={formValuesChanged} />
        )}

        {blockKey === 'shipment_items' && (
          <EditableTableFormPart
            additionalFormValues={additionalFormValues}
            id={id}
            cellProps={{
              system,
              custom_block_values: customBlockValues,
              lng: language,
              id,
              user,
              is_read_only: true,
              additional_form_values: additionalFormValues,
            }}
            compoundTypeKey="shipments.shipment_items"
            optionsData={optionsData}
            language={language}
            typeData={{ key: 'shipments.shipment_items' }}
            columns={editableTableColumns}
            data={formValues[blockKey]}
            blockKey={blockKey}
            formFieldsProps={formFieldsProps}
            fields={fields}
            getPanelHeader={getPanelHeader}
            updateTableRows={updateSelect.bind(null, blockKey)}
            setTextsChosenLng={setTextsChosenLng}
            validationRules={editableTableConfig?.validationRules}
            t={t}
          />
        )}
      </>
    )
  }
)

const ShipmentsRightPanel = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const sidebarInitialData = useMappedState(selectSidebarInitialData)

  const onFormSubmit = (formValuesChanged) => {
    const requestBody = { ...object(formValuesChanged) }

    Object.keys(requestBody).map((key) => {
      if (isObject(requestBody[key]) && requestBody[key].id) {
        requestBody[key] = requestBody[key].id
      }
    })

    if (formValuesChanged.inquiry_items?.length) {
      requestBody.inquiry_items = formValuesChanged.inquiry_items.map((item) => ({
        state: item.state,
        item_id: item.item_id?.id || item.item_id,
        modification_id: item.modification_id?.id || item.modification_id || undefined,
        order_item_id: item.order_item_id?.id || item.order_item_id,
        shipment_id: sidebarInitialData.id || undefined,
        quantity: item.quantity,
        id: item.id,
      }))
    }

    const dataToSend = {
      id: sidebarInitialData.id,
      requestBody,
      type: 'shipments',
      parentType: 'documents',
    }

    dispatch(sidebarUpsert(dataToSend, navigate))
  }

  return (
    <EditForm
      FormBlockWrapper={FormBlockWrapper}
      formConfig={
        sidebarInitialData?.state === 'posted' ? FORM_CONFIGS.shipmentsPosted : FORM_CONFIGS.shipments
      }
      onFormSubmit={onFormSubmit}
    />
  )
}

export default ShipmentsRightPanel
