import React from 'react'
import merge from 'deepmerge'
import { useNavigate } from 'react-router-dom'
import { object } from 'dot-object'
import { useDispatch } from 'react-redux'
import { isObject } from '@aidsupply/components'

import { useMappedState } from '../../../hooks'
import { selectSidebarInitialData } from '../../../redux/selectors'
import { sidebarUpsert } from '../../../redux/actions'
import EditForm from '../../../components/RightPanelForms'
import { slugify } from 'transliteration'

const CategoriesRightPanel = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const categoryInitial = useMappedState(selectSidebarInitialData)

  const isReadOnly = false

  const onFormSubmit = (formValuesChanged) => {
    const requestBody = { ...object(formValuesChanged) }

    Object.keys(requestBody).map((key) => {
      if (isObject(requestBody[key]) && requestBody[key].id) {
        requestBody[key] = requestBody[key].id
      }
    })

    if (requestBody.translations)
      requestBody.translations = merge(categoryInitial.translations, requestBody.translations)

    if (formValuesChanged.name) {
      requestBody.slug = slugify(formValuesChanged.name)
    }

    const dataToSend = {
      id: categoryInitial.id,
      requestBody,
      type: 'categories',
      parentType: 'system',
    }

    dispatch(sidebarUpsert(dataToSend, navigate))
  }

  return <EditForm isReadOnly={isReadOnly} onFormSubmit={onFormSubmit} />
}

export default CategoriesRightPanel
