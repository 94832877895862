import clsx from 'clsx'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { Checkbox, DatePicker } from '@aidsupply/components'
import FormInput from './FormInput'
import FormSelect from './FormSelect'
import { StyledInputWrapper, StyledLabel } from './styled'

const FormField = ({
  field,
  formError,
  formErrors,
  formFlexDirection,
  formSubmit,
  formValue,
  initialValue,
  isDisabled,
  isHidden,
  isSubmitOnBlur,
  // isTranslation,
  labelKey,
  labelType,
  name,
  optionsData,
  optionsFromFormValues,
  sidebarFormId,
  submitByEnterPressed,
  updateCheckbox,
  updateInput,
  updateSelect,
  validateField,
  validationRules,
  withTabs,
}) => {
  const { t, i18n } = useTranslation(['forms', 'table', 'validation'])
  const lng = i18n.language

  const isSelect = field.component === 'dropdown'

  const disabled = field.disabled || field.isDisabled || isDisabled

  const valueNormalized = typeof formValue === 'number' ? formValue.toString(10) : formValue
  const value =
    (field.transformValue ? field.transformValue(valueNormalized, lng) : valueNormalized) ||
    field.defaultValue ||
    ''

  const labelTranslation =
    field.label && (i18n.exists(field.label, { ns: 'forms' }) ? t(field.label) : t(`table:${field.label}`))

  useEffect(() => {
    // TODO: fix for units in item content and stock items warehouse_id
    if (
      field.defaultValue &&
      (formValue?.id !== field.defaultValue?.id || formValue !== field.defaultValue)
    ) {
      // console.log(defaultValue, formValue)
      if (isSelect) {
        updateSelect(field.key)(field.defaultValue)
      } else {
        updateInput({ target: { name: field.key, value: field.defaultValue } })
      }
    }
  }, [])

  const commonSelectAndInputProps = {
    className: clsx('formInput', isHidden && 'isHidden'),
    type: field.type,
    name,
    field,
    formSubmit,
    isSubmitOnBlur,
    // onBlur: onValidateField(field.key),
    variant: field.variant,
    error:
      (formError && typeof formError === 'string' && formError !== 'success'
        ? t(
            `validation:${formError}`,
            field.validationTranslationKeys
              ? {
                  fields: field.validationTranslationKeys.reduce(
                    (acc, curr, i, arr) => `${acc}${t(curr)}${i < arr.length - 1 ? ', ' : ''}`,
                    ''
                  ),
                }
              : {}
          )
        : undefined) || field.error,
    success: formError === 'success',
    placeholder:
      field.placeholder &&
      (i18n.exists(field.placeholder, { ns: 'forms' })
        ? t(field.placeholder)
        : t(field.placeholder, { ns: 'table', nsSeparator: '|' })), //   default namespace separator is ":" which is not suitable for web site placeholder
    required: withTabs
      ? field.required && validationRules[name]?.includes('required')
      : field.required || false,
    initialValue: initialValue || field.initialValue || '',
    labelType: field.labelType || labelType,
    labelWidth: '100px',
    label: labelTranslation,
    t,
    withBorder: true,
    primaryFocusColor: true,
    fullWidth: true,
    isDisabled: disabled,
    isHidden,
    value,
    validateField,
  }

  if (typeof field.component === 'function') {
    return (
      <div className={clsx(formFlexDirection, field.className, isHidden && 'isHidden')}>
        {labelTranslation && (
          <StyledLabel
            labelFontWeight={field.labelFontWeight}
            text={labelTranslation}
            variant={field.variant || 'primary'}
          />
        )}
        {field.component({
          ...commonSelectAndInputProps,
          errors: formErrors,
          formSubmit,
          isSubmitOnBlur,
          lng,
          optionsData,
          sidebarFormId,
          labelKey,
          optionsFromFormValues,
          updateSelect,
          updateInput,
        })}
      </div>
    )
  } else if (field.component === 'checkbox') {
    return (
      <Checkbox
        {...field}
        disabled={disabled}
        name={name}
        value={field.value}
        checked={formValue}
        variant={field.variant || 'primary'}
        label={labelTranslation}
        handleChange={field.onChange || updateCheckbox}
        labelType={field.labelType || 'body2'}
        className="formCheckbox"
      />
    )
  } else if (field.component === 'date') {
    return (
      <DatePicker
        onChange={(date) => {
          updateInput({ target: { name, value: date } }, field.onInputValueChange)
        }}
        minDate={field.minDate}
        withIcon
        iconLeftProps={{ strokeWidth: 1, fill: 'black', name: 'calendarEdit', width: 20, height: 20 }}
        {...commonSelectAndInputProps}
      />
    )
  }

  return (
    <StyledInputWrapper
      width={field.width}
      className={clsx(formFlexDirection, field.className, isHidden && 'isHidden')}
    >
      {field.component === 'dropdown' ? (
        <FormSelect
          {...commonSelectAndInputProps}
          labelKey={labelKey}
          lng={lng}
          optionsData={optionsData}
          optionsFromFormValues={optionsFromFormValues}
          updateSelect={updateSelect}
        />
      ) : (
        <FormInput
          submitByEnterPressed={submitByEnterPressed}
          updateInput={updateInput}
          hideButtons={field.hideButtons}
          {...commonSelectAndInputProps}
        />
      )}
    </StyledInputWrapper>
  )
}

export default FormField
