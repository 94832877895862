//** REDUX CONSTANTS */

// COMMON
export const TOGGLE_NAV_MENU_EXTENDED = 'COMMON/TOGGLE_NAV_MENU_EXTENDED'
export const POPUP_ALERT_SHOW = 'COMMON/POPUP_ALERT_SHOW'
export const POPUP_ALERT_HIDE = 'COMMON/POPUP_ALERT_HIDE'
export const POPUP_ALERT_HIDE_ALL = 'COMMON/POPUP_ALERT_HIDE_ALL'
export const CLEAR_NOTIFICATIONS = 'COMMON/CLEAR_NOTIFICATIONS'
export const SET_NOTIFICATIONS = 'COMMON/SET_NOTIFICATIONS'
export const MARK_NOTIFICATION_AS_OLD = 'COMMON/MARK_NOTIFICATION_AS_OLD'
export const TOGGLE_RIGHT_PANEL_EXTENDED = 'TOGGLE_RIGHT_PANEL_EXTENDED'

// FORMS
export const UPDATE_FORM_VALUES_CHANGED = 'FORMS/UPDATE_FORM_VALUES_CHANGED'
export const RESET_FORM_VALUES_CHANGED = 'FORMS/RESET_FORM_VALUES_CHANGED'

// DATA
export const DATA_BULK_OPERATION = 'DATA/BULK_OPERATION'
export const DATA_BULK_UPDATE_ERROR = 'DATA/BULK_UPDATE_ERROR'
export const DATA_BULK_UPDATE_MAIN_TABLE_SUCCESS = 'DATA/BULK_UPDATE_SUCCESS'
export const DATA_BULK_UPDATE = 'DATA/BULK_UPDATE'
// export const DATA_DELETE = 'DATA/DATA_DELETE'
// export const DATA_DELETE_SUCCESS = 'DATA/DATA_DELETE_SUCCESS'
// export const DATA_DELETE_ERROR = 'DATA/DATA_DELETE_ERROR'
export const SET_DATA_TYPE = 'DATA/SET_TYPE'
export const SET_API_URL_PARAM = 'DATA/SET_API_URL_PARAM'

export const UPSERT = 'DATA/UPSERT'
export const UPSERT_SUCCESS = 'DATA/UPSERT_SUCCESS'
export const UPSERT_ERROR = 'DATA/UPSERT_ERROR'
export const UPDATE_TABLE_AND_SYSTEM_DATA = 'DATA/UPDATE_TABLE_AND_SYSTEM_DATA'
export const DATA_FETCH_SYSTEM_DATA = 'DATA/FETCH_SYSTEM_DATA'
export const DATA_FETCH_SYSTEM_DATA_SUCCESS = 'DATA/FETCH_SYSTEM_DATA_SUCCESS'
export const DATA_FETCH_SYSTEM_DATA_ERROR = 'DATA/FETCH_SYSTEM_DATA_ERROR'
export const DATA_SET_SYSTEM_DATA = 'DATA/SET_SYSTEM_DATA'
export const DATA_FETCH_WITH_FACETS = 'DATA/FETCH_WITH_FACETS'
export const DATA_FETCH_WITH_FACETS_SUCCESS = 'DATA/FETCH_WITH_FACETS_SUCCESS'
export const DATA_FETCH_WITH_FACETS_ERROR = 'DATA/FETCH_WITH_FACETS_ERROR'

// FILTERS
export const FILTERS_TOGGLE_PANEL = 'FILTERS/TOGGLE_PANEL'
export const FILTERS_FILTER_ADD = 'FILTERS/FILTER_ADD'
export const FILTERS_FILTER_REMOVE = 'FILTERS/FILTER_REMOVE'
export const FILTERS_CLEAR_ALL = 'FILTERS/CLEAR_ALL'
export const FILTERS_SET_ON_FIRST_LOAD = 'FILTERS/SET_ON_FIRST_LOAD'
export const FILTERS_SET_FACETS = 'FILTERS/SET_FACETS'
export const FILTERS_BACK_TO_INITIAL_STATE = 'FILTERS/BACK_TO_INITIAL_STATE'
export const FILTERS_SET_FACET_GROUP_JUST_SELECTED = 'FILTERS/SET_FACET_GROUP_JUST_SELECTED'

// BASKET
export const ADD_TO_BASKET = 'BASKET/ADD_TO_BASKET'
export const ADD_PRODUCT_TO_BASKET = 'BASKET/ADD_PRODUCT_TO_BASKET'
export const ADD_DEMAND_TO_BASKET = 'BASKET/ADD_DEMAND_TO_BASKET'
export const UPDATE_BASKET = 'BASKET/UPDATE_BASKET'
export const CLEAN_BASKET = 'BASKET/CLEAN_BASKET'

// MODIFICATIONS
export const UPDATE_MODIFICATION_CODE_IN_SYSTEM = 'MODIFICATIONS/UPDATE_MODIFICATION_CODE_IN_SYSTEM'

// FILES INPUT
export const INPUT_FILES_ADD = 'INPUT/FILES_ADD'
export const INPUT_FILES_CLEAR = 'INPUT/FILES_CLEAR'
export const INPUT_FILE_REMOVE = 'INPUT/FILE_REMOVE'

// FILES UPLOAD
export const FILE_PARSE = 'FILE/PARSE'
export const FILE_UPLOAD = 'FILE/UPLOAD'
export const FILES_UPLOAD_SUCCESS = 'FILE/UPLOAD_SUCCESS'
export const FILE_UPLOAD_FAILURE = 'FILE/UPLOAD_FAILURE'
export const AVATAR_UPLOAD = 'FILE/AVATAR_UPLOAD'
export const AVATAR_UPLOAD_SUCCESS = 'FILE/AVATAR_UPLOAD_SUCCESS'
export const AVATAR_UPLOAD_FAILURE = 'FILE/AVATAR_UPLOAD_FAILURE'
export const PHOTO_DELETE = 'FILE/PHOTO_DELETE'
export const PHOTO_DELETE_SUCCESS = 'FILE/PHOTO_DELETE_SUCCESS'
export const PHOTO_DELETE_FAILURE = 'FILE/PHOTO_DELETE_FAILURE'
export const FILE_UPLOAD_BULK = 'FILE/UPLOAD_BULK'
export const FILE_DELETE = 'FILE/DELETE'
export const FILE_DELETE_SUCCESS = 'FILE/DELETE_SUCCESS'
export const FILE_DELETE_FAILURE = 'FILE/DELETE_FAILURE'

// SIDEBAR
export const SIDEBAR_FETCH_BY_ID = 'SIDEBAR/FETCH_BY_ID'
export const SIDEBAR_FETCH_BY_ID_ERROR = 'SIDEBAR/FETCH_BY_ID_ERROR'
export const SIDEBAR_FETCH_BY_ID_SUCCESS = 'SIDEBAR/FETCH_BY_ID_SUCCESS'
export const SIDEBAR_ITEM_RESET = 'SIDEBAR/ITEM_RESET'
export const SIDEBAR_ITEM_SET = 'SIDEBAR/ITEM_SET'
export const SIDEBAR_ITEM_PHOTOS_SET = 'SIDEBAR/ITEM_PHOTOS_SET'
export const SIDEBAR_ITEM_COPY = 'SIDEBAR/ITEM_COPY'
export const SIDEBAR_UPSERT = 'SIDEBAR/UPSERT'
export const SIDEBAR_UPSERT_USER = 'SIDEBAR/UPSERT_USER'
export const SIDEBAR_UPSERT_USER_SUCCESS = 'SIDEBAR/UPSERT_USER_SUCCESS'
export const SIDEBAR_UPSERT_USER_ERROR = 'SIDEBAR/UPSERT_USER_ERROR'
export const SIDEBAR_UPSERT_TOPICS = 'SIDEBAR/UPSERT_TOPICS'
export const SIDEBAR_UPSERT_ERROR = 'SIDEBAR/UPSERT_ERROR'
export const SIDEBAR_UPSERT_SUCCESS = 'SIDEBAR/UPSERT_SUCCESS'
export const SIDEBAR_POST_COMMENT = 'SIDEBAR/POST_COMMENT'
export const SIDEBAR_POST_COMMENT_ERROR = 'SIDEBAR/POST_COMMENT_ERROR'
export const SIDEBAR_POST_COMMENT_SUCCESS = 'SIDEBAR/POST_COMMENT_SUCCESS'

export const INSTANCE_CREATE = 'SIDEBAR/INSTANCE_CREATE'
export const INSTANCE_CREATE_SUCCESS = 'SIDEBAR/INSTANCE_CREATE_SUCCESS'
export const INSTANCE_CREATE_ERROR = 'SIDEBAR/INSTANCE_CREATE_ERROR'

// AUTH
export const INITIALIZE_APP = 'AUTH/INITIALIZE_APP'
export const GET_CURRENT_USER = 'AUTH/GET_CURRENT_USER'
export const GET_CURRENT_USER_SUCCESS = 'AUTH/GET_CURRENT_USER_SUCCESS'
export const GET_CURRENT_USER_ERROR = 'AUTH/GET_CURRENT_USER_ERROR'
export const SIGN_UP = 'AUTH/SIGN_UP'
export const SIGN_UP_SUCCESS = 'AUTH/SIGN_UP_SUCCESS'
export const SIGN_UP_ERROR = 'AUTH/SIGN_UP_ERROR'
export const SIGN_IN = 'AUTH/SIGN_IN'
export const SIGN_IN_SUCCESS = 'AUTH/SIGN_IN_SUCCESS'
export const SIGN_IN_ERROR = 'AUTH/SIGN_IN_ERROR'
export const SIGN_OUT = 'AUTH/SIGN_OUT'
export const SIGN_OUT_SUCCESS = 'AUTH/SIGN_OUT_SUCCESS'
export const INVITE_MEMBER = 'AUTH/INVITE_MEMBER'
export const CONFIRM_EMAIL = 'AUTH/CONFIRM_EMAIL'
export const CONFIRM_EMAIL_SUCCESS = 'AUTH/CONFIRM_EMAIL_SUCCESS'
export const REQUEST_RESET_PASSWORD = 'AUTH/REQUEST_RESET_PASSWORD'
export const REQUEST_RESET_PASSWORD_SUCCESS = 'AUTH/REQUEST_RESET_PASSWORD_SUCCESS'
export const REQUEST_RESET_PASSWORD_ERROR = 'AUTH/REQUEST_RESET_PASSWORD_ERROR'
export const RESET_PASSWORD = 'AUTH/RESET_PASSWORD'
export const GET_INVITE_DETAILS = 'AUTH/GET_INVITE_DETAILS'
export const GET_INVITE_DETAILS_SUCCESS = 'AUTH/GET_INVITE_DETAILS_SUCCESS'
export const TOKEN_REFRESH = 'AUTH/TOKEN_REFRESH'
export const TOKEN_REFRESH_FAILURE = 'AUTH/TOKEN_REFRESH_FAILURE'
export const TOKEN_REFRESH_SUCCESS = 'AUTH/TOKEN_REFRESH_SUCCESS'
export const TOKEN_REFRESH_ERROR = 'AUTH/TOKEN_REFRESH_ERROR'
export const SET_USER_ROLE = 'AUTH/SET_USER_ROLE'
export const SET_USER_ROLE_SUCCESS = 'AUTH/SET_USER_ROLE_SUCCESS'
export const SET_USER_ROLE_ERROR = 'AUTH/SET_USER_ROLE_ERROR'
