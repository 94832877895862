import { SUPPORTED_LNGS } from '../locales'

const langObject = SUPPORTED_LNGS.reduce((acc, curr) => {
  return { ...acc, [curr]: '' }
}, {})

const defaultBrandValue = {
  id: '',
  state: '',
  name: '',
  name_en: '',
  country_id: '',
  email: '',
  website: '',
  description: langObject,
  photos: {
    logos: { active: [] },
    miniLogos: { active: [] },
    cover: { active: [] },
    brand: { active: [] },
    team: { active: [] },
    products: { active: [] },
  },
}

const defaultItemValue = {
  id: '',
  state: '',
  sku: '',
  brand_id: '',
  category_id: '',
  translations: langObject,
  description: langObject,
  properties: {},
  characteristics: {},
  photos: {
    general: { active: [] },
  },
  // content: [], // { id: enum_option_id, amount: 123, unitId: ''}
}

const defaultCategoryValue = {
  id: '',
  state: '',
  name: '',
  // unit: '',
  parent: '',
  parent_id: '',
  // characteristics: {},
  // properties: {},
  translations: langObject,
  is_used_in_inquiries: false,
  photos: {
    general: { active: [] },
  },
}

const defaultBannerValue = {
  id: '',
  state: '',
  general: {
    page: '',
    category: '',
  },
  seo: {
    title: langObject,
    description: langObject,
    tags: langObject,
    keywords: langObject,
  },
  photos: {
    general: { active: [] },
    mobile: { active: [] },
    square: { active: [] },
  },
}

export const defaultPlatformValue = {
  id: '',
  state: '',
  general: {
    name: '',
    url: 'https://',
    email: '',
    country: '',
    languages: [],
    defaultLanguage: '',
    industries: '',
    carriers: '',
  },
  socialNetworks: {
    facebook: '',
    instagram: '',
    twitter: '',
    linkedin: '',
    pinterest: '',
  },
  tokens: {
    googleAnalytics: '',
    googleTagManager: '',
    replainChat: '',
  },
  media: {
    video: '',
    videoPreview: '',
  },
  photos: {
    logos: { active: [] },
    miniLogos: { active: [] },
    favicon: { active: [] },
    sharingImage: { active: [] },
    page404: { active: [] },
    isComingSoon: { active: [] },
  },
  color: {
    primary: {
      lightest: '',
      lighter: '',
      light: '',
      main: '',
      dark: '',
      darker: '',
      darkest: '',
    },
    secondary: {
      lightest: '',
      lighter: '',
      light: '',
      main: '',
      dark: '',
      darker: '',
      darkest: '',
    },
    general: {
      dark: '',
      light: '',
      gray1: '',
      gray2: '',
      gray3: '',
      gray4: '',
      gray5: '',
      gray6: '',
      gray7: '',
    },
    status: { new: '', success: '', warning: '', error: '' },
  },
  font: {
    family: {
      primary: '',
      secondary: '',
    },
  },
  seo: {
    title: langObject,
    keywords: langObject,
    description: langObject,
  },
}

const defaultOrderValue = {
  status: 'new',
  state: '',
  id: '',
  total: 0,
  totalDelivery: 0,
  // totalDiscount: 0,
  totalTaxes: 0,
  currency: '',
  general: {
    // client: '',
    // merchant: '',
    // customer: {
    //   address: '',
    //   city: '',
    //   country: '',
    //   email: '',
    //   firstName: '',
    //   lastName: '',
    //    phones: '', //[{ code: '', phonePart: '', phone: '' }]
    // },
  },
  items: [
    // {
    //   stockItemId: '',
    //   stock: ',
    //   brand: ',
    //   category: ',
    //   item: ',
    //   model: '',
    //   characteristics: {
    //     key: '', // color:size
    //     value: '', // white:m
    //   },
    //   quantity: null,
    //   price: null,
    //   sum: null,
    // },
  ],
}

const defaultPageCommonValue = {
  id: '',
  state: '',
  type: undefined,
  link: '',
  translations: { en: '' },
  body: { en: '' },
  photos: { general: { active: [] } },
}

const defaultAboutUsPageValue = {
  general: { url: '/story' },
  texts: { firstPage: langObject, secondPage: langObject },
  media: { video: '' },
  style: {
    backgroundColor: '',
    backgroundIsDark: false,
    withOverlay: true,
    firstPageImgIsBackground: false,
  },
  photos: {
    firstScreen: { active: [] },
    firstScreenMobile: { active: [] },
    secondScreen: { active: [] },
    overlay: { active: [] },
  },
}

const defaultTextPageValue = {
  general: {
    url: '',
  },
  translations: {
    header: '',
    pageContent: '',
    linkToPageText: '',
  },
}

const defaultLandingPageValue = {
  general: { url: '' },
  assets: {
    banners: [],
    categories: [],
    categoriesRecommended: [],
    bestsellers: [],
    featuredProducts: [],
    brands: [],
  },
}

const defaultVerticalPageValue = {
  general: { url: '' },
  assets: {
    banners: [],
    categories: [],
    categoriesRecommended: [],
    bestsellers: [],
    featuredProducts: [],
    brands: [],
  },
}

const defaultCategoryPageValue = {
  general: { category: '', url: '' },
  assets: {
    banners: [],
  },
}

const defaultAttributeCommonValue = {
  id: '',
  state: '',
  name: '',
  attribute_type: '',
  is_characteristic: false,
  is_photo_group: false,
  translations: langObject,
}
const defaultNumericAttributeValue = {
  general: {
    unit: '',
  },
}
const defaultEnumAttributeValue = {
  general: {
    enumeration: '',
  },
}
const defaultBoolAttributeValue = {
  general: {},
}

const defaultUnitValue = {
  id: '',
  state: '',
  code: '',
  magnitude: '',
  coefficient: '',
  translations: langObject,
}

const defaultEnumerationValue = {
  id: '',
  name: '',
  translations: langObject,
  options: [], // {id: '', name: langObject, icon: ''}
}

const defaultCarrierValue = {
  id: '',
  slug: '',
  name: '',
  website: '',
  email: '',
  country: '',
  deliveryOptions: [],
  photos: {
    logos: { active: [] },
    miniLogos: { active: [] },
  },
}

const defaultContractValue = {}

const defaultOrganizationValue = {
  id: '',
  state: '',
  status: '',
  type: '', //{ id: 'governmental' }
  email: '',
  phone: undefined, //[{ code: '', phonePart: '', phone: '' }]
  country_id: 0,
  region_id: 0,
  city: '',
  address: '',
  year_founded: null,
  description: langObject,

  photos: { logos: [], banners: [] },
  // shipmentAddress: {
  //   country: '',
  //   region: '',
  //   city: '',
  //   address: '',
  //   phones: '', //[{ code: '', phonePart: '', phone: '' }]
  // },
}

const defaultInquiryValue = {
  state: '',
  // status: 'new',
  recipient_id: '',
}

const defaultInvoiceValue = { status: 'new' }

const defaultProductValue = {
  // platform: ',
  // currency: ',
  customer: {
    address: '',
    city: '',
    country: '',
    email: '',
    firstName: '',
    lastName: '',
    phones: '', //[{ code: '', phonePart: '', phone: '' }],
  },
}

const defaultShipmentValue = { status: 'new' }

const defaultWarehouseValue = {
  state: '',
  name: '',
  type: [],
  currency_id: '',
  country_id: '',
  region: '',
  city: '',
}

const defaultStockItemValue = {}

const defaultUserValue = {
  full_name: '',
  email: '',
  organization_from_signup: '',
  profile_pic: '',
  role: '',
  organization_id: '',
}

const defaultPostValue = {
  state: '',
  visibility: '',
  translations: { en: '' },
  body: { en: '' },
  photos: {
    main: { active: [] },
    slides: { active: [] },
  },
}

const defaultFAQValue = {
  state: '',
  translations: { en: '' },
  body: { en: '' },
}

const defaultReportValue = {
  state: '',
  translations: { en: '' },
  files: { general: { active: [] } },
  month: '',
}

const defaultBlogTopicsValue = { name: '', translations: { en: '' } }

const defaultCampaignsValue = {
  translations: {
    en: '',
  },
  state: '',
  body: { en: '' },
  deadline_at: '',
  photos: {
    general: { active: [] },
  },
}

const defaultInventoriesValue = {
  state: 'drafted',
  organization_id: '',
  warehouse_id: '',
  user_id: '',
}

export const DEFAULT_VALUES_DATA = {
  attributes: defaultAttributeCommonValue,
  numeric: defaultNumericAttributeValue,
  enum: defaultEnumAttributeValue,
  bool: defaultBoolAttributeValue,

  banners: defaultBannerValue,
  brands: defaultBrandValue,
  categories: defaultCategoryValue,
  carriers: defaultCarrierValue,
  contracts: defaultContractValue,
  organizations: defaultOrganizationValue,
  enumerations: defaultEnumerationValue,
  inquiries: defaultInquiryValue,
  invoices: defaultInvoiceValue,
  items: defaultItemValue,
  landing: defaultLandingPageValue,
  inquiry_items: {
    state: '',
    // status: 'new',
    recipient_id: '',
    comment: '',
  },
  orders: defaultOrderValue,

  pages: defaultPageCommonValue,
  text: defaultTextPageValue,
  aboutUs: defaultAboutUsPageValue,
  vertical: defaultVerticalPageValue,
  category: defaultCategoryPageValue,

  platforms: defaultPlatformValue,
  products: defaultProductValue,
  shipments: defaultShipmentValue,
  'stock-items': defaultStockItemValue,
  warehouses: defaultWarehouseValue,
  units: defaultUnitValue,

  users: defaultUserValue,
  organization_owner: {},
  'platform-admin': { general: { platforms: '' } },
  'merchant-admin': { general: { organization: '' } },
  donor: { general: { organization: '' } },
  client: { general: { organization: '' } },
  recipient: { general: { organization: '' } },
  logistic: { general: { organization: '' } },
  posts: defaultPostValue,
  topics: defaultBlogTopicsValue,
  campaigns: defaultCampaignsValue,
  faqs: defaultFAQValue,
  reports: defaultReportValue,
  inventories: defaultInventoriesValue,
}
