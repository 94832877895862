import React, { useContext } from 'react'
import { PropTypes as T } from 'prop-types'
import { Link } from 'react-router-dom'
import { withTheme } from 'styled-components'

import { FlexRow, IMAGEKIT_PARAMS_CONFIG, ScreenContext, Image, UiLink } from '@aidsupply/components'

import { StyledLogoWrapper } from './styled'
import LogoText from '../../assets/icons/LogoText'
import { AIDSUPPLY_MINI_LOGO_DARK_LINK, AIDSUPPLY_MINI_LOGO_LIGHT_LINK, IMAGEKIT_URL } from '../../constants'

const Logo = ({ theme, variant, isExtended, isDark, isTextPage, to, className, width, height }) => {
  const { width: screenWidth } = useContext(ScreenContext) || {}
  const isMobile = screenWidth && screenWidth < theme.breakpoints.sm
  const isTablet = screenWidth && screenWidth < theme.breakpoints.lg

  const size = {
    small: {
      mini: { width: 36, height: 36 },
    },
    medium: {
      mini: { width: 36, height: 36 },
      text: { width: 130, height: 25 },
    },
    large: {
      mini: { width: 40, height: 40 },
      text: { width: 93, height: 25 },
    },
  }

  const getMiniLogo = () => {
    return (
      <Image
        alt="Logo"
        disableImagekit={theme.logoSrc.startsWith('https://')}
        src={isDark ? AIDSUPPLY_MINI_LOGO_DARK_LINK : AIDSUPPLY_MINI_LOGO_LIGHT_LINK}
        className="logo"
        imagekitParams={IMAGEKIT_PARAMS_CONFIG.crm.goodzyk?.logo}
        imagekitUrl={IMAGEKIT_URL}
        {...size[variant].mini}
      />
    )
  }

  return (
    <StyledLogoWrapper className={className}>
      <UiLink noStyles to={to} Link={Link}>
        {isExtended ? (
          <FlexRow>
            {getMiniLogo()}
            <LogoText {...size[variant].text} />
          </FlexRow>
        ) : (
          getMiniLogo()
        )}
      </UiLink>
    </StyledLogoWrapper>
  )
}

export default withTheme(Logo)

Logo.propTypes = {
  theme: T.object,
  variant: T.oneOf(['small', 'medium', 'large']),
  isExtended: T.bool,
  isTextPage: T.bool,
}

Logo.defaultProps = {
  variant: 'large',
}
