import React from 'react'
import { Outlet } from 'react-router-dom'

import GridLayout from '../Layout/GridLayout'
import TableBlock from '../../components/Table/TableBlock'
import SearchCategoryCard from '../../components/Table/SearchCategoryCard'

const Demands = () => {
  return (
    <GridLayout RightPanelComponent={<Outlet />}>
      <TableBlock isMainTable isPaginationDisabled CardTableItem={SearchCategoryCard} />
    </GridLayout>
  )
}

export default Demands
