import React from 'react'
import { useTranslation } from 'react-i18next'

import { StyledStocks } from './styled'
import GridLayout from '../../Layout/GridLayout'
import { Outlet } from 'react-router-dom'
import TableBlock from '../../../components/Table/TableBlock'

const Stocks = () => {
  const { t } = useTranslation()

  return (
    <GridLayout RightPanelComponent={<Outlet context={{}} />}>
      <TableBlock
        // hideFilterPanel={hideFilterPanel}
        isMainTable
        // isReadOnly={isReadOnly}
        // rightPanelIsOpened={rightPanelIsOpened}
        // gap={gap}
        // leftPanelIsOpened={leftPanelIsOpened}
        // setLeftPanelOpened={setLeftPanelOpened}
      />
    </GridLayout>
  )
}

export default Stocks
