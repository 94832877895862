import React from 'react'
import ReactTexty from 'react-texty'

import { InfoRowsBlock, Typography, Tag } from '@aidsupply/components'
import { useMappedState } from '../../../hooks'
import { selectAllSystemCollections, selectUserRole } from '../../../redux/selectors'

const ReportsTableCard = ({ className, data, initialData }) => {
  const { translations, state, period, year, month, created_at } = data
  const system = useMappedState(selectAllSystemCollections)
  const organization = Object.keys(system.organizations).find(
    (key) => system.organizations[key].id === initialData.organization_id
  )
  const organizationName = system.organizations[organization].name

  const userRole = useMappedState(selectUserRole)
  const role = userRole === 'administrator' || userRole === 'system'

  return (
    <InfoRowsBlock
      className={className}
      data={[
        {
          name: {
            customElement: <Typography as={ReactTexty} text={translations} type="h4" />,
            width: 'calc(100% - 36px)',
            hideBorder: true,
            padding: '0 0 0 10px',
          },
          state: {
            customElement: state,
            width: '36px',
            padding: '11px',
          },
        },
        {
          created_at: {
            iconProps: {
              name: 'calendarEdit',
            },
            customElement: created_at,
            width: '50%',
            hideBorder: false,
            padding: ' 10px',
          },
          period: {
            iconProps: {
              name: 'time',
            },
            customElement: <Tag text={period} />,
            width: '50%',
            padding: '8px 10px',
          },
        },
        {
          year: {
            iconProps: {
              name: 'at',
            },
            customElement: year,
            width: initialData.period === 'monthly' ? '50%' : '100%',
          },
          ...(initialData.period === 'monthly' && {
            month: {
              iconProps: {
                name: 'at',
              },
              customElement: month,
              width: '50%',
            },
          }),
        },
        role && {
          organization: {
            iconProps: {
              name: 'warehouse',
            },
            text: organizationName,
            width: '100%',
          },
        },
      ].filter(Boolean)}
    />
  )
}

export default ReportsTableCard
