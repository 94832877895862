import React from 'react'
import { withTheme } from 'styled-components'
import Comments from '../../../components/OrderForm/Comments'

const containerStyles = {
  position: 'relative',
  overflowY: 'auto',
  maxHeight: 'calc(100vh - 100px)',
  '@media (min-width: 550px) and (max-width: 1023px)': {
    maxHeight: 'calc(100vh - 200px)',
  },
  '@media (min-width: 1024px)': {
    maxHeight: 'calc(100vh - 121px)',
  },
}

const inputStyles = {
  position: 'sticky',
  bottom: '0px',
  backgroundColor: '#fff',
  zIndex: 3,
}

const CommentsRightPanel = ({ theme: { color } }) => {
  return <Comments showAll={true} containerStyles={containerStyles} inputStyles={inputStyles} />
}

export default withTheme(CommentsRightPanel)
