import React from 'react'
import { useTranslation } from 'react-i18next'
import { withTheme } from 'styled-components'
import { Link as RouterLink } from 'react-router-dom'

import { Icon, Typography, UiLink } from '@aidsupply/components'

import IntroScreenWrapper from '../../components/IntroScreens/IntroScreenWrapper'
import ReactHelmet from '../../wrappers/ReactHelmet'
import { INTRO_PAGES_IMG } from '../../constants'
// import backOrange from '../../assets/images/backOrange.jpg'

const PasswordRecoverySent = ({ theme }) => {
  const { t } = useTranslation('signIn')

  return (
    <>
      <ReactHelmet title="signTitle" />

      <IntroScreenWrapper
        type="passwordRecoverySent"
        theme={theme}
        imageUrl={INTRO_PAGES_IMG}
        text={t('checkYourMailbox')}
      >
        <Icon name="checkMailbox" margin="30px 0 0" />
        <div className="inscriptionWrapper">
          <Typography
            type="body2"
            color={theme.color.general.dark}
            margin="20px 0 0 0"
            className="inline"
            text={t('passRecoverSentText')}
          />
        </div>
        <div style={{ display: 'flex', gap: '6px', 'margin-top': '20px', 'justify-content': 'center' }}>
          <Typography type="body1">{t('backTo')}</Typography>
          <Typography type="button1">
            <UiLink Link={RouterLink} to="/login">
              {t('toSignIn')}
            </UiLink>
          </Typography>
        </div>
      </IntroScreenWrapper>
    </>
  )
}

export default withTheme(PasswordRecoverySent)
