import React from 'react'
import { useTranslation } from 'react-i18next'

import MultiSelectTable from '../MultiSelectTable/MultiSelectTable'
import FormField from '../Form/FormField'

const ItemAttributesFormPart = ({
  attributes, // all system attrs
  blockKey,
  enumerations,
  formValues, // { attr_slug: [enum_opt_slug] | number | bool }
  blocks, // [ category properties or characteristics ]
  photos,
  units,
  updateCheckbox,
  updateInput,
  updateSelect,
}) => {
  const { i18n } = useTranslation()

  if (!blocks) {
    return null
  }

  return Object.values(blocks).map((categoryAttribute) => {
    const catSlug = categoryAttribute.slug
    const fieldKey = `${blockKey}.${catSlug}`

    const label = categoryAttribute.translations.label?.[i18n.language] || categoryAttribute.general.name

    if (categoryAttribute.general.type.id === 'bool') {
      return (
        <FormField
          key={catSlug}
          name={fieldKey}
          field={{
            label,
            key: fieldKey,
            component: 'checkbox',
          }}
          formValue={formValues[catSlug]}
          updateCheckbox={updateCheckbox}
        />
      )
    }

    if (categoryAttribute.general.type.id === 'numeric') {
      return (
        <FormField
          key={catSlug}
          name={fieldKey}
          field={{
            type: 'number',
            label:
              `${label}` +
              (categoryAttribute.general.unit
                ? `, ${units?.[categoryAttribute.general.unit.id]?.general.code}`
                : ''),
            key: fieldKey,
          }}
          formValue={formValues[catSlug]}
          updateInput={updateInput}
        />
      )
    }

    if (categoryAttribute.general.type.id === 'enum') {
      return (
        <MultiSelectTable
          headerHeight={0}
          attributes={attributes}
          photos={photos}
          customBlockValues={blocks}
          keyToUpdate={`${blockKey}.${catSlug}`}
          withTextHeader
          key={catSlug}
          data={formValues[catSlug]}
          textHeader={label}
          options={enumerations?.[categoryAttribute.general.enumeration.id]?.options || []}
          type={blockKey}
          updateSelectValue={updateSelect}
        />
      )
    }
  })
}

export default ItemAttributesFormPart
