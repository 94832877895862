import dot from 'dot-object'
import React, { useContext, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { withTheme } from 'styled-components'

import { Button, FlexRow, ScreenContext } from '@aidsupply/components'

import { RIGHT_PANEL_HEADERS } from '../../config'
import { HEADER_HEIGHT } from '../../constants'
import { useMappedState } from '../../hooks'
import { sidebarFetchById, sidebarItemCopy, sidebarItemReset } from '../../redux/actions'
import {
  selectDataType,
  selectRightPanelInProgress,
  selectSidebarInitialData,
  selectSidebarItemInProgress,
} from '../../redux/selectors'
import { StyledRightDrawer } from './styled'

const RightPanel = ({
  absolutePositioned,
  children,
  minWidth,
  inProgress,
  openedValue,
  theme,
  toggleDrawer,
  width,
  rightPanelProps,
  isRightPanelWide,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation('forms')
  const dispatch = useDispatch()
  const { currentBreakpoint } = useContext(ScreenContext) || {}
  const { search } = useLocation()
  const navigate = useNavigate()
  const params = useParams()
  const { rightPanelId, action } = params

  const type = useMappedState(selectDataType)
  const rightPanelInProgress = useMappedState(selectRightPanelInProgress)
  const isGetItemInProgress = useMappedState(selectSidebarItemInProgress)
  const itemInitial = useMappedState(selectSidebarInitialData)
  const panelRef = useRef(null)

  useEffect(() => {
    if (itemInitial && !itemInitial.id && panelRef.current) {
      panelRef.current.scrollTo(0, 0)
    }
  }, [itemInitial])

  useEffect(() => {
    if (type && rightPanelId !== 'copy') {
      if (!rightPanelId) {
        dispatch(sidebarItemReset())
      } else if (!isNaN(+rightPanelId) || ['new', 'invite'].includes(rightPanelId)) {
        dispatch(sidebarFetchById(rightPanelId, type))
      }
    }
  }, [rightPanelId, type])

  const onCloseRightPanel = () => {
    if (rightPanelId || action) {
      navigate({ pathname: '.', search })
    }
  }

  const onCopy = () => {
    navigate('./copy')
    dispatch(sidebarItemCopy(rightPanelId, type))
  }

  const getHeaderText = () => {
    if (itemInitial && itemInitial.id) {
      const dataKey = RIGHT_PANEL_HEADERS[type]?.dataKey
      const translationKey = !dataKey && RIGHT_PANEL_HEADERS[type]?.translationKey
      const dataKeyValue = dataKey && dot.pick(dataKey, itemInitial)
      const rightPanelKey = RIGHT_PANEL_HEADERS[type]?.[rightPanelId]
      return (
        (typeof dataKeyValue === 'string' ? dataKeyValue : dataKeyValue?.[language]) ||
        (translationKey && t(translationKey)) ||
        (rightPanelKey && t(rightPanelKey)) ||
        t('edit') //${type.labelSingle}
      )
    }
    return t('create') //${type.labelSingle}

    // || t('all:details')
  }

  return (
    <StyledRightDrawer
      closeIconProps={{
        width: 18,
        height: 18,
        wrapperWidth: 32,
        wrapperHeight: 32,
        wrapperColor: theme.color.primary.main,
        fill: theme.color.general.light,
        strokeWidth: 1.4,
      }}
      closeIconPadding="6px"
      headerHeight={HEADER_HEIGHT}
      width={width}
      minWidth={minWidth}
      absolutePositioned={absolutePositioned}
      openedValue={
        typeof openedValue === 'undefined'
          ? !!(itemInitial && rightPanelId && !isGetItemInProgress)
          : openedValue
      }
      toggleDrawer={toggleDrawer}
      onClose={onCloseRightPanel}
      currentBreakpoint={currentBreakpoint}
      className={inProgress || rightPanelInProgress ? 'rightPanel inProgress' : 'rightPanel'}
      ref={panelRef}
      id="rightPanel"
      {...rightPanelProps}
      title={
        <FlexRow className="editPanelTitleRow" justifyContent="center">
          {rightPanelProps?.title || getHeaderText()}
          <Button
            margin="0 0 auto 0"
            padding="5px"
            variant="bordered"
            withIcon
            iconLeftProps={{
              name: 'pages',
              height: 20,
              width: 20,
              strokeWidth: 1,
            }}
            onClick={onCopy}
          />
        </FlexRow>
      }
      isRightPanelWide={isRightPanelWide}
    >
      {children}
    </StyledRightDrawer>
  )
}

export default withTheme(RightPanel)
