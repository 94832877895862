import React from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'

import GridLayout from '../../Layout/GridLayout'
import TableBlock from '../../../components/Table/TableBlock'
import WarehousesTableCard from './WarehousesTableCard'
import { useMappedState } from '../../../hooks'
import { selectUserRole } from '../../../redux/selectors'

const Warehouses = () => {
  const { t } = useTranslation()

  const userRole = useMappedState(selectUserRole)
  const isOrganizationVisible = userRole === 'administrator' || userRole === 'system'

  return (
    <GridLayout RightPanelComponent={<Outlet context={{}} />}>
      <TableBlock
        // hideFilterPanel={hideFilterPanel}
        isMainTable
        TableCardContent={WarehousesTableCard}
        tableCardHeight={isOrganizationVisible ? 165 : 123}
        // isReadOnly={isReadOnly}
        // rightPanelIsOpened={rightPanelIsOpened}
        // gap={gap}
        // leftPanelIsOpened={leftPanelIsOpened}
        // setLeftPanelOpened={setLeftPanelOpened}
      />
    </GridLayout>
  )
}

export default Warehouses
