import React from 'react'
import { InfoRowsBlock, Userpic, Typography, Tag, Tooltip } from '@aidsupply/components'
import { useTranslation } from 'react-i18next'
import { withTheme } from 'styled-components'
import ReactTexty from 'react-texty'

import { useMappedState } from '../../../hooks'
import { selectAllSystemCollections } from '../../../redux/selectors'
import { IMAGEKIT_URL } from '../../../constants'

const OrganizationsTableCard = ({ className, data, initialData, theme }) => {
  const { logo_url, name, rating, state, type, address, created_at, checkbox } = data

  const system = useMappedState(selectAllSystemCollections)
  const organizationRoles = Object.keys(system.organizationRoles)

  const {
    i18n: { language: lng },
    t,
  } = useTranslation('table')

  const getRole = (role) => {
    role = t(`${role}`)
    return role.charAt(0).toUpperCase()
  }

  const getAllRolesString = (roles) => {
    const allRolesString = roles
      .map((role) => {
        const translatedRole = t(role)
        const capitalizedRole = translatedRole.charAt(0).toUpperCase() + translatedRole.slice(1) // Получаем роль с заглавной первой буквой
        return capitalizedRole
      })
      .join(', ')
    return allRolesString
  }

  const allRolesString = getAllRolesString(organizationRoles)

  return (
    <InfoRowsBlock
      className={className}
      data={[
        {
          logo: {
            customElement: (
              <Userpic
                height="28px"
                width="28px"
                imagekitUrl={IMAGEKIT_URL}
                src={logo_url}
                borderColor={theme.color.general.dark}
                fullName={name}
              />
            ),
            hideBorder: true,
            padding: '0 0 0 10px',
            width: '38px',
          },

          name: {
            customElement: (
              <Typography as={ReactTexty} type="h4">
                {name}
              </Typography>
            ),
            width: 'calc(50% - 28px)',
            hideBorder: true,
            padding: '0 0 0 10px',
          },
          rating: {
            iconProps: {
              name: 'star',
              stroke: theme.color.secondary.dark,
              fill: theme.color.secondary.main,
            },
            text: rating,
            width: 'calc(50% - 66px)',
            hideBorder: true,
            padding: '0 10px 0 0',
            rowItemFlexProps: {
              justifyContent: 'end',
            },
          },
          state: {
            customElement: state,
            width: '36px',
            hideBorder: true,
            padding: '13px 10px 13px 0',
          },
          checkbox: {
            customElement: checkbox,
            width: '20px',
            hideBorder: true,
            padding: '10px 0',
          },
        },
        {
          type: {
            iconProps: {
              name: 'luggage',
            },
            text: type,
            width: '50%',
            hideBorder: true,
            padding: '10px 0 10px 10px',
          },
          roles: {
            customElement: (
              <Tooltip
                textColor={theme.color.general.light}
                text={allRolesString}
                right="5px"
                arrowPosition="right"
                padding="15px 15px"
              >
                {organizationRoles.map((role) => (
                  <Tag key={role} text={getRole(role)} />
                ))}
              </Tooltip>
            ),
            width: '50%',
            hideBorder: true,
            rowItemFlexProps: {
              justifyContent: 'end',
            },
            padding: '8px 10px 9px 0',
          },
        },
        {
          address: {
            text: address,
            width: '50%',
            hideBorder: !created_at,
            padding: '9px 0 10px 10px',
          },
          created_at: {
            iconProps: {
              name: 'at',
            },
            text: created_at,
            width: '50%',
            padding: '10px 10px 10px 10px',
          },
        },
      ]}
    />
  )
}

export default withTheme(OrganizationsTableCard)
