import { useEffect } from 'react'
import useWebSocket from 'react-use-websocket'
import { selectDataTypeObject, selectSidebarInitialData, selectUserToken } from '../redux/selectors'
import { useMappedState } from './index'
import { popupAlertShow, sidebarFetchById, sidebarItemSet } from '../redux/actions'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

const WS_BASE_URL = process.env.REACT_APP_API_URL.replace('https://', 'wss://').replace('http://', 'ws://')

export const useWebsockets = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['notifications', 'table'])

  const dataTypeObject = useMappedState(selectDataTypeObject)
  const type = dataTypeObject?.key
  const itemInitial = useMappedState(selectSidebarInitialData)

  const token = useMappedState(selectUserToken)
  const socketUrl = `${WS_BASE_URL}/ws/notifications?token=${token}`

  const { lastJsonMessage } = useWebSocket(
    socketUrl,
    {
      onOpen: () => console.log('opened'),
      //Will attempt to reconnect on all close events, such as server shutting down
      shouldReconnect: (closeEvent) => true,
    },
    !!token
  )

  useEffect(() => {
    if (lastJsonMessage) {
      if (lastJsonMessage.details.message === 'Parsing order items') {
        dispatch(
          popupAlertShow({
            contentKey: t('parsingOrderItems', { number: lastJsonMessage.details.number }),
            // type: 'success',
            withCloseButton: true,
            // iconName: 'info',
            timeout: 5000,
          })
        )
      }
      console.log(lastJsonMessage)

      if (
        type === 'orders' &&
        lastJsonMessage.notification_type === 'document_updated' &&
        lastJsonMessage.details.entity_type === 'Order' &&
        lastJsonMessage.details.entity_id === itemInitial.id
      ) {
        console.log('test')
        dispatch(sidebarFetchById(lastJsonMessage.details.entity_id, type))
      }

      if (lastJsonMessage.notification_type === 'document_updated') {
        dispatch(
          popupAlertShow({
            contentKey: t('yourOrderUploaded', { number: lastJsonMessage.details.number }),
            type: 'success',
            withCloseButton: true,
            iconName: 'checkRounded',
          })
        )
      }
    }
  }, [lastJsonMessage])
}
