import { takeLatest, put, select } from 'redux-saga/effects'
import Cookies from 'universal-cookie'

import {
  GET_CURRENT_USER,
  SIGN_IN,
  SIGN_IN_SUCCESS,
  SIGN_UP,
  SIGN_UP_SUCCESS,
  CONFIRM_EMAIL,
  CONFIRM_EMAIL_SUCCESS,
  TOKEN_REFRESH,
  REQUEST_RESET_PASSWORD,
  RESET_PASSWORD,
  GET_CURRENT_USER_SUCCESS,
  GET_CURRENT_USER_ERROR,
  GET_INVITE_DETAILS,
  GET_INVITE_DETAILS_SUCCESS,
  SIGN_OUT,
  POPUP_ALERT_SHOW,
  TOKEN_REFRESH_ERROR,
  SIGN_IN_ERROR,
  SIGN_UP_ERROR,
  SET_USER_ROLE,
  SET_USER_ROLE_SUCCESS,
  SET_USER_ROLE_ERROR,
  REQUEST_RESET_PASSWORD_SUCCESS,
  REQUEST_RESET_PASSWORD_ERROR,
} from '../constants'
import { buildRequest, fetchData, showError } from '../../api'
import { popupAlertShow, updateTableAndSystemData } from '../actions'
import { selectUserSystemParams } from '../selectors'

const cookies = new Cookies()

// !!! navigate and location args are for future version of react-router

function* doUserSignUp({ payload, navigate }) {
  const isInvite = payload.code

  try {
    const request = yield buildRequest({
      apiMethod: 'POST',
      type: isInvite ? 'inviteSignup' : 'signUp',
      requestBody: payload,
    })
    const response = yield fetchData(request)

    yield showError(response)

    if (isInvite) {
      navigate('/login')
    } else {
      yield put({ type: SIGN_UP_SUCCESS, email: response.email })
      navigate('/login')
      // navigate('/confirm-email')
      // console.log(`localhost:3000/login?code=${response.email_confirmation_code}`)
      yield put({
        type: POPUP_ALERT_SHOW,
        data: { contentKey: 'signUpSuccess', type: 'success', timeout: 10000 },
      })
    }
  } catch (error) {
    console.error(error)
    yield put({ type: SIGN_UP_ERROR })
  }
}

function doUserLogout() {
  if (cookies) {
    cookies.remove('tokenVal', { path: '/' })
    cookies.remove('tokenExp', { path: '/' })
  }
}

function* doGetCurrentUser({ navigate, location }) {
  try {
    const request = yield buildRequest({ apiMethod: 'GET', type: 'currentUser' })
    const response = yield fetchData(request)

    yield showError(response)

    yield put({ type: GET_CURRENT_USER_SUCCESS, payload: response })

    navigate(location ? `${location.pathname}${location.search}` : '/')

    if (response?.email_verified && !location) {
      navigate('/')
      // navigate(location ? `${location.pathname}${location.search}` : '/')
    } else if ('email_verified' in response && !response.email_verified) {
      navigate('/confirm-email')
      console.log(`localhost:3000/login?code=${response.email_confirmation_code}`)
    }

    return response?.email_verified
  } catch (error) {
    console.error(error)
    yield put({ type: GET_CURRENT_USER_ERROR })
    const { isAuthenticated, inProgress } = yield select(selectUserSystemParams)

    if (!isAuthenticated && !inProgress) {
      navigate('/login')
    }
  }
}

function* doUserSignIn({ payload, navigate }) {
  try {
    const request = yield buildRequest({ apiMethod: 'POST', type: 'signIn', requestBody: payload })
    const response = yield fetchData(request)

    yield showError(response)

    if (response.access_token) {
      yield put({ type: SIGN_IN_SUCCESS, token: response.access_token })
    }
    if (response.expires_in) {
      cookies.set('tokenExp', Date.now() + response.expires_in * 1000, { path: '/' })
    }
    if (response.refresh_token) {
      cookies.set('tokenVal', response.refresh_token, { path: '/', httpOnly: false })
    }

    yield doGetCurrentUser({ navigate })
  } catch (error) {
    console.error(error)
    yield put({ type: SIGN_IN_ERROR })
  }
}

export function* doRefreshToken() {
  try {
    const refreshTokenFromCookies = cookies.get('tokenVal', { path: '/' })

    if (!refreshTokenFromCookies) {
      return
    }

    const request = yield buildRequest({
      apiMethod: 'POST',
      type: 'refreshToken',
      requestBody: { refresh_token: refreshTokenFromCookies },
    })

    const response = yield fetchData(request)

    yield showError(response)

    if (response.access_token) {
      yield put({ type: SIGN_IN_SUCCESS, token: response.access_token })
    }
    if (response.expires_in) {
      cookies.set('tokenExp', Date.now() + response.expires_in * 1000, { path: '/' })
    }

    if (response.refresh_token) {
      cookies.set('tokenVal', response.refresh_token, { path: '/', httpOnly: false })
    }

    return response.access_token
  } catch (error) {
    console.error(error)
    yield put({ type: TOKEN_REFRESH_ERROR })
  }
}

function* doUserRequestResetPassword({ payload, navigate }) {
  try {
    const request = yield buildRequest({
      apiMethod: 'POST',
      type: 'requestResetPassword',
      requestBody: payload,
    })
    const response = yield fetchData(request)

    yield showError(response)

    if (navigate) {
      navigate('/password-request-sent')
    } else {
      yield put(
        popupAlertShow({
          contentKey: 'passRecoverSentText',
          contentParams: { email: payload.email },
          type: 'success',
          withCloseButton: true,
          iconName: 'mailSend',
        })
      )
    }
    yield put({ type: REQUEST_RESET_PASSWORD_SUCCESS })
  } catch (error) {
    console.error(error)
    yield put({ type: REQUEST_RESET_PASSWORD_ERROR })
  }
}

function* doUserResetPassword({ payload, navigate }) {
  try {
    const request = yield buildRequest({ apiMethod: 'POST', type: 'resetPassword', requestBody: payload })
    const response = yield fetchData(request)
    yield showError(response)

    navigate('/login')
  } catch (error) {
    console.error(error)
  }
}

function* doUserConfirm({ payload }) {
  try {
    const request = yield buildRequest({ apiMethod: 'POST', type: 'emailConfirm', requestBody: payload })
    const response = yield fetchData(request)

    yield showError(response)

    if (response.email_verified) {
      yield put({ type: CONFIRM_EMAIL_SUCCESS, status: response.status })
      yield put(
        popupAlertShow({
          contentKey: 'emailConfirmed',
          type: 'success',
          withCloseButton: true,
          iconName: 'doggySign',
        })
      )
    }
  } catch (error) {
    console.error(error)
  }
}

function* doGetInviteDetails({ payload: { code } }) {
  try {
    const request = yield buildRequest({
      apiMethod: 'POST',
      type: 'inviteDetails',
      requestBody: { code: code },
    })
    const response = yield fetchData(request)

    yield showError(response)

    yield put({
      type: GET_INVITE_DETAILS_SUCCESS,
      payload: {
        email: response.email,
        customer_name: response.customer_name,
      },
    })
  } catch (error) {
    console.error(error)
  }
}

function* doSetUserRole({ payload: { role, userId, withSuccessPopup } }) {
  try {
    const request = yield buildRequest({
      apiMethod: 'PATCH',
      type: 'userChangeRole',
      requestBody: { new_role: role, user_id: userId },
      apiUrlParam: userId,
    })
    const response = yield fetchData(request)

    yield showError(response)

    yield put({ type: SET_USER_ROLE_SUCCESS, item: response.values?.[0] })

    yield put(updateTableAndSystemData({ type: 'users', itemToSet: response.values?.[0], status: 'update' }))

    if (withSuccessPopup) {
      yield put({
        type: POPUP_ALERT_SHOW,
        data: { contentKey: 'dataSuccessfullySaved', type: 'success' },
      })
    }
  } catch (error) {
    console.error(error)
    yield put({ type: SET_USER_ROLE_ERROR, error })
  }
}

export default function* userSaga() {
  return [
    yield takeLatest(SIGN_IN, doUserSignIn),
    yield takeLatest(SIGN_UP, doUserSignUp),
    yield takeLatest(SIGN_OUT, doUserLogout),
    yield takeLatest(GET_CURRENT_USER, doGetCurrentUser),
    yield takeLatest(CONFIRM_EMAIL, doUserConfirm),
    yield takeLatest(REQUEST_RESET_PASSWORD, doUserRequestResetPassword),
    yield takeLatest(RESET_PASSWORD, doUserResetPassword),
    yield takeLatest(GET_INVITE_DETAILS, doGetInviteDetails),
    yield takeLatest(TOKEN_REFRESH, doRefreshToken),
    yield takeLatest(SET_USER_ROLE, doSetUserRole),
  ]
}
