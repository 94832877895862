import React from 'react'
import ReactTexty from 'react-texty'
import { InfoRowsBlock, Typography, Tag } from '@aidsupply/components'

import { useMappedState } from '../../../hooks'
import { selectUserRole } from '../../../redux/selectors'

const PagesTableCard = ({ className, data, initialData }) => {
  const { translations, state, link, organization_id, page_type } = data

  const userRole = useMappedState(selectUserRole)
  const isOrganizationVisible = userRole === 'administrator' || userRole === 'system'

  return (
    <InfoRowsBlock
      className={className}
      data={[
        {
          title: {
            customElement: <Typography type="body1" text={translations} />,
            width: 'calc(100% - 36px)',
            hideBorder: true,
            padding: '10px 0 10px 10px',
          },
          state: {
            text: state,
            width: '36px',
            padding: '10px 10px 10px 0',
          },
        },
        {
          link: {
            iconProps: {
              name: 'globe',
            },
            customElement: <Typography type="body1" as={ReactTexty} text={link} />,
            width: '50%',
            hideBorder: false,
            padding: '10px',
          },
          page_type: {
            iconProps: {
              name: 'fileCheck',
            },
            customElement: <Tag text={page_type} />,
            width: '50%',
            padding: '8px 10px',
          },
        },
        isOrganizationVisible && {
          organization: {
            iconProps: {
              name: 'warehouse',
            },
            text: organization_id,
            width: '100%',
          },
        },
      ].filter(Boolean)}
    />
  )
}

export default PagesTableCard
