import styled from 'styled-components'

export const StyledContainerUpload = styled.div`
  border-top: 1px solid ${({ theme }) => theme.bordersColor};
  padding: 20px 0;
`

export const StyledFilePanel = styled.div`
  display: flex;
  padding: 8px 12px 12px 12px;
  margin-top: 10px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border: 1px solid ${({ theme }) => theme.color.general.gray2};
  border-radius: 6px;
  box-shadow: 0px 3px 4px 0px rgba(24, 24, 28, 0.04), 0px 1px 1px 0px rgba(24, 24, 28, 0.04);
`

export const StyledFilePanelHeader = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 8px;

  .icon {
    margin-right: 8px;
  }
`
export const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  > div {
    display: flex;
  }

  .infoRowsBlock {
    width: 100%;
    margin-left: 10px;
  }
`
