import React from 'react'
import { Outlet } from 'react-router-dom'

import GridLayout from '../../Layout/GridLayout'
import TableBlock from '../../../components/Table/TableBlock'
import PagesTableCard from './PagesTableCard'
import { useMappedState } from '../../../hooks'
import { selectUserRole } from '../../../redux/selectors'

const Pages = () => {
  const userRole = useMappedState(selectUserRole)
  const isOrganizationVisible = userRole === 'administrator' || userRole === 'system'

  return (
    <GridLayout RightPanelComponent={<Outlet context={{}} />}>
      <TableBlock
        isMainTable
        TableCardContent={PagesTableCard}
        tableCardHeight={isOrganizationVisible ? 124 : 82}
        // selectable
      />
    </GridLayout>
  )
}

export default Pages
