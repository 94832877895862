import React from 'react'
import merge from 'deepmerge'
import { object, pick } from 'dot-object'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { slugify } from 'transliteration'

import { isObject } from '@aidsupply/components/build/utils'

import EditForm from '../../../components/RightPanelForms'
import { useMappedState } from '../../../hooks'
import { sidebarUpsert } from '../../../redux/actions'
import { selectSidebarInitialData, selectSidebarItemId } from '../../../redux/selectors'
import BlogTopicsRightPanel from './BlogTopicsRightPanel'

const BlogPostsRightPanel = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { rightPanelId } = useParams()

  const itemId = useMappedState(selectSidebarItemId)
  const itemInitial = useMappedState(selectSidebarInitialData)

  const onFormSubmit = (formValuesChanged) => {
    const requestBody = { ...object(formValuesChanged) }

    Object.keys(requestBody).map((key) => {
      if (isObject(requestBody[key]) && requestBody[key].id) {
        requestBody[key] = requestBody[key].id
      }
    })

    if (requestBody?.translations?.en) {
      requestBody.name = requestBody.translations.en
    }

    if (requestBody.translations) {
      requestBody.translations = merge(itemInitial.translations, requestBody.translations)

      if (formValuesChanged.translations?.en || !itemInitial.slug) {
        requestBody.slug = slugify(requestBody.translations.en)
      }
    }

    if (requestBody.body) requestBody.body = merge(itemInitial.body, requestBody.body)

    const dataToSend = {
      id: itemId,
      requestBody,
      type: 'posts',
      parentType: 'platform',
    }

    dispatch(sidebarUpsert(dataToSend, navigate))
  }

  if (rightPanelId === 'topics') {
    return <BlogTopicsRightPanel />
  }

  return <EditForm onFormSubmit={onFormSubmit} />
}

export default BlogPostsRightPanel
