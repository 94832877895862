import React from 'react'
import ReactTexty from 'react-texty'
import { InfoRowsBlock, Typography, Tag } from '@aidsupply/components'

const AttributesTableCard = ({ className, data, initialData }) => {
  const { state, translations, attribute_type, enumeration_id, unit_id } = data

  return (
    <InfoRowsBlock
      className={className}
      data={[
        {
          title: {
            customElement: <Typography as={ReactTexty} type="body1" text={translations} />,
            width: 'calc(100% - 36px)',
            hideBorder: true,
            padding: '12px 0 12px 10px',
          },
          state: {
            text: state,
            width: '36px',
            padding: '12px 10px 12px 0',
          },
        },
        {
          attribute_type: {
            iconProps: {
              name: 'listBulleted',
            },
            customElement: <Tag text={attribute_type} />,
            width: '50%',
            hideBorder: false,
            padding: '10px',
          },
          ...(initialData.attribute_type === 'enum' && {
            enumeration_id: {
              iconProps: {
                name: 'listNumber',
              },
              text: enumeration_id,
              width: '50%',
              hideBorder: true,
            },
          }),
          ...(initialData.attribute_type === 'numeric' && {
            unit_id: {
              iconProps: {
                name: 'ruler',
              },
              text: unit_id,
              width: '50%',
              hideBorder: true,
            },
          }),
        },
      ].filter(Boolean)}
    />
  )
}

export default AttributesTableCard
