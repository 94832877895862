export default {
  errorPage: {
    goHome: 'Go home',
    letUsKnow: 'Let us know',
    needHelp: 'Need Help?',
    somethingsMissing: "Oops! Something's missing",
  },
  footer: {
    allRightsReserved: 'All rights are reserved',
    Feedback: 'Feedback',
  },
  forms: {
    aboutSection: 'About section',
    account: 'Account',
    accountNumber: 'Account number',
    admin: 'Admin',
    addToExistingItems: 'Add to existing items',
    amount: 'Amount',
    answer: 'Answer',
    assets: 'Assets',
    author: 'Author',
    attributes: 'Attributes',
    backgroundColor: 'Background color',
    backgroundIsDark: 'Background is dark, text is light',
    bank: 'Bank',
    bannerCategory: 'Category (choose only if the banner is for a category)',
    banners: 'Banners',
    bestsellers: 'Bestsellers',
    brandDescription: 'Brand Description',
    brandPageHeaderPhrase: 'Brand Page Header Phrase',
    brands: 'Brands',
    brandSlogan: 'Brand Slogan',
    brandSloganAuthor: 'Brand Slogan Author',
    bucketMonobank: 'Monobank jar id',
    bucketWayforpay: 'Wayforpay page name in url',
    carriers: 'Carriers',
    cart: 'Cart',
    categories: 'Categories',
    categoriesBig: 'Categories (big tiles)',
    categoriesRecommended: 'Recommended categories (small)',
    changePassword: 'Change password',
    characteristics: 'Characteristics',
    city: 'City',
    color: 'Color',
    colors: 'Colors',
    comment: 'Comment',
    comments: 'Comments',
    components: 'Components',
    content: 'Content',
    counterparties: 'Counterparties',
    cover: 'Cover',
    coverVideo: 'Cover Video',
    create: 'Create',
    currencies: 'Currencies',
    currentAmount: 'Current amount',
    custom: 'Custom',
    customerFullName: 'Customer Full Name',
    dark: 'Dark',
    darker: 'Darker',
    darkest: 'Darkest',
    dateModified: 'Date modified',
    de: 'German',
    deadline: 'Deadline',
    defaultLanguage: 'Default language',
    deliveryOptions: 'Delivery options',
    description: 'Description',
    dimensions: 'Dimensions',
    district: 'District',
    details: 'Details',
    document: 'Document',
    dropOrSelect: 'Drop or Select files from storage',
    edit: 'Edit',
    en: 'English',
    error: 'Error',
    externalNumber: 'Incoming document number',
    faqSection: 'FAQ section',
    favicon: 'Favicon',
    featuredProducts: 'Featured Products',
    feedbackSection: 'Feedback section',
    files: 'Files',
    fillGeneralAndSaveBeforeUploadingFile:
      'Please fill out all the required fields and hit the Save button before uploading the file',
    fillGeneralAndSaveBeforeUploadingPhotos:
      'Please fill out all the required fields and hit the Save button before uploading the photos',
    financialInfo: 'Financial information',
    firstPageImageIsBackground: 'First-page image is the background',
    firstPageText: 'First page text',
    firstPageWithOverlay: 'First page with an overlay',
    firstScreen: 'First screen',
    firstScreenMobile: 'First screen mobile',
    font: 'Font',
    from: 'from',
    fundraisingAccounts: 'Fundraising acounts',
    general: 'General',
    generalInfo: 'General information',
    generateModelNumber: 'Generate number instead',
    generateNewOrganization: 'Generate new organization',
    goalAmount: 'Goal amount',
    gray1: 'Gray1',
    gray2: 'Gray2',
    gray3: 'Gray3',
    gray4: 'Gray4',
    gray5: 'Gray5',
    gray6: 'Gray6',
    gray7: 'Gray7',
    header: 'Header',
    history: 'History',
    id: 'Indonesian',
    info: 'Information',
    introductionVideo: 'Introduction video',
    introSection: 'Intro section',
    inventoryDetails: 'Inventory details',
    isCharacteristic: 'Is a Characteristic',
    isComingSoon: 'Is coming soon page',
    isCrm: 'Is CRM page',
    isFragile: 'Is Fragile',
    isPhotoGroup: 'Is Photo Group',
    isUsedForInquiries: 'Is used for Inquiries/Demands',
    items: 'Items',
    key: 'Key',
    keywords: 'Keywords',
    label: 'Label',
    languages: 'Languages',
    light: 'Light',
    lighter: 'Lighter',
    lightest: 'Lightest',
    linkToPageText: 'Link to access the page text',
    logos: 'Logos',
    main: 'Main',
    media: 'Media',
    merchants: 'Merchants',
    miniLogos: 'Mini logos',
    mobile: 'For mobile',
    modifications: 'Modifications',
    name: 'Name',
    nameInEnglish: 'Name in English',
    inquiry_items: 'Product categories',
    new: 'New',
    no: 'No',
    noOptionsFound: 'No options found',
    oneFileAllowed: 'Only one active file is allowed',
    optionIconsUploadIsAllowed: 'Option icons upload is allowed',
    orderInfo: 'Order Info',
    organizationFromSignup: 'Organization from signup form',
    organizations: 'Organizations',
    organizationEmail: 'Organization email',
    organizationType: 'Organization type',
    organizationWebsite: 'Organization website',
    myOrganization: 'My Organization',
    overlay: 'Overlay',
    page404: 'Page 404',
    pageContent: 'Page content',
    password: 'Password',
    phoneNumber: 'Phone number',
    photos: 'Photos',
    pickup: 'Self-pickup',
    pickupAddress: 'Pickup address',
    pl: 'Polish',
    platformConstructor: 'Platform constructor',
    platformIsComingSoon: 'The platform is coming soon',
    postTopics: 'Post topics',
    primary: 'Primary',
    primaryPhoto: 'Primary',
    products: 'Products',
    productsDescription: 'Products Description',
    productsTitle: 'Products Title',
    profilePicture: 'Profile picture',
    properties: 'Properties',
    recipient: 'Recipient',
    requiresCooling: 'Requires cooling',
    requiresFreezing: 'Requires freezing',
    registrationNumber: 'Registration number',
    repeatPassword: 'Repeat password',
    replaceExistingItems: 'Replace existing items',
    ru: 'Russian',
    scrollDownForModifications: 'Click to see item modifications with codes below',
    search: 'Search',
    secondary: 'Secondary',
    secondPageText: 'Second-page text',
    secondScreen: 'Second screen',
    seo: 'SEO Optimization',
    sharingImage: 'Sharing image',
    shipmentAddress: 'Shipment address',
    shippingDetails: 'Shipping details',
    shortUrl: 'Short url',
    showMore: 'Show more',
    slides: 'Slides',
    socialNetworks: 'Social media',
    socialProfiles: 'Social profiles',
    sorting: 'Sorting',
    square: 'Square',
    state: 'State',
    status: 'Status',
    subtotal: 'Subtotal',
    success: 'Success',
    systemNameInEnglish: 'Name in English',
    tax: 'Tax',
    taxRate: 'Tax rate',
    team: 'Team',
    teamDescription: 'Team Description',
    teamSlogan: 'Team Slogan',
    textForEveryItem: 'Text for every item',
    texts: 'Texts',
    title: 'Title',
    tokens: 'Tokens',
    topics: 'Topics',
    translations: 'Translations',
    types: 'Types',
    uk: 'Ukrainian',
    uploadFailed: 'Upload failed',
    uploadInProgress: 'Upload is in progress. You will receive a notification when it ends.',
    values: 'Values',
    viewProfile: 'View profile',
    videoPreviewImage: 'Video preview image',
    videos: 'Videos',
    videoSection: 'Video section',
    videoUrl: 'Video URL',
    warning: 'Warning',
    wayforpayURL: 'Wayforpay URL',
    wayforpayAccount: 'Wayforpay merchant account',
    wayforpayKey: 'Wayforpay secret key',
    website: 'Web-site',
    weight: 'Weight',
    weightUnit: 'Weight unit',
    yearFounded: 'Year founded',
    yes: 'Yes',
  },
  general: {
    isIconsUploadAllowed: 'Option icons upload is allowed',
    fileUpload: 'File upload',
    optimalDimensions: 'Optimal dimensions 1920 x 210px',
    pageNotFound: 'Page not found',
    profileImageTip: 'Upload your profile photo, recommended image size is 512x512px',
    profilePicture: 'Profile picture',
    replaceBannerImage: 'Replace Banner Image',
    replaceImage: 'Replace Image',
    update: 'Update',
    upload: 'Upload',
    template: 'Template',
  },
  header: {
    Profile: 'Profile',
    quickMenu: 'Quick Menu',
    searchBar: 'Search Bar',
    Settings: 'Settings',
  },
  loadingOrNoData: {
    brandsHeader: 'No Brands were added yet',
    itemsHeader: 'No Items were added yet',
    outfitsHeader: 'No Outfits were added yet',
    addFirstItem: 'Add {{itemType}}',
    addTopic: 'Add topic',
    attribute: 'attribute',
    banner: 'banner',
    brand: 'brand',
    campaign: 'campaign',
    carrier: 'carrier',
    category: 'category',
    client: 'client',
    contract: 'contract',
    counterparty: 'counterparty',
    demand: 'demand',
    enumeration: 'enumeration',
    FAQ: 'question',
    inquiry: 'inquiry',
    invoice: 'invoice',
    item: 'item',
    loading: 'Loading...',
    merchant: 'merchant',
    noDataFound: 'No Data Found',
    order: 'order',
    organization: 'Organization',
    outfit: 'outfit',
    page: 'page',
    platform: 'platform',
    report: 'report',
    blogPost: 'blog post',
    serverError: '500 Internal Server Error',
    shipment: 'shipment',
    warehouse: 'warehouse',
    'stock-item': 'stock item',
    unit: 'unit',
    user: 'user',
  },
  menu: {
    address: 'Address',
    admin: 'Administration',
    attributes: 'Attributes',
    banners: 'Banners',
    brands: 'Brands',
    campaigns: 'Campaigns',
    carriers: 'Carriers',
    categories: 'Categories',
    clients: 'Clients',
    contracts: 'Contracts',
    counterparties: 'Counterparties',
    dashboard: 'Dashboard',
    documents: 'Documents',
    enumerations: 'Enumerations',
    faqs: 'FAQs',
    Help: 'Help',
    inquiries: 'Inquiries',
    inventories: 'Inventories',
    inventory: 'Inventory',
    invoices: 'Invoices',
    items: 'Items',
    Logout: 'Logout',
    merchant: 'Merchant',
    merchants: 'Merchants',
    inquiry_items: 'Product categories',
    new: 'new',
    notifications: 'Notifications',
    options: 'Options',
    orders: 'Orders',
    organizations: 'Organizations',
    myOrganization: 'My Organization',
    outfits: 'Outfits',
    pages: 'Pages',
    reports: 'Reports',
    platform: 'Platform',
    blogPosts: 'Blog posts',
    products: 'Products',
    Search: 'Search',
    Settings: 'Settings',
    shipments: 'Shipments',
    'stock-items': 'Stock Items',
    warehouses: 'Warehouses',
    system: 'System',
    units: 'Units',
    user: 'User',
    users: 'Users',
  },
  notifications: {
    attributes: 'Attribute was {{status}}',
    banners: 'Banner was {{status}}',
    brands: 'Brand was {{status}}',
    bulkStatusesUpdated: '{{count}} {{type}} were {{status}}',
    carriers: 'Carrier was {{status}}',
    categories: 'Category was {{status}}',
    clearNotifications: 'Clear notifications',
    clients: 'Client was {{status}}',
    contracts: 'Contract was {{status}}',
    counterparties: 'Counterparty was {{status}}',
    created: 'created',
    enumerations: 'Enumeration was {{status}}',
    faqs: 'FAQ was {{status}}',
    inquiries: 'Inquiry was {{status}}',
    invoices: 'Invoice was {{status}}',
    items: 'Item was {{status}}',
    merchants: 'Merchant was {{status}}',
    noNotifications: 'No new messages',
    Notifications: 'Notifications',
    orders: 'Order #{{id}} was {{status}}',
    ordersBulk: 'orders',
    organizations: 'Organization was {{status}}',
    pages: 'Page was {{status}}',
    parsingOrderItems: 'Parsing order is in progress',
    platforms: 'Platform was {{status}}',
    shipments: 'Shipment was {{status}}',
    'stock-items': 'Stock Item was {{status}}',
    'stock-itemsBulk': 'stock items',
    warehouses: 'Warehouse was {{status}}',
    units: 'Unit was {{status}}',
    updated: 'updated',
    uploadItems: 'Use this template to upload your items.',
    users: 'User was {{status}}',
    yourOrderUploaded: 'Your order #{{number}} uploaded successfully',
  },
  orderButtons: {
    invoice: 'Invoice',
    shipment: 'Shipment',
  },
  popupAlerts: {
    dataSuccessfullySaved: 'Data was successfully saved!',
    errorInSavingData: 'There was an error in saving data',
    fillModsError: 'There was an error in filling product modifications',
    IDisNotCorrect: 'ID in url is not correct',
    loginError: 'Incorrect username or password',
    loginSocialError: 'Unexpected error happened',
    passRecoverSentText: 'We have sent а password reset link to your email.',
    signUpSuccess:
      'You were successfully registered!\nWe will send you an email as soon as the account is confirmed.',
    'User is not confirmed.': 'The user account is not active. Please contact the admin',
  },
  productsForm: {
    address: 'Address',
    basketEmpty: 'Basket is empty',
    cart: 'Cart',
    city: 'City',
    contacts: 'Contacts',
    country: 'Country',
    name: 'Name',
    no: 'No',
    orderFrom: 'Order from warehouse',
    outOfStock: 'Out of stock',
    phone: 'Phone',
    preview: 'Preview',
    submitOrder: 'Submit order',
    summary: 'Summary',
    total: 'Total',
    yes: 'Yes',
  },
  signIn: {
    alreadyHaveAccount: 'Already have an account?',
    backTo: 'Back to ',
    checkYourMailbox: 'Check your mailbox',
    createAccount: 'Create your account!',
    createAccountAndUse: 'Create your account and start using it',
    confirmYourEmail: 'Confirm your email',
    continueGoogle: 'Sign in with Google',
    dontHaveAccount: "Don't have an account?",
    fillTheFormToProceed: '',
    firstName: 'First Name',
    forgotPassword: 'Forgot your password?',
    inUkraine: 'in Ukraine',
    lastName: 'Last Name',
    legalPagesInscription: 'By clicking "Sign up" you agree that you have read and agree to',
    or: 'or',
    orLoginWith: 'Or login with',
    passRecoverSentText:
      'We have sent you instructions on how to recover your password. Also check your spam folder if you can’t find our email!',
    passRecoverText: 'Enter your email below to receive password reset instructions.',
    passwordRecovery: 'Password recovery',
    recoverPassword: 'Recover password',
    registerWith: 'Register with',
    rememberMe: 'Remember me',
    requestText: 'To use GettStyle, please confirm your email address first. We have sent a link to',
    requestText1: '. Check the spam folder if the email does not arrive.',
    signIn: 'Sign In',
    signInToContinue: 'Sign in to continue',
    signUp: 'Sign Up',
    termsAgree: 'I accept Terms and Conditions',
    toSignIn: 'Sign in',
    welcome: 'Welcome!',
  },
  statuses: {
    open_demands: 'New',
    in_progress_demands: 'In progress',
    closed_demands: 'Closed',
    total_demands: 'Total',
  },
  table: {
    actions: 'Actions',
    activity: 'Activity',
    add: 'Add',
    address: 'Address',
    availability: 'Availability',
    banner: 'Banner',
    block: 'Block',
    brand: 'Brand',
    campaign: 'Campaign',
    carrier: 'Carrier',
    categories: 'Categories',
    category: 'Category',
    city: 'City',
    changeStateTo: 'Change state to:',
    changeStatusTo: 'Change status to:',
    characteristics: 'Characteristics',
    clearFilters: 'Clear filters',
    client: 'Client',
    closed_demands: 'Closed',
    code: 'Code',
    color: 'Color',
    copy: 'Copy',
    counterparty: 'Counterparty',
    country: 'Country',
    created: 'Created',
    currency: 'Currency',
    currentSum: 'Current sum',
    currentPassword: 'Current password',
    customer: 'Customer',
    date: 'Date',
    dateCreated: 'Date created',
    deadline_at: 'Deadline',
    delete: 'Delete',
    deleted: 'Deleted',
    discount: 'Discount, %',
    donor: 'Donor',
    drafted: 'Draft',
    email: 'E-mail',
    enumeration: 'Enumeration',
    file: 'File',
    filters: 'Filters',
    firstName: 'First Name',
    from: 'from',
    fullName: 'Full name',
    goal: 'Goal',
    goalSum: 'Goal sum',
    heightM: 'Height, m',
    industries: 'Industries',
    industry: 'Industry',
    inquiry: 'Inquiry',
    inquiryNumber: 'Inquiry #',
    invoiceNumber: 'Invoice #',
    inventoryNumber: 'Inventory #',
    in_progress_demands: 'In progress',
    issuer: 'Issuer',
    item: 'Item',
    items: 'Items',
    label: 'Label',
    language: 'Language',
    lastActivity: 'Last activity',
    lastLoggedIn: 'Last seen',
    lastName: 'Last Name',
    lastUpdated: 'Last updated at',
    lengthM: 'Length, m',
    linkToPage: 'Link to page',
    magnitude: 'Magnitude',
    merchant: 'Merchant',
    mobile: 'Mobile',
    model: 'Model',
    month: 'Month',
    name: 'Name',
    newPassword: 'New password',
    newTopic: 'New topic',
    noItems: 'No items found',
    open_demands: 'New',
    options: 'Options',
    order: 'Order',
    orderNumber: 'Order #',
    organization: 'Organization',
    outOfStock: 'Out of stock',
    page: 'Page',
    paid: 'Paid',
    parentCategory: 'Parent category',
    payer: 'Payer',
    pcs: 'pcs.',
    phone: 'Phone',
    platform: 'Platform',
    platforms: 'Platforms',
    posted: 'Posted',
    price: 'Price',
    price_retail: 'Price retail',
    price_sale: 'Price with discount',
    provider: 'Provider',
    reset: 'Reset',
    quantity: 'Q-ty',
    question: 'Question',
    rank: 'Rank',
    ratio: 'Ratio',
    received: 'Received',
    recipient: 'Recipient',
    region: 'Region',
    retail: 'Retail',
    role: 'Role',
    roles: 'Roles',
    saveModifications: 'Save Modifications',
    shipmentNumber: 'Shipment #',
    size: 'Size',
    state: 'State',
    status: 'Status',
    supplier: 'Supplier',
    systemLanguage: 'System language',
    warehouse: 'Warehouse',
    'stock-item': 'Stock item',
    sum: 'Sum',
    summary: 'Summary',
    systemName: 'System name',
    tags: 'Tags',
    title: 'Title',
    topic: 'Topic',
    total: 'Total',
    translation: 'Translation',
    translations: 'Translations',
    type: 'Type',
    unit: 'Unit',
    url: 'URL',
    user: 'User',
    visibility: 'Visibility',
    weightKg: 'Weight, kg',
    widthM: 'Width, m',
    website: 'Web-site',
    wholesale: 'Wholesale',
    year: 'Year',
  },
  validation: {
    capital: 'Uppercase',
    defaultTranslationRequired: "Default translation can't be empty",
    email: 'Incorrect email format',
    lowercase: 'Lowercase',
    minLength: '8 Characters',
    number: 'Digit',
    modelAlreadyExists: 'Such model already exists',
    nameAlreadyExists: 'Such name already exists',
    onlyDigits: 'This field can have only digits',
    required: "The field can't be empty",
    requiredIfFieldsEmpty: 'Fill in one of these fields: {{fields}}',
    requiredIfFieldsNotEmpty: 'This field is required',
    password: 'Your password does not meet the requirements',
    passwordsNotMatch: 'Passwords do not match',
    specialChar: 'Special character',
    urlAlreadyExists: 'Such URL already exists',
    year: 'The year must be in the range from 1900 to the current year',
  },
}
