export const selectDataTypeObject = (state) => state.data.type
export const selectDataType = (state) => state.data.type.key

export const selectDataByType = (type) => (state) => {
  return Array.isArray(type)
    ? type.reduce((acc, type) => {
        acc[type] = state.data[type]
        return acc
      }, {})
    : state.data[type]
}
export const selectDataInProgressAndError = (state) => ({
  inProgress: state.data.inProgress,
  error: state.data.error,
  bulkUpdateInProgress: state.data.bulkUpdateInProgress,
  systemInProgress: state.data.systemInProgress,
  isSystemDataLoaded: state.data.isSystemDataLoaded,
})
export const selectTableDataCount = (state) => state.data.total_items
export const selectMainDataLoading = (state) => state.data.inProgress
export const selectMainDataInitialized = (state) => state.data.isDataInitialized

export const selectApiUrlParam = (state) => state.data.apiUrlParam
export const selectCurrentApiUrlParams = (state) => state.data.apiUrlParamsCurrent
export const selectAllSystemCollections = (state) => state.data.system
export const selectSystemCollections = (types) => (state) => {
  return Array.isArray(types)
    ? types.reduce((acc, type) => {
        acc[type] = state.data.system[type]
        return acc
      }, {})
    : state.data.system[types]
}

export const selectFiltersState = (state) => state.filters
export const selectAreFiltersChosen = (state) => !!state.filters.labels.length
export const selectFiltersPanelOpened = (state) => state.filters.filtersPanelOpened

export const selectUserState = (state) => state.user
export const selectUserDetails = (state) => ({ ...state.user.details, token: state.user.token })
export const selectCurrentUserId = (state) => state.user.details?.id
export const selectUserRole = (state) => state.user.details.role
export const selectUserOrganizationId = (state) => state.user.details.organization_id
export const selectUserToken = (state) => state.user.token
export const selectUserInviteDetails = (state) => state.user.inviteDetails
export const selectUserSystemParams = (state) => ({
  isAuthenticated: state.user.isAuthenticated,
  isAppInitialized: state.user.isAppInitialized,
  inProgress: state.user.inProgress,
})
export const selectUserInProgressAndError = (state) => ({
  inProgress: state.user.inProgress,
  error: state.user.error,
})

export const selectSidebarState = (state) => state.sidebar
export const selectSidebarInitialData = (state) => state.sidebar.initial
export const selectSidebarItemId = (state) => state.sidebar.initial?.id
export const selectSidebarItemPrimaryPhotoId = (photoKey) => (state) =>
  state.sidebar.initial?.[photoKey || 'photo_id']

export const selectSidebarCommentsData = (state) => state.sidebar.initial?.comments
export const selectSidebarUpsertInProgress = (state) =>
  state.sidebar.upsertInProgress || state.sidebar.sidebarUpsertInProgress
export const selectRightPanelInProgress = (state) => state.sidebar.upsertInProgress || state.data.inProgress
export const selectSidebarItemInProgress = (state) => state.sidebar.itemInProgress
export const selectSidebarIsCopy = (state) => state.sidebar.isCopy

export const selectBasketItems = (state) => state.basket.items

export const selectPopupAlerts = (state) => state.common.popupAlerts
export const selectIsAnyPopupAlertShown = (state) => !!state.common.popupAlerts.length
export const selectNotifications = (state) => state.common.notifications
export const selectIsNavMenuExtended = (state) => state.common.isNavMenuExtended
export const selectIsRightPanelExtended = (state) => state.common.isRightPanelExtended

export const selectFileUploadState = (state) => state.fileUpload
export const selectIsCurrentFilesInDraft = (state) => {
  const currentFiles = state.fileUpload.files
  return !!Object.values(currentFiles)?.length && Object.values(currentFiles).some((file) => file?.length)
}

export const selectFormByType = (type) => (state) => state.forms[type]

export const selectBasketItemsCount = (state) => state.basket.items.length
