import styled from 'styled-components'
import { Menu, UiLink } from '@aidsupply/components'

export const StyledNotificationItem = styled.div`
  margin: 10px 0;
  border-bottom: 1px solid ${({ theme: { color } }) => color.general.gray1};
  display: flex;
  justify-content: space-between;
  padding: 15px;

  &:nth-child(2) {
    margin-top: 0;
  }

  &:last-child {
    border-bottom: none;
    margin-bottom: 0;
  }

  &.new {
    border-bottom: none;
    border-radius: ${({ theme }) => theme.size.border.radius.main};
    background-color: ${({ theme: { color } }) => color.general.gray1};
  }

  a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .icon {
    margin-right: 10px;
    flex: 0 0 auto;
    margin-left: 0;

    //&::after {
    //  content: '';
    //  background-color: #ffffff80;
    //  border-radius: 50%;
    //  width: 23px;
    //  height: 23px;
    //  position: absolute;
    //  filter: drop-shadow(0px 2px 5px rgba(0, 0, 0, 0.3));
    //}
    //
    //svg {
    //  z-index: 10;
    //}
  }

  .content {
    flex-grow: 1;

    .firstRow {
      padding-bottom: 2px;
    }
  }

  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.info {
      padding-bottom: 5px;
    }

    .label {
      &:first-child {
        margin-right: 10px;
      }
    }
  }

  .time {
    margin-left: 10px;
    white-space: nowrap;
  }
`

export const StyledNotificationsMenu = styled(Menu)`
  .menuList {
    max-height: 65vh;
    overflow: auto;
    padding: 0 20px 20px;
  }

  .menuItem {
    display: block;
    padding: 0;
  }

  .navbarNotificationsLabel {
    text-align: center;
  }

  .navbarNotificationsBadge {
    margin-top: 6px;

    span {
      padding: 2px 6px;
      font-size: ${({ theme }) => theme.font.size.button1.value};
      line-height: ${({ theme }) => theme.font.size.button1.lineHeight};
      font-weight: ${({ theme }) => theme.font.weight.black};
      height: auto;
      width: max-content;
      text-transform: uppercase;
      border-radius: ${({ theme }) => theme.size.border.radius.smaller};
    }
  }

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    .menuList {
      transform: translate(-10px, -70%);
    }
  }
`

export const StyledNotificationsHeader = styled.div`
  background-color: ${({ theme }) => theme.color.general.light};
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
  position: sticky;
  top: 0;
  z-index: 10;

  .navbarNotifications & {
    padding-bottom: 10px;
  }

  .clearAll {
    cursor: pointer;
  }

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
    .empty & {
      margin-bottom: 20vh;
    }
  }
`

export const StyledNotificationsPanel = styled.div`
  border: 1px solid ${({ theme }) => theme.bordersColor};
  border-radius: ${({ theme }) => theme.size.border.radius.main};
  overflow: auto;
  padding: 0 20px 20px;
  margin-right: 20px;
  max-height: calc(100vh - 60px);
  flex-basis: 35%;
  position: sticky;
  top: 0;

  .panel {
    padding: 10px;
    margin-bottom: 15px;
    .icon {
      margin-right: 5px;
    }
    .date {
      color: ${({ theme }) => theme.color.general.gray3};
    }
  }

  @media only screen and (max-width: 890px) {
    flex-basis: 50%;
  }

  &.empty {
    height: calc(100vh - 60px);
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    &.empty {
      height: calc(100vh - 75px - ${({ theme }) => theme.size.height.header.mobile});
    }
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    margin-bottom: 30px;
  }
`

export const StyledEmptyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  // @media only screen and (min-width: ${({ theme }) => theme.breakpoints.xs}px) {
  //   height: 100%;
  // }
`

export const StyledEmpty = styled.div`
  padding-top: 20px;
  text-align: center;
  padding-left: 10px;
  padding-bottom: 10px;
`
