import React, { useEffect, useState } from 'react'
import { object, pick } from 'dot-object'
import { Button, FlexRow, Icon, isObject, TextPanel, Typography, Select } from '@aidsupply/components'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { fileParse, sidebarItemSet, sidebarPostComment, sidebarUpsert } from '../../../redux/actions'
import {
  selectAllSystemCollections,
  selectSidebarInitialData,
  selectSidebarUpsertInProgress,
  selectUserDetails,
} from '../../../redux/selectors'
import { useMappedState } from '../../../hooks'
import Form from '../../../components/Form'
import { FORM_CONFIGS } from '../../../config/forms'
import OrderFormActivity from '../../../components/OrderForm'
import InfoPanel from '../../../components/InfoPanel/InfoPanel'
import { useTranslation } from 'react-i18next'
import { FILE_TYPES } from '../../../constants'
import UploadFiles from '../../../components/UploadFiles'
import { EDITABLE_TABLE_NAVBAR_FORM_CONFIG } from '../../../config/editableTableNavbarForms'
import EditableTableFormPart from '../../../components/RightPanelForms/EditableTableFormPart'
import { getEditableTableColumns } from '../../../config/columnsEditableTable'
import OrderNextStepsButtons from '../../../components/OrderForm/OrderNextStepsButtons'
import OrdersTableCard from './OrdersTableCard'
import CardTableItem from '@aidsupply/components/build/molecules/CardTableItem'
import { getColumns } from '../../../config/columns'
import { withTheme } from 'styled-components'

const FormBlockWrapper = withTheme(
  ({
    customBlockValues,
    optionsData,
    children,
    blockKey,
    fields,
    formFieldsProps,
    formValues,
    formValuesChanged,
    getPanelHeader,
    id,
    isReadOnly,
    setTextsChosenLng,
    theme,
    updateInput,
    updateSelect,
  }) => {
    const dispatch = useDispatch()
    const {
      t,
      i18n: { language },
    } = useTranslation(['table', 'forms', 'general'])

    const params = useParams()
    const { rightPanelId } = params

    const system = useMappedState(selectAllSystemCollections)
    const user = useMappedState(selectUserDetails)

    const editableTableColumns = getEditableTableColumns('orders.order_items', language, t)

    const editableTableConfig = EDITABLE_TABLE_NAVBAR_FORM_CONFIG['orders.order_items']

    const additionalFormValues = editableTableConfig?.optionsFromValuesDependencies?.reduce(
      (acc, curr) => ({ ...acc, [curr]: pick(curr, formValues) }),
      {}
    )

    const [uploadType, setUploadType] = useState('file') // file, table
    const [replaceOption, setReplaceOption] = useState({ id: 'add', label: t('forms:addToExistingItems') }) // 'add' or 'replace'

    useEffect(() => {
      setReplaceOption({ id: 'add', label: t('forms:addToExistingItems') })
    }, [formValues.id])

    useEffect(() => {
      if (rightPanelId === 'copy') {
        setUploadType('table')
      }
    }, [rightPanelId])

    const onFileDrop = (newFiles) => {
      let is_replace_existing = false
      if (replaceOption.id === 'replace') {
        is_replace_existing = true
      }
      dispatch(fileParse('ordersParseFile', newFiles[0], id, is_replace_existing))
      dispatch(sidebarItemSet({ ...formValues, is_being_processed: true }))
    }

    const columns = getColumns('orders', language, t)
    return (
      <>
        {blockKey === 'noTitle' && (
          <>
            <CardTableItem
              data={formValues}
              CardContent={OrdersTableCard}
              cellProps={{
                system,
                custom_block_values: customBlockValues,
                lng: language,
                id,
                user,
                is_read_only: isReadOnly,
                additional_form_values: additionalFormValues,
                container: { props: { cellProps: { system, lng: language } } },
              }}
              index={formValues.id}
              columns={columns}
              rowIndex={formValues.id}
              rowKey={formValues.id}
              t={t}
              theme={theme}
              type="orders"
            />
            {formValues.id && formValues.state === 'posted' && (
              <OrderNextStepsButtons formValues={formValues} />
            )}
          </>
        )}

        {blockKey === 'comments' && (
          <OrderFormActivity updateInput={updateInput} formValuesChanged={formValuesChanged} />
        )}

        {blockKey === 'order_items' && !id && rightPanelId !== 'copy' && (
          <InfoPanel text={t('forms:fillGeneralAndSaveBeforeUploadingFile')} />
        )}

        {blockKey === 'order_items' && (id || rightPanelId === 'copy') && (
          <>
            {!isReadOnly && rightPanelId !== 'copy' && (
              <FlexRow gap="10px" margin="-5px 0 12px 0">
                <Button
                  size="small"
                  padding="2px 12px"
                  variant={uploadType === 'file' ? 'primary' : 'bordered'}
                  fullWidth
                  onClick={() => {
                    setUploadType('file')
                  }}
                >
                  <Icon
                    name="upload"
                    fill={uploadType === 'file' ? 'white' : 'black'}
                    height={26}
                    width={26}
                    wrapperHeight={32}
                    wrapperWidth={32}
                  />
                  <Typography text={t('file')} color={uploadType === 'file' ? 'white' : 'black'} />
                </Button>
                <Button
                  size="small"
                  padding="2px 12px"
                  variant={uploadType === 'table' ? 'primary' : 'bordered'}
                  fullWidth
                  onClick={() => {
                    setUploadType('table')
                  }}
                >
                  <Icon
                    name="plus2"
                    fill={uploadType === 'table' ? 'white' : 'black'}
                    height={26}
                    width={26}
                    wrapperHeight={32}
                    wrapperWidth={32}
                  />
                  <Typography text={t('add')} color={uploadType === 'table' ? 'white' : 'black'} />
                </Button>
              </FlexRow>
            )}
            {uploadType === 'file' &&
              formValues.order_items?.length > 0 &&
              formValues.state === 'drafted' && (
                <Select
                  value={replaceOption}
                  onChange={(option) => setReplaceOption(option)}
                  options={[
                    { id: 'add', label: t('forms:addToExistingItems') },
                    { id: 'replace', label: t('forms:replaceExistingItems') },
                  ]}
                  withBorder
                />
              )}

            {uploadType === 'file' && formValues.state !== 'posted' && (
              <UploadFiles
                style={{ marginTop: 10 }}
                entityType="orders"
                entityId={id}
                fileGroup="order_items"
                fileTypes={{ 'application/vnd.ms-excel': FILE_TYPES.files['application/vnd.ms-excel'] }}
                filesType="files"
                onFnSet={onFileDrop}
                isUploadInProgress={formValues.is_being_processed}
                maxFiles={1}
                isReadOnly
              />
            )}

            <EditableTableFormPart
              additionalFormValues={additionalFormValues}
              id={id}
              cellProps={{
                system,
                custom_block_values: customBlockValues,
                lng: language,
                id,
                user,
                is_read_only: isReadOnly,
                additional_form_values: additionalFormValues,
              }}
              isNavbarHidden={uploadType === 'file'}
              compoundTypeKey="orders.order_items"
              optionsData={optionsData}
              language={language}
              typeData={{ key: 'orders.order_items' }}
              columns={editableTableColumns}
              data={formValues[blockKey]}
              blockKey={blockKey}
              formFieldsProps={formFieldsProps}
              fields={fields}
              getPanelHeader={getPanelHeader}
              updateTableRows={updateSelect.bind(null, blockKey)}
              setTextsChosenLng={setTextsChosenLng}
              validationRules={editableTableConfig?.validationRules}
              t={t}
            />

            {/*{errors?.[fileGroup] && !isObjectEmpty(errors[fileGroup]) && (*/}
            {/*  <TextPanel type="error" content={t('Some files were not uploaded. Please try again.')} />*/}
            {/*)}*/}
          </>
        )}
        {children}
      </>
    )
  }
)

const OrdersRightPanel = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const user = useMappedState(selectUserDetails)
  const sidebarInitialData = useMappedState(selectSidebarInitialData)
  const system = useMappedState(selectAllSystemCollections)
  const sidebarUpsertInProgress = useMappedState(selectSidebarUpsertInProgress)

  const [comment, setComment] = useState(null)
  const [formSubmitted, setFormSubmitted] = useState(false)

  useEffect(() => {
    if (formSubmitted && comment && sidebarInitialData.id) {
      const commentData = {
        entity_id: sidebarInitialData.id,
        content: comment,
      }
      dispatch(sidebarPostComment(commentData))
      setComment(null)
      setFormSubmitted(false)
    }
  }, [formSubmitted, comment, dispatch])

  const onFormSubmit = (formValuesChanged) => {
    if (formValuesChanged.comment) {
      setComment(formValuesChanged.comment)
    }

    const requestBody = { ...object(formValuesChanged) }

    Object.keys(requestBody).map((key) => {
      if (isObject(requestBody[key]) && requestBody[key].id) {
        requestBody[key] = requestBody[key].id
      }
    })

    if (formValuesChanged.order_items?.length) {
      requestBody.total =
        formValuesChanged.order_items.reduce((acc, curr) => acc + curr.quantity * curr.price, 0) +
        (formValuesChanged.tax || sidebarInitialData.tax || 0)

      requestBody.order_items = formValuesChanged.order_items.map((item) => ({
        inquiry_item_id: item.inquiry_item_id?.id || item.inquiry_item_id,
        order_id: sidebarInitialData.id || undefined,
        state: item.state,
        item_id: item.item?.id || item.item || item.item_id,
        quantity: item.quantity,
        price: item.price,
        total: item.quantity * item.price,
        id: item.id,
      }))
    }

    const dataToSend = {
      id: sidebarInitialData.id,
      requestBody,
      type: 'orders',
      parentType: 'documents',
    }

    dispatch(sidebarUpsert(dataToSend, navigate, true))
    setFormSubmitted(true)
  }

  return (
    <Form
      optionsData={{ ...system, user }}
      initialValues={sidebarInitialData}
      formConfig={sidebarInitialData?.state === 'posted' ? FORM_CONFIGS.ordersPosted : FORM_CONFIGS.orders}
      validationRules={FORM_CONFIGS.orders.validationRules}
      FormWrapper={FormBlockWrapper}
      expansionPanelProps={{
        disabled: false,
        withChevronInHeader: true,
      }}
      inProgress={sidebarUpsertInProgress}
      headerType="h4"
      onSubmit={onFormSubmit}
      buttonsAreSticky
      withActions
      isReadOnly={sidebarInitialData?.state === 'posted'}
    />
  )
}

export default OrdersRightPanel
