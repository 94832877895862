import React from 'react'
import { Typography, Tag, Tooltip, InfoRowsBlock } from '@aidsupply/components'
import ReactTexty from 'react-texty'
import { useTranslation } from 'react-i18next'
import { withTheme } from 'styled-components'

import { useMappedState } from '../../../hooks'
import { selectUserRole, selectAllSystemCollections } from '../../../redux/selectors'
import { formatDateToUserTimezone } from '../../../utils/dates'
import AddressTableCell from '../../../components/Table/components/AddressTableCell'

const InventoriesTableCard = ({ className, data, initialData, theme }) => {
  const { number, state, warehouse_id, user_id, organization_id, created_at, updated_at, checkbox } =
    data || {}

  const system = useMappedState(selectAllSystemCollections)
  const userRole = useMappedState(selectUserRole)

  const user = system.users?.[user_id]?.full_name || ''
  const organization = system.organizations?.[organization_id]?.name || ''

  const getWarehouseName = (warehouseId) => {
    if (typeof warehouseId === 'number') {
      return system.warehouses?.[warehouseId]?.name || ''
    }
    return warehouse_id?.props?.text || ''
  }

  const formatCreatedAtDate = (created_at) => {
    if (typeof created_at === 'object') {
      return created_at
    }
    return formatDateToUserTimezone(created_at, 'DD.MM.YYYY')
  }

  const formatUpdatedAtDate = (updated_at) => {
    if (typeof updated_at === 'object') {
      return updated_at
    }
    return formatDateToUserTimezone(updated_at, 'DD.MM.YYYY')
  }

  const getAddress = (organization) => {
    if (organization) {
      return <AddressTableCell rowData={organization} />
    }
    return null
  }

  const iconName = (() => {
    switch (state) {
      case 'drafted':
        return 'stateDrafted'
      case 'posted':
        return 'statePosted'
      case 'deleted':
        return 'stateDeleted'
      default:
        return 'done'
    }
  })()

  return (
    <InfoRowsBlock
      className={className}
      data={[
        {
          number: {
            customElement: <Typography as={ReactTexty} type="h4" text={number} />,
            width: 'calc(100% - 63px)',
            hideBorder: true,
            padding: '10px 0 10px 10px',
          },
          state: {
            ...(typeof state === 'string' && {
              iconProps: { name: iconName },
            }),
            width: '36px',
            text: state,
            hideBorder: true,
            padding: '10px 10px 10px 0',
          },
          checkbox: {
            customElement: checkbox,
            width: '20px',
            hideBorder: true,
            padding: '10px 0',
          },
        },
        {
          warehouse_id: {
            iconProps: {
              name: 'warehouse',
            },
            customElement: (
              <Tag as={ReactTexty} colorKey="gray2">
                {getWarehouseName(warehouse_id)}
              </Tag>
            ),
            width: '100%',
            hideBorder: false,
            padding: '8px 10px',
          },
        },
        {
          user_id: {
            iconProps: {
              name: 'user',
            },
            ...(typeof user_id === 'number' && {
              customElement: (
                <Typography as={ReactTexty} variant="body1">
                  {user}
                </Typography>
              ),
            }),
            ...(typeof user_id === 'object' && {
              text: user_id,
            }),
            width: '100%',
            hideBorder: false,
            padding: '10px',
          },
        },
        {
          ...(userRole === 'administrator' || userRole === 'system'
            ? {
                address: {
                  customElement:
                    getAddress(system.organizations?.[organization_id]) ||
                    (initialData?.organization_id &&
                      getAddress(system.organizations?.[initialData.organization_id])),
                  width: '50%',
                  hideBorder: false,
                  padding: '10px',
                },
                organization_id: {
                  iconProps: {
                    name: 'layers',
                  },
                  ...(typeof organization_id === 'number' && {
                    customElement: (
                      <Typography as={ReactTexty} variant="body1">
                        {organization}
                      </Typography>
                    ),
                  }),
                  ...(typeof organization_id === 'object' && {
                    text: organization_id,
                  }),
                  width: '50%',
                  hideBorder: false,
                  padding: '8px 10px',
                },
              }
            : {}),
        },
        {
          created_at: {
            iconProps: {
              name: 'at',
            },
            text: formatCreatedAtDate(created_at),
            width: '50%',
            hideBorder: false,
            padding: '9px 10px',
          },
          updated_at: {
            iconProps: {
              name: 'at',
            },
            text: formatUpdatedAtDate(updated_at),
            width: '50%',
            hideBorder: false,
            padding: '9px 10px',
          },
        },
      ]}
    />
  )
}

export default withTheme(InventoriesTableCard)
