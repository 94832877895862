import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { parse } from 'query-string'
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { Input, Icon, usePrevious, Button } from '@aidsupply/components'

import { mapParamsToUrl } from '../../utils/filters'

const SEARCH_QUERY_MIN_LENGTH = 2
const MAX_SEARCH_INPUT_LENGTH = 255

const TableSearch = ({
  activeFilters,
  facets,
  isDropdownSearch,
  iconRightProps,
  isMainTable,
  searchValueState,
  setSearchValue,
  type,
}) => {
  const { i18n } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const { search } = location
  const searchParsed = parse(search)
  const hasCustomSearchState = typeof searchValueState !== 'undefined' && setSearchValue
  const searchValue = hasCustomSearchState ? searchValueState : searchParsed.searchValue ?? ''

  const [inputValue, setInputValue] = useState(searchValue)
  const prevInputValue = usePrevious(inputValue)

  const [searchOpened, setSearchOpened] = useState(false)

  const prevType = usePrevious(type)
  useEffect(() => {
    if (type !== prevType && searchValue) {
      if (hasCustomSearchState) {
        setSearchValue('')
      } else {
        searchParsed.searchValue = ''
      }
    }
  }, [type, prevType])

  const onInputChange = useCallback(
    (e) => {
      const { value } = e.target

      setInputValue(value)

      if (value === searchValue || (value.length > 0 && value.length < SEARCH_QUERY_MIN_LENGTH)) {
        // ignore search
        return
      }

      if (hasCustomSearchState) {
        setSearchValue(value?.length >= SEARCH_QUERY_MIN_LENGTH ? value : '')
      } else if (value.length >= SEARCH_QUERY_MIN_LENGTH) {
        searchParsed.searchValue = value
      } else if (value.length === 0) {
        delete searchParsed.searchValue
        if (prevInputValue?.length > inputValue.length) {
          setInputValue('')
        }
      } else {
        console.log('Unmapped search corner case')
      }

      const urlParams = Object.keys(searchParsed).map((key) => `${key}=${searchParsed[key]}`)
      const searchFiltered = `?${urlParams.join('&')}`

      const { apiQuery, urlQuery } = mapParamsToUrl(
        activeFilters,
        facets,
        type,
        searchFiltered,
        i18n.language
      )

      // console.log(urlQuery, searchParsed, createSearchParams(searchParsed).toString())

      // dispatch(
      //   dataFetchWithFacets({
      //     type: type,
      //     lng: i18n.language,
      //     query: apiQuery,
      //   })
      // )

      if (isMainTable) {
        navigate({
          pathname: location.pathname,
          search: urlQuery,
        })
      }
    },
    [activeFilters, dispatch, facets, isMainTable, i18n.language, searchParsed, searchValue, type]
  )

  const onSearchCleanClick = () => {
    setInputValue('')

    if (hasCustomSearchState) {
      setSearchValue('')
    } else {
      delete searchParsed.searchValue
      navigate({ search: createSearchParams(searchParsed).toString() })
    }
  }

  const withSearchCleanCross = iconRightProps?.name === 'cross'
  const searchCleanIconProps = withSearchCleanCross &&
    inputValue && {
      ...iconRightProps,
      onClick: onSearchCleanClick,
      strokeWidth: 1,
    }

  const getInput = () => (
    <Input
      className="searchInput"
      fullWidth
      iconRightProps={searchCleanIconProps || (!withSearchCleanCross && iconRightProps) || undefined}
      iconLeftProps={{
        name: 'search',
        strokeWidth: 1,
        width: 20,
        height: 20,
      }}
      maxLength={MAX_SEARCH_INPUT_LENGTH}
      onChange={onInputChange}
      primaryFocusColor
      value={inputValue}
      // withDebounce
      withoutValidation
      withBorder
    />
  )

  if (!isDropdownSearch) {
    return getInput()
  }

  return (
    <>
      <Button variant="bordered" onClick={() => setSearchOpened((prev) => !prev)} className="navBar search">
        <Icon name="search" width={20} height={20} strokeWidth={1.5} wrapperWidth={32} wrapperHeight={32} />
      </Button>
      {searchOpened && getInput()}
    </>
  )
}

export default TableSearch
