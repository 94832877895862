import React from 'react'
import { pick } from 'dot-object'
import { withTheme } from 'styled-components'

import { Image, Select, buildImagePath, IMAGEKIT_PARAMS_CONFIG } from '@aidsupply/components'
import { IMAGEKIT_URL } from '../../constants'

const SearchForMultiSelectTable = ({
  banners,
  onAddTabToItemPhotos,
  options,
  keyToUpdate,
  lng,
  noOptionsMessage,
  setTableData,
  tableData,
  theme: { size },
  type,
  tableTypeData,
  updateSelectValue,
}) => {
  const getOptionImage = (optionData) => {
    const categoryBanner =
      type.startsWith('categories') &&
      Object.values(banners).find((banner) => banner.general.category?.id === optionData.data.id)

    const imageName =
      tableTypeData?.srcKey &&
      pick(tableTypeData.srcKey, type.startsWith('categories') ? categoryBanner : optionData.data)

    if (!imageName) return null

    const src = buildImagePath(
      tableTypeData.dataKeyForPhotos || tableTypeData.key,
      categoryBanner?.id || optionData.data.id,
      type === 'brands' ? 'logos' : type.startsWith('categories') ? 'square' : 'general',
      imageName
    )

    return (
      <Image
        src={src}
        height={36}
        radius={size.border.radius.main}
        asBackground
        width={46}
        backgroundSize="cover"
        className="optionImage"
        imagekitParams={IMAGEKIT_PARAMS_CONFIG.components.selectOption}
        imagekitUrl={IMAGEKIT_URL}
      />
    )
  }

  const onResultClick = (props) => () => {
    const id = props.value
    const itemClicked = options.find((opt) => opt.id === id)

    setTableData((prev) => {
      return [...(prev || []), itemClicked]
    })

    updateSelectValue(
      keyToUpdate || tableTypeData.keyToUpdate,
      type === 'characteristics' &&
        onAddTabToItemPhotos.bind({ attributeSlug: keyToUpdate.replace('characteristics.', '') })
    )([...(tableData || []), itemClicked])
  }

  return (
    <Select
      className="searchSelect"
      iconName="search"
      withBorder
      options={options}
      value={tableData}
      valueKey="id"
      labelKey={tableTypeData.withTranslation ? `${tableTypeData.labelKey}.${lng}` : tableTypeData.labelKey}
      noOptionsMessage={noOptionsMessage}
      onOptionClick={onResultClick}
      isMulti
      controlShouldRenderValue={false}
      isClearable={false}
      getOptionBeforeTextComponent={getOptionImage}
    />
  )
}

export default withTheme(SearchForMultiSelectTable)
