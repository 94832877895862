import { getMonths, getYears } from '../utils/dates'

export const UNITS_PIECES_ID = '55081b1be4b0896e3115b865'

export const DEFAULT_TABLE_SORTING = {
  warehouses: { name: 'asc' },
}

export const CONTENT_UNITS_OPTIONS = [
  {
    id: '5531e114e4b00856a7dae1b6',
    label: {
      en: '%',
      ru: '%',
      uk: '%',
    },
  },
  {
    id: '54df505ee4b05dc487638247',
    label: {
      en: 'g',
      ru: 'г',
      uk: 'г',
    },
  },
  {
    id: '617867e67797ce000980447f',
    label: {
      en: 'ml',
      ru: 'мл',
      uk: 'мл',
    },
  },
]

export const FORM_TABLE_OPTIONS = [
  {
    id: 'toEdit',
    label: {
      en: 'Edit',
      ru: 'Редактировать',
      uk: 'Редагувати',
    },
    iconName: 'draft',
  },
  {
    id: 'toDelete',
    label: { en: 'Delete', ru: 'Удалить', uk: 'Видалити' },
    iconName: 'trashBin2',
  },
]

// export const PAGE_TYPES = [
//   { id: 'text', label: { en: 'Text', ru: 'Текст', uk: 'Текст' } },
//   { id: 'landing', label: { en: 'Landing', ru: 'Лэндинг', uk: 'Лендінг' } },
//   { id: 'aboutUs', label: { en: 'About us', ru: 'О нас', uk: 'Про нас' } },
//   { id: 'vertical', label: { en: 'Vertical', ru: 'Вертикаль', uk: 'Вертикаль' } },
//   { id: 'category', label: { en: 'Category', ru: 'Категория', uk: 'Категорія' } },
// ]

export const PAGE_TYPES = [
  { id: 'cookies', label: '/cookies-policy' },
  { id: 'privacy', label: '/privacy-policy' },
  { id: 'terms', label: '/terms-and-conditions' },
]

export const CONTRACT_TYPES = [
  { id: 'payment', label: { en: 'Payment', uk: 'Оплата', ru: 'Оплата' } },
  { id: 'shipment', label: { en: 'Shipment', uk: 'Відвантаження', ru: 'Отгрузка' } },
]

export const MAGNITUDES = [
  { id: 'amperage', label: { en: 'Amperage', ru: 'Сила тока', uk: 'Сила струму' } },
  { id: 'density', label: { en: 'Density', ru: 'Плотность', uk: 'Щільність' } },
  { id: 'force', label: { en: 'Force', ru: 'Сила', uk: 'Сила' } },
  { id: 'frequency', label: { en: 'Frequency', ru: 'Частота', uk: 'Частота' } },
  { id: 'length', label: { en: 'Length', ru: 'Длина', uk: 'Довжина' } },
  { id: 'mass', label: { en: 'Mass', ru: 'Масса', uk: 'Маса' } },
  { id: 'options', label: { en: 'Options', ru: 'Опции', uk: 'Опції' } },
  { id: 'power', label: { en: 'Power', ru: 'Мощность', uk: 'Потужність' } },
  { id: 'pressure', label: { en: 'Pressure', ru: 'Давление', uk: 'Тиск' } },
  { id: 'resistance', label: { en: 'Resistance', ru: 'Сопротивление', uk: 'Опір' } },
  { id: 'speed', label: { en: 'Speed', ru: 'Скорость', uk: 'Швидкість' } },
  { id: 'square', label: { en: 'Square', ru: 'Площадь', uk: 'Площа' } },
  { id: 'temperature', label: { en: 'Temperature', ru: 'Температура', uk: 'Температура' } },
  { id: 'time', label: { en: 'Time', ru: 'Время', uk: 'Час' } },
  { id: 'voltage', label: { en: 'Voltage', ru: 'Напряжение', uk: 'Напруга' } },
  { id: 'volume', label: { en: 'Volume', ru: 'Объём', uk: "Об'єм" } },
]

export const WAREHOUSE_TYPES = [
  { id: 'retail', label: { en: 'Retail', ru: 'Розничный', uk: 'Роздрібний' } },
  { id: 'wholesale', label: { en: 'Wholesale', ru: 'Оптовый', uk: 'Гуртовий' } },
]

export const ORGANIZATION_FORM_STEPS = [
  { title: 'general', iconProps: { name: '', wrapperWidth: 32, wrapperHeight: 32, width: 20, height: 20 } },
  { title: 'address', iconProps: { name: '', wrapperWidth: 32, wrapperHeight: 32, width: 20, height: 20 } },
  {
    title: 'shipmentAddress',
    iconProps: { name: '', wrapperWidth: 32, wrapperHeight: 32, width: 20, height: 20 },
  },
  { title: 'account', iconProps: { name: '', wrapperWidth: 32, wrapperHeight: 32, width: 20, height: 20 } },
]

export const ORGANIZATION_TYPES = [
  {
    id: 'private',
    label: { en: 'Private', uk: 'Приватний', ru: 'Частный' },
  },
  {
    id: 'hospital',
    label: { en: 'Hospital', uk: 'Лікарня', ru: 'Больница' },
  },
  {
    id: 'military',
    label: { en: 'Military', uk: 'Військовий', ru: 'Военный' },
  },
  {
    id: 'business',
    label: { en: 'Business', uk: 'Бізнес', ru: 'Бизнес' },
  },
  {
    id: 'governmental',
    label: { en: 'Governmental', uk: 'Державний', ru: 'Государственный' },
  },
  {
    id: 'fund',
    label: { en: 'Fund', uk: 'Фонд', ru: 'Фонд' },
  },
  {
    id: 'ngo',
    label: { en: 'NGO', uk: 'НДО', ru: 'НПО' },
  },
]

export const VISIBILITY_TYPES = [
  { id: 'team', label: { en: 'Organization', ru: 'Организация', uk: 'Організація' } },
  { id: 'community', label: { en: 'Community', ru: 'Сообщество', uk: 'Спільнота' } },
  { id: 'public', label: { en: 'Public', ru: 'Общий', uk: 'Загальний' } },
]

export const ATTRIBUTE_TYPES = [
  { id: 'numeric', label: { en: 'Number', ru: 'Число', uk: 'Число' } },
  { id: 'enum', label: { en: 'Enumeration', ru: 'Перечисление', uk: 'Перелік' } },
  { id: 'bool', label: { en: 'Boolean', ru: 'Булевое значение', uk: 'Булеве значення' } },
]

export const PROVIDERS = [
  // { id: '', label: { en: 'No provider', uk: 'Без провайдеру', ru: 'Без провайдера' } },
  { id: 'google', label: { en: 'Google', uk: 'Google', ru: 'Google' } },
  { id: 'facebook', label: { en: 'Facebook', uk: 'Facebook', ru: 'Facebook' } },
  { id: 'email', label: { en: 'Email', uk: 'Email', ru: 'Email' } },
]

export const REPORT_TYPES = [
  { id: 'monthly', label: { en: 'Monthly', uk: 'Місячний', ru: 'Месячный' } },
  { id: 'annual', label: { en: 'Annual', uk: 'Річний', ru: 'Годовой' } },
]

export const ENUMERATION_ADDONS = [
  {
    id: 'none',
    label: {
      en: 'None',
      ru: 'Нет',
      uk: 'Немає',
    },
  },
  {
    id: 'icon',
    label: {
      en: 'Icon',
      ru: 'Иконка',
      uk: 'Іконка',
    },
  },
  {
    id: 'image',
    label: {
      en: 'Image',
      ru: 'Изображение',
      uk: 'Зображення',
    },
  },
  {
    id: 'hex_and_image',
    label: {
      en: 'Hex or Image',
      ru: 'Цвет или изображение',
      uk: 'Колір чи зображення',
    },
  },
]

export const MONTHS = getMonths()

export const YEARS = getYears(2000)

export const TAXES_TYPE = [
  {
    id: 'none',
    label: {
      en: 'None',
      ru: 'Нет',
      uk: 'Відсутній',
    },
  },
  {
    id: 'vat_7',
    label: {
      en: 'VAT 7%',
      ru: 'НДС 7%',
      uk: 'ПДВ 7%',
    },
  },
  {
    id: 'vat_14',
    label: {
      en: 'VAT 14%',
      ru: 'НДС 14%',
      uk: 'ПДВ 14%',
    },
  },
  {
    id: 'vat_20',
    label: {
      en: 'VAT 20%',
      ru: 'НДС 20%',
      uk: 'ПДВ 20%',
    },
  },
]
