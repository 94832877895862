import { all, call, put, select, take } from 'redux-saga/effects'

import {
  getFilterLabels,
  getFilterSlug,
  ITEM_TO_API_KEYS,
  isValidJSON,
  isObjectEmpty,
  DATA_KEYS_WITH_VERSIONS_FOR_LOCAL_STORAGE,
  convertArrayToObject,
} from '@aidsupply/components'

import { buildRequest, fetchData } from '../../api'
import {
  DATA_FETCH_SYSTEM_DATA,
  DATA_FETCH_SYSTEM_DATA_ERROR,
  DATA_FETCH_SYSTEM_DATA_SUCCESS,
  DATA_SET_SYSTEM_DATA,
  FILTERS_SET_FACETS,
} from '../constants'
import { FILTERS_CONFIG, FILTERS_KEY_MAPPINGS } from '../../config/filters'
import {
  CRM_DATA_TYPES_WITH_NO_FACETS,
  FIELDS_FOR_ALPHABETICAL_BACKEND_SORT,
  SYSTEM_DATA_FIELDS,
} from '../../apiConstants'

const mapStateToProps = (state) => state

/**
 * Reads data from API with reducer update
 * @param { string } - type
 */
function* readAPIData({ queryParams, type }) {
  try {
    const request = yield buildRequest({
      operation: 'read',
      type,
      queryParams,
    })
    const { items } = yield fetchData(request)

    return { type, items }
  } catch (error) {
    return { error }
  }
}

function* readSystemData(systemDataKeysToRead, stateData, constantsData, platformConfig) {
  const collections = systemDataKeysToRead
  const system = {}
  let collectionsToFetch = []
  let outdatedCollections = []
  let storedData
  let currentVersions
  let storedVersions

  if (collections === undefined || collections.length === 0) {
    yield put({ type: DATA_FETCH_SYSTEM_DATA_SUCCESS })

    return
  }

  if (typeof window !== 'undefined') {
    // Get current versions of system collections
    // currentVersions = (yield call(readAPIData, { type: 'versions' }))
    //   .items
    //   ?.filter(version =>
    //     DATA_KEYS_WITH_VERSIONS_FOR_LOCAL_STORAGE[version.collection]
    //   )
    storedVersions = JSON.parse(window.localStorage.getItem('versions'))

    if (storedVersions) {
      outdatedCollections = currentVersions
        ?.filter((item) => storedVersions[item.collection]?.updated !== item.updated)
        ?.map((item) => item.collection)
    } else {
      outdatedCollections = currentVersions?.map((item) => item.collection)
    }

    // Reading earlier stored data
    collections.forEach((collection) => {
      // TODO: should we run this function only when app launches?! No) we run it also when we change data type.
      if (stateData[collection] !== undefined) {
        return
      }
      // collections stored in local storage (ones with versions)
      const isLocalStorageKey = DATA_KEYS_WITH_VERSIONS_FOR_LOCAL_STORAGE[collection]

      if (isLocalStorageKey) {
        storedData = window.localStorage.getItem(collection)
      }

      if (isLocalStorageKey && storedData && !outdatedCollections?.includes(collection)) {
        if (isValidJSON(storedData)) {
          system[collection] = JSON.parse(storedData)
        }
      } else if (constantsData && constantsData[collection]) {
        system[collection] = constantsData[collection]
      } else {
        collectionsToFetch.push(collection)
      }
    })
  } else {
    collections.forEach((collection) => {
      if (constantsData && constantsData[collection]) {
        system[collection] = constantsData[collection]
      } else {
        collectionsToFetch.push(collection)
      }
    })
  }

  if (collectionsToFetch.length > 0) {
    yield put({ type: DATA_FETCH_SYSTEM_DATA })

    const result = yield all(
      collectionsToFetch.map((collection) => {
        const sortParam = FIELDS_FOR_ALPHABETICAL_BACKEND_SORT[collection]

        const queryParams = [{ key: 'limit', value: 100000 }]

        if (sortParam) {
          queryParams.push({ key: 'sort', value: `${sortParam}=asc` })
        }

        // TODO: it's necessary to rethink this logic for aid supply as we will not have platformConfig/platforms collection here
        // if (platformConfig && collection === 'categories') {
        //   if (platformConfig.general?.industries?.length) {
        //     queryParams.push({
        //       key: 'general.industry.id',
        //       value: platformConfig.general.industries.map((industry) => industry.id).join(','),
        //     })
        //   }

        // if (platformConfig.general?.categories?.length) {
        //   queryParams.push({
        //     key: 'id',
        //     value: platformConfig.general.categories.map((category) => category.id).join(','),
        //   })
        // }
        // }

        return call(readAPIData, {
          type: collection,
          queryParams,
        })
      })
    )

    if (result.findIndex((res) => res.error) !== -1) {
      yield put({ type: DATA_FETCH_SYSTEM_DATA_ERROR, error: result })
    }

    // add to storage & push to system object
    result
      .filter((collection) => !!collection)
      .forEach(({ type, items }) => {
        if (items) {
          system[type] = items

          if (typeof window !== 'undefined' && DATA_KEYS_WITH_VERSIONS_FOR_LOCAL_STORAGE[type]) {
            window.localStorage.setItem(type, JSON.stringify(system[type]))
          }
        }
      })
  }

  try {
    // TODO: set all at once. Change after refactoring
    yield all(
      Object.keys(system).map((key) => {
        return put({ type: DATA_SET_SYSTEM_DATA, key, data: system[key] })
      })
    )
    yield put({ type: DATA_FETCH_SYSTEM_DATA_SUCCESS })

    if (typeof window !== 'undefined') {
      // substitute current versions
      const fetchedVersions = currentVersions?.filter((item) => collectionsToFetch.includes(item.collection))
      const updatedVersions = {
        ...storedVersions,
        ...convertArrayToObject(fetchedVersions, 'collection'),
      }
      window.localStorage.setItem('versions', JSON.stringify(updatedVersions))
    }
  } catch (error) {
    console.log(error)
    yield put({ type: DATA_FETCH_SYSTEM_DATA_ERROR, error })
  }
}

function* transformApiFacets(facetsFromApi, facetsToInclude) {
  const { data } = yield select(mapStateToProps)
  let facets

  // Filter all facetsFromApi if facetsToInclude exists
  if (!facetsToInclude || !facetsFromApi) {
    facets =
      Object.keys(facetsFromApi)?.reduce((acc, curr) => {
        const mappedCurr = FILTERS_KEY_MAPPINGS[curr] || curr

        return { ...acc, [mappedCurr]: facetsFromApi[curr] }
      }, {}) || {}
  } else {
    facets = Object.keys(facetsFromApi)?.reduce((acc, curr) => {
      const mappedCurr = FILTERS_KEY_MAPPINGS[curr] || curr

      if (facetsToInclude[mappedCurr]) {
        return { ...acc, [mappedCurr]: facetsFromApi[curr] }
      }

      return acc
    }, {})
  }

  // Add labels to filters
  Object.keys(facets).forEach((key) => {
    facets[key].forEach((val, idx) => {
      const reduxDataKey =
        FILTERS_CONFIG[key]?.systemDataKeyMapping?.[data.type.key] ||
        // ITEM_TO_API_KEYS[key] ||
        FILTERS_CONFIG[key]?.key ||
        key

      const allSupportData = data.system[reduxDataKey] || {}

      facets[key][idx] = {
        ...val,
        label: getFilterLabels(FILTERS_CONFIG[key]?.options || allSupportData, val.id),
        slug: getFilterSlug(FILTERS_CONFIG[key]?.options || allSupportData, val.id),
      }
    })
  })

  return facets
}

function* fetchFacets({ apiUrlParam, type, queryParams, facetsToInclude, isRefetch }) {
  if (CRM_DATA_TYPES_WITH_NO_FACETS.includes(type)) {
    return
  }

  try {
    const requestFacets = yield buildRequest({
      operation: 'read',
      withFacets: true,
      type,
      queryParams,
      apiUrlParam,
    })

    let { facets } = yield fetchData(requestFacets)

    if (facets && !isObjectEmpty(facets)) {
      const facetsWithLabels = yield transformApiFacets(facets, facetsToInclude)
      yield put({ type: FILTERS_SET_FACETS, facets: facetsWithLabels, isRefetch })
    }
  } catch (error) {
    console.log(error)
  }
}
export { readAPIData, readSystemData, fetchFacets }
