import styled from 'styled-components'

export const StyledTableBlock = styled.div`
  padding-top: ${({ theme: { grid }, currentBreakpoint }) => grid[currentBreakpoint].columns.gapWidth};
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  .table {
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    //overflow-y: scroll;
  }
  .table::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    margin-left: ${({ theme: { grid }, currentBreakpoint }) => grid[currentBreakpoint].columns.gapWidth};
    height: calc(
      100vh - ${({ theme: { grid }, currentBreakpoint }) => grid[currentBreakpoint].columns.gapWidth}
    );
  }

  &.filtersExpanded {
    margin: 0;
  }
`

export const StyledTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: ${({ theme: { grid }, currentBreakpoint }) => grid[currentBreakpoint].columns.gapWidth};

  .menuCloseIcon {
    transform: rotate(180deg);
  }

  .hide {
    display: none;
  }
`

export const StyledNavBar = styled.div`
    display: flex;
    align-items: center;
    gap: 15px 10px;
    padding-bottom: 20px;

    .download {
        padding-left: 9px;
    }

    .drawer & {
        gap: 5px;
    }

    .multiSelectTable & {
        padding-bottom: 0;
    }

    &.cards {
        //padding-bottom: 10px;
    }

    &.editable {
        justify-content: space-between;
        flex-wrap: wrap;
        padding-bottom: 0;

        .row {
            flex-grow: 1;

            &.fullWidth {
                flex-basis: 100%;
                width: 100%;
            }

            &.lastRow {
                &.characteristics {
                    order: 100;
                }

                flex-basis: calc(100% - 40px);
                width: calc(100% - 40px);

                .characteristicsToChoose {
                    margin-top: 10px;
                    display: flex;
                    flex-wrap: wrap;

                    & > div {
                        max-width: 50%;

                        &:first-child {
                            margin-right: 5px;
                        }

                        .selectWrapper {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }

        &:not(.withFields) {
            padding: 5px 15px;
        }

        .iconSelect {
            flex-grow: 1;
        }
    }

    .navRightIcon {
        margin-left: 15px;
        //cursor: pointer;
    }

    &.withDropdownSearch {
        flex-wrap: wrap;

        .searchInput {
            order: 10;
        }
    }

    .selectWrapper .react-select__control {
        min-height: 32px;
    }

    & .inputWrapper {
        & > .icon.leftIcon {
            left: 6px;
        }

        & input {
            height: 32px;

            &.hasLeftIcon {
                padding-right: 6px;
                padding-left: 26px;
            }
        }
    }

    button {
        &.navBar {
            min-width: auto;

            &.add {
                border: none;
                padding: 0;
            }

            &.filters,
            &.cart {
                .itemsTag {
                    margin-left: 6px;
                    margin-right: 0;
                }
            }

            &.search {
                padding: 0;
            }
        }
    }

    .actions {
        margin: 0;

        div:nth-child(2) {
            padding: 0;
        }

        .menuItem {
            padding: 8px 0;
            margin: 0 16px;

            &.clickable {
                border-bottom: none;
            }

            &:not(.clickable) {
                margin: 0;
                padding: 8px;
            }
        }
    }

    .downloadActions {
        display: flex;
    }

    .opened {
        padding: 0;
        width: 34px;

        .icon {
            padding: 0;
        }
    }

    @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
        padding: 10px 8px;

        .downloadActions {
            display: none;
        }
    }
`

export const StyledListItem = styled.div`
  display: flex;

  .icon {
    margin-right: 10px;
  }

  .actionHeader {
    font-size: 12px;
    line-height: 14px;
  }
`
export const StyledCardAttributes = styled.div`
  display: none;
  background-color: ${({ theme }) => theme.color.general.light};
  position: absolute;
  bottom: 0;
  width: calc(100% - 40px);
  padding: 20px;
  border: 1px solid ${({ theme }) => theme.bordersColor};
  border-radius: ${({ theme }) => theme.size.border.radius.main};
  box-shadow: 0px -1px 2px 0px rgba(24, 24, 28, 0.04), 0px -3px 4px -5px rgba(24, 24, 28, 0.03);

  .characteristicsToChoose {
    margin-bottom: 20px;
  }

  .selectWrapper {
    margin-bottom: 10px;
    box-shadow: 0px 1px 2px 0px rgba(24, 24, 28, 0.04), 0px 3px 4px -5px rgba(24, 24, 28, 0.03);

    .react-select__control {
      min-height: 32px;
    }
  }

  .basketAddRow {
    .quantityInput {
      box-shadow: 0px 1px 2px 0px rgba(24, 24, 28, 0.04), 0px 3px 4px -5px rgba(24, 24, 28, 0.03);
      flex-grow: 1;
      input {
        flex-grow: 1;
      }
    }
  }
`

export const StyledSearchItem = styled.div`
  position: relative;
  padding: 1px 20px 0 20px;
  margin-bottom: 20px;
  cursor: pointer;

  &:hover {
    ${StyledCardAttributes} {
      display: block;
    }
  }

  .tile {
    border-radius: ${({ theme }) => theme.size.border.radius.main};
    border: 1px solid ${({ theme }) => theme.bordersColor};

    .title {
      margin: 20px;
    }

    img {
      width: 100%;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-top-left-radius: ${({ theme }) => theme.size.border.radius.main};
      border-top-right-radius: ${({ theme }) => theme.size.border.radius.main};
    }
  }

  &.touchOnly {
    border: 1px solid ${({ theme }) => theme.color.general.gray2};
    border-radius: ${({ theme }) => theme.size.border.radius.main};
    .withIconButton {
      order: 3;
      margin-bottom: 18px;
    }
    .characteristicsToChoose {
      //margin-bottom: auto;
      //margin-top: 14px;
    }
  }
  &:not(.category):not(.touchOnly) .characteristicsToChoose {
    display: none;
    //display: block;
    position: absolute;
    padding: 0 19px 0 19px;
    top: 100%;
    left: 0;
    right: 0;
    background-color: ${({ theme }) => theme.color.general.light};
    z-index: 2;
  }
  &:not(.category):not(.touchOnly):not(.outOfStock):hover {
    padding: 0 19px 0 19px;
    border: 1px solid ${({ theme }) => theme.color.general.gray2};
    border-bottom: none;
    border-radius: ${({ theme }) => theme.size.border.radius.main};
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    .characteristicsToChoose {
      display: block;
      padding: 0 18px 4px 18px;
      border: 1px solid ${({ theme }) => theme.color.general.gray2};
      border-radius: ${({ theme }) => theme.size.border.radius.main};
      border-top: none;
      border-top-right-radius: 0;
      border-top-left-radius: 0;
      left: -1px;
      right: -1px;
    }
  }
  &.category {
    .withIconButton {
      margin-left: auto;
    }
  }
`

export const StyledContent = styled.div`
    height: ${({ topHeight, maxHeight }) => (maxHeight ? `${maxHeight}px` : `calc(100vh - ${topHeight}px)`)};
    position: relative;

    &.editableTable {
        input {
            padding: 9px;
        }

        .BaseTable__table-main .BaseTable__row-cell:first-child {
            margin-left: 7px;
        }

        .BaseTable__table-main .BaseTable__row-cell:last-child {
            padding-right: 7px;
        }
    }

    .emailForCopy {
        cursor: pointer;
    }

    & .card:not(.isSelected) {
        border-color: ${({ theme }) => theme.bordersColor};

        .checkbox {
            border-color: ${({ theme }) => theme.bordersColor};
        }

        &:hover {
            border-color: ${({ theme }) => theme.color.general.gray5};
        }
    }

    .tableCardWrapper > div {
        height: 100%;
    }

    && .BaseTable__header-row {
        border-color: ${({ theme }) => theme.bordersColor};
        border-radius: 0;
    }

    && .BaseTable__table.BaseTable__table-main .BaseTable__row {
        border-color: ${({ theme }) => theme.bordersColor};
    }
`

//     .BaseTable__row-cell,
//     .BaseTable__header-cell {
//       padding: 0 3px;
//       margin-left: 3px;
//     }
//     .BaseTable__row-cell .brandNameImage {
//       color: ${({ theme: { color } }) => color.general.light};
//       background-color: ${({ theme: { color } }) => color.primary.main};
//       font-weight: ${({ theme: { font } }) => font.weight.bold};
//       border-radius: 50%;
//       padding: 6px 0px;
//       min-width: 25px;
//       text-align: center;
//     }
//   }
// }
//
// @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
//   .BaseTable__table {
//     padding: 10px;
//   }
//
//   &.cards {
//     padding-left: 10px;
//   }
//   .listVirtualized {
//     overflow-x: hidden !important;
//   }
// }

export const StyledTable = styled.div`
  margin-top: 30px;

  tr {
    cursor: pointer;
  }

  .table-checkbox {
    width: 10px;
  }

  .table-actions {
    display: flex;
  }

  .filter-group {
    display: flex;
    margin-left: auto;
  }
`

export const StyledNumberTag = styled.div`
  background-color: ${({ theme }) => theme.color.general.light};
`
export const StyledIconTagCounter = styled.div`
  display: flex;
  padding: 1.5px 6px;
  align-items: center;
  gap: 6px;
  border-radius: 6px;
  padding: 6px;
  position: absolute;
  top: 10px;
  right: 30px;
  margin: 0;
  background-color: ${({ theme }) => theme.color.primary.main};
`
