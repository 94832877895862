/*
 *
 * Basket constants/reducer/actions
 *
 */

// constants
import { nanoid } from 'nanoid'
import isEqual from 'lodash.isequal'

import {
  ADD_DEMAND_TO_BASKET,
  ADD_PRODUCT_TO_BASKET,
  ADD_TO_BASKET,
  CLEAN_BASKET,
  UPDATE_BASKET,
} from '../constants'

// initial state
const initialState = {
  itemsCount: null,
  items: [],
}

// reducer
const basket = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TO_BASKET: {
      const newItemIndex = state.items.findIndex(
        (item) => item[action.key || 'id'] === action.item[action.key || 'id']
      )
      const basketWithSameItem = newItemIndex !== -1

      const items = basketWithSameItem
        ? state.items.map((item, i) =>
            i === newItemIndex
              ? {
                  ...item,
                  quantity: item.quantity + (action.item.quantity || 1),
                }
              : item
          )
        : [
            ...state.items,
            {
              ...action.item,
              quantity: action.item.quantity || 1,
            },
          ]

      return {
        items,
        itemsCount: items.length,
      }
    }
    case ADD_PRODUCT_TO_BASKET: {
      const basketItems = state.items
      const newItemInBasketIndex = basketItems.findIndex(
        (item) => item.stockInfo.stockItemId === action.item.stockInfo.stockItemId
      )
      const basketHasSameItem = newItemInBasketIndex !== -1
      const items = basketHasSameItem
        ? basketItems.map((item, i) =>
            i === newItemInBasketIndex
              ? {
                  ...item,
                  stockInfo: {
                    ...item.stockInfo,
                    quantity: item.stockInfo.quantity + (action.item.stockInfo.quantity || 1),
                  },
                }
              : item
          )
        : [
            ...basketItems,
            {
              ...action.item,
              stockInfo: { ...action.item.stockInfo, quantity: action.item.stockInfo.quantity || 1 },
            },
          ]

      return { items, itemsCount: items.length }
    }
    case ADD_DEMAND_TO_BASKET: {
      const newItemCharacteristics = action.item.characteristicsChosen

      const basketItems = state.items
      const newItemInBasketIndex = basketItems.findIndex((item) => {
        return item.id === action.item.id && isEqual(item.characteristicsChosen, newItemCharacteristics)
      })

      const basketHasSameItem = newItemInBasketIndex !== -1

      const items = basketHasSameItem
        ? state.items.map((item, i) =>
            i === newItemInBasketIndex
              ? {
                  ...item,
                  quantity: item.quantity + (action.item.quantity || 1),
                }
              : item
          )
        : [
            ...state.items,
            {
              ...action.item,
              quantity: action.item.quantity || 1,
              basketId: nanoid(),
            },
          ]

      return { items, itemsCount: items.length }
    }
    case UPDATE_BASKET:
      return { items: action.items, itemsCount: action.items && action.items.length }
    case CLEAN_BASKET:
      return { ...initialState }
    default:
      return state
  }
}

export default basket
