import {
  ADD_DEMAND_TO_BASKET,
  ADD_PRODUCT_TO_BASKET,
  ADD_TO_BASKET,
  AVATAR_UPLOAD,
  CLEAN_BASKET,
  CLEAR_NOTIFICATIONS,
  CONFIRM_EMAIL,
  DATA_BULK_OPERATION,
  DATA_FETCH_WITH_FACETS,
  DATA_SET_SYSTEM_DATA,
  FILTERS_CLEAR_ALL,
  FILTERS_FILTER_ADD,
  FILTERS_FILTER_REMOVE,
  FILTERS_TOGGLE_PANEL,
  GET_CURRENT_USER,
  GET_INVITE_DETAILS,
  INITIALIZE_APP,
  INPUT_FILES_ADD,
  INPUT_FILES_CLEAR,
  INPUT_FILE_REMOVE,
  MARK_NOTIFICATION_AS_OLD,
  POPUP_ALERT_HIDE,
  POPUP_ALERT_HIDE_ALL,
  POPUP_ALERT_SHOW,
  REQUEST_RESET_PASSWORD,
  RESET_FORM_VALUES_CHANGED,
  RESET_PASSWORD,
  SET_API_URL_PARAM,
  SET_DATA_TYPE,
  SET_NOTIFICATIONS,
  SET_USER_ROLE,
  SIDEBAR_FETCH_BY_ID,
  SIDEBAR_ITEM_COPY,
  SIDEBAR_ITEM_RESET,
  SIDEBAR_ITEM_SET,
  SIDEBAR_POST_COMMENT,
  SIDEBAR_UPSERT,
  SIDEBAR_UPSERT_TOPICS,
  SIDEBAR_UPSERT_USER,
  SIGN_IN,
  SIGN_OUT,
  SIGN_UP,
  TOGGLE_NAV_MENU_EXTENDED,
  UPDATE_BASKET,
  UPDATE_FORM_VALUES_CHANGED,
  UPDATE_MODIFICATION_CODE_IN_SYSTEM,
  UPDATE_TABLE_AND_SYSTEM_DATA,
  UPSERT,
  PHOTO_DELETE,
  FILE_PARSE,
  TOGGLE_RIGHT_PANEL_EXTENDED,
} from './constants'

// AUTH
const initializeApp = (navigate, location, changeLanguage) => ({
  type: INITIALIZE_APP,
  navigate,
  location,
  changeLanguage,
})
const getCurrentUser = (navigate, location) => ({ type: GET_CURRENT_USER, navigate, location })
const userSignUp = (credentials, navigate) => {
  const { email, password, full_name, company_name, provider, code } = credentials

  return {
    type: SIGN_UP,
    payload: { email, password, full_name, company_name, provider, code },
    navigate,
  }
}
const userSignIn = (credentials, navigate) => ({
  type: SIGN_IN,
  payload: credentials,
  navigate,
})
const userLogout = () => ({ type: SIGN_OUT })
const setUserRole = (role, userId, withSuccessPopup) => ({
  type: SET_USER_ROLE,
  payload: { role, userId, withSuccessPopup },
})

const getInviteDetails = (code) => ({ type: GET_INVITE_DETAILS, payload: { code } })

const userConfirm = (userCode) => {
  return {
    type: CONFIRM_EMAIL,
    payload: { code: userCode },
  }
}

const userRequestResetPassword = (email, navigate) => ({
  type: REQUEST_RESET_PASSWORD,
  payload: { email },
  navigate,
})

const userResetPassword = (credentials, navigate) => ({
  type: RESET_PASSWORD,
  payload: credentials,
  navigate,
})

// SIDEBAR ACTIONS
const sidebarFetchById = (id, type, reload) => ({
  type: SIDEBAR_FETCH_BY_ID,
  payload: { type, id, reload },
})
const sidebarUpsert = (payload, navigate, skipFileUpload) => ({ type: SIDEBAR_UPSERT, payload, navigate, skipFileUpload })
const sidebarUpsertUser = (payload, navigate) => ({ type: SIDEBAR_UPSERT_USER, payload, navigate })
const sidebarUpsertTopics = (topicsObject) => ({ type: SIDEBAR_UPSERT_TOPICS, topicsObject })
const sidebarItemReset = () => ({ type: SIDEBAR_ITEM_RESET })
const sidebarItemSet = (item) => ({ type: SIDEBAR_ITEM_SET, item })
const sidebarItemCopy = (id, type) => ({ type: SIDEBAR_ITEM_COPY, payload: { id, type } })

const sidebarPostComment = (payload) => ({ type: SIDEBAR_POST_COMMENT, payload })

// LOCAL FILES ACTIONS
const inputFilesAdd = (files, fileGroup, filesType) => ({
  type: INPUT_FILES_ADD,
  files,
  filesType,
  fileGroup,
})
const inputFileRemove = (id, fileGroup) => ({ type: INPUT_FILE_REMOVE, filePreviewId: id, fileGroup })
const inputFilesClear = () => ({ type: INPUT_FILES_CLEAR })
const fileParse = (type, file, id, is_replace_existing) => ({
  type: FILE_PARSE,
  payload: { type, file, id, is_replace_existing },
})

const uploadAvatar = (file, { entityType, entityId, fileType, fileGroup }) => ({
  type: AVATAR_UPLOAD,
  file,
  params: { entityType, entityId, fileType, fileGroup },
})
const deletePhoto = ({ photoId, entityId, entityType, primaryPhotoIdKey }) => ({
  type: PHOTO_DELETE,
  payload: { photoId, entityId, entityType, primaryPhotoIdKey },
})

// DATA ACTIONS
const dataBulkOperation = (data) => ({ type: DATA_BULK_OPERATION, data })
const setDataType = (data, initWs) => ({ type: SET_DATA_TYPE, data, initWs })
const upsert = (data, navigate) => ({ type: UPSERT, payload: data, navigate })
const updateTableAndSystemData = ({ status, type, itemToSet, updatedData, skipTableDataUpdate, lng }) => ({
  type: UPDATE_TABLE_AND_SYSTEM_DATA,
  payload: { dataType: type, status, itemToSet, updatedData, skipTableDataUpdate, lng },
})
const dataFetchWithFacets = (data) => ({ type: DATA_FETCH_WITH_FACETS, data })
const dataSetSystemData = (key, data) => ({ type: DATA_SET_SYSTEM_DATA, key, data })

// FILTERS
const toggleFiltersPanel = (opened) => ({ type: FILTERS_TOGGLE_PANEL, opened })
const filterAdd = (key, value, lng, locationSearch, navigate) => ({
  type: FILTERS_FILTER_ADD,
  key,
  value,
  lng,
  locationSearch,
  navigate,
})
const filtersClearAll = (lng, locationSearch, navigate) => ({
  type: FILTERS_CLEAR_ALL,
  lng,
  locationSearch,
  navigate,
})
const filterRemove = (key, value, lng, locationSearch, navigate) => ({
  type: FILTERS_FILTER_REMOVE,
  key,
  value,
  lng,
  locationSearch,
  navigate,
})
const setApiUrlParam = (apiUrlParam) => ({ type: SET_API_URL_PARAM, apiUrlParam })

// MODIFICATIONS
const updateModificationCodeInSystem = (modId, code) => ({
  type: UPDATE_MODIFICATION_CODE_IN_SYSTEM,
  modId,
  code,
})

// BASKET
const updateBasket = (items) => ({
  type: UPDATE_BASKET,
  items,
})

const addProductToBasket = (item) => ({
  type: ADD_PRODUCT_TO_BASKET,
  item,
})

const addDemandToBasket = (item) => ({
  type: ADD_DEMAND_TO_BASKET,
  item,
})

const addToBasket = (item, key) => ({
  type: ADD_TO_BASKET,
  item,
  key,
})

const cleanBasket = () => ({
  type: CLEAN_BASKET,
})

// COMMON
const toggleNavMenuExtended = (extendedValue) => ({ type: TOGGLE_NAV_MENU_EXTENDED, extendedValue })
const toggleRightPanelExtended = (extendedValue) => ({
  type: TOGGLE_RIGHT_PANEL_EXTENDED,
  extendedValue,
})
const popupAlertShow = (data) => ({ type: POPUP_ALERT_SHOW, data })
const popupAlertHide = (id) => ({ type: POPUP_ALERT_HIDE, id })
const popupAlertHideAll = () => ({ type: POPUP_ALERT_HIDE_ALL })
const clearNotifications = () => ({ type: CLEAR_NOTIFICATIONS })
const setNotifications = (notification) => ({ type: SET_NOTIFICATIONS, notification })
const markNotificationAsOld = (id) => ({ type: MARK_NOTIFICATION_AS_OLD, id })

// FORMS
const updateFormValues = (key, values) => ({ type: UPDATE_FORM_VALUES_CHANGED, key, valuesChanged: values })
const resetFormValuesChanged = (key) => ({ type: RESET_FORM_VALUES_CHANGED, key })

export {
  addDemandToBasket,
  addProductToBasket,
  addToBasket,
  cleanBasket,
  clearNotifications,
  dataBulkOperation,
  dataFetchWithFacets,
  dataSetSystemData,
  deletePhoto,
  fileParse,
  filterAdd,
  filterRemove,
  filtersClearAll,
  getCurrentUser,
  getInviteDetails,
  initializeApp,
  inputFileRemove,
  inputFilesAdd,
  inputFilesClear,
  markNotificationAsOld,
  popupAlertHide,
  popupAlertHideAll,
  popupAlertShow,
  resetFormValuesChanged,
  setApiUrlParam,
  setDataType,
  setNotifications,
  setUserRole,
  sidebarFetchById,
  sidebarItemCopy,
  sidebarItemReset,
  sidebarItemSet,
  sidebarPostComment,
  sidebarUpsert,
  sidebarUpsertTopics,
  sidebarUpsertUser,
  toggleFiltersPanel,
  toggleNavMenuExtended,
  toggleRightPanelExtended,
  updateBasket,
  updateFormValues,
  updateModificationCodeInSystem,
  updateTableAndSystemData,
  uploadAvatar,
  upsert,
  userConfirm,
  userLogout,
  userRequestResetPassword,
  userResetPassword,
  userSignIn,
  userSignUp,
}
