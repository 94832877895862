import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { withTheme } from 'styled-components'

import { Icon, Typography, ErrorBoundary } from '@aidsupply/components'

import { StyledCard, StyledDashboardCards, StyledDashboardWrapper } from './styled'
import { getUserAccessLevel } from '../../utils/user'
import { MENU_ICONS_BY_TYPES, DASHBOARD_CARDS } from '../../config'
import NotificationsDashboard from '../../components/Notifications/NotificationsDashboard'
import { useMappedState } from '../../hooks'
import { selectDataByType } from '../../redux/selectors'

const mapStateToProps = (state) => {
  const {
    data: { dashboard },
    // user: {
    //     details: { roles },
    //   },
  } = state

  return { dashboard }
}

const Dashboard = ({ theme }) => {
  const dashboard = useMappedState(selectDataByType('dashboard'))
  const { t } = useTranslation('menu')
  // const [cardHovered, setCardHovered] = useState('')
  // #roles
  // const userAccessLevel = getUserAccessLevel(roles)
  // const cards = DASHBOARD_CARDS.filter((card) => card.accessLevel >= userAccessLevel) // TODO: refactor: simplify - remove DASHBOARD_CARDS, use backend structure for roles

  // const isNotificationsShown = !!notifications.length

  return (
    <StyledDashboardWrapper>
      <ErrorBoundary>
        <StyledDashboardCards>
          {DASHBOARD_CARDS.map((value) => {
            const valueObject = dashboard?.find((dashboardItem) => dashboardItem.name === value.key)

            if (!valueObject) {
              return null
            }

            return (
              <div className="gridItem" key={value.label}>
                <StyledCard
                  as={Link}
                  to={value.src}
                  // onMouseOver={() => setCardHovered(value.label)}
                  // onMouseOut={() => setCardHovered('')}
                  className={dashboard ? '' : 'inProgress'}
                >
                  <div className="column">
                    <div className="flexRow">
                      <Icon
                        borderRadius="50%"
                        fill={theme.color.general.light}
                        wrapperColor={theme.color.secondary.main}
                        wrapperHeight={46}
                        wrapperWidth={46}
                        width={22}
                        height={22}
                        name={MENU_ICONS_BY_TYPES[value.key]}
                      />
                      <Typography type="body1">{t(value.label)}</Typography>
                    </div>
                    <Typography className="number" type="h2">
                      {dashboard ? valueObject?.count || '0' : 'Load'}
                    </Typography>
                  </div>
                  {/*<Icon name="chartDummy" id={value.label} active={cardHovered === value.label} />*/}
                </StyledCard>
              </div>
            )
          })}
        </StyledDashboardCards>
      </ErrorBoundary>
      <ErrorBoundary>
        <NotificationsDashboard />
      </ErrorBoundary>
    </StyledDashboardWrapper>
  )
}

export default withTheme(Dashboard)
