import React from 'react'
import merge from 'deepmerge'
import { useNavigate } from 'react-router-dom'
import { object } from 'dot-object'
import { useDispatch } from 'react-redux'
import { isObject } from '@aidsupply/components'
import { slugify } from 'transliteration'

import { selectSidebarInitialData } from '../../../redux/selectors'
import { useMappedState } from '../../../hooks'
import { sidebarUpsert } from '../../../redux/actions'
import EditForm from '../../../components/RightPanelForms'

const AttributesRightPanel = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const isReadOnly = false

  const attributesInitial = useMappedState(selectSidebarInitialData)

  const onFormSubmit = (formValuesChanged) => {
    const requestBody = { ...object(formValuesChanged) }

    Object.keys(requestBody).map((key) => {
      if (isObject(requestBody[key]) && requestBody[key].id) {
        requestBody[key] = requestBody[key].id
      }
    })

    if (formValuesChanged.name) {
      requestBody.slug = slugify(formValuesChanged.name)
    }

    if (requestBody.translations)
      requestBody.translations = merge(attributesInitial.translations, requestBody.translations)

    const dataToSend = {
      id: attributesInitial.id,
      requestBody,
      type: 'attributes',
      parentType: 'system',
    }

    dispatch(sidebarUpsert(dataToSend, navigate))
  }
  return <EditForm isReadOnly={isReadOnly} onFormSubmit={onFormSubmit} />
}

export default AttributesRightPanel
