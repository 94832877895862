import { SIGN_OUT_SUCCESS, RESET_FORM_VALUES_CHANGED, UPDATE_FORM_VALUES_CHANGED } from '../constants'

const defaultState = {
  modifications: { valuesChanged: [] },
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case UPDATE_FORM_VALUES_CHANGED:
      return {
        ...state,
        [action.key]: { valuesChanged: [...state[action.key].valuesChanged, ...action.valuesChanged] },
      }
    case RESET_FORM_VALUES_CHANGED:
      return { ...state, [action.key]: defaultState[action.key] }
    case SIGN_OUT_SUCCESS:
      return defaultState
    default:
      return state
  }
}
