import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { withTheme } from 'styled-components'
import { useDispatch } from 'react-redux'
import { pick } from 'dot-object'

import { Icon, Label, Typography } from '@aidsupply/components'

import { STATES_ICONS, STATES_OPTIONS, STATUSES_ICONS, STATUSES_OPTIONS } from '../../config/table'
import { StyledNotificationItem } from './styled'
import { MENU_ICONS_BY_TYPES, SUBMENU } from '../../config'
import { markNotificationAsOld, sidebarFetchById, sidebarItemSet } from '../../redux/actions'
import { useMappedState } from '../../hooks'
import { selectDataType, selectSystemCollections } from '../../redux/selectors'

dayjs.extend(relativeTime)

const ICONS = {
  ...STATUSES_ICONS,
  ...STATES_ICONS,
  // update: 'reload',
  // create: STATUSES_ICONS.new,
}

const INFO = (language) => ({
  brands: 'name',
  carriers: 'name',
  orders: 'id',
  'stock-items': '',
  warehouses: 'name',
  items: 'model',
  categories: `label.${language}`,
  users: 'email', //general.firstName,general.lastName
  enumerations: `translations.${language}`,
  units: `translations.${language}`,
  attributes: `translations.${language}`,
  inquiries: 'id',
  invoices: 'id',
  contracts: 'id',
  organizations: 'name',
  shipments: 'id',
})

const mapStateToProps = (state) => ({
  system: state.data.system,
  type: state.data.type?.key,
})

const NotificationItem = ({ item, language, t, theme: { color } }) => {
  const dispatch = useDispatch()
  const type = useMappedState(selectDataType)
  const users = useMappedState(selectSystemCollections('users'))

  useEffect(() => {
    const packageName = `dayjs/locale/${language}`
    require(packageName)
  }, [language])

  const { operation, entity, collection, isNew } = item
  const date = dayjs(entity.created_at).locale(language).fromNow()

  const user = users && Object.values(users).find((user) => user.username === entity.user)

  const getText = () => {
    const statusOrStateObj = STATUSES_OPTIONS[operation] || STATES_OPTIONS[operation]
    const statusText = (
      statusOrStateObj ? statusOrStateObj.label[language] : t(`${operation}d`)
    ).toLowerCase()

    return t(collection, { status: statusText })
    // data.length === 1
    // : t('bulkStatusesUpdated', { status: statusText, count: data.length, collection: t(`${collection}Bulk`) }) //TODO: add i18n Plurals functionality
  }

  const getIconWrapperColor = () => {
    if (operation === 'create' || operation === 'new') {
      return color.status.new
    } else if (operation === 'update') {
      return color.primary.main
    } else if (operation === 'deleted' || operation === 'canceled') {
      return color.status.error
    } else if (operation === 'drafted') {
      return color.general.gray2
    } else {
      return color.status.success
    }
  }

  const getIcon = () => {
    if (operation === 'create' || operation === 'update' || operation === 'new') {
      return MENU_ICONS_BY_TYPES[collection]
    } else {
      return ICONS[collection]
    }
  }

  const getInfo = () => {
    const key = INFO(language)[collection]
    const text = key && pick(key, entity)

    return text && collection === 'orders' ? `#${text}` : text
  }

  const onItemClick = () => {
    if (type !== collection) {
      dispatch(sidebarFetchById(entity.id, collection))
    } else {
      dispatch(sidebarItemSet(entity))
    }
    dispatch(markNotificationAsOld(entity.id))
  }

  return (
    <StyledNotificationItem
      className={isNew ? 'new' : undefined}
      // noStyles
      // Link={Link}
      // onClick={onItemClick}
      // to={`${
      //   Object.values(SUBMENU)
      //     .flat()
      //     .find((menuItem) => menuItem.key === collection)?.route
      // }/${entity.id}`}
    >
      <Icon
        name={getIcon()}
        fill={color.general.light}
        shade={
          (operation === 'create' || operation === 'update' || operation === 'new') && getIconWrapperColor()
        }
        wrapperColor={getIconWrapperColor()}
        wrapperHeight={37}
        wrapperWidth={37}
        width={22}
        height={22}
        borderRadius="50%"
      />
      <div className="content">
        <Typography type="body2" component="div" className="firstRow">
          {getText()}
        </Typography>
        {getInfo() && (
          <div className="info row">
            <Typography type="body1">{getInfo()}</Typography>
          </div>
        )}
        {/*<Typography collection="body2">{t(`table:${DATA_TYPE_TO_SINGULAR_FORM[collection]}`)}</Typography>*/}
        <div className="labels row">
          {user && (
            <Label
              collection="smaller"
              text={`${user.general.firstName} ${user.general.lastName}`}
              color={color.general.gray5}
            />
          )}
          <Label collection="smaller" text={date} color={color.general.gray5} />
        </div>
      </div>
    </StyledNotificationItem>
  )
}

export default withTheme(NotificationItem)
