import React from 'react'
import {
  InfoRowsBlock,
  Image,
  IMAGEKIT_PARAMS_CONFIG,
  Typography,
  NoImageAvailable,
} from '@aidsupply/components'
import ReactTexty from 'react-texty'

import { IMAGEKIT_URL } from '../../../constants'
import { StyledCard } from './styled'

const CategoriesTableCard = ({ className, data, initialData }) => {
  const { translations, state, photo_url, parent_translations, updated_at, checkbox } = data

  return (
    <StyledCard>
      {photo_url ? (
        <Image
          src={photo_url}
          height={104}
          width={100}
          maxWidth={100}
          imagekitParams={IMAGEKIT_PARAMS_CONFIG.crm.thumbnail}
          imagekitUrl={IMAGEKIT_URL}
          alt="photo item"
        />
      ) : (
        <NoImageAvailable height={124} width={100} iconWidth={36} iconHeight={36} />
      )}
      <InfoRowsBlock
        className="infoRowsBlock"
        data={[
          {
            title: {
              customElement: (
                <Typography as={ReactTexty} type="h4">
                  {translations}
                </Typography>
              ),
              width: 'calc(100% - 50px)',
              hideBorder: true,
              padding: '14px 0 14px 10px',
            },
            state: {
              customElement: state,
              width: '26px',
              hideBorder: true,
              padding: '14px 0',
            },
            checkbox: {
              customElement: checkbox,
              width: '25px',
              padding: '14px 10px 14px 0',
            },
          },
          {
            updated_at: {
              iconProps: {
                name: 'at',
              },
              text: updated_at,
              width: '50%',
              hideBorder: !parent,
              padding: '14px 10px 14px 10px',
            },
            ...(parent_translations && {
              parent: {
                iconProps: {
                  name: 'flowchart',
                },
                text: parent_translations,
                width: '50%',
                hideBorder: true,
                padding: '14px 10px 15px 10px',
              },
            }),
          },
        ]}
      />
    </StyledCard>
  )
}

export default CategoriesTableCard
