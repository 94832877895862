import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { ScreenContext, Badge, FlexRow, Typography, HeaderMenuIcon } from '@aidsupply/components'

import { useMappedState } from '../../../hooks'
import { selectTableDataCount } from '../../../redux/selectors'

const TableTitleBlock = ({ title, titleKey, withMenuIcon, menuIconProps, withBadge, children }) => {
  const { t } = useTranslation('menu')
  const { width, md } = useContext(ScreenContext)

  const total_items = useMappedState(selectTableDataCount)

  return (
    <FlexRow className="tableTitleRow" justifyContent={'start'}>
      <Typography type="h3">{title || t(`menu:${titleKey}`)}</Typography>
      {withBadge && <Badge returnBadgeOnly badgeContent={total_items || 0} className="itemsCountBadge" />}
      {withMenuIcon && <HeaderMenuIcon className="menuIcon" {...menuIconProps} />}
      {children}
    </FlexRow>
  )
}

export default TableTitleBlock

TableTitleBlock.defaultProps = {
  withBadge: true,
}
