import React from 'react'
import { useNavigate } from 'react-router-dom'
import { object } from 'dot-object'
import { isObject } from '@aidsupply/components'
import { useDispatch } from 'react-redux'

import EditForm from '../../../components/RightPanelForms'
import { selectSidebarInitialData, selectAllSystemCollections } from '../../../redux/selectors'
import { useMappedState } from '../../../hooks'
import { sidebarUpsert } from '../../../redux/actions'

const InventoriesRightPanel = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const sidebarInitialData = useMappedState(selectSidebarInitialData)
  const system = useMappedState(selectAllSystemCollections)

  const onFormSubmit = (formValuesChanged) => {
    const requestBody = { ...object(formValuesChanged) }

    Object.keys(requestBody).map((key) => {
      if (isObject(requestBody[key]) && requestBody[key].id) {
        requestBody[key] = requestBody[key].id
      }
    })

    requestBody.state = formValuesChanged.state?.id || sidebarInitialData.state

    if (formValuesChanged.inventory_items?.length) {
      requestBody.inventory_items = formValuesChanged.inventory_items.map((item) => {
        const itemId = item.item_id?.id || item.id || item.item_id
        return {
          inventory_id: sidebarInitialData.id || undefined,
          item_id: item.item_id?.id || item.id || item.item_id,
          quantity: item.quantity,
          price: item.price,
          id: item.id,
          modification_id: item.modification_id,
          state: item.state,
        }
      })
    }

    const dataToSend = {
      id: sidebarInitialData.id,
      requestBody,
      type: 'inventories',
      parentType: 'inventory',
    }

    dispatch(sidebarUpsert(dataToSend, navigate))
  }

  const isReadOnly = sidebarInitialData?.state === 'posted' || sidebarInitialData?.state === 'deleted'

  return <EditForm onFormSubmit={onFormSubmit} isReadOnly={isReadOnly} />
}

export default InventoriesRightPanel
