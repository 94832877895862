import { slugify } from 'transliteration'
import dot from 'dot-object'

import { isObject } from '@aidsupply/components'

import { POSTGRES_TYPES } from '../apiConstants'

export const countryCodeToFlagEmoji = (countryCode) => {
  if (countryCode?.length !== 2) {
    return '' // Country code should be 2 characters
  }
  return countryCode
    .toUpperCase()
    .split('')
    .map((letter) => String.fromCodePoint(0x1f1e6 + letter.charCodeAt(0) - 'A'.charCodeAt(0)))
    .join('')
}
// export const buildKey = name =>
//   tr(name)
//     .toLowerCase()
//     .replace(/(?!^)\b./g, a => a.toUpperCase())
//     .replace(/\s/g, '') transliterate as tr,

export const transformDataForServer = (id, values, type) => {
  const keys = Object.keys(values)

  //TODO: logic refactor
  const initialDataObj =
    type === 'brands' && values['name']
      ? { hasBrandPage: false, slug: slugify(values['name']) }
      : (type === 'categories' && values['name']) ||
        (type === 'attributes' && !id) ||
        (type === 'enumerations' && !id)
      ? { slug: slugify(values['name']) }
      : {}

  //TODO: logic refactor
  const newValues = Object.values(values).reduce((result, value, i) => {
    let transformedValue

    if (value) {
      if (isObject(value)) {
        if (type === 'warehouses' && keys[i] === 'currency') {
          transformedValue = value.code
        } else if (
          ['state', 'status', 'defaultLanguage', 'language'].includes(keys[i]) ||
          keys[i].endsWith('_id') ||
          POSTGRES_TYPES.includes(type)
        ) {
          transformedValue = value.id
        } else if (keys[i] === 'brand') {
          transformedValue = { id: value.id, name: value.name, slug: value.slug }
        } else if (keys[i] === 'category') {
          transformedValue = { id: value.id, slug: value.slug }
        } else {
          transformedValue = { id: value.id }
          if (value.slug) {
            transformedValue.slug = value.slug // TODO: to delete other slug assignments
          }
        }
      } else if (Array.isArray(value)) {
        if (
          (type === 'inquiries' && keys[i] === 'inquiry_items') ||
          (type === 'orders' && keys[i] === 'order_items') ||
          (type === 'inventories' && keys[i] === 'inventory_items')
        ) {
          transformedValue = value
        } else if (type === 'shipments' && keys[i] === 'shipment_items') {
          transformedValue = value.map((item) => ({
            item_id: item.id,
            quantity: item.quantity,
            state: item.state || 'drafted',
          }))
        } else if (type === 'enumerations' && keys[i] === 'options') {
          transformedValue = value.map((v) => (v.icon?.id ? { ...v, icon: v.icon.id } : v))
        } else if (keys[i] === 'modification_ids') {
          transformedValue = value.map((v) => v.modification_oid)
        } else if (keys[i].startsWith('characteristics') || keys[i].startsWith('properties')) {
          transformedValue = value.map((v) => ({ id: v.id, slug: v.slug }))
        } else {
          transformedValue = value.map((val) => {
            const newValue = {
              id: val.id,
            }
            if (val.slug) {
              newValue.slug = val.slug // TODO: to delete other slug assignments
            }

            return newValue
          })
        }
      } else {
        transformedValue = value
      }
      // else if (type === 'orders' && keys[i] === 'order_items') {
      //   initialDataObj.total = 0
      //   transformedValue = value.map(item => {
      //     const sum = item.price * item.quantity
      //     initialDataObj.total += sum
      //
      //     return {
      //       ...item,
      //       sum,
      //     }
      //   })
      // }
    } else {
      // if (type === 'users' && keys[i] === 'organization_id') {
      //   transformedValue = ''
      // } else {
      transformedValue =
        keys[i].includes('price') || keys[i] === 'discount' || keys[i] === 'quantity' ? 0 : value
      // }
    }

    return { ...result, [keys[i]]: transformedValue }
  }, initialDataObj)

  return dot.object(newValues)
}
export const getSlugsFromCharacteristicValue = (value) => {
  console.log(value)
  if (!value) {
    return ''
  } else if (!value.startsWith('id-')) {
    return value
  }
  const characteristics = value.split(':')
  const slugs = characteristics.map((characteristic) => characteristic.split('-slug-')?.[1])

  return slugs.join(':')
}

export const getCurrencyObjectById = (currencyId, currencies) => {
  return currencies && currencies[currencyId]
}
