import styled from 'styled-components'
import { getTokens } from '@aidsupply/components'

export const StyledSelect = styled.div`
  .selectWrapper {
    min-width: ${({ minWidth }) => minWidth};

    &:not(.isFullSize) {
      .react-select__menu-list {
        .react-select__option {
          padding: 12px 10px;
        }
        .icon.rightIcon {
          padding: 8px;
        }
      }
    }
  }

  .react-select__indicators {
    svg path {
      fill: ${({ theme }) => theme.color.general.gray2};
    }
  }

  .react-select__value-container {
    padding: 9px !important;

    .react-select__single-value {
      position: relative;
      transform: none;
      max-width: none;
      color: ${({ theme }) => theme.color.general.gray5};
    }
  }
  .iconText {
    margin-left: 10px;
  }

  @media only screen and (max-width: 1139px) {
    .selectWrapper {
      border-color: ${({ theme }) => theme.color.general.gray2};
      margin-left: auto;
      width: fit-content;

      .react-select__value-container .react-select__single-value {
        color: ${({ theme }) => theme.color.general.gray5};
      }
      .leftIcon path {
        stroke: ${({ theme }) => theme.color.general.gray5};
      }

      .chevronDown path {
        fill: ${({ theme }) => theme.color.general.gray5};
        stroke: none;
      }
    }
  }

  @media only screen and (max-width: 639px) {
    .react-select__menu {
      right: 0;
      width: max-content !important;
    }

    .react-select__value-container .react-select__single-value {
      color: ${({ theme }) => theme.color.general.gray5};
      line-height: 23px;
    }
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    .react-select__indicators {
      padding-right: 9px;
    }
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    .selectWrapper {
      border: 1px solid ${({ theme }) => theme.color.general.gray2};

      .chevronDown path {
        fill: ${({ theme }) => theme.color.general.gray5};
        stroke: none;
      }
      .react-select__value-container {
        padding: 10px 5px 10px 15px;
      }
      .react-select__indicators {
        padding-right: 10px;
      }
      .react-select__indicator {
        padding: 0;
      }
    }
  }
`
