import { parse } from 'query-string'
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { createSearchParams, useLocation, useNavigate, useParams } from 'react-router-dom'
import { withTheme } from 'styled-components'

import {
  EmptyTable,
  ErrorBoundary,
  Loader,
  ScreenContext,
  Table,
  usePrevious,
  VirtualizedList,
} from '@aidsupply/components'

import { RIGHT_PANEL_CREATE_ROUTES } from '../../config'
import { getColumns } from '../../config/columns'
import { EDITABLE_TABLE_NAVBAR_FORM_CONFIG } from '../../config/editableTableNavbarForms'
import { getTableSummary } from '../../config/tableSummary'
import { HEADER_HEIGHT } from '../../constants'
import { useMappedState } from '../../hooks'
import { dataFetchWithFacets } from '../../redux/actions'
import {
  selectAllSystemCollections,
  selectApiUrlParam,
  selectAreFiltersChosen,
  selectDataByType,
  selectDataTypeObject,
  selectMainDataInitialized,
  selectMainDataLoading,
  selectTableDataCount,
  selectUserDetails,
} from '../../redux/selectors'
import { sortObjectToUrlString } from '../../utils/table'
import EmptyScreen from '../EmptyScreen'
import TableTitleBlock from './components/TableTitleBlock'
import EditableTableNavBar from './EditableTableNavBar'
import { useSorting } from './hooks/useSorting'
import { useTableModeAndSize } from './hooks/useTableModeAndSize'
import NavBar from './NavBar'
import SearchCategoryCard from './SearchCategoryCard'
import SearchProductCard from './SearchProductCard'
import { StyledContent } from './styled'
import useEditableTable from './useEditableTable'

const TableBlock = ({
  additionalFormValues,
  changingBlocksDependencyValue,
  createNewOptions,
  className,
  editable,
  editableNavbarFields,
  emptyScreenChildren,
  formId,
  iconEmpty,
  isDeletionByState,
  isMainTable,
  isReadOnly,
  isTableWithTitle,
  isPaginationDisabled,
  menuIconProps,
  navbarHidden,
  navbarProps = {},
  queryParams,
  rowKey,
  selectable,
  TableCardContent,
  tableCardHeight,
  tableTitleChildren,
  tableModeInitial,
  textsChosenLng,
  theme,
  updateTableRows,
  validationRules,
  withMenuIcon,
  ...rest
}) => {
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation('table')
  const location = useLocation()
  const navigate = useNavigate()
  const { width: screenWidth, sm } = useContext(ScreenContext)
  const searchParsed = parse(location.search)
  const prevSearchValue = usePrevious(searchParsed.searchValue)

  const params = useParams()
  const editedItemId = params?.rightPanelId

  const activeRowId = editedItemId
  const prevRowId = usePrevious(activeRowId)

  const gridRef = useRef(null)
  const contentRef = useRef(null)

  const inProgress = useMappedState(selectMainDataLoading)
  const isDataInitialized = useMappedState(selectMainDataInitialized)
  const itemsTotalCount = useMappedState(selectTableDataCount)
  const user = useMappedState(selectUserDetails)

  // const total_items = useMappedState(selectTableDataCount)
  const system = useMappedState(selectAllSystemCollections)
  const areFiltersChosen = useMappedState(selectAreFiltersChosen)
  const typeData = useMappedState(selectDataTypeObject)
  const type = typeData?.key
  const data = useMappedState(selectDataByType(type))

  const initialDataRef = useRef(data)

  const [tableData, setTableData] = useState(data)
  const tableDataWithoutDeleted = isDeletionByState
    ? tableData?.filter((item) => item.state !== 'deleted')
    : tableData

  const withCustomCards = type === 'products' || type === 'inquiry_items'

  useEffect(() => {
    setTableData(data)
  }, [data])

  const [selectedRows, setSelectedRows] = useState([])
  const [isAllSelected, setAllSelected] = useState(false)

  const resetSelectedRows = useCallback(() => {
    setSelectedRows([])
    setAllSelected(false)
  }, [])

  const selectedApiUrlParam = useMappedState(selectApiUrlParam)

  // TODO: move to ./utils
  const onRowSelect = ({ rowData, rowIndex, selected }) => {
    const selectedValue = typeof selected !== 'boolean' ? selected.selected : selected
    if (!rowData) {
      if (selectedValue) {
        setSelectedRows(tableDataWithoutDeleted.map((val) => val[rowKey || 'id']))
        setAllSelected(true)
      } else {
        resetSelectedRows()
      }
    } else {
      if (selectedValue) {
        setSelectedRows([...selectedRows, rowData[rowKey || 'id']])
      } else {
        setSelectedRows(selectedRows.filter((val) => val !== rowData[rowKey || 'id']))
      }
    }
  }

  const { onBlurCellInput, onDeleteClick } = useEditableTable({
    dispatch,
    isDeletionByState,
    rowKey,
    tableData: tableDataWithoutDeleted,
    type,
    initialData: initialDataRef?.current,
    setTableData,
    selectedRows,
    textsChosenLng,
    updateTableRows,
  })

  const isEmptyTable =
    isDataInitialized &&
    !areFiltersChosen &&
    !tableDataWithoutDeleted?.length &&
    !searchParsed?.searchValue &&
    inProgress === false
  // !(['system', 'administrator'].includes(user.role) && typeData.key === 'stock-items')

  const gap = 20
  const tableRowHeight = type === 'products' || type === 'inquiry_items' ? 355 : 44
  const tableHeaderHeight = 44
  const tableRowGap = 4
  const navbarHeight = screenWidth <= 415 ? 123 : 66

  const isMobile = screenWidth && screenWidth < theme.breakpoints.lg

  const isPageWithHeader = screenWidth && screenWidth < theme.breakpoints.xl
  const headerHeightNum = +HEADER_HEIGHT.replace('px', '')
  const topHeight =
    (isPageWithHeader ? headerHeightNum : 0) + (isTableWithTitle ? headerHeightNum : 0) + navbarHeight
  // const { top } = useElementRect(contentRef, 'resize')
  // const topHeight = top + parseInt(gap, 10) / 2 + 2 || 0

  const [gridColCount, setGridColCount] = useState(1)

  const { tableMode, onTableResize, onListResize, getMaxTableHeight, isDropdownSearch } = useTableModeAndSize(
    {
      type,
      // dataLength: tableDataWithoutDeleted?.length,
      topHeight,
      gap,
      gridRef,
      // gridColCount,
    }
  )

  const columnsFromConfig = getColumns(type, i18n.language, t, tableMode) || []
  const columns = columnsFromConfig.filter(
    (column) => !column.getIsHidden || !column.getIsHidden({ role: user.role })
  )

  useEffect(() => {
    if (tableMode === 'table') {
      setGridColCount(1)
    }
  }, [tableMode])

  const maxTableHeight = getMaxTableHeight(
    tableMode === 'table' ? tableRowHeight : tableCardHeight || tableRowHeight,
    tableHeaderHeight
  )

  const maxTableRowsCount = Math.floor(maxTableHeight / (tableRowHeight + tableRowGap))

  const maxListRowsCount = useRef()

  const { sortState, onColumnSort } = useSorting(type)

  useEffect(() => {
    if (maxTableHeight) {
      maxListRowsCount.current = maxTableRowsCount

      dispatch(
        dataFetchWithFacets({
          type,
          locationSearch: location.search,
          sortString: sortObjectToUrlString(sortState),
          pageLimit: !isPaginationDisabled && maxTableRowsCount,
          pageOffset:
            !isPaginationDisabled &&
            (!prevSearchValue && searchParsed.searchValue
              ? undefined
              : searchParsed.page && (searchParsed.page - 1) * maxTableRowsCount),
          // tableMode,
          query: [...(queryParams || [])],
          lng: i18n.language,
          apiUrlParam: selectedApiUrlParam,
        })
      )
    }
  }, [type, location.search, maxTableRowsCount, maxTableHeight, queryParams, selectedApiUrlParam])

  useEffect(() => {
    if (searchParsed.page && !prevSearchValue && searchParsed.searchValue) {
      delete searchParsed.page
      navigate({
        search: createSearchParams(searchParsed).toString(),
      })
    }
  }, [prevSearchValue, searchParsed])

  const onRowClick = useCallback(
    (data) => {
      // dispatch(inputFilesClear())
      // prevRowId.current = data.rowKey?.toString()
      navigate({
        pathname: data.rowKey ? `${data.rowKey}` : type,
        search: createSearchParams(searchParsed).toString().replace(/%2C/g, ','),
      })
    },
    [dispatch, searchParsed]
  )

  // if (!searchValue && !(['system', 'administrator'].includes(user.role) && typeData.key === 'stock-items') && isSubType && !items.length && !areFiltersChosen) {
  //   return getLoadingOrNoData(true)
  // }
  const isDataInProgress =
    ((!searchParsed.searchValue && !tableDataWithoutDeleted?.length) || !isDataInitialized) && inProgress

  const cellProps = { system, lng: i18n.language, user, is_read_only: isReadOnly }

  const navBarProps = {
    addBtnDisabled: !tableDataWithoutDeleted?.length && !!searchParsed?.searchValue,
    cellProps,
    columns,
    data,
    gridColCount,
    isDropdownSearch,
    isMainTable,
    isPaginationDisabled,
    onRowClick,
    pageLimit: maxTableRowsCount,
    prevSearchValue,
    resetSelectedRows,
    selectable,
    selectedRows,
    sortString: sortObjectToUrlString(sortState),
    tableMode,
    typeData,
    ...navbarProps,
  }

  const getTableComponent = () => {
    if (isDataInProgress) {
      return <Loader size="60px" top="50%" left="50%" />
    }

    if (isEmptyTable) {
      return (
        <EmptyScreen role={user.role} type={type} icon={iconEmpty} btnLabelType={typeData.labelSingle}>
          {emptyScreenChildren}
        </EmptyScreen>
      )
    }

    switch (tableMode) {
      case 'table': {
        const summaryData =
          tableDataWithoutDeleted &&
          getTableSummary(type, t, tableDataWithoutDeleted, {
            currency:
              additionalFormValues?.currency_id?.symbol ||
              system?.currencies?.[additionalFormValues?.currency_id]?.symbol,
          })
        return (
          <Table
            cellProps={cellProps}
            columns={columns}
            data={tableDataWithoutDeleted}
            // editable={!isReadOnly && editable}
            isAllSelected={isAllSelected}
            isReadOnly={isReadOnly}
            // isSelectAllDisabled={POSTGRES_TYPES.includes(type)}
            emptyText={t(inProgress ? t('loadingOrNoData:loading') : 'noItems')}
            headerHeight={tableHeaderHeight}
            maxHeight={maxTableHeight}
            onBlurCellInput={onBlurCellInput}
            onColumnSort={onColumnSort}
            onResize={onTableResize}
            onRowClick={onRowClick}
            onRowSelect={onRowSelect}
            // paddingsAndBorders={20}
            rowClassName={({ rowData }) => {
              const isInactive = rowData.status === 'inactive' ? 'inactive' : ''
              const isHighlightedRow =
                activeRowId && !RIGHT_PANEL_CREATE_ROUTES.includes(activeRowId)
                  ? activeRowId === rowData.id?.toString()
                  : prevRowId?.current && rowData.id?.toString() === prevRowId.current

              const activeRow = isHighlightedRow ? 'activeRow' : ''

              return `${isInactive} ${activeRow}`
            }}
            rowHeight={tableRowHeight || 50}
            rowKey={rowKey || 'id'}
            selectable={selectable}
            selectedRowKeys={selectedRows}
            sortState={sortState}
            summaryData={summaryData}
            textsChosenLng={textsChosenLng}
            // onChangeCellInput={onChangeCellInput}
            // onSubmitCellInput={onSubmitEditCellInput}
            // emptyText={t('nothingFound')}
            // maxHeight={maxHeight || getMaxTableHeight(50, 48)}
            // topHeight={topHeight}
            // paddingsAndBorders={(screenWidth < sm ? 20 : 40) + 2}
            {...rest}
          />
        )
      }
      case 'cards': {
        const onSetGridColCount = (width) => {
          if (withCustomCards) {
            setGridColCount(width < 500 ? 1 : width < 750 ? 2 : width < 1050 ? 3 : 4)
          } else {
            setGridColCount(width < 650 ? 1 : width < 1050 ? 2 : 3)
          }
        }

        return tableDataWithoutDeleted?.length ? (
          <VirtualizedList
            activeRowId={activeRowId}
            cellProps={{ container: { props: { cellProps } } }}
            columns={columns}
            columnCount={gridColCount}
            editedItemId={editedItemId}
            gridRef={gridRef}
            initialPageNumber={1}
            // isSelectAllDisabled={POSTGRES_TYPES.includes(type)}
            itemSize={(index) => (tableDataWithoutDeleted.length - 1 === index ? 80 + 40 : 80 + 20)} // tableMode === 'list'
            items={tableDataWithoutDeleted}
            itemsTotalCount={itemsTotalCount}
            CustomListItem={
              (type === 'products' && SearchProductCard) || (type === 'inquiry_items' && SearchCategoryCard)
            }
            onItemClick={onRowClick}
            onItemSelect={onRowSelect}
            onResize={onListResize}
            onSetColumnCount={onSetGridColCount}
            rowHeight={tableCardHeight || (type === 'products' || type === 'inquiry_items' ? 394 : 115)}
            rowKey={rowKey || 'id'}
            selectable={selectable}
            selectedRowKeys={selectedRows}
            sortState={sortState}
            t={t}
            TableCardContent={TableCardContent}
            type={type}
            variant={tableMode === 'cards' && 'grid'}
            // hasNextPage={itemsTotalCount > tableData.length}
            // isNextPageLoading={inProgress}
            {...rest}
          />
        ) : (
          <EmptyTable className="emptyList" text={t(inProgress ? t('loading') : 'nothingFound')} />
        )
      }
    }
  }

  return (
    <>
      {isTableWithTitle && (
        <TableTitleBlock titleKey={typeData.label} withMenuIcon={withMenuIcon} menuIconProps={menuIconProps}>
          {!isMobile && tableTitleChildren}
        </TableTitleBlock>
      )}
      <ErrorBoundary>
        {!navbarHidden &&
          (!isReadOnly && editableNavbarFields ? (
            <EditableTableNavBar
              additionalFormValues={additionalFormValues}
              formId={formId}
              changingBlocksDependencyValue={changingBlocksDependencyValue}
              createNewOptions={createNewOptions}
              data={tableDataWithoutDeleted}
              fields={editableNavbarFields}
              // onAddIconImage={onAddIconImage}
              onDeleteClick={onDeleteClick}
              optionsData={system}
              setTableData={setTableData}
              t={t}
              transformAddedResult={EDITABLE_TABLE_NAVBAR_FORM_CONFIG[type]?.transformAddedResult}
              typeData={typeData}
              updateTableRows={updateTableRows}
              validationRules={validationRules}
              withIconsSelect
            />
          ) : (
            !navbarProps.isHidden &&
            isDataInitialized && (
              <NavBar
                tableMode={tableMode}
                rowsCount={maxTableRowsCount} //tableMode === 'table' ? maxTableRowsCount : 50
                itemsTotalCount={itemsTotalCount}
                // className={clsx((isDataInProgress || isEmptyTable) && 'transparent')}
                {...navBarProps}
              />
            )
          ))}
      </ErrorBoundary>
      {isTableWithTitle && isMobile && tableTitleChildren}
      <StyledContent ref={contentRef} topHeight={topHeight} maxHeight={maxTableHeight} className={className}>
        {getTableComponent()}
      </StyledContent>
    </>
  )
}

export default withTheme(TableBlock)

TableBlock.defaultProps = {
  isMainTable: true,
  isTableWithTitle: true,
}
