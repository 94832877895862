import React, { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { withTheme } from 'styled-components'
import { deviceType } from 'detect-it'
import ReactTexty from 'react-texty'
import { useLocation, useNavigate } from 'react-router-dom'

import {
  Icon,
  ItemCharacteristicsToChoose,
  Price,
  Tile,
  buildImagePath,
  IMAGEKIT_PARAMS_CONFIG,
  useMappedState,
  getAvailableTranslation,
  useUnmount,
  useStock,
  getItemPhotosByColor,
  transformItemForOrder,
  StyledSearchItem,
  getCurrencyObjectByCode,
} from '@aidsupply/components'

import { addProductToBasket, sidebarItemSet } from '../../redux/actions'
import { DEFAULT_VALUES_DATA } from '../../data/defaultValues'

const mapStateToProps = (state) => state.data.system

const imagekitParams = IMAGEKIT_PARAMS_CONFIG.platform.search.item

const SearchProductCard = ({ data, theme: { color } }) => {
  const {
    t,
    i18n: {
      language,
      options: { defaultLng },
    },
  } = useTranslation('table')
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const { attributes, enumerations, currencies } = useMappedState(mapStateToProps)

  const [attributesChosen, setAttributesChosen] = useState({})

  const [itemAddedToBasket, setItemAddedToBasket] = useState('')

  const { chosenModStock, itemOutOfStock } = useStock(attributesChosen, data?.modifications)

  const currency = getCurrencyObjectByCode(chosenModStock?.currency, currencies)

  const outOfStock = itemOutOfStock || !chosenModStock.quantity

  const { photos, texts } = data || {}
  const title = getAvailableTranslation(texts?.title, defaultLng, language)

  const { itemPhotos, photosType } = getItemPhotosByColor(attributesChosen, photos)

  const onAddItemClick = useCallback(
    (item) => () => {
      const newBasketItem = transformItemForOrder(item, chosenModStock, attributesChosen, 1, currency)
      dispatch(addProductToBasket(newBasketItem))
      setItemAddedToBasket(item.id)
      if (location.pathname !== '/products/new') {
        dispatch(sidebarItemSet(DEFAULT_VALUES_DATA.products))
        navigate('/products/new')
      }
      window.basketItemAddedTimeout = setTimeout(() => {
        setItemAddedToBasket('')
      }, 1500)
    },
    [chosenModStock, dispatch, attributesChosen, currency]
  )

  useUnmount(() => {
    clearTimeout(window.basketItemAddedTimeout)
  })

  const getButtonContent = useCallback(() => {
    return (
      <Icon
        name={itemAddedToBasket === data?.id ? 'checkRounded' : 'addToBasket'}
        fill={itemAddedToBasket === data?.id ? color.status.success : color.primary.main}
        height={21}
        width={21}
      />
    )
  }, [itemAddedToBasket])

  return (
    <StyledSearchItem key={data.id} className={deviceType}>
      <Tile
        imagekitParams={imagekitParams}
        withButton={!outOfStock}
        buttonProps={{
          variant: 'icon',
          content: getButtonContent(),
          onClick: onAddItemClick(data),
          size: 'small',
        }}
        descriptionProps={{
          type: 'body1',
        }}
        radius={4}
        title={<ReactTexty>{title}</ReactTexty>}
        description={<Price currency={currency} itemOutOfStock={outOfStock} stock={chosenModStock} t={t} />}
        imageSrc={buildImagePath('items', data?.id, photosType, itemPhotos?.[0])}
        titleProps={{
          type: 'body1',
        }}
        imageHeight={200}
        imageWidth={190}
      >
        <ItemCharacteristicsToChoose
          attributes={attributes}
          enumerations={enumerations}
          attributesChosen={attributesChosen}
          item={data}
          setAttributesChosen={setAttributesChosen}
          t={t}
        />
      </Tile>
    </StyledSearchItem>
  )
}

export default withTheme(SearchProductCard)
