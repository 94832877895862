import { FlexRow } from '@aidsupply/components'
import styled from 'styled-components'

export const StyledThumbnails = styled.div`
  &.filesContainer {
    margin-top: 20px;
  }
  .thumbnail {
    border: ${({ theme }) => `1px solid ${theme.color.general.gray1}`};
    &:not(:last-child) {
      border-bottom: none;
    }
    &:first-child {
      border-top-left-radius: ${({ theme }) => theme.size.border.radius.main};
      border-top-right-radius: ${({ theme }) => theme.size.border.radius.main};
    }
    &:last-child {
      border-bottom-left-radius: ${({ theme }) => theme.size.border.radius.main};
      border-bottom-right-radius: ${({ theme }) => theme.size.border.radius.main};
    }
  }
`

export const StyledThumbnail = styled(FlexRow)`
  gap: 10px;
  padding: 12px;
  border: 1px solid transparent;

  &.saved {
    //display: none;
    border: 1px solid ${({ theme }) => theme.color.general.gray2};
  }

  &.error {
    border: 1px solid ${({ theme }) => theme.color.status.error};
  }

  &.isDeleted {
    opacity: 0.5;
  }
`
