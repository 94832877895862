import React, { forwardRef } from 'react'
import { useDispatch } from 'react-redux'

import { Button, Icon, Image, Typography } from '@aidsupply/components'
import clsx from 'clsx'

import { deletePhoto } from '../../../redux/actions'
import { StyledBanner } from './styled'
import { useTranslation } from 'react-i18next'
import { withTheme } from 'styled-components'
import { selectSidebarUpsertInProgress } from '../../../redux/selectors'
import { useMappedState } from '../../../hooks'

const OrganizationBanner = forwardRef(({ children, entityId, photoId, src, theme }, ref) => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['general', 'table'])

  const inProgress = useMappedState(selectSidebarUpsertInProgress)

  const content = (
    <div className="container">
      <Typography
        type="h3"
        color={theme.color.general.light}
        fontWeight={700}
        text={t('replaceBannerImage')}
      />
      <Typography
        type="body1"
        color={theme.color.general.light}
        fontWeight={500}
        text={t('optimalDimensions')}
      />
      <div className={clsx('containerButton', { noImage: !src })}>
        <Button
          variant="bordered"
          onClick={() => {
            if (photoId) {
              dispatch(
                deletePhoto({
                  photoId,
                  entityId,
                  entityType: 'organizations',
                  primaryPhotoIdKey: 'banner_photo_id',
                })
              )
            }
          }}
          disabled={inProgress || !photoId}
        >
          {t(`table:delete`)}
        </Button>
        <Button variant="primary" disabled={inProgress}>
          {t('replaceImage')}
          {children}
        </Button>
      </div>
    </div>
  )

  return (
    <>
      {src ? (
        <StyledBanner src={src}>
          <Image src={src} width="100%" maxHeight={'210px'} alt="Banner" />
          {content}
        </StyledBanner>
      ) : (
        <StyledBanner ref={ref} src={src}>
          {content}
        </StyledBanner>
      )}
    </>
  )
})

export default withTheme(OrganizationBanner)
