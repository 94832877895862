import clsx from 'clsx'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Icon, Typography } from '@aidsupply/components'

import { EDITABLE_TABLE_NAVBAR_FORM_CONFIG } from '../../config/editableTableNavbarForms'
import { COLLAPSED_FORM_BLOCKS, FORM_CONFIGS } from '../../config/forms'
import INFO_PANEL_CONFIG from '../../config/infoPanel'
import InfoPanel from '../InfoPanel/InfoPanel'
import FormBlockTabs, { DEFAULT_TABS } from './FormBlockTabs'
import FormFields from './FormFields'
import { StyledExpansionPanel } from './styled'

const FormBlock = ({
  blockKey,
  fields,
  formErrors,
  formSubmit,
  formValues,
  formValuesChanged,
  FormWrapper,
  initialValues,
  isReadOnly,
  isSecondaryBlock,
  isSubmitOnBlur,
  labelKey,
  labelType,
  optionsData,
  touchedFields,
  type,
  setTouchedFields,
  submitByEnterPressed,
  updateCheckbox,
  updateInput,
  updateSelect,
  validateField,
  headerType,
  expansionPanelProps,
  validationRules,
}) => {
  const { t, i18n } = useTranslation(['forms', 'menu', 'validation'])

  const withBlockTabs =
    Object.keys(DEFAULT_TABS).includes(blockKey) || FORM_CONFIGS[type]?.withTabs?.includes(blockKey)

  const InfoPanelConfig = INFO_PANEL_CONFIG[type]?.[blockKey]
  const withInfo = !!InfoPanelConfig?.props

  const [infoOpened, setInfoOpened] = useState(InfoPanelConfig?.initiallyOpened)

  const onToggleInfo = (e) => {
    e.stopPropagation()
    setInfoOpened((prev) => !prev)
  }

  const isFormWithOneBlockOnly = !blockKey

  const getPanelHeader = (blockKey, withInfo) => {
    if (blockKey === 'noTitle') {
      return null
    }

    const translationKey = blockKey.endsWith('_items') ? 'items' : blockKey
    const blockNameTranslation =
      translationKey &&
      (i18n.exists(translationKey, { ns: 'forms' }) ? t(translationKey) : t(`menu:${translationKey}`))

    return (
      <>
        {blockNameTranslation}
        {withInfo && (
          <Icon
            name="info"
            className="infoIcon"
            wrapperWidth={16}
            wrapperHeight={16}
            borderRadius="50%"
            onClick={onToggleInfo}
          />
        )}
      </>
    )
  }

  const formFieldsProps = {
    formErrors,
    formSubmit,
    formValues,
    initialValues,
    isReadOnly,
    isSubmitOnBlur,
    labelKey,
    labelType,
    optionsData,
    submitByEnterPressed,
    type,
    updateCheckbox,
    updateInput,
    updateSelect,
    sidebarFormId: formValues.id,
    validateField,
    validationRules,
  }

  const compoundTypeKey = `${type}.${blockKey}`
  const editableTableConfig =
    EDITABLE_TABLE_NAVBAR_FORM_CONFIG[type] || EDITABLE_TABLE_NAVBAR_FORM_CONFIG[compoundTypeKey]

  const customBlockValues =
    (fields.getBlockValuesByCustomRule && fields.getBlockValuesByCustomRule(formValues, optionsData)) ||
    (editableTableConfig?.getBlockValuesByCustomRule &&
      editableTableConfig?.getBlockValuesByCustomRule(formValues, optionsData))

  const getIsBlockShown = () => {
    let isShown = true
    if (
      isReadOnly ||
      ['recipient', 'client'].includes(optionsData?.user?.role)
      // #roles
    ) {
      isShown = blockKey !== 'admin'
    }

    if (blockKey === 'comments' && !formValues.id) {
      isShown = false
    }

    if (isShown) {
      if (
        fields &&
        Array.isArray(fields) &&
        fields.some((field) => !!field.getIsHidden) &&
        !fields.filter(
          (field) => !field.getIsHidden || !field.getIsHidden(formValues, optionsData, initialValues)
        ).length
      ) {
        isShown = false
      }
    }

    return isShown
  }

  const isBlockShown =
    fields.getBlockValuesByCustomRule || editableTableConfig?.getBlockValuesByCustomRule
      ? customBlockValues
      : getIsBlockShown()

  const panelProps = {
    isHidden: isFormWithOneBlockOnly || blockKey === 'noTitle',
    // key: blockKey,
    // header: getPanelHeader(blockKey, withInfo),
    // initialOpened: !COLLAPSED_FORM_BLOCKS.includes(blockKey),
    // id: blockKey,
    // className: clsx(
    //   'formBlock',
    //   blockKey,
    //   !isBlockShown && 'hidden',
    //   isFormWithOneBlockOnly && 'oneBlockForm'
    // ),
    ...expansionPanelProps,
  }

  const getFields = () => {
    if (!isBlockShown) {
      return null
    }

    return Array.isArray(fields) ? (
      <FormFields {...formFieldsProps} fields={fields} />
    ) : (
      Object.values(fields).map((values, i) => {
        const key = Object.keys(fields)[i]

        return Array.isArray(values) ? (
          <div key={i}>
            <Typography type="h4" text={t(key)} margin="0 0 10px 0" />
            <FormFields {...formFieldsProps} fields={values} />
          </div>
        ) : null
      })
    )
  }

  return FormWrapper ? (
    <StyledExpansionPanel
      isHidden={!blockKey}
      key={blockKey}
      type="light"
      header={getPanelHeader(blockKey, withInfo)}
      size="small"
      initialOpened={
        !COLLAPSED_FORM_BLOCKS.includes(blockKey) ||
        (blockKey === 'admin' && ['operator', 'platform-admin'].includes(optionsData?.user?.role))
      }
      id={blockKey}
      className={clsx(
        'singleFormTitle',
        blockKey,
        !!editableTableConfig && 'isEditableTable',
        !isBlockShown && 'hidden'
      )}
      {...panelProps}
    >
      {withInfo && infoOpened && <InfoPanel {...InfoPanelConfig?.props} />}
      <FormWrapper
        isBlockShown={isBlockShown}
        isReadOnly={isReadOnly}
        blockKey={blockKey}
        compoundTypeKey={compoundTypeKey}
        customBlockValues={customBlockValues}
        id={formValues.id}
        editableTableConfig={editableTableConfig}
        fields={fields}
        formFieldsProps={formFieldsProps}
        formValues={formValues}
        formValuesChanged={formValuesChanged}
        getPanelHeader={getPanelHeader}
        optionsData={optionsData}
        setTouchedFields={setTouchedFields}
        touchedFields={touchedFields}
        withInfo={!isSecondaryBlock}
        updateCheckbox={updateCheckbox}
        updateInput={updateInput}
        updateSelect={updateSelect}
      >
        {withBlockTabs ? (
          <FormBlockTabs formFieldsProps={formFieldsProps} fields={fields} type={type} blockKey={blockKey} />
        ) : (
          getFields()
        )}
      </FormWrapper>
    </StyledExpansionPanel>
  ) : (
    <FormFields {...formFieldsProps} fields={fields} />
  )
}

export default FormBlock
