import React from 'react'

import { Color, getAvailableTranslation, Icon } from '@aidsupply/components'
import { IMAGEKIT_PARAMS_CONFIG } from '@aidsupply/components'

import { StyledCharacteristics } from './styled'
import { DEFAULT_LANGUAGE } from '../../locales'

const Characteristics = ({ itemModId, modKey, lng, modifications, attributes, enumerations, modValue }) => {
  const modification = itemModId && modifications?.[itemModId]
  const modAttributes = (modKey || modification?.key)?.split(':')
  const modAttributesValues = (modValue || modification?.value)?.split(':')

  return (
    <StyledCharacteristics>
      {modAttributes?.map((attributeSlug, i) => {
        const attribute = attributes?.[attributeSlug]

        if (!attribute) {
          return null
        }

        const attrValue = modAttributesValues[i]
        const optionValue = enumerations?.[attribute.enumeration_id].options.find(
          (option) => option.slug === attrValue
        )

        if (!optionValue) {
          return ''
        }

        const translation = getAvailableTranslation(optionValue.translations, DEFAULT_LANGUAGE, lng)

        return (
          <StyledCharacteristics key={i}>
            {optionValue?.extras?.hex_color && (
              <Color
                color={optionValue.extras.hex_color}
                dataTooltip={translation || optionValue.name}
                size={16}
                style={{ marginRight: 2 }}
                withBorder
              />
            )}
            {(optionValue?.extras?.icon || optionValue?.extras?.icon_image) && (
              <Icon
                dataTooltip={translation || optionValue.name}
                name={optionValue.extras.icon}
                width={16}
                height={16}
                style={{ marginRight: 2 }}
                borderRadius="50%"
                backgroundImage={
                  optionValue.extras.icon_image
                  // buildImagePath('enumerations', 'colors', optionValue.slug, optionValue.iconImage)
                }
                backgroundSize="120%"
                imagekitParams={IMAGEKIT_PARAMS_CONFIG.crm.icon.texture}
              />
            )}
            {!optionValue?.extras?.hex_color &&
              !optionValue?.extras?.icon &&
              !optionValue?.extras?.icon_image &&
              (translation || optionValue.name)}
          </StyledCharacteristics>
        )
      })}
    </StyledCharacteristics>
  )
}

export default Characteristics
