import { pick } from 'dot-object'
import React, { useEffect, useState } from 'react'
import { withTheme } from 'styled-components'

import { FlexRow, Icon, Tabs, Typography } from '@aidsupply/components'

import { FORM_CONFIGS } from '../../config/forms'
import FormFields from './FormFields'
import { SUPPORTED_LNGS } from '../../locales'

export const DEFAULT_TABS = {
  translations: 'en',
  seo: 'en',
  description: 'en',
  body: 'en',
}

const FormBlockTabs = ({
  blockKey,
  defaultTabsValues,
  dropdownItems,
  formFieldsProps = {},
  fields,
  getTabScreenCustom,
  isLanguageTabs,
  onTabChange,
  theme,
  type,
  withTabsFieldsCustom,
}) => {
  const { isReadOnly } = formFieldsProps
  const [tabs, setTabs] = useState([])
  const [errorTabIndex, setErrorTabIndex] = useState({ index: -1 })

  useEffect(() => {
    const inputWithError =
      formFieldsProps.formErrors &&
      Object.keys(formFieldsProps.formErrors).filter((error) => {
        return fields.filter((field) => error.includes(field.key))
      })

    if (!inputWithError) {
      return
    }

    let errorId
    inputWithError.find((el) => {
      errorId = tabs.find((tab) => el.includes(`.${tab}`))
      return errorId
    })
    const index = tabs.indexOf(errorId)

    setErrorTabIndex({ ...errorTabIndex, index })
  }, [formFieldsProps.formErrors])

  const getTabOptions = () => {
    const withTabsFields = withTabsFieldsCustom || FORM_CONFIGS[type]?.withTabs || Object.keys(DEFAULT_TABS)
    const tabsKeys = fields?.length
      ? withTabsFields.filter((key) => fields.some((field) => field.key === key))
      : withTabsFields

    if (!tabsKeys.length) {
      return null
    }

    let tabs = [(defaultTabsValues || DEFAULT_TABS)[blockKey]]

    tabsKeys.forEach((key) => {
      const tabValueObj = pick(key, formFieldsProps.formValues) || {}

      Object.keys(tabValueObj).forEach((tabKeyFromValues) => {
        if (!tabs.includes(tabKeyFromValues) && tabValueObj[tabKeyFromValues]) {
          tabs = [...tabs, tabKeyFromValues]
        }
      })
    })

    return tabs
  }

  useEffect(() => {
    const tabs = getTabOptions()

    if (tabs) {
      setTabs(tabs)
    }
  }, [formFieldsProps.initialValues?.id])

  const getTabScreen = (tabKey) => {
    if (getTabScreenCustom) {
      return getTabScreenCustom(tabKey)
    } else {
      return <FormFields fields={fields} tabKey={tabKey} {...formFieldsProps} />
    }
  }

  const onDropItemClick = (id) => {
    setTabs([...tabs, id])
  }

  //TODO check object
  const getTabTitle = (title) => (
    <FlexRow id={title} gap="6px">
      {(isLanguageTabs || ['translations', 'texts', 'seo'].includes(blockKey)) && (
        <Icon width={16} height={12} name={`flag${title.toUpperCase()}`} />
      )}
      <Typography text={title} textTransform="uppercase" />
    </FlexRow>
  )

  const getDropdownItems = () => {
    if (dropdownItems) {
      return dropdownItems
    } else {
      return SUPPORTED_LNGS.filter((lng) => !tabs.includes(lng)).map((lng) => ({
        id: lng,
        label: lng.toUpperCase(),
      }))
    }
  }

  return (
    <Tabs
      backgroundColor={theme.color.general.gray2}
      isDisabled={isReadOnly}
      withAddAction
      tabsTitles={tabs.map((tab) => getTabTitle(tab))}
      tabsContents={tabs.map((tab) => getTabScreen(tab))}
      dropdownItems={getDropdownItems()}
      onDropItemClick={onDropItemClick}
      onTabChange={onTabChange}
      typographyType="link"
      errorTabIndex={errorTabIndex}
    />
  )
}

export default withTheme(FormBlockTabs)
