import React from 'react'

import { Color, Icon, Typography } from '@aidsupply/components'
import { buildImagePath, IMAGEKIT_PARAMS_CONFIG } from '@aidsupply/components'

import UploadFiles from '../../UploadFiles'
import { selectFileUploadState, selectSidebarUpsertInProgress } from '../../../redux/selectors'
import { useMappedState } from '../../../hooks'
import { IMAGEKIT_URL } from '../../../constants'

const EnumerationOptionNameWithColor = (props) => {
  const { files } = useMappedState(selectFileUploadState)
  const sidebarUpsertInProgress = useMappedState(selectSidebarUpsertInProgress)

  const {
    rowData,
    cellData,
    container: {
      props: {
        cellProps: { system, id },
      },
    },
  } = props
  const { extras, slug } = rowData
  const hexColor = extras?.hex_color
  const icon = extras?.icon
  const iconImage = extras?.icon_image
  const enumerationObject = system.enumerations?.[id]

  const file = files[slug]?.[0]

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {hexColor && <Color color={hexColor} size={20} style={{ marginRight: 10 }} />}
      {(icon || iconImage || file) && (
        <Icon
          name={icon?.id || icon}
          style={{ marginRight: 10 }}
          height={20}
          width={20}
          borderRadius="50%"
          backgroundImage={
            (iconImage && buildImagePath('enumerations', 'colors', slug, iconImage)) || (file && file.preview)
          }
          disableImagekit={!!file}
          backgroundSize="120%"
          imagekitParams={IMAGEKIT_PARAMS_CONFIG.crm.icon.texture}
          imagekitUrl={IMAGEKIT_URL}
        />
      )}
      {id &&
        !file &&
        !sidebarUpsertInProgress &&
        enumerationObject?.is_icons_upload_allowed &&
        !hexColor &&
        !icon &&
        !iconImage && <UploadFiles isSimpleButton fileGroup={slug} maxFiles={1} />}
      <Typography type="body2">{cellData}</Typography>
    </div>
  )
}

export default EnumerationOptionNameWithColor
