import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { withTheme } from 'styled-components'
// import { useMappedState } from '../../hooks/useReactRedux'
import ReactHelmet from '../../wrappers/ReactHelmet'

import { Icon, Typography, UiLink } from '@aidsupply/components'
import IntroScreenWrapper from '../../components/IntroScreens/IntroScreenWrapper'
import { INTRO_PAGES_IMG } from '../../constants'
// import backBlue from '../../assets/images/backBlue.jpg'
// import { selectWholeUserState } from '../../redux-saga/selectors'

const RequestSent = ({ theme }) => {
  const { t } = useTranslation('signIn')

  // const user = useMappedState(selectWholeUserState)
  const user = {}
  return (
    <>
      <ReactHelmet title="signTitle" />

      <IntroScreenWrapper
        type="requestSent"
        theme={theme}
        imageUrl={INTRO_PAGES_IMG}
        text={t('confirmYourEmail')}
      >
        <Icon name="requestSent" margin="30px 0 0" />
        <div style={{ textAlign: 'center' }}>
          <Typography type="body1" className="inline" text={t('requestText')} />
          <Typography type="body1" fontWeight={500} className="inline" style={{ letterSpacing: '1px' }}>
            &nbsp;{user?.details?.email || 'username@domain.com'}
          </Typography>
          <Typography type="body1" className="inline" text={t('requestText1')} />
        </div>
        <div style={{ display: 'flex', gap: '6px', marginTop: '20px', justifyContent: 'center' }}>
          <Typography type="body1">{t('backTo')}</Typography>
          <Typography type="button1">
            <UiLink Link={RouterLink} to="/login">
              {t('toSignIn')}
            </UiLink>
          </Typography>
        </div>
      </IntroScreenWrapper>
    </>
  )
}

export default withTheme(RequestSent)
