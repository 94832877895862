import React, { useContext, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import isEqual from 'lodash.isequal'

import {
  Icon,
  ScreenContext,
  Dropdown,
  validate,
  isObjectEmpty,
  Button,
  convertArrayToObject,
  FlexRow,
} from '@aidsupply/components'

import { StyledNavBar } from './styled'
import FormFields from '../Form/FormFields'
// import UploadImages from '../UploadImages'

// TODO: REFACTOR!!

const getPostedOptionsOnly = (options, type) => {
  const POSTED_OPTIONS_DATA_KEYS = {
    'inquiries.inquiry_items': 'categories',
    'orders.order_items': 'order-items',
    'shipments.shipment_items': 'stock-items',
    'inventories.inventory_items': 'inventory-items',
  }
  const optionsDatKey = POSTED_OPTIONS_DATA_KEYS[type]
  return {
    ...options,
    [optionsDatKey]: options[optionsDatKey]
      ? convertArrayToObject(
          Object.values(options[optionsDatKey]).filter((option) => option.state === 'posted')
        )
      : {},
  }
}

const EditableTableNavBar = ({
  additionalFormValues,
  createNewOptions,
  data,
  fields,
  formId,
  onAddIconImage,
  onDeleteClick,
  optionsData,
  setTableData,
  t,
  transformAddedResult,
  typeData,
  updateTableRows,
  validationRules,
}) => {
  const { currentBreakpoint } = useContext(ScreenContext) || {}
  const [show, setShow] = useState(true)

  const [formValues, setFormValues] = useState({})
  const [formErrors, setFormErrors] = useState({})

  if (!fields?.length) {
    return null
  }

  const onSelectChange = (name, customOnChange) => (option) => {
    setFormErrors({})
    setFormValues((prev) => ({ ...prev, [name]: option }))
    if (customOnChange) {
      customOnChange(option, setFormValues)
    }
  }

  const onInputChange = (e, customOnChange) => {
    const { name, value } = e.target
    setFormErrors({})
    setFormValues((prev) => ({ ...prev, [name]: value }))
    if (customOnChange) {
      customOnChange(value, setFormValues) //updateInputValue => initialValues
    }
  }

  const onAddNewRow = (e) => {
    e.preventDefault()
    const submitErrors = validate(validationRules)(formValues, { list: data })
    setFormErrors(submitErrors)

    if (!isObjectEmpty(submitErrors)) {
      return
    }

    // const requiredField = fields.find(field => field.isRequired)

    if (updateTableRows && formValues && !isObjectEmpty(formValues)) {
      const fieldToSpread = fields.find((field) => field.isObjectKeyToBeSpread)
      let transformedObject

      const commonRowObj = {
        ...Object.keys(formValues).reduce((acc, key) => {
          const keyHasObjectValue =
            !createNewOptions &&
            formValues[key] === Object(formValues[key]) &&
            !Array.isArray(formValues[key])

          const objectToSpread = createNewOptions
            ? { [key]: formValues[key].id || formValues[key] }
            : {
                ...(fieldToSpread?.key === key && keyHasObjectValue ? formValues[key] : {}),
              }

          if (!keyHasObjectValue || fieldToSpread?.key !== key) {
            objectToSpread[key] = formValues[key]
          }

          return {
            ...acc,
            ...objectToSpread,
          }
        }, {}),
      }

      if (createNewOptions) {
        commonRowObj.id = uuidv4()
      }

      if (transformAddedResult) {
        transformedObject = transformAddedResult(commonRowObj, additionalFormValues)
      }

      let newTableData
      const newItem = transformedObject || commonRowObj
      const deletedItems = data?.filter((item) => item.state === 'deleted')

      if (newItem.category_id) {
        if (
          deletedItems?.length &&
          deletedItems.some(
            (item) =>
              item.category_id === newItem.category_id &&
              isEqual(item.characteristics_keys, newItem.characteristics_keys) &&
              isEqual(item.characteristics_values, newItem.characteristics_values)
          )
        ) {
          const filteredData = data.filter(
            (item) =>
              item.category_id !== newItem.category_id ||
              !(
                isEqual(item.characteristics_keys, newItem.characteristics_keys) &&
                isEqual(item.characteristics_values, newItem.characteristics_values)
              )
          )
          newTableData = [...filteredData, newItem]
        } else if (
          data?.length &&
          data.some(
            (item) =>
              item.category_id === newItem.category_id &&
              isEqual(item.characteristics_keys, newItem.characteristics_keys) &&
              isEqual(item.characteristics_values, newItem.characteristics_values)
          )
        ) {
          newTableData = data.map((item) => {
            if (
              item.category_id === newItem.category_id &&
              isEqual(item.characteristics_keys, newItem.characteristics_keys) &&
              isEqual(item.characteristics_values, newItem.characteristics_values)
            ) {
              return { ...item, quantity: item.quantity + 1 }
            } else {
              return item
            }
          })
        } else {
          newTableData = [...(data || []), transformedObject || commonRowObj]
        }
      } else {
        newTableData = [...(data || []), transformedObject || commonRowObj]
      }

      updateTableRows()(newTableData, false)
      setTableData(newTableData)
      setFormValues({})
    }
  }
  // console.log(typeData.key)
  return (
    <StyledNavBar
      currentBreakpoint={currentBreakpoint}
      className={`editable navbar ${fields?.length ? 'withFields' : ''}`}
    >
      <FormFields
        additionalFormValues={additionalFormValues}
        fields={fields}
        flexDirection="row"
        sidebarFormId={formId}
        type={typeData.key}
        mainFormData={data}
        formErrors={formErrors}
        formValues={formValues}
        optionsData={
          [
            'inquiries.inquiry_items',
            'orders.order_items',
            'shipments.shipment_items',
            'inventories.inventory_items',
          ].includes(typeData.key)
            ? getPostedOptionsOnly(optionsData, typeData.key)
            : optionsData
        }
        updateInput={onInputChange}
        updateCheckbox={onInputChange}
        updateSelect={onSelectChange}
      />

      <Button size="small" variant="primary" onClick={onAddNewRow} className="navBar add">
        <Icon name="plus2" fill="white" height={20} width={20} wrapperHeight={32} wrapperWidth={32} />
      </Button>

      <div style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
        {/*<Button size="small" variant="primary" onClick={onAddNewRow} className="navBar add">*/}
        {/*  <Icon name="plus2" fill="white" height={20} width={20} wrapperHeight={32} wrapperWidth={32} />*/}
        {/*</Button>*/}
        {/*<Dropdown*/}
        {/*  MenuButton={Icon}*/}
        {/*  buttonProps={{*/}
        {/*    name: 'threeDotsVertical',*/}
        {/*    fill: 'black',*/}
        {/*    // onClick: toggleActions,*/}
        {/*    wrapperWidth: 40,*/}
        {/*    wrapperHeight: 40,*/}
        {/*  }}*/}
        {/*  show={show}*/}
        {/*  onToggle={(nextShow) => setShow(nextShow)}*/}
        {/*  title={`${show ? 'Close' : 'Open'} Dropdown`}*/}
        {/*>*/}
        {/*  /!*<div onClick={onEditClick}>Edit</div>*!/*/}
        {/*  <Icon*/}
        {/*    name="trashBin"*/}
        {/*    height={20}*/}
        {/*    width={20}*/}
        {/*    wrapperHeight={32}*/}
        {/*    wrapperWidth={32}*/}
        {/*    onClick={onDeleteClick}*/}
        {/*  />*/}
        {/*</Dropdown>*/}
      </div>
    </StyledNavBar>
  )
}

export default EditableTableNavBar
