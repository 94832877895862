import styled from 'styled-components'
import PasswordChecklist from 'react-password-checklist'

export const StyledHeader = styled.div`
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg - 1}px) {
    display: flex;
  }
`

export const StyledSocialBlock = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .lineWrapper {
    display: flex;
    align-items: center;
    margin: 20px 0;
  }
  .horizontalLine {
    border-top: ${({ theme: { color } }) => `1px solid ${color.general.gray2}`};
    width: 100%;
  }

  .socialText {
    padding: 0 10px;
    background-color: ${({ theme: { color } }) => color.general.light};
  }

  .googleText {
    color: ${({ theme: { color } }) => color.general.dark};
    margin-left: 10px;
  }

  .fullWidth {
    min-width: 72px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg - 1}px) {
    .googleText {
      display: none;
    }
  }
`

export const StyledSocialButtons = styled.div`
  display: flex;
  width: 100%;
  button {
    margin-right: 18px;
    &:last-child {
      margin: 0;
    }

    &.disabled,
    &.bordered {
      min-width: 0;
    }
  }
  &.isBeforeForm {
    margin-top: 20px;
  }
`

export const StyledPasswordChecklist = styled(PasswordChecklist)`
  &.passwordCheckList {
    display: flex;
    flex-wrap: wrap;
    gap: 6px;

    li {
      font-size: 12px;
      line-height: 18px;
      font-weight: 500;
      padding: 2px 5px;
      color: ${({ theme: { color } }) => color.secondary.dark};
      background-color: ${({ theme: { color } }) => `${color.secondary.main}1a`};
      border: 1px solid ${({ theme: { color } }) => color.secondary.lightest};
      width: fit-content;
      border-radius: 6px;

      span {
        opacity: 1;
        padding: 0;
      }

      &.valid {
        color: ${({ theme: { color } }) => color.status.success};
        background-color: ${({ theme: { color } }) => `${color.status.success}1a`};
        border: 1px solid ${({ theme: { color } }) => `${color.status.success}1a`};
      }

      svg {
        display: none;
      }
    }
  }
  &.passError li {
    color: ${({ theme: { color } }) => color.status.error};
    background-color: ${({ theme: { color } }) => `${color.status.error}1a`};
    border: 1px solid ${({ theme: { color } }) => `${color.status.error}1a`};
  }
`
