import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { object } from 'dot-object'
import { useDispatch } from 'react-redux'

import { isObject } from '@aidsupply/components'

import EditForm from '../../../components/RightPanelForms'
import { sidebarUpsert } from '../../../redux/actions'
import { FORM_CONFIGS } from '../../../config/forms'
import { useMappedState } from '../../../hooks'
import { selectSidebarInitialData } from '../../../redux/selectors'

const StocksRightPanel = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { rightPanelId: editedItemId } = useParams()

  const formConfig = editedItemId === 'new' && FORM_CONFIGS.stockItemsNew
  const itemInitial = useMappedState(selectSidebarInitialData)

  const onFormSubmit = (formValuesChanged) => {
    const requestBody = { ...object(formValuesChanged) }

    Object.keys(requestBody).map((key) => {
      if (isObject(requestBody[key]) && requestBody[key].id) {
        requestBody[key] = requestBody[key].id
      } else if (Array.isArray(requestBody[key]) && requestBody[key].length) {
        requestBody[key] = requestBody[key].map((item) => (isObject(item) && item.id ? item.id : item))
      }
    })

    const dataToSend = {
      id: itemInitial.id,
      requestBody: itemInitial.id ? [requestBody] : requestBody,
      type: 'stock-items',
      parentType: 'inventory',
    }

    dispatch(sidebarUpsert(dataToSend, navigate))
  }

  return <EditForm formConfig={formConfig} onFormSubmit={onFormSubmit} />
}

export default StocksRightPanel
