import styled from 'styled-components'

export const StyledMultiSelectTable = styled.div`
  margin-bottom: ${({ maxTableHeight }) => maxTableHeight}px;

  .select {
    flex-grow: 1;
  }

  .BaseTable__row-cell .typography {
    font-size: 13px;
  }

  .BaseTable__table.BaseTable__table-main .BaseTable__row:last-child {
    border-bottom: none;
  }

  .optionImage {
    margin: 5px 0 5px 10px;
  }

  .searchSelect {
    flex-grow: 1;
  }
`
