import styled from 'styled-components'
import { Drawer, Image } from '@aidsupply/components'
import { HEADER_HEIGHT } from '../../constants'

export const StyledRightDrawer = styled(Drawer)`
    background-color: #fff;
    border-left: 1px solid ${({ theme }) => theme.bordersColor};
    min-width: ${({ minWidth }) => minWidth || '280px'};
    padding: 0 20px;

    .editPanelTitleRow {
        flex-grow: 1;
        font-weight: 600;

        button {
            margin-left: auto;

            .icon {
                rect {
                    height: 1px;
                }

                rect:nth-child(6), rect:nth-child(7), rect:nth-child(8) {
                    height: 0.5px;
                }

                path {
                    stroke-width: 1;
                }
            }
        }
    }

    &.displayed {
        display: flex;
        flex-direction: column;
    }

    button.formInput {
        width: 100%;
    }

    .formInput.isHidden,
    .isHidden {
        display: none;
    }

    .quantityInput {
        input {
            line-height: 22px;
        }
    }

    .secondaryBlockTitle {
        margin-bottom: 10px;
    }

    &.inProgress {
        opacity: 0.4;
        user-select: none;
        pointer-events: none;
        cursor: not-allowed;
    }

    .titleEditPanel {
        position: sticky;
        top: 0;
        z-index: 10;
        padding-top: 15px;
        background-color: ${({ theme }) => theme.color.general.light};

        & > div {
            cursor: default;
        }

        margin-bottom: 20px;

        .panel.header {
            padding: 10px;
        }
    }

    .expansionPanel.opened.tableWithSummary ~ .expansionPanel {
        margin-top: 95px;
    }

    .scrollDownLink {
        margin-bottom: 20px;
        margin-top: -10px;
        display: flex;
        align-items: baseline;
    }

    .toggleIcon {
        display: none;
    }

    @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
        z-index: 5;
        border: none;
        margin-top: ${HEADER_HEIGHT};
    }

    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
        padding-top: 0;

        .titleEditPanel {
            padding-top: 0;
        }
    }

    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
        .titleEditPanel {
            margin-bottom: 30px;
        }

        .toggleIcon {
            display: block;
            transition: transform 0.2s cubic-bezier(0.25, 0.5, 0.5, 0.9);

            &.open {
                transform: rotate(180deg);
            }
        }
    }

    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) and (max-width: ${({
                                                                                                        theme,
                                                                                                    }) => theme.breakpoints.lg}px) {
        .titleEditPanel {
            margin-bottom: 30px;
        }
    }
`

export const StyledFlexRow = styled.div`
  display: flex;
  align-items: baseline;

  .typography {
    line-height: 24px;
  }

  .label {
    margin-right: 10px;
  }
`

export const StyledItemImage = styled(Image)`
  a {
    margin-left: 0;
  }
  margin-right: 16px;

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
    width: 100px;
    height: 100px;
    margin-right: 24px;
  }
`
