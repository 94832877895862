import React from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'
import { useLocation, useNavigate } from 'react-router-dom'
import { withTheme } from 'styled-components'

import GridLayout from '../../Layout/GridLayout'
import TableBlock from '../../../components/Table/TableBlock'
import InvoicesTableCard from './InvoicesTableCard'

const Invoices = ({ theme }) => {
  const { t } = useTranslation('forms')

  const { pathname } = useLocation()
  const navigate = useNavigate()

  const handleGoBack = () => {
    navigate(-1)
  }

  let title
  if (pathname.includes('new')) {
    title = t('create')
  } else if (pathname.includes('comments')) {
    title = t('comments')
  } else {
    title = t('edit')
  }

  const backIconProps =
    title === t('comments')
      ? {
          withBackIcon: true,
          backIconProps: {
            width: 24,
            height: 24,
            wrapperWidth: 32,
            wrapperHeight: 32,
            wrapperColor: theme.color.primary.main,
            fill: theme.color.general.light,
            strokeWidth: 1.4,
            onClick: handleGoBack,
          },
        }
      : {}

  return (
    <GridLayout
      RightPanelComponent={<Outlet context={{}} />}
      rightPanelProps={{
        title,
        ...backIconProps,
      }}
    >
      <TableBlock
        // hideFilterPanel={hideFilterPanel}
        isMainTable
        // isReadOnly={isReadOnly}
        // rightPanelIsOpened={rightPanelIsOpened}
        // gap={gap}
        // leftPanelIsOpened={leftPanelIsOpened}
        // setLeftPanelOpened={setLeftPanelOpened}
        TableCardContent={InvoicesTableCard}
        tableCardHeight={148}
      />
    </GridLayout>
  )
}

export default withTheme(Invoices)
