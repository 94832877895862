import merge from 'deepmerge'
import dot, { object } from 'dot-object'
import cloneDeep from 'lodash.clonedeep'

import { getAvailableTranslation, LANGUAGES_FULL_NAME } from '@aidsupply/components'
import { ENTITIES } from '../config'
import { DEFAULT_LANGUAGE } from '../locales'

// That's for sorting by two or more columns
// export const getApiTableSortString = (sortState, columns) => {
//   return Object.keys(sortState).reduce((acc, curr, i) => {
//     return acc + `${i !== 0 ? ',' : ''}` + `${columns.find(col => col.key === curr).dataKey}_${sortState[curr]}`
//   }, '?sort=')
// }

const overwriteMerge = (destinationArray, sourceArray) => sourceArray

const mergeOptions = {
  customMerge: (key) => {
    if (key === 'photos') {
      return (photosA, photosB) => photosB
    }
  },
  arrayMerge: overwriteMerge,
}

export const getValuesWithAddedPhotosTab = (valuesChanged, characteristicsChosen, initialValues) => {
  const isInitialAction = !valuesChanged?.photos
  const photos = isInitialAction
    ? initialValues.photos
    : merge(initialValues.photos, object(cloneDeep(valuesChanged.photos)), mergeOptions)

  const addOldPhotoValuesToTabs = (characteristicsChosen) => {
    return characteristicsChosen.reduce((acc, curr) => {
      return {
        ...acc,
        [curr]: photos[curr] || {
          active: [],
        },
      }
    }, {})
  }

  const newPhotosObj = merge(cloneDeep(photos), addOldPhotoValuesToTabs(characteristicsChosen), mergeOptions)

  return {
    ...cloneDeep(valuesChanged || initialValues),
    photos: {
      ...newPhotosObj,
    },
  }
}

export const transformStringSelectValue = (value, labelKey, lng) => {
  if (typeof value === 'object') {
    return value
  }

  return {
    id: value,
    [labelKey || 'label']: value[lng] || value,
  }
}
// TODO: refactor
export const getSelectOptions = (field, optionsData, lng) => {
  if (!field.options && !field.optionsKeys && !field.getOptions) {
    return
  }

  // console.log('HERE: ' + field.label)

  const getOptionsArr = field.getOptions && field.getOptions(optionsData)

  const optionsArr =
    getOptionsArr ||
    field.options ||
    field.optionsKeys?.reduce((acc, curr) => {
      const rawOptions = optionsData[curr] || {}
      const options = Object.values(rawOptions)

      return [...acc, ...options]
    }, [])

  return optionsArr?.[0]?.id
    ? optionsArr
    : optionsArr?.map((option) =>
        field.key.toLowerCase().includes('language')
          ? getSelectLanguageValue(option)
          : transformStringSelectValue(option, field.labelKey, lng)
      )
}

export const getSelectValue = (value, options, field) => {
  // TODO: @AG, why do we have this one here?
  if (!value) {
    return ''
  }

  if (!options) {
    return value
  }

  const labelKey = field.labelKey || 'label'
  const valueKey = field.valueKey || 'id'

  if ((field.isMulti && Array.isArray(value)) || Array.isArray(value)) {
    return value.map((oneValue) =>
      options.find((option) => option[valueKey] === (oneValue[valueKey] || oneValue))
    )
  }
  if (typeof value === 'string' || typeof value === 'number') {
    return options.find((option) => option[valueKey] == value) //TODO: check inquiry value in orders
  }
  if (!dot.pick(labelKey, value)) {
    return options.find((option) => option[valueKey] === (value[valueKey] || value))
  }

  return value
}

export const getSelectLabel = (system, value, lng, noTranslation, labelKey, valueKey) => {
  const valueFound =
    (valueKey && system[valueKey]) || system[value.id] || system[value.slug] || system[value] || value

  if (typeof valueFound === 'string') {
    return valueFound
  } else if (noTranslation || !lng) {
    const label = dot.pick(labelKey || 'label', valueFound)
    return label
  }

  const labelObject = dot.pick(labelKey || 'label', valueFound)

  return getAvailableTranslation(labelObject, DEFAULT_LANGUAGE, lng)
}

export const getSelectLanguageValue = (lngKey) => {
  const name = LANGUAGES_FULL_NAME[lngKey]

  return {
    id: lngKey,
    label: name,
  }
}

export const getEntityByRoute = (route, subRoute) => {
  const parentEntity = ENTITIES.find((val) => val.key === route)
  let subEntity

  if (subRoute) {
    subEntity = parentEntity.items.find(
      (item) => item.key === subRoute || item.route.split('/')[2] === subRoute
    )
    subEntity.parentType = route
  }

  return subEntity || parentEntity
}

export const getDescendantProp = (obj, path) => path.split('.').reduce((acc, part) => acc && acc[part], obj)

/**
 * Mark image disabled
 * @param {Object} values - current photos or files
 * @param {string} file - file/photo object
 * @param {bool} isDeleted - delete or recover
 * @returns {Object} - updated files and files states
 */
export const markFileDraftedOrPosted = (values, file, isDeleted) => {
  const id = file.id
  const updatedValues = {}
  const updatedFilesState = {}

  if (isDeleted) {
    updatedValues.active = values.active.filter((file) => file.id !== id)
    updatedValues.disabled = [...(values.disabled || []), file]
    updatedFilesState[`id-${id}`] = 'drafted'
  } else {
    updatedValues.active = [...values.active, file]
    updatedValues.disabled = values.disabled.filter((file) => file.id !== id)
    updatedFilesState[`id-${id}`] = 'posted'
  }

  return { updatedValues, updatedFilesState }
}
