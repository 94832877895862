import React from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { PopupAlerts } from '@aidsupply/components'

import { popupAlertHide } from '../redux/actions'
import { useMappedState } from '../hooks'
import { selectPopupAlerts } from '../redux/selectors'

const PopupAlertsComponent = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation('popupAlerts')
  const popupAlerts = useMappedState(selectPopupAlerts)

  return <PopupAlerts t={t} popupAlerts={popupAlerts} closeAction={(id) => dispatch(popupAlertHide(id))} />
}

export default PopupAlertsComponent
