import React from 'react'
import { InfoRowsBlock, getAvailableTranslation } from '@aidsupply/components'
import { useTranslation } from 'react-i18next'

import { DEFAULT_LANGUAGE } from '../../../locales'
import { useMappedState } from '../../../hooks'
import { selectAllSystemCollections } from '../../../redux/selectors'
import InquiryNumberWithStatusBar from '../../../components/InquiryNumberWithStatusBar/InquiryNumberWithStatusBar'

const CampaignsTableCard = ({ className, data, initialData }) => {
  const { state, deadline_at, sum_current, sum_goal } = data

  const system = useMappedState(selectAllSystemCollections)
  const organization = Object.keys(system.organizations).find(
    (key) => system.organizations[key].id === initialData.organization_id
  )
  const organizationName = system.organizations[organization].name
  const {
    i18n: { language: lng },
    t,
  } = useTranslation('table')

  const percentages = [
    {
      percentage: (initialData.sum_current / initialData.sum_goal) * 100,
      tooltipTitle: t('currentSum'),
    },
    {
      percentage: 100,
      tooltipTitle: null,
    },
  ]

  const title = getAvailableTranslation(initialData.translations, DEFAULT_LANGUAGE, lng)

  return (
    <InfoRowsBlock
      className={className}
      data={[
        {
          name: {
            customElement: <InquiryNumberWithStatusBar cellData={title} percentages={percentages} />,
            width: 'calc(100% - 36px)',
            hideBorder: true,
            padding: '0 0 0 10px',
          },
          state: {
            customElement: state,
            width: '36px',
            padding: '11px',
          },
        },
        {
          sum_current: {
            iconProps: {
              name: 'box',
            },
            text: sum_current,
            width: '50%',
            hideBorder: !sum_goal,
            padding: '9px',
          },
          sum_goal: {
            iconProps: {
              name: 'target',
            },
            text: sum_goal,
            width: '50%',
            padding: '9px',
          },
        },
        {
          organization: {
            iconProps: {
              name: 'warehouse',
            },
            text: organizationName,
            width: '50%',
            hideBorder: !deadline_at,
            padding: '9px',
          },
          deadline_at: {
            iconProps: {
              name: 'time',
            },
            text: deadline_at,
            width: '50%',
            padding: '9px',
          },
        },
      ]}
    />
  )
}

export default CampaignsTableCard
