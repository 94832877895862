import { all } from 'redux-saga/effects'

import dataSaga from './sagas/data'
import fileUploadSaga from './sagas/fileUpload'
import initialSaga from './sagas/init'
import userSaga from './sagas/user'
import sidebarSaga from './sagas/sidebar'

export default function* rootSaga() {
  yield all([
    initialSaga(),
    dataSaga(),
    fileUploadSaga(),
    sidebarSaga(),
    userSaga(),
    // fork(watchLiveDataSaga),
  ])
}
