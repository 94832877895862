import { pick } from 'dot-object'

export const getOptionsBasedOnCategory = (
  formValues,
  optionsData,
  keyInCategory,
  optionsDataKey,
  finalKeyToPickFromProps
) => {
  const cat = formValues['general.category'] || pick('general.category', formValues)
  const category = cat && optionsData.categories?.[cat?.id]
  const propIds = category && pick(keyInCategory, category)?.map((prop) => prop.id)

  const categoryProperties =
    optionsData?.[optionsDataKey] &&
    propIds &&
    Object.values(optionsData[optionsDataKey]).filter((property) => propIds.includes(property.id))

  return finalKeyToPickFromProps
    ? categoryProperties?.reduce((acc, curr) => {
        return [...acc, ...curr[finalKeyToPickFromProps]]
      }, [])
    : categoryProperties
}

export const getBackendValueFromEnumerations = ({
  blockValuesKey,
  valueToGetKey,
  container,
  cellData,
  rowData,
}) => {
  const {
    props: {
      cellProps: {
        system: { enumerations },
        custom_block_values: customBlockValues,
      },
    },
  } = container
  let valueFromBackend

  if (cellData) {
    return cellData
  } else {
    if (!customBlockValues) {
      return
    }
    const hasSpareValueToGetKey = Array.isArray(valueToGetKey)
    const categoryEnumsIds = customBlockValues.map((enumValue) => pick(blockValuesKey, enumValue))

    const categoryEnumsOptions =
      enumerations &&
      Object.keys(enumerations).reduce((acc, curr) => {
        if (categoryEnumsIds.includes(curr)) {
          return [...acc, ...(enumerations[curr].options || [])]
        }
        return acc
      }, [])

    const valueFromBackendObj = categoryEnumsOptions?.find((option) => rowData.id === option.id)
    valueFromBackend =
      valueFromBackendObj &&
      pick(hasSpareValueToGetKey ? valueToGetKey[0] : valueToGetKey, valueFromBackendObj)

    return (
      (hasSpareValueToGetKey && !valueFromBackend
        ? pick(valueToGetKey[1], valueFromBackendObj)
        : valueFromBackend) || ''
    )
  }
}

export const transformCharacteristicsFromObjectToArray = (characteristics, attributes, attributeSlug) => {
  if (attributeSlug && !attributes?.[attributeSlug]?.is_photo_group) {
    return undefined
  }
  const propsKeys = characteristics ? Object.keys(characteristics) : []

  return propsKeys?.length
    ? Object.values(characteristics)
        .filter((attributeProps, i) => attributes?.[propsKeys[i]]?.is_photo_group)
        .map((attributeProps, i) => attributeProps?.map((prop) => `${propsKeys[i]}_${prop.slug}`))
        .flat()
    : []
}

export const getStateCheck = (initialValues) => {
  const state = initialValues?.state
  const stateCheck = state === 'posted' || state === 'deleted'
  return stateCheck
}
