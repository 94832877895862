import dot from 'dot-object'
import React from 'react'

import { isObjectEmpty } from '@aidsupply/components'

import { EDITABLE_TABLE_NAVBAR_FORM_CONFIG } from '../../config/editableTableNavbarForms'
import { FORM_CONFIGS } from '../../config/forms'
import FormField from './FormField'
import { DEFAULT_TABS } from './FormBlockTabs'

const FormFields = ({
  additionalFormValues,
  fields,
  flexDirection = 'column',
  formErrors,
  formSubmit,
  tabKey,
  mainFormData,
  formValues,
  initialValues,
  isReadOnly,
  isSubmitOnBlur,
  labelKey,
  labelType,
  optionsData,
  sidebarFormId,
  submitByEnterPressed,
  type,
  updateCheckbox,
  updateInput,
  updateSelect,
  validateField,
  validationRules,
}) => {
  const platformName = 'aidsupply'

  if (!Array.isArray(fields)) {
    return null
  }

  const filteredFields = fields.filter(
    (field) => !field.platformsToShowOn || field.platformsToShowOn.includes(platformName)
  )

  return (
    filteredFields &&
    filteredFields.map((field) => {
      const withTabs =
        EDITABLE_TABLE_NAVBAR_FORM_CONFIG[type]?.withTabs ||
        FORM_CONFIGS[type]?.withTabs ||
        Object.keys(DEFAULT_TABS)
      const isTabs = withTabs.includes(field.key)
      const name = isTabs ? `${field.key}.${tabKey}` : field.key

      return (
        <FormField
          formFlexDirection={flexDirection}
          field={field}
          formSubmit={formSubmit}
          formError={!isObjectEmpty(formErrors) && (formErrors[name] || dot.pick(name, formErrors))}
          formErrors={field.validationRules && !isObjectEmpty(formErrors) && formErrors}
          formValue={formValues && (formValues[name] || dot.pick(name, formValues))}
          initialValue={initialValues && dot.pick(name, initialValues)}
          isDisabled={
            (field.isReadOnly !== false && isReadOnly) ||
            (field.getDisabled && field.getDisabled(formValues, optionsData, initialValues))
          }
          isHidden={field.getIsHidden && field.getIsHidden(formValues, optionsData, initialValues)}
          isSubmitOnBlur={isSubmitOnBlur}
          withTabs={isTabs}
          key={field.label || field.key}
          labelKey={labelKey}
          labelType={labelType}
          name={name}
          optionsData={optionsData}
          optionsFromFormValues={
            field.getOptionsFromFormValues &&
            optionsData &&
            field.getOptionsFromFormValues(formValues, optionsData, mainFormData, additionalFormValues)
          }
          sidebarFormId={sidebarFormId}
          submitByEnterPressed={submitByEnterPressed}
          updateCheckbox={updateCheckbox}
          updateInput={updateInput}
          updateSelect={updateSelect}
          validateField={validateField}
          validationRules={validationRules}
        />
      )
    })
  )
}

export default FormFields
