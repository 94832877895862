import dayjs from 'dayjs'
import dot, { pick } from 'dot-object'
import { customAlphabet } from 'nanoid'
import { alphanumeric } from 'nanoid-dictionary'
import React from 'react'
import { slugify } from 'transliteration'

import { convertArrayToObject, getAvailableTranslation } from '@aidsupply/components'

import { StyledAdditionToInputLink, StyledLabel } from '../components/Form/styled'
import ChangeStatusButton from '../components/RightPanelForms/components/ChangeStatusButton'
import Characteristics from '../components/Stocks/Characteristics'
import { TOOLBAR_CONFIG } from '../constants'
import { CONTRACT_TYPES, ORGANIZATION_TYPES, TAXES_TYPE } from '../data'
import { DEFAULT_LANGUAGE, LANGUAGES_FULL_NAME } from '../locales'
import { transformStringSelectValue } from '../utils'
import { getSlugsFromCharacteristicValue } from '../utils/data'
import { getStateCheck } from '../utils/forms'

export const FIELDS_TO_EXCLUDE_WHEN_COPYING = [
  'meta',
  'system',
  '_id',
  'user',
  'id',
  'dateCreated',
  'dateModified',
  'number',
  'status',
  'state',
  'platform',
  'platformId',
  'updated_at',
  'created_at',
  'comments',
  'open_demands',
  'closed_demands',
  'in_progress_demands',
  'comment',
  'qty_cancelled',
  'qty_completed',
  'qty_in_progress',
  'qty_items_cancelled',
  'qty_items_completed',
  'qty_items_in_progress',
  'qty_items_new',
  'qty_new',
  'name',
  'title',
  'email',
  'translations',
  'description',
]
// #roles
const MERCHANT = {
  key: 'general.merchant',
  label: 'merchant',
  noTranslation: true,
  labelKey: 'general.name',
  optionsKeys: ['organizations'],
  component: 'dropdown',
  isClearable: true,
  getIsHidden: (formValues, optionsData) =>
    ['recipient', 'client', 'donor', 'merchant-admin'].includes(optionsData.user.role),
}

const RECIPIENT = {
  key: 'recipient_id',
  label: 'recipient',
  labelKey: 'name',
  optionsKeys: ['organizations'],
  noTranslation: true,
  component: 'dropdown',
  required: true,
  getOptionsFromFormValues: (formValues, optionsData) => {
    // console.log(optionsData)
    return (
      optionsData.organizations &&
      Object.values(optionsData.organizations).filter(
        (org) => org.state === 'posted' // && client.address?.country?.id
      )
    )
  },
  // getIsHidden: (formValues, optionsData) => ['recipient', 'client'].includes(optionsData.user.role),
}

const SUPPLIER = {
  key: 'supplier_id',
  label: 'supplier',
  labelKey: 'name',
  optionsKeys: ['organizations'],
  noTranslation: true,
  component: 'dropdown',
  required: true,
  //getIsHidden: (formValues, optionsData) => !['system', 'administrator'].includes(optionsData.user.role),
  getOptionsFromFormValues: (formValues, optionsData) => {
    return (
      optionsData.organizations &&
      Object.values(optionsData.organizations).filter((org) => org.state === 'posted')
    )
  },
}

const ISSUER = {
  key: 'issuer_id',
  label: 'issuer',
  labelKey: 'name',
  optionsKeys: ['organizations'],
  noTranslation: true,
  component: 'dropdown',
  required: true,
  getIsHidden: (formValues, optionsData) => !['system', 'administrator'].includes(optionsData.user.role),
  getOptionsFromFormValues: (formValues, optionsData) => {
    return (
      optionsData.organizations &&
      Object.values(optionsData.organizations).filter((org) => org.state === 'posted')
    )
  },
}

const PAYER = {
  key: 'payer_id',
  label: 'payer',
  labelKey: 'name',
  optionsKeys: ['organizations'],
  noTranslation: true,
  component: 'dropdown',
  required: true,
  getOptionsFromFormValues: (formValues, optionsData) => {
    return (
      optionsData.organizations &&
      Object.values(optionsData.organizations).filter((org) => org.state === 'posted')
    )
  },
}

export const PHONE = {
  key: 'phone',
  label: 'phone',
  labelType: 'top',
}

export const EMAIL = {
  label: 'email',
  key: 'email',
  labelType: 'top',
  placeholder: 'username@domain.com',
}

export const LANGUAGE = {
  key: 'lang',
  label: 'language',
  component: 'dropdown',
  isMulti: false,
  options: Object.keys(LANGUAGES_FULL_NAME).map((lng) => ({
    id: lng,
    label: LANGUAGES_FULL_NAME[lng],
  })),
}

export const FULL_NAME = {
  label: 'fullName',
  key: 'full_name',
  variant: 'secondary',
  labelType: 'top',
  required: true,
}

const CLIENT = {
  key: 'general.client',
  label: 'client',
  labelKey: 'general.name',
  optionsKeys: ['organizations'],
  noTranslation: true,
  component: 'dropdown',
  getOptionsFromFormValues: (formValues, optionsData) =>
    optionsData.organizations &&
    Object.values(optionsData.organizations).filter(
      (client) => client.state === 'posted' && client.address?.country?.id
    ),
  getIsHidden: (formValues, optionsData) =>
    formValues.general?.client?.id && ['recipient', 'client'].includes(optionsData.user.role),
}

const WAREHOUSE = {
  key: 'warehouse_id',
  label: 'warehouse',
  optionsKeys: ['warehouses'],
  noTranslation: true,
  component: 'dropdown',
  labelKey: 'name',
  // getDefaultValue: options => (options?.length === 1 ? options[0] : undefined),
}

const CURRENCY = {
  key: 'currency_id',
  label: 'currency',
  labelKey: 'code',
  optionsKeys: ['currencies'],
  noTranslation: true,
  required: true,
  // getOptions: (systemObject) => getOptionsFilteredByPlatformChosenItems('currencies', systemObject),
  component: 'dropdown',
}

const STOCK_ITEMS_VALUES = [
  {
    key: 'quantity',
    label: 'quantity',
    type: 'number',
    min: 0,
    step: 'any',
  },
  {
    key: 'price',
    label: 'price',
    type: 'number',
    min: 0,
    step: 'any',
  },
  // {
  //   key: 'price_retail',
  //   label: 'price_retail',
  //   type: 'number',
  //   min: 0,
  //   step: 'any',
  // },
  // {
  //   key: 'price_sale',
  //   label: 'price_sale',
  //   type: 'number',
  //   min: 0,
  //   step: 'any',
  // },
  // {
  //   key: 'discount',
  //   label: 'discount',
  //   type: 'number',
  //   min: 0,
  //   step: 'any',
  // },
]

export const SOCIAL_NETWORKS = [
  {
    key: 'facebook_url',
    label: 'Facebook',
    placeholder: 'Profile URL',
  },
  {
    key: 'instagram_url',
    label: 'Instagram',
    placeholder: 'Profile URL',
  },
  {
    key: 'twitter_url',
    label: 'Twitter',
    placeholder: 'Profile URL',
  },
  {
    key: 'linkedin_url',
    label: 'LinkedIn',
    placeholder: 'Profile URL',
  },
  {
    key: 'youtube_url',
    label: 'YouTube',
    placeholder: 'Profile URL',
  },
  {
    key: 'tiktok_url',
    label: 'TikTok',
    placeholder: 'Profile URL',
  },
  // {
  //   key: 'socialNetworks.pinterest',
  //   label: 'Pinterest',
  //   placeholder: 'Profile URL',
  // },
]

const DATES_POSTGRES = [
  {
    key: 'created_at',
    label: 'dateCreated',
    readOnly: true,
    transformValue: (value) => (value ? dayjs(value).format('DD/MM/YYYY HH:mm:ss') : ''),
    getIsHidden: (formValues) => !formValues.id,
  },
  {
    key: 'updated_at',
    label: 'dateModified',
    readOnly: true,
    transformValue: (value) => (value ? dayjs(value).format('DD/MM/YYYY HH:mm:ss') : ''),
    getIsHidden: (formValues) => !formValues.id,
  },
]

const DATES_MONGO = [
  {
    key: 'meta.created.date',
    label: 'dateCreated',
    readOnly: true,
    transformValue: (value) => (value ? dayjs(value).format('DD/MM/YYYY HH:mm:ss') : ''),
    getIsHidden: (formValues) => !formValues.id,
  },
  {
    key: 'meta.modified.date',
    label: 'dateModified',
    readOnly: true,
    transformValue: (value) => (value ? dayjs(value).format('DD/MM/YYYY HH:mm:ss') : ''),
    getIsHidden: (formValues) => !formValues.id,
  },
]

const ID = {
  key: 'id',
  label: 'Id',
  readOnly: true,
  getIsHidden: (formValues) => !formValues.id,
  // isLink: true,
  // disabled: true,
}

const SLUG = {
  key: 'slug',
  label: 'Slug',
  readOnly: true,
  isLink: true,
}

const STATE = {
  key: 'state',
  label: 'state',
  optionsKeys: ['states'],
  component: 'dropdown',
}

const COUNTRY = {
  key: 'country_id',
  label: 'country',
  labelKey: 'translations',
  required: true,
  optionsKeys: ['countries'],
  component: 'dropdown',
}

const ORDER_WITH_DATE = {
  key: 'order_id',
  label: 'order',
  optionsKeys: ['orders'],
  noTranslation: true,
  labelKey: 'number',
  component: 'dropdown',
  customGetOptionLabel: (option, t) => {
    return `№ ${option.number || option.id} ${t('from')} ${
      option.created_at ? dayjs(option.created_at)?.format('DD/MM/YYYY') : ''
    }`
  },
}

const INQUIRY_WITH_DATE = {
  key: 'inquiry_id',
  label: 'inquiry',
  optionsKeys: ['inquiries'],
  noTranslation: true,
  labelKey: 'number',
  component: 'dropdown',
  isClearable: true,
  customGetOptionLabel: (option, t) => {
    return `№ ${option.number || option.id} ${t('from')} ${
      option.created_at ? dayjs(option.created_at)?.format('DD/MM/YYYY') : ''
    }`
  },
}

const COMMENT = {
  key: 'comment',
  label: 'comment',
  multiline: true,
}
export const COLLAPSED_FORM_BLOCKS = ['admin', 'comments', 'info']

export const FORM_CONFIGS = {
  signup: {
    fields: [
      FULL_NAME,
      {
        label: 'organization',
        key: 'company_name',
        variant: 'secondary',
        labelType: 'top',
      },
      { ...EMAIL, required: true },
      {
        key: 'password',
        label: 'password',
        variant: 'secondary',
        type: 'password',
        labelType: 'top',
        required: true,
      },
      {
        key: 'repeat_password',
        label: 'repeatPassword',
        variant: 'secondary',
        type: 'password',
        labelType: 'top',
        required: true,
      },
    ],
  },
  login: {
    fields: [
      {
        key: 'email',
        label: 'email',
        variant: 'secondary',
        placeholder: 'username@domain.com',
        labelType: 'top',
      },
      { key: 'password', label: 'password', variant: 'secondary', type: 'password', labelType: 'top' },
    ],
  },
  passwordRecovery: {
    fields: [
      {
        ...EMAIL,
        placeholder: 'you@yourcompany.com',
      },
    ],
  },
  brands: {
    validationRules: {
      country: ['required'],
      name: [
        'required',
        {
          type: 'keyInListAbsent',
          customRuleName: 'nameAlreadyExists',
          withSuccess: true,
          dataForValidation: {
            listKey: 'brands',
            searchKey: 'slug',
            transformValue: (value) => slugify(value),
          },
        },
      ],
    },
    withTabs: ['description'],
    fields: {
      general: [
        {
          key: 'name',
          label: 'name',
          disabledWhenNotEmpty: true,
          required: true,
        },
        {
          key: 'name_en',
          label: 'nameInEnglish',
        },
        COUNTRY,
        {
          key: 'email',
          label: 'email',
          placeholder: 'username@domain.com',
        },
        {
          key: 'website',
          label: 'website',
          placeholder: 'https://domain.com',
        },
      ],
      translations: [
        {
          key: 'description',
          label: 'description',
          type: 'richText',
        },
      ],
      photos: [],
      admin: [STATE, ...DATES_POSTGRES, ID, SLUG],
    },
  },
  items: {
    validationRules: {
      category: ['required'],
      sku: [
        'required',
        {
          type: 'keyInListAbsent',
          customRuleName: 'modelAlreadyExists',
          withSuccess: true,
          dataForValidation: {
            listKey: 'items',
            filterListBy: 'brand.id',
            searchKey: 'sku',
          },
        },
      ],
      brand: ['required'],
      'translations.en': ['required'],
    },
    withTabs: ['translations', 'description'],
    fields: {
      general: [
        {
          key: 'sku',
          label: 'model',
          disabledWhenNotEmpty: true,
          addElementToInput: (updateInput, t, disabled) => (
            <StyledAdditionToInputLink
              disabled={disabled}
              onClick={() =>
                updateInput({
                  target: { name: 'sku', value: customAlphabet(alphanumeric, 6)() },
                })
              }
              text={t('generateModelNumber')}
            />
          ),
        },
        {
          key: 'brand_id',
          label: 'brand',
          optionsKeys: ['brands'],
          required: true,
          noTranslation: true,
          component: 'dropdown',
          labelKey: 'name',
        },
        {
          key: 'category_id',
          label: 'category',
          required: true,
          component: 'dropdown',
          optionsKeys: ['categories'],
          labelKey: 'translations',
        },
        {
          key: 'weight',
          label: 'weight',
        },
        {
          key: 'weight_unit_id',
          label: 'weightUnit',
          labelKey: 'translations',
          optionsKeys: ['units'],
          component: 'dropdown',
          noTranslation: true,
        },
        {
          key: 'tags',
          label: 'tags',
          getOptionsFromFormValues: (formValues, optionsData) => {
            // const { platformName } = getInfoFromHostname()
            const brandId = formValues.brand?.id
            const brand = optionsData.brands?.[brandId]
            const platformIdsFromBrand = dot.pick('platforms', brand)

            const platforms = (optionsData.platforms && Object.values(optionsData.platforms))?.filter(
              (platform) => platformIdsFromBrand?.includes(platform.id)
            )

            return platforms?.reduce((acc, curr) => {
              const platformTags = curr?.tags?.map((tag) => transformStringSelectValue(tag)) || []

              return [...acc, ...platformTags]
            }, [])
          },
          noTranslation: true,
          isMulti: true,
          component: 'dropdown',
        },
      ],
      translations: [
        {
          key: 'translations',
          label: 'title',
          placeholder: 'Your item title',
          required: true,
        },
        {
          key: 'description',
          label: 'description',
          type: 'richText',
        },
      ],
      characteristics: {
        getBlockValuesByCustomRule: (formValues, optionsData) => {
          const category = formValues?.category && optionsData.categories?.[formValues.category.id]
          return category && pick('characteristics', category)
        },
      },
      properties: {
        getBlockValuesByCustomRule: (formValues, optionsData) => {
          const category = formValues?.category && optionsData.categories?.[formValues.category.id]
          return category && pick('properties', category)
        },
      },
      // content: [],
      photos: [],
      admin: [STATE, ...DATES_MONGO, SLUG, ID],
      // dimensions: [
      //   { key: 'dimensions.length', label: 'Length, m', type: 'number', min: 0, step: 'any' },
      //   { key: 'dimensions.width', label: 'Width, m', type: 'number', min: 0, step: 'any' },
      //   { key: 'dimensions.height', label: 'Height, m', type: 'number', min: 0, step: 'any' },
      //   { key: 'dimensions.volume', label: 'Volume, m³', type: 'number', min: 0, step: 'any' },
      //   { key: 'dimensions.weight', label: 'Weight, kg', type: 'number', min: 0, step: 'any' },
      // ],
      // modifications: [],
    },
  },
  products: {
    validationRules: {
      recipient_id: ['required'],
    },
    fields: {
      general: [
        { ...RECIPIENT, getDisabled: (formValues) => !!formValues.inquiry_id },
        {
          ...INQUIRY_WITH_DATE,
          onSelectValueChange: (val, setValuesChanged, initialValues) => {
            setValuesChanged((prev) => ({
              // ...prev,
              inquiry_id: val,
              recipient_id: val?.recipient_id || (val === null && initialValues.recipient_id) || '',
              // ['recipient_id.id']: val?.recipient_id || '',
            }))
          },
        },
        COMMENT,
      ],
    },
  },
  orders: {
    validationRules: {
      // 'client': ['required'],
      // 'contract':['required'],
      recipient_id: ['required'],
      supplier_id: ['required'],
      warehouse_id: ['required'],
    },
    fields: {
      general: [
        { ...STATE, getIsHidden: (formValues) => !formValues.id },
        {
          ...SUPPLIER,
          onSelectValueChange: (val, setValuesChanged, initialValues) => {
            setValuesChanged((prev) => {
              return {
                ...prev,
                supplier_id: val,
                warehouse_id: '',
              }
            })
          },
        },
        {
          ...WAREHOUSE,
          required: true,
          getDisabled: (formValues, optionsData) => !formValues.supplier_id,
          getOptionsFromFormValues: (formValues, optionsData) => {
            return (
              optionsData.warehouses &&
              Object.values(optionsData.warehouses).filter(
                (warehouse) =>
                  warehouse.organization_id === (formValues.supplier_id?.id || formValues.supplier_id)
              )
            )
          },
        },
        {
          key: 'external_number',
          label: 'externalNumber',
          component: 'input',
          getIsHidden: (formValues) => !formValues.id,
        },
        RECIPIENT,
        {
          ...INQUIRY_WITH_DATE,
          // isDisabled: true,
          onSelectValueChange: async (val, setValuesChanged, initialValues, optionsData) => {
            setValuesChanged((prev) => ({
              ...prev,
              recipient_id: val?.recipient_id || '',
            }))
          },
          getIsHidden: (formValues) => !formValues.id,
        },
        {
          ...CURRENCY,
          getIsHidden: (formValues) => !formValues.id,
        },
        {
          key: 'tax_rate',
          label: 'taxRate',
          component: 'dropdown',
          options: TAXES_TYPE,
          getIsHidden: (formValues) => !formValues.id,
        },
        // {
        //   key: 'total',
        //   label: 'Total',
        //   readOnly: true,
        //   transformValue: (price) => getPriceWithCurrency(price),
        // },
      ],
      // {
      //   key: 'items',
      //   label: 'Total Items',
      //   readOnly: true,
      //   transformValue: value => {
      //     return value?.reduce((acc, curr) => {
      //       acc += +curr.quantity
      //       return acc
      //     }, 0)
      //   },
      // },
      order_items: [],
      comments: [], // prev: activity
      admin: [
        {
          key: 'status',
          label: 'status',
          optionsKeys: ['statuses'],
          component: 'dropdown',
          isDisabled: true,
          getIsHidden: (formValues) => !formValues.id,
        },
        ...DATES_POSTGRES,
        ID,
      ],
      // activity: [],
    },
  },
  ordersPosted: {
    validationRules: {},
    fields: { noTitle: [], order_items: [], comments: [] },
  },
  carriers: {
    validationRules: {
      'general.name': ['required'],
      'general.website': ['website'],
      'general.email': ['email'],
    },
    fields: {
      general: [
        {
          key: 'general.name',
          label: 'name',
        },
        {
          key: 'general.website',
          label: 'website',
          onInputValueChange: (value, setFormValues) => {
            const presetPart = 'https://'
            const formattedValue =
              value === 'https:/' ? presetPart : `${presetPart}${value.replace(presetPart, '')}`
            setFormValues((prev) => {
              return { ...prev, general: { ...prev?.general, url: formattedValue } }
            })
          },
        },
        {
          key: 'general.email',
          label: 'email',
        },
        COUNTRY,
      ],
      deliveryOptions: [],
      photos: [],
      admin: [STATE, ID],
    },
  },
  categories: {
    withTabs: ['translations'],
    validationRules: {
      name: [
        'required',
        {
          type: 'keyInListAbsent',
          customRuleName: 'nameAlreadyExists',
          withSuccess: true,
          dataForValidation: {
            listKey: 'categories',
            searchKey: 'slug',
            transformValue: (value) => slugify(value),
          },
        },
      ],
      'translations.en': ['required'],
    },
    fields: {
      admin: [STATE, ...DATES_POSTGRES, ID, SLUG],
      general: [
        {
          key: 'name',
          label: 'systemNameInEnglish',
          required: true,
          onInputValueChange: (value, setFormValues, initialValues) => {
            if (!initialValues.id)
              setFormValues((prev) => {
                return { ...prev, 'translations.en': value }
              })
          },
        },
        // {
        //   key: 'unit',
        //   label: 'unit',
        //   optionsKeys: ['units'],
        //   component: 'dropdown',
        //   noTranslation: true,
        // },
        {
          key: 'parent_id',
          label: 'parentCategory',
          labelKey: 'name',
          noTranslation: true,
          optionsKeys: ['categories'],
          component: 'dropdown',
          getOptionsFromFormValues: (formValues, systemObject) => {
            const categoriesOptions = systemObject?.categories ? Object.values(systemObject?.categories) : []
            return categoriesOptions
          },
        },
        // {
        //   key: 'characteristics',
        //   label: 'characteristics',
        //   labelKey: 'name',
        //   optionsKeys: ['attributes'],
        //   getOptions: (optionsData) => {
        //     return (
        //       optionsData?.attributes &&
        //       Object.values(optionsData.attributes).filter((attr) => attr.is_characteristic)
        //     )
        //   },
        //   valueKey: 'slug',
        //   isMulti: true,
        //   component: 'dropdown',
        //   // noTranslation: true,
        // },
        // {
        //   key: 'properties',
        //   label: 'properties',
        //   optionsKeys: ['attributes'],
        //   valueKey: 'slug',
        //   labelKey: 'translations',
        //   isMulti: true,
        //   component: 'dropdown',
        //   // noTranslation: true,
        // },
        // {
        //   key: 'general.content',
        //   label: 'content',
        //   optionsKeys: ['enumerations'],
        //   labelKey: 'translations.label',
        //   getOptions: (optionsData) => {
        //     return (
        //       optionsData?.enumerations &&
        //       Object.values(optionsData.enumerations).map((enumeration) => ({
        //         id: enumeration.id,
        //         translations: enumeration.translations,
        //         general: enumeration.general,
        //       }))
        //     )
        //   },
        //   isMulti: true,
        //   component: 'dropdown',
        // },
        { key: 'is_used_in_inquiries', label: 'isUsedForInquiries', component: 'checkbox' },
      ],
      translations: [
        {
          key: 'translations',
          label: 'title',
          placeholder: 'Your item title',
          required: true,
        },
      ],
      photos: [],
    },
  },
  units: {
    withTabs: ['translations'],
    validationRules: {
      'translations.en': ['required'],
      code: ['required'],
      coefficient: ['required'],
      magnitude: ['required'],
    },
    fields: {
      general: [
        {
          key: 'code',
          label: 'code',
          required: true,
        },
        {
          key: 'magnitude',
          label: 'magnitude',
          optionsKeys: ['magnitudes'],
          component: 'dropdown',
          required: true,
        },
        {
          key: 'coefficient',
          label: 'Coefficient',
          required: true,
          type: 'number',
          min: 0,
          step: '0.001',
        },
      ],
      translations: [
        {
          key: 'translations',
          label: 'title',
          placeholder: 'Your item title',
          required: true,
        },
      ],
      admin: [STATE, ...DATES_POSTGRES, ID],
    },
  },
  enumerations: {
    withTabs: ['translations'],
    validationRules: {
      name: [
        'required',
        {
          type: 'keyInListAbsent',
          customRuleName: 'nameAlreadyExists',
          withSuccess: true,
          dataForValidation: {
            listKey: 'enumerations',
            searchKey: 'slug',
            transformValue: (value) => slugify(value),
          },
        },
      ],
      'translations.en': ['required'],
    },
    fields: {
      general: [
        {
          key: 'name',
          label: 'systemNameInEnglish',
          required: true,
          onInputValueChange: (value, setFormValues, initialValues) => {
            if (!initialValues.id)
              setFormValues((prev) => {
                return { ...prev, 'translations.en': value }
              })
          },
        },
        {
          key: 'addons',
          label: 'addons',
          optionsKeys: ['enumerationAddons'],
          component: 'dropdown',
        },
        // {
        //   key: 'is_icons_upload_allowed',
        //   label: 'optionIconsUploadIsAllowed',
        //   component: 'checkbox',
        // },
      ],
      translations: [
        {
          key: 'translations',
          label: 'title',
          placeholder: 'Your item title',
          required: true,
        },
      ],
      options: [],
      admin: [STATE, ...DATES_POSTGRES, ID, SLUG],
    },
  },
  attributes: {
    withTabs: ['translations'],
    validationRules: {
      name: [
        'required',
        {
          type: 'keyInListAbsent',
          customRuleName: 'nameAlreadyExists',
          withSuccess: true,
          dataForValidation: {
            listKey: 'attributes',
            searchKey: 'slug',
            transformValue: (value) => slugify(value),
          },
        },
      ],
      attribute_type: ['required'],
      enumeration_id: ['required'],
      unit_id: ['required'],
      'translations.en': ['required'],
    },
    changingBlocksDependency: 'attribute_type',
    changingBlocks: {
      enum: {
        general: [
          {
            key: 'enumeration_id',
            label: 'enumeration',
            labelKey: 'translations',
            valueKey: 'id',
            noTranslation: true,
            required: true,
            component: 'dropdown',
            getOptions: (optionsData) => {
              return optionsData.enumerations
                ? Object.values(optionsData.enumerations).map((enumeration) => ({
                    id: enumeration.id,
                    translations: enumeration.translations,
                  }))
                : []
            },
          },
        ],
      },
      numeric: {
        general: [
          {
            key: 'unit_id',
            label: 'unit',
            valueKey: 'id',
            labelKey: 'translations',
            noTranslation: true,
            required: true,
            component: 'dropdown',
            getOptions: (optionsData) => {
              return optionsData.units
                ? Object.values(optionsData.units).map((unit) => ({
                    id: unit.id,
                    translations: unit.translations,
                  }))
                : []
            },
          },
        ],
      },
      bool: { general: [] },
    },
    fields: {
      general: [
        {
          key: 'is_characteristic',
          label: 'isCharacteristic',
          component: 'checkbox',
        },
        {
          key: 'is_photo_group',
          label: 'isPhotoGroup',
          component: 'checkbox',
        },
        {
          key: 'name',
          label: 'systemNameInEnglish',
          required: true,
          disabledWhenNotEmpty: true,
          onInputValueChange: (value, setFormValues, initialValues) => {
            if (!initialValues.id)
              setFormValues((prev) => {
                return { ...prev, 'translations.en': value }
              })
          },
        },
        {
          key: 'attribute_type',
          label: 'type',
          required: true,
          optionsKeys: ['attributeTypes'],
          component: 'dropdown',
        },
      ],
      translations: [
        {
          key: 'translations',
          label: 'title',
          placeholder: 'Your item title',
          required: true,
        },
      ],
      admin: [STATE, ...DATES_POSTGRES, ID, SLUG],
    },
  },
  warehouses: {
    withTabs: [],
    validationRules: {
      name: ['required'],
      type: ['required'],
      currency_id: ['required'],
      country_id: ['required'],
    },
    fields: {
      general: [
        {
          key: 'name',
          label: 'name',
          required: true,
        },
        {
          key: 'type',
          label: 'types',
          isMulti: true,
          labelKey: 'label',
          required: true,
          optionsKeys: ['warehouseTypes'],
          component: 'dropdown',
        },
        CURRENCY,
        {
          key: 'organization_id',
          label: 'organization',
          noTranslation: true,
          labelKey: 'name',
          valueKey: 'id',
          optionsKeys: ['organizations'],
          component: 'dropdown',
          isClearable: true,
          required: true,
          getIsHidden: (formValues, optionsData) => {
            return optionsData.user.role !== 'administrator' && optionsData.user.role !== 'system'
          },
        },
      ],
      address: [
        {
          key: 'country_id',
          label: 'country',
          component: 'dropdown',
          optionsKeys: ['countries'],
          labelKey: 'translations',
          required: true,
        },
        {
          key: 'region_id',
          label: 'region',
          component: 'dropdown',
          optionsKeys: ['country_subdivisions'],
          labelKey: 'translations',
        },
        {
          key: 'city',
          label: 'city',
        },
      ],
      admin: [STATE, ...DATES_POSTGRES, ID],
    },
  },
  'stock-items': {
    withTabs: [],
    validationRules: {},
    fields: {
      general: [
        {
          key: 'brand_id',
          label: 'brand',
          optionsKeys: ['brands'],
          noTranslation: true,
          readOnly: true,
          component: 'dropdown',
          labelKey: 'name',
        },
        {
          key: 'item_id',
          label: 'item',
          optionsKeys: ['items'],
          readOnly: true,
          noTranslation: true,
          component: 'dropdown',
          labelKey: 'sku',
          customGetOptionLabel: (option, t, lng) => {
            const title = getAvailableTranslation(option?.translations, DEFAULT_LANGUAGE, lng)

            return title ? `${title} (${option?.sku})` : option?.sku
          },
        },
        {
          key: 'modification_id',
          getOptionsFromFormValues: (formValues) => ({
            item_id: formValues.item_id,
          }),
          component: ({ value, lng, optionsFromFormValues, optionsData, t }) => {
            const itemId = optionsFromFormValues.item_id
            const modifications = optionsData.items?.[itemId]?.modifications
            const modValue = getSlugsFromCharacteristicValue(
              convertArrayToObject(modifications, 'modification_id')?.[value]?.value
            )

            if (!modValue) return null

            return (
              <div style={{ marginBottom: '20px' }}>
                {<StyledLabel labelFontWeight={600} text={t('characteristics')} variant="primary" />}
                <Characteristics
                  itemModId={value}
                  lng={lng}
                  attributes={optionsData.attributes}
                  enumerations={optionsData.enumerations}
                  modifications={convertArrayToObject(modifications, 'modification_id')}
                  modValue={modValue}
                />
              </div>
            )
          },
        },
      ],
      values: STOCK_ITEMS_VALUES,
      admin: [...DATES_POSTGRES, ID, STATE],
    },
  },
  stockItemsNew: {
    withTabs: [],
    validationRules: {
      warehouse_id: ['required'],
      brand_ids: [
        {
          type: 'requiredIfFieldsEmpty',
          fields: ['brand_ids', 'item_ids', 'modification_ids'],
        },
      ],
      item_ids: [
        {
          type: 'requiredIfFieldsEmpty',
          fields: ['brand_ids', 'item_ids', 'modification_ids'],
        },
      ],
      modification_ids: [
        {
          type: 'requiredIfFieldsEmpty',
          fields: ['item_ids', 'brand_ids', 'modification_ids'],
        },
      ],
    },
    fields: {
      general: [
        WAREHOUSE,
        {
          key: 'brand_ids',
          label: 'brands',
          optionsKeys: ['brands'],
          noTranslation: true,
          isMulti: true,
          component: 'dropdown',
          labelKey: 'name',
          validationTranslationKeys: ['brands', 'items', 'characteristics'],
          getDisabled: (formValues) => !!formValues.item_ids?.length,
          getOptionsFromFormValues: (formValues, optionsData) => {
            return optionsData?.brands && Object.values(optionsData.brands)
          }, // TODO: understand why this unnecessary "getOptionsFromFormValues" fixes validation bug
        },
        {
          key: 'item_ids',
          label: 'items',
          isMulti: true,
          noTranslation: true,
          component: 'dropdown',
          validationTranslationKeys: ['brands', 'items', 'characteristics'],
          onSelectValueChange: (val, setValuesChanged) => {
            if (!val || val.length > 1) {
              setValuesChanged((prev) => ({
                ...prev,
                modification_ids: null,
              }))
            }
          },
          customGetOptionLabel: (option, t, lng) => {
            const title = getAvailableTranslation(option?.translations, DEFAULT_LANGUAGE, lng)

            return title ? `${title} (${option?.sku})` : option?.sku
          },
          getOptionsFromFormValues: (formValues, optionsData) => {
            const allItems = optionsData?.items && Object.values(optionsData.items)

            return formValues.brand_ids?.length
              ? allItems.filter(
                  (item) => formValues.brand_ids.findIndex((brand) => brand.id === item?.brand_id) !== -1
                )
              : allItems
          },
        },
        {
          key: 'modification_ids',
          label: 'characteristics',
          isMulti: true,
          noTranslation: true,
          component: 'dropdown',
          valueKey: 'value',
          customGetOptionLabel: (option, t, lng) => {
            return getSlugsFromCharacteristicValue(option.value)
          },
          validationTranslationKeys: ['brands', 'items', 'characteristics'],
          getOptionsFromFormValues: (formValues) => {
            const itemMods = formValues.item_ids?.[0]?.modifications || []

            return formValues.item_ids?.length === 1
              ? itemMods.filter((modification) => modification.state === 'posted')
              : null
          },
        },
      ],
      values: STOCK_ITEMS_VALUES,
      admin: [STATE],
    },
  },
  users: {
    withTabs: [],
    validationRules: {
      full_name: ['required'],
      email: ['required', 'email'],
      role: ['required'],
      organization: ['required'], // TODO: make required if roles not admin, pl-admin
    },
    // changingBlocksDependency: 'general.roles',
    fields: {
      general: [
        {
          key: 'full_name',
          label: 'fullName',
          required: true,
        },
        {
          key: 'email',
          label: 'email',
          required: true,
          getDisabled: (formValues) => !!formValues.id && !!formValues.email,
        },
        {
          key: 'organization_from_signup',
          label: 'organizationFromSignup',
          getDisabled: (formValues) => !!formValues.id,
        },
        {
          key: 'profile_pic',
          label: 'profilePicture',
          getIsHidden: (formValues) => !formValues.id,
        },
        {
          key: 'role',
          label: 'role',
          optionsKeys: ['userRoles'],
          component: 'dropdown',
          required: true,
          getDisabled: (formValues, optionsData, initialValues) =>
            initialValues.role === 'system' ||
            // optionsData.user?.role === 'administrator' ||
            optionsData.user?.id === formValues.id,
          // isOptionDisabled: (optionsData) => (option) => {
          //   return (
          //     option.id === 'system' ||
          //     (optionsData.role !== 'system' && option.id === 'customer_owner')
          //   )
          // },
        },
        {
          key: 'organization_id',
          label: 'organization',
          noTranslation: true,
          labelKey: 'name',
          valueKey: 'id',
          optionsKeys: ['organizations'],
          component: 'dropdown',
          isClearable: true,
          required: true,
          getIsHidden: (formValues, optionsData) => {
            return optionsData.user.role !== 'administrator' && optionsData.user.role !== 'system'
          },
        },
        // {
        //   key: 'password',
        //   label: 'password',
        //   type: 'password',
        // },
      ],
      admin: [
        {
          key: 'status',
          label: 'status',
          optionsKeys: ['orgOrUsersStatuses'],
          component: 'dropdown',
          getIsHidden: (formValues) => !formValues.id,
        },
        ...DATES_POSTGRES,
        ID,
      ],
    },
  },
  organizations: {
    withTabs: ['description'],
    validationRules: {
      name: ['required'],
      type: ['required'],
      // roles: ['required'],
      phone: ['phone'],
      email: ['email'],
      country_id: ['required'],
      year_founded: ['year'],
    },
    fields: {
      general: [
        {
          key: 'name',
          label: 'name',
          className: 'firstInOrder',
          required: true,
        },
        {
          key: 'name_en',
          label: 'nameInEnglish',
        },
        {
          key: 'type',
          label: 'type',
          component: 'dropdown',
          options: ORGANIZATION_TYPES,
          required: true,
        },
        {
          key: 'roles',
          label: 'roles',
          component: 'dropdown',
          optionsKeys: ['organizationRoles'],
          isMulti: true,
          // required: true,
        },
        {
          key: 'year_founded',
          label: 'yearFounded',
          type: 'number',
          hideButtons: true,
        },
        {
          key: 'reg_number',
          label: 'registrationNumber',
        },
        {
          key: 'website',
          label: 'website',
          placeholder: 'https://domain.com',
        },
        {
          key: 'email',
          label: 'email',
          placeholder: 'email@address.com',
        },
        {
          key: 'video_url',
          label: 'introductionVideo',
        },
        // {
        //   key: 'phone',
        //   label: 'phoneNumber',
        // },
        // {
        //   key: 'general.contactPerson',
        //   label: 'contactPerson',
        // },
        // {
        //   key: 'general.pickup',
        //   label: 'pickup',
        //   component: 'checkbox',
        // },
      ],
      address: [
        {
          key: 'country_id',
          label: 'country',
          component: 'dropdown',
          optionsKeys: ['countries'],
          labelKey: 'translations',
          required: true,
        },
        {
          key: 'region_id',
          label: 'region',
          component: 'dropdown',
          optionsKeys: ['country_subdivisions'],
          labelKey: 'translations',
          isClearable: true,
        },
        {
          key: 'city',
          label: 'city',
        },
      ],
      translations: [
        {
          key: 'description',
          label: 'description',
          type: 'richText',
        },
      ],
      fundraisingAccounts: [
        {
          key: 'wayforpay_url',
          label: 'wayforpayURL',
          placeholder: 'Profile Url',
        },
        {
          key: 'paypal_url',
          label: 'PayPal',
          placeholder: 'Profile Url',
        },
        {
          key: 'patreon_url',
          label: 'Patreon',
          placeholder: 'Profile Url',
        },
        {
          key: 'buymeacoffee_url',
          label: 'Buy Me A Coffee',
          placeholder: 'Profile Url',
        },
        {
          key: 'venmo_url',
          label: 'Venmo',
          placeholder: 'Profile Url',
        },
      ],
      // shipmentAddress: [
      //   {
      //     key: 'shipmentAddress.country',
      //     label: 'country',
      //     component: 'dropdown',
      //     optionsKeys: ['countries'],
      //     labelKey: 'translations',
      //   },
      //   {
      //     key: 'shipmentAddress.region',
      //     label: 'region',
      //     // component: 'dropdown',
      //     // optionsKeys: ['ukrainianRegions'],
      //     // labelKey: 'translations.label',
      //   },
      //   {
      //     key: 'shipmentAddress.city',
      //     label: 'city',
      //   },
      //   {
      //     key: 'shipmentAddress.address',
      //     label: 'address',
      //   },
      //   { ...PHONE, key: 'shipmentAddress.phone' },
      // ],
      socialNetworks: SOCIAL_NETWORKS,
      photos: [],
      admin: [
        {
          key: 'status',
          label: 'status',
          optionsKeys: ['orgOrUsersStatuses'],
          component: 'dropdown',
          getIsHidden: (formValues) => !formValues.id,
        },
        STATE,
        ...DATES_POSTGRES,
        SLUG,
        ID,
      ],
    },
  },
  inquiries: {
    withTabs: [''],
    validationRules: { recipient_id: ['required'] },
    fields: {
      general: [RECIPIENT],
      inquiry_items: [],
      comments: [], // prev: activity
      admin: [
        STATE,
        {
          key: 'status',
          label: 'status',
          optionsKeys: ['inquiryStatuses'],
          component: 'dropdown',
          isDisabled: true,
        },
        ...DATES_POSTGRES,
        ID,
      ],
    },
  },
  invoices: {
    withTabs: [''],
    validationRules: {
      currency_id: ['required'],
      issuer_id: ['required'],
      payer_id: ['required'],
      subtotal: ['required'],
    },
    fields: {
      general: [
        {
          key: 'status',
          getIsHidden: (formValues, optionsData) => {
            return optionsData.user.organization_id !== formValues.payer_id
          },
          // getIsHidden: (formValues, optionsData) => optionsData.user.role !== 'donor',
          component: ({ className, sidebarFormId }) => (
            <ChangeStatusButton
              disabled
              className={className}
              type="invoices"
              status="paid"
              sidebarFormId={sidebarFormId}
              style={{ marginBottom: '20px' }}
            />
          ),
        },
        // ORDER_WITH_DATE,
        {
          ...ORDER_WITH_DATE,
          getDisabled: (formValues) => formValues.id,
          onSelectValueChange: (val, setValuesChanged, initialValues, optionsData) => {
            setValuesChanged((prev) => ({
              ...prev,
              subtotal: val.total - (val.tax || 0),
              tax: val.tax,
              // shipment_items: order?.order_items,
              payer_id: val?.recipient_id || '',
            }))
          },
          // onSelectValueChange: (val, setValuesChanged) => {
          //   setValuesChanged((prev) => ({
          //     ...prev,
          //     order_items: val?.order_items,
          //   }))
          // },
        },
        ISSUER,
        PAYER,
        CURRENCY,
        {
          key: 'subtotal',
          label: 'subtotal',
          type: 'number',
          required: true,
        },
        {
          key: 'tax',
          label: 'tax',
          type: 'number',
        },
        // {
        //   key: 'general.contract',
        //   label: 'contract',
        //   labelKey: 'general.name',
        //   optionsKeys: ['contracts'],
        //   noTranslation: true,
        //   // readOnly: true,
        //   component: 'dropdown',
        // },
      ],
      // order_items: [],
      comments: [],
      admin: [
        STATE,
        {
          key: 'status',
          label: 'status',
          optionsKeys: ['invoiceStatuses'],
          component: 'dropdown',
        },
        ...DATES_POSTGRES,
        ID,
      ],
    },
  },
  shipmentsPosted: {
    validationRules: {},
    fields: {
      noTitle: [
        {
          key: 'status',
          label: 'status',
          isReadOnly: false,
          component: 'dropdown',
          className: 'shipmentStatusPosted',
          getOptions: (systemObject) =>
            systemObject.shipmentStatuses &&
            Object.values(systemObject.shipmentStatuses).filter(
              (status) => status.id === 'in_progress' || status.id === 'shipped' || status.id === 'delivered'
            ),
          getIsHidden: (formValues, optionsData) =>
            !['system', 'administrator'].includes(optionsData.user.role),
        },
      ],
      shipment_items: [],
      comments: [],
    },
  },
  shipments: {
    validationRules: {
      // order_id: ['required'],
      warehouse_id: ['required'],
      // 'length': ['required'],
      // 'width': ['required'],
      // 'height': ['required'],
      // 'weight': ['required'],
    },
    fields: {
      general: [
        { ...STATE, getIsHidden: (formValues) => !formValues.id },
        {
          ...ORDER_WITH_DATE,
          onSelectValueChange: async (val, setValuesChanged, initialValues, optionsData) => {
            const token = optionsData.user.token

            const response = await fetch(`${process.env.REACT_APP_API_URL}/documents/orders/${val.id}`, {
              headers: { Authorization: `Bearer ${token}` },
            })
            const order = await response.json()

            setValuesChanged((prev) => ({
              ...prev,
              shipment_items: order?.order_items,
              recipient_id: val?.recipient_id || '',
              supplier_id: val?.supplier_id || '',
              warehouse_id: val?.warehouse_id || '',
            }))
          },
        },
        {
          ...SUPPLIER,
          getDisabled: (formValues) => formValues.order_id?.id || formValues.order_id,
        },
        {
          ...WAREHOUSE,
          required: true,
          getDisabled: (formValues) => formValues.order_id?.id || formValues.order_id,
        },
        RECIPIENT,
        // {
        //   key: 'logist_id',
        //   label: 'carrier',
        //   labelKey: 'name',
        //   noTranslation: true,
        //   optionsKeys: ['organizations'],
        //   component: 'dropdown',
        //   getOptions: (systemObject) =>
        //     systemObject.organizations &&
        //     Object.values(systemObject.organizations).filter((carrier) => carrier.state === 'posted'),
        // },
      ],
      // properties: [
      //   { key: 'length', label: 'lengthM', type: 'number', min: 0, step: 'any' },
      //   { key: 'width', label: 'widthM', type: 'number', min: 0, step: 'any' },
      //   { key: 'height', label: 'heightM', type: 'number', min: 0, step: 'any' },
      //   { key: 'weight', label: 'weightKg', type: 'number', min: 0, step: 'any' },
      //   { key: 'isFragile', label: 'isFragile', component: 'checkbox' },
      //   { key: 'requiresCooling', label: 'requiresCooling', component: 'checkbox' },
      //   { key: 'requiresFreezing', label: 'requiresFreezing', component: 'checkbox' },
      // ],
      shipment_items: [],
      comments: [],
      admin: [
        //{
        //  key: 'status',
        //  label: 'status',
        //  optionsKeys: ['shipmentStatuses'],
        //  component: 'dropdown',
        //  isDisabled: true,
        //},
        ...DATES_POSTGRES,
        ID,
      ],
    },
  },
  contracts: {
    validationRules: {
      'general.name': ['required'],
    },
    fields: {
      general: [
        {
          key: 'general.name',
          label: 'name',
        },
        {
          key: 'general.type',
          label: 'type',
          component: 'dropdown',
          options: CONTRACT_TYPES,
        },
        {
          key: 'general.organizations',
          label: 'organizations',
          isMulti: true,
          noTranslation: true,
          labelKey: 'general.name',
          optionsKeys: ['organizations'],
          component: 'dropdown',
        },
        {
          key: 'general.document',
          label: 'document',
          component: 'dropdown',
          options: [],
        },
      ],
      admin: [STATE, ...DATES_POSTGRES, ID],
    },
  },
  inquiry_items: {
    validationRules: {
      recipient_id: ['required'],
    },
    fields: [RECIPIENT, COMMENT],
  },
  posts: {
    validationRules: {
      topic_id: ['required'],
      'translations.en': ['required'],
      'body.en': ['required'],
      visibility: ['required'],
    },
    withTabs: ['translations', 'body'],
    fields: {
      general: [
        { ...STATE, required: true },
        {
          key: 'topic_id',
          label: 'topic',
          labelKey: 'translations',
          optionsKeys: ['topics'],
          component: 'dropdown',
          required: true,
        },
        {
          key: 'visibility',
          label: 'visibility',
          labelKey: 'label',
          optionsKeys: ['visibilityTypes'],
          component: 'dropdown',
          required: true,
        },
      ],
      translations: [
        {
          key: 'translations',
          label: 'title',
          placeholder: 'Your item title',
          required: true,
        },
        {
          key: 'body',
          label: 'texts',
          type: 'richText',
          toolbarOptions: TOOLBAR_CONFIG,
          required: true,
        },
      ],
      photos: [],
      admin: [...DATES_POSTGRES, ID, SLUG],
    },
  },
  campaigns: {
    validationRules: {
      'translations.en': ['required'],
      'body.en': ['required'],
      sum_goal: ['required'],
      currency_id: ['required'],
      bucket_wayforpay_slug: [
        {
          type: 'requiredIfFieldsEmpty',
          fields: ['bucket_monobank_slug', 'bucket_wayforpay_slug'],
        },
      ],
      bucket_monobank_slug: [
        {
          type: 'requiredIfFieldsEmpty',
          fields: ['bucket_monobank_slug', 'bucket_wayforpay_slug'],
        },
      ],
    },
    withTabs: ['translations', 'body'],
    fields: {
      general: [
        { ...STATE, required: true },
        {
          key: 'deadline_at',
          label: 'deadline',
          component: 'date',
          transformValue: (value) => (value ? new Date(value) : ''),
          minDate: new Date(),
        },
        {
          key: 'currency_id',
          label: 'currency',
          labelKey: 'code',
          optionsKeys: ['currencies'],
          noTranslation: true,
          required: true,
          component: 'dropdown',
        },
        {
          key: 'sum_goal',
          label: 'goalAmount',
          type: 'number',
          required: true,
          hideButtons: true,
        },
        {
          key: 'sum_current',
          label: 'currentAmount',
          type: 'number',
          hideButtons: true,
        },
        {
          key: 'bucket_wayforpay_slug',
          label: 'bucketWayforpay',
          getDisabled: (formValues) => !!formValues.bucket_monobank_slug,
        },
        {
          key: 'bucket_monobank_slug',
          label: 'bucketMonobank',
          getDisabled: (formValues) => !!formValues.bucket_wayforpay_slug,
        },
      ],
      translations: [
        {
          key: 'translations',
          label: 'title',
          placeholder: 'Your item title',
          required: true,
        },
        {
          key: 'body',
          label: 'texts',
          type: 'richText',
          required: true,
        },
      ],
      photos: [],
      admin: [...DATES_POSTGRES, ID, SLUG],
    },
  },
  faqs: {
    validationRules: {
      state: ['required'],
      'translations.en': ['required'],
      'body.en': ['required'],
    },
    withTabs: ['translations', 'body'],
    fields: {
      general: [{ ...STATE, required: true }],
      translations: [
        {
          key: 'translations',
          label: 'question',
          placeholder: 'Your item title',
          required: true,
        },
        {
          key: 'body',
          label: 'answer',
          type: 'richText',
          toolbarOptions: TOOLBAR_CONFIG,
          required: true,
        },
      ],
      admin: [...DATES_POSTGRES, ID],
    },
  },
  reports: {
    validationRules: {
      'translations.en': ['required'],
      state: ['required'],
      period: ['required'],
      year: ['required'],
    },
    withTabs: ['translations'],
    fields: {
      general: [
        { ...STATE, required: true },
        {
          key: 'period',
          label: 'type',
          labelKey: 'label',
          optionsKeys: ['reportTypes'],
          component: 'dropdown',
          required: true,
        },
        {
          key: 'year',
          label: 'year',
          labelKey: 'id',
          optionsKeys: ['years'],
          component: 'dropdown',
          required: true,
          noTranslation: true,
          getOptionsFromFormValues: (_, optionsData) => {
            const years = optionsData?.years ? Object.values(optionsData.years) : []
            return years.reverse()
          },
        },
        {
          key: 'month',
          label: 'month',
          labelKey: 'label',
          optionsKeys: ['months'],
          component: 'dropdown',
          getIsHidden: ({ period }) => (period?.id || period) !== 'monthly',
          required: ({ period }) => (period?.id || period) === 'monthly',
        },
      ],
      translations: [
        {
          key: 'translations',
          label: 'name',
          placeholder: 'Your item title',
          required: true,
        },
      ],
      files: [],
    },
  },
  pages: {
    validationRules: {
      'translations.en': ['required'],
      'body.en': ['required'],
      state: ['required'],
      type: ['required'],
    },
    withTabs: ['translations', 'body'],
    fields: {
      general: [
        { ...STATE, required: true },
        {
          key: 'page_type',
          label: 'type',
          labelKey: 'label',
          noTranslation: true,
          optionsKeys: ['pageTypes'],
          component: 'dropdown',
          required: true,
          getIsHidden: (formValues) => formValues.id,
          onSelectValueChange: (val, setValuesChanged) => {
            setValuesChanged((prev) => ({
              ...prev,
              link: val?.label,
            }))
          },
        },
        {
          key: 'link',
          label: 'linkToPage',
          required: true,
          disabled: true,
        },
      ],
      translations: [
        {
          key: 'translations',
          label: 'title',
          placeholder: 'Your item title',
          required: true,
        },
        {
          key: 'body',
          label: 'texts',
          type: 'richText',
          toolbarOptions: TOOLBAR_CONFIG,
          required: true,
        },
      ],
      photos: [],
      admin: [...DATES_POSTGRES, ID],
    },
  },
  inventories: {
    validationRules: {
      state: ['required'],
    },
    fields: {
      details: [
        {
          ...WAREHOUSE,
          required: true,
          getIsHidden: (_, optionsData, initialValues) => getStateCheck(initialValues),
        },
        {
          ...STATE,
          required: true,
          getIsHidden: (_, optionsData, initialValues) => getStateCheck(initialValues),
        },
      ],
      inventory_items: [],
    },
  },
}
