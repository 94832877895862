import { REDIRECT_NOT_AUTHORIZED, REDIRECT_ROOT, USER_ROLES } from '../config'
// #roles
/**
 * Validates authorization for given path based on user roles
 * @param {string} path
 * @param {array} roles
 */
export const isUserAuthorized = (path, roles) => {
  if (path === REDIRECT_ROOT || path === REDIRECT_NOT_AUTHORIZED) {
    return true
  } else if (!Array.isArray(roles) || roles.length === 0) {
    return false
  }

  const accessLevel = getUserAccessLevel(roles)

  return Object.keys(USER_ROLES).some((role) => {
    if (buildPathRegex(role).test(path)) {
      if (
        USER_ROLES[role].entities?.length &&
        USER_ROLES[role].entities.some((entity) => path.startsWith(`/${entity}`))
      ) {
        return accessLevel === USER_ROLES[role].level
      }
      return accessLevel <= USER_ROLES[role].level
    }
  })
}

/**
 * Builds regular expression for the given role
 * @param {string} role
 * @returns {string}
 */
const buildPathRegex = (role) => {
  const allPathEntities = (USER_ROLES[role].entitiesSharedWithUpperRoles || []).concat(
    USER_ROLES[role].entities || []
  )

  return new RegExp(`^/(${allPathEntities.join('|')})(/[a-z0-9]+)?$`)
}

/**
 * Returns minimal available access level
 * @param {string} role - user roles
 * @returns {integer}
 */
export const getUserAccessLevel = (role) => {
  const maxLevel = Object.keys(USER_ROLES).length - 1

  return USER_ROLES[role]?.level ?? maxLevel
}

export const isAccessAllowed = (accessLevel, userRole) => {
  const userAccessLevel = getUserAccessLevel(userRole)

  return typeof accessLevel === 'number'
    ? accessLevel >= userAccessLevel
    : accessLevel.some((accessRole) => userRole === accessRole)
}
