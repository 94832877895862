import React from 'react'
import { InfoRowsBlock, Typography, Tag, Tooltip } from '@aidsupply/components'
import ReactTexty from 'react-texty'
import { useTranslation } from 'react-i18next'
import { withTheme } from 'styled-components'
import { useMappedState } from '../../../hooks'
import { selectUserRole, selectAllSystemCollections } from '../../../redux/selectors'

const WarehousesTableCard = ({ className, data, initialData, theme }) => {
  const { name, state, currency_id, type, city, organization_id, checkbox } = data

  const userRole = useMappedState(selectUserRole)
  const isOrganizationVisible = userRole === 'administrator' || userRole === 'system'

  const {
    i18n: { language: lng },
    t,
  } = useTranslation('table')
  const getWarehouseTypeFirstLetter = (type) => {
    type = t(type)
    return type.charAt(0).toUpperCase()
  }
  const getWarehouseTypesString = (types) => {
    const allTypesString = types
      .map((type) => {
        const translatedType = t(type)
        const capitalizedType = translatedType.charAt(0).toUpperCase() + translatedType.slice(1)
        return capitalizedType
      })
      .join(', ')
    return allTypesString
  }
  const allTypesString = getWarehouseTypesString(initialData.type)

  return (
    <InfoRowsBlock
      className={className}
      data={[
        {
          name: {
            customElement: <Typography as={ReactTexty} type="body1" text={name} />,
            width: 'calc(100% - 63px)',
            hideBorder: true,
            padding: '10px 0 10px 10px',
          },
          state: {
            text: state,
            width: '36px',
            hideBorder: true,
            padding: '10px',
          },
          checkbox: {
            customElement: checkbox,
            width: '20px',
            padding: '10px 0',
          },
        },
        {
          currency_id: {
            iconProps: {
              name: 'currency',
            },
            text: currency_id,
            width: '50%',
            hideBorder: false,
            padding: '10px',
          },
          type: {
            iconProps: {
              name: 'deliveryBox',
            },
            customElement: (
              <Tooltip
                textColor={theme.color.general.light}
                text={allTypesString}
                right="5px"
                arrowPosition="right"
                padding="15px 15px"
              >
                {initialData.type.map((type) => (
                  <Tag key={type} text={getWarehouseTypeFirstLetter(type)} />
                ))}
              </Tooltip>
            ),
            width: '50%',
            padding: '5px 10px',
          },
        },
        {
          address: {
            text: city,
            width: '100%',
            padding: '10px',
          },
        },
        isOrganizationVisible && {
          organization: {
            iconProps: {
              name: 'warehouse',
            },
            text: organization_id,
            width: '100%',
          },
        },
      ].filter(Boolean)}
    />
  )
}

export default withTheme(WarehousesTableCard)
