import merge from 'deepmerge'
import { object } from 'dot-object'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { isObject } from '@aidsupply/components/build/utils'

import EditForm from '../../../components/RightPanelForms'
import { useMappedState } from '../../../hooks'
import { sidebarUpsert } from '../../../redux/actions'
import { selectSidebarInitialData, selectSidebarItemId } from '../../../redux/selectors'

const ReportsRightPanel = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const itemId = useMappedState(selectSidebarItemId)
  const itemInitial = useMappedState(selectSidebarInitialData)

  const onFormSubmit = (formValuesChanged) => {
    const requestBody = { ...object(formValuesChanged) }

    Object.keys(requestBody).map((key) => {
      if (isObject(requestBody[key]) && requestBody[key].id) {
        requestBody[key] = requestBody[key].id
      }
    })

    if (requestBody?.translations?.en) {
      requestBody.name = requestBody.translations.en
    }

    if (requestBody.translations) {
      requestBody.translations = merge(itemInitial.translations, requestBody.translations)
    }

    const dataToSend = {
      id: itemId,
      requestBody,
      type: 'reports',
      parentType: 'platform',
    }

    dispatch(sidebarUpsert(dataToSend, navigate))
  }
  return <EditForm onFormSubmit={onFormSubmit} />
}

export default ReportsRightPanel
