import React, { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { withTheme } from 'styled-components'
import { deviceType } from 'detect-it'
import ReactTexty from 'react-texty'
import clsx from 'clsx'
import { useLocation, useNavigate } from 'react-router-dom'

import {
  Icon,
  Tile,
  buildImagePath,
  IMAGEKIT_PARAMS_CONFIG,
  getAvailableTranslation,
  useUnmount,
  isObjectEmpty,
  CategoryCharacteristicsToChoose,
  Button,
  FlexRow,
  Typography,
  QuantityInput,
  Tag,
} from '@aidsupply/components'

import { addDemandToBasket, sidebarItemSet } from '../../redux/actions'
import { useMappedState } from '../../hooks'
import { selectSystemCollections } from '../../redux/selectors'
import { StyledCardAttributes, StyledSearchItem, StyledIconTagCounter, StyledNumberTag } from './styled'
import { IMAGEKIT_URL } from '../../constants'

const imagekitParams = IMAGEKIT_PARAMS_CONFIG.platform.search.item

const SearchCategoryCard = ({ data, theme: { color } }) => {
  const {
    i18n: {
      language,
      options: { defaultLng },
    },
  } = useTranslation('table')
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()

  const { attributes, enumerations } = useMappedState(selectSystemCollections(['attributes', 'enumerations']))

  const [attributesChosen, setAttributesChosen] = useState({})
  const [quantity, setQuantity] = useState(1)

  const [itemAddedToBasket, setItemAddedToBasket] = useState('')

  const { translations } = data || {}
  const title = getAvailableTranslation(translations, defaultLng, language)

  const onAddItemClick = useCallback(
    (item) => () => {
      const itemWithCharacteristics = isObjectEmpty(attributesChosen)
        ? { ...item, quantity }
        : { ...item, characteristicsChosen: attributesChosen, quantity }

      dispatch(addDemandToBasket(itemWithCharacteristics))
      setItemAddedToBasket(item.id)

      if (location.pathname !== '/inquiry-items/new') {
        dispatch(sidebarItemSet({}))
        navigate('/inquiry-items/new', { replace: true })
      }

      window.basketItemAddedTimeout = setTimeout(() => {
        setItemAddedToBasket('')
      }, 1500)
    },
    [dispatch, attributesChosen, quantity]
  )

  const onChangeQuantity = (value) => {
    setQuantity(value)
  }

  useUnmount(() => {
    clearTimeout(window.basketItemAddedTimeout)
  })

  const characteristicsCount = data.characteristics?.length || '0'

  return (
    <StyledSearchItem key={data.id} className={clsx(deviceType, 'category')}>
      <StyledIconTagCounter>
        <Icon name="settingsCard" stroke={color.general.light} fill={color.general.light} />
        <Tag backgroundColor={color.general.light} color={color.general.dark} fontSize={12} fontWeight={600}>
          {characteristicsCount}
        </Tag>
      </StyledIconTagCounter>

      <Tile
        imagekitParams={imagekitParams}
        imagekitUrl={IMAGEKIT_URL}
        radius={4}
        title={<ReactTexty>{title}</ReactTexty>}
        imageSrc={data.photo_url}
        titleProps={{
          type: 'caption1',
        }}
        imageHeight={314}
      />
      <StyledCardAttributes>
        <Typography margin="0 0 10px 0" as={ReactTexty} text={title} type="caption1" />
        <CategoryCharacteristicsToChoose
          attributes={attributes}
          enumerations={enumerations}
          category={data}
          setAttributesChosen={setAttributesChosen}
          lng={language}
          selectOpenDirection="top"
        />
        <FlexRow gap="20px" className="basketAddRow">
          <QuantityInput
            onChange={onChangeQuantity}
            value={quantity}
            min={1}
            className="quantityInput"
            iconProps={{ strokeWidth: 1.4 }}
            withBorder
          />
          <Button
            variant={itemAddedToBasket === data.id ? 'bordered' : 'primary'}
            size="small"
            onClick={onAddItemClick(data)}
            padding="9px"
          >
            <Icon
              name={itemAddedToBasket === data.id ? 'checkRounded' : 'shoppingCart2'}
              fill={itemAddedToBasket === data.id ? color.status.success : color.general.light}
              height={20}
              width={20}
            />
          </Button>
        </FlexRow>
      </StyledCardAttributes>
    </StyledSearchItem>
  )
}

export default withTheme(SearchCategoryCard)
