import React from 'react'

import { ScreenContext, useScreenContext } from '@aidsupply/components'
import theme from '../theme'

const ScreenContextWrapper = ({ children }) => {
  const screenContextValues = useScreenContext(theme)

  return <ScreenContext.Provider value={screenContextValues}>{children}</ScreenContext.Provider>
}

export default ScreenContextWrapper
