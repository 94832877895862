import {
  CLEAR_NOTIFICATIONS,
  SIGN_OUT_SUCCESS,
  MARK_NOTIFICATION_AS_OLD,
  POPUP_ALERT_HIDE,
  POPUP_ALERT_HIDE_ALL,
  POPUP_ALERT_SHOW,
  SET_NOTIFICATIONS,
  TOGGLE_NAV_MENU_EXTENDED,
  TOGGLE_RIGHT_PANEL_EXTENDED,
} from '../constants'

const defaultState = {
  isNavMenuExtended: false,
  isRightPanelExtended: JSON.parse(localStorage.getItem('rightPanelState')) || false,
  notifications: [],
  popupAlerts: [],
}

const buildPopupAlerts = (popupAlerts, data) => {
  let updatedPopupAlerts = popupAlerts
  const { id, type, content, timeout = 3000, stacked, contentKey } = data

  if (stacked) {
    const similarItemIndex = popupAlerts.findIndex(
      (alert) => type === alert.type && content === alert.content
    )

    if (similarItemIndex !== -1) {
      updatedPopupAlerts.splice(similarItemIndex, 1)
    }
  }

  const newAlert = {
    timeout,
    id: id || Date.now(),
    type,
    content,
    contentKey,
  }

  return [...updatedPopupAlerts, newAlert]
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case TOGGLE_NAV_MENU_EXTENDED: {
      const isNavMenuExtended =
        typeof action.extendedValue === 'undefined' ? !state.isNavMenuExtended : action.extendedValue
      localStorage.setItem('menuState', JSON.stringify({ isExtended: isNavMenuExtended }))

      return {
        ...state,
        isNavMenuExtended,
      }
    }
    case TOGGLE_RIGHT_PANEL_EXTENDED: {
      const isRightPanelExtended =
        typeof action.extendedValue === 'undefined' ? !state.isRightPanelExtended : action.extendedValue

      localStorage.setItem('rightPanelState', JSON.stringify(isRightPanelExtended))

      return {
        ...state,
        isRightPanelExtended,
      }
    }
    case POPUP_ALERT_SHOW:
      return { ...state, popupAlerts: buildPopupAlerts(state.popupAlerts, action.data) }
    case POPUP_ALERT_HIDE:
      return {
        ...state,
        popupAlerts: state.popupAlerts.filter((alert) => alert.id !== action.id),
      }
    case POPUP_ALERT_HIDE_ALL:
      return { ...state, popupAlerts: [] }
    case SET_NOTIFICATIONS:
      return {
        ...state,
        notifications: [{ ...action.notification, isNew: true }, ...state.notifications],
      }
    case MARK_NOTIFICATION_AS_OLD:
      return {
        ...state,
        notifications: state.notifications.map((notification) => {
          if (notification.entity.id === action.id) {
            return { ...notification, isNew: false }
          } else {
            return notification
          }
        }),
      }
    case CLEAR_NOTIFICATIONS:
      return { ...state, notifications: [] }
    case SIGN_OUT_SUCCESS:
      return defaultState
    default:
      return state
  }
}
