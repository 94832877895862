import React from 'react'
import { object } from 'dot-object'
import { isObject } from '@aidsupply/components'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import EditForm from '../../../components/RightPanelForms'
import { sidebarUpsert, sidebarPostComment } from '../../../redux/actions'
import { selectSidebarInitialData } from '../../../redux/selectors'
import { useMappedState } from '../../../hooks'

const InvoicesRightPanel = () => {
  const dispatch = useDispatch()
  // const { rightPanelId: editedItemId } = useParams()
  const navigate = useNavigate()

  const sidebarInitialData = useMappedState(selectSidebarInitialData)

  const onFormSubmit = (formValuesChanged) => {
    const requestBody = { ...object(formValuesChanged) }

    Object.keys(requestBody).map((key) => {
      if (isObject(requestBody[key]) && requestBody[key].id) {
        requestBody[key] = requestBody[key].id
      }
    })

    requestBody.total =
      formValuesChanged.subtotal ||
      sidebarInitialData.subtotal + (formValuesChanged.tax || sidebarInitialData.tax || 0)

    const dataToSend = {
      id: sidebarInitialData.id,
      requestBody,
      type: 'invoices',
      parentType: 'documents',
    }

    dispatch(sidebarUpsert(dataToSend, navigate))
  }

  return <EditForm onFormSubmit={onFormSubmit} />
}

export default InvoicesRightPanel
