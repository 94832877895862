import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { PropTypes as T } from 'prop-types'

import { ScreenContext, Typography } from '@aidsupply/components'

import HeaderIntroScreen from './components/HeaderIntroScreen'
import { StyledFooterText, StyledWindow, StyledWrapper, StyledIntroWrapper } from './styled'
import PopupAlertsComponent from '../../wrappers/PopupAlertsComponent'

const IntroScreenWrapper = ({ children, text, type, theme, imageUrl }) => {
  const { t } = useTranslation('signIn')

  const { width, sm, lg } = useContext(ScreenContext) || {}
  const isMobile = width && width < sm
  const isTablet = width && width < lg

  return (
    <StyledWrapper type={type} className={`${type}Wrapper`}>
      <div className="mainWrapper">
        <PopupAlertsComponent />

        <HeaderIntroScreen theme={theme} />

        <StyledWindow className={type} type={type}>
          <div className="contentWrapper">
            <Typography
              type="h2"
              className="text"
              textAlign="left"
              text={t(text || type)}
              margin="0 0 20px"
            />
            {children}
          </div>
        </StyledWindow>
        <StyledFooterText>
          <Typography type="body2" className="footerText">
            AidSupply {new Date().getFullYear()} © {t('footer:allRightsReserved')}
          </Typography>
        </StyledFooterText>
      </div>
      {!isTablet && (
        <StyledIntroWrapper
          className="introWrapper"
          url={imageUrl}
          color={theme.color.general.light}
        ></StyledIntroWrapper>
      )}
    </StyledWrapper>
  )
}

export default IntroScreenWrapper

IntroScreenWrapper.propTypes = {
  children: T.node,
  text: T.string,
  type: T.string,
  theme: T.object,
  imageUrl: T.string,
}
