import React from 'react'

import { useTranslation } from 'react-i18next'
import { InfoRowsBlock, Userpic, Typography, Tag } from '@aidsupply/components'
import { withTheme } from 'styled-components'
import ReactTexty from 'react-texty'

const UsersTableCard = ({ className, data, initialData, theme }) => {
  const { full_name, status, organization_name, role, created_at, last_logged_at, checkbox } = data

  return (
    <InfoRowsBlock
      className={className}
      data={[
        {
          avatar: {
            customElement: (
              <Userpic
                height="28px"
                width="28px"
                fullName={full_name}
                backgroundColor={theme.color.general.gray4}
                color={theme.color.general.light}
              />
            ),
            hideBorder: true,
            padding: '0 10px',
            width: '36px',
          },
          full_name: {
            customElement: (
              <Typography as={ReactTexty} type="h4">
                {full_name}
              </Typography>
            ),
            width: 'calc(50% - 36px)',
            hideBorder: true,
            padding: '9px 10px',
          },
          status: {
            customElement: status,
            width: 'calc(50% - 36px)',
            hideBorder: true,
            padding: '0 15px 0 0',
            rowItemFlexProps: {
              justifyContent: 'end',
            },
          },
          checkbox: {
            customElement: checkbox,
            width: 'calc(36px - 15px)',
            hideBorder: true,
            padding: '10px 0',
          },
        },
        {
          organization_name: {
            iconProps: {
              name: 'warehouse',
            },
            text: organization_name,
            width: '50%',
            hideBorder: true,
          },
          role: {
            customElement: <Tag text={role} />,
            width: '50%',
            hideBorder: true,
            padding: '10px',
            rowItemFlexProps: {
              justifyContent: 'end',
            },
          },
        },
        {
          created_at: {
            iconProps: {
              name: 'at',
            },
            text: created_at,
            width: '50%',
            hideBorder: !last_logged_at,
            padding: '10px',
          },
          last_logged_at: {
            iconProps: {
              name: 'at',
            },
            text: last_logged_at,
            width: '50%',
            padding: '10px',
          },
        },
      ]}
    />
  )
}

export default withTheme(UsersTableCard)
