import { parse } from 'query-string'

import { SEARCH_FIELDS } from '../apiConstants'
import { getColumns } from '../config/columns'
import { FILTERS_CONFIG } from '../config/filters'
import { isObjectEmpty } from '@aidsupply/components'

const SAME_GROUP_FILTER_SEPARATOR = '|'
const FILTER_GROUP_SEPARATOR = ','

export const getSearchValueApiQuery = (searchValue, type, lng) => {
  const tableSearchField = SEARCH_FIELDS[type]

  return [`searchValue=${searchValue}`, `searchFields=${tableSearchField || 'translations'}`]
}

export const getSortParams = (sortQuery, type, lng) => {
  const sortArr = sortQuery.split('_')
  const sortOrder = sortArr[1] || 'asc'
  const apiSortValue = getColumns(type, lng).find((col) => col.key === sortArr[0])?.dataKey

  return {
    value: { key: sortArr[0], order: sortOrder },
    apiSortString: `sort=${apiSortValue}_${sortOrder}`,
  }
}

export const mapParamsToUrl = (filters, facets, type, search, lng) => {
  let apiQueryPairs // array of strings
  const urlQueryPairs = [] // array of strings
  const urlFilterPairs = [] // array of strings
  let apiQuery = ''
  let urlQuery = ''
  const searchParsed = parse(search)

  if (searchParsed?.searchValue) {
    urlQueryPairs.push(`searchValue=${searchParsed?.searchValue}`)
    apiQueryPairs = getSearchValueApiQuery(searchParsed?.searchValue, type, lng)
  } else {
    apiQueryPairs = []
  }

  if (searchParsed?.sort) {
    urlQueryPairs.push(`sort=${searchParsed?.sort}`)
    const { apiSortString } = getSortParams(searchParsed?.sort, type, lng)
    apiQueryPairs.push(apiSortString)
  }

  // filters
  if (!isObjectEmpty(filters)) {
    Object.keys(filters).forEach((key) => {
      const value = filters[key].join(FILTER_GROUP_SEPARATOR)
      const valueBySlug = filters[key].map((filter) => {
        const facetValue = facets[key] && facets[key].find((facet) => facet.id === filter)
        return facetValue?.slug || facetValue?.id || value
      })

      if (value.length > 0) {
        apiQueryPairs.push(`${FILTERS_CONFIG[key]?.apiKey}=${value}`)
        urlFilterPairs.push(`${key}=${valueBySlug.join(SAME_GROUP_FILTER_SEPARATOR)}`)
      }
    })
  }

  if (apiQueryPairs.length > 0) {
    const hasActiveFilters = urlFilterPairs.length
    if (hasActiveFilters) {
      urlQueryPairs.push(urlFilterPairs.join(FILTER_GROUP_SEPARATOR))
    }

    apiQuery = apiQueryPairs.map((pair) => {
      const pairSplit = pair.split('=')
      return { key: pairSplit[0], value: pairSplit[1] }
    })
    urlQuery = `${urlQueryPairs.join('&')}`
  }

  return { apiQuery: apiQuery || [], urlQuery }
}

export const mapUrlToParams = (lng, search, type, systemData) => {
  const filters = {}
  let apiQueryPairs
  let apiQuery = ''
  let searchValue
  let sortValue
  const searchParsed = parse(search)

  const firstFilterUrlKey =
    searchParsed &&
    Object.keys(searchParsed).filter((key) => key !== 'sort' && key !== 'searchValue' && key !== 'refresh')[0]
  const firstFilterConfigObj = FILTERS_CONFIG[firstFilterUrlKey]

  // search
  if (searchParsed?.searchValue) {
    searchValue = searchParsed.searchValue
    apiQueryPairs = getSearchValueApiQuery(searchParsed.searchValue, type, lng)
  } else {
    apiQueryPairs = []
  }

  // sorting
  if (searchParsed?.sort) {
    const { value, apiSortString } = getSortParams(searchParsed.sort, type, lng)
    sortValue = value
    apiQueryPairs.push(apiSortString)
  }

  // filters
  if (search && firstFilterConfigObj) {
    const urlFilters = `${firstFilterUrlKey}=${searchParsed[firstFilterUrlKey]}`
    const urlQueryPairs = urlFilters.split(FILTER_GROUP_SEPARATOR)
    urlQueryPairs.forEach((pair) => {
      const [key, slugValue] = pair.split('=')

      const systemDataKey =
        FILTERS_CONFIG[key]?.systemDataKeyMapping?.[type] ||
        FILTERS_CONFIG[key]?.key ||
        // ITEM_TO_API_KEYS[key] ||
        key

      const idValueArray = slugValue
        .split(SAME_GROUP_FILTER_SEPARATOR)
        .map(
          (slug) =>
            Object.values(systemData[systemDataKey] || FILTERS_CONFIG[key].options || []).find(
              (systemItem) =>
                systemItem?.slug === slug ||
                systemItem?.key === slug ||
                systemItem?.id === slug ||
                systemItem?.id === +slug
            )?.id || slug
        )
        .filter((id) => id)

      filters[key] = idValueArray

      apiQueryPairs.push(
        `${FILTERS_CONFIG[key]?.apiKey || key}=${
          idValueArray?.length ? idValueArray.join(SAME_GROUP_FILTER_SEPARATOR) : slugValue
        }`
      )
    })
  }

  if (apiQueryPairs.length > 0) {
    apiQuery = apiQueryPairs.map((pair) => {
      const pairSplit = pair.split('=')
      return { key: pairSplit[0], value: pairSplit[1] }
    })
  }

  return { filters, apiQuery: apiQuery || [], searchValue, sortValue }
}
