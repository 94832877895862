import React from 'react'
import ReactTexty from 'react-texty'

import {
  CategoryCharacteristicsChosen,
  Image,
  getCurrencyObjectByCode,
  getPriceWithCurrency,
  isObjectEmpty,
  getAvailableTranslation,
  Typography,
  Label,
  FlexRow,
} from '@aidsupply/components'

import EnumerationOptionNameWithColor from '../components/RightPanelForms/components/EnumerationOptionNameWithColor'
import Characteristics from '../components/Stocks/Characteristics'
import { UNITS_PIECES_ID } from '../data'
import { getSelectCellValue, headerRenderer } from '../utils/table'
import { IMAGEKIT_URL } from '../constants'
import { DEFAULT_LANGUAGE } from '../locales'

const renderPriceWithCurrency = ({
  rowData,
  cellData,
  container: {
    props: { cellProps },
  },
}) => {
  const currencyCode = rowData.currency || cellProps.additional_form_values?.currency || ''
  const currency = getCurrencyObjectByCode(currencyCode, cellProps.system?.currencies)

  return <ReactTexty>{currency ? getPriceWithCurrency(cellData, currency) : cellData}</ReactTexty>
}

const getPriceFromAmount = (t) => ({
  key: 'price',
  dataKey: 'price',
  type: 'number',
  width: 0,
  flexGrow: 1,
  // isEditable: true,
  cellRenderer: ({ cellData, rowData, container }) => {
    const {
      props: {
        cellProps: {
          system: { currencies },
        },
      },
    } = container

    const price = cellData / rowData.quantity
    const currency = getCurrencyObjectByCode(rowData.currency, currencies)

    return currency ? getPriceWithCurrency(price, currency) : price
  },
  headerRenderer: headerRenderer(t, 'table'),
})

const getBrandNameImage = (brandName) => {
  if (!brandName) {
    return null
  }

  const twoNameLetters = brandName
    .split(' ')
    .map((word) => word[0])
    .join('')
    .slice(0, 2)

  return <div className="brandNameImage">{twoNameLetters}</div>
}

const getBrand = (t, lng) => {
  return {
    key: 'brand',
    // dataKey: 'modification_oid',
    dataKey: 'brand_id',
    width: 50,
    noTranslation: true,
    headerRenderer: '',
    cellRenderer: ({ cellData, container, returnTextOnly }) => {
      // const modification = container.props.cellProps.system.modifications?.[cellData]
      // const brandObject = modification && container.props.cellProps.system.brands?.[modification.brandId]
      const brandObject = container.props.cellProps.system.brands?.[cellData]
      const brandName = brandObject?.name

      const brandNameImage = getBrandNameImage(brandName)
      const brandLogo = brandObject?.photo_url

      if (returnTextOnly) {
        return brandName
      }

      return cellData ? (
        <ReactTexty data-tooltip={brandName}>
          {brandLogo ? <Image src={brandLogo} imagekitUrl={IMAGEKIT_URL} /> : brandNameImage}
        </ReactTexty>
      ) : (
        ''
      )
    },
  }
}

export const getItemTitle = (t, lng) => {
  return {
    key: 'item',
    dataKey: 'item_id',
    width: 0,
    flexGrow: 2,
    cellRenderer: ({ cellData, container, rowData }) => {
      const {
        props: {
          cellProps: { system },
        },
      } = container

      const title =
        getAvailableTranslation(system.items?.[cellData]?.translations, DEFAULT_LANGUAGE, lng) ||
        rowData.item_name ||
        ''
      const model = system.items?.[cellData]?.sku

      const brandObject = rowData.brand_id && container.props.cellProps.system.brands?.[rowData.brand_id]
      const brandName = brandObject?.name || ''

      return (
        <ReactTexty tooltipClassName="invisible">
          <ReactTexty>{title ? `${title} (${model})` : model}</ReactTexty>
          <Label type="smaller" text={rowData.brand_name || brandName} />
        </ReactTexty>
      )
    },
    headerRenderer: headerRenderer(t, 'table'),
  }
}

const getCharacteristics = () => ({
  key: 'characteristics',
  dataKey: 'modification_id',
  width: 0,
  flexGrow: 1,
  cellRenderer: ({
    cellData,
    container: {
      props: {
        cellProps: {
          lng,
          system: { modifications, attributes, enumerations },
        },
      },
    },
  }) => {
    const mod = modifications[cellData]
    return mod ? (
      <Characteristics
        modKey={mod?.key}
        modValue={mod?.value}
        lng={lng}
        modifications={modifications}
        attributes={attributes}
        enumerations={enumerations}
      />
    ) : (
      ''
    )
  },
  headerRenderer: () => '',
})

export const getEditableTableColumns = (type, lng, t) => {
  const EDITABLE_TABLE_COLUMNS_CONFIG = {
    'enumerations.options': [
      {
        key: 'systemName',
        dataKey: 'name',
        width: 0,
        flexGrow: 1,
        cellRenderer: (props) => <EnumerationOptionNameWithColor {...props} />,
        headerRenderer: headerRenderer(t),
      },
      {
        key: 'translations',
        title: 'name',
        dataKey: `translations.${lng}`,
        width: 0,
        flexGrow: 1.2,
        isEditable: true,
        headerRenderer: headerRenderer(t),
      },
    ],
    // 'items.content': [
    //   {
    //     key: 'name',
    //     dataKey: `translations.label.${lng}`,
    //     width: 0,
    //     flexGrow: 1,
    //     cellRenderer: (tableProps) =>
    //       getBackendValueFromEnumerations({
    //         blockValuesKey: 'id',
    //         valueToGetKey: `translations.${lng}`,
    //         ...tableProps,
    //       }),
    //     headerRenderer: headerRenderer(t),
    //   },
    //   {
    //     key: 'amount',
    //     dataKey: 'amount',
    //     width: 100,
    //     flexShrink: 0,
    //     resizable: false,
    //     cellRenderer: ({ cellData, rowData, container }) => {
    //       const {
    //         props: {
    //           cellProps: {
    //             system: { units },
    //           },
    //         },
    //       } = container
    //
    //       return cellData
    //         ? `${cellData}${
    //             rowData.unit
    //               ? rowData.unit?.label?.[lng] || units?.[rowData.unit?.id]?.code
    //               : CONTENT_UNITS_OPTIONS[0].label[lng]
    //           }`
    //         : ''
    //     },
    //     headerRenderer: headerRenderer(t),
    //   },
    // ],
    'orders.order_items': [
      {
        key: 'index',
        cellRenderer: ({ rowIndex }) => rowIndex + 1,
      },
      // getBrand(t, lng),
      getItemTitle(t, lng),
      // getCharacteristics(),
      // getPriceFromAmount(t),
      {
        key: 'quantity',
        dataKey: 'quantity',
        isEditable: true,
        type: 'number',
        width: 80,
        headerRenderer: headerRenderer(t, 'table'),
      },
      {
        key: 'price',
        dataKey: 'price',
        isEditable: true,
        type: 'number',
        width: 85,
        headerRenderer: headerRenderer(t, 'table'),
      },
      // {
      //   key: 'total',
      //   dataKey: 'total',
      //   width: 0,
      //   flexGrow: 1.1,
      //   sortable: true,
      //   headerRenderer: headerRenderer(t, 'table'),
      //   cellRenderer: renderPriceWithCurrency,
      // },
    ],
    'invoices.order_items': [
      {
        key: 'index',
        cellRenderer: ({ rowIndex }) => rowIndex + 1,
      },
      getBrand(t, lng),
      {
        ...getItemTitle(t, lng),
        flexGrow: 0.7,
        headerRenderer: headerRenderer(t, 'table'),
      },
      // getCharacteristics(),
      // getPriceFromAmount(t),
      {
        key: 'quantity',
        dataKey: 'quantity',
        // isEditable: true,
        type: 'number',
        width: 85,
        headerRenderer: headerRenderer(t, 'table'),
      },
    ],
    'shipments.shipment_items': [
      {
        key: 'index',
        cellRenderer: ({ rowIndex }) => rowIndex + 1,
      },
      // getBrand(t, lng),
      getItemTitle(t, lng),
      // getCharacteristics(),
      {
        key: 'quantity',
        dataKey: 'quantity',
        isEditable: true,
        type: 'number',
        width: 80,
        headerRenderer: headerRenderer(t, 'table'),
      },
    ],
    'inquiries.inquiry_items': [
      {
        key: 'index',
        cellRenderer: ({ rowIndex }) => rowIndex + 1,
      },
      {
        key: 'category',
        // sortable: true,
        dataKey: 'category_id',
        width: 0,
        flexGrow: 2,
        cellRenderer: ({ cellData, container, rowData }) => {
          const {
            props: {
              cellProps: { system, lng },
            },
          } = container
          const category = system.categories?.[cellData]
          const unitId = category?.unit_id
          const unit = system.units?.[unitId] || system.units?.[UNITS_PIECES_ID]

          const characteristics = rowData.characteristics_keys?.length
            ? rowData.characteristics_keys?.reduce((acc, key, i) => {
                acc[key] = rowData.characteristics_values[i]
                return acc
              }, {})
            : rowData.characteristics

          return (
            <ReactTexty>
              <div style={{ display: 'flex' }}>
                <ReactTexty>
                  {getAvailableTranslation(category?.translations, DEFAULT_LANGUAGE, lng)}
                  {system.units &&
                    unit &&
                    `, ${getAvailableTranslation(unit.translations, DEFAULT_LANGUAGE, lng) || unit.code}`}
                </ReactTexty>
              </div>{' '}
              {!isObjectEmpty(characteristics) && (
                <CategoryCharacteristicsChosen
                  characteristicsChosen={characteristics}
                  system={system}
                  language={lng}
                  t={t}
                  unitsPiecesId={UNITS_PIECES_ID}
                />
              )}
            </ReactTexty>
          )
        },
        headerRenderer: headerRenderer(t, 'table'),
      },
      {
        key: 'quantity',
        dataKey: 'qty_new',
        isEditable: true,
        type: 'number',
        width: 80,
        headerRenderer: headerRenderer(t, 'table'),
      },
    ],
    'items.modifications': [
      {
        key: 'item',
        dataKey: 'itemId',
        width: 0,
        flexGrow: 1,
        labelKey: 'general.model',
        cellRenderer: getSelectCellValue,
        headerRenderer: headerRenderer(t, 'table'),
      },
      {
        key: 'brand',
        optionsKeys: ['brands'],
        title: 'brand',
        dataKey: 'brandId',
        width: 0,
        flexGrow: 1,
        labelKey: 'general.name',
        cellRenderer: getSelectCellValue,
        headerRenderer: headerRenderer(t, 'table'),
      },
      {
        key: 'characteristics',
        dataKey: 'id',
        width: 0,
        flexGrow: 0.7,
        cellRenderer: ({
          cellData,
          container: {
            props: {
              cellProps: {
                lng,
                system: { modifications, attributes, enumerations },
              },
            },
          },
        }) => {
          const mod = modifications[cellData]
          return cellData ? (
            <Characteristics
              modKey={mod.key}
              modValue={mod.value}
              lng={lng}
              modifications={modifications}
              attributes={attributes}
              enumerations={enumerations}
            />
          ) : (
            ''
          )
        },
        headerRenderer: headerRenderer(t, 'table'),
      },
      {
        key: 'code', // артикул
        dataKey: 'code',
        width: 0,
        flexGrow: 2,
        isEditable: true,
        headerRenderer: headerRenderer(t, 'table'),
      },
    ],
    topics: [
      {
        key: 'name',
        dataKey: 'name',
        width: 0,
        flexGrow: 1,
        headerRenderer: headerRenderer(t),
      },
      {
        key: 'translations',
        title: 'name',
        dataKey: `translations.${lng}`,
        width: 0,
        flexGrow: 1.2,
        isEditable: true,
        headerRenderer: headerRenderer(t),
      },
    ],
    'inventories.inventory_items': [
      {
        key: 'index',
        cellRenderer: ({ rowIndex }) => rowIndex + 1,
      },
      getItemTitle(t, lng),
      {
        key: 'quantity',
        dataKey: 'quantity',
        isEditable: true,
        type: 'number',
        width: 80,
        headerRenderer: headerRenderer(t, 'table'),
      },
      {
        key: 'price',
        dataKey: 'price',
        isEditable: true,
        type: 'number',
        width: 85,
        headerRenderer: headerRenderer(t, 'table'),
      },
    ],
  }

  return EDITABLE_TABLE_COLUMNS_CONFIG[type]
}
