import { pick } from 'dot-object'
import { jsPDF } from 'jspdf'
import 'jspdf-autotable'
import { parse } from 'query-string'
import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { createSearchParams, useLocation, useNavigate, useParams } from 'react-router-dom'
import { withTheme } from 'styled-components'

import { Button, Pagination, ScreenContext, Tag } from '@aidsupply/components'

import clsx from 'clsx'
import { DISABLED_ADD_MAIN_TABLE_TYPES, FRONTEND_TABLE_ACTIONS } from '../../config/table'
import { useMappedState } from '../../hooks'
import {
  dataFetchWithFacets,
  resetFormValuesChanged,
  sidebarFetchById,
  toggleFiltersPanel,
  upsert,
} from '../../redux/actions'
import {
  selectBasketItemsCount,
  selectDataInProgressAndError,
  selectFiltersState,
  selectFormByType,
} from '../../redux/selectors'
import OrganizationSelect from '../Stocks/OrganizationSelect'
import TableSearch from './Search'
import { StyledNavBar } from './styled'
import { CRM_DATA_TYPES_WITH_NO_FACETS } from '../../apiConstants'

const NavBar = ({
  addBtnDisabled,
  cellProps,
  columns,
  data,
  gridColCount,
  isDropdownSearch,
  isMainTable,
  isPaginationDisabled,
  itemsTotalCount,
  onRowClick,
  pageLimit,
  prevSearchValue,
  resetSelectedRows,
  rowsCount,
  selectable,
  selectedRows,
  sortString,
  tableMode,
  theme,
  typeData,
  navBarChildren,
}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { i18n, t } = useTranslation(['table', 'menu', 'productsForm'])
  const { currentBreakpoint } = useContext(ScreenContext) || {}
  const location = useLocation()
  const { search } = location
  const searchParsed = parse(search)
  const currentPage = parseInt(searchParsed.page) || 1

  const { bulkUpdateInProgress, inProgress, error } = useMappedState(selectDataInProgressAndError)
  const modifications = useMappedState(selectFormByType('modifications'))
  const { active, facets } = useMappedState(selectFiltersState)
  const itemsCount = useMappedState(selectBasketItemsCount)

  const role = cellProps?.user?.role

  const { rightPanelId } = useParams()
  const type = typeData.key
  const isProductsOrDemands = type === 'products' || type === 'inquiry_items'

  const reloadData = () => {
    if (isMainTable) {
      dispatch(
        dataFetchWithFacets({
          type,
          locationSearch: search,
          lng: i18n.language,
          pageLimit,
          pageOffset:
            !prevSearchValue && searchParsed.searchValue
              ? undefined
              : searchParsed.page && (searchParsed.page - 1) * pageLimit,
          sortString,
        })
      )
    }

    if (rightPanelId) {
      dispatch(sidebarFetchById(rightPanelId, type, true))
    }
  }

  useEffect(() => {
    if (!bulkUpdateInProgress && !error) {
      resetSelectedRows()
    }
  }, [bulkUpdateInProgress, error, resetSelectedRows])

  useEffect(() => {
    resetSelectedRows()
  }, [type, resetSelectedRows])

  const saveModifications = (e) => {
    e.preventDefault()
    const dataToSend = {
      requestBody: modifications.valuesChanged,
      type: 'modifications',
      operation: 'update',
    }
    dispatch(upsert(dataToSend))
    dispatch(resetFormValuesChanged('modifications'))
  }

  const onDownloadClick = () => {
    const unit = 'pt'
    const size = 'A4' // Use A1, A2, A3 or A4
    const orientation = columns.length > 3 ? 'landscape' : 'portrait'

    const marginLeft = 40
    const doc = new jsPDF(orientation, unit, size)

    doc.setFontSize(15)

    const title = t(`menu:${type}`)
    const headers = [columns.map((col) => t(col.key) || col.title || '')]

    const pdfData = data.map((item, i) =>
      columns.map((col) => {
        const cell =
          col.cellRenderer &&
          col.cellRenderer({
            cellData: pick(col.dataKey, item),
            column: col,
            container: { props: { cellProps } },
            returnTextOnly: true,
            rowData: data[i],
          })
        // console.log(cell)
        return (
          (typeof cell === 'string' || typeof cell === 'number' ? cell : cell?.props?.children || '') ||
          pick(col.dataKey, item)
        )
      })
    )

    let content = {
      startY: 50,
      head: headers,
      body: pdfData,
    }

    doc.text(title, marginLeft, 40)
    doc.autoTable(content)
    doc.save(`${title}.pdf`)
  }

  const onPageChange = (page) => {
    if (page === 1) {
      if (searchParsed.page) {
        delete searchParsed.page
      }
      navigate({
        search: createSearchParams(searchParsed).toString(),
      })
    } else {
      searchParsed.page = page
      navigate({
        search: createSearchParams(searchParsed).toString(),
      })
    }
  }

  return (
    <StyledNavBar
      currentBreakpoint={currentBreakpoint}
      className={isDropdownSearch ? 'withDropdownSearch ' + tableMode : tableMode}
    >
      {isMainTable &&
        !DISABLED_ADD_MAIN_TABLE_TYPES[type] &&
        (isProductsOrDemands || !cellProps.is_read_only) && (
          <Button
            className={clsx('navBar', isProductsOrDemands ? 'cart' : 'add')}
            disabled={addBtnDisabled}
            onClick={() => onRowClick({ rowKey: 'new' })}
            size="small"
            variant="primary"
            uppercase={false}
            withIcon={type !== 'brands'}
            iconLeftProps={{
              name: isProductsOrDemands ? 'shoppingCart2' : 'plus2',
              stroke: theme.color.general.light,
              height: 20,
              width: 20,
              strokeWidth: 1.5,
              wrapperWidth: isProductsOrDemands ? 20 : 32,
              wrapperHeight: isProductsOrDemands ? 20 : 32,
            }}
          >
            {isProductsOrDemands && (
              <>
                {t('productsForm:cart')}
                <Tag
                  className="itemsTag"
                  backgroundColor={theme.color.general.light}
                  color={theme.color.general.dark}
                  fontSize={12}
                  fontWeight={600}
                >
                  {itemsCount > 0 ? itemsCount : '0'}
                </Tag>
              </>
            )}
          </Button>
        )}
      {type === 'items' && isMainTable && (
        <Button
          iconLeftProps={{ name: 'download', stroke: theme.color.general.gray5 }}
          size="small"
          variant="bordered"
          onClick={onDownloadClick}
          className="download"
        />
      )}
      {navBarChildren}

      {isMainTable && !CRM_DATA_TYPES_WITH_NO_FACETS.includes(type) && (
        <Button
          onClick={() => dispatch(toggleFiltersPanel())}
          variant="bordered"
          iconLeftProps={{ name: 'hamburgerMenu3', stroke: theme.color.general.gray5, height: 20 }}
          size="small"
          className="navBar filters"
          text={t('filters')}
          uppercase={false}
        />
      )}

      {/*{isMainTable && selectable && !isProductsOrDemands && !cellProps.is_read_only && (*/}
      {/*  <NavbarActions*/}
      {/*    bulkUpdateInProgress={bulkUpdateInProgress}*/}
      {/*    dispatch={dispatch}*/}
      {/*    language={i18n.language}*/}
      {/*    resetSelectedRows={resetSelectedRows}*/}
      {/*    selectedRows={selectedRows}*/}
      {/*    t={t}*/}
      {/*    typeData={typeData}*/}
      {/*  />*/}
      {/*)}*/}

      {type === 'stock-items' && ['administrator', 'system'].includes(role) && <OrganizationSelect t={t} />}

      {isMainTable && FRONTEND_TABLE_ACTIONS[type]?.search !== false && (
        <TableSearch
          isMainTable={isMainTable}
          type={type}
          activeFilters={active}
          facets={facets}
          isDropdownSearch={isDropdownSearch}
          iconRightProps={{ name: 'cross', strokeWidth: 1.5 }}
        />
      )}

      {!isPaginationDisabled && (
        <Pagination
          dashText={t('of')}
          currentPage={currentPage}
          itemsCount={itemsTotalCount}
          itemsPerRowCount={gridColCount}
          onPageChange={onPageChange}
          rowsCount={rowsCount}
        />
      )}

      {/*{isMainTable && !isProductsOrDemands && (*/}
      {/*  <div className="downloadActions">*/}
      {/*    <Icon name="print" className="navRightIcon" />*/}
      {/*    <Icon name="download" className="navRightIcon" onClick={onDownloadClick} />*/}
      {/*  </div>*/}
      {/*)}*/}

      {type === 'items.modifications' && (
        <Button
          variant="bordered"
          onClick={saveModifications}
          disabled={inProgress}
          className="navBar saveMods"
        >
          {t('saveModifications')}
        </Button>
      )}
    </StyledNavBar>
  )
}

export default withTheme(NavBar)
