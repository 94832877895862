import React from 'react'
import { useTranslation } from 'react-i18next'

import { Outlet, useLocation } from 'react-router-dom'
import TableBlock from '../../../components/Table/TableBlock'
import GridLayout from '../../Layout/GridLayout'
import CampaignsTableCard from './CampaignsTableCard'

const Campaigns = () => {
  const { t } = useTranslation('forms')

  const { pathname } = useLocation()

  return (
    <GridLayout RightPanelComponent={<Outlet context={{}} />}>
      <TableBlock
        // hideFilterPanel={hideFilterPanel}
        isMainTable
        // isReadOnly={isReadOnly}
        // rightPanelIsOpened={rightPanelIsOpened}
        // gap={gap}
        // leftPanelIsOpened={leftPanelIsOpened}
        // setLeftPanelOpened={setLeftPanelOpened}
        TableCardContent={CampaignsTableCard}
        tableCardHeight={118}
      />
    </GridLayout>
  )
}

export default Campaigns
