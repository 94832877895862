import * as dot from 'dot-object'
import React from 'react'
import ReactTexty from 'react-texty'

import {
  IMAGEKIT_PARAMS_CONFIG,
  ITEM_TO_API_KEYS,
  Tag,
  ThumbWithText,
  Typography,
  buildImagePath,
} from '@aidsupply/components'

import { getSelectLabel } from './index'

export const searchTableOnFrontend = () => {}

export const sortObjectToUrlString = (sortObject) => {
  return (
    sortObject &&
    Object.entries(sortObject)
      .filter(([, value]) => value)
      .map(([key, value]) => `${key}-${value}`)
      .join(',')
  )
}

export const sortTableData = (data, key, order, column, cellProps) => {
  return data.sort((a, b) => {
    const valueA = dot.pick(key, a)
    const valueB = dot.pick(key, b)

    if (typeof valueA === 'number') {
      return order === 'asc' ? valueA - valueB : valueB - valueA
    }

    const getKeyText = (element) => {
      const keyText = column.cellRenderer({
        cellData: dot.pick(key, element),
        column,
        container: { props: { cellProps } },
        returnTextOnly: true,
      })

      return typeof keyText === 'string' ? keyText.toUpperCase() : ''
    }

    const keyA = column && column.cellRenderer ? getKeyText(a) : valueA
    const keyB = column && column.cellRenderer ? getKeyText(b) : valueB

    if (!keyA || !keyB) {
      return false
    }

    return order === 'asc' ? keyA.localeCompare(keyB) : keyB.localeCompare(keyA)
  })
}

export const headerRenderer =
  (t, tableMode) =>
  ({ columns, column, columnIndex, headerIndex, container }) => {
    return tableMode === 'list' ? (
      <ReactTexty>{t(column.title || column.key)}</ReactTexty>
    ) : (
      <Typography as={ReactTexty} type="body2">
        {t(column.key) || t(column.title)}
      </Typography>
    )
  }

/**
 * Renders value for the table/card
 * @param {object} { value, column, container }
 */
export const getSelectCellValue = ({ rowData, cellData: value, column, container, returnTextOnly }) => {
  const {
    props: {
      cellProps: { system, lng },
    },
  } = container
  const { key, optionsKeys, labelKey, valueKey, isMulti, noTranslation } = column

  const optionsCollection = optionsKeys?.reduce(
    (acc, curr) => (system[curr] ? { ...acc, ...system[curr] } : {}),
    {}
  )

  const collection =
    (optionsCollection && Object.keys(optionsCollection).length && optionsCollection) ||
    system[key] ||
    system[ITEM_TO_API_KEYS[key]]

  if (!collection || !value) {
    return
  }

  if ((isMulti && Array.isArray(value)) || Array.isArray(value)) {
    return (
      <ReactTexty key={column.key}>
        {value?.map((val) => (
          <Tag key={val.id || val} colorKey="gray2">
            {getSelectLabel(collection, val, lng, noTranslation, labelKey, valueKey)}
          </Tag>
        ))}
      </ReactTexty>
    )
  } else {
    return returnTextOnly ? (
      getSelectLabel(collection, value, lng, noTranslation, labelKey, valueKey)
    ) : (
      <ReactTexty key={column.key}>
        {getSelectLabel(collection, value, lng, noTranslation, labelKey, valueKey)}
      </ReactTexty>
    )
  }
}

export const getTableValueFromRowId =
  (systemKey) =>
  ({ cellData, rowData, column, container, returnTextOnly }) => {
    // console.log(systemKey, cellData, rowData)
    if (cellData?.id) {
      return getSelectCellValue({ cellData, column, container, returnTextOnly })
    } else if (typeof cellData !== 'undefined') {
      return cellData
    }

    const {
      props: {
        cellProps: { system },
      },
    } = container
    const { id } = rowData
    const { dataKey } = column
    const collection = system[systemKey] || system[ITEM_TO_API_KEYS[systemKey]]
    const value = collection?.[id] ? dot.pick(dataKey, collection[id]) : null

    return value?.id ? getSelectCellValue({ cellData: value, column, container, returnTextOnly }) : value
  }

export const getThumbWithTextCell =
  (type, srcKey, lng, text) =>
  ({ rowData, column, container }) => {
    const {
      props: {
        cellProps: { system },
      },
    } = container
    const { id } = rowData
    const { dataKey } = column
    const collection = system[type] || system[ITEM_TO_API_KEYS[type]]

    const categoryBanner =
      type === 'categories' &&
      Object.values(system.banners).find((banner) => banner.general.category?.id === rowData.id)

    const isModel = column.dataKey === 'general.model'

    const cellData = text || (collection?.[id] ? dot.pick(dataKey, collection[id]) : null)

    let imageName
    if (type === 'categories') {
      imageName = categoryBanner && dot.pick(srcKey, categoryBanner)
    } else {
      imageName = id && collection[id] && dot.pick(srcKey, collection[id])
    }

    return (
      <ThumbWithText
        customTooltip={isModel && `${cellData} ${collection?.[id]?.texts?.title?.[lng] || ''}`}
        imageProps={{
          asBackground: true,
          height: 36,
          width: 36,
          backgroundSize: 'cover',
          imagekitParams: IMAGEKIT_PARAMS_CONFIG.crm.thumbWithTextCell,
        }}
        text={typeof cellData === 'string' ? cellData : ''}
        src={
          imageName &&
          buildImagePath(
            type === 'categories' ? 'banners' : type,
            categoryBanner?.id || rowData.id,
            type === 'brands' ? 'logos' : type === 'categories' ? 'square' : 'general',
            imageName
          )
        }
      />
    )
  }
