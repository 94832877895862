import { FORM_TABLE_OPTIONS } from '../data'

export const FRONTEND_TABLE_ACTIONS = {
  'stock-items': { search: false },
  'orders.items': { sort: true, search: false },
  'invoices.order_items': { sort: true, search: false },
  'shipments.shipment_items': { sort: true, search: false },
  'items.modifications': { sort: true, search: false },
  'inventories.inventory_items': { sort: true, search: false },
}

export const TABLE_MODES = [
  {
    key: 'table',
    icon: 'listView',
  },
  {
    key: 'cards',
    icon: 'gridView',
  },
]

export const TABLE_MODES_BY_TYPES = {
  items: ['table', 'cards'],
  brands: ['table', 'cards'],
  orders: ['table', 'cards'],
  'stock-items': ['table', 'cards'],
  warehouses: ['table', 'cards'],
  users: ['table', 'cards'],
  categories: ['table', 'cards'],
  products: ['cards'],
  inquiry_items: ['cards'],
  inquiries: ['table', 'cards'],
  invoices: ['table', 'cards'],
  shipments: ['table', 'cards'],
  posts: ['table', 'cards'],
  reports: ['table', 'cards'],
  campaigns: ['table', 'cards'],
  faqs: ['table', 'cards'],
  organizations: ['table', 'cards'],
  attributes: ['table', 'cards'],
  units: ['table', 'cards'],
  pages: ['table', 'cards'],
  inventories: ['table', 'cards'],
}

export const DISABLED_ADD_MAIN_TABLE_TYPES = {
  // orders: true,
}

export const STATES_ICONS = {
  drafted: 'stateDrafted',
  posted: 'statePosted',
  deleted: 'stateDeleted',
}

export const STATUSES_ICONS = {
  new: 'statusNew',
  confirmed: 'statusConfirmed',
  paid: 'statusPaid',
  shipped: 'statusShipped',
  completed: 'statusCompleted',
  delivered: 'statusCompleted',
  canceled: 'statusCanceled',
  in_progress: 'reload',
  pending: 'reload',
  active: 'statusCompleted',
  inactive: 'statusCanceled',
}

export const COPY_NAV_ACTION = {
  id: 'copy',
  iconName: 'pages',
}

export const TYPES_WITHOUT_STATE = ['users', 'organizations']
export const STATES_OPTIONS = {
  drafted: {
    id: 'drafted',
    label: {
      en: 'Draft',
      ru: 'Черновик',
      uk: 'Чернетка',
    },
    iconName: STATES_ICONS.drafted,
  },
  posted: {
    id: 'posted',
    label: { en: 'Posted', ru: 'Опубликован', uk: 'Опубліковано' },
    iconName: STATES_ICONS.posted,
  },
  deleted: {
    id: 'deleted',
    label: { en: 'Deleted', ru: 'Удален', uk: 'Видалено' },
    iconName: STATES_ICONS.deleted,
  },
}

export const ORG_OR_USER_STATUSES_OPTIONS = {
  pending: {
    id: 'pending',
    label: {
      en: 'Pending',
      ru: 'Ожидает подтверждения',
      uk: 'Очікує підтвердження',
    },
    iconName: STATUSES_ICONS.in_progress,
  },
  active: {
    id: 'active',
    label: {
      en: 'Active',
      ru: 'Активный',
      uk: 'Активний',
    },
    iconName: STATUSES_ICONS.completed,
  },
  inactive: {
    id: 'inactive',
    label: {
      en: 'Inactive',
      ru: 'Неактивный',
      uk: 'Неактивний',
    },
    iconName: STATUSES_ICONS.canceled,
  },
}

export const STATUSES_OPTIONS = {
  new: {
    id: 'new',
    label: {
      en: 'Created',
      ru: 'Создан',
      uk: 'Створено',
    },
    iconName: STATUSES_ICONS.new,
  },
  confirmed: {
    id: 'confirmed',
    label: { en: 'Confirmed', ru: 'Подтвержден', uk: 'Підтверджено' },
    iconName: STATUSES_ICONS.confirmed,
  },
  paid: { id: 'paid', label: { en: 'Paid', ru: 'Оплачен', uk: 'Оплачено' }, iconName: STATUSES_ICONS.paid },
  shipped: {
    id: 'shipped',
    label: {
      en: 'Shipped',
      ru: 'Отправлен',
      uk: 'Відправлено',
    },
    iconName: STATUSES_ICONS.shipped,
  },
  completed: {
    id: 'completed',
    label: { en: 'Completed', ru: 'Завершен', uk: 'Завершено' },
    iconName: STATUSES_ICONS.completed,
  },
  canceled: {
    id: 'canceled',
    label: { en: 'Canceled', ru: 'Отменен', uk: 'Скасовано' },
    iconName: STATUSES_ICONS.canceled,
  },
}

export const CAMPAIGNS_STATUSES_OPTIONS = {
  active: ORG_OR_USER_STATUSES_OPTIONS.active,
  completed: {
    id: 'complete',
    label: { en: 'Completed', ru: 'Завершен', uk: 'Завершено' },
    iconName: STATUSES_ICONS.completed,
  },
  canceled: STATUSES_OPTIONS.canceled,
}

export const INQUIRY_STATUSES_OPTIONS = {
  new: STATUSES_OPTIONS.new,
  in_progress: {
    id: 'in_progress',
    label: { en: 'In progress', ru: 'В работе', uk: 'В роботі' },
    iconName: STATUSES_ICONS.in_progress,
  },
  completed: STATUSES_OPTIONS.completed,
  canceled: STATUSES_OPTIONS.canceled,
}

export const INVOICE_STATUSES_OPTIONS = {
  new: STATUSES_OPTIONS.new,
  paid: STATUSES_OPTIONS.paid,
  canceled: STATUSES_OPTIONS.canceled,
}

export const ORDER_STATUSES_OPTIONS = {
  new: STATUSES_OPTIONS.new,
  confirmed: STATUSES_OPTIONS.confirmed,
  shipped: STATUSES_OPTIONS.shipped,
  completed: STATUSES_OPTIONS.completed,
  canceled: STATUSES_OPTIONS.canceled,
}

export const SHIPMENT_STATUSES_OPTIONS = {
  new: STATUSES_OPTIONS.new,
  in_progress: INQUIRY_STATUSES_OPTIONS.in_progress,
  shipped: STATUSES_OPTIONS.shipped,
  delivered: {
    id: 'delivered',
    label: { en: 'Delivered', ru: 'Доставлено', uk: 'Доставлено' },
    iconName: STATUSES_ICONS.completed,
  },
  received: {
    id: 'received',
    label: { en: 'Received', ru: 'Получено', uk: 'Отримано' },
    iconName: STATUSES_ICONS.completed,
  },
  canceled: STATUSES_OPTIONS.canceled,
}

const TWO_STATES_ONLY = Object.values(STATES_OPTIONS).filter((opt) => opt.id !== 'drafted')

export const ALL_STATUSES_OPTIONS = {
  orders: Object.values(ORDER_STATUSES_OPTIONS),
  inquiries: Object.values(INQUIRY_STATUSES_OPTIONS),
  invoices: Object.values(INVOICE_STATUSES_OPTIONS),
  shipments: Object.values(SHIPMENT_STATUSES_OPTIONS),
  users: Object.values(ORG_OR_USER_STATUSES_OPTIONS),
  organizations: Object.values(ORG_OR_USER_STATUSES_OPTIONS),
  campaigns: Object.values(CAMPAIGNS_STATUSES_OPTIONS),
}

export const TABLE_BULK_OPERATIONS = {
  orderItems: FORM_TABLE_OPTIONS,
  enumOptions: FORM_TABLE_OPTIONS,
  categories: TWO_STATES_ONLY,
  banners: TWO_STATES_ONLY,
}

// export const TABLE_TYPES_WITH_STATUSES = ['orders', 'invoices', 'shipments', 'inquiries']
