import React, { forwardRef } from 'react'
import { useDispatch } from 'react-redux'

import { Icon, Userpic } from '@aidsupply/components'

import { StyledUserpic } from './styled'
import { deletePhoto } from '../../../redux/actions'

const OrganizationAvatar = forwardRef(
  ({ children, entityId, photoId, name, src, theme, userpicSize }, ref) => {
    const dispatch = useDispatch()

    return (
      <StyledUserpic ref={ref}>
        {children}
        <Userpic
          src={src}
          width={userpicSize}
          height={userpicSize}
          theme={theme}
          fullName={name}
          borderColor={theme.color.general.gray2}
        />
        <Icon
          name="trashBin"
          onClick={() => {
            if (photoId) {
              dispatch(
                deletePhoto({
                  photoId,
                  entityId,
                  entityType: 'organizations',
                  primaryPhotoIdKey: 'logo_photo_id',
                })
              )
            }
          }}
        />
      </StyledUserpic>
    )
  }
)

export default OrganizationAvatar
