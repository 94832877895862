import { Step } from '@aidsupply/components'
import styled from 'styled-components'

export const StyledTabsWrapper = styled.div`
  &.photos {
    margin-top: -5px;
    padding-bottom: 20px;
  }
`

export const StyledProductsForm = styled.div`
  form {
    margin-top: auto;
    padding-bottom: 130px;
  }

  .buttonsWrapper {
    display: flex;
    justify-content: space-between;

    button {
      height: 44px;
    }

    button:not(.fullWidth) {
      flex-basis: 48%;
    }
  }

  .summaryWrapper {
    padding-bottom: 20px;
    bottom: 60px;
    right: 0;
    position: fixed;
    background-color: ${({ theme }) => theme.color.general.light};
    left: ${({ theme: { grid }, currentBreakpoint }) => grid[currentBreakpoint].columns.gapWidth};
    @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm - 1}px) {
      left: 0;
    }
  }

  .summary {
    position: initial;
    right: 0;
  }
`

export const StyledStep = styled(Step)`
  margin-bottom: 25px;

  ~ .titleEditPanel {
    margin-bottom: 25px;
  }

  && .panel {
    margin: 0 5px 0 5px;
    padding: 10px;

    .icon {
      margin-right: 9px;
    }
  }
`
