const getQuantity = (t, tableData, props) => ({
  // label: t('quantity'),
  value: tableData?.length || 0,
  width: 80,
  padding: props?.state === 'drafted' ? '0px 7px 0 15px' : undefined,
})

const getTotal = (t, tableData, props) => {
  return {
    // label: t('total'),
    value: ` ${props.currency || ''}${tableData?.reduce((acc, curr) => {
      acc += (+curr.quantity || 0) * (+curr.price || 0)
      return acc
    }, 0)}`,
    width: 85,
    padding: props?.state === 'drafted' ? '0px 7px 0 15px' : undefined,
  }
}

export const getTableSummary = (type, t, tableData, props) => {
  const CONFIG = {
    'orders.order_items': {
      title: t('summary'),
      data: [getQuantity(t, tableData, props), getTotal(t, tableData, props)],
    },
    'invoices.order_items': {
      title: t('summary'),
      data: [getQuantity(t, tableData, props), getTotal(t, tableData, props)],
    },
    'shipments.shipment_items': {
      title: t('summary'),
      data: [getQuantity(t, tableData, props)],
    },
    'inventories.inventory_items': {
      title: t('summary'),
      data: [getQuantity(t, tableData, props)],
    },
  }

  return CONFIG[type]
}
