import styled from 'styled-components'

export const StyledHeaderMobile = styled.div`
  background-color: ${({ theme }) => theme.color.general.gray1};
  padding: 0 10px;
  width: 100vw;
  height: max-content;
  position: relative;
  z-index: 11;
  border-bottom: 1px solid transparent;

  &.extended {
    border-bottom: 1px solid ${({ theme }) => theme.color.general.gray2};
  }

  .menuItem {
    margin-right: 9px;
    .item {
      padding: 8px;
    }
  }
  .headerMobileLogo {
    flex-grow: 1;
  }
  .menuItemIcon path {
    stroke: ${({ theme }) => theme.color.general.dark};
  }
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    .menuItem {
      order: 3;
    }
    .headerMobileLogo {
      order: 2;
      flex-grow: 0;
    }
    .menuIcon {
      order: 1;
    }
  }
`
