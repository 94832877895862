import styled from 'styled-components'

export const StyledInquiryNumber = styled.div`
  margin: 8px 0;
  width: 100%;

  .card & {
    margin: 0;
  }
`
export const StyledInquiryStatusBar = styled.div`
  display: flex;
  height: 3px;
  width: 100%;
  margin-top: 3px;
  position: relative;
`

export const StyledInquiryStatusBarItem = styled.div`
  width: ${({ width }) => width}%;
  border-radius: 4px;
  opacity: ${({ opacity }) => opacity}%;
  background-color: ${({ theme }) => theme.color.primary.main};
  position: absolute;
  height: 3px;
  border-right: 1px solid ${({ theme }) => theme.color.primary.darkest};
`
