import React from 'react'
import { withTheme } from 'styled-components'
import { parse } from 'query-string'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { Button, Typography, useWindowWidth } from '@aidsupply/components'
// import { EmptyFolder } from '../../assets/icons/EmptyFolder'
import { StyledEmptyScreen } from './styled'
import OrganizationSelect from '../Stocks/OrganizationSelect'

const EmptyScreen = ({ type, btnLabelType, onButtonClick, buttonProps, role, theme, children }) => {
  const { search } = useLocation()
  const searchParsed = parse(search)

  const { t } = useTranslation('loadingOrNoData')

  const width = useWindowWidth()
  const isMobile = width && width < theme.breakpoints.lg

  const navigate = useNavigate()

  return (
    <>
      {/* <TableTitleBlock type={type} /> */}
      <StyledEmptyScreen className={type}>
        {type === 'stock-items' && ['administrator', 'system'].includes(role) && (
          <OrganizationSelect label={t('organization')} t={t} />
        )}
        {/*<EmptyFolder />*/}
        <Typography type={isMobile ? 'h3' : 'h2'} margin="20px 0 10px" textTransform="uppercase">
          {/*{t(`${type}Header`)}*/}
          {t('noDataFound')}
        </Typography>
        {/* <Typography type="body2" text={t(typeData[type]?.text || `${type}Text`)} className="inline" /> */}
        {btnLabelType && (
          <Button
            text={t('addFirstItem', { itemType: t(btnLabelType) })}
            onClick={onButtonClick ? onButtonClick : () => navigate('new')}
            variant="bordered"
            {...buttonProps}
          />
        )}
        {children}
      </StyledEmptyScreen>
    </>
  )
}

export default withTheme(EmptyScreen)
