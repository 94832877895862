import React from 'react'
import { useDispatch } from 'react-redux'
import { StyledLeftDrawer } from './styled'
import { toggleFiltersPanel } from '../../redux/actions'
import { useMappedState } from '../../hooks'
import { selectFiltersPanelOpened } from '../../redux/selectors'
import CentralPanel from './CentralPanel'
import ErrorBoundary from '../../ErrorBoundary'
import RightPanel from '../../components/RightPanel'

const GridLayoutMobile = ({
  children,
  isLeftPanelOpened,
  setIsLeftPanelOpened,
  isRightPanelOpened,
  setIsRightPanelOpened,
  LeftPanelComponent,
  RightPanelComponent,
  t,
  theme,
  type,
  className,
  rightPanelProps,
}) => {
  const dispatch = useDispatch()
  const onToggleFilters = (opened) => {
    dispatch(toggleFiltersPanel(opened))
  }
  const filtersPanelOpened = useMappedState(selectFiltersPanelOpened)
  return (
    <>
      <CentralPanel className={className}>{children}</CentralPanel>

      {LeftPanelComponent && (
        <StyledLeftDrawer
          width="100vw"
          side="left"
          openedValue={filtersPanelOpened}
          toggleDrawer={onToggleFilters}
          absolutePositioned
          title={t('table:filters')}
          closeIconPadding="6px"
          id="filters"
          borderColor={theme.bordersColor}
          closeIconProps={{
            width: 18,
            height: 18,
            wrapperWidth: 32,
            wrapperHeight: 32,
            wrapperColor: theme.color.primary.main,
            fill: theme.color.general.light,
            strokeWidth: 1.4,
          }}
        >
          <ErrorBoundary>{LeftPanelComponent}</ErrorBoundary>
        </StyledLeftDrawer>
      )}

      {RightPanelComponent && (
        <RightPanel
          openedValue={isRightPanelOpened}
          toggleDrawer={setIsRightPanelOpened}
          width="100vw"
          absolutePositioned
          rightPanelProps={rightPanelProps}
        >
          <ErrorBoundary>{RightPanelComponent}</ErrorBoundary>
        </RightPanel>
      )}
    </>
  )
}

export default GridLayoutMobile
