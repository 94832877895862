import React from 'react'
import { object } from 'dot-object'
import { isObject } from '@aidsupply/components'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import EditForm from '../../../components/RightPanelForms'
import { sidebarUpsert } from '../../../redux/actions'
import { selectSidebarInitialData } from '../../../redux/selectors'
import { useMappedState } from '../../../hooks'

const InquiriesRightPanel = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const sidebarInitialData = useMappedState(selectSidebarInitialData)

  const onFormSubmit = (formValuesChanged) => {
    const requestBody = { ...object(formValuesChanged) }

    Object.keys(requestBody).map((key) => {
      if (isObject(requestBody[key]) && requestBody[key].id) {
        requestBody[key] = requestBody[key].id
      }
    })

    if (formValuesChanged.inquiry_items?.length) {
      requestBody.inquiry_items = formValuesChanged.inquiry_items.map((item) => ({
        status: item.status,
        category_id: item.category_id?.id || item.category_id,
        characteristics_keys: item.characteristics_keys,
        characteristics_values: item.characteristics_values,
        qty_new: item.qty_new,
        id: item.id,
        // qty_in_progress: item.qty_in_progress,
        // qty_cancelled: item.qty_cancelled,
        // qty_completed: item.qty_completed,
      }))
    }

    const dataToSend = {
      id: sidebarInitialData.id,
      requestBody,
      type: 'inquiries',
      parentType: 'documents',
    }

    dispatch(sidebarUpsert(dataToSend, navigate))
  }

  return <EditForm onFormSubmit={onFormSubmit} />
}

export default InquiriesRightPanel
