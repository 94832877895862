import React, { useEffect } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

// import { ROUTES_FORBIDDEN } from '../constants'
import { selectUserDetails, selectUserSystemParams } from './redux/selectors'
import { initializeApp } from './redux/actions'
import { useMappedState } from './hooks'

const PrivateRoute = ({ children }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const { i18n } = useTranslation('all')

  const user = useMappedState(selectUserDetails)
  const { isAuthenticated, isAppInitialized, inProgress } = useMappedState(selectUserSystemParams)

  useEffect(() => {
    if (!isAppInitialized) {
      dispatch(initializeApp(navigate, location, i18n.changeLanguage))
    }
  }, [user])

  if (!isAppInitialized || inProgress || !user.email) {
    return null
  }

  return isAuthenticated ? children : <Navigate replace to="/signin" />
}

export default PrivateRoute
