import { ORGANIZATION_TYPES } from '../../../data'
import { SOCIAL_NETWORKS } from '../../../config/forms.js'

export const formConfig = {
  withTabs: ['description'],
  validationRules: {
    name: ['required'],
    type: ['required'],
    email: ['email'],
    country_id: ['required'],
    year_founded: ['year'],
  },
  fields: {
    generalInfo: [
      {
        key: 'name',
        label: 'name',
        required: true,
      },
      {
        key: 'name_en',
        label: 'nameInEnglish',
      },
      {
        key: 'type',
        label: 'organizationType',
        options: ORGANIZATION_TYPES,
        component: 'dropdown',
        required: true,
      },
      {
        key: 'year_founded',
        label: 'yearFounded',
        type: 'number',
        hideButtons: true,
      },
      {
        key: 'reg_number',
        label: 'registrationNumber',
      },
      {
        key: 'country_id',
        label: 'country',
        component: 'dropdown',
        optionsKeys: ['countries'],
        labelKey: 'translations',
        required: true,
      },
      {
        key: 'region_id',
        label: 'region',
        component: 'dropdown',
        isClearable: true,
        optionsKeys: ['country_subdivisions'],
        labelKey: 'translations',
      },
      {
        key: 'city',
        label: 'city',
      },
      {
        key: 'website',
        label: 'organizationWebsite',
        placeholder: 'https://domain.com',
      },
      {
        key: 'email',
        label: 'organizationEmail',
        placeholder: 'email@address.com',
      },
      {
        key: 'video_url',
        label: 'introductionVideo',
      },
    ],
    translations: [
      {
        key: 'description',
        label: 'description',
        type: 'richText',
      },
    ],
    fundraisingAccounts: [
      {
        key: 'wayforpay_url',
        label: 'wayforpayURL',
        placeholder: 'Profile URL',
      },
      {
        key: 'paypal_url',
        label: 'PayPal',
        placeholder: 'Profile URL',
      },
      {
        key: 'patreon_url',
        label: 'Patreon',
        placeholder: 'Profile URL',
      },
      {
        key: 'buymeacoffee_url',
        label: 'Buy Me A Coffee',
        placeholder: 'Profile URL',
      },
      {
        key: 'venmo_url',
        label: 'Venmo',
        placeholder: 'Profile URL',
      },
    ],
    socialProfiles: SOCIAL_NETWORKS,
  },
}
