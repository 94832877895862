export const API_METHODS = {
  create: 'POST',
  read: 'GET',
  update: 'PATCH',
  upsert: 'POST',
  delete: 'DELETE',
}

export const POSTGRES_TYPES = [
  'inquiries',
  'orders',
  'invoices',
  'shipments',
  'warehouses',
  'stock-items',
  'users',
  'organizations',
]
export const DATA_TYPES_WITH_ITEMS = ['enumerations']

export const CRM_DATA_TYPES_WITH_NO_FACETS = ['dashboard', 'enumerations', 'products']

export const TYPES_WITH_NO_STATE = {
  versions: true,
  industries: true,
  feedbacks: true,
  countries: true,
  currencies: true,
  orders: true,
}

export const CRM_TYPES_WITH_POSTED_STATE = ['products', 'inquiry_items']

export const GET_CRM_API_URLS = (param) => {
  return {
    attributes: '/catalogs/attributes',
    banners: '/common/posters',
    brands: '/catalogs/brands',
    carriers: '/common/carriers',
    categories: '/catalogs/categories',
    categoriesAll: '/catalogs/categories',
    contracts: '/documents/contracts',
    campaigns: '/documents/campaigns',
    comments: '/documents/comments',
    country_subdivisions: '/catalogs/country_subdivisions',
    currentUser: '/current_user',
    dashboard: '/common/dashboard',
    inquiry_items: '/catalogs/categories',
    countries: '/catalogs/countries',
    currencies: '/catalogs/currencies',
    emailConfirm: '/auth/confirm_email',
    enumerations: '/catalogs/enumerations',
    enumerationsItems: `/catalogs/enumerations/${param}/options`, // enumeration_id
    faqs: '/knowledgebase/faqs',
    feedbacks: '/common/feedbacks',
    filesUpload: '/files',
    // industries: '/industries',
    inquiries: '/documents/inquiries',
    inviteDetails: '/auth/confirm_invite_details',
    inviteSignup: '/auth/confirm_invite',
    invoices: '/documents/invoices',
    inventories: '/documents/inventories',
    items: '/catalogs/items',
    orders: '/documents/orders',
    ordersItems: `/documents/orders/${param}/items`, // order_id
    ordersParseFile: '/documents/orders/parse_items',
    organizations: '/organizations',
    pages: '/knowledgebase/pages',
    pagesOpenAPI: '/platform/pages',
    platforms: '/common/platforms',
    platformsOpenAPI: '/platform/platforms',
    posts: '/knowledgebase/posts',
    reports: '/knowledgebase/reports',
    topics: '/knowledgebase/topics',
    products: '/common/search',
    refreshToken: '/auth/refresh-token',
    requestResetPassword: '/auth/request_reset_password',
    resetPassword: '/auth/reset_password',
    signIn: '/auth/signin',
    signUp: '/auth/signup',
    shipments: '/documents/shipments',
    shipmentsItems: `/documents/shipments/${param}/shipment_items`, // shipment_id
    warehouses: '/warehouses',
    'stock-items': '/stocks',
    //     {
    //   read: `/warehouse/${param}/organization_stocks`, // organization_id
    //   create: `/warehouse/${param}/stocks/generate`, // warehouse_id
    //   update: `/warehouse/${param}/stocks`, // warehouse_id
    // },
    versions: '/common/versions',
    units: '/catalogs/units',
    users: '/users',
    userChangeRole: `/users/${param}/user-role`, // user_id
    userInvite: '/auth/invite-user',
  }
}

export const SYSTEM_DATA_FIELDS = {
  attributes: 'id,state,slug,general,translations.label',
  brands:
    'id,state,platformId,slug,general.name,general.industry,general.platforms,general.merchant,photos.logos.active,photos.miniLogos.active,texts.textForEveryItem',
  carriers: 'id,state,slug,general',
  categories:
    'id,state,slug,label,general.name,general.industry,general.properties,general.characteristics,general.content,is_used_in_inquiries,general.unit,photos',
  countries: 'id,callingCodes,flag,translations',
  currencies: 'id,state,code,name,symbol,rates',
  enumerations: 'id,slug,state,general.name,general.isIconsUploadAllowed,translations.label,options',
  industries: 'all',
  inquiries: 'all',
  items:
    'id,state,slug,platformId,general.model,general.brand,general.category,photos.general.active,characteristics,modifications,texts.title',
  orders: 'all',
  organizations: 'all',
  'stock-items': 'all',
  warehouses: 'all',
  units: 'id,state,general,translations',
  users: 'all',
  campaigns: 'all',
  'organization-profile': 'all',
}

export const SYSTEM_DATA_KEYS = {
  attributes: ['attributes', 'states', 'enumerations', 'units', 'attributeTypes', 'users'],
  brands: ['brands', 'organizations', 'countries', 'categories', 'industries', 'states', 'users'],
  carriers: ['countries', 'attributes', 'enumerations', 'users'],
  categories: ['categories', 'industries', 'states', 'attributes', 'enumerations', 'units', 'users'],
  contracts: ['states', 'organizations', 'documents', 'contractTypes'],
  dashboard: [
    'brands',
    'categories',
    'organizations',
    'invoices',
    'items',
    'orders',
    'shipments',
    'warehouses',
    'users',
  ],
  inquiry_items: [
    'states',
    'organizations',
    'units',
    'industries',
    'attributes',
    'enumerations',
    'currencies',
  ],
  enumerations: ['enumerations', 'states', 'users', 'enumerationAddons'],
  faqs: ['states', 'organizations'],
  inquiries: [
    'organizationTypes',
    'states',
    'inquiryStatuses',
    'organizations',
    'categories',
    'units',
    'attributes',
    'enumerations',
    'countries',
    'country_subdivisions',
  ],
  invoices: [
    'states',
    'invoiceStatuses',
    'organizations',
    'contracts',
    'currencies',
    'orders',
    'items',
    'attributes',
    'enumerations',
    'brands',
  ],
  inventories: [
    'states',
    'organizations',
    'warehouses',
    'users',
    'items',
    'inventories',
    'brands',
    'countries',
    'country_subdivisions',
  ],
  items: [
    'attributes',
    'brands',
    'countries',
    'categories',
    'enumerations',
    'items',
    'states',
    'units',
    'users',
  ],
  orders: [
    'brands',
    'categories',
    'organizations',
    'states',
    'orderStatuses',
    'attributes',
    'enumerations',
    'currencies',
    'countries',
    'warehouses',
    'items',
    'inquiries',
    'users',
    'taxTypes',
  ],
  organizations: [
    'states',
    'organizationTypes',
    'currencies',
    'countries',
    'country_subdivisions',
    'orgOrUsersStatuses',
    'organizationRoles',
  ],
  pages: ['states', 'organizations', 'pageTypes'],
  products: [
    'states',
    'categories',
    'brands',
    'attributes',
    'enumerations',
    'currencies',
    'countries',
    'organizations',
    'inquiries',
  ],
  posts: ['states', 'visibilityTypes', 'topics', 'organizations'],
  shipments: [
    'states',
    'shipmentStatuses',
    'organizations',
    'warehouses',
    'currencies',
    'orders',
    'items',
    'attributes',
    'enumerations',
    'brands',
  ],
  warehouses: [
    'currencies',
    'organizations',
    'countries',
    'country_subdivisions',
    'states',
    'warehouseTypes',
    'users',
  ],
  'stock-items': [
    'brands',
    'categories',
    'currencies',
    'items',
    'organizations',
    'states',
    'warehouses',
    'attributes',
    'enumerations',
    'users',
  ],
  units: ['states', 'magnitudes', 'users'],
  users: ['states', 'userRoles', 'users', 'organizations', 'orgOrUsersStatuses'],
  campaigns: ['states', 'currencies', 'organizations', 'campaignStatuses'],
  'organization-profile': ['organizationTypes', 'countries', 'country_subdivisions'],
  reports: ['states', 'reportTypes', 'months', 'years', 'organizations'],
}

export const SEARCH_FIELDS = {
  // products: 'translations', // CRM: /search
  // inquiry_items: 'translations',
  orders: 'external_number|number|total', // client.name
  inquiries: 'number|recipient_name',
  invoices: 'number', // client.name
  shipments: 'number', // client.name
  organizations: 'name|name_en',
  brands: 'name|name_en',
  users: 'full_name|email',
  items: 'name|sku|translations',
  categories: 'name|translations',
  // attributes: 'translations',
  // units: 'translations',
  // enumerations: 'translations',
  warehouses: 'name|city', // |region
  // 'stock-items': 'price|quantity',
}

export const FIELDS_FOR_ALPHABETICAL_BACKEND_SORT = {
  // banners: `general.page`,
  // brands: 'name',
  // items: 'model',
  // platforms: 'name',
  // merchants: 'name',
}

export const KEYS_FOR_ALPHABETICAL_FRONTEND_SORT = {
  categories: true,
  countries: true,
  industries: true,
}

export const DATA_TYPE_TO_SINGULAR_FORM = {
  attributes: 'attribute',
  banners: 'banner',
  brands: 'brand',
  carriers: 'carrier',
  categories: 'category',
  clients: 'client',
  countries: 'country',
  currencies: 'currency',
  enumerations: 'enumeration',
  inquiries: 'inquiry',
  invoices: 'invoice',
  items: 'item',
  inventories: 'inventory',
  orders: 'order',
  organizations: 'organization',
  pages: 'page',
  platforms: 'platform',
  posts: 'post',
  reports: 'report',
  roles: 'role',
  shipments: 'shipment',
  'stock-items': 'stock-item',
  stocks: 'stock',
  warehouses: 'warehouse',
  modifications: 'modification',
  units: 'unit',
  users: 'user',
  campaigns: 'campaign',
}
