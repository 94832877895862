import React from 'react'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { withTheme } from 'styled-components'
import { useDispatch } from 'react-redux'

import { Typography, UiLink, Button } from '@aidsupply/components'

import ReactHelmet from '../../wrappers/ReactHelmet'
import IntroScreenWrapper from '../../components/IntroScreens/IntroScreenWrapper'
import Form from '../../components/Form'
import { EMAIL } from '../../config/forms'
// import backOrange from '../../assets/images/backOrange.jpg'
import { userRequestResetPassword } from '../../redux/actions'
import {INTRO_PAGES_IMG} from "../../constants";

const initialValues = { email: '', password: '' }

const PasswordRecovery = ({ theme }) => {
  const { t } = useTranslation('signIn')
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const onFormSubmit = (data) => {
    dispatch(userRequestResetPassword(data.email, navigate))
  }

  return (
    <>
      <ReactHelmet title="signTitle" />

      <IntroScreenWrapper type="passwordRecovery" theme={theme} imageUrl={INTRO_PAGES_IMG}>
        <Typography type="button1" text={t('passRecoverText')} margin="20px 0" />
        <Form
          type="passwordRecovery"
          initialValues={initialValues}
          // serverError={error !== false}
          onSubmit={onFormSubmit}
          validationRules={{
            email: ['required', 'email'],
          }}
          fields={[EMAIL]}
        >
          <Button
            fullWidth
            variant="bordered"
            type="submit"
            iconName="passwordReset"
            uppercase={false}
            theme={theme}
          >
            {t('recoverPassword')}
          </Button>
        </Form>
        <div className="inscriptionWrapper">
          <Typography type="body1">{t('backTo')}</Typography>
          <Typography type="button1">
            <UiLink Link={RouterLink} to="/login">
              {t('toSignIn')}
            </UiLink>
          </Typography>
        </div>
      </IntroScreenWrapper>
    </>
  )
}

export default withTheme(PasswordRecovery)
