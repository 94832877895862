import React, { useContext } from 'react'
import styled, { withTheme } from 'styled-components'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { ErrorBoundary, Line, ExpansionPanel, Filters, Icon, ScreenContext } from '@aidsupply/components'
import { pick } from 'dot-object'

import { StyledFiltersTitle } from '../Main/styled'
import { FILTERS_CONFIG } from '../../config/filters'
import { filterAdd, filterRemove, filtersClearAll } from '../../redux/actions'
import { useMappedState } from '../../hooks'
import {
  selectAllSystemCollections,
  selectFiltersState,
  selectTableDataCount,
  selectUserRole,
} from '../../redux/selectors'
import { USER_ROLES } from '../../config'

const LeftFiltersPanel = ({ leftPanelIsOpened, setLeftPanelOpened, theme }) => {
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation('table')
  const { currentBreakpoint, width, md } = useContext(ScreenContext)
  const navigate = useNavigate()
  const { search } = useLocation()

  const system = useMappedState(selectAllSystemCollections)
  const filters = useMappedState(selectFiltersState)
  const itemsCount = useMappedState(selectTableDataCount)
  const role = useMappedState(selectUserRole)

  const borderColor = pick(USER_ROLES[role]?.navMenuLineColor || 'general.gray1', theme.color)

  const updateFilters = (updateFunction) => {}

  return (
    <ErrorBoundary>
      <Line color={borderColor} margin="0" />
      <Filters
        variant="primary"
        buttonProps={{
          uppercase: false,
          withTextShadow: true,
          withLetterSpacing: false,
        }}
        className="filters"
        searchItemsLength={itemsCount}
        config={FILTERS_CONFIG}
        filters={filters}
        fnDispatch={dispatch}
        fnFilterAdd={filterAdd}
        fnFiltersClearAll={filtersClearAll}
        fnFilterRemove={filterRemove}
        fnNavigate={navigate}
        locationSearch={search}
        padding="0"
        systemData={system}
        currentLng={i18n.language}
        fallbackLng="en"
        t={t}
        tagProps={{ colorKey: 'gray3' }}
      />
    </ErrorBoundary>
  )
}

export default withTheme(LeftFiltersPanel)
