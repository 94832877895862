import styled from 'styled-components'

export const StyledTopicsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .stickyAtTheTop {
    position: sticky;
    top: 50px;
    z-index: 1;
    background-color: ${({ theme }) => theme.color.general.light};
  }

  & > div:nth-child(2) {
    flex-grow: 1;
  }

  .label {
    margin-bottom: 5px;
  }

  div[role='tabpanel'] {
    .inputContainer {
      margin-bottom: 10px;
    }
  }
`
