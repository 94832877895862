import dayjs from 'dayjs'
import { capitalizeFirstLetter } from './general'
require('dayjs/locale/uk')
require('dayjs/locale/ru')

const timezone = require('dayjs/plugin/timezone')
const utc = require('dayjs/plugin/utc')
const duration = require('dayjs/plugin/duration')
dayjs.extend(timezone)
dayjs.extend(utc)
dayjs.extend(duration)

export const formatDateToUserTimezone = (date, formatTemplate) => {
  return dayjs.utc(date).tz().format(formatTemplate)
}

export const getYears = (firstYear) => {
  function getAllYearsInRange(startYear, endYear) {
    const years = []
    for (let year = startYear; year <= endYear; year++) {
      const value = { id: year }
      years.push(value)
    }
    return years
  }

  const currentYear = dayjs().year()
  const years = getAllYearsInRange(firstYear, currentYear)
  return years
}

export const getMonths = () => {
  function getAllMonths() {
    const months = []
    for (let month = 0; month < 12; month++) {
      const value = {
        id: dayjs().month(month).format('MMMM').toLowerCase(),
        label: {
          en: capitalizeFirstLetter(dayjs().month(month).format('MMMM')),
          uk: capitalizeFirstLetter(dayjs().locale('uk').month(month).format('MMMM')),
          ru: capitalizeFirstLetter(dayjs().locale('ru').month(month).format('MMMM')),
        },
      }
      months.push(value)
    }
    return months
  }

  const months = getAllMonths()
  return months
}
