import React from 'react'
import styled, { withTheme } from 'styled-components'
import { Icon, TextPanel, Typography, UiLink } from '@aidsupply/components'
import { Link } from 'react-router-dom'

const StyledTextPanel = styled(TextPanel)`
  padding: 20px;
  margin-bottom: 30px;
  .icon {
    margin-right: 15px;
    flex-shrink: 0;
  }

  .link {
    margin-top: 5px;
  }
`

const InfoPanel = ({ children, date, linkText, linkTo, text, theme: { color } }) => {
  return (
    <StyledTextPanel bordered>
      <Icon
        name="info"
        wrapperHeight={30}
        wrapperWidth={30}
        borderRadius="50%"
        fill={color.general.dark}
        stroke={color.general.dark}
        // wrapperColor={color.primary.main}
        height={20}
        width={20}
      />
      {children || (
        <div>
          <Typography type="body1">{text}</Typography>
          <br />
          {date && (
            <Typography type="body2" className="date">
              {date}
            </Typography>
          )}
          {linkText && (
            <UiLink Link={Link} to={linkTo} themeColor="status.new">
              {linkText}
            </UiLink>
          )}
        </div>
      )}
    </StyledTextPanel>
  )
}

export default withTheme(InfoPanel)
