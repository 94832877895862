import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { TextPanel, isObjectEmpty } from '@aidsupply/components'

import { FILE_TYPES } from '../../constants'
import { useMappedState } from '../../hooks'
import { inputFileRemove, inputFilesAdd } from '../../redux/actions'
import { selectFileUploadState } from '../../redux/selectors'
import DropZone from '../DropZone'
import Thumbnail from './Thumbnail'
import { StyledThumbnails } from './styled'

const UploadFiles = ({
  style,
  fileGroup,
  maxFiles,
  isSimpleButton,
  onFnSet,
  withImagePreview = true,
  filesType = 'photos', // | 'files'
  fileTypes,
  simpleButtonProps,
  isUploadInProgress,
  isMultipleUploadDisabled,
  isReadOnly,
  onFileDrop,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { files, filesInProgress, errors } = useMappedState(selectFileUploadState)
  const fileType = fileTypes || (filesType === 'files' ? FILE_TYPES.files : FILE_TYPES.images)

  const fnSet = (newFiles) => {
    dispatch(inputFilesAdd(newFiles, fileGroup, filesType))
    if (onFnSet) {
      onFnSet(newFiles)
    }
  }

  const fnDelete = (id) => {
    dispatch(inputFileRemove(id, fileGroup))
  }

  return (
    <StyledThumbnails style={style}>
      {!isSimpleButton && withImagePreview && (
        <aside className="thumbnails">
          {files[fileGroup] &&
            files[fileGroup].map((file) => (
              <Thumbnail
                error={errors[fileGroup]?.includes(file.preview)}
                fnDelete={fnDelete}
                key={file.preview}
                loading={filesInProgress[fileGroup]?.includes(file.preview)}
                src={file.preview}
                disableImagekit
                fileSize={file.size}
                fileName={file.name}
                filesType={filesType}
                isReadOnly={isReadOnly}
              />
            ))}
        </aside>
      )}

      {!isSimpleButton && errors?.[fileGroup] && !isObjectEmpty(errors[fileGroup]) && (
        <TextPanel type="error" content={t('Some files were not uploaded. Please try again.')} />
      )}

      <DropZone
        fileType={fileType} // https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
        setFiles={fnSet}
        fileGroup={fileGroup}
        currentFiles={files[fileGroup]}
        isSimpleButton={isSimpleButton}
        simpleButtonProps={simpleButtonProps}
        maxFiles={maxFiles}
        isMultipleUploadDisabled={isMultipleUploadDisabled}
        isUploadInProgress={isUploadInProgress}
        // onFileDrop={onFileDrop}
      />
    </StyledThumbnails>
  )
}

export default UploadFiles
