import { useEffect, useRef, useState } from 'react'

import { TABLE_MODES_BY_TYPES } from '../../../config/table'

export const useTableModeAndSize = ({ type, dataLength, topHeight, gap, gridRef, gridColCount }) => {
  const [isDropdownSearch, setIsDropdownSearch] = useState(false)

  const tableModeInitial = TABLE_MODES_BY_TYPES[type]?.[0] || 'table'
  const [tableMode, setTableMode] = useState(tableModeInitial)

  useEffect(() => {
    setTableMode(tableModeInitial)
  }, [type, tableModeInitial])

  // const getMaxTableHeight = (rowHeight, tableHeaderHeight) => {
  //   // console.log(tableData, rowHeight, tableHeaderHeight)
  //   if (typeof window !== 'undefined' && dataLength) {
  //     const rowsCount = tableMode === 'cards' ? Math.ceil(dataLength / gridColCount) : dataLength
  //
  //     if (rowsCount * rowHeight < window.innerHeight - topHeight - tableHeaderHeight) {
  //       return rowsCount * rowHeight + tableHeaderHeight
  //     } else {
  //       return window.innerHeight - topHeight - tableHeaderHeight - gap
  //     }
  //   } else {
  //     return tableMode === 'cards' ? tableHeaderHeight + 20 : rowHeight
  //   }
  // }

  const getMaxTableHeight = (rowHeight, tableHeaderHeight) => {
    if (typeof window !== 'undefined' && topHeight) {
      if (tableMode === 'table') {
        return window.innerHeight - topHeight - tableHeaderHeight - gap
      } else if (tableMode === 'cards') {
        return window.innerHeight - topHeight - gap
      }
    }

    return null
  }

  const onListResize = (width) => {
    if (width < 474) {
      setIsDropdownSearch(true)
    } else {
      setIsDropdownSearch(false)
    }

    if (gridRef?.current) {
      if (gridRef.current.resetAfterColumnIndex) {
        gridRef.current.resetAfterColumnIndex(0)
      }
      if (gridRef.current.resetAfterRowIndex) {
        gridRef.current.resetAfterRowIndex(0)
      }
    }

    const tableModes = TABLE_MODES_BY_TYPES[type]

    if (tableModes) {
      if (width <= 850) {
        if (tableModes.includes('cards')) {
          setTableMode('cards')
        }
      }

      if (width > 850) {
        if (tableModes.includes('table')) {
          setTableMode('table')
        }
      }
    }
  }

  const onTableResize = ({ width }) => {
    if (width < 474) {
      setIsDropdownSearch(true)
    } else {
      setIsDropdownSearch(false)
    }

    const tableModes = TABLE_MODES_BY_TYPES[type]

    if (tableModes) {
      if (width < 850) {
        if (tableModes.includes('cards')) {
          setTableMode('cards')
        }
      }
    }
  }

  return { tableMode, onTableResize, onListResize, getMaxTableHeight, isDropdownSearch }
}
