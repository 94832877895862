import { ALL_PLATFORMS_LANGUAGES, LANGUAGES_FULL_NAME } from '@aidsupply/components'
import { PROVIDERS } from '../data'

export const FILTERS_CONFIG = {
  brand: {
    key: 'brands',
    apiKey: 'brand_id',
    label: {
      uk: 'Бренд',
      ru: 'Бренд',
      en: 'Brand',
    },
    type: 'checkboxList',
  },
  carrier: {
    key: 'carriers',
    apiKey: 'general.carrier.id',
    label: {
      uk: 'Перевізник',
      ru: 'Перевозчик',
      en: 'Carrier',
    },
    type: 'checkboxList',
  },
  category: {
    key: 'categories',
    apiKey: 'category_id',
    label: {
      uk: 'Категорія',
      ru: 'Категория',
      en: 'Category',
    },
    type: 'checkboxList',
  },
  client: {
    key: 'organizations',
    apiKey: 'general.client.id',
    label: {
      uk: 'Клієнт',
      ru: 'Клиент',
      en: 'Client',
    },
    type: 'checkboxList',
  },
  organization: {
    key: 'organizations',
    apiKey: 'organization_id',
    label: {
      uk: 'Організація',
      ru: 'Организация',
      en: 'Organization',
    },
    type: 'checkboxList',
  },
  country: {
    key: 'countries',
    apiKey: 'country_id',
    label: {
      uk: 'Країна',
      ru: 'Страна',
      en: 'Country',
    },
    type: 'checkboxList',
  },
  // customer: {
  //   key: 'organizations',
  //   apiKey: 'general..id',
  //   label: {
  //     uk: 'Покупець',
  //     ru: 'Покупатель',
  //     en: 'Customer',
  //   },
  //   type: 'checkboxList',
  // },
  // delivery: {
  //   key: 'deliveries',
  //   label: {
  //     uk: 'Оператори доставки',
  //     ru: 'Операторы доставки',
  //     en: 'Delivery operators',
  //   },
  //   type: 'checkboxList',
  // },
  inquirer_oid: {
    key: 'organizations',
    apiKey: 'inquirer_oid',
    label: { uk: 'Запитувач', ru: 'Запрашивающая организация', en: 'Inquirer' },
    type: 'checkboxList',
  },
  language: {
    type: 'checkboxList',
    key: '',
    apiKey: 'general.language',
    options: ALL_PLATFORMS_LANGUAGES.map((lng) => ({ id: lng, label: LANGUAGES_FULL_NAME[lng] })),
    label: {
      uk: 'Мова',
      en: 'Language',
      ru: 'Язык',
    },
  },
  logist_oid: {
    key: 'carriers',
    apiKey: 'logist_oid',
    label: {
      uk: 'Перевізник',
      ru: 'Перевозчик',
      en: 'Carrier',
    },
    type: 'checkboxList',
  },
  magnitude: {
    key: 'magnitudes',
    type: 'checkboxList',
    apiKey: 'magnitude',
    label: {
      en: 'Magnitude',
      ru: 'Величина',
      uk: 'Величина',
    },
  },
  merchant: {
    key: 'organizations',
    type: 'checkboxList',
    apiKey: 'general.merchant.id',
    label: {
      uk: 'Продавець',
      ru: 'Продавец',
      en: 'Merchant',
    },
  },
  platform: {
    key: 'platforms',
    apiKey: 'general.platform.id',
    label: {
      uk: 'Платформа',
      ru: 'Платформа',
      en: 'Platform',
    },
    type: 'checkboxList',
  },
  platforms: {
    key: 'platforms',
    apiKey: 'general.platforms',
    label: {
      uk: 'Платформа',
      ru: 'Платформа',
      en: 'Platform',
    },
    type: 'checkboxList',
  },
  price: {
    apiKey: 'price',
    label: {
      uk: 'Ціна',
      ru: 'Цена',
      en: 'Price',
    },
    type: 'checkboxList',
  },
  provider: {
    key: '',
    options: PROVIDERS,
    type: 'checkboxList',
    apiKey: 'provider',
    label: {
      uk: 'Провайдер',
      en: 'Provider',
      ru: 'Провайдер',
    },
  },
  recipient: {
    key: 'organizations',
    apiKey: 'recipient_id',
    label: { uk: 'Отримувач', ru: 'Получатель', en: 'Recipient' },
    type: 'checkboxList',
  },
  role: {
    type: 'checkboxList',
    key: 'userRoles',
    apiKey: 'role',
    label: {
      uk: 'Роль',
      en: 'Role',
      ru: 'Роль',
    },
  },
  roles: {
    type: 'checkboxList',
    key: 'organizationRoles',
    apiKey: 'roles',
    label: {
      uk: 'Роль',
      en: 'Role',
      ru: 'Роль',
    },
  },
  state: {
    key: 'states',
    apiKey: 'state',
    label: {
      uk: 'Стан',
      ru: 'Состояние',
      en: 'State',
    },
    type: 'checkboxList',
  },
  status: {
    key: '',
    systemDataKeyMapping: {
      inquiries: 'inquiryStatuses',
      invoices: 'invoiceStatuses',
      shipments: 'shipmentStatuses',
      users: 'orgOrUsersStatuses',
      organizations: 'orgOrUsersStatuses',
      campaigns: 'campaignStatuses',
      orders: 'orderStatuses',
    },
    apiKey: 'status',
    label: {
      uk: 'Статус',
      ru: 'Статус',
      en: 'Status',
    },
    type: 'checkboxList',
  },
  warehouse: {
    key: 'warehouses',
    type: 'checkboxList',
    apiKey: 'general.stock.id',
    label: {
      uk: 'Склад',
      en: 'Warehouse',
      ru: 'Склад',
    },
  },
  type: {
    key: '',
    systemDataKeyMapping: {
      organizations: 'organizationTypes',
      warehouses: 'warehouseTypes',
      pages: 'pageTypes',
      contracts: 'contractTypes',
      inquiries: 'organizationTypes',
    },
    type: 'checkboxList',
    apiKey: 'type',
    label: {
      uk: 'Тип',
      en: 'Type',
      ru: 'Тип',
    },
  },
  attributeType: {
    key: 'attributeTypes',
    apiKey: 'attribute_type',
    label: {
      uk: 'Тип атрибуту',
      ru: 'Тип атрибута',
      en: 'Attribute type',
    },
    type: 'checkboxList',
  },
  organizationType: {
    key: 'organizationTypes',
    apiKey: 'recipient_organization_type',
    label: {
      uk: 'Тип організації',
      ru: 'Тип организации',
      en: 'Organization type',
    },
    type: 'checkboxList',
  },
  region: {
    key: 'country_subdivisions',
    apiKey: 'recipient_region',
    label: {
      uk: 'Регіон',
      ru: 'Регион',
      en: 'Region',
    },
    type: 'checkboxList',
  },
  topic: {
    key: 'topics',
    apiKey: 'topic_id',
    label: {
      uk: 'Тема',
      ru: 'Тема',
      en: 'Topic',
    },
    type: 'checkboxList',
  },
  visibility: {
    key: 'visibilityTypes',
    apiKey: 'visibility',
    label: {
      uk: 'Видимість',
      ru: 'Видимость',
      en: 'Visibility',
    },
    type: 'checkboxList',
  },
}

export const FILTERS_KEY_MAPPINGS = {
  'general.client.id': 'client',
  'general.customer.id': 'customer',
  'general.merchant.id': 'merchant',
  'general.platforms': 'platforms',
  'general.platform.id': 'platform',
  'general.language': 'language',
  'general.stock.id': 'stock',
  'inquirer_oid.id': 'inquirer_oid',
  'logist_oid.id': 'logist_oid',
  attribute_type: 'attributeType',
  brand_id: 'brand',
  category_id: 'category',
  country_id: 'country',
  organization_id: 'organization',
  recipient_id: 'recipient',
  recipient_organization_type: 'organizationType',
  recipient_region: 'region',
  topic_id: 'topic',
  visibility: 'visibility',
}
