import React from 'react'

import { Input } from '@aidsupply/components'

import ReadOnlyInfo from '../RightPanel/ReadOnlyInfo'

const FormInput = (props) => {
  const {
    field,
    formSubmit,
    isDisabled,
    isHidden,
    isSubmitOnBlur,
    label,
    multiline,
    name,
    submitByEnterPressed,
    t,
    updateInput,
    value,
    validateField,
  } = props

  if (field.readOnly) {
    return <ReadOnlyInfo label={label} value={value} />
  }

  const onChangeInput = (e) => {
    if (field.onChange) {
      validateField(field.key, e.target.value)
      field.onChange(e)
    } else {
      updateInput(e.target ? e : { target: { name, value: e } }, field.onInputValueChange)
    }
  }

  return (
    <>
      <Input
        {...props}
        type={field.type}
        // onBlur={onValidateField(field.key)}
        onBlur={isSubmitOnBlur && formSubmit}
        onEnter={isSubmitOnBlur && !multiline && formSubmit}
        submitByEnterPressed={submitByEnterPressed}
        onChange={onChangeInput}
        multiline={field.multiline || false}
        disabled={isDisabled}
        min={field.min}
        max={field.max}
        step={field.step}
        value={value || ''}
        primaryFocusColor
        iconRightProps={field.iconRightProps}
        iconLeftProps={field.iconLeftProps}
        variant="secondary"
        toolbarOptions={field.toolbarOptions}
      />
      {field.additionToInput}
      {!isHidden && field.addElementToInput && field.addElementToInput(updateInput, t, field.disabled)}
    </>
  )
}

export default FormInput
