import React from 'react'
import { useNavigate } from 'react-router-dom'
import { object } from 'dot-object'
import { useDispatch } from 'react-redux'

import { isObject } from '@aidsupply/components'

import EditForm from '../../../components/RightPanelForms'
import { useMappedState } from '../../../hooks'
import { selectSidebarInitialData } from '../../../redux/selectors'
import { sidebarUpsertUser } from '../../../redux/actions'

const UsersRightPanel = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const userInitial = useMappedState(selectSidebarInitialData)

  const isReadOnly = false

  const onFormSubmit = (formValuesChanged) => {
    const requestBody = { ...object(formValuesChanged) }

    Object.keys(requestBody).map((key) => {
      if (isObject(requestBody[key]) && requestBody[key].id) {
        requestBody[key] = requestBody[key].id
      }
    })

    if (formValuesChanged.role?.id === userInitial.role?.id) {
      delete requestBody.role
    }

    if (!userInitial.id && !requestBody.state) {
      requestBody.state = 'posted'
    }

    const dataToSend = {
      id: userInitial.id,
      requestBody,
      type: 'users',
      parentType: 'admin',
    }

    dispatch(sidebarUpsertUser(dataToSend, navigate))
  }

  return <EditForm isReadOnly={isReadOnly} onFormSubmit={onFormSubmit} />
}

export default UsersRightPanel
