import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { withTheme } from 'styled-components'

import { Button, Icon } from '@aidsupply/components'

import Form from '../../components/Form'
import CustomPasswordChecklist from '../../components/IntroScreens/components/CustomPasswordChecklist'
import { FORM_CONFIGS } from '../../config/forms'
import { useMappedState } from '../../hooks'
import { useIsPasswordShown } from '../../hooks/useIsPasswordShown'
import { userSignUp } from '../../redux/actions'
import { selectUserInProgressAndError } from '../../redux/selectors'

const FormCreate = ({ initialValues, theme }) => {
  const [password, setPassword] = useState('')
  const [repeat_password, setRepeatPassword] = useState('')
  const [isPasswordValid, setIsPasswordValid] = useState(undefined)
  const [isSubmitPressed, setIsSubmitPressed] = useState(false)

  const { t } = useTranslation(['signIn', 'validation'])
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { inProgress, error } = useMappedState(selectUserInProgressAndError)

  const onFormSubmit = (credentials) => {
    if (password === repeat_password) {
      dispatch(userSignUp(credentials, navigate))
    }
  }

  return (
    <Form
      className="form"
      type="signUp"
      withCustomValidationRule
      initialValues={initialValues}
      serverError={error !== false}
      onSubmit={onFormSubmit}
      validationRules={{
        full_name: ['required'],
        email: ['required', 'email'],
        password: ['required'],
        repeat_password: ['required'],
      }}
      fields={[
        FORM_CONFIGS.signup.fields[0],
        FORM_CONFIGS.signup.fields[1],
        FORM_CONFIGS.signup.fields[2],
        {
          ...FORM_CONFIGS.signup.fields[3],
          ...useIsPasswordShown(),
          onInputValueChange: (value) => setPassword(value),
        },
        {
          ...FORM_CONFIGS.signup.fields[4],
          ...useIsPasswordShown(),
          onInputValueChange: (value) => setRepeatPassword(value),
          error: isSubmitPressed && password !== repeat_password ? t('validation:passwordsNotMatch') : '',
          className: 'repeatPassword',
        },
      ]}
    >
      <CustomPasswordChecklist
        password={password}
        setIsPasswordValid={setIsPasswordValid}
        className={isSubmitPressed && !isPasswordValid ? 'passError' : ''}
      />
      <Button
        fullWidth
        variant="primary"
        type="submit"
        uppercase={false}
        onClick={() => setIsSubmitPressed(true)}
        disabled={inProgress}
      >
        <Icon
          name="statusCompleted"
          size={20}
          stroke={theme.color.general.light}
          fill={theme.color.primary.main}
        />
        &nbsp;{t('signUp')}
      </Button>
    </Form>
  )
}

export default withTheme(FormCreate)
