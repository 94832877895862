import React from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'

import GridLayout from '../../Layout/GridLayout'
import TableBlock from '../../../components/Table/TableBlock'
import BrandsTableCard from './BrandsTableCard'

const Brands = () => {
  const { t } = useTranslation()

  return (
    <GridLayout RightPanelComponent={<Outlet context={{}} />}>
      <TableBlock
        isMainTable
        TableCardContent={BrandsTableCard}
        tableCardHeight={86}
        // isReadOnly={isReadOnly}
      />
    </GridLayout>
  )
}

export default Brands
