import React from 'react'
import dayjs from 'dayjs'
import 'dayjs/locale/uk'
import 'dayjs/locale/ru'
import { InfoRowsBlock } from '@aidsupply/components'

import { useTranslation } from 'react-i18next'
import { capitalizeFirstLetter } from '../../../utils/general'

const ShipmentsTableCard = ({ className, data, initialData }) => {
  const { state, warehouse_id, recipient_id, supplier_id, status } = data

  const {
    i18n: { language: lng },
    t,
  } = useTranslation('forms')

  const formattedDate = capitalizeFirstLetter(dayjs(initialData.created_at).locale(lng).format('MMM D, YYYY'))
  const name = initialData.number + ' ' + t('from') + ' ' + formattedDate

  return (
    <InfoRowsBlock
      className={className}
      data={[
        {
          state: {
            customElement: state,
            width: '30px',
            hideBorder: true,
            padding: '20px 0 20px 10px',
          },
          number: {
            customElement: name,
            width: 'calc(100% - 61px)',
            hideBorder: true,
            padding: '0',
          },
          status: {
            customElement: status,
            width: '30px',
            hideBorder: true,
            padding: '5px',
          },
        },
        {
          supplier: {
            iconProps: {
              name: 'hospital',
              stroke: 'none',
            },
            text: supplier_id,
            width: '50%',
            // hideBorder: !recipient_city,
            padding: '11px',
          },
          warehouse: {
            text: warehouse_id,
            width: '50%',
            padding: '9px',
            iconProps: { name: 'warehouse' },
          },
        },
        {
          recipient_name: {
            iconProps: {
              name: 'home',
            },
            text: recipient_id,
            width: 'calc(100% - 36px)',
            hideBorder: true,
            padding: '13px 11px',
          },
        },
      ]}
    />
  )
}

export default ShipmentsTableCard
