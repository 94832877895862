import styled from 'styled-components'
import { Input, UiLink } from '@aidsupply/components'

export const StyledOrderWrapper = styled.div`
  .activity {
    margin-bottom: 30px;
  }
`

export const StyledComments = styled.div`
  ${({ containerStyles }) => containerStyles}
`

export const StyledInput = styled(Input)`
  margin-right: 10px;
  width: 100%;

  .icon {
    margin-right: 7px;
  }
`

export const StyledOrderButtons = styled.div`
    display: flex;
    padding-top: 20px;
    margin-top: 20px;
    border-top: 1px solid ${({ theme }) => theme.color.general.gray1};

    button:first-child {
        margin-right: 10px;
    }

    && button {
        justify-content: flex-start;
        width: 100%;
        padding: 3px 14px;
        font-size: 13px;
        white-space: normal;
        min-width: initial;
    }

    .icon {
        margin: 0;

        path {
            stroke: ${({ theme }) => theme.color.general.light};
        }
    }
`

export const StyledLink = styled(UiLink)`
  margin-bottom: 20px;

  a {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  svg {
    margin-top: 3px;
  }
`

export const StyledContainer = styled.div`
  ${({ inputStyles }) => inputStyles}
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .button {
    min-height: 65px;
    padding: 10px;
  }
`
