import React, { useEffect, useState } from 'react'

import { getAvailableTranslation, Select } from '@aidsupply/components'

import { getSelectOptions, getSelectValue } from '../../utils'
import ReadOnlyInfo from '../RightPanel/ReadOnlyInfo'
import { DEFAULT_LANGUAGE } from '../../locales'

const FormSelect = (props) => {
  const {
    field,
    formSubmit,
    initialValue,
    isDisabled,
    isHidden,
    isSubmitOnBlur,
    label,
    labelKey,
    lng,
    name,
    optionsData,
    optionsFromFormValues,
    t,
    updateSelect,
    value,
    optionsArray,
    validateField,
  } = props

  const [options, setOptions] = useState([])

  const fieldLabelKey = field.noTranslation
    ? field.labelKey || labelKey
    : `${field.labelKey || labelKey}.${lng}`

  const getOneOfTranslations = (option) => {
    return getAvailableTranslation(option?.translations, DEFAULT_LANGUAGE, lng)
  }

  useEffect(() => {
    const filteredOptions = (
      optionsFromFormValues ||
      optionsArray ||
      getSelectOptions(field, optionsData, lng)
    )?.filter((option) => option.state !== 'deleted')
    setOptions(filteredOptions)
  }, [field, lng, optionsData, optionsArray, optionsFromFormValues])

  const val = getSelectValue(value, options, field) //TODO: make it defaultValue of select
  if (field.readOnly) {
    return <ReadOnlyInfo labelKey={fieldLabelKey || 'label'} label={label} value={val} />
  }

  const onCreateOption = (newValue) => {
    if (field.isCreatable && !field.getOptionsFromFormValues) {
      const newOptions = field.isMulti ? [...value, newValue] : [newValue]
      setOptions(newOptions)
    }

    updateSelect(name, field.onSelectValueChange)(field.isMulti ? [...val, newValue] : newValue)

    if (isSubmitOnBlur && formSubmit) {
      formSubmit({}, { [name]: field.isMulti ? [...val, newValue] : newValue })
    }
  }

  const handleChange = (newValue) => {
    if (field.onChange) {
      validateField(name, newValue)
      field.onChange(newValue)
    } else {
      updateSelect(name, field.onSelectValueChange)(newValue)
    }

    if (isSubmitOnBlur && formSubmit) {
      formSubmit({}, { [name]: newValue })
    }
  }

  return (
    <>
      <Select
        {...props}
        menuIsOpen={field.menuIsOpen}
        value={val}
        onChange={handleChange}
        onCreateOption={onCreateOption}
        createLabelText={t('create')}
        isDisabled={isDisabled || (field.disabledWhenNotEmpty && initialValue !== '') || !options}
        autosize={field.autosize}
        isMulti={field.isMulti || false}
        isCreatable={field.isCreatable || false}
        isClearable={typeof field.isClearable === 'undefined' && field.isMulti ? true : field.isClearable}
        noOptionsMessage={t('noOptionsFound')}
        getOptionBeforeTextComponent={field.getOptionBeforeTextComponent}
        customGetOptionLabel={
          field.customGetOptionLabel || (['translations'].includes(field.labelKey) && getOneOfTranslations)
        }
        labelKey={fieldLabelKey || 'label'}
        valueKey={field.valueKey || 'id'}
        options={options}
        t={field.customGetOptionLabel && t}
        lng={field.customGetOptionLabel && lng}
      />
      {!isHidden && field.addElementToSelect && field.addElementToSelect(updateSelect, t, field.disabled)}
    </>
  )
}

export default FormSelect

FormSelect.defaultProps = {
  labelKey: 'label',
}
