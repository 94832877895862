import React from 'react'
// import { pick } from 'dot-object'
import cloneDeep from 'lodash.clonedeep'
import { slugify } from 'transliteration'

import { CategoryCharacteristicsToChoose, IconsList, isObjectEmpty } from '@aidsupply/components'

// import { getOptionsBasedOnCategory } from '../utils/forms'
// import { CONTENT_UNITS_OPTIONS } from '../data'

export const EDITABLE_TABLE_NAVBAR_FORM_CONFIG = {
  'enumerations.options': {
    withTabs: ['translations'],
    defaultTabsValues: {
      options: 'en',
    },
    isLanguageTabs: true,
    validationRules: {
      name: [
        'required',
        {
          type: 'keyInListAbsent',
          customRuleName: 'nameAlreadyExists',
          dataForValidation: {
            searchKey: 'slug',
            transformValue: (value) => slugify(value),
          },
        },
      ],
    },
    createNewOptions: true,
    changingBlocksDependency: 'tableOptionType',
    changingBlocks: {
      none: [],
      icon: [
        {
          key: 'icon',
          placeholder: 'Icon',
          isClearable: true,
          isSearchable: true,
          optionIconKey: 'id',
          optionIconColor: 'black',
          className: 'iconSelect',
          noTranslation: true,
          options: IconsList.map((icon) => ({
            id: icon,
            label: icon,
          })),
          fullWidth: true,
          component: 'dropdown',
        },
      ],
      hex_color: [
        {
          key: 'hex_color',
          placeholder: 'Hex Color Code',
        },
      ],
      // icon_image: [
      //   {
      //     key: 'iconImage',
      //     component: ({ value, optionsData, sidebarFormId }) => {
      //       const { options, slug } = optionsData.enumerations[sidebarFormId] || {}
      //
      //       return (
      //         <UploadImages
      //           entityType="enumerations"
      //           isSimpleButton
      //           // fileGroup={slug}
      //           initialData={options}
      //           id={slug}
      //         />
      //       )
      //     },
      //   },
      // ],
    },
    fields: [
      {
        key: 'name',
        placeholder: 'systemName',
        isRequired: true,
        onInputValueChange: (value, setFormValues) => {
          setFormValues((prev) => ({
            ...prev,
            slug: slugify(value),
            translations: { en: value },
          }))
        },
      },
    ],
  },
  // 'items.content': {
  //   withTabs: [],
  //   validationRules: {},
  //   optionsFromValuesDependencies: ['general.category'],
  //   getBlockValuesByCustomRule: (formValues, optionsData) => {
  //     const category =
  //       formValues?.general?.category && optionsData.categories?.[formValues.general.category.id]
  //     return category && pick('general.content', category)
  //   },
  //   createNewOptions: false,
  //   fields: [
  //     {
  //       key: 'enumerationOption',
  //       dataKey: 'translations.label',
  //       placeholder: 'Name',
  //       getOptionsFromFormValues: (formValues, optionsData, mainFormData, additionalOptions) => {
  //         const tableIds = mainFormData.map((row) => row.id)
  //         const formValuesForOptions = additionalOptions || {}
  //
  //         return getOptionsBasedOnCategory(
  //           formValuesForOptions,
  //           optionsData,
  //           'general.content',
  //           'enumerations',
  //           'options'
  //         )?.filter((option) => !tableIds.includes(option.id))
  //       },
  //       isRequired: true,
  //       isObjectKeyToBeSpread: true,
  //       isSearchable: true,
  //       autosize: true,
  //       labelKey: 'translations.label',
  //       search: true,
  //       selection: true,
  //       component: 'dropdown',
  //       width: '30%',
  //     },
  //     { key: 'amount', placeholder: 'Amount', width: '18%' },
  //     {
  //       key: 'unit',
  //       dataKey: 'unit.label',
  //       options: CONTENT_UNITS_OPTIONS,
  //       autosize: true,
  //       labelKey: 'label',
  //       component: 'dropdown',
  //       width: '18%',
  //       defaultValue: CONTENT_UNITS_OPTIONS[0],
  //     },
  //   ],
  // },
  'orders.order_items': {
    // withTabs: [],
    validationRules: { item: ['required'] },
    createNewOptions: false,
    tableRowKey: 'id',
    // isDeletionByState: true,
    // tableRowKey: 'stockItemId',
    optionsFromValuesDependencies: ['currency_id', 'state'],
    transformAddedResult: (resultObj, additionalFormValues) => {
      if (!resultObj || isObjectEmpty(resultObj)) {
        return
      }
      // console.log(resultObj)
      // const { currency, price, categoryId, stockItemId, warehouse, value, key } =
      //   resultObj?.characteristics || {}

      return {
        brand_id: resultObj.brand?.id || resultObj.item.brand_id,
        item_id: resultObj.item.id,
        currency_id: additionalFormValues.currency_id,
        state: additionalFormValues.state,
        quantity: 1,
        // characteristics: { key, value },
        // price,
        // warehouse,
        // stockItemId,
        // category: { id: categoryId },
      }
    },
    fields: [
      {
        key: 'brand',
        optionsKeys: ['brands'],
        placeholder: 'brand',
        noTranslation: true,
        component: 'dropdown',
        labelKey: 'name',
        fullWidth: true,
        isClearable: true,
        className: 'fullWidth',
        validationTranslationKeys: ['brands', 'items'], // 'characteristics],
        onSelectValueChange: (value, setFormValues) => {
          setFormValues((prev) => ({ ...prev, item: '' }))
        },
        getOptionsFromFormValues: (formValues, optionsData) => {
          return optionsData.brands && Object.values(optionsData.brands)
        }, // TODO: understand why this unnecessary "getOptionsFromFormValues" fixes validation bug
      },
      {
        key: 'item',
        // noTranslation: true,
        placeholder: 'item',
        component: 'dropdown',
        labelKey: 'translations',
        isRequired: true,
        fullWidth: true,
        className: 'fullWidth lastRow',
        validationTranslationKeys: ['brands', 'items'], // 'characteristics'],
        // onSelectValueChange: (value, setFormValues) => {
        //   setFormValues((prev) => ({ ...prev, characteristics: '' }))
        // },
        getOptionsFromFormValues: (formValues, optionsData) => {
          const allItems = optionsData.items && Object.values(optionsData.items)
          return formValues.brand
            ? allItems.filter((item) => item.brand_id === formValues.brand.id)
            : allItems
        },
      },
      // {
      //   key: 'characteristics',
      //   noTranslation: true,
      //   component: 'dropdown',
      //   labelKey: 'value',
      //   isRequired: true,
      //   autosize: true,
      //   validationTranslationKeys: ['brands', 'items', 'characteristics'],
      //   getOptionsFromFormValues: (formValues, optionsData) => {
      //     const allMods = optionsData.modifications && Object.values(optionsData.modifications)
      //     const stockItems = optionsData['stock-items'] && Object.values(optionsData['stock-items'])
      //
      //     return formValues.item
      //       ? allMods
      //           .filter(modification => {
      //             const stockItem = stockItems?.find(stockItem => stockItem.item_mod_id === modification.id)
      //
      //             return (
      //               modification.state === 'posted' &&
      //               formValues.item.id === modification.itemId &&
      //               stockItem?.quantity
      //             )
      //           })
      //           .map(mod => {
      //             const stockItem = stockItems.find(stockItem => stockItem.item_mod_id === mod.id)
      //             const stockInfo = stockItem
      //               ? {
      //                   price:
      //                     stockItem.price || //priceSale
      //                     (stockItem.discount
      //                       ? stockItem.price_retail * (1 - stockItem.discount / 100)
      //                       : stockItem.price_retail),
      //                   stock: { id: stockItem.warehouse_id },
      //                   currency: {
      //                     id: optionsData.warehouses?.[stockItem.warehouse_id].currency.id,
      //                   },
      //                   stockItemId: stockItem.id,
      //                 }
      //               : {}
      //
      //             return { ...mod, ...stockInfo }
      //           })
      //       : null
      //   },
      // },
    ],
  },
  'inquiries.inquiry_items': {
    // withTabs: [],
    validationRules: {},
    createNewOptions: false,
    tableRowKey: 'id',
    isDeletionByState: true,
    transformAddedResult: (resultObj) => {
      if (!resultObj || isObjectEmpty(resultObj)) {
        return
      }
      const categoryId = resultObj.category.id
      const characteristics = cloneDeep(resultObj)
      delete characteristics.category

      return {
        category_id: categoryId,
        characteristics_keys: Object.keys(characteristics),
        characteristics_values: Object.values(characteristics),
        characteristics,
        quantity: 1,
      }
    },
    fields: [
      {
        key: 'category',
        optionsKeys: ['categories'],
        component: 'dropdown',
        isRequired: true,
        labelKey: 'translations',
        placeholder: 'category',
        className: 'fullWidth',
        getOptionsFromFormValues: (formValues, optionsData) =>
          optionsData.categories
            ? Object.values(optionsData.categories).filter((cat) => cat.is_used_in_inquiries)
            : [],
      },
      {
        key: 'characteristics',
        className: 'lastRow characteristics',
        getOptionsFromFormValues: (formValues, optionsData, mainTableData) => {
          return {
            navbarFormValues: formValues,
            mainTableData,
          }
        },
        component: ({ optionsFromFormValues, optionsData, lng, t, updateInput }) => {
          const { navbarFormValues, mainTableData } = optionsFromFormValues
          // const isCategoryInTableAlready = mainTableData.some(
          //   (row) => row?.category_id === navbarFormValues.category?.id
          // )

          return (
            <CategoryCharacteristicsToChoose
              className="inquiriesDemandsTable"
              attributes={optionsData.attributes}
              enumerations={optionsData.enumerations}
              category={navbarFormValues?.category}
              onAttributeChoose={(key, value) => {
                updateInput({
                  target: {
                    name: key,
                    value,
                  },
                })
              }}
              lng={lng}
              t={t}
            />
          )
        },
      },
    ],
  },
  'invoices.order_items': {
    // withTabs: [],
    validationRules: {},
    createNewOptions: false,
    tableRowKey: 'stock_id',
    optionsFromValuesDependencies: ['currency_id'],
    fields: [],
  },
  'shipments.shipment_items': {
    validationRules: { item: ['required'] },
    createNewOptions: false,
    tableRowKey: 'id',
    // isDeletionByState: true,
    // tableRowKey: 'stockItemId',
    optionsFromValuesDependencies: ['currency_id', 'order_id'],
    transformAddedResult: (resultObj, additionalFormValues) => {
      if (!resultObj || isObjectEmpty(resultObj)) {
        return
      }

      // const { currency, price, categoryId, stockItemId, warehouse, value, key } =
      //   resultObj?.characteristics || {}
      console.log(resultObj)
      return {
        brand_id: resultObj.brand?.id || resultObj.item.brand_id,
        item_id: resultObj.item.id,
        currency_id: additionalFormValues.currency_id,
        quantity: 1,
        // price,
        // warehouse,
        // stockItemId,
        // category: { id: categoryId },
      }
    },
    fields: [
      {
        key: 'brand',
        optionsKeys: ['brands'],
        placeholder: 'brand',
        noTranslation: true,
        component: 'dropdown',
        labelKey: 'name',
        fullWidth: true,
        isClearable: true,
        className: 'fullWidth',
        validationTranslationKeys: ['brands', 'items'], // 'characteristics],
        onSelectValueChange: (value, setFormValues) => {
          setFormValues((prev) => ({ ...prev, item: '' }))
        },
        getOptionsFromFormValues: (formValues, optionsData) => {
          return optionsData.brands && Object.values(optionsData.brands)
        }, // TODO: understand why this unnecessary "getOptionsFromFormValues" fixes validation bug
      },
      {
        key: 'item',
        placeholder: 'item',
        component: 'dropdown',
        labelKey: 'translations',
        isRequired: true,
        fullWidth: true,
        className: 'fullWidth',
        validationTranslationKeys: ['brands', 'items'], // 'characteristics'],
        // onSelectValueChange: (value, setFormValues) => {
        //   setFormValues((prev) => ({ ...prev, characteristics: '' }))
        // },
        getOptionsFromFormValues: (formValues, optionsData) => {
          const allItems = optionsData.items && Object.values(optionsData.items)
          return formValues.brand
            ? allItems.filter((item) => item.brand_id === formValues.brand.id)
            : allItems
        },
      },
    ],
  },
  'items.modifications': {
    // withTabs: [],
    validationRules: {},
    createNewOptions: false,
    getBlockValuesByCustomRule: (formValues, optionsData) => {
      const itemId = formValues.id
      const allMods = optionsData.modifications && Object.values(optionsData.modifications)
      return allMods?.filter((mod) => mod?.itemId === itemId)
    },
  },
  topics: {
    withTabs: ['translations'],
    defaultTabsValues: {
      topics: 'en',
    },
    isLanguageTabs: true,
    validationRules: {
      name: ['required'],
    },
    createNewOptions: true,
    fields: [
      {
        key: 'name',
        placeholder: 'newTopic',
        isRequired: true,
        onInputValueChange: (value, setFormValues) => {
          setFormValues((prev) => ({
            ...prev,
            name: value,
            translations: { en: value },
          }))
        },
      },
    ],
  },
  'inventories.inventory_items': {
    validationRules: { item: ['required'] },
    createNewOptions: false,
    tableRowKey: 'id',
    optionsFromValuesDependencies: ['currency_id', 'state'],
    transformAddedResult: (resultObj, additionalFormValues) => {
      if (!resultObj || isObjectEmpty(resultObj)) {
        return
      }
      // const { currency, price, categoryId, stockItemId, warehouse, value, key } =
      //   resultObj?.characteristics || {}
      return {
        brand_id: resultObj.brand?.id || resultObj.item.brand_id,
        item_id: resultObj.item.id,
        currency_id: additionalFormValues.currency_id,
        state: additionalFormValues.state,
        quantity: 1,
        // state: resultObj.state,
        modification_id: resultObj.modification_id,
        //characteristics: { key, value },
        // price,
        // warehouse,
        // stockItemId,
        // category: { id: categoryId },
      }
    },
    fields: [
      {
        key: 'brand',
        optionsKeys: ['brands'],
        placeholder: 'brand',
        noTranslation: true,
        component: 'dropdown',
        labelKey: 'name',
        fullWidth: true,
        isClearable: true,
        className: 'fullWidth',
        validationTranslationKeys: ['brands', 'items'], // 'characteristics],
        onSelectValueChange: (value, setFormValues) => {
          setFormValues((prev) => ({ ...prev, item: '' }))
        },
        getOptionsFromFormValues: (formValues, optionsData) => {
          return optionsData.brands && Object.values(optionsData.brands)
        },
      },
      {
        key: 'item',
        placeholder: 'item',
        component: 'dropdown',
        labelKey: 'translations',
        isRequired: true,
        fullWidth: true,
        className: 'fullWidth',
        validationTranslationKeys: ['brands', 'items'],
        getOptionsFromFormValues: (formValues, optionsData) => {
          const allItems = optionsData.items && Object.values(optionsData.items)
          return formValues.brand
            ? allItems.filter((item) => item.brand_id === formValues.brand.id)
            : allItems
        },
      },
    ],
  },
}

export const EDITABLE_TABLE_CHANGING_BLOCKS_OPTIONS = {
  'enumerations.options': {
    getDefaultValue: (data, options) => {
      if (!data?.length) {
        return
      }
      if (data.some((item) => !!item.icon)) {
        return options[1]
      }
      if (data.some((item) => !!item.hex_color)) {
        return options[2]
      }
    },
    options: [
      {
        id: 'none',
        label: {
          en: 'Name only',
          ru: 'Только название',
          uk: 'Тільки назва',
        },
      },
      {
        id: 'select_icon',
        label: {
          en: 'Icon and Name',
          ru: 'Иконка и название',
          uk: 'Іконка та назва',
        },
      },
      {
        id: 'hex',
        label: {
          en: 'Color and Name',
          ru: 'Цвет и название',
          uk: 'Колір і назва',
        },
      },
      // {
      //   id: 'upload_icon',
      //   label: {
      //     en: 'Upload image for icon',
      //     ru: 'Загрузить изображение для иконки',
      //     uk: 'Завантажити зображення для іконки',
      //   },
      // },
    ],
  },
}
