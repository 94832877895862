import styled from 'styled-components'

export const StyledEmptyScreen = styled.div`
  height: calc(100% - 5vh);
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  white-space: pre-wrap;
  padding: 0 15px;
  text-align: center;

  .labelTop {
    margin-bottom: 10px;
    text-align: left;
  }

  .inline {
    display: inline;
  }

  button {
    margin-top: 20px;
    min-width: 200px;
    &.confirmState {
      width: auto;
    }
  }

  .typography {
    max-width: 430px;
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    .body2,
    .body3 {
      color: ${({ theme }) => theme.color.general.dark};
    }
    .link a {
      color: ${({ theme }) => theme.color.secondary.dark};
    }
  }
`
