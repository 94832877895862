import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { object } from 'dot-object'
import merge from 'deepmerge'
import { slugify } from 'transliteration'

import EditForm from '../../../components/RightPanelForms'
import { isObject } from '@aidsupply/components'
import { sidebarUpsert } from '../../../redux/actions'
import { useMappedState } from '../../../hooks'
import { selectSidebarInitialData } from '../../../redux/selectors'

const PagesRightPanel = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const dataInitial = useMappedState(selectSidebarInitialData)

  const onFormSubmit = (formValuesChanged) => {
    const requestBody = { ...object(formValuesChanged) }

    Object.keys(requestBody).map((key) => {
      if (isObject(requestBody[key]) && requestBody[key].id) {
        requestBody[key] = requestBody[key].id
      }
    })

    // if (!dataInitial.id && !requestBody.state) {
    //   requestBody.state = 'posted'
    // }

    if (formValuesChanged.translations) {
      requestBody.translations = merge(dataInitial.translations, requestBody.translations)
      if (formValuesChanged.translations.en) {
        requestBody.name = formValuesChanged.translations.en
      }
    }
    if (requestBody.body) requestBody.body = merge(dataInitial.body, requestBody.body)

    const dataToSend = {
      id: dataInitial.id,
      requestBody,
      type: 'pages',
      parentType: 'platform',
    }

    dispatch(sidebarUpsert(dataToSend, navigate))
  }

  return <EditForm onFormSubmit={onFormSubmit} />
}

export default PagesRightPanel
