import React from 'react'
import { withTheme } from 'styled-components'

import { FlexRow, HeaderMenuIcon } from '@aidsupply/components'

import { StyledHeaderMobile } from './styled'
import NavMenu from '../NavMenu'
import MenuItem from '../NavMenu/MenuItem'
import Logo from '../../atomic-components/Logo'
import ErrorBoundary from '../../ErrorBoundary'
import { toggleNavMenuExtended } from '../../redux/actions'
import { useDispatch } from 'react-redux'
import { useMappedState } from '../../hooks'
import { selectIsNavMenuExtended } from '../../redux/selectors'

const HeaderMobile = ({ isTablet, isRevert, theme }) => {
  const dispatch = useDispatch()
  const isNavMenuExtended = useMappedState(selectIsNavMenuExtended)
  const onIconClick = () => {
    dispatch(toggleNavMenuExtended())
  }

  return (
    <>
      <StyledHeaderMobile className={isNavMenuExtended && 'extended'}>
        <FlexRow justifyContent="space-between">
          <Logo
            theme={theme}
            variant="small"
            isExtended
            hasMiniLogo
            logoColor={theme.color.general.dark}
            to="/"
            className="headerMobileLogo"
          />
          <MenuItem iconName="chats" to="/chats" />
          <HeaderMenuIcon
            wrapperColor={theme.color.general.gray1}
            className="menuIcon"
            opened={isNavMenuExtended}
            onClick={onIconClick}
            fill={theme.color.general.dark}
          />
        </FlexRow>
      </StyledHeaderMobile>
      <ErrorBoundary>
        <NavMenu isMobile isTablet={isTablet} isRevert={isRevert} onClick={onIconClick} />
      </ErrorBoundary>
    </>
  )
}

export default withTheme(HeaderMobile)
