import clsx from 'clsx'
import merge from 'deepmerge'
import dot from 'dot-object'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, ScreenContext, isObjectEmpty, usePrevious, validate } from '@aidsupply/components'

import { DEFAULT_VALUES_DATA } from '../../data/defaultValues'
import FormBlock from './FormBlock'
import { DEFAULT_TABS } from './FormBlockTabs'
import { StyledForm, StyledFormButtons } from './styled'
import { useChangingBlocks } from './useChangingBlocks'
import useForm from './useForm'
import { useParams } from 'react-router-dom'

const Form = ({
  id,
  buttonsAreSticky,
  children,
  className,
  fields: customFields,
  formConfig,
  formErrors: customFormErrors,
  formValues: customFormValues,
  FormWrapper,
  getBeforeFormChildren,
  getFormButtons,
  initialValues: initialValuesBeforeCheck,
  initialValuesChanged: initialValuesChangedBeforeCheck,
  inProgress,
  isDraft: customIsDraft,
  isReadOnly,
  isSubmitOnBlur,
  labelKey,
  labelType,
  optionsData,
  onSubmit,
  resetForm,
  serverError,
  setFormErrors: customSetFormErrors,
  setIsDraft: customSetIsDraft,
  submitByEnterPressed,
  type,
  updateInputValue: customUpdateInputValue,
  updateSelectValue: customUpdateSelectValue,
  validationRules: validationRulesInitial = {},
  valuesChanged: customValuesChanged,
  setValuesChanged: customSetValuesChanged,
  // withBlocks,
  withActions,
  headerType,
  expansionPanelProps,
}) => {
  const params = useParams()
  const { rightPanelId } = params
  const isCopy = rightPanelId === 'copy'
  const { currentBreakpoint } = useContext(ScreenContext) || {}
  const { t } = useTranslation(['forms', 'validation'])
  const [touchedFields, setTouchedFields] = useState([])
  const [isSubmitPressed, setIsSubmitPressed] = useState(false)

  // const formConfig = customFormConfig || FORM_CONFIGS[type]
  const changingBlocksDependency = formConfig?.changingBlocksDependency
  const withTabs = formConfig?.withTabs || Object.keys(DEFAULT_TABS)
  const prevId = usePrevious(id)

  const initialValuesToCheckChangingBlock = initialValuesChangedBeforeCheck || initialValuesBeforeCheck
  const initialChangingBlocksDependencyValue =
    changingBlocksDependency &&
    initialValuesToCheckChangingBlock &&
    (dot.pick(`${changingBlocksDependency}.id`, initialValuesToCheckChangingBlock) ||
      dot.pick(changingBlocksDependency, initialValuesToCheckChangingBlock))

  const initialValues = merge.all([
    DEFAULT_VALUES_DATA[initialChangingBlocksDependencyValue || type] || {},
    initialValuesBeforeCheck || {},
    initialValuesChangedBeforeCheck ? dot.object(initialValuesChangedBeforeCheck) : {},
  ])

  const {
    formValues,
    formErrors: errors,
    setFormErrors: setErrors,
    isDraft: formIsDraft,
    setIsDraft: setFormIsDraft,
    updateInputValue,
    updateSelectValue,
    updateCheckboxValue,
    validateField,
    valuesChanged,
    setValuesChanged,
  } = useForm({
    customFormValues,
    initialValues,
    initialValuesChanged: isCopy ? initialValuesBeforeCheck : initialValuesChangedBeforeCheck,
    validationRules: validationRulesInitial,
    optionsData,
    setTouchedFields,
  })

  const formValuesChanged = customValuesChanged || valuesChanged
  const setFormValuesChanged = customSetValuesChanged || setValuesChanged
  const formErrors = customFormErrors || errors
  const setFormErrors = customSetFormErrors || setErrors
  const isDraft = customIsDraft || formIsDraft || isCopy
  const setIsDraft = customSetIsDraft || setFormIsDraft
  const updateInput = customUpdateInputValue || updateInputValue
  const updateSelect = customUpdateSelectValue || updateSelectValue

  useChangingBlocks(
    type,
    changingBlocksDependency,
    initialChangingBlocksDependencyValue,
    initialValues,
    formValuesChanged,
    setFormValuesChanged,
    setFormErrors
  )
  // const formIsEditTableType = DISABLED_ADD_MAIN_TABLE_TYPES.includes(type.split('.')[0])

  const formReset = useCallback(
    (event) => {
      if (event !== undefined) {
        event.preventDefault()
      }
      const initValuesChangedBeforeCheck = isCopy ? initialValuesBeforeCheck : initialValuesChangedBeforeCheck
      setFormValuesChanged(initValuesChangedBeforeCheck ? dot.dot(initValuesChangedBeforeCheck) : {})
      setIsDraft(!!initValuesChangedBeforeCheck)
      setFormErrors({})
      if (resetForm) {
        resetForm()
      }
    },
    [setFormErrors, setIsDraft, setFormValuesChanged]
  )

  useEffect(() => {
    if ((inProgress === false && !serverError) || (prevId !== id && typeof prevId !== 'undefined')) {
      formReset()
    }
  }, [inProgress, serverError, formReset, id, prevId])

  const getFields = () => {
    if (customFields) {
      return customFields
    }
    const commonFields = formConfig?.fields

    const currentDependencyKey =
      changingBlocksDependency &&
      (dot.pick(`${changingBlocksDependency}.id`, formValues) ||
        dot.pick(changingBlocksDependency, formValues))

    const changingBlocksFromConfig =
      currentDependencyKey && formConfig?.changingBlocks?.[currentDependencyKey]

    if (!changingBlocksFromConfig) {
      return commonFields
    }

    const withBlocks = !Array.isArray(commonFields)

    return withBlocks
      ? merge(commonFields, changingBlocksFromConfig)
      : [...commonFields, ...changingBlocksFromConfig]
  }

  const fields = getFields()

  const validationRules = Object.keys(validationRulesInitial).reduce((acc, curr) => {
    let customRules
    if (
      Object.values(fields)
        .flat()
        .some((field) => {
          const isFieldPresent =
            (field.key === curr || (withTabs.includes(field.key) && curr.startsWith(field.key))) &&
            (!field.getIsHidden || !field.getIsHidden(formValues, optionsData))

          if (isFieldPresent && field.validationRules) {
            customRules = { ...field.validationRules }
          }
          return isFieldPresent
        })
    ) {
      return { ...acc, [curr]: validationRulesInitial[curr], ...(customRules || {}) }
    }
    return acc
  }, {})

  const formSubmit = (e, customValuesForOnBlur) => {
    if (e?.preventDefault) {
      e.preventDefault()
    }
    //(isSubmitOnBlur && isObjectEmpty(customValues||formValuesChanged))
    if (isReadOnly || (isSubmitOnBlur && isObjectEmpty(customValuesForOnBlur || formValuesChanged))) {
      return
    }

    if (!isSubmitPressed) {
      setIsSubmitPressed(true)
    }

    const submitErrors = validate(validationRules)(
      customValuesForOnBlur ? { ...initialValues, ...customValuesForOnBlur } : formValues,
      {
        values: customValuesForOnBlur ? { ...initialValues, ...customValuesForOnBlur } : formValues,
        ...optionsData,
      }
    )
    setFormErrors(submitErrors)
    console.log(formValuesChanged, valuesChanged)
    // const errorsWithoutSuccess = Object.values(submitErrors).filter(err=> err === 'success')
    if (isObjectEmpty(submitErrors)) {
      if (onSubmit) {
        onSubmit(customValuesForOnBlur || formValuesChanged, e)
      }
    }
  }

  const getFormBlock = (fields, blockKey, isSecondaryBlock) => {
    const customFields = fields.getFieldsByCustomRule && fields.getFieldsByCustomRule(formValues, optionsData)
    if (customFields && !customFields.length) {
      return
    }

    return (
      <FormBlock
        blockKey={blockKey}
        fields={customFields || fields || []}
        formErrors={formErrors}
        formSubmit={isSubmitOnBlur && formSubmit}
        formValues={formValues}
        formValuesChanged={formValuesChanged}
        FormWrapper={FormWrapper}
        initialValues={merge(
          initialValues,
          initialValuesChangedBeforeCheck ? dot.dot(initialValuesChangedBeforeCheck) : {}
        )}
        isSubmitOnBlur={isSubmitOnBlur}
        isReadOnly={isReadOnly}
        isSecondaryBlock={isSecondaryBlock}
        key={blockKey}
        labelKey={labelKey}
        labelType={labelType}
        optionsData={optionsData}
        setTouchedFields={setTouchedFields}
        submitByEnterPressed={submitByEnterPressed}
        touchedFields={touchedFields}
        type={type}
        updateInput={updateInput}
        updateSelect={updateSelect}
        updateCheckbox={updateCheckboxValue}
        validateField={validateField}
        headerType={headerType}
        expansionPanelProps={expansionPanelProps}
        validationRules={validationRules}
      />
    )
  }

  // VR: form renders multiple times
  return (
    <StyledForm className={clsx(className, withActions && isDraft && 'buttonsAreShown')}>
      <form onSubmit={formSubmit}>
        {getBeforeFormChildren && getBeforeFormChildren(formValues)}
        {fields &&
          (Array.isArray(fields)
            ? getFormBlock(fields)
            : Object.keys(fields).map((blockKey) => getFormBlock(fields[blockKey], blockKey)))}
        {children}
        {!isReadOnly && withActions && isDraft && Object.keys(valuesChanged).length > 0 && (
          <StyledFormButtons
            currentBreakpoint={currentBreakpoint}
            className={buttonsAreSticky ? 'sticky' : ''}
          >
            <div className={buttonsAreSticky ? 'stickyButtonsWrapper' : ''}>
              <Button variant="bordered" onClick={formReset} disabled={inProgress}>
                {t('Discard')}
              </Button>
              <Button fullWidth type="submit" variant="primary" disabled={inProgress}>
                {t('Save')}
              </Button>
            </div>
          </StyledFormButtons>
        )}
        {!withActions && getFormButtons && getFormButtons(isDraft)}
      </form>
    </StyledForm>
  )
}

export default Form
