import React from 'react'
// import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { object } from 'dot-object'
import { isObject, isObjectEmpty } from '@aidsupply/components'
import { getAvailableTranslation } from '@aidsupply/components/build/utilsDataRelated/translations'

import EditForm from '../../../components/RightPanelForms'
import { selectSidebarInitialData } from '../../../redux/selectors'
import { useMappedState } from '../../../hooks'
// import { DEFAULT_LANGUAGE } from '../../../locales'
import { sidebarUpsert } from '../../../redux/actions'

const UnitsRightPanel = () => {
  // const props = useOutletContext()
  // const { t } = useTranslation()

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const unitInitial = useMappedState(selectSidebarInitialData)

  const isReadOnly = false

  const onFormSubmit = (formValuesChanged) => {
    const requestBody = { ...object(formValuesChanged) }

    Object.keys(requestBody).map((key) => {
      if (isObject(requestBody[key]) && requestBody[key].id) {
        requestBody[key] = requestBody[key].id
      }
    })

    if (formValuesChanged.translations?.en || !isObjectEmpty(formValuesChanged.translations)) {
      requestBody.name = getAvailableTranslation(formValuesChanged.translations, 'en', 'en')
    }

    const dataToSend = {
      id: unitInitial.id,
      requestBody,
      type: 'units',
      parentType: 'system',
    }

    dispatch(sidebarUpsert(dataToSend, navigate))
  }

  return <EditForm isReadOnly={isReadOnly} onFormSubmit={onFormSubmit} />
}

export default UnitsRightPanel
