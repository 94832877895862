import React, { useContext, useEffect, useState } from 'react'
import { withTheme } from 'styled-components'

import { ScreenContext, Select } from '@aidsupply/components'

import Table from '../Table'
import {
  EDITABLE_TABLE_CHANGING_BLOCKS_OPTIONS,
  EDITABLE_TABLE_NAVBAR_FORM_CONFIG,
} from '../../config/editableTableNavbarForms'
import FormBlockTabs from '../Form/FormBlockTabs'
import { getEditableTableColumns } from '../../config/columnsEditableTable'
import { useTranslation } from 'react-i18next'

const EditableTableFormPart = ({
  additionalFormValues,
  blockKey,
  cellProps,
  columns,
  compoundTypeKey,
  data,
  fields,
  formFieldsProps,
  getPanelHeader,
  id,
  isNavbarHidden,
  language,
  optionsData,
  t,
  theme,
  typeData = { key: '' },
  updateTableRows,
  validationRules,
}) => {
  const { currentBreakpoint } = useContext(ScreenContext) || {}
  const isReadOnly = cellProps.is_read_only

  const type = typeData.key || ''

  const changingBlocksConfig = !isReadOnly && EDITABLE_TABLE_CHANGING_BLOCKS_OPTIONS[type]
  const changingBlocksOptions = changingBlocksConfig?.options
  const changingBlocksDefaultValue =
    (changingBlocksConfig?.getDefaultValue &&
      changingBlocksConfig.getDefaultValue(data, changingBlocksOptions)) ||
    changingBlocksOptions?.[0]

  const [changingBlocksDependencyValue, setChangingBlocksDependencyValue] =
    useState(changingBlocksDefaultValue)

  useEffect(() => {
    if (!isReadOnly) {
      setChangingBlocksDependencyValue(changingBlocksDefaultValue)
    }
  }, [changingBlocksConfig, changingBlocksDefaultValue, changingBlocksOptions, data, isReadOnly])

  const formConfig = !isNavbarHidden && !isReadOnly && EDITABLE_TABLE_NAVBAR_FORM_CONFIG[type]

  const editableNavbarFields =
    formConfig && changingBlocksDependencyValue
      ? [...formConfig.changingBlocks[changingBlocksDependencyValue.id], ...formConfig.fields]
      : formConfig.fields

  const withTabs = !!formConfig?.withTabs?.length

  const getTabContent = (tabKey) => {
    const columnsWithTabs =
      tabKey &&
      getEditableTableColumns(EDITABLE_TABLE_NAVBAR_FORM_CONFIG[type] ? type : compoundTypeKey, tabKey, t)

    return (
      <>
        {formConfig?.changingBlocksDependency && changingBlocksOptions && (
          <Select
            className="changingBlocksEditableTableSelect"
            value={changingBlocksDependencyValue}
            onChange={(option) => setChangingBlocksDependencyValue(option)}
            withBorder
            labelKey={`translations.${language}`}
            options={changingBlocksOptions}
          />
        )}
        <Table
          key={tabKey}
          additionalFormValues={additionalFormValues}
          blockKey={blockKey}
          formId={id}
          isDeletionByState={formConfig?.isDeletionByState}
          rowKey={formConfig?.tableRowKey || 'id'}
          navbarHidden={isReadOnly || formConfig.navbarHidden}
          cellProps={cellProps}
          createNewOptions={formConfig?.createNewOptions}
          optionsData={optionsData}
          changingBlocksDependencyValue={changingBlocksDependencyValue}
          editableNavbarFields={editableNavbarFields}
          updateTableRows={updateTableRows}
          isReadOnly={isReadOnly}
          editable={!isReadOnly}
          // selectable={type !== 'items.modifications'}
          typeData={typeData}
          columns={tabKey ? columnsWithTabs : columns}
          className="editableTable"
          tableMode="table"
          data={data}
          gap={theme.grid[currentBreakpoint].columns.gapWidth}
          rowHeight={50}
          headerHeight={48}
          maxHeight={data ? data.filter((item) => item.state !== 'deleted').length * 50 + 50 : 50}
          validationRules={validationRules}
          useRowKeyInEditCellName={type === 'items.modifications'}
        />
      </>
    )
  }

  return withTabs ? (
    <FormBlockTabs
      type={type}
      blockKey={blockKey}
      formFieldsProps={formFieldsProps}
      fields={fields}
      getTabScreenCustom={getTabContent}
      defaultTabsValues={formConfig?.defaultTabsValues}
      withTabsFieldsCustom={formConfig?.withTabs}
      isLanguageTabs={formConfig?.isLanguageTabs}
    />
  ) : (
    getTabContent()
  )
}

export default withTheme(EditableTableFormPart)
