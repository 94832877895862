import React from 'react'
import dayjs from 'dayjs'
import 'dayjs/locale/uk'
import 'dayjs/locale/ru'
import { InfoRowsBlock } from '@aidsupply/components'

import { useTranslation } from 'react-i18next'
import { capitalizeFirstLetter } from '../../../utils/general'

const InquiriesTableCard = ({ className, data, initialData }) => {
  const { state, recipient_id, recipient_organization_type, recipient_city, status } = data

  const {
    i18n: { language: lng },
    t,
  } = useTranslation('forms')

  const formattedDate = capitalizeFirstLetter(dayjs(initialData.created_at).locale(lng).format('MMM D, YYYY'))
  const name = initialData.number + ' ' + t('from') + ' ' + formattedDate

  return (
    <InfoRowsBlock
      className={className}
      data={[
        {
          state: {
            customElement: state,
            hideBorder: true,
            width: '30px',
            padding: '20px 0 20px 10px',
          },
          number: {
            customElement: name,
            width: 'calc(100% - 63px)',
            hideBorder: true,
            padding: '0',
          },
          status: {
            customElement: status,
            width: '30px',
            hideBorder: true,
            padding: '5px',
          },
        },
        {
          recipient_name: {
            iconProps: {
              name: 'home',
            },
            text: recipient_id,
            width: '50%',
            padding: '13px 11px',
          },
          recipient_organization_type: {
            iconProps: {
              name: 'hospital',
              stroke: 'none',
            },
            text: recipient_organization_type,
            width: '50%',
            hideBorder: !recipient_city,
            padding: '12px 11px',
          },
        },
        {
          recipient_address: {
            text: recipient_city,
            // width: '50%',
            padding: '11px 9px 0 9px',
          },
        },
      ]}
    />
  )
}

export default InquiriesTableCard
