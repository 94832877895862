import React from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { Button } from '@aidsupply/components'

import { StyledOrderButtons } from './styled'
import { INSTANCE_CREATE } from '../../redux/constants'

const OrderNextStepsButtons = ({ formValues }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('orderButtons')
  const noOrderItems = !formValues.order_items?.length

  const onCreateInstance = (type) => (e) => {
    e.preventDefault()
    const { order_items, id, recipient_id, supplier_id, warehouse_id, currency_id } = formValues

    console.log(formValues, order_items)

    const dataToSend = {
      type,
      requestBody: {
        order_id: id,
        status: 'new',
        state: 'drafted',
      },
    }
    if (type === 'shipments') {
      if (recipient_id) {
        dataToSend.requestBody.recipient_id = recipient_id
      }
      if (supplier_id) {
        dataToSend.requestBody.supplier_id = supplier_id
      }
      if (warehouse_id) {
        dataToSend.requestBody.warehouse_id = warehouse_id
      }
      dataToSend.requestBody.shipment_items = order_items.map((item) => ({
        item_id: item.item_id,
        order_item_id: item.id,
        quantity: item.quantity,
        state: item.state || 'drafted',
      }))
    }

    if (type === 'invoices') {
      if (currency_id) {
        dataToSend.requestBody.currency_id = currency_id
      }
      //issuer_id
      //payer_id
      //subtotal
      //tax
      //total
    }

    console.log(dataToSend)
    dispatch({ type: INSTANCE_CREATE, data: dataToSend })
  }

  return (
    <StyledOrderButtons>
      <Button
        disabled={noOrderItems || formValues.status === 'canceled' || formValues.status === 'confirmed'}
        variant="primary"
        text={t('invoice')}
        onClick={onCreateInstance('invoices')}
        withIcon
        iconLeftProps={{
          name: 'plus2',
          height: 20,
          width: 20,
          strokeWidth: 1.5,
          wrapperWidth: 32,
          wrapperHeight: 32,
        }}
      />
      <Button
        disabled={noOrderItems || formValues.status === 'canceled' || formValues.status === 'confirmed'}
        variant="primary"
        text={t('shipment')}
        onClick={onCreateInstance('shipments')}
        withIcon
        iconLeftProps={{
          name: 'plus2',
          height: 20,
          width: 20,
          strokeWidth: 1.5,
          wrapperWidth: 32,
          wrapperHeight: 32,
        }}
      />
    </StyledOrderButtons>
  )
}

export default OrderNextStepsButtons
