import React, { Fragment } from 'react'
import { PropTypes as T } from 'prop-types'
import { withTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import clsx from 'clsx'

import { Icon } from '@aidsupply/components'

import MenuItem from './MenuItem'
import MenuGroup from './MenuGroup'
import { StyledNavMenu, StyledLine } from './styled'
import { ENTITIES, USER_ROLES } from '../../config/index'
import { useMappedState } from '../../hooks'
import { selectDataTypeObject, selectIsNavMenuExtended, selectUserRole } from '../../redux/selectors'
import { toggleNavMenuExtended, userLogout } from '../../redux/actions'
import { isAccessAllowed } from '../../utils/user'
import Logo from '../../atomic-components/Logo'
import UserBlock from './UserBlock'

const NavMenu = ({ isMobile, theme, isTablet, isRevert, onClick }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('menu')

  const role = useMappedState(selectUserRole)
  const urlPageTypeObj = useMappedState(selectDataTypeObject)
  const isNavMenuExtended = useMappedState(selectIsNavMenuExtended)

  const onToggleIconClick = () => {
    dispatch(toggleNavMenuExtended())
  }

  const isActiveParent = (key) => {
    return urlPageTypeObj ? urlPageTypeObj?.route?.startsWith(`/${key}`) : key === 'dashboard'
  }
  // #roles
  const allowedEntities = ENTITIES.filter((entity) => isAccessAllowed(entity.accessLevel, role))

  const onLogout = () => {
    dispatch(userLogout())
  }

  return (
    <StyledNavMenu
      className={clsx(
        isNavMenuExtended ? 'extended' : 'condensed',
        isTablet && 'tabletView',
        isRevert && 'revertView'
      )}
      activeBgColor={USER_ROLES[role]?.navMenuBgColor}
    >
      {!isMobile && <Icon name="navMenuToggleArrow" className="toggleIcon" onClick={onToggleIconClick} />}
      {!isMobile && (
        <Logo variant="small" isExtended={isNavMenuExtended} isDark to="/" className="menuLogo" />
      )}
      <StyledLine className={isNavMenuExtended ? 'extended' : 'condensed'} />

      <div className="topWrapper">
        {allowedEntities.map((entityObj, i) => {
          const parentEntity = entityObj.items && entityObj

          return (
            <Fragment key={i}>
              <MenuGroup
                parentEntity={parentEntity}
                entitiesArr={entityObj.items || [entityObj]}
                isExtended={isNavMenuExtended}
                isMobile={isMobile}
                onClick={onClick}
                role={role}
                isActiveParent={isActiveParent(parentEntity?.key)}
                withExpansionPanel={allowedEntities.length > 5}
              />
              {i !== allowedEntities.length - 1 && <StyledLine />}
            </Fragment>
          )
        })}
      </div>

      <UserBlock to="/settings/profile" t={t} isExtended={isNavMenuExtended} />
      <MenuItem
        iconName="logout"
        text={t('Logout')}
        className="logoutLink"
        to="/login"
        isActive={false}
        onClick={onLogout}
        isExtended={isNavMenuExtended}
        isMobile={isMobile}
      />
    </StyledNavMenu>
  )
}

export default withTheme(NavMenu)

NavMenu.propTypes = {
  isExtended: T.bool,
}

NavMenu.defaultProps = {}
