import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { CookiesProvider } from 'react-cookie'
import * as Sentry from '@sentry/react'
import configureStore from './configureStore'
import './i18n'
import App from './App'
import * as serviceWorker from './serviceWorker'
import GlobalStyle from './globalStyles'
import ReactHelmet from './wrappers/ReactHelmet'
import PopupAlertsComponent from './wrappers/PopupAlertsComponent'
import ThemeProviderWrapper from './wrappers/ThemeProviderWrapper'
import ScreenContextWrapper from './wrappers/ScreenContextWrapper'

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  tracesSampleRate: 1.0,
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [/^https:\/\/crm\.aidsupply\.org/],
  // Session Replay
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const store = configureStore()

ReactDOM.render(
  <CookiesProvider>
    <Provider store={store}>
      <ThemeProviderWrapper>
        <ScreenContextWrapper>
          <ReactHelmet />
          <GlobalStyle />
          <App />
          <PopupAlertsComponent />
        </ScreenContextWrapper>
      </ThemeProviderWrapper>
    </Provider>
  </CookiesProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
