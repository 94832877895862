import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { Comment, Typography, Icon, Button } from '@aidsupply/components'
import { Link as RouterLink, useParams } from 'react-router-dom'

import { StyledComments, StyledInput, StyledLink, StyledContainer } from './styled'
import {
  selectSidebarCommentsData,
  selectCurrentUserId,
  selectSidebarInitialData,
  selectSidebarState,
} from '../../redux/selectors'
import { sidebarPostComment } from '../../redux/actions'
import { formatDateToUserTimezone } from '../../utils/dates'
import { useMappedState } from '../../hooks'
import { withTheme } from 'styled-components'

const Comments = ({
  containerStyles,
  inputStyles,
  theme: { color },
  showAll = false,
  formValuesChanged,
  updateInput, // only when inside Form
  ...rest
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('forms')
  const commentsData = useMappedState(selectSidebarCommentsData)
  const userId = useMappedState(selectCurrentUserId)
  const sidebarInitialData = useMappedState(selectSidebarInitialData)
  const sidebar = useMappedState(selectSidebarState)
  const sidebarUpsertInProgress = sidebar.sidebarUpsertInProgress

  const [inputValue, setInputValue] = useState('')
  const { rightPanelId: editedItemId } = useParams()

  useEffect(() => {
    if (!formValuesChanged?.comment) {
      setInputValue('')
    }
  }, [formValuesChanged?.comment])

  useEffect(() => {
    if (sidebarUpsertInProgress) {
      setInputValue('')
    }
  }, [sidebarUpsertInProgress])

  const submitComment = () => {
    const commentValue = inputValue
    if (commentValue) {
      dispatch(
        sidebarPostComment({
          entity_id: sidebarInitialData.id,
          content: commentValue,
        })
      )
      setInputValue('')
      if (updateInput) {
        updateInput({ target: { name: 'comment', value: null } })
      }
    }
  }

  const renderComment = (comment) => {
    return (
      <Comment
        {...comment}
        comment={comment.content}
        dateCreated={comment.created_at}
        dateFormat={(date) => formatDateToUserTimezone(date, 'MMM DD, YYYY, hh:mma')}
        key={comment.id}
        name={comment.user_full_name}
        tagText={comment.user_id === userId ? t('author') : null}
      />
    )
  }

  const sortedComments = [...(commentsData || [])].sort(
    (a, b) => new Date(b.created_at) - new Date(a.created_at)
  )
  const newestComment = sortedComments.length > 0 ? sortedComments[0] : null

  return (
    <StyledComments containerStyles={containerStyles} {...rest}>
      {showAll ? (
        sortedComments?.map((comment) => renderComment(comment))
      ) : (
        <>
          {newestComment && renderComment(newestComment)}
          {sortedComments.length > 1 && (
            <StyledLink Link={RouterLink} to="./comments">
              <Typography
                text={t('showMore')}
                color={color.primary.main}
                fontWeight={500}
                lineHeight="16px"
              />
              <Icon name="chevronDoubleLeft" fill={color.primary.main} />
            </StyledLink>
          )}
        </>
      )}
      <StyledContainer inputStyles={inputStyles}>
        <StyledInput
          multiline
          withBorder
          variant="secondary"
          value={inputValue}
          onChange={(value) => {
            setInputValue(value.currentTarget.value)
            if (updateInput) {
              updateInput({ target: { name: 'comment', value: value.currentTarget.value } })
            }
          }}
          onEnter={submitComment}
          maxLength="1000"
        />
        <Button
          className="button"
          variant="primary"
          onClick={submitComment}
          disabled={!inputValue || editedItemId === 'copy' || editedItemId === 'new'}
        >
          <Icon name="send" fill={color.general.light} width={16} height={16} />
        </Button>
      </StyledContainer>
    </StyledComments>
  )
}
export default withTheme(Comments)
