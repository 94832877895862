import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
// import LanguageDetector from 'i18next-browser-languagedetector' (it was setting ru-RU, have to change this behavior before uncomment)

import { DEFAULT_LANGUAGE, resources, SUPPORTED_LNGS } from './locales'

// const platformName = 'aidsupply'

i18n
  .use(initReactI18next)
  // .use(LanguageDetector)
  .init(
    {
      resources,
      supportedLngs: SUPPORTED_LNGS,
      load: 'languageOnly',
      fallbackLng: 'en',
      defaultLng: DEFAULT_LANGUAGE,
      lng: DEFAULT_LANGUAGE,
      languages: Object.keys(resources),
      keySeparator: false, // we do not use keys in form messages.welcome
      interpolation: {
        escapeValue: false, // react already safes from xss
      },
    }
    // (err, t) => {
    //   i18n.changeLanguage()
    // }
  )

export default i18n
