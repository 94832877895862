import React, { useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { withTheme } from 'styled-components'
import { useDispatch } from 'react-redux'

import { isObjectEmpty, ScreenContext } from '@aidsupply/components'

import GridLayoutMobile from './GridLayoutMobile'
import GridLayoutDesktop from './GridLayoutDesktop'
import { selectDataType, selectFiltersState } from '../../redux/selectors'
import { useMappedState } from '../../hooks'
import { toggleNavMenuExtended } from '../../redux/actions'
import LeftFiltersPanel from './LeftFiltersPanel'

const GridLayout = ({
  children,
  theme,
  isLeftPanelShown,
  LeftPanelComponent,
  RightPanelComponent,
  rightPanelProps,
  ...otherProps
}) => {
  const dispatch = useDispatch()
  const { width, md, lg } = useContext(ScreenContext)
  const { t } = useTranslation(['menu', 'all'])

  const params = useParams()
  const { rightPanelId, action } = params

  const type = useMappedState(selectDataType)
  const filters = useMappedState(selectFiltersState)
  const showFilterPanel = !isObjectEmpty(filters.facets) || !isObjectEmpty(filters.active)

  const [isRightPanelOpened, setIsRightPanelOpened] = React.useState(false)

  const panelsProps = {
    isRightPanelOpened,
    setIsRightPanelOpened,
    // isLeftPanelOpened,
    // setIsLeftPanelOpened,
  }

  useEffect(() => {
    setIsRightPanelOpened(!!rightPanelId || !!action)
  }, [rightPanelId, action])

  useEffect(() => {
    if (width <= lg) {
      if (isRightPanelOpened) {
        dispatch(toggleNavMenuExtended(false))
      }
    }
  }, [isRightPanelOpened, width])

  // useEffect(() => {
  //   if (width <= md) {
  //     if (pathname.includes('templates')) {
  //       setIsLeftPanelOpened(!templateId)
  //     }
  //     if (pathname.includes('chats')) {
  //       setIsLeftPanelOpened(!chatId)
  //     }
  //     if (pathname === '/my-company') {
  //       setIsLeftPanelOpened(true)
  //     }
  //     if (pathname.includes('/my-company/')) {
  //       setIsLeftPanelOpened(false)
  //     }
  //     if (pathname === '/settings') {
  //       setIsLeftPanelOpened(true)
  //     }
  //     if (pathname.includes('/settings/')) {
  //       setIsLeftPanelOpened(false)
  //     }
  //   }
  // }, [width, pathname])

  if (width <= md) {
    return (
      <GridLayoutMobile
        RightPanelComponent={RightPanelComponent}
        LeftPanelComponent={LeftPanelComponent || (showFilterPanel && <LeftFiltersPanel />)}
        t={t}
        theme={theme}
        type={type}
        {...otherProps}
        {...panelsProps}
        rightPanelProps={rightPanelProps}
      >
        {children}
      </GridLayoutMobile>
    )
  }

  return (
    <GridLayoutDesktop
      RightPanelComponent={RightPanelComponent}
      LeftPanelComponent={LeftPanelComponent || (showFilterPanel && <LeftFiltersPanel />)}
      t={t}
      theme={theme}
      type={type}
      {...otherProps}
      {...panelsProps}
      rightPanelProps={rightPanelProps}
    >
      {children}
    </GridLayoutDesktop>
  )
}

export default withTheme(GridLayout)
