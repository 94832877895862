export const REDIRECT_ROOT = '/'
export const REDIRECT_NOT_AUTHENTICATED = '/login'
export const REDIRECT_NOT_AUTHORIZED = '/404'

export const RIGHT_PANEL_HEADERS = {
  brands: {
    dataKey: 'general.name',
  },
  items: {
    dataKey: 'texts.title',
  },
  orders: {
    translationKey: 'orderInfo',
  },
  users: {
    invite: 'inviteUser',
    // new: '',
  },
  posts: {
    topics: 'postTopics',
  },
}

export const RIGHT_PANEL_CREATE_ROUTES = ['invite', 'new', 'copy']

export const MENU_ICONS_BY_TYPES = {
  attributes: 'listBulleted',
  banners: 'banners',
  brands: 'brand',
  campaigns: 'moneyBox',
  carriers: 'deliveryTruck',
  categories: 'flowchart',
  contracts: 'contracts',
  organizations: 'layers',
  'my-organization': 'layers',
  enumerations: 'listNumber',
  faqs: 'faqs',
  inquiries: 'fileQuestion',
  invoices: 'fileDollar',
  inventories: 'fileCheckList',
  items: 'warehouses',
  orders: 'fileCheck',
  pages: 'pagesMenu',
  platforms: 'platforms',
  shipments: 'deliveryBox',
  'stocks-items': 'deliveryBoxes',
  warehouses: 'warehouse',
  units: 'ruler',
  users: 'usersPlus',
}

export const ORGANIZATION_ROLES = {
  recipient: {
    id: 'recipient',
    label: { en: 'Recipient', ru: 'Получатель', uk: 'Одержувач' },
  },
  donor: {
    id: 'donor',
    label: { en: 'Donor', ru: 'Донор', uk: 'Донор' },
  },
  merchant: {
    id: 'merchant',
    label: { en: 'Merchant', ru: 'Продавец', uk: 'Продавець' },
  },
}

// #roles
export const USER_ROLES = {
  system: {
    level: 0,
    entities: ['system/.*'],
    label: { en: 'System administrator', ru: 'Системный администратор', uk: 'Системний адміністратор' },
    id: 'system',
    navMenuBgColor: 'general.light',
    navMenuLineColor: 'general.gray1',
  },
  administrator: {
    level: 1,
    entitiesSharedWithUpperRoles: ['system/.*'],
    label: { en: 'Administrator', ru: 'Администратор', uk: 'Адміністратор' },
    id: 'administrator',
    navMenuBgColor: 'general.light',
    navMenuLineColor: 'general.gray1',
  },
  organization_owner: {
    level: 2,
    entitiesSharedWithUpperRoles: ['admin/.*'],
    label: { en: 'Organization owner', uk: 'Власник організації', ru: 'Владелец организации' },
    id: 'organization_owner',
    navMenuBgColor: 'general.gray1',
    navMenuLineColor: 'general.gray2',
  },
  organization_member: {
    id: 'organization_member',
    label: { en: 'Organization member', uk: 'Учасник організації', ru: 'Участник организации' },
    level: 3,
    entitiesSharedWithUpperRoles: ['products', 'inquiry_items', 'inventory/.*', 'documents/.*'],
    navMenuBgColor: 'general.gray1',
    navMenuLineColor: 'general.gray2',
  },
}

// #roles
// There is no dashboard for client and recipient roles (accessLevel 4 and 5)
export const DASHBOARD_CARDS = [
  {
    key: 'users',
    label: 'users',
    src: '/admin/users',
    accessLevel: 2,
  },
  {
    key: 'categories',
    label: 'categories',
    src: '/system/categories',
    accessLevel: 1,
  },
  {
    key: 'brands',
    label: 'brands',
    src: '/system/brands',
    accessLevel: 1,
  },
  {
    key: 'organizations',
    label: 'organizations',
    src: '/admin/organizations',
    accessLevel: 2,
  },
  {
    key: 'warehouses',
    label: 'warehouses',
    src: '/inventory/warehouses',
    accessLevel: 3,
  },
  {
    key: 'items',
    label: 'items',
    src: '/system/items',
    accessLevel: 1,
  },
  {
    key: 'stock-items',
    label: 'SKUs',
    src: '/inventory/stock-items',
    accessLevel: 3,
  },
  {
    key: 'inquiries',
    label: 'inquiries',
    src: '/documents/inquiries',
    accessLevel: 3,
  },
  {
    key: 'orders',
    label: 'orders',
    src: '/documents/orders',
    accessLevel: 3,
  },
  {
    key: 'invoices',
    label: 'invoices',
    src: '/documents/invoices',
    accessLevel: 3,
  },
  {
    key: 'shipments',
    label: 'shipments',
    src: '/documents/shipments',
    accessLevel: 3,
  },
]

// #roles
export const SUBMENU = {
  admin: [
    {
      key: 'users',
      icon: MENU_ICONS_BY_TYPES.users,
      route: '/admin/users',
      label: 'users',
      labelSingle: 'user',
      parentType: 'admin',
      accessLevel: USER_ROLES['organization_owner'].level,
    },
    {
      key: 'organizations',
      label: 'organizations',
      labelSingle: 'organization',
      route: '/admin/organizations',
      icon: MENU_ICONS_BY_TYPES.organizations,
      parentType: 'admin',
      accessLevel: USER_ROLES['administrator'].level,
    },

    {
      key: 'organization-profile',
      label: 'myOrganization',
      labelSingle: 'myOrganization',
      route: '/admin/organization-profile',
      icon: MENU_ICONS_BY_TYPES['my-organization'],
      parentType: 'admin',
      accessLevel: ['organization_owner'],
    },
  ],
  documents: [
    {
      key: 'inquiries',
      label: 'inquiries',
      labelSingle: 'inquiry',
      route: '/documents/inquiries',
      icon: MENU_ICONS_BY_TYPES.inquiries,
      parentType: 'documents',
      accessLevel: USER_ROLES['organization_member'].level,
    },
    {
      key: 'orders',
      label: 'orders',
      labelSingle: 'order',
      route: '/documents/orders',
      icon: MENU_ICONS_BY_TYPES.orders,
      parentType: 'documents',
      accessLevel: USER_ROLES['organization_member'].level,
    },
    {
      key: 'invoices',
      label: 'invoices',
      labelSingle: 'invoice',
      route: '/documents/invoices',
      icon: MENU_ICONS_BY_TYPES.invoices,
      parentType: 'documents',
      accessLevel: USER_ROLES['organization_member'].level,
    },
    {
      key: 'shipments',
      label: 'shipments',
      labelSingle: 'shipment',
      route: '/documents/shipments',
      icon: MENU_ICONS_BY_TYPES.shipments,
      parentType: 'documents',
      accessLevel: USER_ROLES['organization_member'].level,
    },
    {
      key: 'contracts',
      label: 'contracts',
      labelSingle: 'contract',
      route: '/documents/contracts',
      icon: MENU_ICONS_BY_TYPES.contracts,
      parentType: 'documents',
      accessLevel: USER_ROLES['organization_member'].level,
    },
  ],
  inventory: [
    {
      key: 'warehouses',
      label: 'warehouses',
      labelSingle: 'warehouse',
      route: '/inventory/warehouses',
      icon: MENU_ICONS_BY_TYPES.warehouses,
      parentType: 'inventory',
      accessLevel: USER_ROLES['organization_member'].level,
    },
    {
      key: 'inventories',
      label: 'inventories',
      labelSingle: 'inventory',
      route: '/inventory/inventories',
      icon: MENU_ICONS_BY_TYPES.inventories,
      parentType: 'inventory',
      accessLevel: USER_ROLES['organization_member'].level,
    },
    {
      key: 'stock-items',
      label: 'stock-items',
      labelSingle: 'stock-item',
      route: '/inventory/stock-items',
      icon: MENU_ICONS_BY_TYPES['stocks-items'],
      parentType: 'inventory',
      accessLevel: USER_ROLES['organization_member'].level,
    },
  ],
  system: [
    {
      key: 'brands',
      label: 'brands',
      labelSingle: 'brand',
      route: '/system/brands',
      icon: MENU_ICONS_BY_TYPES.brands,
      parentType: 'system',
      accessLevel: USER_ROLES['administrator'].level,
    },
    {
      key: 'items',
      label: 'items',
      labelSingle: 'item',
      route: '/system/items',
      icon: MENU_ICONS_BY_TYPES.items,
      parentType: 'system',
      accessLevel: USER_ROLES['administrator'].level,
    },
    {
      key: 'categories',
      icon: MENU_ICONS_BY_TYPES.categories,
      route: '/system/categories',
      label: 'categories',
      labelSingle: 'category',
      parentType: 'system',
      accessLevel: USER_ROLES['administrator'].level,
    },
    {
      key: 'attributes',
      icon: MENU_ICONS_BY_TYPES.attributes,
      route: '/system/attributes',
      label: 'attributes',
      labelSingle: 'attribute',
      parentType: 'system',
      accessLevel: USER_ROLES['administrator'].level,
    },
    {
      key: 'enumerations',
      icon: MENU_ICONS_BY_TYPES.enumerations,
      route: '/system/enumerations',
      label: 'enumerations',
      labelSingle: 'enumeration',
      parentType: 'system',
      accessLevel: USER_ROLES['administrator'].level,
    },
    {
      key: 'units',
      icon: MENU_ICONS_BY_TYPES.units,
      route: '/system/units',
      label: 'units',
      labelSingle: 'unit',
      parentType: 'system',
      accessLevel: USER_ROLES['administrator'].level,
    },
  ],
  platform: [
    {
      key: 'posts',
      label: 'blogPosts',
      labelSingle: 'blogPost',
      route: '/platform/posts',
      iconName: 'fileSimple',
      iconProps: { width: 16, height: 16 },
      accessLevel: USER_ROLES['organization_member'].level,
    },
    {
      key: 'faqs',
      label: 'faqs',
      labelSingle: 'faq',
      route: '/platform/faqs',
      iconName: 'fileQuestion',
      iconProps: { width: 16, height: 16 },
      accessLevel: USER_ROLES['organization_member'].level,
    },
    {
      key: 'campaigns',
      label: 'campaigns',
      labelSingle: 'campaign',
      route: '/platform/campaigns',
      icon: MENU_ICONS_BY_TYPES.campaigns,
      parentType: 'platform',
      accessLevel: USER_ROLES['organization_member'].level,
    },
    {
      key: 'reports',
      label: 'reports',
      labelSingle: 'report',
      route: '/platform/reports',
      iconName: 'fileCheckList',
      iconProps: { width: 16, height: 16 },
      accessLevel: USER_ROLES['organization_owner'].level,
    },
    {
      key: 'pages',
      label: 'pages',
      labelSingle: 'page',
      route: '/platform/pages',
      iconName: 'fileCheck',
      iconProps: { width: 16, height: 16 },
      accessLevel: USER_ROLES['organization_owner'].level,
    },
  ],
}

// #roles
export const ENTITIES = [
  {
    key: 'inquiry_items',
    label: 'inquiry_items',
    labelSingle: 'inquiry_item',
    route: '/inquiry-items',
    iconName: 'listBulleted',
    accessLevel: USER_ROLES['administrator'].level,
  },
  {
    key: 'products',
    label: 'products',
    labelSingle: 'product',
    route: '/products',
    iconName: 'shoppingBag',
    iconProps: { width: 16, height: 16 },
    accessLevel: USER_ROLES['administrator'].level,
  },
  {
    key: 'documents',
    label: 'documents',
    labelSingle: 'document',
    route: '/documents/inquiries',
    iconName: 'file',
    items: SUBMENU.documents,
    accessLevel: USER_ROLES['organization_member'].level,
  },
  {
    key: 'inventory',
    label: 'inventory',
    labelSingle: 'inventory',
    route: '/inventory/warehouses',
    iconName: 'squares',
    items: SUBMENU.inventory,
    accessLevel: USER_ROLES['administrator'].level,
  },
  // {
  //   key: 'my-network',
  //   label: 'My network',
  //   labelSingle: 'my-network',
  //   route: '/my-network',
  //   iconName: 'globe',
  //   accessLevel: USER_ROLES['organization_member'].level,
  // },
  {
    key: 'system',
    label: 'system',
    labelSingle: 'system',
    route: '/system/brands',
    iconName: 'settings',
    items: SUBMENU.system,
    accessLevel: USER_ROLES['administrator'].level,
  },
  {
    key: 'admin',
    label: 'admin',
    labelSingle: 'admin',
    route: '/admin/users',
    iconName: 'user',
    items: SUBMENU.admin,
    accessLevel: USER_ROLES['organization_owner'].level,
  },
  {
    key: 'platform',
    label: 'platform',
    labelSingle: 'platform',
    route: '/platform',
    iconName: 'globe',
    iconProps: { width: 20, height: 20 },
    items: SUBMENU.platform,
    accessLevel: USER_ROLES['administrator'].level,
  },
  {
    key: 'notifications',
    label: 'notifications',
    route: '/',
    iconName: 'info',
    iconProps: { width: 20, height: 20 },
    accessLevel: USER_ROLES['organization_member'].level,
  },
]

export const MIME_IMAGES = ['mime/jpg', 'mime/jpeg', 'mime/png']

export const PRODUCTS_CHECKOUT_STEPS = [
  {
    title: 'cart',
    iconProps: {
      name: 'shoppingBasket',
      wrapperWidth: 25,
      wrapperHeight: 25,
      width: 16,
      height: 16,
    },
  },
  // {
  //   title: 'contacts',
  //   iconProps: { name: 'letter', wrapperWidth: 25, wrapperHeight: 25, width: 16, height: 16 },
  // },
  {
    title: 'preview',
    iconProps: {
      name: 'checkRounded',
      wrapperWidth: 25,
      wrapperHeight: 25,
      width: 16,
      height: 16,
    },
  },
  // {
  //   title: 'delivery',
  //   content: 'chooseDeliveryOptions',
  //   iconProps: { name: 'deliveryFolder', width: 30, height: 25 },
  // },
  // { title: 'billing', content: 'enterBillingInfo', iconProps: { name: 'bill', width: 25, height: 30 } },
]
