import styled from 'styled-components'

export const StyledCard = styled.div`
  display: flex;
  padding: 10px 0;

  figure {
    flex-shrink: 0;
  }

  .infoRowsBlock {
    width: calc(100% - 110px);
    margin-left: 10px;
    .dataRow {
      justify-content: space-between;
      flex-flow: nowrap;
    }
  }
`
