import React from 'react'
import { useTranslation } from 'react-i18next'

import { Typography } from '@aidsupply/components'

const ErrorScreen = () => {
  const { t } = useTranslation()

  return <Typography type="h3" text={t('somethingWentWrong')} margin="15px 50px" />
}

export default ErrorScreen
