import styled from 'styled-components'

export const StyledWrapper = styled.div`
  display: flex;

  .mainWrapper {
    position: relative;
    width: 45%;
    padding: 20px 20px 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;

    h1.text {
      width: 100%;
    }

    .repeatPassword {
      .inputContainer {
        margin-bottom: 7px;
      }
    }

    .inscriptionWrapper {
      display: flex;
      gap: 6px;
      margin-top: 20px;

      &.terms {
        display: inline-block;
      }
      .typography {
        margin-top: 0;
      }
    }

    form {
      button {
        margin-top: 10px;
      }
    }
  }

  .introHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .footerText {
    max-width: 345px;
    text-align: center;
    margin: 0 auto;
    white-space: pre-wrap;
    .typography {
      padding: 0;
    }
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    .mainWrapper {
      width: 100%;
      padding: 30px 15px;
    }
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    .mainWrapper {
      width: 100%;
      height: 100vh;
      overflow-y: auto;
    }
    .textLogo {
      margin-left: 0;
    }
  }
`

export const StyledIntroWrapper = styled.div`
  background-image: ${({ url }) => `url(${url})`};
  background-size: cover;
  width: 53%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: column;
  color: ${({ color }) => color};
  margin: 20px;
  border-radius: 6px;
`

export const StyledWindow = styled.div`
  flex-grow: 1;
  width: 100%;
  max-width: 345px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: -60px;

  &.signUp {
    margin-top: 0;
  }

  .inscriptionWrapper {
    .auth.link a {
      font-weight: 600;
    }
  }

  &.requestSent,
  &.passwordRecoverySent {
    .typography,
    .inscriptionWrapper {
      text-align: center;
    }
  }

  &.signIn,
  &.signUp {
    .rightIcon {
      path {
        fill: none;
      }
    }
  }
  // .captcha {
  //   margin: 0 auto;
  // }
  .form {
    margin-top: 20px;
  }

  .formBlock {
    padding: 0;
  }

  .signUpLink a {
    font-weight: 300;
    letter-spacing: 1px;
  }

  .contentWrapper {
    .text {
      letter-spacing: 2px;
    }
  }

  @media only screen and (max-width: 1139px) {
    margin-top: 0;
    justify-content: start;
    .contentWrapper {
      margin: auto 0;
    }
    &.signUp .contentWrapper {
      padding-top: 30px;
    }
  }
`

export const StyledFooterText = styled.div`
  text-align: center;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    padding: 20px 0 0;
  }
`
export const StyledLine = styled.hr`
  background-color: ${({ theme }) => theme.color.general.gray2};
  color: ${({ theme }) => theme.color.general.gray2};
  border-width: 0;
  height: 1px;
  margin: 10px 0;
`

export const StyledTextWrapper = styled.div`
  max-width: 1024px;
  margin: 0 auto;
  padding: 60px 60px 0;
  white-space: pre-wrap;

  .textLogo {
    margin: 0 auto 0 20px;
  }

  hr {
    margin: 30px 0;
  }

  .headerWrapper {
    display: flex;
    justify-content: space-between;
  }

  .react-select__control {
    .leftIcon path {
      stroke: ${({ theme: { color } }) => color.general.dark};
    }
    .chevronDown path {
      fill: ${({ theme: { color } }) => color.general.dark};
    }
    .react-select__value-container {
      padding: 10px 7px 10px 15px;
      text-transform: uppercase;
    }
    .react-select__indicators {
      padding-right: 12px;
      .react-select__indicator {
        padding: 0;
      }
    }
  }
  .react-select__value-container .react-select__single-value {
    color: ${({ theme: { color } }) => color.general.dark};
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg - 1}px) {
    padding: 40px 15px;
  }
`
