import React from 'react'

const INFO_PANEL_CONFIG = {
  templates: {
    translations: {
      initiallyOpened: true,
      props: {
        children: '',
        // <TemplatesBodyInfo />
        text: '%USERNAME%, %DOCUMENT_ID%, %DOCUMENT_TYPE%, %DOCUMENT_STATE_PREV%, %DOCUMENT_STATE_NEXT%',
        linkText: '',
        linkTo: '',
      },
    },
  },
}

export default INFO_PANEL_CONFIG
