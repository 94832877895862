import { all, call, put, select, takeLatest } from 'redux-saga/effects'

import { isObjectEmpty } from '@aidsupply/components'
import { buildRequest, fetchData, showError } from '../../api'
import { DATA_TYPE_TO_SINGULAR_FORM } from '../../apiConstants'
import { sidebarUpsert } from '../actions'
import {
  AVATAR_UPLOAD,
  AVATAR_UPLOAD_FAILURE,
  FILES_UPLOAD_SUCCESS,
  FILE_UPLOAD,
  FILE_UPLOAD_FAILURE,
  GET_CURRENT_USER_SUCCESS,
  PHOTO_DELETE,
  POPUP_ALERT_SHOW,
} from '../constants'
import { selectUserDetails } from '../selectors'

// Collections with photo upload support: brands, items, carriers, categories, enumerations (option icons), posters, platforms, pages

const mapStateToProps = (state) => {
  const { fileUpload, sidebar } = state
  return { fileUpload, sidebar }
}

export function* getUploadedFilesData({ type, requestBody, id, filesType }) {
  const { fileUpload, sidebar } = yield select(mapStateToProps)

  try {
    console.log(filesType)
    const initialValues = type === 'enumerations' ? sidebar.initial.options : sidebar.initial?.[filesType]

    const filesResult = yield all(
      Object.keys(fileUpload.files)
        .map((fileGroup) => {
          return fileUpload.files[fileGroup].map((file) => {
            return call(uploadFile, {
              file,
              params: {
                entityType: type,
                entityId: id,
                fileGroup,
                entitySlug: type === 'enumerations' && (requestBody.slug || sidebar.initial.slug),
                fileType: filesType === 'files' ? 'document' : 'image',
              },
            })
          })
        })
        .flat()
    )

    const files = filesResult.reduce((acc, fileObj) => {
      if (acc[fileObj.fileGroup]) {
        return { ...acc, [fileObj.fileGroup]: [...acc[fileObj.fileGroup], fileObj] }
      } else {
        return { ...acc, [fileObj.fileGroup]: [fileObj] }
      }
    }, {})

    // merge new files' states or old files with new uploaded files
    const filesFormDataObject =
      files &&
      Object.keys(files).reduce((acc, fileGroup) => {
        const newActiveFromUpload = files[fileGroup] || []
        const newValuesFromFormData = type === 'enumerations' ? requestBody.options : requestBody[filesType]

        return type === 'enumerations'
          ? {
              options: (newValuesFromFormData || initialValues || []).map((option) => {
                return option.slug === fileGroup && newActiveFromUpload?.[0]
                  ? { ...option, image: newActiveFromUpload?.[0] }
                  : option
              }),
            }
          : {
              [filesType]: {
                ...(isObjectEmpty(acc[filesType])
                  ? newValuesFromFormData || initialValues || {}
                  : acc[filesType]),
                [fileGroup]: {
                  ...(newValuesFromFormData?.[fileGroup] || initialValues?.[fileGroup] || {}),
                  active: [
                    ...(newValuesFromFormData?.[fileGroup]?.active ||
                      initialValues?.[fileGroup]?.active ||
                      []),
                    ...newActiveFromUpload,
                  ],
                },
              },
            }
      }, {})

    yield put({
      type: FILES_UPLOAD_SUCCESS,
      files,
    })

    if (filesType === 'files') {
      return filesFormDataObject
    }

    // for photos, not for files
    if (type === 'organizations' || type === 'users') {
      Object.keys(files).forEach((fileGroup) => {
        const primaryPhotoKey =
          (fileGroup === 'logos' && 'logo_photo_id') || (fileGroup === 'banners' && 'banner_photo_id')
        const primary_photo_id = sidebar.initial[primaryPhotoKey]

        if (!primary_photo_id) {
          filesFormDataObject.primaryPhotoObj = {
            ...filesFormDataObject.primaryPhotoObj,
            [primaryPhotoKey]: files[fileGroup][0].id,
          }
        }
      })
    } else {
      const primary_photo_id = sidebar.initial.photo_id
      if (!primary_photo_id) {
        const filteredPhotos = filesResult.filter((el) =>
          ['main', 'general', 'logos', 'miniLogos'].includes(el.fileGroup)
        )

        filesFormDataObject.primaryPhotoObj = { photo_id: filteredPhotos[0]?.id || null }
      }
    }

    return filesFormDataObject
  } catch (error) {
    console.error(error)
  }
}

export function* doUploadAvatar({ file, params }) {
  try {
    const { entityId, entityType, fileGroup } = params
    const result = yield uploadFile({ file, params })

    yield showError(result)

    yield put(
      sidebarUpsert({
        id: entityId,
        type: entityType,
        requestBody: {
          [(params.fileGroup === 'logos' && 'logo_photo_id') ||
          (params.fileGroup === 'banners' && 'banner_photo_id')]: result.id,
        },
      })
    )

    if (params.entityType === 'users') {
      const currentUser = yield select(selectUserDetails)

      yield put({
        type: GET_CURRENT_USER_SUCCESS,
        payload: { ...currentUser, profile_pic: result.url },
      })
    }

    // yield put({ type: AVATAR_UPLOAD_SUCCESS, payload: result.url })
    return result
  } catch (error) {
    console.error(error)
    yield put({ type: AVATAR_UPLOAD_FAILURE, error })
  }
}

function* doDeletePhoto({ payload: { primaryPhotoIdKey, entityId, entityType, photoId } }) {
  try {
    // yield call(doUpsert, {
    //   payload: {
    //     id: photoId,
    //     type: 'filesUpload',
    //     requestBody: { state: 'deleted' },
    //   },
    // })

    yield put(
      sidebarUpsert({
        id: entityId,
        type: entityType,
        requestBody: {
          [primaryPhotoIdKey]: null,
        },
      })
    )

    if (entityType === 'users') {
      const currentUser = yield select(selectUserDetails)

      yield put({
        type: GET_CURRENT_USER_SUCCESS,
        payload: { ...currentUser, profile_pic: null },
      })
    }
    // else if (entityType === 'organizations') {
    //   const myOrganizationInitial = yield select(selectSidebarInitialData)
    //
    //   yield put({
    //     type: SIDEBAR_UPSERT_SUCCESS,
    //     payload: { ...myOrganizationInitial, [primaryPhotoUrlKey]: null },
    //   })
    // }
  } catch (error) {
    console.error(error)
  }
}

/**
 * Core function which holds all the logic of file upload/Library update
 * @param {file} file - File to be uploaded
 * @param {object} params - File's metadata
 */
export function* uploadFile({ file, params }) {
  const { entityType, entityId, fileGroup = 'noGroup', fileType, onSuccess } = params
  const filePreviewId = file.preview

  try {
    const signedParams = {
      entity_id: params.entitySlug || entityId,
      entity_type: DATA_TYPE_TO_SINGULAR_FORM[entityType] || entityType,
      file_type: fileType || 'image',
      file_name: file.name,
      mime_type: file.type,
    }

    if (fileGroup && fileGroup !== 'noGroup') {
      signedParams.file_group = fileGroup
    }

    const fd = new FormData()
    fd.append('file_obj', file)
    for (const key in signedParams) {
      fd.append(key, signedParams[key])
    }

    const request = yield buildRequest({
      requestBody: fd,
      operation: 'create',
      type: 'filesUpload',
    })

    const result = yield fetchData(request)

    if (result.url) {
      return {
        fileGroup,
        id: result.id,
        file_type: result.file_type,
        url: result.url,
        meta: {
          mime_type: result.meta.mime_type,
          file_name_original: result.meta.file_name_original,
          file_size: result.meta.file_size,
        },
        filePreviewId,
      }
    } else {
      throw new Error('No "result.url" in response')
    }
  } catch (error) {
    console.log(error)
    yield put({ type: FILE_UPLOAD_FAILURE, filePreviewId, fileGroup })
    yield put({ type: POPUP_ALERT_SHOW, data: { contentKey: 'errorInSavingData', type: 'error' } })
  }

  // if (!errors?.length && params.onSuccess) {
  //   params.onSuccess(result.photos)
  // }
}

export default function* fileUploadSaga() {
  return [
    yield takeLatest(FILE_UPLOAD, uploadFile),
    yield takeLatest(AVATAR_UPLOAD, doUploadAvatar),
    yield takeLatest(PHOTO_DELETE, doDeletePhoto),
  ]
}
