import clsx from 'clsx'
import React from 'react'
import { Link } from 'react-router-dom'
import ReactTexty from 'react-texty'
import { withTheme } from 'styled-components'

import { UiLink, Userpic } from '@aidsupply/components'

import { IMAGEKIT_URL } from '../../constants'
import { useMappedState } from '../../hooks'
import { selectUserDetails } from '../../redux/selectors'
import { StyledMenuItem, StyledUserName } from './styled'

const UserBlock = ({ isExtended, isMobile, t, theme, to }) => {
  const user = useMappedState(selectUserDetails)

  return (
    <UiLink to={to} Link={Link} className="menuItem userLink" withHoverForTypographyOnly={isExtended}>
      <StyledMenuItem className={clsx(isExtended ? 'extendedMenu' : 'condensedMenu')}>
        <Userpic
          height="36px"
          width="36px"
          imagekitUrl={IMAGEKIT_URL}
          src={user.profile_pic}
          theme={theme}
          fullName={user.full_name}
        />

        <StyledUserName
          type={isMobile ? 'button2' : 'button1'}
          // text={user.member_full_name}
          className={clsx(isExtended ? 'extendedItem' : 'condensedItem')}
        >
          <ReactTexty>{user.full_name}</ReactTexty>
        </StyledUserName>
      </StyledMenuItem>
    </UiLink>
  )
}

export default withTheme(UserBlock)
