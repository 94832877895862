import React from 'react'
import { withTheme } from 'styled-components'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'

import { CONFIG } from '@aidsupply/components'
import { AIDSUPPLY_FAVICON_LINK } from '../constants'

const ReactHelmet = ({ children, theme }) => {
  const { i18n } = useTranslation()

  const platforms = []
  const isGoodzykCommonPlatform = ['crm', 'goodzyk'].includes(theme.platform)
  const platformConfig = !isGoodzykCommonPlatform && platforms && Object.values(platforms)[0] // isGoodzykCommonPlatform ? platforms[CONFIG.crm.id]

  const getDescription = () => {
    if (!platformConfig) {
      return null
    }

    return isGoodzykCommonPlatform
      ? CONFIG.crm.description
      : platformConfig.seo?.description?.[i18n?.language] || ''
  }

  return (
    <Helmet>
      <link rel="icon" href={AIDSUPPLY_FAVICON_LINK} id="favicon" />
      <meta name="description" content={getDescription()} />
      <title>{theme.title}</title>
      {children}
    </Helmet>
  )
}

export default withTheme(ReactHelmet)
