import styled from 'styled-components'
import * as dot from 'dot-object'

import { HEADER_HEIGHT, NAV_MENU_WIDTH } from '../../constants'

export const StyledNavMenu = styled.nav`
  position: relative;
  background-color: ${({ theme, activeBgColor }) => dot.pick(activeBgColor, theme.color)};
  transition: width 0.3s cubic-bezier(0.25, 0.5, 0.5, 0.9);
  z-index: 5;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  border-right: 1px solid ${({ theme }) => theme.bordersColor};

  .detailsWrapper .menuItem {
    margin-bottom: 10px;
    .extendedMenu {
      margin-right: 10px;
      margin-left: 10px;
    }
  }

  .headerChevronIcon {
    margin-right: 10px;
  }

  &.extended {
    width: ${NAV_MENU_WIDTH.desktop.extended};
    &.tabletView {
      position: absolute;
      top: ${HEADER_HEIGHT};
      bottom: 0;
      left: 0;
      width: 300px;
      border-radius: 0 0 5px 0;
      box-shadow: 0 15px 25px ${({ theme }) => `${theme.color.general.light}14`};
    }

    .textLogo {
      opacity: 1;
      transition: opacity 1.5s;
    }
  }

  &.condensed {
    width: ${NAV_MENU_WIDTH.desktop.condensed};

    .toggleIcon {
      transform: rotate(180deg);
    }
    .textLogo {
      width: 0;
      margin: 0;
      opacity: 0;
      transition: opacity 0s;
    }
    .headerChevronIcon {
      margin-right: 0;
      position: absolute;
      bottom: 4px;
      right: 4px;
    }

    .userLink {
      margin-left: 4px;
    }
  }

  .toggleIcon {
    position: absolute;
    right: -10px;
    top: 20px;
    transition: transform 0.2s cubic-bezier(0.25, 0.5, 0.5, 0.9);
  }

  .label {
    display: inline-block;
    color: ${({ theme }) => theme.color.general.gray3};
    margin-left: 10px;
    font-size: 12px;
  }

  .active .panelHeader {
    background-color: ${({ theme }) => theme.color.general.gray3};
    border-radius: 6px;

    .typography {
      color: ${({ theme }) => theme.color.general.light};
    }
  }

  .userLink {
    margin-top: 10px;
  }

  .topWrapper {
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;

    > .expansionPanel {
      margin-left: 10px;
      margin-right: 10px;
    }

    > div:nth-child(n + 2) {
      margin-top: 10px;
      margin-bottom: 10px;
    }

    > div:first-child {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }

  .userLink,
  .logoutLink {
    display: flex;
    margin-bottom: 20px;
    padding: ${({ padding }) => padding || '0 10px'};
    padding-left: 0;
    margin-left: 10px;
    margin-right: 10px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    &.extended {
      margin-top: 12px;
      height: 100vh;
    }
  }
  @media only screen and (max-height: ${({ theme }) => theme.breakpoints.lg}px) {
    &.extended.revertView {
      height: calc(100vh - ${HEADER_HEIGHT});
    }
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.xl}px) {
    padding: 0;

    &.extended {
      width: ${NAV_MENU_WIDTH.mobile.extended};
      transform: translateY(0%);
      transition: transform 0.4s ease-in-out;
      border-top: 1px solid ${({ theme }) => theme.color.general.gray2};
      overflow-y: auto;
      padding: 14px;
      position: absolute;
      z-index: 12;
      top: 52px;
    }

    &.condensed {
      width: ${NAV_MENU_WIDTH.mobile.condensed};
      position: absolute;
      bottom: 100vh;
      left: 0;
      transform: translateY(-100%);
      transition: transform 0.6s ease-in-out;
    }
  }
`

export const StyledMenuItems = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${({ margin }) => margin};

  a {
    width: 100%;
    color: ${({ textColor }) => textColor};
  }
`

export const StyledMenuItem = styled.div`
  padding: 6px 10px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  position: relative;

  &.active.extendedMenu,
  &.active.condensedMenu {
    background-color: ${({ theme }) => theme.color.status.new};
    border-radius: 6px;

    .typography {
      color: ${({ theme }) => theme.color.general.light};
    }
  }

  .typography {
    margin-left: 6px;
    color: ${({ theme }) => theme.color.general.dark};
  }

  .condensedItem {
    opacity: 0;
    transition: opacity 0s;
    transform: translateX(-100%);
    transition: transform 0.6s ease-in-out;

    &.typography {
      margin-left: 0;
      display: none;
    }
  }

  .extendedItem {
    transform: translateX(0%);
    transition: transform 0.4s ease-in-out;
    opacity: 1;
    transition: opacity 1.5s;
    color: ${({ theme }) => theme.color.general.dark};

    &:hover,
    &:focus {
      transition: none;
    }
  }

  &:hover {
    .condensedItem.typography {
      opacity: 0 !important;
      transition: none;
    }
  }

  .icon {
    margin: 0;
  }

  a,
  .typography {
    color: ${({ theme }) => theme.color.general.dark};
  }
`

export const StyledLine = styled.hr`
  color: ${({ theme }) => theme.color.general.light};
  background-color: ${({ theme }) => theme.bordersColor};
  border-width: 0;
  height: 1px;
  margin: 0;
`

export const StyledUserName = styled.span`
  width: 200px;
  margin-left: 10px;
`
