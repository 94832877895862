import styled from 'styled-components'
import { Drawer } from '@aidsupply/components'

export const StyledMain = styled.main`
  display: flex;
  width: inherit;
  position: relative;
  padding-top: calc(${({ theme }) => theme.size.height.header.mobile} + 10px);
  max-height: 100vh;
  overflow-y: hidden;

  &.touchOnly {
    .buttonsAreShown {
      form {
        padding-bottom: 20px;
      }
    }

    .sticky {
      position: relative;
    }

    .titleEditPanel {
      position: relative;
    }
  }

  &.empty {
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    .emptyText {
      font-size: calc(10px + 2vmin);
    }
  }

  .expansionPanel .header {
    min-height: 48px;
  }

  .drawer {
    height: 100vh;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm - 1}px) {
    .drawer {
      width: calc(100vw - ${({ theme: { grid }, currentBreakpoint }) => grid[currentBreakpoint].columns.gapWidth} * 2);
    }
  }

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.sm}px) and (max-width: ${({
      theme,
    }) => theme.breakpoints.md}px) {
    .drawer {
      height: calc(100vh - 80px);
    }
  }

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    padding-top: 0;
  }
`

export const StyledFiltersDrawer = styled(Drawer)`
  .drawer {
    min-width: 219px;
  }
  &.opened {
    margin-right: ${({ theme: { grid }, currentBreakpoint }) => grid[currentBreakpoint].columns.gapWidth};
  }

  .filters {
    padding: ${({ theme: { grid }, currentBreakpoint }) => grid[currentBreakpoint].columns.gapWidth} 0 0 0;
  }

  .checkbox {
    margin-left: calc(30px - ${({ theme: { grid }, currentBreakpoint }) => grid[currentBreakpoint].columns.gapWidth});
  }

  .tagsStickyWrapper {
    padding-bottom: ${({ theme: { grid }, currentBreakpoint }) => grid[currentBreakpoint].columns.gapWidth};
    margin-top: ${({ theme: { grid }, currentBreakpoint }) => grid[currentBreakpoint].columns.gapWidth};
  }

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
    .filters {
      padding-right: ${({ theme: { grid }, currentBreakpoint }) => grid[currentBreakpoint].columns.gapWidth};
    }
  }

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    .filters {
      padding: ${({ theme: { grid }, currentBreakpoint }) => grid[currentBreakpoint].columns.gapWidth}
        ${({ theme: { grid }, currentBreakpoint }) => grid[currentBreakpoint].columns.gapWidth} 0;
    }
    .drawer.opened {
      border-right: 1px solid ${({ theme: { color } }) => color.general.gray2};
    }
  }
`

export const StyledFiltersTitle = styled.div`
  display: flex;
  padding-top: ${({ theme: { grid }, currentBreakpoint }) => grid[currentBreakpoint].columns.gapWidth};
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: ${({ theme }) => theme.color.general.light};

  .expansionPanel {
    padding: ${({ theme: { grid }, currentBreakpoint }) => grid[currentBreakpoint].columns.gapWidth};
    padding-top: 0;
    padding-bottom: 0;
    margin: 0;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm - 1}px) {
    .expansionPanel {
      padding-right: 0;
    }
  }
`

export const StyledEditDrawer = styled(Drawer)`
  .drawer {
    padding-top: ${({ theme: { grid }, currentBreakpoint }) => grid[currentBreakpoint].columns.gapWidth};
  }

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.sm - 1}px) {
    .drawer {
      border-left: 1px solid ${({ theme }) => theme.color.general.gray2};
      padding: ${({ theme: { grid }, currentBreakpoint }) => grid[currentBreakpoint].columns.gapWidth} 0 0
        ${({ theme: { grid }, currentBreakpoint }) => grid[currentBreakpoint].columns.gapWidth};
      margin-left: ${({ theme: { grid }, currentBreakpoint }) => grid[currentBreakpoint].columns.gapWidth};
    }
  }

  // @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm - 1}px) {
  //   margin: 0;
  //   padding-right: calc(${({ theme: { grid }, currentBreakpoint }) => grid[currentBreakpoint].columns.gapWidth} * 2);
  //   padding-left: 0;
  //   border: none;
  // }
`
