import styled from 'styled-components'
import { UiLink } from '@aidsupply/components'

export const StyledLink = styled(UiLink)`
  width: 100%;
  border-radius: 6px;
  padding: 4.5px 10px;
  border: 1px solid ${({ theme }) => theme.color.general.gray2};

  a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    width: 100%;
  }

  .iconDownload {
    transform: rotate(180deg);
    margin-right: 0;
  }
`
export const StyledTemplate = styled.div`
  display: flex;
  flex-direction: row;
  .icon {
    margin-left: 0;
    margin-right: 8px;
  }
`
export const StyledTypeFile = styled.div`
  display: flex;
  flex-direction: column;
  .dot {
    color: ${({ theme }) => theme.color.general.gray3};
  }
`
export const StyledContainer = styled.div`
  width: 100%;
  border-radius: 6px;
  padding: 4.5px 10px;
  border: 1px solid ${({ theme }) => theme.color.general.gray2};
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  .iconDownload {
    transform: rotate(180deg);
    margin-right: 0;
  }
`
