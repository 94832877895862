import styled from 'styled-components'
import { Icon } from '@aidsupply/components'

export const StyledOrganizationProfile = styled.div`
  margin: 30px auto;
  margin-bottom: 20px;
  width: 70%;
  padding: 0 10px;

  .thumbnails {
    background-color: ${({ theme }) => theme.color.general.dark};
    height: 200px;
  }
  
  .h4 {
    text-transform: uppercase;
    letter-spacing: 2px;
  }

  .panelHeader {
    border-bottom: 1px   solid ${({ theme: { color } }) => color.general.gray1};
    padding: 20px 0px;
  }

  .inputContainer {
    margin-bottom: 25px;
  }
  .sticky {
    padding-top: 20px;
    padding-bottom: 0px;
  .stickyButtonsWrapper{
    justify-content: center;
    .primary {
      width:100px;
    }
  }
  }

  .infoOrganization {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: none;
    margin-bottom: 20px;
    width: 100%;

    .container {
      display: flex;
      align-items: center;
      justify-content: center;
      
      .icon {
      z-index: 11
    }
    }

    .bordered {
      margin-left: 10px;
      padding: 10px 14px;
      box-shadow: 0px 1px 2px 0px rgba(24, 24, 28, 0.04), 0px 3px 4px -5px rgba(24, 24, 28, 0.03);
    }

    .info {
      margin-left: 20px;
    }
  }

  .generalInfo {
    .panelHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .detailsWrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      }
  }

  .translation {
    .detailsWrapper {
      .column {
        margin-bottom: 41px;
      }
    }
  }

  .socialProfiles {
    .detailsWrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .column {
        flex-basis: calc(50% - 20px);
      }
    }
  }

  .fundraisingAccounts {
    .detailsWrapper {
      display: block;
    }
  }


    @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    .infoOrganization {
      flex-wrap: wrap;
      justify-content: center;

      .bordered {
        margin-top: 20px;
      }
    }

    .generalInfo {
      .detailsWrapper {
        display: block;
      }
    }

    .fundraisingAcounts, .socialProfiles {
      .detailsWrapper {
        display: block;
      }
    }
  }

    @media only screen and (min-width: ${({ theme }) => theme.breakpoints.lg}px) and (max-width: ${({
  theme,
}) => theme.breakpoints.xl}px) {
       .generalInfo {
         .detailsWrapper {
           .column {
             flex-basis: calc(50% - 20px);
           }
         }
       }
      }

      
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.xl}px) {
    .generalInfo{
     .detailsWrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .column {
        :nth-child(1),
        :nth-child(2),
        :nth-child(9),
        :nth-child(10) {
          flex-basis: calc(50% - 20px);
        }
        :nth-child(3),
        :nth-child(4),
        :nth-child(5),  
        :nth-child(6),
        :nth-child(7),
        :nth-child(8) {
          flex-basis: calc(33.33% - 20px);
        }
        :last-child {
          width: 100%;
        }
      }
    }
  }


  
`
export const StyledUserpic = styled.div`
  position: relative;
  max-width: 124px;
  max-height: 124px;

  .icon {
    position: absolute;
    max-width: 32px;
    max-height: 32px;
    bottom: 0;
    right: 0;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.color.general.light};
    border: 1px solid ${({ theme }) => theme.color.general.gray2};
    padding: 8px;
    cursor: pointer;
    box-shadow: 0px 1px 2px 0px #18181c0a, 0px 3px 4px -5px #18181c08;
  }

  input {
    display: block !important;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 10;
  }
`

export const StyledBanner = styled.div`
  background-color: ${({ theme }) => theme.color.general.gray3};
  height: ${({ src }) => (src ? '210px' : '')};
  min-height: ${({ src }) => (!src ? '210px' : '')};
  background-size: cover;
  background-position: center;
  margin-bottom: 20px;
  position: relative;

  figure {
    height: 100%;
    img {
      height: 100%;
    }
  }

  .container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    z-index: 10;
    padding: 58px 0;
    margin: 0 auto;

    .title {
      margin-bottom: 10px;
    }

    .containerButton {
      display: none;
    }

    .noImage {
      display: flex;
      justify-content: center;
      margin-top: 16px;
      .bordered {
        padding: 6px 10px;
        margin-right: 20px;
      }
      .primary {
        padding: 6px 10px;
        position: relative;
      }
      input {
        display: block !important;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        opacity: 0;
        cursor: pointer;
        z-index: 11;
      }
    }

    &:hover .containerButton {
      display: flex;
      justify-content: center;
      margin-top: 16px;
      .bordered {
        padding: 6px 10px;
        margin-right: 20px;
      }
      .primary {
        padding: 6px 10px;
        position: relative;
      }
      input {
        display: block !important;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        opacity: 0;
        cursor: pointer;
        z-index: 11;
      }
    }
  }

  @media only screen and (max-width: 416px) {
    .container {
      padding: 30px 0;
      display: flex;
      flex-direction: column;
      align-items: center;

      .containerButton {
        display: none;
      }
      .noImage {
        display: inline-block;
        text-align: center;
        .bordered {
          margin: 10px auto;
        }
      }

      &:hover .containerButton {
        display: inline-block;
        text-align: center;
        .bordered {
          margin: 10px auto;
        }
      }
    }
  }
`
