import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import clsx from 'clsx'

import { Icon, Typography } from '@aidsupply/components'

import {
  StyledEmpty,
  StyledEmptyWrapper,
  StyledNotificationsHeader,
  StyledNotificationsPanel,
} from './styled'
import NotificationItem from './NotificationItem'
import { clearNotifications, setNotifications } from '../../redux/actions'
import { useMappedState } from '../../hooks'
import { selectNotifications, selectUserToken } from '../../redux/selectors'
import InfoPanel from '../InfoPanel'
import { capitalizeFirstLetter } from '../../utils/general'
import dayjs from 'dayjs'

const NotificationsDashboard = () => {
  const dispatch = useDispatch()
  const {
    i18n: { language },
    t,
  } = useTranslation(['notifications', 'table'])

  const [notifications, setNotifications] = useState([])
  const token = useMappedState(selectUserToken)

  const getNotificationData = async () => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/notifications`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    const notifications = await response.json()
    console.log(notifications)
    setNotifications(
      notifications?.items?.filter(
        (item) =>
          item.notification_type === 'document_updated' || item.details.message === 'Parsing order items'
      ) || []
    )
  }

  useEffect(() => {
    getNotificationData()
  }, [])

  const onClearAll = () => {
    dispatch(clearNotifications())
  }

  return (
    <StyledNotificationsPanel className={clsx('notificationsPanel', !notifications.length && 'empty')}>
      <StyledNotificationsHeader>
        <Typography text={t('Notifications')} type="h4" />
        {!!notifications?.length && (
          <Typography text={t('clearNotifications')} type="body1" onClick={onClearAll} className="clearAll" />
        )}
      </StyledNotificationsHeader>
      {notifications.length ? (
        notifications.map((item, i) => {
          const formattedDate = capitalizeFirstLetter(
            dayjs(item.created_at).locale(language).format('MMM D, YYYY, HH:mm')
          )

          return (
            <InfoPanel
              date={formattedDate}
              key={i}
              text={t(
                item.notification_type === 'document_updated' ? 'yourOrderUploaded' : 'parsingOrderItems',
                { number: item.details.number }
              )}
            />
          )
        }) //<NotificationItem key={i} item={item} language={language} t={t} />)
      ) : (
        <StyledEmptyWrapper>
          <Icon name="noNewNotification" />
          <StyledEmpty>{t('noNotifications')}</StyledEmpty>
        </StyledEmptyWrapper>
      )}
    </StyledNotificationsPanel>
  )
}

export default NotificationsDashboard
