import { useEffect, useState } from 'react'
import dot from 'dot-object'
import cloneDeep from 'lodash.clonedeep'
import merge from 'deepmerge'

import { validate, validateOneField, isEqualArraysById } from '@aidsupply/components'

import { selectIsCurrentFilesInDraft } from '../../redux/selectors'
import { useMappedState } from '../../hooks'

const overwriteMerge = (target, source, options) => {
  // combine arrays
  if (target[0]?.code || target[0]?.phone || target[0]?.phonePart) {
    const destination = target.slice()
    source.forEach((item, index) => {
      if (typeof destination[index] === 'undefined') {
        destination[index] = options.cloneUnlessOtherwiseSpecified(item, options)
      } else if (options.isMergeableObject(item)) {
        destination[index] = merge(target[index], item, options)
      } else if (target.indexOf(item) === -1) {
        destination.push(item)
      }
    })
    return destination
  }
  // overwrite arrays
  return source
}

const mergeOptions = {
  customMerge: (key) => {
    if (key === 'photos') {
      return (photosA, photosB) => photosB
    }
  },
  arrayMerge: overwriteMerge,
}

const useForm = ({
  customFormValues,
  initialValues = {},
  initialValuesChanged,
  optionsData,
  validationRules,
  setTouchedFields,
}) => {
  const [valuesChanged, setValuesChanged] = useState({})
  const [formErrors, setFormErrors] = useState({})
  const [isDraft, setIsDraft] = useState(false)
  const formValues =
    customFormValues || merge(initialValues, dot.object(cloneDeep(valuesChanged)), mergeOptions)

  const isCurrentFilesInDraft = useMappedState(selectIsCurrentFilesInDraft)

  useEffect(() => {
    setIsDraft(false)
  }, [])

  useEffect(() => {
    if (initialValuesChanged) {
      setValuesChanged(dot.dot(initialValuesChanged))
    }
  }, [initialValuesChanged])

  useEffect(() => {
    if (Object.keys(valuesChanged).length) {
      setIsDraft(true)
    } else {
      setIsDraft(false)
    }
  }, [valuesChanged, setIsDraft])

  const addToValuesChangedObject = (path, value, isInput) => {
    dot.keepArray = true
    dot.override = true

    if (isInput && value === Object(value) && !Array.isArray(value)) {
      //if input value (by updateInput) is object
      setValuesChanged((prev) => {
        return { ...cloneDeep(prev), ...dot.dot({ [path]: value }) }
      })
    } else {
      setValuesChanged((prev) => ({ ...cloneDeep(prev), [path]: value }))
    }
  }

  const removeFromValuesChangedObject = (path) => {
    setValuesChanged((prev) => {
      const newValue = cloneDeep(prev)
      delete newValue[path]

      return newValue
    })
  }

  const validateField = (name, value) => {
    if (
      validationRules[name] &&
      validationRules[name].findIndex((rule) => rule.type === 'requiredIfFieldsEmpty') !== -1
    ) {
      const newValues = { ...formValues, [name]: value }
      const errors = validate(validationRules)(newValues, { values: newValues })
      setFormErrors(errors)
      return
    }

    const ruleWithSuccess = validationRules[name]?.find((rule) => rule.withSuccess)
    const dataForValidation = ruleWithSuccess?.dataForValidation
    const listKey = dataForValidation?.listKey

    if (formErrors[name] || ruleWithSuccess) {
      const initialValueObj = { initialValue: dot.pick(name, initialValues), values: formValues }
      const data = ruleWithSuccess
        ? {
            ...initialValueObj,
            ruleWithSuccess,
            [listKey]: optionsData?.[listKey],
          }
        : initialValueObj
      const fieldError = validateOneField(name, validationRules)(value, data)

      if (!Object.keys(fieldError).length) {
        setFormErrors((prev) => {
          const newErrors = { ...prev }
          delete newErrors[name]

          return newErrors
        })
      } else {
        setFormErrors((prev) => ({ ...prev, ...fieldError }))
      }
    }
  }

  const updateCheckboxValue = (checked, e) => {
    const { name } = e.target
    const initialValue = dot.pick(name, initialValues)

    if (checked === initialValue || (!initialValue && !checked)) {
      removeFromValuesChangedObject(name)
    } else {
      addToValuesChangedObject(name, checked)
    }
  }

  const updateInputValue = (e, customOnChange) => {
    // event.persist() - if we save e.target to var, we can omit it https://medium.com/@brunogarciagonzalez/reactjs-events-exploration-a295505016f1
    const { name, value } = e.target
    validateField(name, value)

    if (value === dot.pick(name, initialValues)) {
      removeFromValuesChangedObject(name)
    } else {
      addToValuesChangedObject(name, value, true)
    }

    setTouchedFields((prev) => (prev.includes(name) ? prev : [...prev, name]))

    if (customOnChange) {
      customOnChange(value, setValuesChanged, cloneDeep(initialValues)) // TODO: do we need cloneDeep here?
    }
  }

  const updateSelectValue = (name, customOnChange) => (value, equalsToInitialValue) => {
    validateField(name, value)
    const initialValue = dot.pick(name, initialValues)

    if (typeof equalsToInitialValue === 'undefined') {
      if (Array.isArray(value)) {
        if (value?.length === initialValue?.length && isEqualArraysById(value, initialValue)) {
          removeFromValuesChangedObject(name)
        } else {
          addToValuesChangedObject(name, value)
        }
      } else if (value === null) {
        if (!initialValue || (Array.isArray(initialValue) && !initialValue.length)) {
          removeFromValuesChangedObject(name)
        } else {
          addToValuesChangedObject(name, value)
        }
      } else if (value.id === initialValue?.id) {
        removeFromValuesChangedObject(name)
      } else {
        addToValuesChangedObject(name, value)
      }
    } else {
      if (equalsToInitialValue === true) {
        removeFromValuesChangedObject(name)
      } else if (equalsToInitialValue === false) {
        addToValuesChangedObject(name, value)
      }
    }

    setTouchedFields((prev) => (prev.includes(name) ? prev : [...prev, name]))

    if (customOnChange) {
      customOnChange(value, setValuesChanged, cloneDeep(initialValues), optionsData) // TODO: do we need cloneDeep here?
    }
  }

  return {
    formValues,
    formErrors,
    setFormErrors,
    isDraft: isDraft || isCurrentFilesInDraft,
    setIsDraft,
    updateCheckboxValue,
    updateInputValue,
    updateSelectValue,
    setValuesChanged,
    valuesChanged,
    validateField,
    addToValuesChangedObject,
  }
}

export default useForm
