export default {
  errorPage: {
    goHome: 'На головну',
    letUsKnow: 'Повідомте нас',
    needHelp: 'Потрібна допомога?',
    somethingsMissing: 'Упс! Чогось бракує',
  },
  footer: {
    allRightsReserved: 'Усі права захищені',
    Feedback: "Зворотній зв'язок",
  },
  forms: {
    aboutSection: 'Розділ "Про нас"',
    account: 'Рахунок',
    accountNumber: 'Номер рахунку',
    admin: 'Адмін',
    addToExistingItems: 'Додати до існуючих товарів',
    amount: 'Кількість',
    answer: 'Відповідь',
    assets: 'Ресурси',
    attributes: 'Атрибути',
    author: 'Автор',
    backgroundColor: 'Колір фону',
    backgroundIsDark: 'Фон темний, текст світлий',
    bank: 'Банк',
    bannerCategory: 'Категорія (оберіть її лише якщо цей банер призначений для категорії)',
    banners: 'Банери',
    bestsellers: 'Лідери продажів',
    brandDescription: 'Опис бренду',
    brandPageHeaderPhrase: 'Фраза-заголовок сторінки бренду',
    brands: 'Бренди',
    brandSlogan: 'Слоган бренду',
    brandSloganAuthor: 'Автор слогану бренду',
    bucketMonobank: 'Id банки у Монобанку',
    bucketWayforpay: 'Назва Wayforpay сторінки в url адресі',
    carriers: 'Перевізники',
    cart: 'Кошик',
    categories: 'Категорії',
    categoriesBig: 'Категорії (великі плитки)',
    categoriesRecommended: 'Рекомендовані категорії (дрібні)',
    changePassword: 'Змінити пароль',
    characteristics: 'Характеристики',
    city: 'Місто',
    color: 'Колір',
    colors: 'Кольори',
    comment: 'Коментар',
    comments: 'Коментарі',
    components: 'Компоненти',
    content: 'Склад',
    counterparties: 'Контрагенти',
    cover: 'Обкладинка',
    coverVideo: 'Відео-обкладинка',
    create: 'Створити',
    currencies: 'Валюти',
    currentAmount: 'Поточна сума',
    custom: 'Користувальницький',
    customerFullName: "Повне ім'я замовника",
    dark: 'Темний',
    darker: 'Темніше',
    darkest: 'Найтемніший',
    dateModified: 'Дата зміни',
    de: 'Німецька',
    deadline: 'Дедлайн',
    defaultLanguage: 'Мова за умовчанням',
    deliveryOptions: 'Варіанти доставки',
    description: 'Опис',
    dimensions: 'Розміри',
    district: 'Район',
    details: 'Деталі',
    document: 'Документ',
    dropOrSelect: 'Перетягніть або оберіть з локального диску',
    edit: 'Редагувати',
    en: 'Англійська',
    error: 'Помилка',
    externalNumber: 'Номер вхідного документа',
    faqSection: 'Розділ FAQ',
    favicon: 'Фавікон',
    featuredProducts: 'Рекомендовані товари',
    feedbackSection: "Розділ зворотнього зв'язку",
    files: 'Файли',
    fillGeneralAndSaveBeforeUploadingFile:
      'Будь ласка, заповніть всі обов’язкові поля і натисніть кнопку «Зберегти» перед завантаженням файлу',
    fillGeneralAndSaveBeforeUploadingPhotos:
      "Будь ласка, заповніть всі обов'язкові поля і натисніть кнопку «Зберегти» перед завантаженням фотографій",
    financialInfo: 'Фінансова інформація',
    firstPageImageIsBackground: 'Зображення першої сторінки є фоном',
    firstPageText: 'Текст першої сторінки',
    firstPageWithOverlay: 'Перша сторінка з накладенням',
    firstScreen: 'Перший екран',
    firstScreenMobile: 'Перший екран мобільного',
    font: 'Шрифт',
    from: 'від',
    fundraisingAccounts: 'Рахунки для збору коштів',
    general: 'Загальні',
    generalInfo: 'Загальна інформація',
    generateModelNumber: 'Згенерувати код моделі',
    generateNewOrganization: 'Створити організацію',
    goalAmount: 'Сума цілі',
    gray1: 'Сірий1',
    gray2: 'Сірий2',
    gray3: 'Сірий3',
    gray4: 'Сірий4',
    gray5: 'Сірий5',
    gray6: 'Сірий6',
    gray7: 'Сірий7',
    header: 'Заголовок',
    history: 'Історія',
    id: 'Індонезійська',
    info: 'Інформація',
    introductionVideo: 'Вступне відео',
    introSection: 'Вступний розділ',
    inventoryDetails: 'Деталі інвентаризації',
    isCharacteristic: 'Є характеристикою',
    isComingSoon: 'Незабаром з’явиться сторінка',
    isCrm: 'Є сторінкою для CRM',
    isFragile: 'Ламка посилка',
    isPhotoGroup: 'Група фотографій',
    isUsedForInquiries: 'Використовується для Запитів/Потреб',
    items: 'Товари',
    key: 'Ключ',
    keywords: 'Ключові слова',
    label: 'Назва',
    languages: 'Мови',
    light: 'Світлий',
    lighter: 'Світліше',
    lightest: 'Найсвітліший',
    linkToPageText: 'Текст посилання на сторінку',
    logos: 'Лого',
    main: 'Головний',
    media: 'ЗМІ',
    merchants: 'Продавці',
    miniLogos: 'Міні логотипи',
    mobile: 'Для мобільних',
    modifications: 'Модифікації',
    name: 'Назва',
    nameInEnglish: 'Імʼя англійською',
    no: 'Ні',
    inquiry_items: 'Продуктові категорії',
    new: 'Новий',
    noOptionsFound: 'Варіантів не знайдено',
    oneFileAllowed: 'Дозволено лише один активний файл',
    optionIconsUploadIsAllowed: 'Завантаження іконок для опцій дозволено',
    orderInfo: 'Інформація про замовлення',
    organizationFromSignup: 'Організація, зазначена під час реєстрації',
    organizations: 'Організації',
    organizationEmail: 'Електронна адреса організації',
    organizationType: 'Тип організації',
    organizationWebsite: 'Сайт організації',
    myOrganization: 'Моя організація',
    overlay: 'Накладення',
    page404: 'Сторінка 404',
    pageContent: 'Наполнение',
    password: 'Пароль',
    phoneNumber: 'Телефон',
    photos: 'Фотографії',
    pickup: 'Самовивіз',
    pickupAddress: 'Адреса отримання',
    pl: 'Польська',
    platformConstructor: 'Конструктор платформи',
    platformIsComingSoon: 'Платформа незабаром з’явиться',
    postTopics: 'Теми постів',
    primary: 'Первинний',
    primaryPhoto: 'Головна',
    products: 'Товари',
    productsDescription: 'Опис товарів',
    productsTitle: 'Заголовок для товарів',
    profilePicture: 'Зображення профілю',
    properties: 'Властивості',
    recipient: 'Отримувач',
    requiresCooling: 'Потребує охолодження',
    requiresFreezing: 'Потребує заморозки',
    registrationNumber: 'Реєстраційний номер',
    repeatPassword: 'Повторіть пароль',
    replaceExistingItems: 'Замінити існуючі товари',
    ru: 'Російська',
    scrollDownForModifications: 'Натисніть для переходу до модифікацій товару з кодами внизу',
    search: 'Пошук',
    secondary: 'Другорядний',
    secondPageText: 'Текст другої сторінки',
    secondScreen: 'Другий екран',
    seo: 'SEO Оптимізація',
    sharingImage: "Картинка для прев'ю",
    shipmentAddress: 'Адреса доставки',
    shippingDetails: 'Деталі доставки',
    shortUrl: 'Короткий URL',
    showMore: 'Показати більше',
    slides: 'Слайди',
    socialNetworks: 'Соціальні мережі',
    socialProfiles: 'Соціальні мережі',
    sorting: 'Сортування',
    square: 'Квадратний',
    state: 'Стан',
    status: 'Статус',
    subtotal: 'Підсумок',
    success: 'Успіх',
    systemNameInEnglish: 'Назва англійською мовою',
    tax: 'Податок',
    taxRate: 'Податкова ставка',
    team: 'Команда',
    teamDescription: 'Опис команди',
    teamSlogan: 'Слоган команди',
    textForEveryItem: 'Текст для кожного товару',
    texts: 'Тексти',
    title: 'Назва',
    tokens: 'Жетони',
    topics: 'Теми',
    translations: 'Переклади',
    types: 'Типи',
    uk: 'Українська',
    uploadFailed: 'Помилка завантаження',
    uploadInProgress: 'Завантаження в процесі. Ви отримаєте повідомлення, коли воно завершиться.',
    values: 'Цінність',
    viewProfile: 'Переглянути профіль',
    videoPreviewImage: 'Зображення для попереднього  перегляду відео',
    videos: 'Відео ',
    videoSection: 'Відео розділ',
    videoUrl: 'URL-адреса відео',
    warning: 'Попередження ',
    wayforpayURL: 'Wayforpay URL',
    wayforpayAccount: 'Обліковий запис Wayforpay',
    wayforpayKey: 'Секретний ключ Wayforpay',
    website: 'Веб-сайт',
    weight: 'Вага',
    weightUnit: 'Одиниця виміру ваги',
    yearFounded: 'Рік заснування',
    yes: 'Так',
  },
  general: {
    isIconsUploadAllowed: 'Завантаження іконок для опцій дозволено',
    fileUpload: 'Завантажити файл',
    optimalDimensions: 'Оптимальні розміри 1920 x 210px',
    pageNotFound: 'Сторінка на знайдена',
    profileImageTip: 'Завантажте свою світлину, рекомендований розмір зображення 512x512px',
    profilePicture: 'Зображення профілю',
    replaceBannerImage: 'Замінити зображення банера',
    replaceImage: 'Замінити зображення',
    update: 'Оновити',
    upload: 'Завантажити',
    template: 'Шаблон',
  },
  header: {
    Profile: 'Профіль',
    quickMenu: 'Швидке меню',
    searchBar: 'Пошук',
    Settings: 'Налаштування',
  },
  loadingOrNoData: {
    brandsHeader: 'Брендів ще немає',
    itemsHeader: 'Товарів ще немає',
    outfitsHeader: 'Образів ще немає',
    addFirstItem: 'Додати {{itemType}}',
    addTopic: 'Додати тему',
    attribute: 'атрибут',
    banner: 'банер',
    brand: 'бренд',
    campaign: 'кампанію',
    carrier: 'перевізника',
    category: 'категорію',
    client: 'клієнта',
    contract: 'договір',
    counterparty: 'контрагента',
    demand: 'потребу',
    enumeration: 'перелік',
    FAQ: 'питання',
    inquiry: 'запит',
    invoice: 'рахунок',
    item: 'товар',
    loading: 'Завантаження...',
    merchant: 'продавця',
    noDataFound: 'Даних не знайдено',
    order: 'замовлення',
    organization: 'Організація',
    outfit: 'образ',
    page: 'сторінку',
    platform: 'платформу',
    report: 'звіт',
    blogPost: 'публікацію',
    serverError: '500 внутрішня помилка сервера',
    shipment: 'відвантаження',
    warehouse: 'склад',
    'stock-item': 'товар на складі',
    unit: 'одиницю вимірювання',
    user: 'користувача',
  },
  menu: {
    address: 'Адреса',
    admin: 'Адмін',
    attributes: 'Атрибути',
    banners: 'Банери',
    brands: 'Бренди',
    campaigns: 'Кампанії',
    carriers: 'Перевізники',
    categories: 'Категорії',
    clients: 'Клієнти',
    contracts: 'Договори',
    counterparties: 'Контрагенти',
    dashboard: 'Дашборд ',
    documents: 'Документи',
    enumerations: 'Переліки ',
    faqs: 'FAQs',
    Help: 'Допомога',
    inquiries: 'Запити',
    inventories: 'Інвентаризація',
    inventory: 'Інвентар',
    invoices: 'Рахунки',
    items: 'Товари',
    Logout: 'Вихід',
    merchant: 'Продавець',
    merchants: 'Продавці',
    inquiry_items: 'Продуктові категорії',
    new: 'новий',
    notifications: 'Сповіщення',
    options: 'Опції',
    orders: 'Замовлення',
    organizations: 'Організації',
    myOrganization: 'Моя організація',
    outfits: 'Наряди',
    pages: 'Сторінки',
    reports: 'Звіти',
    platform: 'Платформа',
    blogPosts: 'Публікації',
    products: 'Товари',
    Search: 'Пошук',
    Settings: 'Налаштування',
    shipments: 'Відвантаження',
    'stock-items': 'Товари на складі',
    warehouses: 'Склади',
    system: 'Система',
    units: 'Одиниці виміру',
    user: 'Користувач',
    users: 'Користувачі',
  },
  notifications: {
    attributes: 'Атрибут {{status}}',
    banners: 'Банер {{status}}',
    brands: 'Бренд {{status}}',
    bulkStatusesUpdated: '{{count}} {{type}} було {{status}}',
    carriers: 'Перевізника {{status}}',
    categories: 'Категорію {{status}}',
    clearNotifications: '',
    clients: 'Клієнта {{status}}',
    contracts: 'Договір {{status}}',
    counterparties: 'Контрагента {{status}}',
    created: 'створено',
    enumerations: 'Перелік {{status}}',
    faqs: 'FAQ {{status}}',
    inquiries: 'Запит {{status}}',
    invoices: 'Рахунок {{status}}',
    items: 'Товар {{status}}',
    merchants: 'Продавця {{status}}',
    noNotifications: 'Нових повідомлень немає',
    Notifications: 'Сповіщення',
    orders: 'Замовлення №{{id}} було {{status}}',
    ordersBulk: 'замовлення',
    organizations: 'Організацію було {{status}}',
    pages: 'Сторінку {{status}}',
    parsingOrderItems: 'Парсинг товарів замовлення в процесі',
    platforms: 'Платформу {{status}}',
    shipments: 'Відвантаження {{status}}',
    'stock-items': 'Товар на складі був {{status}}',
    'stock-itemsBulk': 'складські позиції',
    warehouses: 'Склад {{status}}',
    units: 'Одиницю виміру {{status}}',
    updated: 'оновлено',
    uploadItems: 'Використовуйте цей шаблон, щоб завантажити свої товари.',
    users: 'Користувача {{status}}',
    yourOrderUploaded: 'Ваше замовлення №{{number}} було успішно завантажено',
  },
  orderButtons: {
    invoice: 'Рахунок',
    shipment: 'Відвантаження',
  },
  popupAlerts: {
    dataSuccessfullySaved: 'Дані були успішно збережені!',
    errorInSavingData: 'Під час збереження даних сталася помилка',
    fillModsError: 'Сталася помилка під час заповнення модифікацій товару',
    IDisNotCorrect: 'Невірний ID в URL',
    loginError: "Невірне ім'я користувача або пароль",
    loginSocialError: 'Сталася неочікувана помилка',
    passRecoverSentText: 'Ми надіслали посилання на відновлення пароля на вашу електронну пошту.',
    signUpSuccess: 'Дякуємо за регістрацію!\nМи повідомимо вас про активацію акаунту на електронну пошту.',
    'User is not confirmed.':
      "Обліковий запис користувача не активний. Будь ласка, зв'яжіться з адміністратором",
  },
  productsForm: {
    address: 'Адреса',
    basketEmpty: 'Ваш кошик порожній',
    cart: 'Кошик',
    city: 'Місто',
    contacts: 'Контакти',
    country: 'Країна',
    name: "Ім'я",
    no: 'Ні',
    orderFrom: 'Замовлення від складу',
    outOfStock: 'Немає в наявності',
    phone: 'Телефонний номер',
    preview: "Прев'ю",
    submitOrder: 'Підтвердити',
    summary: 'Підсумок',
    total: 'Всього',
    yes: 'Так',
  },
  signIn: {
    alreadyHaveAccount: 'Вже є аккаунт?',
    backTo: 'Повернутися до',
    checkYourMailbox: 'Перевірте свою поштову скриньку',
    createAccount: 'Створіть обліковий запис!',
    createAccountAndUse: 'Створіть свій обліковий запис і почніть ним користуватися',
    confirmYourEmail: 'Підтвердьте свою електронну адресу',
    continueGoogle: 'Продовжити з Google',
    dontHaveAccount: 'Не маєте облікового запису?',
    fillTheFormToProceed: 'Заповніть форму, щоб завершити реєстрацію',
    firstName: "Ім'я",
    forgotPassword: 'Забули пароль?',
    inUkraine: 'в Україні',
    lastName: 'Прізвище',
    legalPagesInscription: 'Натискаючи «Зареєструватися», ви підтверджуєте, що прочитали та погодилися з',
    or: 'або',
    orLoginWith: 'Або увійдіть за допомогою',
    passRecoverSentText:
      'Ми надіслали вам інструкції щодо відновлення пароля. Також перевірте теку зі спамом, якщо ви не можете знайти наш лист!',
    passRecoverText: 'Введіть свою електронну адресу нижче, щоб отримати інструкції щодо зміни пароля.',
    passwordRecovery: 'Відновлення пароля',
    recoverPassword: 'Відновити пароль',
    registerWith: 'Зареєструйтесь за допомогою',
    rememberMe: "Запам'ятати мене",
    requestText:
      'Щоб використовувати GettStyle, спершу підтвердьте свою електронну адресу. Ми надіслали посилання на',
    requestText1: '. Перевірте папку зі спамом, якщо лист не приходить.',
    signIn: 'Вхід',
    signInToContinue: 'Увійдіть, щоб продовжити',
    signUp: 'Зареєструватися',
    termsAgree: 'Я погоджуюсь з правилами та умовами',
    toSignIn: 'Авторизації',
    welcome: 'Ласкаво просимо!',
    withTermsAndConditions: 'правилами та умовами',
  },
  statuses: {
    open_demands: 'Нових',
    in_progress_demands: 'В роботі',
    closed_demands: 'Закритих',
    total_demands: 'Всього',
  },
  table: {
    actions: 'Дії',
    activity: 'Активність',
    add: 'Додати',
    address: 'Адреса',
    availability: 'Наявність',
    banner: 'Банер',
    block: 'Заблокувати',
    brand: 'Бренд',
    campaign: 'Кампанія',
    carrier: 'Перевізник',
    categories: 'Категорії',
    category: 'Категорія',
    city: 'Місто',
    changeStateTo: 'Змінити стан на:',
    changeStatusTo: 'Змінити статус на:',
    characteristics: 'Характеристики',
    clearFilters: 'Очистити фільтри',
    client: 'Клієнт',
    closed_demands: 'Закритих',
    code: 'Код',
    color: 'Колір',
    copy: 'Копіювати',
    counterparty: 'Контрагент',
    country: 'Країна',
    created: 'Створено',
    currency: 'Валюта',
    currentSum: 'Поточна сума',
    currentPassword: 'Поточний пароль',
    customer: 'Замовник',
    date: 'Дата',
    dateCreated: 'Дата створення',
    deadline_at: 'Дедлайн',
    delete: 'Видалити',
    deleted: 'Видалено',
    discount: 'Знижка, %',
    donor: 'Донор',
    drafted: 'Чернетка',
    email: 'E-mail',
    enumeration: 'Перелік',
    file: 'Файл',
    filters: 'Фільтри',
    firstName: "Ім'я",
    from: 'від',
    fullName: "Повне ім'я",
    goal: 'Мета',
    heightM: 'Висота, м',
    industries: 'Галузі ',
    industry: 'Галузь',
    inquiry: 'Запит',
    inquiryNumber: '№ запиту',
    invoiceNumber: '№ рахунку',
    inventoryNumber: '№ інвентаризації',
    in_progress_demands: 'В роботі',
    issuer: 'Видавець',
    item: 'Товар',
    items: 'Товари',
    label: 'Переклад',
    language: 'Мова',
    lastActivity: 'Остання активність',
    lastLoggedIn: 'Останній вхід',
    lastName: 'Прізвище',
    lastUpdated: 'Останнє оновлення',
    lengthM: 'Довжина, м',
    linkToPage: 'Посилання на сторінку',
    magnitude: 'Величина',
    merchant: 'Продавець',
    mobile: 'Телефонний номер',
    model: 'Модель',
    month: 'Місяць',
    name: 'Назва',
    newPassword: 'Новий пароль',
    newTopic: 'Нова тема',
    noItems: 'Нічого не знайдено',
    open_demands: 'Нових',
    options: 'Опції',
    order: 'Замовлення',
    orderNumber: '№ замовлення',
    organization: 'Організація',
    outOfStock: 'Немає в наявності',
    page: 'Сторінка',
    paid: 'Сплачено',
    parentCategory: 'Верхня категорія',
    payer: 'Платник',
    pcs: 'шт.',
    phone: 'Телефон',
    platform: 'Платформа',
    platforms: 'Платформи',
    posted: 'Опубліковано',
    price: 'Ціна',
    price_retail: 'Ціна роздрібна',
    price_sale: 'Уцінка',
    provider: 'Провайдер',
    reset: 'Скинути',
    quantity: 'К-ть',
    question: 'Питання',
    rank: 'Пріоритет',
    ratio: 'Коефіціент',
    received: 'Отримано',
    region: 'Область',
    recipient: 'Отримувач',
    retail: 'Роздрібний',
    role: 'Роль',
    roles: 'Ролі',
    saveModifications: 'Зберегти модифікації',
    shipmentNumber: '№ відвантаження',
    size: 'Розмір',
    state: 'Стан',
    status: 'Статус',
    supplier: 'Постачальник',
    systemLanguage: 'Мова системи',
    warehouse: 'Склад',
    'stock-item': 'Товар на складі',
    sum: 'Сума',
    summary: 'Підсумок',
    systemName: "Системне ім'я",
    tags: 'Теги',
    title: 'Заголовок',
    topic: 'Тема',
    total: 'Усього',
    translation: 'Переклад',
    translations: 'Переклади',
    type: 'Тип',
    unit: 'Одиниця виміру',
    url: 'URL',
    user: 'Користувач',
    visibility: 'Видимість',
    weightKg: 'Вага, кг',
    widthM: 'Ширина, м',
    website: 'Веб-сайт',
    wholesale: 'Гуртовий',
    year: 'Рік',
  },
  validation: {
    capital: 'Велика літера',
    defaultTranslationRequired: 'Переклад за умовчанням не може бути порожнім',
    email: 'Невірний формат електронної пошти',
    lowercase: 'Мала літера',
    minLength: '8 Символів',
    number: 'Цифра',
    modelAlreadyExists: 'Така модель вже існує',
    nameAlreadyExists: 'Таке ім’я вже існує',
    onlyDigits: 'Тут можуть бути тільки цифри',
    password: 'Ваш пароль не відповідає вимогам',
    passwordsNotMatch: 'Паролі не співпадають',
    required: 'Поле не може бути порожнім ',
    requiredIfFieldsEmpty: 'Заповніть одне з цих полів: {{fields}}',
    requiredIfFieldsNotEmpty: "Це поле є обов'язковим",
    specialChar: 'Спецсимвол',
    urlAlreadyExists: 'Така URL-адреса вже існує',
    year: 'Рік має бути в діапазоні від 1900 до поточного року',
  },
}
