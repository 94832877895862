import React, { useState, useEffect, useRef, useContext, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import { parse } from 'query-string'
import clsx from 'clsx'

import {
  ScreenContext,
  EmptyTable,
  Table,
  useElementRect,
  VirtualizedList,
  ErrorBoundary,
} from '@aidsupply/components'

import { StyledContent } from './styled'
import NavBar from './NavBar'
import EditableTableNavBar from './EditableTableNavBar'
import useEditableTable from './useEditableTable'
import { getTableSummary } from '../../config/tableSummary'
import { EDITABLE_TABLE_NAVBAR_FORM_CONFIG } from '../../config/editableTableNavbarForms'
import SearchProductCard from './SearchProductCard'
import SearchCategoryCard from './SearchCategoryCard'
import { useSorting } from './hooks/useSorting'
import { useTableModeAndSize } from './hooks/useTableModeAndSize'

const TableComponent = ({
  additionalFormValues,
  blockKey,
  CardTableItem,
  cellProps,
  changingBlocksDependencyValue,
  className,
  columns,
  createNewOptions,
  data,
  editable,
  editableNavbarFields,
  formId,
  gap,
  inProgress,
  isDeletionByState,
  isMainTable,
  isReadOnly: isReadOnlyProp,
  maxHeight,
  navbarHidden,
  optionsData,
  onRowClick,
  rowKey,
  selectable,
  setTableMode,
  tableMode,
  typeData,
  // updateFormValue,
  updateTableRows,
  validationRules,
  ...rest
}) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const params = useParams()
  const editedItemId = params?.rightPanelId
  const { search } = location
  const { i18n, t } = useTranslation(['table', 'loadingOrNoData'])
  const gridRef = useRef(null)
  const contentRef = useRef()
  const { top } = useElementRect(contentRef, 'resize')
  const topHeight = top + parseInt(gap, 10) / 2 + 2 || 0

  const { currentBreakpoint, width, sm } = useContext(ScreenContext) || {}

  const searchParsed = parse(search)

  const [gridColCount, setGridColCount] = useState(1)

  const [selectedRows, setSelectedRows] = useState([])
  const [isAllSelected, setAllSelected] = useState(false)

  const initialDataRef = useRef(data)

  const [tableData, setTableData] = useState(data)
  const tableDataWithoutDeleted = isDeletionByState
    ? tableData?.filter((item) => item.state !== 'deleted')
    : tableData

  const withCustomCards = typeData.key === 'products' || typeData.key === 'inquiry_items'

  useEffect(() => {
    setTableData(data)
  }, [data])

  const { isDropdownSearch, onTableResize, onListResize, getMaxTableHeight } = useTableModeAndSize(
    typeData?.key,
    tableDataWithoutDeleted?.length,
    topHeight,
    gap,
    gridRef,
    gridColCount,
    tableMode,
    setTableMode
  )

  const { onBlurCellInput, onDeleteClick } = useEditableTable({
    dispatch,
    isDeletionByState,
    rowKey,
    tableData,
    type: typeData.key,
    initialData: initialDataRef?.current,
    setTableData,
    selectedRows,
    updateTableRows,
  })

  const { sortBy, onColumnSort } = useSorting(
    columns,
    typeData.key,
    tableData,
    setTableData,
    i18n.language,
    cellProps,
    isMainTable
  )

  const resetSelectedRows = useCallback(() => {
    setSelectedRows([])
    setAllSelected(false)
  }, [])

  // TODO: move to ./utils
  const onRowSelect = ({ rowData, rowIndex, selected }) => {
    const selectedValue = typeof selected !== 'boolean' ? selected.selected : selected
    if (!rowData) {
      if (selectedValue) {
        setSelectedRows(tableData.map((val) => val[rowKey || 'id']))
        setAllSelected(true)
      } else {
        resetSelectedRows()
      }
    } else {
      if (selectedValue) {
        setSelectedRows([...selectedRows, rowData[rowKey || 'id']])
      } else {
        setSelectedRows(selectedRows.filter((val) => val !== rowData[rowKey || 'id']))
      }
    }
  }

  useEffect(() => {
    if (tableMode === 'table') {
      setGridColCount(1)
    }
  }, [tableMode])

  const onSetGridColCount = (width) => {
    if (withCustomCards) {
      setGridColCount(width < 500 ? 1 : width < 750 ? 2 : width < 1050 ? 3 : 4)
    } else {
      setGridColCount(width < 650 ? 1 : width < 1050 ? 2 : 3)
    }
  }

  const navBarProps = {
    addBtnDisabled: !tableData?.length && !!searchParsed?.searchValue,
    cellProps,
    columns,
    data,
    isMainTable,
    isDropdownSearch,
    onRowClick,
    resetSelectedRows,
    selectable,
    selectedRows,
    typeData,
  }

  const isReadOnly = isReadOnlyProp || (blockKey === 'shipment_items' && additionalFormValues.order_id)

  if (tableMode === 'table') {
    const summaryData =
      tableData &&
      getTableSummary(typeData.key, t, tableData, {
        state: additionalFormValues?.state,
        currency:
          additionalFormValues?.currency_id?.symbol ||
          optionsData?.currencies?.[additionalFormValues?.currency_id]?.symbol,
      })

    return (
      <>
        <ErrorBoundary>
          {!navbarHidden &&
            (!isReadOnly && editableNavbarFields ? (
              <EditableTableNavBar
                additionalFormValues={additionalFormValues}
                formId={formId}
                changingBlocksDependencyValue={changingBlocksDependencyValue}
                createNewOptions={createNewOptions}
                data={tableData}
                fields={editableNavbarFields}
                // onAddIconImage={onAddIconImage}
                onDeleteClick={onDeleteClick}
                optionsData={optionsData}
                setTableData={setTableData}
                t={t}
                transformAddedResult={EDITABLE_TABLE_NAVBAR_FORM_CONFIG[typeData.key]?.transformAddedResult}
                typeData={typeData}
                updateTableRows={updateTableRows}
                validationRules={validationRules}
                withIconsSelect
              />
            ) : (
              <NavBar {...navBarProps} />
            ))}
        </ErrorBoundary>
        <StyledContent
          ref={contentRef}
          currentBreakpoint={currentBreakpoint}
          topHeight={topHeight}
          maxHeight={maxHeight}
          className={clsx(tableMode, !!(summaryData && tableData?.length) && 'withSummary', className)}
        >
          <Table
            onBlurCellInput={onBlurCellInput}
            // onChangeCellInput={onChangeCellInput}
            // onSubmitCellInput={onSubmitEditCellInput}
            rowKey={rowKey}
            editable={!isReadOnly && editable}
            isReadOnly={isReadOnly}
            emptyText={t(inProgress ? t('loadingOrNoData:loading') : 'noItems')}
            cellProps={cellProps}
            maxHeight={maxHeight || getMaxTableHeight(50, 48)}
            topHeight={topHeight}
            isAllSelected={isAllSelected}
            data={tableDataWithoutDeleted}
            columns={columns}
            onColumnSort={onColumnSort}
            onRowSelect={onRowSelect}
            onRowClick={onRowClick}
            onResize={onTableResize}
            // paddingsAndBorders={(width < sm ? 20 : 40) + 2}
            rowHeight={50}
            selectedRowKeys={selectedRows}
            selectable={selectable}
            sortBy={sortBy}
            rowClassName={({ rowData }) =>
              isMainTable && editedItemId && rowData.id?.toString() === editedItemId && 'activeRow'
            }
            summaryData={summaryData}
            {...rest}
          />
        </StyledContent>
      </>
    )
  }

  // const loadMoreCards = (startIndex, stopIndex, isSearchInitialized) => {
  //   return new Promise((resolve) => {
  //     dispatch(
  //       getTableData({
  //         type,
  //         search: location.search,
  //         pageLimit: maxListRowsCount.current,
  //         pageSkip: startIndex,
  //         isInfiniteScrollPagination: !isSearchInitialized,
  //       })
  //     )
  //     return resolve()
  //   })
  // }

  const getRowHeights = (index) => {
    const characteristicsNumberMaxInRow = tableData.reduce((max, dataItem, i) => {
      if (Math.floor(i / gridColCount) === index) {
        return Math.max(max, dataItem?.general?.characteristics?.length || 0)
      }
      return max
    }, 0)

    const characteristicRowHeight = 77
    if (characteristicsNumberMaxInRow) {
      return 325 + characteristicsNumberMaxInRow * characteristicRowHeight
    }

    return 325
  }

  return (
    <>
      <NavBar {...navBarProps} />
      <StyledContent
        ref={contentRef}
        currentBreakpoint={currentBreakpoint}
        topHeight={topHeight}
        className={clsx(tableMode, typeData.key)}
        maxHeight={
          typeData.key !== 'inquiry_items' &&
          getMaxTableHeight(tableMode === 'cards' ? (typeData.key === 'products' ? 355 : 110) : 100, 24)
        }
      >
        {tableData?.length ? (
          <VirtualizedList
            cellProps={{ container: { props: { cellProps } } }}
            CustomListItem={
              CardTableItem || (typeData.key === 'products' && SearchProductCard)
              // (typeData.key === 'inquiry_items' && SearchCategoryCard)
            }
            rowHeight={
              typeData.key === 'products'
                ? 355
                : typeData.key === 'inquiry_items'
                ? (index) => getRowHeights(index)
                : 115
            }
            onSetColumnCount={onSetGridColCount}
            type={typeData.key}
            editedItemId={editedItemId}
            onItemClick={onRowClick}
            onItemSelect={onRowSelect}
            items={tableDataWithoutDeleted}
            columns={columns}
            selectable={selectable}
            // sortBy={sortBy}
            itemSize={(index) => (tableData.length - 1 === index ? 80 + 40 : 80 + 20)} // tableMode === 'list'
            selectedRowKeys={selectedRows}
            variant={tableMode === 'cards' && 'grid'}
            gridRef={gridRef}
            onResize={onListResize}
            columnCount={gridColCount}
            t={t}
            {...rest}
          />
        ) : (
          <EmptyTable className="emptyList" text={t(inProgress ? t('loadingOrNoData:loading') : 'noItems')} />
        )}
      </StyledContent>
    </>
  )
}

export default TableComponent
