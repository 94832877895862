import React from 'react'
import ReactTexty from 'react-texty'

import {
  Icon,
  Tag,
  Tooltip,
  TranslationsCell,
  convertArrayToObject,
  getCurrencyObjectByCode,
  getPriceWithCurrency,
  getAvailableTranslation,
} from '@aidsupply/components'

import InquiryNumberWithStatusBar from '../components/InquiryNumberWithStatusBar'
import Characteristics from '../components/Stocks/Characteristics'
import { DEFAULT_LANGUAGE } from '../locales'
import { getCurrencyObjectById, getSlugsFromCharacteristicValue } from '../utils/data'
import { formatDateToUserTimezone } from '../utils/dates'
import { getSelectCellValue, headerRenderer } from '../utils/table'
import { getItemTitle } from './columnsEditableTable'
import { STATES_ICONS, STATUSES_ICONS } from './table'
import AddressTableCell from '../components/Table/components/AddressTableCell'

const getStateColumnProps = (t, tableMode, lng) => {
  return {
    key: 'state',
    dataKey: 'state',
    title: 'State',
    width: 35,
    flexShrink: 0,
    resizable: false,
    sortable: true,
    cellRenderer: ({ cellData }) => {
      const id = cellData?.id || cellData
      return STATES_ICONS[id] ? (
        <Tooltip left="8px" text={t(`${cellData}`)} arrowPosition="left">
          <Icon
            className="stateIcon"
            // wrapperHeight={tableMode === 'list' ? '100%' : 'auto'}
            // wrapperWidth={tableMode === 'table' ? '100%' : 'auto'}
            onClick={() => {}}
            name={STATES_ICONS[id]}
            width={14}
            height={14}
            padding="10px"
          />
        </Tooltip>
      ) : (
        ''
      )
    },
    headerRenderer: () =>
      tableMode !== 'list' ? <Icon title={t('state')} name="status" wrapperWidth="100%" /> : '',
  }
}

const getStatusColumnProps = (t, tableMode, lng) => ({
  key: 'status',
  dataKey: 'status',
  title: 'Status',
  width: 35,
  flexShrink: 0,
  resizable: false,
  sortable: true,
  cellRenderer: ({ cellData, column }) => {
    return STATUSES_ICONS[cellData?.id || cellData] ? (
      <Tooltip
        left={tableMode === 'table' ? '8px' : undefined}
        right={tableMode === 'table' ? undefined : '8px'}
        text={t(`${cellData}`)}
        arrowPosition={tableMode === 'table' ? 'left' : 'right'}
      >
        <Icon
          key={column.key}
          className="stateIcon"
          // wrapperHeight={tableMode === 'list' ? '100%' : 'auto'}
          // wrapperWidth={tableMode === 'table' ? '100%' : 'auto'}
          onClick={() => {}}
          name={STATUSES_ICONS[cellData?.id || cellData]}
          width={16}
          padding="10px"
        />
      </Tooltip>
    ) : (
      ''
    )
  },
  headerRenderer: () =>
    tableMode !== 'list' ? <Icon title={t('status')} name="status" wrapperWidth="100%" /> : '',
})

const getDate = (t, tableMode, format) => ({
  key: 'created',
  sortable: true,
  dataKey: 'created_at',
  width: 0,
  flexGrow: 1,
  cellRenderer: ({ cellData }) =>
    cellData ? <ReactTexty>{formatDateToUserTimezone(cellData, format || 'DD.MM.YYYY')}</ReactTexty> : '',
  headerRenderer: headerRenderer(t, tableMode),
})

const getName = (t, tableMode) => ({
  key: 'name',
  dataKey: 'name',
  title: 'name',
  sortable: true,
  width: 0,
  flexGrow: 1,
  headerRenderer: headerRenderer(t, tableMode),
})

const getRecipient = (t, tableMode) => ({
  key: 'recipient',
  sortable: true,
  dataKey: 'recipient_id',
  labelKey: 'name',
  noTranslation: true,
  cellRenderer: getSelectCellValue,
  optionsKeys: ['organizations'],
  width: 0,
  flexGrow: 0.8,
  headerRenderer: headerRenderer(t, tableMode),
})

const getSupplier = (t, tableMode) => ({
  key: 'supplier',
  sortable: true,
  dataKey: 'supplier_id',
  labelKey: 'name',
  noTranslation: true,
  cellRenderer: getSelectCellValue,
  optionsKeys: ['organizations'],
  width: 0,
  flexGrow: 0.8,
  headerRenderer: headerRenderer(t, tableMode),
})

const getWarehouse = (t, tableMode) => ({
  key: 'warehouse',
  dataKey: 'warehouse_id',
  width: 0,
  flexGrow: 1,
  labelKey: 'name',
  noTranslation: true,
  sortable: true,
  cellRenderer: getSelectCellValue,
  headerRenderer: headerRenderer(t, tableMode),
})

const getOrganization = (t, tableMode) => ({
  key: 'organization',
  dataKey: 'organization_id',
  labelKey: 'name',
  optionsKeys: ['organizations'],
  noTranslation: true,
  sortable: true,
  width: 0,
  flexGrow: 1.5,
  headerRenderer: headerRenderer(t, tableMode),
  cellRenderer: getSelectCellValue,
  getIsHidden: ({ role }) => !['administrator', 'system'].includes(role),
})

export const getColumns = (type, lng, t, tableMode) => {
  const COLUMNS_CONFIG = {
    brands: [
      getStateColumnProps(t, tableMode),
      getName(t, tableMode),
      {
        key: 'country',
        dataKey: 'country_id',
        labelKey: `translations.${lng}`,
        optionsKeys: ['countries'],
        sortable: true,
        width: 120,
        noTranslation: true,
        headerRenderer: headerRenderer(t, tableMode),
        cellRenderer: ({ cellData, rowData }) => {
          return <AddressTableCell rowData={rowData} />
        },
      },
      {
        key: 'website',
        dataKey: 'website',
        sortable: true,
        width: 0,
        flexGrow: 1,
        headerRenderer: headerRenderer(t, tableMode),
      },
    ],
    items: [
      getStateColumnProps(t, tableMode),
      {
        key: 'model',
        sortable: true,
        dataKey: 'sku',
        width: 0,
        flexGrow: 0.5,
        headerRenderer: headerRenderer(t, tableMode),
      },
      {
        key: 'title',
        sortable: true,
        dataKey: `translations.${lng}`,
        width: 0,
        flexGrow: 1,
        headerRenderer: headerRenderer(t, tableMode),
        cellRenderer: (props) => (
          <TranslationsCell
            translations={props.rowData.translations}
            defaultLanguage={DEFAULT_LANGUAGE}
            currentLanguage={lng}
          />
        ),
      },
      {
        key: 'brand',
        sortable: true,
        dataKey: 'brand_id',
        noTranslation: true,
        labelKey: 'name',
        width: 0,
        flexGrow: 1,
        headerRenderer: headerRenderer(t, tableMode),
        cellRenderer: getSelectCellValue,
      },
      {
        key: 'category',
        sortable: true,
        dataKey: 'category_id',
        labelKey: 'translations',
        width: 0,
        flexGrow: 1,
        headerRenderer: headerRenderer(t, tableMode),
        cellRenderer: getSelectCellValue,
      },
      {
        key: 'tags',
        dataKey: 'tags',
        headerRenderer: headerRenderer(t, tableMode),
        width: 0,
        flexGrow: 0.8,
        cellRenderer: ({ cellData, column }) =>
          cellData?.length ? (
            <ReactTexty key={column.key}>
              {cellData.map((tag) => (
                <Tag key={tag} colorKey="gray2" text={tag} style={{ marginRight: 3 }} />
              ))}
            </ReactTexty>
          ) : (
            ''
          ),
      },
    ],
    orders: [
      getStateColumnProps(t, tableMode),
      getStatusColumnProps(t, tableMode, lng),
      {
        key: 'orderNumber',
        sortable: true,
        dataKey: 'number',
        width: 0,
        flexGrow: 1,
        headerRenderer: headerRenderer(t, tableMode),
      },
      getDate(t, tableMode),
      {
        key: 'inquiryNumber',
        sortable: true,
        dataKey: 'inquiry_id',
        labelKey: 'number',
        optionsKeys: ['inquiries'],
        noTranslation: true,
        width: 0,
        flexGrow: 1,
        cellRenderer: getSelectCellValue,
        headerRenderer: headerRenderer(t, tableMode),
      },
      getRecipient(t, tableMode),
      getSupplier(t, tableMode),
      getWarehouse(t, tableMode),
      // {
      //   key: 'quantity',
      //   sortable: true,
      //   dataKey: 'items_count',
      //   width: 50,
      //   headerRenderer: headerRenderer(t, tableMode),
      // },
      {
        key: 'sum',
        sortable: true,
        dataKey: 'total',
        width: 0,
        flexGrow: 0.5,
        headerRenderer: headerRenderer(t, tableMode),
        cellRenderer: ({
          rowData,
          cellData,
          container: {
            props: { cellProps },
          },
        }) => {
          const currency = getCurrencyObjectById(rowData.currency_id, cellProps.system?.currencies)

          return (
            <ReactTexty>{currency ? getPriceWithCurrency(cellData || 0, currency) : cellData}</ReactTexty>
          )
        },
      },
      // {
      //   key: 'platform',
      //   title: 'platform',
      //   dataKey: 'general.platform',
      //   optionsKeys: ['platforms'],
      //   labelKey: 'key',
      //   noTranslation: true,
      //   sortable: true,
      //   width: 0,
      //   flexGrow: 0.9,
      //   headerRenderer: headerRenderer(t, tableMode),
      //   cellRenderer: ({ cellData, column, container }) =>
      //     cellData ? (
      //       <ReactTexty key={column.key}>
      //         <Tag colorKey="gray2">
      //           {getSelectCellValue({ cellData, column, container, returnTextOnly: true })}
      //         </Tag>
      //       </ReactTexty>
      //     ) : (
      //       ''
      //     ),
      // },
    ],
    carriers: [
      getStateColumnProps(t, tableMode),
      {
        key: 'name',
        dataKey: 'general.name',
        noTranslation: true,
        sortable: true,
        width: 0,
        flexGrow: 1.5,
        headerRenderer: headerRenderer(t, tableMode),
      },
      {
        key: 'country',
        dataKey: 'general.country',
        sortable: true,
        width: 0,
        flexGrow: 1,
        labelKey: `translations.${lng}`,
        headerRenderer: headerRenderer(t, tableMode),
        cellRenderer: getSelectCellValue,
      },
      // {
      //   key: 'platforms',
      //   dataKey: 'general.platforms',
      //   isMulti: true,
      //   noTranslation: true,
      //   labelKey: 'key',
      //   sortable: false,
      //   width: 0,
      //   flexGrow: 1,
      //   headerRenderer: headerRenderer(t, tableMode),
      //   cellRenderer: getSelectCellValue,
      // },
      {
        ...getDate(t, tableMode),
        dataKey: 'meta.created.date',
      },
    ],
    categories: [
      getStateColumnProps(t, tableMode),
      {
        key: 'name',
        dataKey: 'name',
        sortable: true,
        width: 0,
        flexGrow: 1.5,
        headerRenderer: headerRenderer(t, tableMode),
      },
      {
        key: 'title',
        dataKey: `translations.${lng}`,
        sortable: true,
        width: 0,
        flexGrow: 1.5,
        headerRenderer: headerRenderer(t, tableMode),
        cellRenderer: (props) => (
          <TranslationsCell
            translations={props.rowData.translations}
            defaultLanguage={DEFAULT_LANGUAGE}
            currentLanguage={lng}
          />
        ),
      },
      // {
      //   key: 'tag',
      //   title: 'Tag',
      //   dataKey: `label.${lng}`,
      //   sortable: true,
      //   width: 140,
      //   // flexGrow: 1,
      //   headerRenderer: headerRenderer(t, tableMode),
      //   cellRenderer: ({ cellData }) => (cellData ? <Tag colorKey="gray2">{cellData}</Tag> : ''),
      // },
      {
        key: 'parentCategory',
        optionsKeys: ['categories'],
        // dataKey: 'parent_id',
        dataKey: 'parent_translations',
        // labelKey: 'translations',
        // noTranslation: true,
        sortable: true,
        width: 0,
        flexGrow: 1.5,
        headerRenderer: headerRenderer(t, tableMode),
        cellRenderer: ({ cellData }) => {
          return cellData ? (
            <ReactTexty>{getAvailableTranslation(cellData, DEFAULT_LANGUAGE, lng)}</ReactTexty>
          ) : (
            ''
          )
        },
        // cellRenderer: getSelectCellValue,
      },
      // {
      //   key: 'characteristics',
      //   title: 'Characteristics',
      //   dataKey: 'characteristics',
      //   labelKey: `translations.${lng}`,
      //   width: 0,
      //   flexGrow: 2,
      //   isMulti: true,
      //   optionsKeys: ['attributes'],
      //   headerRenderer: headerRenderer(t, tableMode),
      //   cellRenderer: getSelectCellValue,
      // },
      // {
      //   key: 'properties',
      //   title: 'Properties',
      //   dataKey: 'properties',
      //   labelKey: `translations.${lng}`,
      //   width: 0,
      //   flexGrow: 2,
      //   isMulti: true,
      //   optionsKeys: ['attributes'],
      //   headerRenderer: headerRenderer(t, tableMode),
      //   cellRenderer: getSelectCellValue,
      // },
      {
        ...getDate(t, tableMode),
        key: 'lastUpdated',
        dataKey: 'updated_at',
      },
    ],
    units: [
      getStateColumnProps(t, tableMode),
      {
        key: 'label',
        sortable: true,
        dataKey: `translations.${lng}`,
        width: 0,
        flexGrow: 1,
        headerRenderer: headerRenderer(t, tableMode),
        cellRenderer: (props) => (
          <TranslationsCell
            translations={props.rowData.translations}
            defaultLanguage={DEFAULT_LANGUAGE}
            currentLanguage={lng}
          />
        ),
      },
      {
        key: 'code',
        title: 'Code',
        dataKey: 'code',
        sortable: true,
        width: 100,
        flexShrink: 0,
        resizable: false,
        headerRenderer: headerRenderer(t, tableMode),
      },
      {
        key: 'magnitude',
        title: 'Magnitude',
        dataKey: 'magnitude',
        sortable: true,
        width: 0,
        flexGrow: 0.8,
        cellRenderer: getSelectCellValue,
        headerRenderer: headerRenderer(t, tableMode),
      },
      {
        key: 'coefficient',
        title: 'Coefficient',
        dataKey: 'coefficient',
        sortable: true,
        width: 0,
        flexGrow: 0.8,
        headerRenderer: headerRenderer(t, tableMode),
      },
    ],
    enumerations: [
      getStateColumnProps(t, tableMode),
      {
        key: 'systemName',
        dataKey: 'name',
        sortable: true,
        width: 0,
        flexGrow: 1,
        headerRenderer: headerRenderer(t, tableMode),
      },
      {
        key: 'label',
        sortable: true,
        dataKey: `translations.${lng}`,
        width: 0,
        flexGrow: 1,
        headerRenderer: headerRenderer(t, tableMode),
        cellRenderer: (props) => (
          <TranslationsCell
            translations={props.rowData.translations}
            defaultLanguage={DEFAULT_LANGUAGE}
            currentLanguage={lng}
          />
        ),
      },
      // {
      //   key: 'options',
      //   sortable: true,
      //   dataKey: 'options_count',
      //   width: 0,
      //   flexGrow: 0.3,
      //   headerRenderer: headerRenderer(t, tableMode),
      // },
    ],
    attributes: [
      getStateColumnProps(t, tableMode),
      {
        key: 'label',
        sortable: true,
        dataKey: `translations.${lng}`,
        width: 0,
        flexGrow: 1,
        headerRenderer: headerRenderer(t, tableMode),
        cellRenderer: (props) => (
          <TranslationsCell
            translations={props.rowData.translations}
            defaultLanguage={DEFAULT_LANGUAGE}
            currentLanguage={lng}
          />
        ),
      },
      {
        key: 'type',
        dataKey: 'attribute_type',
        sortable: true,
        width: 0,
        flexGrow: 1,
        cellRenderer: getSelectCellValue,
        headerRenderer: headerRenderer(t, tableMode),
      },
      {
        key: 'enumeration',
        dataKey: 'enumeration_id',
        labelKey: `translations.${lng}`,
        valueKey: 'id',
        noTranslation: true,
        sortable: true,
        width: 0,
        flexGrow: 1,
        cellRenderer: getSelectCellValue,
        headerRenderer: headerRenderer(t, tableMode),
      },
      {
        key: 'unit',
        dataKey: 'unit_id',
        labelKey: `translations.${lng}`,
        noTranslation: true,
        sortable: true,
        width: 0,
        flexGrow: 1,
        cellRenderer: getSelectCellValue,
        headerRenderer: headerRenderer(t, tableMode),
      },
    ],
    warehouses: [
      getStateColumnProps(t, tableMode),
      { ...getName(t, tableMode), dataKey: 'name' },
      // {
      //   key: 'items',
      //   dataKey: 'info.items',
      //   noTranslation: true,
      //   sortable: false,
      //   width: 0,
      //   flexGrow: 1,
      //   headerRenderer: headerRenderer(t, tableMode),
      // },
      {
        key: 'items',
        dataKey: 'stocks_count',
        sortable: true,
        width: 80,
        headerRenderer: headerRenderer(t, tableMode),
      },
      {
        key: 'currency',
        optionsKeys: ['currencies'],
        dataKey: 'currency_id',
        labelKey: 'code',
        valueKey: 'id',
        sortable: true,
        noTranslation: true,
        width: 80,
        headerRenderer: headerRenderer(t, tableMode),
        cellRenderer: getSelectCellValue,
      },
      {
        key: 'type',
        dataKey: 'type',
        optionsKeys: ['warehouseTypes'],
        isMulti: true,
        labelKey: 'label',
        sortable: true,
        width: 0,
        flexGrow: 1.5,
        headerRenderer: headerRenderer(t, tableMode),
        cellRenderer: getSelectCellValue,
      },
      {
        key: 'address',
        labelKey: `translations.${lng}`,
        dataKey: 'city',
        width: 0,
        flexGrow: 1.5,
        sortable: true,
        headerRenderer: headerRenderer(t, tableMode),
        cellRenderer: ({ cellData, rowData }) => {
          return <AddressTableCell rowData={rowData} />
        },
      },
      getOrganization(t, tableMode),
    ],
    'stock-items': [
      getStateColumnProps(t, tableMode),
      getWarehouse(t, tableMode),
      {
        key: 'category',
        title: 'category',
        optionsKeys: ['categories'],
        dataKey: 'category_id',
        width: 0,
        flexGrow: 1.4,
        sortable: true,
        cellRenderer: getSelectCellValue,
        headerRenderer: headerRenderer(t, tableMode),
      },
      {
        key: 'brand',
        optionsKeys: ['brands'],
        dataKey: 'brand_id',
        width: 0,
        flexGrow: 1,
        sortable: true,
        cellRenderer: ({
          cellData,
          container: {
            props: {
              cellProps: {
                system: { brands },
              },
            },
          },
        }) => {
          const brand = brands?.[cellData]

          return brand ? <ReactTexty>{brand.name}</ReactTexty> : ''
        },
        headerRenderer: headerRenderer(t, tableMode),
      },
      getItemTitle(t, lng),
      {
        key: 'characteristics',
        dataKey: 'modification_id',
        width: 0,
        flexGrow: 1.6,
        cellRenderer: ({
          cellData,
          rowData,
          container: {
            props: {
              cellProps: {
                lng,
                system: { attributes, enumerations, items },
              },
            },
          },
        }) => {
          const itemId = rowData.item_id
          const modifications = items?.[itemId]?.modifications

          return (
            <Characteristics
              itemModId={cellData}
              lng={lng}
              attributes={attributes}
              enumerations={enumerations}
              modifications={convertArrayToObject(modifications, 'modification_id')}
              modValue={getSlugsFromCharacteristicValue(
                convertArrayToObject(modifications, 'modification_id')?.[cellData]?.value
              )}
            />
          )
        },
        headerRenderer: headerRenderer(t, tableMode),
      },
      {
        key: 'quantity',
        sortable: true,
        dataKey: 'quantity',
        width: 0,
        flexGrow: 0.5,
        cellRenderer: ({ cellData }) => {
          return (
            <>
              {cellData} {t('pcs')}
            </>
          )
        },
        headerRenderer: headerRenderer(t, tableMode),
      },
      {
        key: 'price',
        sortable: true,
        dataKey: 'price',
        width: 0,
        flexGrow: 0.5,
        cellRenderer: ({
          cellData,
          rowData,
          container: {
            props: {
              cellProps: { system },
            },
          },
        }) => {
          const currency = getCurrencyObjectByCode(rowData.currency, system?.currencies)

          return currency ? getPriceWithCurrency(cellData, currency) : cellData
        },
        headerRenderer: headerRenderer(t, tableMode),
      },
      // {
      //   key: 'price_retail',
      //   sortable: true,
      //   dataKey: 'price_retail',
      //   width: 0,
      //   flexGrow: 0.5,
      //   cellRenderer: ({
      //     cellData,
      //     rowData,
      //     container: {
      //       props: {
      //         cellProps: { system },
      //       },
      //     },
      //   }) => {
      //     const currencyId = system?.warehouses?.[rowData.warehouse_id]?.general?.currency?.id
      //
      //     return currencyId ? getPriceWithCurrency(cellData, system?.currencies?.[currencyId]) : cellData
      //   },
      //   headerRenderer: headerRenderer(t, tableMode),
      // },
      // {
      //   key: 'price_sale',
      //   sortable: true,
      //   dataKey: 'price_sale',
      //   width: 0,
      //   flexGrow: 0.5,
      //   cellRenderer: ({
      //     cellData,
      //     rowData,
      //     container: {
      //       props: {
      //         cellProps: { system },
      //       },
      //     },
      //   }) => {
      //     const currencyId = system?.warehouses?.[rowData.warehouse_id]?.general?.currency?.id
      //     const price = cellData || (rowData.discount && rowData.price_retail * (1 - rowData.discount / 100))
      //
      //     return currencyId ? getPriceWithCurrency(price, system?.currencies?.[currencyId]) : cellData
      //   },
      //   headerRenderer: headerRenderer(t, tableMode),
      // },
      // {
      //   key: 'discount',
      //   sortable: true,
      //   dataKey: 'discount',
      //   width: 0,
      //   flexGrow: 0.4,
      //   cellRenderer: ({ cellData }) => `${cellData}%`,
      //   headerRenderer: headerRenderer(t, tableMode),
      // },
    ],
    users: [
      getStatusColumnProps(t, tableMode, lng),
      {
        key: 'fullName',
        dataKey: 'full_name',
        sortable: true,
        width: 0,
        flexGrow: 1,
        headerRenderer: headerRenderer(t, tableMode),
      },
      {
        key: 'email',
        title: 'E-mail',
        dataKey: 'email',
        sortable: true,
        width: 0,
        flexGrow: 1,
        headerRenderer: headerRenderer(t, tableMode),
      },
      {
        key: 'provider',
        title: 'Provider',
        dataKey: 'provider',
        sortable: true,
        width: 0,
        flexGrow: 0.8,
        headerRenderer: headerRenderer(t, tableMode),
      },
      {
        key: 'role',
        dataKey: 'role',
        sortable: true,
        width: 0,
        flexGrow: 1,
        optionsKeys: ['userRoles'],
        cellRenderer: getSelectCellValue,
        headerRenderer: headerRenderer(t, tableMode),
      },
      {
        key: 'organization',
        dataKey: 'organization_name',
        sortable: true,
        width: 0,
        flexGrow: 1,
        noTranslation: true,
        headerRenderer: headerRenderer(t, tableMode),
      },
      getDate(t, tableMode),
      {
        ...getDate(t, tableMode),
        key: 'lastLoggedIn',
        title: 'Last login',
        dataKey: 'last_logged_at',
      },
    ],
    organizations: [
      getStateColumnProps(t, tableMode),
      getStatusColumnProps(t, tableMode, lng),
      { ...getName(t, tableMode), dataKey: 'name' },
      {
        key: 'type',
        title: 'Type',
        dataKey: 'type',
        optionsKeys: ['organizationTypes'],
        sortable: true,
        width: 0,
        flexGrow: 1,
        headerRenderer: headerRenderer(t, tableMode),
        cellRenderer: getSelectCellValue,
      },
      {
        key: 'roles',
        dataKey: 'roles',
        optionsKeys: ['organizationRoles'],
        isMulti: true,
        labelKey: 'label',
        sortable: true,
        width: 0,
        flexGrow: 1.5,
        headerRenderer: headerRenderer(t, tableMode),
        cellRenderer: getSelectCellValue,
      },
      {
        key: 'email',
        title: 'E-mail',
        dataKey: 'email',
        sortable: true,
        width: 0,
        flexGrow: 1,
        headerRenderer: headerRenderer(t, tableMode),
      },
      {
        key: 'address',
        labelKey: `translations.${lng}`,
        dataKey: 'address',
        width: 0,
        flexGrow: 1.5,
        sortable: true,
        headerRenderer: headerRenderer(t, tableMode),
        cellRenderer: ({ cellData, rowData }) => {
          return <AddressTableCell rowData={rowData} />
        },
      },
      getDate(t, tableMode),
    ],
    products: [
      {
        key: 'category',
        title: 'category',
        optionsKeys: ['categories'],
        dataKey: 'category_id',
        width: 0,
        flexGrow: 1.4,
        sortable: true,
        cellRenderer: getSelectCellValue,
        headerRenderer: headerRenderer(t, tableMode),
      },
      {
        key: 'brand',
        optionsKeys: ['brands'],
        title: 'brand',
        dataKey: 'brand_id',
        width: 0,
        flexGrow: 1,
        sortable: true,
        labelKey: 'general.name',
        cellRenderer: getSelectCellValue,
        headerRenderer: headerRenderer(t, tableMode),
      },
      // {
      //   key: 'item',
      //   dataKey: 'item_id',
      //   width: 0,
      //   flexGrow: 1,
      //   sortable: true,
      //   labelKey: 'general.model',
      //   cellRenderer: getSelectCellValue,
      //   headerRenderer: headerRenderer(t, tableMode),
      // },
      {
        key: 'characteristics',
        dataKey: 'item_mod_id',
        width: 0,
        flexGrow: 1.6,
        cellRenderer: ({
          cellData,
          container: {
            props: {
              cellProps: {
                lng,
                system: { modifications, attributes, enumerations },
              },
            },
          },
        }) => (
          <Characteristics
            itemModId={cellData}
            lng={lng}
            modifications={modifications}
            attributes={attributes}
            enumerations={enumerations}
          />
        ),
        headerRenderer: headerRenderer(t, tableMode),
      },
      // {
      //   key: 'quantity',
      //   sortable: true,
      //   dataKey: 'quantity',
      //   width: 0,
      //   flexGrow: 0.5,
      //   cellRenderer: ({ cellData }) => {
      //     return (
      //       <>
      //         {cellData} {t('pcs')}
      //       </>
      //     )
      //   },
      //   headerRenderer: headerRenderer(t, tableMode),
      // },
      {
        key: 'price_retail',
        sortable: true,
        dataKey: 'price_retail',
        width: 0,
        flexGrow: 0.5,
        cellRenderer: ({
          cellData,
          rowData,
          container: {
            props: {
              cellProps: { system },
            },
          },
        }) => {
          const currencyId = system?.warehouses?.[rowData.warehouse_id]?.general?.currency?.id

          return currencyId ? getPriceWithCurrency(cellData, system?.currencies?.[currencyId]) : cellData
        },
        headerRenderer: headerRenderer(t, tableMode),
      },
      {
        key: 'price_sale',
        sortable: true,
        dataKey: 'price_sale',
        width: 0,
        flexGrow: 0.5,
        cellRenderer: ({
          cellData,
          rowData,
          container: {
            props: {
              cellProps: { system },
            },
          },
        }) => {
          const currencyId = system?.warehouses?.[rowData.warehouse_id]?.general?.currency?.id
          const price = cellData || (rowData.discount && rowData.price_retail * (1 - rowData.discount / 100))

          return currencyId ? getPriceWithCurrency(price, system?.currencies?.[currencyId]) : cellData
        },
        headerRenderer: headerRenderer(t, tableMode),
      },
      {
        key: 'discount',
        sortable: true,
        dataKey: 'discount',
        width: 0,
        flexGrow: 0.4,
        cellRenderer: ({ cellData }) => `${cellData}%`,
        headerRenderer: headerRenderer(t, tableMode),
      },
    ],
    invoices: [
      getStateColumnProps(t, tableMode),
      getStatusColumnProps(t, tableMode, lng),
      getDate(t, tableMode),
      {
        key: 'invoiceNumber',
        sortable: true,
        dataKey: 'number',
        width: 0,
        flexGrow: 1.5,
        headerRenderer: headerRenderer(t, tableMode),
      },
      {
        key: 'orderNumber',
        sortable: true,
        dataKey: 'order_id',
        labelKey: 'number',
        noTranslation: true,
        optionsKeys: ['orders'],
        width: 0,
        flexGrow: 1,
        cellRenderer: getSelectCellValue,
        headerRenderer: headerRenderer(t, tableMode),
      },
      {
        key: 'issuer',
        sortable: true,
        dataKey: 'issuer_id',
        labelKey: 'name',
        noTranslation: true,
        cellRenderer: getSelectCellValue,
        optionsKeys: ['organizations'],
        width: 0,
        flexGrow: 0.8,
        headerRenderer: headerRenderer(t, tableMode),
      },
      {
        key: 'payer',
        sortable: true,
        dataKey: 'payer_id',
        labelKey: 'name',
        noTranslation: true,
        cellRenderer: getSelectCellValue,
        optionsKeys: ['organizations'],
        width: 0,
        flexGrow: 0.8,
        headerRenderer: headerRenderer(t, tableMode),
      },
      {
        key: 'sum',
        sortable: true,
        dataKey: 'total',
        width: 0,
        flexGrow: 0.8,
        headerRenderer: headerRenderer(t, tableMode),
        cellRenderer: ({
          rowData,
          cellData,
          container: {
            props: { cellProps },
          },
        }) => {
          const currency = getCurrencyObjectById(rowData.currency_id, cellProps.system?.currencies)

          return (
            <ReactTexty>{currency ? getPriceWithCurrency(cellData || 0, currency) : cellData}</ReactTexty>
          )
        },
      },
    ],
    shipments: [
      getStateColumnProps(t, tableMode),
      getStatusColumnProps(t, tableMode, lng),
      getDate(t, tableMode),
      {
        key: 'shipmentNumber',
        sortable: true,
        dataKey: 'number',
        width: 0,
        flexGrow: 1,
        headerRenderer: headerRenderer(t, tableMode),
      },
      {
        key: 'orderNumber',
        sortable: true,
        noTranslation: true,
        dataKey: 'order_id',
        labelKey: 'number',
        optionsKeys: ['orders'],
        width: 0,
        flexGrow: 1,
        cellRenderer: getSelectCellValue,
        headerRenderer: headerRenderer(t, tableMode),
      },
      getRecipient(t, tableMode),
      getSupplier(t, tableMode),
      getWarehouse(t, tableMode),
      // {
      //   key: 'quantity',
      //   sortable: true,
      //   dataKey: 'shipment_items_count',
      //   width: 60,
      //   headerRenderer: headerRenderer(t, tableMode),
      // },
    ],
    inquiries: [
      getStateColumnProps(t, tableMode),
      getStatusColumnProps(t, tableMode, lng),
      {
        key: 'inquiryNumber',
        sortable: true,
        dataKey: 'number',
        width: 0,
        flexGrow: 1.5,
        headerRenderer: headerRenderer(t, tableMode),
        cellRenderer: ({ cellData, rowData }) => {
          const { open_demands, closed_demands, in_progress_demands } = rowData
          const totalDemands = open_demands + closed_demands + in_progress_demands

          const getPercentageObject = (key, totalDemands) => {
            return {
              percentage: totalDemands === 0 ? 0 : (rowData[key] / totalDemands) * 100,
              tooltipTitle: t(key),
            }
          }
          const percentages = [
            getPercentageObject('open_demands', totalDemands),
            getPercentageObject('closed_demands', totalDemands),
            getPercentageObject('in_progress_demands', totalDemands),
            { percentage: 100, tooltipTitle: null },
          ]

          return <InquiryNumberWithStatusBar cellData={cellData} percentages={percentages} />
        },
      },
      { ...getDate(t, tableMode), key: 'date' },
      {
        key: 'type',
        sortable: true,
        dataKey: 'recipient_organization_type',
        cellRenderer: ({ cellData, container }) => {
          const {
            props: {
              cellProps: { system },
            },
          } = container
          const organizationSystemType = cellData && system.organizationTypes[cellData]
          return organizationSystemType?.label?.[lng]
        },
        width: 0,
        flexGrow: 1,
        headerRenderer: headerRenderer(t, tableMode),
      },
      {
        key: 'address',
        labelKey: `translations.${lng}`,
        dataKey: 'recipient_city',
        width: 0,
        flexGrow: 1.5,
        sortable: true,
        headerRenderer: headerRenderer(t, tableMode),
        cellRenderer: ({
          cellData,
          rowData,
          container: {
            props: {
              cellProps: {
                system: { organizations },
              },
            },
          },
        }) => {
          const country_id = organizations[rowData.recipient_id]?.country_id
          const region_id = organizations[rowData.recipient_id]?.region_id

          const address = {
            city: cellData,
            country_id: country_id,
            region_id: region_id,
          }

          return <AddressTableCell rowData={address} />
        },
      },
      { ...getRecipient(t, tableMode), flexGrow: 3 },
    ],
    contracts: [
      getStateColumnProps(t, tableMode),
      getDate(t, tableMode),
      getName(t, tableMode),
      {
        key: 'type',
        title: 'Type',
        dataKey: 'general.type',
        optionsKeys: ['contractTypes'],
        sortable: true,
        width: 0,
        flexGrow: 0.7,
        headerRenderer: headerRenderer(t, tableMode),
        cellRenderer: getSelectCellValue,
      },
    ],
    posts: [
      getStateColumnProps(t, tableMode),
      {
        key: 'title',
        dataKey: `translations.${lng}`,
        sortable: true,
        width: 0,
        flexGrow: 1.5,
        headerRenderer: headerRenderer(t, tableMode),
        cellRenderer: (props) => (
          <TranslationsCell
            translations={props.rowData.translations}
            defaultLanguage={DEFAULT_LANGUAGE}
            currentLanguage={lng}
          />
        ),
      },
      {
        key: 'topic',
        dataKey: 'topic_id',
        optionsKeys: ['topics'],
        labelKey: 'translations',
        sortable: true,
        width: 0,
        flexGrow: 1.5,
        headerRenderer: headerRenderer(t, tableMode),
        cellRenderer: getSelectCellValue,
      },
      {
        key: 'visibility',
        dataKey: 'visibility',
        optionsKeys: ['visibilityTypes'],
        sortable: true,
        width: 0,
        flexGrow: 1.5,
        headerRenderer: headerRenderer(t, tableMode),
        cellRenderer: getSelectCellValue,
      },
      // {
      //   key: 'tags',
      //   title: 'Tag',
      //   dataKey: `label.${lng}`,
      //   sortable: true,
      //   width: 140,
      //   // flexGrow: 1,
      //   headerRenderer: headerRenderer(t, tableMode),
      //   cellRenderer: ({ cellData }) => (cellData ? <Tag colorKey="gray2">{cellData}</Tag> : ''),
      // },
      getOrganization(t, tableMode),
      { ...getDate(t, tableMode), key: 'lastUpdated', dataKey: 'updated_at' },
    ],
    campaigns: [
      getStateColumnProps(t, tableMode),
      {
        key: 'title',
        dataKey: `translations.${lng}`,
        sortable: true,
        width: 0,
        flexGrow: 1.5,
        headerRenderer: headerRenderer(t, tableMode),
        cellRenderer: (props) => (
          <TranslationsCell
            translations={props.rowData.translations}
            defaultLanguage={DEFAULT_LANGUAGE}
            currentLanguage={lng}
          />
        ),
      },
      { ...getDate(t, tableMode), key: 'deadline_at', dataKey: 'deadline_at' },
      {
        key: 'goal',
        sortable: true,
        dataKey: 'sum_goal',
        width: 0,
        flexGrow: 1,
        headerRenderer: headerRenderer(t, tableMode),
        cellRenderer: ({
          rowData,
          cellData,
          container: {
            props: { cellProps },
          },
        }) => {
          const currency = getCurrencyObjectById(rowData.currency_id, cellProps.system?.currencies)
          return (
            <ReactTexty>
              {currency?.symbol} {cellData}
            </ReactTexty>
          )
        },
      },
      {
        key: 'currentSum',
        sortable: true,
        dataKey: 'sum_current',
        width: 0,
        flexGrow: 1,
        headerRenderer: headerRenderer(t, tableMode),
        cellRenderer: ({
          rowData,
          cellData,
          container: {
            props: { cellProps },
          },
        }) => {
          const currency = getCurrencyObjectById(rowData.currency_id, cellProps.system?.currencies)
          return (
            <ReactTexty>
              {currency?.symbol} {cellData}
            </ReactTexty>
          )
        },
      },
      getOrganization(t, tableMode),
      { ...getDate(t, tableMode), key: 'created' },
    ],
    faqs: [
      getStateColumnProps(t, tableMode),
      {
        key: 'question',
        dataKey: `translations.${lng}`,
        sortable: true,
        width: 0,
        flexGrow: 1.5,
        headerRenderer: headerRenderer(t, tableMode),
        cellRenderer: (props) => (
          <TranslationsCell
            translations={props.rowData.translations}
            defaultLanguage={DEFAULT_LANGUAGE}
            currentLanguage={lng}
          />
        ),
      },
      { ...getOrganization(t, tableMode), flexGrow: 0.7 },
    ],
    reports: [
      getStateColumnProps(t, tableMode),
      {
        key: 'name',
        dataKey: `translations.${lng}`,
        sortable: true,
        width: 0,
        flexGrow: 1.5,
        headerRenderer: headerRenderer(t, tableMode),
        cellRenderer: (props) => (
          <TranslationsCell
            translations={props.rowData.translations}
            defaultLanguage={DEFAULT_LANGUAGE}
            currentLanguage={lng}
          />
        ),
      },
      {
        key: 'type',
        dataKey: 'period',
        optionsKeys: ['reportTypes'],
        sortable: true,
        width: 0,
        flexGrow: 1.5,
        headerRenderer: headerRenderer(t, tableMode),
        cellRenderer: getSelectCellValue,
      },
      {
        key: 'year',
        dataKey: 'year',
        sortable: true,
        width: 0,
        flexGrow: 1.5,
        headerRenderer: headerRenderer(t, tableMode),
      },
      {
        key: 'month',
        dataKey: 'month',
        optionsKeys: ['months'],
        sortable: true,
        width: 0,
        flexGrow: 1.5,
        headerRenderer: headerRenderer(t, tableMode),
        cellRenderer: getSelectCellValue,
      },
      getOrganization(t, tableMode),
      getDate(t, tableMode),
    ],
    pages: [
      getStateColumnProps(t, tableMode),
      {
        key: 'title',
        dataKey: `translations.${lng}`,
        sortable: true,
        width: 0,
        flexGrow: 1,
        headerRenderer: headerRenderer(t, tableMode),
        cellRenderer: (props) => (
          <TranslationsCell
            translations={props.rowData.translations}
            defaultLanguage={DEFAULT_LANGUAGE}
            currentLanguage={lng}
          />
        ),
      },
      {
        key: 'linkToPage',
        dataKey: 'link',
        sortable: true,
        width: 0,
        flexGrow: 1,
        headerRenderer: headerRenderer(t, tableMode),
      },
      getOrganization(t, tableMode),
    ],
    inventories: [
      getStateColumnProps(t, tableMode),
      {
        key: 'inventoryNumber',
        dataKey: 'number',
        sortable: true,
        width: 0,
        flexGrow: 1.5,
        headerRenderer: headerRenderer(t, tableMode),
      },
      {
        ...getWarehouse(t, tableMode),
        cellRenderer: ({
          cellData,
          rowData,
          container: {
            props: { cellProps },
          },
        }) => {
          const warehouse = cellProps.system?.warehouses?.[cellData]?.name
          const truncatedName =
            warehouse && warehouse.length > 8 ? warehouse.substring(0, 8) + '...' : warehouse
          return (
            <Tooltip left="8px" text={warehouse} arrowPosition="left">
              <Tag colorKey="gray2">{truncatedName}</Tag>
            </Tooltip>
          )
        },
      },
      // {
      //   key: 'items',
      //   dataKey: 'items_count',
      //   sortable: true,
      //   width: 0,
      //   flexGrow: 1,
      //   headerRenderer: headerRenderer(t, tableMode),
      // },
      {
        key: 'user',
        optionsKeys: ['users'],
        dataKey: 'user_id',
        labelKey: 'full_name',
        sortable: true,
        noTranslation: true,
        flexGrow: 1,
        headerRenderer: headerRenderer(t, tableMode),
        cellRenderer: getSelectCellValue,
      },
      getOrganization(t, tableMode),
      getDate(t, tableMode),
      { ...getDate(t, tableMode), key: 'lastUpdated', dataKey: 'updated_at' },
    ],
  }

  return COLUMNS_CONFIG[type]
}
