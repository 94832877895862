import { call, put, select, take, takeLatest } from 'redux-saga/effects'

import { mergeArraysById } from '@aidsupply/components'

import { buildRequest, fetchData } from '../../api'
import { SYSTEM_DATA_KEYS } from '../../apiConstants'
import { ORGANIZATION_ROLES, USER_ROLES } from '../../config'
import {
  FRONTEND_TABLE_ACTIONS,
  CAMPAIGNS_STATUSES_OPTIONS,
  INQUIRY_STATUSES_OPTIONS,
  INVOICE_STATUSES_OPTIONS,
  ORG_OR_USER_STATUSES_OPTIONS,
  SHIPMENT_STATUSES_OPTIONS,
  STATES_OPTIONS,
  STATUSES_OPTIONS,
  ORDER_STATUSES_OPTIONS,
} from '../../config/table'
import {
  ATTRIBUTE_TYPES,
  CONTRACT_TYPES,
  ENUMERATION_ADDONS,
  MAGNITUDES,
  MONTHS,
  ORGANIZATION_TYPES,
  PAGE_TYPES,
  REPORT_TYPES,
  TAXES_TYPE,
  VISIBILITY_TYPES,
  WAREHOUSE_TYPES,
  YEARS,
} from '../../data'
import { mapParamsToUrl, mapUrlToParams } from '../../utils/filters'
import {
  DATA_BULK_OPERATION,
  DATA_BULK_UPDATE,
  DATA_BULK_UPDATE_ERROR,
  DATA_BULK_UPDATE_MAIN_TABLE_SUCCESS,
  DATA_FETCH_SYSTEM_DATA_SUCCESS,
  DATA_FETCH_WITH_FACETS,
  DATA_FETCH_WITH_FACETS_ERROR,
  DATA_FETCH_WITH_FACETS_SUCCESS,
  DATA_SET_SYSTEM_DATA,
  FILTERS_CLEAR_ALL,
  FILTERS_FILTER_ADD,
  FILTERS_FILTER_REMOVE,
  FILTERS_SET_FACET_GROUP_JUST_SELECTED,
  FILTERS_SET_ON_FIRST_LOAD,
  POPUP_ALERT_SHOW,
  SET_DATA_TYPE,
  SIDEBAR_ITEM_SET,
  UPDATE_TABLE_AND_SYSTEM_DATA,
} from '../constants'
import {
  selectAllSystemCollections,
  selectDataInProgressAndError,
  selectSystemCollections,
} from '../selectors'
import { fetchFacets, readSystemData } from './dataFromCommonPackage'
import { watchLiveDataSaga } from './init'

const mapStateToProps = (state) => state

/**
 * Application initialization
 */
function* doSetDataType({ data, initWs }) {
  const type = data.key

  const constants = {
    attributeTypes: ATTRIBUTE_TYPES,
    contractTypes: CONTRACT_TYPES,
    campaignStatuses: Object.values(CAMPAIGNS_STATUSES_OPTIONS),
    organizationTypes: ORGANIZATION_TYPES,
    magnitudes: MAGNITUDES,
    pageTypes: PAGE_TYPES,
    states: Object.values(STATES_OPTIONS),
    statuses: Object.values(STATUSES_OPTIONS),
    organizationRoles: Object.values(ORGANIZATION_ROLES),
    orgOrUsersStatuses: Object.values(ORG_OR_USER_STATUSES_OPTIONS),
    inquiryStatuses: Object.values(INQUIRY_STATUSES_OPTIONS),
    invoiceStatuses: Object.values(INVOICE_STATUSES_OPTIONS),
    shipmentStatuses: Object.values(SHIPMENT_STATUSES_OPTIONS),
    warehouseTypes: WAREHOUSE_TYPES,
    // ukrainianRegions: UKRAINIAN_REGIONS,
    userRoles: Object.values(USER_ROLES),
    visibilityTypes: VISIBILITY_TYPES,
    reportTypes: REPORT_TYPES,
    months: MONTHS,
    years: YEARS,
    enumerationAddons: ENUMERATION_ADDONS,
    orderStatuses: Object.values(ORDER_STATUSES_OPTIONS),
    taxTypes: Object.values(TAXES_TYPE),
  }

  const systemData = yield select(selectAllSystemCollections)
  yield readSystemData(SYSTEM_DATA_KEYS[type], systemData, constants)

  if (initWs) {
    yield watchLiveDataSaga()
  }
}

/**
 * Proxy function (with token verification)
 * @param {Object} payload
 * @returns
 */
function* readApiDataWithFacets(payload) {
  const {
    data: {
      type,
      query,
      apiUrlParam,
      facetsToInclude,
      locationSearch,
      filtersFromUrl,
      mapUrlToParams,
      pageLimit,
      pageOffset,
    },
  } = payload

  const {
    user: {
      details: { role, organization_id },
    },
    data: { apiUrlParam: apiUrlParamFromState },
  } = yield select(mapStateToProps)

  let queryUpdated = query?.length ? query : []

  // pagination
  if (pageLimit) {
    queryUpdated.push({ key: 'limit', value: pageLimit })
  }
  if (pageOffset) {
    queryUpdated.push({ key: 'skip', value: pageOffset })
  }

  if (type === 'inquiry_items') {
    queryUpdated.push({ key: 'is_used_in_inquiries', value: 'true' })
  } else if (type === 'shipments') {
    if (role === 'logistic') {
      queryUpdated.push({ key: 'state', value: 'posted' })
      queryUpdated.push({ key: 'status', value: 'new' })
    }
  } else if (type === 'organizations' && ['merchant-admin', 'donor'].includes(role)) {
    queryUpdated.push({ key: 'id', value: organization_id })
  } else if (['brands', 'items'].includes(type)) {
    if (role === 'merchant-admin') {
      queryUpdated.push({ key: 'general.merchant.id', value: organization_id })
    }
  }

  const filterItems = (items) => {
    if (type === 'shipments' && role === 'logistic') {
      return items.filter((item) => !item.logist_oid) // TODO: move logic to server
    } else if (type === 'inquiries' && role === 'donor') {
      return items.filter((item) => item.state === 'posted')
    }

    return items
  }

  let urlParam
  if (type === 'stock-items') {
    let organizations
    if (['system', 'administrator'].includes(role)) {
      if (!apiUrlParam && !apiUrlParamFromState) {
        const { isSystemDataLoaded } = yield select(selectDataInProgressAndError)
        if (!isSystemDataLoaded) {
          yield take(DATA_FETCH_SYSTEM_DATA_SUCCESS) // Waiting till all system data is loaded
        }
        organizations = yield select(selectSystemCollections('organizations'))
      }
      urlParam = apiUrlParam || apiUrlParamFromState || (organizations && Object.keys(organizations)?.[0])
    } else {
      urlParam = organization_id
    }
  }

  try {
    const {
      data: { isSystemDataLoaded },
    } = yield select(mapStateToProps)
    let commonQuery

    if (!isSystemDataLoaded && type !== 'dashboard') {
      yield take(DATA_FETCH_SYSTEM_DATA_SUCCESS) // Waiting till all system data is loaded
    }

    // First load
    if (filtersFromUrl || locationSearch) {
      const {
        data: { system },
      } = yield select(mapStateToProps)

      // Set active filters
      const { filters, apiQuery } = mapUrlToParams(
        locationSearch?.charAt(0) === '?' ? locationSearch?.substr(1) : locationSearch,
        type,
        system,
        filtersFromUrl
      )
      console.log(filters)
      yield put({ type: FILTERS_SET_ON_FIRST_LOAD, active: filters })

      // Join queryParams from arguments and new params from url => common apiQuery
      if (apiQuery?.length) {
        commonQuery = queryUpdated ? queryUpdated.concat(apiQuery) : apiQuery // TODO: to think if apiQuery and query can have the same key/value pairs
      }
    }

    if (!commonQuery) {
      commonQuery = queryUpdated || []
    }

    const requestItems = yield buildRequest({
      operation: 'read',
      type,
      queryParams: commonQuery,
      apiUrlParam: urlParam,
    })

    let { items, total_items, dashboard } = yield fetchData(requestItems)

    if (items || dashboard) {
      const filteredItems = filterItems ? yield call(filterItems, dashboard || items) : dashboard || items

      yield put({
        type: DATA_FETCH_WITH_FACETS_SUCCESS,
        data: { items: filteredItems, total_items, type },
      })
    } else {
      yield put({
        type: DATA_FETCH_WITH_FACETS_ERROR,
        error: 'noItems',
        data: { type },
      })
    }

    yield fetchFacets({
      type,
      queryParams: commonQuery,
      facetsToInclude,
      apiUrlParam: urlParam,
    })
  } catch (error) {
    console.log(error)
    yield put({ type: DATA_FETCH_WITH_FACETS_ERROR, error, data: { type } })
  }
}

function* includePlatformIndustriesInQuery(type, queryUpdated) {
  if (type === 'categories' || type === 'inquiry_items') {
    const {
      data: {
        system: { platforms },
      },
    } = yield select(mapStateToProps)

    const platformConfig = platforms && Object.values(platforms)[0]

    if (platformConfig?.general?.industries?.length) {
      queryUpdated.push({
        key: 'general.industry.id',
        value: platformConfig.general.industries.map((industry) => industry.id).join(','),
      })
    }

    // if (platformConfig?.general?.categories?.length) {
    //   queryUpdated.push({
    //     key: 'id',
    //     value: platformConfig.general.categories.map(category => category.id).join(','),
    //   })
    // }
  }
}

/**
 * Reads data from API with postprocessing and reducer update
 * @param { object } { data, type }
 */
// query: [{key: '', value: ''}]
function* doReadTableData({
  data: { type, query, locationSearch, lng, apiUrlParam, sortString, pageLimit, pageOffset },
}) {
  try {
    let queryUpdated = query?.length ? query : []

    yield includePlatformIndustriesInQuery(type, queryUpdated)

    yield readApiDataWithFacets({
      data: {
        type,
        query: queryUpdated,
        locationSearch: !FRONTEND_TABLE_ACTIONS[type]?.search && locationSearch,
        mapUrlToParams: mapUrlToParams.bind(null, lng),
        apiUrlParam,
        sortString,
        pageLimit,
        pageOffset,
      },
    })
  } catch (error) {
    yield put({ type: DATA_FETCH_WITH_FACETS_ERROR, error, data: { type } })
  }
}

function* doUpdateTableAndSystemData({
  payload: { dataType, itemToSet, status, updatedData, skipTableDataUpdate },
}) {
  const { data, sidebar } = yield select(mapStateToProps)
  if (!data[dataType] && !data.system[dataType]) {
    return
  }

  let allItemsToSetInTable = updatedData
  let totalItemsCount = data.total_items
  let allItemsToSetInSystem = updatedData

  // sidebar update case
  if (!updatedData && itemToSet) {
    const allCurrentSystemItems = data.system[dataType] && Object.values(data.system[dataType])
    // check status names when backend is ready
    if (status === 'create' || status === STATUSES_OPTIONS.new.id) {
      allItemsToSetInSystem = allCurrentSystemItems && [itemToSet, ...allCurrentSystemItems]
      allItemsToSetInTable = data[dataType] && [itemToSet, ...data[dataType]]
      totalItemsCount = totalItemsCount + 1
    } else if (status === 'update') {
      allItemsToSetInSystem =
        allCurrentSystemItems &&
        allCurrentSystemItems.map((item) => (itemToSet.id === item.id ? itemToSet : item))
      allItemsToSetInTable =
        data[dataType] && data[dataType].map((item) => (itemToSet.id === item.id ? itemToSet : item))
    }
  }

  // bulk update and sidebar update cases (TODO: check stock-items also)
  if (allItemsToSetInSystem) {
    if (data.system[dataType]) {
      yield put({ type: DATA_SET_SYSTEM_DATA, key: dataType, data: allItemsToSetInSystem })
    }
  }

  if (allItemsToSetInTable) {
    const skipTableUpdate = skipTableDataUpdate || !data[dataType]
    //   || (itemToSet &&
    //   status === 'update' &&
    //   !data[dataType].some((dataItem) => dataItem.id === itemToSet.id)) ||
    // (itemToSet && status === 'create' && data[dataType].some((dataItem) => dataItem.id === itemToSet.id))

    if (!skipTableUpdate) {
      yield put({
        type: DATA_FETCH_WITH_FACETS_SUCCESS,
        data: {
          items: allItemsToSetInTable,
          type: dataType,
          total_items: updatedData ? updatedData.length : totalItemsCount,
        },
      })
    }
  }

  // case when edit sidebar is opened (if bulk update)
  if (sidebar.initial?.id) {
    // sidebar item updated or
    // if (itemToSet && itemToSet.id === sidebar.initial.id) {
    //   yield put({ type: SIDEBAR_ITEM_SET, item: itemToSet })
    // } else
    if (updatedData?.length) {
      const item = updatedData.find((dataItem) => dataItem.id === sidebar.initial.id)
      yield put({ type: SIDEBAR_ITEM_SET, item })
    }
  }
}

/**
 * Updates multiple objects with specific value
 * @param { object } { data, type }
 */
function* doBulkUpdate({ data }) {
  const { requestBody, type } = data

  const {
    data: { system },
  } = yield select(mapStateToProps)

  try {
    const request = yield buildRequest({
      id: 'bulk',
      operation: 'update',
      type,
      requestBody,
    })

    yield put({ type: DATA_BULK_UPDATE })

    const items = yield fetchData(request)
    const itemsTransformed = (items?.items || items?.values || items).map((item) =>
      item.id ? item : { ...item, id: item._id }
    )

    yield put({ type: DATA_BULK_UPDATE_MAIN_TABLE_SUCCESS, items: itemsTransformed })

    const systemDataToUpdate = system[type] && Object.values(system[type])
    yield doUpdateTableAndSystemData({
      payload: {
        dataType: type,
        updatedData: systemDataToUpdate && mergeArraysById(systemDataToUpdate, itemsTransformed),
        skipTableDataUpdate: true,
      },
    })

    yield put({ type: POPUP_ALERT_SHOW, data: { contentKey: 'dataSuccessfullySaved', type: 'success' } })
  } catch (error) {
    yield put({ type: DATA_BULK_UPDATE_ERROR, error })
    yield put({ type: POPUP_ALERT_SHOW, data: { contentKey: 'errorInSavingData', type: 'error' } })
  }
}

/**
 * Being triggered on filters update
 */
function* doUpdateFilters({ key, value, lng, type: actionType, locationSearch, navigate }) {
  const { data, filters } = yield select(mapStateToProps)
  const type = data?.type?.key

  const { apiQuery, urlQuery } = mapParamsToUrl(filters.active, filters.facets, type, locationSearch, lng)

  const queryUpdated = [...apiQuery]

  if (actionType === FILTERS_FILTER_REMOVE && !Object.values(filters.active)?.flat()?.length) {
    yield includePlatformIndustriesInQuery(type, queryUpdated)
  }

  const readArgs = { data: { type, query: queryUpdated, lng } }

  // if (isObjectEmpty(filters.active)) {
  //   yield doReadTableData(readArgs)
  // } else {
  //   yield readApiDataWithFacets(readArgs)
  // }

  // TODO: if error, do not change pathname
  navigate({ search: urlQuery })
  yield put({ type: FILTERS_SET_FACET_GROUP_JUST_SELECTED, facetJustSelected: { key, value } })
}

export default function* dataSaga() {
  return [
    yield takeLatest(DATA_FETCH_WITH_FACETS, doReadTableData),
    yield takeLatest(DATA_BULK_OPERATION, doBulkUpdate),
    yield takeLatest([FILTERS_FILTER_ADD, FILTERS_FILTER_REMOVE, FILTERS_CLEAR_ALL], doUpdateFilters),
    yield takeLatest(SET_DATA_TYPE, doSetDataType),
    yield takeLatest(UPDATE_TABLE_AND_SYSTEM_DATA, doUpdateTableAndSystemData),
  ]
}

export { fetchData }
