import React from 'react'
import ReactTexty from 'react-texty'
import { getAvailableTranslation } from '@aidsupply/components'

import { useTranslation } from 'react-i18next'
import { useMappedState } from '../../../hooks'
import { selectAllSystemCollections } from '../../../redux/selectors'
import { DEFAULT_LANGUAGE } from '../../../locales'
import { countryCodeToFlagEmoji } from '../../../utils/data'

const AddressTableCell = ({ cellData, rowData }) => {
  const {
    i18n: { language: lng },
  } = useTranslation()

  const system = useMappedState(selectAllSystemCollections)

  const { city, country_id, region_id } = rowData || {}

  const country = getAvailableTranslation(
    system?.countries?.[country_id]?.translations,
    DEFAULT_LANGUAGE,
    lng
  )
  const region = getAvailableTranslation(
    system?.country_subdivisions?.[region_id]?.translations,
    DEFAULT_LANGUAGE,
    lng
  )
  const countryFlag = countryCodeToFlagEmoji(system?.countries?.[country_id]?.alpha2code)

  const address = [country, region, city].filter(Boolean).join(', ')

  return (
    <ReactTexty>
      {countryFlag}&nbsp;&nbsp;{address}
    </ReactTexty>
  )
}

export default AddressTableCell
