module.exports = {
  breakpoints: {
    xxl: 1440,
    xl: 1024,
    lg: 768,
    md: 550,
    sm: 400,
    xs: 0,
    xxs: 0,
  },
  gutter: {
    container: {
      side: {
        md: 20,
        base: 10,
        xl: 40,
        lg: 30,
        sm: 15,
        xs: 10,
        xxs: 10,
      },
    },
  },
  grid: {
    xxl: {
      columns: { count: 12, gapWidth: '40px' },
      rightPanelCols: 3.5,
      leftPanelCols: 2.5,
      rightPanelColsWide: 5,
    },
    xl: {
      columns: { count: 12, gapWidth: '30px' },
      rightPanelCols: 3.5,
      leftPanelCols: 2.5,
      rightPanelColsWide: 5,
    },
    lg: {
      columns: { count: 12, gapWidth: '20px' },
      rightPanelCols: 3.5,
      leftPanelCols: 2.5,
      rightPanelColsWide: 5,
    },
    md: {
      columns: { count: 6, gapWidth: '20px' },
      rightPanelCols: 3.5,
      leftPanelCols: 2.5,
      rightPanelColsWide: 5,
    },
    sm: {
      columns: { count: 4, gapWidth: '10px' },
      rightPanelCols: 4,
      leftPanelCols: 4,
      rightPanelColsWide: 4,
    },
    xs: {
      columns: { count: 4, gapWidth: '10px' },
      rightPanelCols: 4,
      leftPanelCols: 4,
      rightPanelColsWide: 4,
    },
    xxs: {
      columns: { count: 4, gapWidth: '10px' },
      rightPanelCols: 4,
      leftPanelCols: 4,
      rightPanelColsWide: 4,
    },
  },
  color: {
    primary: {
      lightest: '#E6F0FF',
      lighter: '#99C1FF',
      light: '#66A3FF',
      main: '#0065FF',
      dark: '#0051EB',
      darker: '#003DD7',
      darkest: '#000087',
    },
    secondary: {
      lightest: '#FFF7E5',
      lighter: '#FFE6B3',
      light: '#FDCE11',
      main: '#FFAB00',
      dark: '#FB9715',
      darker: '#FF762A',
      darkest: '#F86216',
    },
    general: {
      dark: '#172B4D',
      light: '#FFFFFF',
      gray1: '#F1F3F4',
      gray2: '#CBD2DE',
      gray3: '#8B95A6',
      gray4: '#0D2143',
      gray5: '#0A183A',
      gray6: '#000224',
      gray7: '#000224',
    },
    status: {
      success: '#36B37E',
      warning: '#FFC400',
      error: '#DE350B',
      new: '#0065FF',
    },
  },
  font: {
    family: {
      primary: 'Manrope, Lato, Open Sans, Arial, sans-serif',
      primaryCondensed: 'Manrope, Lato, Open Sans, Arial, sans-serif',
      secondary: 'Manrope, Lato, Open Sans, Arial, sans-serif',
      secondaryCondensed: 'Manrope, Lato, Open Sans, Arial, sans-serif',
    },
    size: {
      h1: {
        value: '34px',
        lineHeight: '42px',
        name: 'font-size-h1',
      },
      h2: {
        value: '28px',
        lineHeight: '34px',
        name: 'font-size-h2',
      },
      h3: {
        value: '18px',
        lineHeight: '25px',
        name: 'font-size-h3',
      },
      h4: {
        value: '16px',
        lineHeight: '22px',
        name: 'font-size-h4',
      },
      body1: {
        value: '14px',
        lineHeight: '20px',
        name: 'font-size-body1',
      },
      body2: {
        value: '12px',
        lineHeight: '16px',
        name: 'font-size-body2',
      },
      button1: {
        value: '14px',
        lineHeight: '20px',
        name: 'font-size-button1',
      },
      button2: {
        value: '12px',
        lineHeight: '17px',
        name: 'font-size-button2',
      },
      link: {
        value: '14px',
        lineHeight: '16px',
        name: 'font-size-link',
      },
    },
    weight: {
      light: 300,
      regular: 400,
      medium: 500,
      bold: 600,
      black: 700,
    },
  },
  size: {
    border: {
      radius: {
        main: '6px',
        smaller: '4px',
        bigger: '8px',
      },
    },
    height: {
      header: {
        mobile: '76px',
      },
      footer: {
        desktop: '72px',
        mobile: '83px',
      },
    },
  },
  get components() {
    return {
      header: {
        standard: {
          regular: {
            large: {
              color: this.color.general.dark,
              'background-color': this.color.general.light,
              name: 'header-standard-regular-large',
            },
            small: {
              color: this.color.general.dark,
              'background-color': this.color.general.light,
              name: 'header-standard-regular-small',
            },
          },
        },
      },
      button: {
        standard: {
          primary: {
            large: {
              color: this.color.general.light,
              'background-color': this.color.primary.main,
              'line-height': this.font.size.button1.lineHeight,
              'font-size': this.font.size.button1.value,
              'font-weight': this.font.weight.bold,
              'font-family': this.font.family.primary,
              'border-radius': this.size.border.radius.main,
              padding: '9px 14px',
              name: 'button-standard-primary-large',
            },
            small: {
              color: this.color.general.light,
              'background-color': this.color.primary.main,
              'line-height': this.font.size.button1.lineHeight,
              'font-size': this.font.size.button1.value,
              'font-weight': this.font.weight.bold,
              'font-family': this.font.family.primary,
              'border-radius': this.size.border.radius.main,
              padding: '5px 14px',
              name: 'button-standard-primary-small',
            },
          },
          secondary: {
            large: {
              color: this.color.general.light,
              'background-color': this.color.secondary.main,
              'line-height': '20px',
              'font-size': '16px',
              'font-weight': this.font.weight.bold,
              'font-family': this.font.family.primary,
              'border-radius': this.size.border.radius.main,
              padding: '9px 14px',
              name: 'button-standard-secondary-large',
            },
            small: {
              color: this.color.general.light,
              'background-color': this.color.secondary.main,
              'line-height': '20px',
              'font-size': '16px',
              'font-weight': this.font.weight.bold,
              'font-family': this.font.family.primary,
              'border-radius': this.size.border.radius.main,
              padding: '5px 14px',
              name: 'button-standard-secondary-small',
            },
          },
          bordered: {
            large: {
              color: this.color.general.dark,
              'line-height': this.font.size.button1.lineHeight,
              'font-size': this.font.size.button1.value,
              'background-color': this.color.general.light,
              'font-weight': this.font.weight.bold,
              'font-family': this.font.family.primary,
              'border-radius': this.size.border.radius.main,
              border: `1px solid ${this.color.general.gray2}`,
              padding: '9px 14px',
              name: 'button-standard-bordered-large',
            },
            small: {
              color: this.color.general.dark,
              'line-height': this.font.size.button1.lineHeight,
              'font-size': this.font.size.button1.value,
              'background-color': this.color.general.light,
              'font-weight': this.font.weight.bold,
              'font-family': this.font.family.primary,
              'border-radius': this.size.border.radius.main,
              border: `1px solid ${this.color.general.gray2}`,
              padding: '5px 14px',
              name: 'button-standard-bordered-small',
            },
          },
          disabled: {
            large: {
              color: this.color.general.gray3,
              'background-color': this.color.general.gray1,
              'line-height': this.font.size.button1.lineHeight,
              'font-size': this.font.size.button1.value,
              'font-weight': this.font.weight.bold,
              'font-family': this.font.family.primary,
              'border-radius': this.size.border.radius.main,
              border: '1px solid transparent',
              padding: '9px 14px',
              name: 'button-standard-disabled-large',
            },
            small: {
              color: this.color.general.gray3,
              'background-color': this.color.general.gray1,
              'line-height': this.font.size.button1.lineHeight,
              'font-size': this.font.size.button1.value,
              'font-weight': this.font.weight.bold,
              'font-family': this.font.family.primary,
              'border-radius': this.size.border.radius.main,
              border: '1px solid transparent',
              padding: '5px 14px',
              name: 'button-standard-disabled-small',
            },
          },
          success: {
            large: {
              color: this.color.general.light,
              'line-height': this.font.size.button1.lineHeight,
              'font-size': this.font.size.button1.value,
              'background-color': this.color.status.success,
              'font-weight': this.font.weight.bold,
              'font-family': this.font.family.primary,
              'border-radius': this.size.border.radius.main,
              name: 'button-standard-secondary-large',
            },
            small: {
              color: this.color.general.light,
              'line-height': this.font.size.button1.lineHeight,
              'font-size': this.font.size.button1.value,
              'background-color': this.color.status.success,
              'font-weight': this.font.weight.bold,
              'font-family': this.font.family.primary,
              'border-radius': this.size.border.radius.main,
              name: 'button-standard-success-small',
            },
          },
        },
      },
      typography: {
        h1: {
          black: {
            large: {
              color: this.color.general.dark,
              'line-height': this.font.size.h1.lineHeight,
              'font-size': this.font.size.h1.value,
              'font-weight': this.font.weight.black,
              'font-family': this.font.family.primary,
              name: 'typography-h1-black-large',
            },
            medium: {
              color: this.color.general.dark,
              'line-height': this.font.size.h1.lineHeight,
              'font-size': this.font.size.h1.value,
              'font-weight': this.font.weight.black,
              'font-family': this.font.family.primary,
              name: 'typography-h1-black-medium',
            },
            small: {
              color: this.color.general.dark,
              'line-height': this.font.size.h3.lineHeight,
              'font-size': this.font.size.h3.value,
              'font-weight': this.font.weight.black,
              'font-family': this.font.family.primary,
              name: 'typography-h1-black-small',
            },
          },
        },
        h2: {
          black: {
            large: {
              color: this.color.general.dark,
              'line-height': this.font.size.h2.lineHeight,
              'font-size': this.font.size.h2.value,
              'font-weight': this.font.weight.black,
              'font-family': this.font.family.primary,
              name: 'typography-h2-black-large',
            },
            medium: {
              color: this.color.general.dark,
              'line-height': this.font.size.h2.lineHeight,
              'font-size': this.font.size.h2.value,
              'font-weight': this.font.weight.black,
              'font-family': this.font.family.primary,
              name: 'typography-h2-black-medium',
            },
            small: {
              color: this.color.general.dark,
              'line-height': this.font.size.h3.lineHeight,
              'font-size': this.font.size.h3.value,
              'font-weight': this.font.weight.black,
              'font-family': this.font.family.primary,
              name: 'typography-h2-black-small',
            },
          },
        },
        h3: {
          black: {
            large: {
              color: this.color.general.dark,
              'line-height': this.font.size.h3.lineHeight,
              'font-size': this.font.size.h3.value,
              'font-weight': this.font.weight.black,
              'font-family': this.font.family.primary,
              name: 'typography-h3-black-large',
            },
            medium: {
              color: this.color.general.dark,
              'line-height': this.font.size.h3.lineHeight,
              'font-size': this.font.size.h3.value,
              'font-weight': this.font.weight.black,
              'font-family': this.font.family.primary,
              name: 'typography-h3-black-medium',
            },
            small: {
              color: this.color.general.dark,
              'line-height': this.font.size.h3.lineHeight,
              'font-size': this.font.size.h3.value,
              'font-weight': this.font.weight.black,
              'font-family': this.font.family.primary,
              name: 'typography-h3-black-small',
            },
          },
        },
        h4: {
          black: {
            large: {
              color: this.color.general.dark,
              'line-height': this.font.size.h4.lineHeight,
              'font-size': this.font.size.h4.value,
              'font-weight': this.font.weight.bold,
              'font-family': this.font.family.primary,
              name: 'typography-h4-black-large',
            },
            medium: {
              color: this.color.general.dark,
              'line-height': this.font.size.h4.lineHeight,
              'font-size': this.font.size.h4.value,
              'font-weight': this.font.weight.bold,
              'font-family': this.font.family.primary,
              name: 'typography-h4-black-medium',
            },
            small: {
              color: this.color.general.dark,
              'line-height': this.font.size.h4.lineHeight,
              'font-size': this.font.size.h4.value,
              'font-weight': this.font.weight.bold,
              'font-family': this.font.family.primary,
              name: 'typography-h4-black-small',
            },
          },
        },
        caption1: {
          black: {
            large: {
              color: this.color.general.dark,
              'line-height': this.font.size.button1.lineHeight,
              'font-size': this.font.size.button1.value,
              'font-weight': this.font.weight.bold,
              'font-family': this.font.family.primary,
              name: 'typography-caption1-black-large',
            },
            medium: {
              color: this.color.general.dark,
              'line-height': this.font.size.button1.lineHeight,
              'font-size': this.font.size.button1.value,
              'font-weight': this.font.weight.bold,
              'font-family': this.font.family.primary,
              name: 'typography-caption1-black-medium',
            },
            small: {
              color: this.color.general.dark,
              'line-height': this.font.size.button1.lineHeight,
              'font-size': this.font.size.button1.value,
              'font-weight': this.font.weight.black,
              'font-family': this.font.family.primary,
              name: 'typography-caption1-black-small',
            },
          },
        },
        caption2: {
          black: {
            large: {
              color: this.color.general.dark,
              'line-height': this.font.size.button2.lineHeight,
              'font-size': this.font.size.button2.value,
              'font-weight': this.font.weight.bold,
              'font-family': this.font.family.primary,
              name: 'typography-caption2-black-large',
            },
            medium: {
              color: this.color.general.dark,
              'line-height': this.font.size.button2.lineHeight,
              'font-size': this.font.size.button2.value,
              'font-weight': this.font.weight.bold,
              'font-family': this.font.family.primary,
              name: 'typography-caption2-black-medium',
            },
            small: {
              color: this.color.general.dark,
              'line-height': this.font.size.button2.lineHeight,
              'font-size': this.font.size.button2.value,
              'font-weight': this.font.weight.bold,
              'font-family': this.font.family.primary,
              name: 'typography-caption2-black-small',
            },
          },
        },
        caption3: {
          black: {
            large: {
              color: this.color.general.dark,
              'line-height': this.font.size.button2.lineHeight,
              'font-size': this.font.size.button2.value,
              'font-weight': this.font.weight.bold,
              'font-family': this.font.family.primary,
              name: 'typography-caption3-black-large',
            },
            medium: {
              color: this.color.general.dark,
              'line-height': this.font.size.button2.lineHeight,
              'font-size': this.font.size.button2.value,
              'font-weight': this.font.weight.bold,
              'font-family': this.font.family.primary,
              name: 'typography-caption3-black-medium',
            },
            small: {
              color: this.color.general.dark,
              'line-height': this.font.size.button2.lineHeight,
              'font-size': this.font.size.button2.value,
              'font-weight': this.font.weight.bold,
              'font-family': this.font.family.primary,
              name: 'typography-caption3-black-small',
            },
          },
        },
        body1: {
          black: {
            large: {
              color: this.color.general.dark,
              'line-height': this.font.size.body1.lineHeight,
              'font-size': this.font.size.body1.value,
              'font-weight': this.font.weight.regular,
              'font-family': this.font.family.primary,
              name: 'typography-body1-black-large',
            },
            medium: {
              color: this.color.general.dark,
              'line-height': this.font.size.body1.lineHeight,
              'font-size': this.font.size.body1.value,
              'font-weight': this.font.weight.regular,
              'font-family': this.font.family.primary,
              name: 'typography-body1-black-medium',
            },
            small: {
              color: this.color.general.dark,
              'line-height': this.font.size.body1.lineHeight,
              'font-size': this.font.size.body1.value,
              'font-weight': this.font.weight.regular,
              'font-family': this.font.family.primary,
              name: 'typography-body1-black-small',
            },
          },
        },
        body2: {
          black: {
            large: {
              color: this.color.general.dark,
              'line-height': this.font.size.body2.lineHeight,
              'font-size': this.font.size.body2.value,
              'font-weight': this.font.weight.regular,
              'font-family': this.font.family.primary,
              name: 'typography-body2-black-large',
            },
            medium: {
              color: this.color.general.dark,
              'line-height': this.font.size.body2.lineHeight,
              'font-size': this.font.size.body2.value,
              'font-weight': this.font.weight.regular,
              'font-family': this.font.family.primary,
              name: 'typography-body2-black-medium',
            },
            small: {
              color: this.color.general.dark,
              'line-height': this.font.size.body2.lineHeight,
              'font-size': this.font.size.body2.value,
              'font-weight': this.font.weight.regular,
              'font-family': this.font.family.primary,
              name: 'typography-body2-black-small',
            },
          },
        },
        link: {
          black: {
            large: {
              color: this.color.general.dark,
              'line-height': this.font.size.link.lineHeight,
              'font-size': this.font.size.link.value,
              'font-weight': this.font.weight.medium,
              'font-family': this.font.family.primary,
              name: 'typography-link-black-large',
            },
            medium: {
              color: this.color.general.dark,
              'line-height': this.font.size.link.lineHeight,
              'font-size': this.font.size.link.value,
              'font-weight': this.font.weight.medium,
              'font-family': this.font.family.primary,
              name: 'typography-link-black-medium',
            },
            small: {
              color: this.color.general.dark,
              'line-height': this.font.size.link.lineHeight,
              'font-size': this.font.size.link.value,
              'font-weight': this.font.weight.medium,
              'font-family': this.font.family.primary,
              name: 'typography-link-black-small',
            },
          },
        },
        button1: {
          black: {
            large: {
              color: this.color.general.dark,
              'line-height': this.font.size.button1.lineHeight,
              'font-size': this.font.size.button1.value,
              'font-weight': this.font.weight.bold,
              'font-family': this.font.family.primary,
              name: 'typography-button1-black-large',
            },
            medium: {
              color: this.color.general.dark,
              'line-height': this.font.size.button1.lineHeight,
              'font-size': this.font.size.button1.value,
              'font-weight': this.font.weight.bold,
              'font-family': this.font.family.primary,
              name: 'typography-button1-black-medium',
            },
            small: {
              color: this.color.general.dark,
              'line-height': this.font.size.button1.lineHeight,
              'font-size': this.font.size.button1.value,
              'font-weight': this.font.weight.bold,
              'font-family': this.font.family.primary,
              name: 'typography-button1-black-small',
            },
          },
        },
        button2: {
          black: {
            large: {
              color: this.color.general.dark,
              'line-height': this.font.size.button2.lineHeight,
              'font-size': this.font.size.button2.value,
              'font-weight': this.font.weight.bold,
              'font-family': this.font.family.primary,
              name: 'typography-button2-black-large',
            },
            medium: {
              color: this.color.general.dark,
              'line-height': this.font.size.button2.lineHeight,
              'font-size': this.font.size.button2.value,
              'font-weight': this.font.weight.bold,
              'font-family': this.font.family.primary,
              name: 'typography-button2-black-medium',
            },
            small: {
              color: this.color.general.dark,
              'line-height': this.font.size.button2.lineHeight,
              'font-size': this.font.size.button2.value,
              'font-weight': this.font.weight.bold,
              'font-family': this.font.family.primary,
              name: 'typography-button2-black-small',
            },
          },
        },
        input: {
          black: {
            large: {
              color: this.color.general.dark,
              'line-height': this.font.size.body1.lineHeight,
              'font-size': this.font.size.body1.value,
              'font-weight': this.font.weight.regular,
              'font-family': this.font.family.primary,
              name: 'typography-input-black-large',
            },
            medium: {
              color: this.color.general.dark,
              'line-height': this.font.size.body1.lineHeight,
              'font-size': this.font.size.body1.value,
              'font-weight': this.font.weight.regular,
              'font-family': this.font.family.primary,
              name: 'typography-input-black-medium',
            },
            small: {
              color: this.color.general.dark,
              'line-height': this.font.size.body1.lineHeight,
              'font-size': this.font.size.body1.value,
              'font-weight': this.font.weight.regular,
              'font-family': this.font.family.primary,
              name: 'typography-input-black-small',
            },
          },
        },
      },
      link: {
        standard: {
          main: {
            large: {
              'line-height': this.font.size.link.lineHeight,
              'font-size': this.font.size.link.value,
              'font-weight': this.font.weight.medium,
              'font-family': this.font.family.primary,
              name: 'link-standard-main-large',
            },
          },
        },
      },
      input: {
        primary: {
          black: {
            large: {
              color: this.color.general.dark,
              'background-color': this.color.general.light,
              'line-height': '20px',
              'font-size': '14px',
              'font-weight': this.font.weight.regular,
              'font-family': this.font.family.primary,
              'border-radius': '6px',
              padding: '9px 16px',
              'box-shadow': '0px 1px 2px 0px rgba(24, 24, 28, 0.04), 0px 3px 4px -5px rgba(24, 24, 28, 0.03)',
              name: 'input-primary-black-large',
            },
            small: {
              color: this.color.general.dark,
              'background-color': this.color.general.light,
              'line-height': '22px',
              'font-size': '16px',
              'font-weight': this.font.weight.regular,
              'font-family': this.font.family.primary,
              'border-radius': '6px',
              padding: '9px 16px',
              'box-shadow': '0px 1px 2px 0px rgba(24, 24, 28, 0.04), 0px 3px 4px -5px rgba(24, 24, 28, 0.03)',
              name: 'input-primary-black-small',
            },
          },
        },
        secondary: {
          black: {
            large: {
              color: this.color.general.dark,
              'background-color': this.color.general.light,
              'line-height': '20px',
              'font-size': '14px',
              'font-weight': this.font.weight.regular,
              'font-family': this.font.family.primary,
              'border-radius': '6px',
              padding: '9px 16px',
              'box-shadow': '0px 1px 2px 0px rgba(24, 24, 28, 0.04), 0px 3px 4px -5px rgba(24, 24, 28, 0.03)',
              name: 'input-secondary-black-large',
            },
            small: {
              color: this.color.general.dark,
              'background-color': this.color.general.light,
              'line-height': '22px',
              'font-size': '16px',
              'font-weight': this.font.weight.regular,
              'font-family': this.font.family.primary,
              'border-radius': '6px',
              padding: '9px 16px',
              'box-shadow': '0px 1px 2px 0px rgba(24, 24, 28, 0.04), 0px 3px 4px -5px rgba(24, 24, 28, 0.03)',
              name: 'input-secondary-black-small',
            },
          },
        },
        transparent: {
          black: {
            large: {
              color: this.color.general.dark,
              'background-color': 'transparent',
              'line-height': '39px',
              'font-size': this.font.size.h1.value,
              'font-weight': this.font.weight.bold,
              'font-family': this.font.family.primary,
              'border-radius': '4px',
              name: 'input-transparent-black-large',
            },
            small: {
              color: this.color.general.dark,
              'background-color': 'transparent',
              'line-height': '25px',
              'font-size': '16px',
              'font-weight': this.font.weight.bold,
              'font-family': this.font.family.primary,
              'border-radius': '4px',
              name: 'input-transparent-black-small',
            },
          },
          white: {
            large: {
              color: this.color.general.light,
              'background-color': 'transparent',
              'line-height': '39px',
              'font-size': this.font.size.h1.value,
              'font-weight': this.font.weight.bold,
              'font-family': this.font.family.primary,
              'border-radius': '4px',
              name: 'input-transparent-white-large',
            },
            small: {
              color: this.color.general.light,
              'background-color': 'transparent',
              'line-height': '25px',
              'font-size': '16px',
              'font-weight': this.font.weight.bold,
              'font-family': this.font.family.primary,
              'border-radius': '4px',
              name: 'input-transparent-white-small',
            },
          },
        },
      },
      homeTextPanelText: {
        standard: {
          large: {
            'line-height': this.font.size.h3.value,
            'font-size': this.font.size.h3.value,
            'font-weight': this.font.weight.bold,
            'font-family': this.font.family.primary,
            name: 'homeTextPanelText-standard-large',
          },
          small: {
            'line-height': this.font.size.h4.value,
            'font-size': this.font.size.h4.value,
            'font-weight': this.font.weight.bold,
            'font-family': this.font.family.primary,
            name: 'homeTextPanelText-standard-large',
          },
        },
      },
      select: {
        primary: {
          black: {
            large: {
              color: this.color.general.dark,
              'background-color': this.color.general.light,
              'line-height': '20px',
              'font-size': '14px',
              'font-weight': this.font.weight.regular,
              'font-family': this.font.family.primary,
              'border-radius': '6px',
              'min-height': '40px',
              'box-shadow': '0px 1px 2px 0px rgba(24, 24, 28, 0.04), 0px 3px 4px -5px rgba(24, 24, 28, 0.03)',
              name: 'select-primary-black-large',
            },
          },
        },
        secondary: {
          black: {
            large: {
              color: this.color.general.dark,
              'background-color': this.color.general.light,
              'line-height': '20px',
              'font-size': '14px',
              'font-weight': this.font.weight.regular,
              'font-family': this.font.family.primary,
              'border-radius': '6px',
              'min-height': '40px',
              'box-shadow': '0px 1px 2px 0px rgba(24, 24, 28, 0.04), 0px 3px 4px -5px rgba(24, 24, 28, 0.03)',
              name: 'select-secondary-black-large',
            },
          },
        },
      },
      badge: {
        standard: {
          primary: {
            large: {
              color: this.color.general.light,
              'background-color': this.color.primary.main,
              'line-height': '17px',
              'font-size': '12px',
              'font-weight': this.font.weight.bold,
              'font-family': this.font.family.primary,
              'border-radius': '6px',
              padding: '1.5px 5.5px',
              name: 'badge-standard-primary-large',
            },
          },
        },
      },
      intro: {
        standard: {
          maintenance: {
            large: {
              color: this.color.general.dark,
              name: 'intro-standard-maintenance-large',
            },
            medium: {
              color: this.color.general.dark,
              name: 'intro-standard-maintenance-medium',
            },
            small: {
              color: this.color.general.dark,
              'line-height': '44px',
              'font-size': '36px',
              'font-weight': this.font.weight.bold,
              'font-family': this.font.family.primary,
              name: 'intro-standard-maintenance-small',
            },
          },
          home: {
            large: {
              color: this.color.general.dark,
              name: 'intro-standard-home-large',
            },
            medium: {
              color: this.color.general.dark,
              name: 'intro-standard-home-medium',
            },
            small: {
              color: this.color.general.dark,
              name: 'intro-standard-home-small',
            },
          },
        },
      },
      tag: {
        standard: {
          gray: {
            large: {
              color: this.color.general.light,
              'background-color': this.color.general.gray3,
              'line-height': '17px',
              'font-size': '12px',
              'font-weight': this.font.weight.bold,
              'font-family': this.font.family.primary,
              'border-radius': '6px',
              name: 'tag-standard-gray-large',
            },
          },
          primary: {
            large: {
              color: this.color.general.light,
              'background-color': this.color.primary.main,
              'line-height': '17px',
              'font-size': '12px',
              'font-weight': this.font.weight.bold,
              'font-family': this.font.family.primary,
              'border-radius': '6px',
              name: 'tag-standard-primary-large',
            },
          },
        },
      },
      label: {
        standard: {
          uppercase: {
            large: {
              color: this.color.general.dark,
              'line-height': '10px',
              'font-size': '8px',
              'font-weight': this.font.weight.black,
              'font-family': this.font.family.primary,
            },
            small: {
              color: this.color.general.dark,
              'line-height': '10px',
              'font-size': '8px',
              'font-weight': this.font.weight.black,
              'font-family': this.font.family.primary,
            },
          },
          bigger: {
            large: {
              color: this.color.general.dark,
              'line-height': '16px',
              'font-size': '12px',
              'font-weight': this.font.weight.bold,
              'font-family': this.font.family.primary,
            },
            small: {
              color: this.color.general.dark,
              'line-height': '16px',
              'font-size': '12px',
              'font-weight': this.font.weight.bold,
              'font-family': this.font.family.primary,
            },
          },
          smaller: {
            large: {
              color: this.color.general.dark,
              'line-height': '14px',
              'font-size': '10px',
              'font-weight': this.font.weight.regular,
              'font-family': this.font.family.primary,
            },
            small: {
              color: this.color.general.dark,
              'line-height': '14px',
              'font-size': '10px',
              'font-weight': this.font.weight.regular,
              'font-family': this.font.family.primary,
            },
          },
        },
      },
      popupAlert: {
        standard: {
          default: {
            large: {
              color: this.color.general.light,
              'background-color': this.color.general.dark,
              'line-height': this.font.size.h3.value,
              'font-size': this.font.size.h4.value,
              'font-weight': this.font.weight.bold,
              'font-family': this.font.family.primary,
              'border-radius': '5px',
              name: 'popupAlert-standard-default-large',
            },
          },
        },
      },
      miniLogoWrapper: {
        standard: {
          main: {
            large: {
              'background-color': 'linear-gradient(180deg, #D6DAF5 0%, #FFF5D0 100%)',
              'border-radius': '50%',
              name: 'miniLogoWrapper-standard-main-large',
            },
          },
        },
      },
    }
  },
  appName: 'crm',
  name: 'crm',
}
