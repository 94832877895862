import React from 'react'
import { InfoRowsBlock, Typography, Tag } from '@aidsupply/components'

const UnitsTableCard = ({ className, data, initialData }) => {
  const { state, translations, code, magnitude, coefficient } = data

  return (
    <InfoRowsBlock
      className={className}
      data={[
        {
          title: {
            customElement: <Typography type="body1" text={translations} />,
            width: 'calc(100% - 36px)',
            hideBorder: true,
            padding: '10px 0 10px 10px',
          },
          state: {
            text: state,
            width: '36px',
            padding: '10px 10px 10px 0',
          },
        },
        {
          code: {
            iconProps: {
              name: 'ruler',
            },
            text: code,
            width: '50%',
            hideBorder: false,
            padding: '10px',
          },
          coefficient: {
            iconProps: {
              name: 'coefficient',
            },
            text: coefficient,
            width: '50%',
          },
        },
        {
          magnitude: {
            iconProps: {
              name: 'meter',
            },
            text: magnitude,
            width: '100%',
          },
        },
      ]}
    />
  )
}

export default UnitsTableCard
