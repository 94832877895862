import clsx from 'clsx'
import React from 'react'
import { withTheme } from 'styled-components'

import { FlexRow, IMAGEKIT_PARAMS_CONFIG, Icon, Image, Typography } from '@aidsupply/components'
import ReactTexty from 'react-texty'
import { IMAGEKIT_URL } from '../../constants'
import { markFileDraftedOrPosted } from '../../utils'
import { formatSizeUnits } from '../../utils/formatMeasureUnits'
import { StyledThumbnail } from './styled'

const Thumbnail = ({
  changedFilesStates = {},
  disableImagekit,
  entityType,
  error,
  file,
  fnDelete = () => null,
  isDeleted,
  isReadOnly,
  loading,
  fileGroup,
  values,
  saved,
  src,
  theme,
  updateInput,
  fileSize,
  fileName,
  isPrimary,
  t,
  isActive,
  filesType = 'photos', // | 'files'
  isMultipleUploadDisabled,
}) => {
  if (!src) {
    return null
  }

  const changeFileState = (fileGroup, fileId, isDeleted) => {
    const { updatedValues, updatedFilesState } = markFileDraftedOrPosted(values[fileGroup], file, isDeleted)

    const newFiles = {
      ...values,
      [fileGroup]: updatedValues,
    }

    updateInput({ target: { name: filesType, value: newFiles } })
    updateInput({
      target: { name: 'filesNewStates', value: { ...changedFilesStates, ...updatedFilesState } },
    })
  }

  const setPrimaryPhoto = (fileId) => {
    const primaryPhotoKey =
      entityType === 'organizations' || entityType === 'users'
        ? (fileGroup === 'logos' && 'logo_photo_id') || (fileGroup === 'banners' && 'banner_photo_id')
        : 'photo_id'
    updateInput({ target: { name: primaryPhotoKey, value: fileId } })
  }

  const transformedSize = formatSizeUnits(fileSize)
  const fileType = fileName?.split('.')[1]
  const iconName =
    fileType === 'xls' || fileType === 'xlsx'
      ? 'xlsFileType'
      : fileType === 'pdf'
      ? 'pdfFileType'
      : fileType === 'doc' || fileType === 'docx'
      ? 'docFileType'
      : fileType === 'txt'
      ? 'txtFileType'
      : 'defaultFileType'

  return (
    <>
      <StyledThumbnail
        className={clsx('thumbnail', error && 'error', saved && 'saved', isDeleted && 'isDeleted')}
      >
        {filesType === 'photos' ? (
          <Image
            alt="thumbnail"
            imagekitParams={IMAGEKIT_PARAMS_CONFIG.crm.thumbnail}
            imagekitUrl={IMAGEKIT_URL}
            src={src}
            disableImagekit={disableImagekit}
            maxWidth={40}
            height={40}
          />
        ) : (
          <Icon name={iconName} width={30} height={30} />
        )}
        <div style={{ flexGrow: 1, overflow: 'hidden', display: 'block' }}>
          <Typography text={fileName} type="caption2" as={ReactTexty} />
          <FlexRow>
            <Typography
              text={`${fileType} • ${transformedSize}`}
              type="caption3"
              color={theme.color.general.gray3}
            />
            {isActive && filesType === 'photos' && (
              <Icon
                onClick={() => setPrimaryPhoto(file.id)}
                fill={isPrimary ? theme.color.general.gray2 : ''}
                name="star"
                width={16}
                height={16}
                wrapperHeight={20}
                wrapperWidth={20}
              />
            )}
            {isPrimary && <Typography text={t('primaryPhoto')} type="button2" />}
          </FlexRow>
        </div>
        {!isReadOnly && (
          <Icon
            className="deleteIcon"
            onClick={() => (saved ? changeFileState(fileGroup, file.id, !isDeleted) : fnDelete(src))}
            fill={theme.color.general.gray6}
            name={isDeleted ? 'reload' : 'trashBin'}
            width={16}
            height={16}
            wrapperHeight={20}
            wrapperWidth={20}
            disabled={isPrimary || (isDeleted && isMultipleUploadDisabled)}
          />
        )}
      </StyledThumbnail>
    </>
  )
}

export default withTheme(Thumbnail)
