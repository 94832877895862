import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { ExpansionPanel, Label } from '@aidsupply/components'

import MenuItem from './MenuItem'
import { StyledMenuItems } from './styled'
import { isAccessAllowed } from '../../utils/user'
import { USER_ROLES } from '../../config'
import theme from '../../theme'

const MenuGroup = ({
  parentEntity,
  entitiesArr,
  children,
  isActiveParent,
  isExtended,
  isMobile,
  textColor,
  onClick,
  padding,
  role,
  withExpansionPanel,
}) => {
  const { t } = useTranslation('menu')
  const { pathname } = useLocation()

  // #roles
  const allowedSubmenuEntities = (entitiesArr || []).filter((entity) =>
    isAccessAllowed(entity.accessLevel, role)
  )
  const isMember = USER_ROLES[role]?.id === 'organization_member'

  const getGroupList = () => {
    if (!allowedSubmenuEntities) return null

    const isActiveKey = (key) =>
      pathname && pathname !== '/' ? pathname.includes(`/${key.toLowerCase()}`) : key === 'dashboard'

    return (
      <StyledMenuItems
        onClick={onClick}
        textColor={textColor}
        padding={padding}
        margin={isMember ? '0 10px' : null}
      >
        {allowedSubmenuEntities.map((entity, index) => {
          const { key, route, iconName, iconProps, label, icon, sortObject } = entity

          return (
            <MenuItem
              iconName={iconName || icon}
              iconProps={iconProps}
              key={key}
              text={label}
              route={route}
              isActive={isActiveKey(key)}
              isExtended={isExtended}
              isMobile={isMobile}
              sortObject={sortObject}
            />
          )
        })}
      </StyledMenuItems>
    )
  }
  const iconFill = isActiveParent ? theme.color.general.light : theme.color.general.dark
  return (
    <>
      {withExpansionPanel ? (
        <ExpansionPanel
          headerIconRightProps={{ fill: iconFill, strokeWidth: 1.4 }}
          className={isActiveParent ? 'active' : ''}
          isHidden={!parentEntity}
          header={
            parentEntity && (
              <MenuItem
                iconName={parentEntity.iconName}
                iconProps={parentEntity.iconProps}
                key={parentEntity.key}
                text={parentEntity.label}
                isExtended={isExtended}
                isMobile={isMobile}
                className="parentMenuItem"
                isActiveParent={isActiveParent}
              />
            )
          }
        >
          {getGroupList()}
        </ExpansionPanel>
      ) : (
        <>
          {isExtended && parentEntity && <Label text={t(parentEntity.label)} />}
          {getGroupList()}
        </>
      )}

      {children}
    </>
  )
}

export default MenuGroup
