import React from 'react'
import { useNavigate } from 'react-router-dom'
import { object } from 'dot-object'
import { useDispatch } from 'react-redux'
import { slugify } from 'transliteration'

import { getAvailableTranslation, isObjectEmpty, isObject } from '@aidsupply/components'

import EditForm from '../../../components/RightPanelForms'
import { getValuesWithAddedPhotosTab } from '../../../utils'
import { transformCharacteristicsFromObjectToArray } from '../../../utils/forms'
import { useMappedState } from '../../../hooks'
import { selectSidebarInitialData, selectSystemCollections } from '../../../redux/selectors'
import { sidebarUpsert } from '../../../redux/actions'
import merge from 'deepmerge'

const ItemsRightPanel = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const itemInitial = useMappedState(selectSidebarInitialData)
  const { attributes, brands, categories } = useMappedState(
    selectSystemCollections(['attributes', 'brands', 'categories'])
  )

  const isReadOnly = false

  const initialValuesFromApi = !isObjectEmpty(itemInitial?.characteristics)
    ? getValuesWithAddedPhotosTab(
        null,
        transformCharacteristicsFromObjectToArray(itemInitial.characteristics, attributes),
        itemInitial
      )
    : itemInitial

  const onFormSubmit = (formValuesChanged) => {
    const requestBody = { ...object(formValuesChanged) }

    Object.keys(requestBody).map((key) => {
      if (isObject(requestBody[key]) && requestBody[key].id) {
        requestBody[key] = requestBody[key].id
      }
    })

    if (formValuesChanged.translations?.en) {
      requestBody.name = formValuesChanged.translations.en
    }

    if (formValuesChanged.translations) {
      requestBody.translations = merge(itemInitial.translations, requestBody.translations)
    }
    if (formValuesChanged.description) {
      requestBody.description = merge(itemInitial.description, requestBody.description)
    }

    if (formValuesChanged.tags) {
      requestBody.tags = formValuesChanged.tags.map((tag) => tag.id)
    }

    if (formValuesChanged.characteristics) {
      requestBody.characteristics = formValuesChanged.characteristics.map((char) => ({
        id: char.id,
        slug: char.slug,
      }))
    }

    if (formValuesChanged.properties) {
      requestBody.properties = formValuesChanged.properties.map((char) => ({
        id: char.id,
        slug: char.slug,
      }))
    }

    if (formValuesChanged.sku || formValuesChanged.category_id || formValuesChanged.brand_id) {
      const category = categories[`${formValuesChanged.category_id?.id || itemInitial.category_id}`]
      const brand = brands[`${formValuesChanged.brand_id?.id || itemInitial.brand_id}`]

      requestBody.slug = `${category.slug}_${brand.slug}_${slugify(formValuesChanged.sku || itemInitial.sku)}`
    }

    // if (formValuesChanged.content) {
    //   requestBody.content = formValuesChanged.content.map((val) => ({
    //     id: val.id,
    //     slug: val.slug,
    //     amount: val.amount,
    //     unit: val.unit ? { id: val.unit.id } : undefined,
    //   }))
    // }

    const dataToSend = {
      id: itemInitial.id,
      requestBody,
      type: 'items',
      parentType: 'system',
    }

    dispatch(sidebarUpsert(dataToSend, navigate))
  }

  return (
    <EditForm
      initialValuesFromApi={initialValuesFromApi}
      isReadOnly={isReadOnly}
      onFormSubmit={onFormSubmit}
    />
  )
}

export default ItemsRightPanel
