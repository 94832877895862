import {
  FILTERS_BACK_TO_INITIAL_STATE,
  FILTERS_CLEAR_ALL,
  FILTERS_FILTER_ADD,
  FILTERS_FILTER_REMOVE,
  FILTERS_SET_FACET_GROUP_JUST_SELECTED,
  FILTERS_SET_FACETS,
  FILTERS_SET_ON_FIRST_LOAD,
  FILTERS_TOGGLE_PANEL,
} from '../constants'
import { mergeArraysById } from '@aidsupply/components'

const defaultState = {
  active: {},
  apiQuery: '',
  labels: [],
  facets: [],
  facetsInitial: {},
  filtersInitialized: false,
  filtersPanelOpened: false,
  facetJustSelected: {},
}

const buildFilter = (key, value) => `${key}:${value}`

const buildLabels = (active) => {
  const labels = []

  if (active) {
    Object.keys(active).forEach((key) => {
      active[key].forEach((value) => labels.push(buildFilter(key, value)))
    })
  }

  return labels
}

const filters = (state = defaultState, action) => {
  switch (action.type) {
    case FILTERS_SET_ON_FIRST_LOAD:
      return {
        ...state,
        active: action.active,
        labels: buildLabels(action.active),
      }
    case FILTERS_SET_FACETS:
      return {
        ...state,
        facets:
          state.filtersInitialized && !action.isRefetch
            ? Object.keys(state.facets).reduce((acc, stateFacetKey) => {
                const currFacetOptionsArr = state.facets[stateFacetKey]
                const newFacetOptionsArr = action.facets[stateFacetKey]

                const currentFacetsThatRemain =
                  stateFacetKey !== state.facetJustSelected.key &&
                  currFacetOptionsArr.filter((currFacetOption) => {
                    return state.active?.[stateFacetKey]?.includes(currFacetOption.id)
                  })

                const currentFacetsThatRemainMappedWithCountsZero =
                  currentFacetsThatRemain &&
                  currentFacetsThatRemain.map((currFacet) => {
                    if (newFacetOptionsArr.some((newFacet) => newFacet.id === currFacet.id)) {
                      return currFacet
                    } else {
                      return { ...currFacet, count: 0 }
                    }
                  })

                const newValues =
                  stateFacetKey === state.facetJustSelected.key
                    ? currFacetOptionsArr
                    : // activeFilters that can have count=0 now + newFacetOptionsArr:
                    currentFacetsThatRemain
                    ? mergeArraysById(currentFacetsThatRemainMappedWithCountsZero, newFacetOptionsArr)
                    : newFacetOptionsArr
                return {
                  ...acc,
                  [stateFacetKey]: newFacetOptionsArr?.length > 0 ? newValues : currFacetOptionsArr,
                }
              }, {})
            : action.facets &&
              Object.keys(action.facets).reduce((acc, currKey) => {
                // filter out facets that have only one value
                if (state.active[currKey]?.length || action.facets[currKey]?.length > 0) {
                  return { ...acc, [currKey]: action.facets[currKey] }
                }
                return acc
              }, {}),
        filtersInitialized: true,
      }
    case FILTERS_FILTER_ADD:
      return {
        ...state,
        active: {
          ...state.active,
          [action.key]: [...(state.active[action.key] || []), ...[action.value]],
        },
        labels: [...state.labels, ...[buildFilter(action.key, action.value)]],
      }
    case FILTERS_SET_FACET_GROUP_JUST_SELECTED:
      return {
        ...state,
        facetJustSelected: action.facetJustSelected,
      }
    case FILTERS_FILTER_REMOVE: {
      const newActiveFilters = state.active[action.key].filter((val) => val !== action.value)
      return {
        ...state,
        active: {
          ...state.active,
          [action.key]: newActiveFilters,
        },
        labels: state.labels.filter((val) => val !== buildFilter(action.key, action.value)),
        filtersInitialized: !!newActiveFilters?.length,
      }
    }
    case FILTERS_CLEAR_ALL:
      return { ...state, active: {}, labels: [], filtersInitialized: false }
    case FILTERS_TOGGLE_PANEL:
      return {
        ...state,
        filtersPanelOpened: typeof action.opened === 'undefined' ? !state.filtersPanelOpened : action.opened,
      }
    case FILTERS_BACK_TO_INITIAL_STATE:
      return defaultState
    default:
      return state
  }
}
export default filters
