import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { withTheme } from 'styled-components'

import { FlexRow, Tabs, Typography } from '@aidsupply/components'

import { useMappedState } from '../../hooks'
import { selectDataType, selectSystemCollections } from '../../redux/selectors'
import InfoPanel from '../InfoPanel'
import UploadFiles from '../UploadFiles'
import Thumbnail from '../UploadFiles/Thumbnail'
import { StyledThumbnails } from '../UploadFiles/styled'
import { StyledTabsWrapper } from './styled'

const getPhotosQuantity = (photos) =>
  photos.active
    ? `${photos.active.length}${photos.disabled ? '/' + photos.disabled.length : ''}`
    : photos.length

const FilesForm = ({
  changedFilesStates,
  id,
  isReadOnly,
  itemCharacteristics,
  values = { general: {} },
  updateInput,
  theme: { color },
  primaryPhotoId,
  primaryLogoId,
  primaryBannerId,
  filesType,
}) => {
  const { t, i18n } = useTranslation('forms')
  const dispatch = useDispatch()
  const { enumerations, attributes } = useMappedState(selectSystemCollections(['enumerations', 'attributes']))
  const entityType = useMappedState(selectDataType)
  const [activeIndex, setActiveIndex] = useState(0)

  // We don't allow adding pictures until collection object is written to the DB (when creating new one)
  if (!id) {
    return <InfoPanel text={t('fillGeneralAndSaveBeforeUploadingPhotos')} />
  }

  // const filesGroups = !isObjectEmpty(values) ? Object.keys(values) : ['general']
  const filesGroups = Object.keys(values)

  const filteredFileGroups = filesGroups.filter((group) => {
    if (entityType === 'items') {
      const splitPhotoType = group.split('_')
      const attributeSlug = splitPhotoType[0]
      const enumOptionSlug = splitPhotoType[1]

      if (
        !itemCharacteristics ||
        (group !== 'general' &&
          (!itemCharacteristics[attributeSlug] ||
            itemCharacteristics[attributeSlug].findIndex(
              (enumOption) => enumOption.slug === enumOptionSlug
            ) === -1))
      ) {
        return false
      }
    }
    return true
  })

  return (
    <StyledTabsWrapper className="photos">
      <Tabs
        withAddAction
        tabsTitles={filteredFileGroups.map((fileGroup, idx) => {
          const text = t(fileGroup)
          return (
            <FlexRow id={fileGroup} gap="6px" key={idx}>
              <Typography text={text} fontWeight={600} />
            </FlexRow>
          )
        })}
        tabsContents={filteredFileGroups.map((fileGroup, i) => {
          const isOnlyOneFileAllowed = entityType === 'reports'
          const isMultipleUploadDisabled = isOnlyOneFileAllowed && !!values?.general?.active.length

          const primaryPhoto =
            filesType === 'photos' &&
            (entityType === 'organizations' || entityType === 'users'
              ? (fileGroup === 'logos' && primaryLogoId) || (fileGroup === 'banners' && primaryBannerId)
              : primaryPhotoId)

          return (
            <div
              aria-labelledby={`scrollable-auto-tab-${i}`}
              id={`scrollable-auto-tabpanel-${i}`}
              key={`${fileGroup}-${i}`}
              role="tabpanel"
            >
              <UploadFiles
                entityType={entityType}
                entityId={id}
                fileGroup={fileGroup}
                filesType={filesType}
                isMultipleUploadDisabled={isMultipleUploadDisabled}
                maxFiles={isOnlyOneFileAllowed ? 1 : undefined}
              />
              {!!values?.[fileGroup]?.length && (
                <StyledThumbnails>
                  {values[fileGroup].map((photo, index) => (
                    <Thumbnail
                      index={index}
                      values={values}
                      updateInput={updateInput}
                      key={`${photo.url}-${index}`}
                      saved
                      src={photo.url}
                      filesType={filesType}
                    />
                  ))}
                </StyledThumbnails>
              )}

              {values?.[fileGroup]?.active && (
                <StyledThumbnails className="filesContainer">
                  {values[fileGroup].active.map((file, i) => (
                    <Thumbnail
                      changedFilesStates={changedFilesStates}
                      entityType={entityType}
                      isReadOnly={isReadOnly}
                      file={file}
                      src={file.url}
                      values={values}
                      updateInput={updateInput}
                      key={file.id}
                      saved
                      fileGroup={fileGroup}
                      fileSize={file.meta.file_size}
                      fileName={file.meta.file_name_original}
                      isPrimary={primaryPhoto === file.id}
                      t={t}
                      isActive
                      filesType={filesType}
                    />
                  ))}
                </StyledThumbnails>
              )}

              {!isReadOnly && (
                <>
                  {values?.[fileGroup]?.disabled && (
                    <StyledThumbnails className="filesContainer">
                      {values[fileGroup].disabled.map((file, i) => (
                        <Thumbnail
                          changedFilesStates={changedFilesStates}
                          file={file}
                          src={file.url}
                          isDeleted
                          values={values}
                          updateInput={updateInput}
                          key={file.id}
                          saved
                          fileGroup={fileGroup}
                          fileSize={file.meta.file_size}
                          fileName={file.meta.file_name_original}
                          filesType={filesType}
                          isMultipleUploadDisabled={isMultipleUploadDisabled}
                        />
                      ))}
                    </StyledThumbnails>
                  )}
                </>
              )}
            </div>
          )
        })}
        typographyType="body1"
        backgroundColor={color.general.gray2}
      />
    </StyledTabsWrapper>
  )
}

export default withTheme(FilesForm)
