import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Button, Icon, Typography, UiLink } from '@aidsupply/components'

import { StyledErrorWrapper, StyledNotFound } from './styled'

const NotFound = () => {
  const { t } = useTranslation('errorPage')

  return (
    <StyledNotFound>
      <StyledErrorWrapper>
        <Icon name="page404" text={t('pageNotFound')} />
        <Typography margin="20px 0" textAlign="center" type="h2">
          {t('somethingsMissing')}
        </Typography>
        <UiLink to="/" Link={Link} noStyles>
          <Button>{t('goHome')}</Button>
        </UiLink>
        {/*<UiLink to="" Link={Link}>*/}
        {/*  {t('needHelp')}*/}
        {/*</UiLink>*/}
      </StyledErrorWrapper>
    </StyledNotFound>
  )
}

export default NotFound
