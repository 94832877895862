import React from 'react'
import { pick } from 'dot-object'

import { Typography } from '@aidsupply/components'

import { StyledFlexRow } from './styled'

const ReadOnlyInfo = ({ label, labelKey, value }) => {
  if (!value) return null

  return (
    <StyledFlexRow>
      <Typography type="body1" className="label">
        {label}:
      </Typography>
      <Typography type="body2">
        {typeof value === 'object' && !Array.isArray(value) ? pick(labelKey, value) : value}
      </Typography>
    </StyledFlexRow>
  )
}

export default ReadOnlyInfo
