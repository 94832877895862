import React, { useContext } from 'react'

import { ScreenContext } from '@aidsupply/components'

import { StyledHeader } from './styled'
import LanguageSelect from '../../LanguageSelect'
import Logo from '../../../atomic-components/Logo'

const HeaderIntroScreen = ({ theme }) => {
  const { width, sm, lg, xl } = useContext(ScreenContext) || {}

  const isMobile = width && width < sm
  const isTablet = width && lg < width && width < xl
  const currWidth = !isMobile && !isTablet ? '140px' : '74px'

  return (
    <StyledHeader className="introHeader">
      <Logo theme={theme} variant={isMobile ? 'medium' : 'large'} to="../login" isExtended />
      <LanguageSelect isIntroScreensSelect theme={theme} minWidth={currWidth} isFullSize={!isMobile && !isTablet} />
    </StyledHeader>
  )
}

export default HeaderIntroScreen
