import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { withTheme } from 'styled-components'

import { Typography, UiLink } from '@aidsupply/components'

import IntroScreenWrapper from '../../components/IntroScreens/IntroScreenWrapper'
import { INTRO_PAGES_IMG } from '../../constants'
import { useMappedState } from '../../hooks'
import { userSignUp } from '../../redux/actions'
import { selectUserInProgressAndError } from '../../redux/selectors'
import FormCreate from './FormCreate'

const initialValues = {
  full_name: '',
  company_name: '',
  email: '',
  password: '',
  provider: 'email',
}

const SignUp = ({ theme }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation('signIn')
  const { inProgress, error } = useMappedState(selectUserInProgressAndError)

  const [isRulesAgreed, agreeToRules] = useState(false)

  const onFormSubmit = (credentials) => {
    const creds = { ...credentials }
    delete creds.repeat_password

    dispatch(userSignUp(creds, navigate))
  }

  // console.log(isRulesAgreed, inProgress)
  return (
    <IntroScreenWrapper text={t('signUp')} type="signUp" theme={theme} imageUrl={INTRO_PAGES_IMG}>
      <FormCreate initialValues={initialValues} theme={theme} />

      {/* <SocialButtons /> */}

      <div className="inscriptionWrapper">
        <Typography type="body1" color={theme.color.general.dark} margin="20px 0 0 0">
          {t('alreadyHaveAccount')}
        </Typography>
        <Typography type="button1">
          <UiLink to="/login" className="auth inline" Link={RouterLink} themeColor="general.dark">
            {t('signIn')}
          </UiLink>
        </Typography>
      </div>

      <div className="inscriptionWrapper terms">
        <Typography type="body2" color={theme.color.general.dark} margin="20px 0 0 0" className="inline">
          {t('legalPagesInscription')}
        </Typography>
        <Typography type="body2" fontWeight={500} color={theme.color.primary.main} className="inline">
          <UiLink
            to="/terms-and-conditions"
            className="link inline"
            themeColor="primary.main"
            target="_blank"
          >
            &nbsp;{`${t('withTermsAndConditions')}.`}
          </UiLink>
        </Typography>
      </div>
    </IntroScreenWrapper>
  )
}

export default withTheme(SignUp)
