import { Outlet } from 'react-router-dom'
import { withTheme } from 'styled-components'

import TableBlock from '../../../components/Table/TableBlock'
import GridLayout from '../../Layout/GridLayout'
import ReportsTableCard from './ReportsTableCard'
import { useMappedState } from '../../../hooks'
import { selectUserRole } from '../../../redux/selectors'

const Reports = ({ theme }) => {
  const userRole = useMappedState(selectUserRole)
  const role = userRole === 'administrator' || userRole === 'system'

  const tableCardHeight = role ? 160 : 119

  return (
    <GridLayout RightPanelComponent={<Outlet />}>
      <TableBlock isMainTable TableCardContent={ReportsTableCard} tableCardHeight={tableCardHeight} />
    </GridLayout>
  )
}

export default withTheme(Reports)
