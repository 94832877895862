import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { object } from 'dot-object'
import { isObject } from '@aidsupply/components'
import { useDispatch } from 'react-redux'

import EditForm from '../../../components/RightPanelForms'
import { selectSidebarInitialData } from '../../../redux/selectors'
import { useMappedState } from '../../../hooks'
import { sidebarUpsert } from '../../../redux/actions'

const WarehousesRightPanel = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { rightPanelId: editedItemId } = useParams()

  const warehouseInitial = useMappedState(selectSidebarInitialData)

  const isReadOnly = false

  const onFormSubmit = (formValuesChanged) => {
    const requestBody = { ...object(formValuesChanged) }

    Object.keys(requestBody).map((key) => {
      if (isObject(requestBody[key]) && requestBody[key].id) {
        requestBody[key] = requestBody[key].id
      }
    })

    if (formValuesChanged.type) {
      requestBody.type = formValuesChanged.type.map((type) => (editedItemId === 'copy' ? type : type.id))
    }

    const dataToSend = {
      id: warehouseInitial.id,
      requestBody,
      type: 'warehouses',
      parentType: 'inventory',
    }

    dispatch(sidebarUpsert(dataToSend, navigate))
  }

  return <EditForm isReadOnly={isReadOnly} onFormSubmit={onFormSubmit} />
}

export default WarehousesRightPanel
