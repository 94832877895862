import { convertArrayToObject, mergeArraysById, sortSearchResultsByAvailability } from '@aidsupply/components'

import {
  SET_DATA_TYPE,
  DATA_BULK_UPDATE,
  DATA_BULK_UPDATE_ERROR,
  DATA_BULK_UPDATE_MAIN_TABLE_SUCCESS,
  UPDATE_MODIFICATION_CODE_IN_SYSTEM,
  SIGN_OUT_SUCCESS,
  DATA_FETCH_WITH_FACETS_ERROR,
  DATA_FETCH_WITH_FACETS_SUCCESS,
  DATA_FETCH_SYSTEM_DATA,
  DATA_FETCH_SYSTEM_DATA_ERROR,
  DATA_FETCH_SYSTEM_DATA_SUCCESS,
  DATA_SET_SYSTEM_DATA,
  DATA_FETCH_WITH_FACETS,
  SET_API_URL_PARAM,
} from '../constants'

// import users from '../../dummyData/users'
// import system from '../../dummyData/system'

const defaultState = {
  bulkUpdateInProgress: false,
  inProgress: false,
  system: {},
  systemInProgress: false,
  type: {},
  isSystemDataLoaded: false,
  // errorSystem: {},
  error: false,
  total_items: null,
  apiUrlParam: '',
  apiUrlParamsCurrent: [],
  isDataInitialized: false,
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_DATA_TYPE:
      return {
        ...state,
        type: action.data,
        isSystemDataLoaded: false,
      }
    case SET_API_URL_PARAM:
      return {
        ...state,
        apiUrlParam: action.apiUrlParam,
      }
    case DATA_FETCH_WITH_FACETS:
      return {
        ...state,
        inProgress: true,
      }
    case DATA_FETCH_WITH_FACETS_SUCCESS: {
      const { type, items, total_items, queryParams } = action.data

      // const itemsFiltered = PLATFORM_CHOSEN_COLLECTIONS[type]
      //   ? getOptionsFilteredByPlatformChosenItems(type, {
      //       [type]: items,
      //       platforms: state.system.platforms,
      //     })
      //   : items

      // const stockItemsFilteredObj =
      //   type === 'stock-items'
      //     ? {
      //         'stock-items': items?.filter((item) => state.system.warehouses?.[item.warehouse_id]),
      //       }
      //     : {}

      const productsSortedObj =
        state.type.key === 'products' ? { products: sortSearchResultsByAvailability(items) } : {}

      return {
        ...state,
        [type]: items,
        total_items,
        // ...stockItemsFilteredObj,
        ...productsSortedObj,
        inProgress: false,
        error: false,
        apiUrlParamsCurrent: queryParams,
        isDataInitialized: true,
      }
    }
    case DATA_FETCH_WITH_FACETS_ERROR: {
      //VR: refactor candidate
      const emptyDataObj = action.data?.type ? { [action.data.type]: [] } : {}

      return {
        ...state,
        inProgress: false,
        ...emptyDataObj,
        error: action.error,
      }
    }
    case DATA_FETCH_SYSTEM_DATA:
      return {
        ...state,
        systemInProgress: true,
      }
    case DATA_FETCH_SYSTEM_DATA_SUCCESS:
      return {
        ...state,
        systemInProgress: false,
        isSystemDataLoaded: true,
      }
    case DATA_FETCH_SYSTEM_DATA_ERROR:
      return {
        ...state,
        systemInProgress: false,
      }
    case DATA_SET_SYSTEM_DATA:
      return {
        ...state,
        system: {
          ...state.system,
          [action.key.replace('OpenAPI', '')]: Array.isArray(action.data)
            ? convertArrayToObject(
                action.data,
                // action.key === 'attributes' ? 'slug' :
                'id'
              )
            : action.data,
        },
      }
    case DATA_BULK_UPDATE:
      return {
        ...state,
        bulkUpdateInProgress: true,
        error: undefined,
      }
    case DATA_BULK_UPDATE_MAIN_TABLE_SUCCESS:
      return {
        ...state,
        bulkUpdateInProgress: false,
        [state.type.key]: mergeArraysById(state[state.type.key], action.items),
      }
    case DATA_BULK_UPDATE_ERROR:
      return {
        ...state,
        bulkUpdateInProgress: false,
        error: action.error,
      }
    case UPDATE_MODIFICATION_CODE_IN_SYSTEM:
      return {
        ...state,
        system: {
          ...state.system,
          modifications: {
            ...state.system.modifications,
            [action.modId]: {
              ...state.system.modifications[action.modId],
              code: action.code,
            },
          },
        },
      }
    case SIGN_OUT_SUCCESS:
      return {
        ...defaultState,
        system: {
          pages: state.system.pages || '',
          platforms: state.system.platforms || '',
        },
      }
    default:
      return state
  }
}
