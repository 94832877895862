import styled from 'styled-components'

export const StyledUserProfile = styled.div`
  max-width: 880px;
  margin: 30px auto;
  width: 70%;

  .attachButton {
    position: relative;
  }
  .profileDrop {
    width: 100%;
    height: 100%;
  }
  .expansionPanel {
    border: none;
    padding: 0 20px;
    margin-bottom: 20px;
    width: 100%;
  }
  .formInput {
    margin-bottom: 0;
  }
  .inputContainer {
    margin-bottom: 0;
  }

  // .panelHeader {
  //   margin-bottom: 20px;
  //   padding-bottom: 10px;
  //   border-bottom: 1px solid ${({ theme: { color } }) => color.general.gray1};
  // }

  .languages .detailsWrapper {
    padding: 20px 0;
    border-top: 1px solid ${({ theme: { color } }) => color.general.gray1};
  }

  .languages .formInput {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;
  }

  .general {
    .detailsWrapper {
      display: flex;
      flex-wrap: wrap;
      gap: 20px 2%;
      padding: 20px 0;
      border-top: 1px solid ${({ theme: { color } }) => color.general.gray1};
      .column {
        width: 49%;
      }
    }
  }

  .submitFormButton {
    margin: 0 20px;
  }

  .rowsForm {
    form {
      flex-direction: revert;
      align-items: flex-end;
      justify-content: space-between;
    }
    .changeEmail {
      width: 100%;
    }
    button {
      margin: 0 20px 7px 0;
    }
    .submitFormButtons {
      display: none;
    }
  }

  .passForm form {
    display: grid;
    grid-template-columns: repeat(2, 38%) 20%;
    grid-template-rows: repeat(3, auto);
    grid-column-gap: 2%;
    grid-row-gap: 0px;
    padding: 0 20px;
    .expansionPanel {
      grid-area: 1 / 1 / 3 / 4;
      margin: 0 0 20px 0;
      padding: 0;
      .detailsWrapper {
        display: grid;
        grid-template-columns: repeat(2, 38%) 18%;
        grid-template-rows: auto;
        grid-column-gap: 2%;
        grid-row-gap: 0px;
        padding: 20px 0;
        border-top: 1px solid ${({ theme: { color } }) => color.general.gray1};
      }
    }
    button {
      grid-area: 2 / 3 / 3 / 4;
      margin: 0 0 40px 0;
      padding: 9px 17px;
    }
    .passwordCheckList {
      grid-area: 3 / 2 / 4 / 3;
    }
    .rightIcon {
      path {
        fill: none;
      }
    }
  }

  .h4 {
    text-transform: uppercase;
    letter-spacing: 2px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    margin-top: 20px;

    .profilePicture .column > div {
      flex-wrap: wrap;
      justify-content: center;
    }

    .general {
      grid-template-rows: repeat(3, auto);
      grid-template-columns: 1fr;
      .panelHeader {
        grid-area: 1;
      }
    }
    .languages {
      grid-template-rows: repeat(4, auto);
      grid-template-columns: 1fr;
      .panelHeader {
        grid-area: 1/1/2/2;
      }
      .explanation {
        grid-area: 2/1/2/2;
      }
    }
    .rowsForm:not(.passForm) form {
      flex-wrap: wrap;
      justify-content: center;
      button {
        margin: 0 20px;
      }
    }
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    .passForm form {
      grid-template-rows: repeat(3, auto);
      grid-template-columns: 1fr;
      .expansionPanel {
        grid-area: 1 / 1 / 2 / 2;
        .detailsWrapper {
          display: flex;
          flex-wrap: wrap;
          gap: 20px;
          .column {
            width: 100%;
          }
        }
      }
      button {
        grid-area: 3 / 1 / 4 / 2;
      }
      .passwordCheckList {
        grid-area: 2 / 1 / 3 / 2;
      }
    }

    .general {
      .detailsWrapper {
        .column {
          width: 100%;
        }
      }
    }
  }
`

export const StyledProfilePicture = styled.div`
  display: flex;
  padding: 20px 0;
  margin-top: 10px;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
  border-top: 1px solid ${({ theme: { color } }) => color.general.gray1};
  border-bottom: 1px solid ${({ theme: { color } }) => color.general.gray1};
  figure {
    flex-shrink: 0;
  }
  .body1 {
    flex-grow: 1;
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    flex-wrap: wrap;
    justify-content: center;
  }
`
