import React from 'react'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { Link } from 'react-router-dom'

import { Icon, UiLink, Typography } from '@aidsupply/components'

import { StyledMenuItem } from './styled'
import { sortObjectToUrlString } from '../../utils/table'
import theme from '../../theme'

const MenuItem = ({
  iconName,
  iconProps = {},
  text,
  route,
  isActive,
  isActiveParent,
  className,
  to,
  onClick,
  isExtended,
  isMobile,
  activeBGColor,
  sortObject,
}) => {
  const { t } = useTranslation('menu')
  const routeWithSortString = sortObject
    ? `${route || to}?sort=${sortObjectToUrlString(sortObject)}`
    : route || to

  const iconFill = isActive || isActiveParent ? theme.color.general.light : theme.color.general.dark

  return (
    <UiLink
      className={clsx(className, 'menuItem')}
      to={routeWithSortString}
      onClick={onClick}
      Link={Link}
      withHoverForTypographyOnly={isExtended}
    >
      <StyledMenuItem
        className={clsx(isExtended ? 'extendedMenu' : 'condensedMenu', isActive && 'active')}
        activeBGColor={activeBGColor}
        iconName={iconName}
      >
        <Icon
          name={iconName}
          className={clsx(iconName, 'menuItemIcon')}
          fill={iconFill}
          strokeWidth={1.4}
          stroke={iconFill}
          {...iconProps}
        />
        <Typography
          type={isMobile ? 'button2' : 'button1'}
          text={t(text)}
          className={clsx(isExtended ? 'extendedItem' : 'condensedItem')}
        />
      </StyledMenuItem>
    </UiLink>
  )
}

export default MenuItem
