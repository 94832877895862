import React from 'react'
import { useNavigate } from 'react-router-dom'
import { object } from 'dot-object'
import { useDispatch } from 'react-redux'
import { slugify } from 'transliteration'

import EditForm from '../../../components/RightPanelForms'
import { sidebarUpsert } from '../../../redux/actions'
import { useMappedState } from '../../../hooks'
import { selectSidebarItemId } from '../../../redux/selectors'
import { isObject } from '@aidsupply/components'

const EnumerationsRightPanel = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const itemId = useMappedState(selectSidebarItemId)

  const isReadOnly = false

  const onFormSubmit = (formValuesChanged) => {
    const requestBody = { ...object(formValuesChanged) }

    Object.keys(requestBody).map((key) => {
      if (isObject(requestBody[key]) && requestBody[key].id) {
        requestBody[key] = requestBody[key].id
      }
    })

    if (formValuesChanged.options) {
      requestBody.options = formValuesChanged.options.map((option) =>
        option.icon?.id ? { ...option, icon: option.icon.id } : option
      )
    }

    if (formValuesChanged.name) {
      requestBody.slug = slugify(formValuesChanged.name)
    }

    const dataToSend = {
      id: itemId,
      requestBody,
      type: 'enumerations',
      parentType: 'system',
    }

    dispatch(sidebarUpsert(dataToSend, navigate))
  }

  return <EditForm isReadOnly={isReadOnly} onFormSubmit={onFormSubmit} />
}

export default EnumerationsRightPanel
