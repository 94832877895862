import React from 'react'
import { Image, IMAGEKIT_PARAMS_CONFIG, InfoRowsBlock, Typography } from '@aidsupply/components'
import { IMAGEKIT_URL } from '../../../constants'

const BrandsTableCard = ({ className, data, initialData }) => {
  const { name, country_id, photo_url, state, website } = data

  return (
    <InfoRowsBlock
      className={className}
      data={[
        {
          nameAndLogo: {
            customElement: (
              <>
                {photo_url && (
                  <Image
                    className="brandLogo"
                    radius="50%"
                    src={photo_url}
                    height={35}
                    width={35}
                    imagekitParams={IMAGEKIT_PARAMS_CONFIG.components.cardTableItemLogo}
                    imagekitUrl={IMAGEKIT_URL}
                    alt="brand logo"
                  />
                )}
                <Typography type="body1" padding="12px 0">
                  {name}
                </Typography>
              </>
            ),
            width: 'calc(100% - 34px)',
            hideBorder: true,
            padding: '0 0 0 10px',
          },
          state: { customElement: state, width: '34px' },
        },
        {
          country_id: { text: country_id, width: website ? '50%' : '100%', hideBorder: !website },
          ...(website && {
            website: {
              iconProps: {
                name: 'globe',
                margin: '0 3px 0 0',
              },
              text: website,
              width: '50%',
            },
          }),
        },
      ]}
    />
  )
}

export default BrandsTableCard
