import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { object } from 'dot-object'
import merge from 'deepmerge'
import { slugify } from 'transliteration'

import EditForm from '../../../components/RightPanelForms'
import { isObject } from '@aidsupply/components'
import { sidebarUpsert } from '../../../redux/actions'
import { useMappedState } from '../../../hooks'
import { selectSidebarInitialData } from '../../../redux/selectors'

const OrganizationsRightPanel = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const dataInitial = useMappedState(selectSidebarInitialData)

  const onFormSubmit = (formValuesChanged) => {
    const requestBody = { ...object(formValuesChanged) }

    Object.keys(requestBody).map((key) => {
      if (isObject(requestBody[key]) && requestBody[key].id) {
        requestBody[key] = requestBody[key].id
      } else if (Array.isArray(requestBody[key]) && requestBody[key].length) {
        requestBody[key] = requestBody[key].map((item) => (isObject(item) && item.id ? item.id : item))
      }
    })

    if (!dataInitial.id && !requestBody.state) {
      requestBody.state = 'posted'
    }

    if (requestBody.description)
      requestBody.description = merge(dataInitial.description, requestBody.description)

    if (formValuesChanged.name_en || (formValuesChanged.name && !dataInitial.name_en)) {
      requestBody.slug = slugify(formValuesChanged.name_en || formValuesChanged.name)
    }

    if ('year_founded' in requestBody && requestBody.year_founded === '') {
      requestBody.year_founded = null
    }

    const dataToSend = {
      id: dataInitial.id,
      requestBody,
      type: 'organizations',
      parentType: 'admin',
    }

    dispatch(sidebarUpsert(dataToSend, navigate))
  }

  return <EditForm onFormSubmit={onFormSubmit} />
}

export default OrganizationsRightPanel
