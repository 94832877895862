import { object } from 'dot-object'
import { updateFormValues } from '../../redux/actions'

import { updateModificationCodeInSystem } from '../../redux/actions'

const useEditableTable = ({
  dispatch,
  isDeletionByState,
  rowKey,
  selectedRows,
  setTableData,
  tableData,
  type,
  initialData,
  updateTableRows,
}) => {
  // const [rowsEditing, setRowsEditing] = useState([])
  if (!updateTableRows) {
    return {}
  }

  const onAddIconImage = () => {}

  const onBlurCellInput = (e, column) => {
    const { name, value } = e.target

    if (type === 'items.modifications') {
      dispatch(updateFormValues('modifications', [{ id: name.split('.')[0], code: value }]))
      dispatch(updateModificationCodeInSystem(name.split('.')[0], value))
    } else {
      const isNumber = column.type === 'number'
      const checkedValue = isNumber ? +value : value
      const deletedItems = tableData.filter((item) => item.state === 'deleted')

      const updatedData = tableData
        .filter((item) => item.state !== 'deleted')
        .map((row, index) => {
          if (index === +name.split('.')[0]) {
            const newValueObj = {
              [column.dataKey]: checkedValue,
            }
            return { ...row, ...newValueObj } //TODO: check: maybe mergeDeep
          } else {
            return row
          }
        })
      const isEqualToInitial = false // TODO: compare whole array with initialData array

      const newData = [...updatedData, ...deletedItems]

      updateTableRows()(newData, isEqualToInitial)
      setTableData(newData.map((item) => object(item)))
    }
  }

  // const onEditClick = () => {
  //   setRowsEditing(selectedRows)
  // }

  const onDeleteClick = () => {
    const filteredData = tableData.filter((row) => !selectedRows.includes(row[rowKey || 'id']))
    const mappedData = tableData.map((row) =>
      selectedRows.includes(row.id || 'id') ? { ...row, state: 'deleted' } : row
    )

    if (isDeletionByState) {
      updateTableRows()(mappedData, false)
    } else {
      updateTableRows()(filteredData)
    }
  }

  return { onAddIconImage, onBlurCellInput, onDeleteClick }
}

export default useEditableTable
