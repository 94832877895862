import styled from 'styled-components'

import { Drawer } from '@aidsupply/components'

import { HEADER_HEIGHT } from '../../constants'

export const StyledMainWrapper = styled.div`
  display: flex;
  width: 100vw;
  overflow: hidden;
  height: 100vh;

  .titleRow {
    height: ${HEADER_HEIGHT};
    padding: 10px 20px;
    gap: 10px;

    .closeIcon {
      border: 1px solid ${({ theme: { color } }) => color.primary.main};
      border-radius: 6px;
    }

    .backIcon {
      border: 1px solid ${({ theme: { color } }) => color.primary.main};
      border-radius: 6px;
    }
  }

  .rightPanel {
    .titleRow {
      margin-bottom: 2px;
      padding-right: 0;
      padding-left: 0;
    }
  }

  .popupLinkText {
    text-transform: uppercase;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.xl}px) {
    display: flex;
    flex-direction: column;
  }
  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.xl}px) {
    .oneLinePopup {
      max-width: 100%;
      white-space: nowrap;
    }
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    height: auto;
  }
`

export const StyledGrid = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-grow: 1;

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.xl}px) {
    // &.extended {
    //   margin-top: ${HEADER_HEIGHT}px;
    // }
  }
`

export const StyledMain = styled.div`
  position: relative;
  flex-grow: 1;
  padding: 0 20px;

  &.settingsPage {
    overflow-y: auto;
  }

  .tableTitleRow {
    height: ${HEADER_HEIGHT};
    gap: 10px;

    .menuIcon {
      margin-left: auto;
    }
  }

  &.withBackIcon {
    .tableTitleRow .menuIcon {
      padding: 10px;
      border: 1px solid ${({ theme: { color } }) => color.general.gray3};
      border-radius: 3px;
    }
  }
`

export const StyledLeftDrawer = styled(Drawer)`
  border-right: 1px solid ${({ borderColor }) => borderColor};
  min-width: 200px;
  overflow-x: hidden;

  .counterBadge {
    span {
      background-color: ${({ theme: { color } }) => color.general.light};
      color: ${({ theme: { color } }) => color.general.dark};
      border: 1px solid ${({ theme: { color } }) => color.general.gray2};
      border-radius: 4px;
      line-height: 14px;
    }
  }

  &.chats {
    min-width: 260px;
  }

  &.templates {
    min-width: 300px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    margin-top: ${HEADER_HEIGHT};
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    top: ${HEADER_HEIGHT};
    z-index: 5;
    border: none;
  }
  @media only screen and (max-width: ${({ theme }) => theme.breakpoints.xl}px) {
    height: calc(100vh - ${HEADER_HEIGHT});
  }
`
