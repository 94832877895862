import styled from 'styled-components'

import { ExpansionPanel, Typography, UiLink, getTokens } from '@aidsupply/components'

export const StyledForm = styled.div`
    flex-grow: 1;

    form {
        display: flex;
        flex-direction: column;
        height: 100%;

        .seo {
            order: 30;
        }

        .admin {
            order: 31;
        }

        .general .opened {
            order: -3;
        }

        .firstInOrder {
            order: -2;
        }

        .secondInOrder {
            order: -1;
        }
    }

    .translationsSelect,
    .wrapper {
        margin-bottom: 25px;
    }

    .checkbox.formCheckbox {
        margin-bottom: 15px;
    }

    .datePickerContainer {
        .react-datepicker-wrapper {
            width: 100%;
            margin-bottom: 25px;
        }

        .icon.leftIcon {
            bottom: 0;
        }

        .hasLeftIcon {
            padding-left: 40px;
        }
    }

    input[type='number'] {
        padding-left: 8px;
    }

    .singleFormTitle {
        .header {
            margin-bottom: 25px;

            .infoIcon {
                display: inline-flex;
                margin-left: 10px;
                border: 1px solid ${({ theme }) => theme.color.general.dark};

                path {
                    fill: ${({ theme }) => theme.color.general.dark};
                }
            }
        }

        //&.socialNetworks.opened {
        //  margin-bottom: 44px;
        //}
    }

    .rightPanel & {
        .expansionPanel {
            padding: 20px 0;
            border-top: 1px solid ${({ theme }) => theme.bordersColor};

            &.multiSelectTable {
                padding-bottom: 0;
                padding-top: 10px;
            }

            &.opened:not(.comments) {
                padding-bottom: 0;

                .panelHeader {
                    margin-bottom: 20px;
                }
            }

            .tabsDropdown {
                z-index: 5;
            }

            .table.withSummary {
                margin-bottom: 48px;

                .summary {
                    background-color: white;
                    left: 0;
                    right: 0;
                    padding: 0;
                    border-radius: 0;
                }
            }
        }
    }
`
export const StyledLabel = styled(Typography)`
  display: block;
  margin-bottom: 10px;
  min-width: ${({ labelWidth }) => labelWidth};
  margin-right: 15px;
  font-size: ${({ theme: { components }, variant }) => components.input[variant].black.large['font-size']};
`

export const StyledInputWrapper = styled.div`
  position: relative;

  .error {
    .editable.navbar & {
      white-space: nowrap;
    }
  }

  .inputContainer {
    margin-bottom: 15px;
  }

  &.row {
    width: ${({ width }) => width};
    flex-grow: 1;

    &:first-child {
      margin-left: 0;
    }

    .formInput {
      margin: 0;
      padding: 0;
    }

    @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm - 1}px) {
      width: 100%;
      margin-bottom: 10px;
    }
  }

  .label {
    ${({ theme }) => getTokens('typography-link-black-large', theme)};
    margin-bottom: 8px;
  }
`

export const StyledFormButtons = styled.div`
  background-color: ${({ theme }) => theme.color.general.light};
  padding-bottom: ${({ theme: { grid }, currentBreakpoint }) => grid[currentBreakpoint].columns.gapWidth};

  &.sticky {
    position: sticky;
    bottom: 0;
    z-index: 8;
    padding-bottom: 20px;
    order: 100;
    margin-top: auto;

    @media only screen and (max-width: ${({ theme }) => theme.breakpoints.sm - 1}px) {
      left: 0;
    }
  }

  .stickyButtonsWrapper {
    display: flex;
    gap: 10px;
  }
`

export const StyledAdditionToInputLink = styled(UiLink)`
  position: absolute;
  top: 4px;
  right: 0;
  z-index: 2;
  a {
    ${({ theme }) => getTokens('typography-body2-black-large', theme)};
  }
`

export const StyledExpansionPanel = styled(ExpansionPanel)`
  position: relative;

  &.hidden {
    display: none;
  }

  &.isEditableTable {
    &.shipment_items:not(.opened) {
      .panelHeader {
        margin-bottom: 0 !important;
      }
    }

    .translationsSelect {
      position: sticky;
      top: 48px;
      order: 2;
      margin-bottom: 20px;
      z-index: 10;
    }
    .changingBlocksEditableTableSelect {
      order: 1;
      margin-bottom: 10px;
      z-index: 4;
      .react-select__control {
        min-height: 32px;
      }
    }
    .editable {
      //navbar
      order: 3;
      position: sticky;
      z-index: 3;
      top: 92px;
      background-color: ${({ theme: { color } }) => color.general.light};

      &.navbar {
        .select {
          width: 100%;
        }
      }

      & ~ .table {
        .BaseTable__table-main {
          border-top: none;
        }

        div.BaseTable__row:last-of-type {
          border-bottom: none;
        }
      }
    }
    .table {
      order: 4;
    }
  }
`

export const StyledFlexRow = styled.div`
  display: flex;

  .typography {
    line-height: 24px;
  }

  .label {
    margin-right: 10px;
  }
`
