import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'

import { Select } from '@aidsupply/components'

import { selectApiUrlParam, selectSystemCollections } from '../../redux/selectors'
import { setApiUrlParam } from '../../redux/actions'
import { useMappedState } from '../../hooks'

const StyledOrganizationSelect = styled(Select)`
  width: 200px;
  .react-select__control {
    min-height: 32px;
  }
`

const OrganizationSelect = ({ label, t }) => {
  const dispatch = useDispatch()
  const organizations = useMappedState(selectSystemCollections('organizations'))
  const organizationsArray = organizations && Object.values(organizations)

  const organization = useMappedState(selectApiUrlParam)

  useEffect(() => {
    if (!organization && organizationsArray) {
      dispatch(setApiUrlParam(organizationsArray?.[0]?.id))
    }
  }, [organization, organizationsArray])

  if (!organizationsArray) {
    return null
  }

  const onChange = (organization) => {
    dispatch(setApiUrlParam(organization?.id))
  }

  return (
    <StyledOrganizationSelect
      label={label}
      labelType="top"
      options={organizationsArray}
      value={organizations[organization]}
      onChange={onChange}
      valueKey="id"
      labelKey="name"
      variant="primary"
      withBorder
      withoutValidation
      // width="200px"
      placeholder={t('organization')}
    />
  )
}

export default OrganizationSelect
