import React from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'

import GridLayout from '../../Layout/GridLayout'
import TableBlock from '../../../components/Table/TableBlock'
import ItemsTableCard from './ItemsTableCard'

const Items = () => {
  const { t } = useTranslation()

  return (
    <GridLayout RightPanelComponent={<Outlet context={{}} />}>
      <TableBlock
        // hideFilterPanel={hideFilterPanel}
        isMainTable
        TableCardContent={ItemsTableCard}
        tableCardHeight={170}
        // isReadOnly={isReadOnly}
        // rightPanelIsOpened={rightPanelIsOpened}
        // gap={gap}
        // leftPanelIsOpened={leftPanelIsOpened}
        // setLeftPanelOpened={setLeftPanelOpened}
      />
    </GridLayout>
  )
}

export default Items
