import { put, take, call, takeLatest } from 'redux-saga/effects'
import { channel, eventChannel } from 'redux-saga'
import ReconnectingWebSocket from 'reconnecting-websocket'

import { INITIALIZE_APP, SIGN_IN_ERROR, SIGN_IN_SUCCESS, UPDATE_TABLE_AND_SYSTEM_DATA } from '../constants'
import { getCurrentUser, setNotifications } from '../actions'
import { verifyToken } from '../../api'

const initChannel = channel()

const wsUrl = {
  stage: 'wss://juffaub4zc.execute-api.eu-west-2.amazonaws.com/$default',
  development: 'wss://juffaub4zc.execute-api.eu-west-2.amazonaws.com/$default',
  production: 'wss://d4ddzr8cri.execute-api.eu-west-2.amazonaws.com/$default',
}

// function* authInit() {
//   console.log('authInit')
//
//   try {
//     const system = yield select(mapStateToProps)
//     yield readSystemData(SYSTEM_DATA_KEYS.login, system)
//   } catch (error) {
//     console.log(error)
//     // yield put({ type: LOGIN_AUTH_FAILURE, error })
//   }
// }

function* doInitializeApp({ navigate, location, changeLanguage }) {
  console.log('authInit')
  try {
    //     const system = yield select(mapStateToProps)
    //     yield readSystemData(SYSTEM_DATA_KEYS.login, system)
    const token = yield verifyToken()

    if (token) {
      yield put({ type: SIGN_IN_SUCCESS, token })
      yield put(getCurrentUser(navigate, location))
    } else {
      navigate('/login')
      yield put({ type: SIGN_IN_ERROR })
      // navigate('/login')
    }
  } catch (error) {
    console.log(error)
    // navigate('/login'
    navigate('/login')
    yield put({ type: SIGN_IN_ERROR })
  }
}

function* watchInitChannel() {
  while (true) {
    const action = yield take(initChannel)
    yield put(action)
  }
}

export function initWebsocket() {
  const data = {} //yield authCurrentUser()
  const idToken = data?.idToken

  return eventChannel((emitter) => {
    const ws = new ReconnectingWebSocket(
      wsUrl[window.location.hostname.startsWith('stage') ? 'stage' : process.env.NODE_ENV],
      [],
      {
        maxReconnectionDelay: 60000,
        minReconnectionDelay: 5000,
        reconnectionDelayGrowFactor: 1.5,
      }
    )

    ws.onopen = () => {
      if (idToken) {
        console.log('opening...')
      }
      ws.send(JSON.stringify({ action: 'auth', token: idToken }))
    }

    ws.onerror = (error) => {
      console.log('WebSocket error ' + error)
      console.dir(error)
    }

    ws.onclose = () => {
      console.log('Socket onclose')
    }

    ws.onmessage = (e) => {
      let msg = null

      try {
        msg = JSON.parse(e.data)
      } catch (e) {
        console.error(`Error parsing : ${e.data}`)
      }

      console.log(msg)

      if (msg?.collection) {
        const data = {
          dataType: msg.collection,
          itemToSet: { ...msg.entity, id: msg.entity._id },
          status: msg.operation,
        }

        initChannel.put(setNotifications({ ...msg, entity: { ...msg.entity, id: msg.entity._id } }))
        initChannel.put({ type: UPDATE_TABLE_AND_SYSTEM_DATA, ...data })
      }
    }

    // unsubscribe function
    return () => {
      console.log('Socket off')
      ws.close()
    }
  })
}

function* wsSaga() {
  const channel = yield call(initWebsocket)

  while (true) {
    const action = yield take(channel)
    yield put(action)
  }
}

export function* watchLiveDataSaga() {
  // yield takeEvery("SOCKET_START", wsSaga);
  yield wsSaga()
}

export default function* initSaga() {
  return [yield takeLatest(INITIALIZE_APP, doInitializeApp)]
}
// yield watchInitChannel()
