import { combineReducers } from 'redux'

import data from './reducers/data'
import fileUpload from './reducers/fileUpload'
import sidebar from './reducers/sidebar'
import user from './reducers/user'
import common from './reducers/common'
import forms from './reducers/forms'
import filters from './reducers/filters'
import basket from './reducers/basket'

export default combineReducers({
  data,
  fileUpload,
  filters,
  sidebar,
  user,
  common,
  forms,
  basket,
})
