import { Outlet, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { withTheme } from 'styled-components'

import { Button } from '@aidsupply/components'

import TableBlock from '../../../components/Table/TableBlock'
import { useMappedState } from '../../../hooks'
import { selectUserDetails } from '../../../redux/selectors'
import GridLayout from '../../Layout/GridLayout'
import BlogPostsTableCard from './BlogPostsTableCard'

const BlogPosts = ({ theme }) => {
  const navigate = useNavigate()
  const { t } = useTranslation(['table'])
  const user = useMappedState(selectUserDetails)

  const onBlogTopicsMenuOpen = () => navigate('./topics')

  return (
    <GridLayout RightPanelComponent={<Outlet />}>
      <TableBlock
        isMainTable
        navbarProps={{
          navBarChildren: ['system', 'administrator'].includes(user.role) ? (
            <Button
              iconLeftProps={{ name: 'file', stroke: theme.color.general.gray5 }}
              size="small"
              variant="bordered"
              onClick={onBlogTopicsMenuOpen}
              className="topics"
              text={t('topics')}
            />
          ) : null,
        }}
        emptyScreenChildren={
          <Button text={t('loadingOrNoData:addTopic')} onClick={onBlogTopicsMenuOpen} variant="bordered" />
        }
        TableCardContent={BlogPostsTableCard}
        tableCardHeight={130}
      />
    </GridLayout>
  )
}

export default withTheme(BlogPosts)
