import {
  AVATAR_UPLOAD,
  FILE_PARSE,
  INSTANCE_CREATE,
  SET_USER_ROLE,
  SET_USER_ROLE_ERROR,
  SET_USER_ROLE_SUCCESS,
  SIDEBAR_FETCH_BY_ID,
  SIDEBAR_FETCH_BY_ID_ERROR,
  SIDEBAR_FETCH_BY_ID_SUCCESS,
  SIDEBAR_ITEM_PHOTOS_SET,
  SIDEBAR_ITEM_RESET,
  SIDEBAR_ITEM_SET,
  SIDEBAR_POST_COMMENT,
  SIDEBAR_POST_COMMENT_ERROR,
  SIDEBAR_POST_COMMENT_SUCCESS,
  SIDEBAR_UPSERT,
  SIDEBAR_UPSERT_ERROR,
  SIDEBAR_UPSERT_SUCCESS,
  SIGN_OUT_SUCCESS,
  UPSERT,
  UPSERT_ERROR,
  UPSERT_SUCCESS,
} from '../constants'

const defaultState = {
  initial: null,
  itemInProgress: null,
  error: null,
  upsertError: null,
  upsertInProgress: null,
  sidebarUpsertInProgress: null,
}

export default (state = defaultState, action) => {
  switch (action.type) {
    // case FILE_PARSE_SUCCESS:
    //   return {
    //     ...state,
    //     initial: {
    //       ...state.initial,
    //     },
    //   }
    case SIDEBAR_FETCH_BY_ID:
      return {
        ...state,
        itemInProgress: !action.payload.reload,
        sidebarUpsertInProgress: action.payload.reload,
      }
    case SIDEBAR_FETCH_BY_ID_ERROR:
      return {
        ...state,
        itemInProgress: false,
        sidebarUpsertInProgress: false,
        error: action.error,
      }
    case SIDEBAR_FETCH_BY_ID_SUCCESS:
      return {
        ...state,
        error: false,
        itemInProgress: false,
        sidebarUpsertInProgress: false,
        initial: action.item?.id ? action.item : { ...action.item, id: action.item._id || '' },
      }
    case SIDEBAR_ITEM_RESET:
      return defaultState
    case SIDEBAR_ITEM_SET:
      return {
        ...state,
        initial: action.item || state.initial,
        itemInProgress: false,
        sidebarUpsertInProgress: false,
        error: false,
      }
    case SIDEBAR_ITEM_PHOTOS_SET:
      return {
        ...state,
        initial: {
          ...state.initial,
          photos: action.photos || state.initial.photos,
          files: action.files || state.initial.files,
        },
      }
    case SIDEBAR_UPSERT:
    case SET_USER_ROLE:
    case AVATAR_UPLOAD:
      return {
        ...state,
        sidebarUpsertInProgress: true,
      }
    case UPSERT:
      return {
        ...state,
        upsertInProgress: true,
      }
    case SIDEBAR_UPSERT_SUCCESS:
      return {
        ...state,
        initial: action.item?.photos
          ? { ...action.item, photos: state.initial.photos }
          : action.item || state.initial,
        error: false,
        sidebarUpsertInProgress: false,
      }
    case SET_USER_ROLE_SUCCESS:
      return {
        ...state,
        initial: action.item,
        error: false,
        sidebarUpsertInProgress: false,
      }
    case INSTANCE_CREATE:
      return { ...state, upsertInProgress: true }
    case UPSERT_SUCCESS:
      return { ...state, upsertInProgress: false, upsertError: false }
    case SIDEBAR_UPSERT_ERROR:
    case SET_USER_ROLE_ERROR:
      return {
        ...state,
        error: action.error,
        sidebarUpsertInProgress: false,
      }
    case SIDEBAR_POST_COMMENT:
      return {
        ...state,
      }
    case SIDEBAR_POST_COMMENT_SUCCESS:
      return {
        ...state,
        initial: { ...state.initial, comments: [...state.initial.comments, action.comment] },
        error: false,
      }
    case SIDEBAR_POST_COMMENT_ERROR:
      return {
        ...state,
        error: action.error,
      }
    case UPSERT_ERROR:
      return {
        ...state,
        upsertError: action.error,
        upsertInProgress: false,
        sidebarUpsertInProgress: false,
      }
    case SIGN_OUT_SUCCESS:
      return defaultState

    default:
      return state
  }
}
