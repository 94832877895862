import { pick } from 'dot-object'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { getEditableTableColumns } from '../../config/columnsEditableTable'
import { EDITABLE_TABLE_NAVBAR_FORM_CONFIG } from '../../config/editableTableNavbarForms'
import { useMappedState } from '../../hooks'
import { selectAllSystemCollections, selectDataType, selectUserRole } from '../../redux/selectors'
import { getOptionsBasedOnCategory } from '../../utils/forms'
import MultiSelectTable from '../MultiSelectTable/MultiSelectTable'
import OrderFormActivity from '../OrderForm'
import EditableTableFormPart from '../RightPanelForms/EditableTableFormPart'
import ItemAttributesFormPart from '../RightPanelForms/ItemAttributesFormPart'
import FilesForm from '../RightPanelForms/FilesForm'
import GenerateNewOrganization from '../RightPanelForms/components/GenerateNewOrganization'
import StockItemsUpload from '../StockItemsUpload'

const FormBlockWrapper = ({
  blockKey,
  children,
  compoundTypeKey,
  customBlockValues,
  editableTableConfig,
  fields,
  formFieldsProps,
  formValues,
  formValuesChanged,
  getPanelHeader,
  id,
  isReadOnly,
  optionsData,
  touchedFields,
  setTouchedFields,
  setTextsChosenLng,
  updateCheckbox,
  updateInput,
  updateSelect,
}) => {
  const type = useMappedState(selectDataType)
  const system = useMappedState(selectAllSystemCollections)
  const role = useMappedState(selectUserRole)
  const {
    t,
    i18n: { language },
  } = useTranslation('table')

  if (blockKey === 'noExpansionTop') {
    return children
  }

  const editableTableColumns = getEditableTableColumns(
    EDITABLE_TABLE_NAVBAR_FORM_CONFIG[type] ? type : compoundTypeKey,
    '',
    t
  )

  const additionalFormValues = editableTableConfig?.optionsFromValuesDependencies?.reduce(
    (acc, curr) => ({ ...acc, [curr]: pick(curr, formValues) }),
    {}
  )

  return (
    <>
      {!!editableTableColumns && (
        <EditableTableFormPart
          additionalFormValues={additionalFormValues}
          id={id}
          cellProps={{
            system,
            custom_block_values: customBlockValues,
            lng: language,
            id,
            user: { role },
            is_read_only: isReadOnly,
            additional_form_values: additionalFormValues,
          }}
          isReadOnly={blockKey === 'shipment_items' && formValues.order_id}
          compoundTypeKey={compoundTypeKey}
          optionsData={optionsData}
          language={language}
          typeData={{ key: EDITABLE_TABLE_NAVBAR_FORM_CONFIG[type] ? type : compoundTypeKey }}
          columns={editableTableColumns}
          data={blockKey === 'modifications' ? customBlockValues : formValues[blockKey]}
          blockKey={blockKey}
          formFieldsProps={formFieldsProps}
          fields={fields}
          getPanelHeader={getPanelHeader}
          updateTableRows={updateSelect.bind(null, blockKey)}
          setTextsChosenLng={setTextsChosenLng}
          validationRules={editableTableConfig?.validationRules}
          t={t}
        />
      )}

      {['inquiries', 'orders', 'shipments', 'invoices'].includes(type) && blockKey === 'comments' && (
        <OrderFormActivity updateInput={updateInput} formValuesChanged={formValuesChanged} />
      )}

      {type === 'users' && blockKey === 'general' && fields.find((field) => field.key === 'organization') && (
        <GenerateNewOrganization updateSelect={updateSelect} formValues={formValues} />
      )}

      {blockKey === 'photos' || blockKey === 'files' ? (
        <FilesForm
          changedPhotos={formValuesChanged.photos}
          changedFilesStates={formValuesChanged.changedFilesStates}
          isReadOnly={isReadOnly}
          itemCharacteristics={formValues.characteristics}
          id={id}
          values={formValues[blockKey]}
          updateInput={updateInput}
          primaryPhotoId={formValues.photo_id}
          primaryLogoId={formValues.logo_photo_id}
          primaryBannerId={formValues.banner_photo_id}
          filesType={blockKey}
        />
      ) : (
        children
      )}

      {blockKey === 'stockItemsUpload' && <StockItemsUpload />}

      {type === 'carriers' && blockKey === 'deliveryOptions' && (
        <MultiSelectTable
          attributes={optionsData.attributes}
          customBlockValues={
            optionsData.attributes && [
              Object.values(optionsData.attributes).find((attr) => attr.slug === 'delivery-options'),
            ]
          }
          data={formValues[blockKey]}
          enumerations={optionsData.enumerations}
          headerHeight={0}
          keyToUpdate={blockKey}
          options={
            (optionsData.enumerations &&
              Object.values(optionsData.enumerations).find(
                (enumeration) => enumeration.slug === 'delivery-options'
              )?.options) ||
            []
          }
          type={blockKey}
          updateSelectValue={updateSelect}
          withTextHeader
        />
      )}

      {type === 'items' && ['properties', 'characteristics'].includes(blockKey) && (
        <ItemAttributesFormPart
          attributes={optionsData.attributes}
          blockKey={blockKey}
          blocks={getOptionsBasedOnCategory(formValues, optionsData, `general.${blockKey}`, 'attributes')}
          customBlockValues={customBlockValues}
          enumerations={optionsData.enumerations}
          formValues={formValues[blockKey]}
          getPanelHeader={getPanelHeader}
          photos={formValues.photos}
          units={optionsData.units}
          updateInput={updateInput}
          updateSelect={updateSelect}
          updateCheckbox={updateCheckbox}
        />
      )}
    </>
  )
}

export default FormBlockWrapper
