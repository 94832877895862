export default {
  errorPage: {
    goHome: 'На главную',
    letUsKnow: 'Сообщите нам',
    needHelp: 'Нужна помощь?',
    somethingsMissing: 'Упс! Чего-то не хватает',
  },
  footer: {
    allRightsReserved: 'Все права защищены',
    Feedback: 'Обратная связь',
  },
  forms: {
    aboutSection: 'Раздел "О нас"',
    account: 'Счет',
    accountNumber: 'Номер счета',
    admin: 'Админ',
    addToExistingItems: 'Добавить к существующим товарам',
    amount: 'Количество',
    answer: 'Ответ',
    assets: 'Ресурсы',
    attributes: 'Атрибуты',
    author: 'Автор',
    backgroundColor: 'Фоновый цвет',
    backgroundIsDark: 'Фон темный, текст светлый',
    bank: 'Банк',
    bannerCategory: 'Категория (выберите, только если баннер банер предназначен для категории)',
    banners: 'Банеры',
    bestsellers: 'Лидеры продаж',
    brandDescription: 'Описание бренда',
    brandPageHeaderPhrase: 'Фраза-заголовок страницы бренда',
    brands: 'Бренды',
    brandSlogan: 'Слоган бренда',
    brandSloganAuthor: 'Автор слогана бренда',
    bucketMonobank: 'Id банки в Монобанке',
    bucketWayforpay: 'Название Wayforpay страницы в url адресе',
    carriers: 'Перевозчики',
    cart: 'Корзина',
    categories: 'Категории',
    categoriesBig: 'Категории (большие плитки)',
    categoriesRecommended: 'Рекомендованные категории (мелкие)',
    characteristics: 'Характеристики',
    city: 'Город',
    color: 'Цвет',
    colors: 'Цвета',
    comment: 'Комментарий',
    comments: 'Комментарии',
    components: 'Компоненты',
    content: 'Состав',
    counterparties: 'Контрагенты',
    cover: 'Обложка',
    coverVideo: 'Видео-обложка',
    create: 'Создать',
    currencies: 'Валюты',
    custom: 'Пользовательский',
    customerFullName: 'Полное имя заказчика',
    dark: 'Темный',
    darker: 'Темнее',
    darkest: 'Самый темный',
    dateModified: 'Дата изменения',
    de: 'Немецкий',
    defaultLanguage: 'Язык по умолчанию',
    deliveryOptions: 'Варианты доставки',
    description: 'Описание',
    dimensions: 'Размеры',
    district: 'Район',
    details: 'Детали',
    document: 'Документ',
    dropOrSelect: 'Перетащите или выберите файлы с локального диска',
    edit: 'Редактировать',
    en: 'Английский',
    error: 'Ошибка',
    externalNumber: 'Номер входящего документа',
    faqSection: 'Раздел часто задаваемых вопросов',
    favicon: 'Фавикон',
    featuredProducts: 'Рекомендованные товары',
    feedbackSection: 'Раздел обратной связи',
    fillGeneralAndSaveBeforeUploadingFile:
      'Пожалуйста, заполните все обязательные поля и нажмите кнопку на «Сохранить» перед загрузкой файла',
    fillGeneralAndSaveBeforeUploadingPhotos:
      'Пожалуйста, заполните все обязательные поля и нажмите кнопку на «Сохранить» перед загрузкой фотографий',
    financialInfo: 'Финансовая информация',
    firstPageImageIsBackground: 'Изображение первой страницы является фоновым',
    firstPageText: 'Текст на первой странице',
    firstPageWithOverlay: 'Первая страница с наложением',
    firstScreen: 'Первый экран',
    firstScreenMobile: 'Первый экран мобильного',
    font: 'Шрифт',
    from: 'от',
    fundraisingAccounts: 'Счета для сбора средств',
    general: 'Общие',
    generalInfo: 'Общая информация',
    generateModelNumber: 'Сгенерировать код модели',
    generateNewOrganization: 'Создать организацию',
    gray1: 'Серый1',
    gray2: 'Серый2',
    gray3: 'Серый3',
    gray4: 'Серый4',
    gray5: 'Серый5',
    gray6: 'Серый6',
    gray7: 'Серый7',
    header: 'Заголовок',
    history: 'История',
    id: 'Индонезийский',
    info: 'Информация',
    introductionVideo: 'Вступительное видео',
    introSection: 'Вводный раздел',
    inventoryDetails: 'Детали инвентаризации',
    isCharacteristic: 'Является характеристикой',
    isComingSoon: 'Страница скоро появится',
    isCrm: 'Есть страницей для CRM',
    isFragile: 'Хрупкий груз',
    isPhotoGroup: 'Это группа фотографий',
    isUsedForInquiries: 'Используется для Запросов/Нужд',
    items: 'Товары',
    key: 'Ключ',
    keywords: 'Ключевые слова',
    label: 'Название',
    languages: 'Языки',
    light: 'Светлый',
    lighter: 'Светлее',
    lightest: 'Самый светлый ',
    linkToPageText: 'Текст ссылки на страницу',
    logos: 'Лого',
    main: 'Главный',
    media: 'СМИ',
    merchants: 'Продавцы',
    miniLogos: 'Мини логотипы',
    mobile: 'Для мобильных',
    modifications: 'Модификации',
    name: 'Наименование',
    nameInEnglish: 'Имя на английском',
    inquiry_items: 'Категории продуктов',
    new: 'Новый',
    noOptionsFound: 'Вариантов не найдено',
    optionIconsUploadIsAllowed: 'Загрузка иконок для опций разрешена',
    orderInfo: 'Информация о заказе',
    organizationFromSignup: 'Организация, указанная при регистрации',
    organizations: 'Организации',
    organizationEmail: 'Электронная почта организации',
    organizationType: 'Тип организации',
    organizationWebsite: 'Сайт организации',
    myOrganization: 'Моя организация',
    overlay: 'Наложение ',
    page404: 'Страница 404',
    pageContent: 'Наповнення',
    password: 'Пароль',
    phoneNumber: 'Номер телефона',
    photos: 'Фотографии',
    pickup: 'Самовывоз',
    pickupAddress: 'Адрес доставки',
    pl: 'Польский',
    platformConstructor: 'Конструктор платформы',
    platformIsComingSoon: 'Платформа скоро появится',
    primary: 'Изначальный',
    products: 'Товары',
    productsDescription: 'Описание товаров',
    productsTitle: 'Заголовок для товаров',
    profilePicture: 'Изображение профиля',
    properties: 'Свойства',
    recipient: 'Получатель',
    requiresCooling: 'Требует охлаждения',
    requiresFreezing: 'Требует заморозки',
    registrationNumber: 'Регистрационный номер',
    ru: 'Русский',
    replaceExistingItems: 'Заменить существующие товары',
    scrollDownForModifications: 'Нажмите, чтобы перейти к модификациям товара с кодами внизу',
    search: 'Поиск',
    secondary: 'Второстеппеный',
    secondPageText: 'Текст другой страницы',
    secondScreen: 'Второй экран',
    seo: 'SEO Оптимизация',
    sharingImage: 'Картинка для превью',
    shipmentAddress: 'Адрес доставки',
    shippingDetails: 'Детали доставки',
    shortUrl: 'Короткий URL',
    showMore: 'Показать больше',
    socialNetworks: 'Социальные сети',
    socialProfiles: 'Социальные сети',
    sorting: 'Сортировка',
    square: 'Квадратный',
    state: 'Состояние',
    status: 'Статус',
    subtotal: 'Промежуточный итог',
    success: 'Успех',
    systemNameInEnglish: 'Название на английском',
    tax: 'Налог',
    taxRate: 'Налоговая ставка',
    team: 'Команда',
    teamDescription: 'Описание команды',
    teamSlogan: 'Слоган команды',
    textForEveryItem: 'Текст для каждого товара',
    texts: 'Тексты',
    title: 'Название',
    tokens: 'Жетоны',
    translations: 'Переводы',
    types: 'Типы',
    uk: 'Украинский',
    uploadFailed: 'Ошибка загрузки',
    values: 'Ценность',
    viewProfile: 'Просмотр профиля',
    videoPreviewImage: 'Изображение для предварительного просмотра видео',
    videos: 'Видео',
    videoSection: 'Раздел видео',
    videoUrl: 'URL-адрес видео',
    warning: 'Предупреждение',
    wayforpayURL: 'Wayforpay URL',
    wayforpayAccount: 'Учётная запись Wayforpay',
    wayforpayKey: 'Секретный ключ Wayforpay',
    website: 'Веб-сайт',
    weight: 'Вес',
    weightUnit: 'Единица измерения веса',
    yearFounded: 'Год основания',
  },
  general: {
    isIconsUploadAllowed: 'Загрузка иконок для опций разрешена',
    fileUpload: 'Загрузка файлов',
    optimalDimensions: 'Оптимальные размеры 1920 x 210px',
    pageNotFound: 'Страница не найдена',
    replaceBannerImage: 'Заменить изображение баннера',
    replaceImage: 'Заменить изображение',
    template: 'Шаблон',
  },
  header: {
    Profile: 'Профиль',
    quickMenu: 'Быстрое меню',
    searchBar: 'Поиск',
    Settings: 'Настройки',
  },
  loadingOrNoData: {
    addFirstItem: 'Добавить {{itemType}}',
    attribute: 'атрибут',
    addTopic: 'Добавить тему',
    banner: 'баннер',
    blogPost: 'пост',
    brand: 'бренд',
    campaign: 'кампанию',
    carrier: 'перевозчика',
    category: 'категорию',
    client: 'клиента',
    contract: 'контракт',
    counterparty: 'контрагента',
    demand: 'потребность',
    enumeration: 'перечень',
    FAQ: 'вопрос',
    inquiry: 'запрос',
    invoice: 'счет',
    item: 'товар',
    loading: 'Загрузка...',
    merchant: 'продавца',
    noDataFound: 'Данные не найдены',
    order: 'заказ',
    page: 'страницу',
    platform: 'платформу',
    serverError: '500 внутренняя ошибка сервера',
    shipment: 'отгрузку',
    warehouse: 'склад',
    'stock-item': 'товар на складе',
    unit: 'единицу измерения',
    user: 'пользователя',
  },
  menu: {
    address: 'Адрес',
    admin: 'Админ',
    attributes: 'Атрибуты',
    banners: 'Баннеры',
    brands: 'Бренды',
    blogPosts: 'Посты',
    campaigns: 'Кампании',
    carriers: 'Перевозчики',
    categories: 'Категории',
    clients: 'Клиенты',
    contracts: 'Договоры',
    counterparties: 'Контрагенты',
    dashboard: 'Дашборд ',
    documents: 'Документы',
    enumerations: 'Перечни',
    Help: 'Помощь',
    inventories: 'Инвентаризация',
    inventory: 'Инвентарь',
    inquiries: 'Запросы',
    invoices: 'Счета',
    items: 'Товары',
    Logout: 'Выход',
    merchant: 'Продавец',
    merchants: 'Продавцы',
    inquiry_items: 'Категории продуктов',
    new: 'новый',
    options: 'Опции',
    orders: 'Заказы',
    organizations: 'Организации',
    myOrganization: 'Моя организация',
    pages: 'Страницы',
    platform: 'Платформа',
    products: 'Товары',
    Search: 'Поиск',
    Settings: 'Настройки',
    shipments: 'Отгрузки',
    'stock-items': 'Товары на складе',
    warehouses: 'Склады',
    system: 'Система',
    units: 'Единицы измерения',
    users: 'Пользователи',
  },
  notifications: {
    attributes: '{{status}} атрибут',
    banners: '{{status}} баннер',
    brands: '{{status}} бренд',
    bulkStatusesUpdated: '{{count}} {{type}} сменили статус: {{status}}',
    carriers: '{{status}} перевозчик',
    categories: '{{status}} категория',
    clearNotifications: 'Очистить уведомления',
    clients: '{{status}} клиент',
    contracts: '{{status}} договор ',
    counterparties: '{{status}} контрагент',
    created: 'Создано: ',
    enumerations: '{{status}} перечень',
    faqs: '{{status}} FAQ',
    inquiries: '{{status}} запрос',
    invoices: '{{status}} счет',
    items: '{{status}} товар',
    merchants: '{{status}} продавец',
    noNotifications: 'Новых уведомлений нет',
    Notifications: 'Уведомления',
    orders: '{{status}} заказ №{{id}}',
    ordersBulk: 'заказ',
    organizations: '{{status}} организация',
    pages: '{{status}} страница',
    platforms: '{{status}} платформа',
    shipments: '{{status}} отгрузка',
    'stock-items': '{{status}} товар на складе',
    'stock-itemsBulk': 'складские позиции',
    warehouses: '{{status}} склад',
    units: '{{status}} единица измерения',
    updated: 'Изменено: ',
    uploadItems: 'Используйте этот шаблон для загрузки своих товаров.',
    users: '{{status}} пользователь',
  },
  orderButtons: {
    invoice: 'Создать счет',
    shipment: 'Создать отгрузку',
  },
  popupAlerts: {
    dataSuccessfullySaved: 'Данные были успешно сохранены!',
    errorInSavingData: 'При сохранении данных произошла ошибка',
    fillModsError: 'Произошла ошибка при заполнении модификаций товара',
    IDisNotCorrect: 'Неверный ID в URL',
    loginError: 'Неверное имя пользователя или пароль',
    loginSocialError: 'Произошла непредвиденная ошибка',
    passRecoverSentText: 'Мы отправили ссылку для восстановления пароля на вашу электронную почту.',
    signUpSuccess: 'Спасибо за регистрацию!\nМы сообщим вам об активации аккаунта по электронной почте.',
    'User is not confirmed.':
      'Учетная запись пользователя не активна. Пожалуйста, свяжитесь с администратором',
  },
  productsForm: {
    address: 'Адрес',
    basketEmpty: 'Ваша корзина пуста',
    cart: 'Корзина',
    city: 'Город',
    contacts: 'Контакты',
    country: 'Страна',
    name: 'Имя',
    orderFrom: 'Заказ от склада',
    outOfStock: 'Нет в наличии',
    phone: 'Номер телефона',
    preview: 'Превью',
    submitOrder: 'Подтвердить',
    summary: 'Сводка',
    total: 'Итого',
  },
  signIn: {
    alreadyHaveAccount: 'Уже есть аккаунт?',
    backTo: 'Вернуться к',
    checkYourMailbox: 'Проверьте свой почтовый ящик',
    createAccount: 'Создайте учетную запись!',
    createAccountAndUse: 'Создайте свою учетную запись и начните ее использовать',
    continueGoogle: 'Продолжить с Google',
    dontHaveAccount: 'У вас нет учетной записи?',
    fillTheFormToProceed: 'Заполните форму, чтобы завершить регистрацию',
    firstName: 'Имя',
    forgotPassword: 'Забыли пароль?',
    inUkraine: 'в Украине',
    lastName: 'Фамилия',
    legalPagesInscription:
      'Нажимая «Зарегистрироваться», вы соглашаетесь с тем, что ознакомились и согласны с',
    or: 'или',
    orLoginWith: 'Или войдите с помощью',
    passRecoverSentText:
      'Мы отправили вам инструкции по восстановлению пароля. Также проверьте папку со спамом, если вы не можете найти наше письмо!',
    passRecoverText: 'Введите свой адрес электронной почты ниже, чтобы получить инструкции по сбросу пароля.',
    passwordRecovery: 'Востановление пароля',
    recoverPassword: 'Восстановить пароль',
    registerWith: 'Зарегистрируйтесь при помощи',
    rememberMe: 'Запомнить меня',
    signIn: 'Войти',
    signInToContinue: 'Войдите, чтобы продолжить',
    signUp: 'Зарегистрироваться',
    termsAgree: 'Я согласен(-на) с Правилами и Условиями',
    toSignIn: 'Авторизации',
    welcome: 'Добро пожаловать!',
    withTermsAndConditions: 'правилами и условиями',
  },
  statuses: {
    open_demands: 'Новых',
    in_progress_demands: 'В работе',
    closed_demands: 'Закрытых',
    total_demands: 'Всего',
  },
  table: {
    actions: 'Действия',
    activity: 'Активность',
    add: 'Добавить',
    address: 'Адрес',
    availability: 'Наличие',
    banner: 'Баннер',
    block: 'Заблокировать',
    brand: 'Бренд',
    carrier: 'Перевозчик',
    categories: 'Категории',
    category: 'Категория',
    city: 'Город',
    changeStateTo: 'Изменить состояние на:',
    changeStatusTo: 'Изменить статус на:',
    characteristics: 'Характеристики',
    clearFilters: 'Очистить фильтры',
    client: 'Клиент',
    closed_demands: 'Закрытых',
    code: 'Код',
    color: 'Цвет',
    copy: 'Копировать',
    counterparty: 'Контрагент',
    country: 'Страна',
    created: 'Создан',
    currency: 'Валюта',
    customer: 'Заказчик',
    date: 'Дата',
    dateCreated: 'Дата создания',
    delete: 'Удалить',
    deleted: 'Удалено',
    discount: 'Скидка, %',
    donor: 'Донор',
    drafted: 'Черновик',
    email: 'E-mail',
    enumeration: 'Перечень',
    file: 'Файл',
    filters: 'Фильтры',
    firstName: 'Имя',
    from: 'от',
    heightM: 'Высота, м',
    industries: 'Отрасли',
    industry: 'Отрасль',
    inquiry: 'Запрос',
    inquiryNumber: '№ запроса',
    invoiceNumber: '№ счета',
    inventoryNumber: '№ инвентаризации',
    in_progress_demands: 'В работе',
    issuer: 'Эмитент',
    item: 'Тoвар',
    items: 'Тoвары',
    label: 'Перевод',
    language: 'Язык',
    lastActivity: 'Последняя активность',
    lastLoggedIn: 'Последний вход',
    lastName: 'Фамилия',
    lengthM: 'Длина, м',
    linkToPage: 'Ссылка на страницу',
    magnitude: 'Величина',
    merchant: 'Продавец',
    mobile: 'Номер телефона',
    model: 'Модель',
    name: 'Название',
    noItems: 'Ничего не найдено',
    open_demands: 'Новых',
    options: 'Опции',
    order: 'Заказ',
    orderNumber: '№ заказа',
    organization: 'Организация',
    outOfStock: 'Нет в наличии',
    page: 'Страница',
    paid: 'Оплачено',
    parentCategory: 'Верхняя категория',
    payer: 'Плательщик',
    pcs: 'шт.',
    platform: 'Платформа',
    platforms: 'Платформы',
    posted: 'Опубликовано',
    price: 'Цена',
    price_retail: 'Цена розничная',
    price_sale: 'Уценка',
    provider: 'Провайдер',
    quantity: 'К-во',
    question: 'Вопрос',
    rank: 'Приоритет',
    ratio: 'Коэффициент',
    recipient: 'Получатель',
    received: 'Получено',
    region: 'Область',
    retail: 'Розничный',
    role: 'Роль',
    roles: 'Роли',
    saveModifications: 'Сохранить модификации',
    shipmentNumber: '№ отправки',
    size: 'Размер',
    state: 'Состояние',
    status: 'Статус',
    supplier: 'Поставщик',
    warehouse: 'Склад',
    'stock-item': 'Товар на складе',
    sum: 'Сумма',
    summary: 'Сводка',
    systemName: 'Системное имя',
    tags: 'Тэги',
    title: 'Заголовок',
    total: 'Всего',
    translation: 'Перевод',
    type: 'Тип',
    unit: 'Единица',
    url: 'URL',
    user: 'Пользователь',
    weightKg: 'Вес, кг',
    widthM: 'Ширина, м',
    website: 'Веб-сайт',
    wholesale: 'Оптовый',
  },
  validation: {
    capital: 'Заглавная буква',
    defaultTranslationRequired: 'Перевод по умолчанию не может быть пустым',
    email: 'Неверный формат электронной почты',
    lowercase: 'Cтрочная буква',
    minLength: '8 символов',
    number: 'Цифра',
    modelAlreadyExists: 'Такая модель уже существует',
    nameAlreadyExists: 'Такое имя уже существует',
    onlyDigits: 'Тут могут быть только цифры',
    required: 'Поле не может быть пустым',
    requiredIfFieldsEmpty: 'Заполните одно из этих полей: {{fields}}',
    specialChar: 'Специальный символ',
    urlAlreadyExists: 'Такой URL уже существует',
    year: 'Год должен быть в диапазоне от 1900 до текущего года',
  },
}
