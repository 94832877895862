import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  html {
    //height: -webkit-fill-available;
  }

  body {
    margin: 0;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: ${({ theme }) => theme.font.family.primary};
    //min-height: -webkit-fill-available;
    //overflow: hidden;

  }

  #root{
    //overflow: hidden;
    //width: 100vw;
    //height: 100vh;
  }

  // ReactTexty styles
  [data-texty] {
    display:block !important;
  }
  [data-texty-tooltip] {
    background-color: ${({ theme }) => theme.color.general.dark};
    max-width: 60vw;
    padding: 7px 10px;
    color: ${({ theme }) => theme.color.general.light};

    span {
      color: ${({ theme }) => theme.color.general.light};
    }
      
      &.invisible {
          opacity: 0;
      }
  }

  [data-texty-arrow] {
    border-color: ${({ theme }) => theme.color.general.dark} transparent transparent;
  }

  [data-tooltip]:before {
    /* needed - do not touch */
    content: attr(data-tooltip);
    position: absolute;
    opacity: 0;
    z-index: 50000;

    /* customizable */
    transition: all 0.15s ease;
    padding: 10px;
    //color: white !important;
    border-radius: 10px;
  }

  [data-tooltip]:hover:before {
    /* needed - do not touch */
    opacity: 1;

    /* customizable */
    background: ${({ theme }) => theme.color.general.dark};
    color: ${({ theme }) => theme.color.general.light};
    //margin-bottom: -60px;
    //margin-right: 60px;

    .table & {
      margin-bottom: 0;
      //margin-left: 150px;
    }
  }

  [data-tooltip]:not([data-tooltip-persistent]):before {
    pointer-events: none;
  }

  button {
    color: inherit;
    border: none;
    padding: 0;
    cursor: pointer;
  }

  button, input, optgroup, select, textarea {
    font-family: inherit;
  }

  figure {
    margin: 0;
  }

  input::-moz-focus-inner {
    border: 0;
    padding: 0;
    margin: 0;
  }

  //a:-webkit-any-link {
  //  color: unset;
  //}

  ul, ol, dd {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0;
    font-size: inherit;
    font-weight: inherit;
  }

  p {
    margin: 0;
  }

  fieldset {
    border-width: 0;
    padding: 0;
    margin: 0;
  }

  :focus {
    outline: 0;
  }
  ::placeholder {
    color: ${({ theme }) => theme.color.general.gray3} !important;
  }
  .react-select__placeholder {
    color: ${({ theme }) => theme.color.general.gray3} !important;
  }
  // button.bordered:active {
  //   background-color: ${({ theme }) => theme.color.general.gray4};
  //   color: ${({ theme }) => theme.color.general.light};
  //   .typography{
  //     color: ${({ theme }) => theme.color.general.light};
  //   }
  // svg {
  //   fill: ${({ theme: { color } }) => color.general.gray4};
  //   path {
  //     stroke: ${({ theme: { color } }) => color.general.light};
  //   }
  // }
  // }
  *, :after, :before {
    box-sizing: border-box;
  }

  .inline {
    display: inline;
  }

  //scrollbar
  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  /* Track */
  // ::-webkit-scrollbar-track {
  //   box-shadow: inset 0 0 4px ${({ theme }) => theme.color.general.gray1}; 
  //   border-radius: 5px;
  // }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.color.general.gray2};
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.color.general.gray2};
  }

  .ql-tooltip.ql-editing {
    z-index: 10000;
    transform: translateY(0);
    left: -10px !important;
    top: auto !important;
    bottom: -10px;
    border-radius: 6px;
    white-space: initial;
  }
`

export default GlobalStyle
