import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useParams } from 'react-router-dom'
import { useLocation, useNavigate } from 'react-router-dom'
import { withTheme } from 'styled-components'

import GridLayout from '../../Layout/GridLayout'
import TableBlock from '../../../components/Table/TableBlock'
import OrdersTableCard from './OrdersTableCard'
import { inputFilesClear, toggleRightPanelExtended } from '../../../redux/actions'
import { useDispatch } from 'react-redux'
import { useMappedState } from '../../../hooks'
import { selectDataType } from '../../../redux/selectors'

const Orders = ({ theme }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('forms')

  const { pathname } = useLocation()
  const { rightPanelId } = useParams()
  const navigate = useNavigate()
  const type = useMappedState(selectDataType)

  useEffect(() => {
    dispatch(inputFilesClear())
  }, [rightPanelId, type])

  const handleGoBack = () => {
    navigate(-1)
  }

  let title
  if (pathname.includes('new')) {
    title = t('create')
  } else if (pathname.includes('comments')) {
    title = t('comments')
  } else {
    title = t('edit')
  }

  const backIconProps =
    title === t('comments')
      ? {
          withBackIcon: true,
          backIconProps: {
            width: 24,
            height: 24,
            wrapperWidth: 32,
            wrapperHeight: 32,
            wrapperColor: theme.color.primary.main,
            fill: theme.color.general.light,
            strokeWidth: 1.4,
            onClick: handleGoBack,
          },
        }
      : {}

  const onToggleIconClickPanelWide = () => {
    dispatch(toggleRightPanelExtended())
  }

  return (
    <GridLayout
      RightPanelComponent={<Outlet context={{}} />}
      rightPanelProps={{
        title,
        ...backIconProps,
        withRightPanelWideIcon: true,
        onToggleIconClickPanelWide,
        isRightPanelWideProps: { width: 20, height: 20, wrapperWidth: 20, wrapperHeight: 20 },
      }}
    >
      <TableBlock
        // hideFilterPanel={hideFilterPanel}
        isMainTable
        // isReadOnly={isReadOnly}
        // rightPanelIsOpened={rightPanelIsOpened}
        // gap={gap}
        // leftPanelIsOpened={leftPanelIsOpened}
        // setLeftPanelOpened={setLeftPanelOpened}
        TableCardContent={OrdersTableCard}
        tableCardHeight={148}
      />
    </GridLayout>
  )
}

export default withTheme(Orders)
