import React, { useCallback, useContext } from 'react'
import { useDispatch } from 'react-redux'

import { ScreenContext } from '@aidsupply/components'

import { StyledGrid, StyledLeftDrawer } from './styled'
import { HEADER_HEIGHT, NAV_MENU_WIDTH } from '../../constants'
import CentralPanel from './CentralPanel'
import ErrorBoundary from '../../ErrorBoundary'
import RightPanel from '../../components/RightPanel'
import { useMappedState } from '../../hooks'
import {
  selectFiltersPanelOpened,
  selectIsNavMenuExtended,
  selectIsRightPanelExtended,
} from '../../redux/selectors'
import { toggleFiltersPanel } from '../../redux/actions'

const GridLayoutDesktop = ({
  children,
  className,
  isRightPanelOpened,
  setIsRightPanelOpened,
  isLeftPanelOpened,
  setIsLeftPanelOpened,
  LeftPanelComponent,
  RightPanelComponent,
  RightPanelMinWidth,
  rightPanelProps,
  t,
  theme,
  type,
}) => {
  const dispatch = useDispatch()
  const { currentBreakpoint } = useContext(ScreenContext)

  const isNavMenuExtended = useMappedState(selectIsNavMenuExtended)

  const filtersPanelOpened = useMappedState(selectFiltersPanelOpened)

  const isRightPanelWide = useMappedState(selectIsRightPanelExtended)

  const getWidth = useCallback(
    (panelColsCount) => {
      const navMenuWidth = isNavMenuExtended
        ? NAV_MENU_WIDTH.desktop.extended
        : NAV_MENU_WIDTH.desktop.condensed

      return `calc(((100vw - ${navMenuWidth}) / ${theme.grid[currentBreakpoint]?.columns?.count}) * ${panelColsCount} - 20px)`
    },
    [isNavMenuExtended, currentBreakpoint]
  )

  const getRightPanelWidth = (currentBreakpoint, isRightPanelWide) => {
    const panelWidth = isRightPanelWide
      ? theme.grid[currentBreakpoint]?.rightPanelColsWide
      : theme.grid[currentBreakpoint]?.rightPanelCols

    return `calc(((100vw) / ${theme.grid[currentBreakpoint]?.columns?.count}) * ${panelWidth} - 20px)`
  }

  const onToggleFilters = (opened) => {
    dispatch(toggleFiltersPanel(opened))
  }

  return (
    <StyledGrid className={isNavMenuExtended && 'extended'}>
      {LeftPanelComponent && (
        <StyledLeftDrawer
          borderColor={theme.bordersColor}
          headerHeight={HEADER_HEIGHT}
          width={getWidth(theme.grid[currentBreakpoint]?.leftPanelCols)}
          openedValue={filtersPanelOpened}
          toggleDrawer={onToggleFilters}
          side="left"
          title={t('table:filters')}
          className={type}
          currentBreakpoint={currentBreakpoint}
          id="filters"
          closeIconPadding="6px"
          closeIconProps={{
            width: 18,
            height: 18,
            wrapperWidth: 32,
            wrapperHeight: 32,
            wrapperColor: theme.color.primary.main,
            fill: theme.color.general.light,
            strokeWidth: 1.4,
          }}
        >
          <ErrorBoundary>{LeftPanelComponent}</ErrorBoundary>
        </StyledLeftDrawer>
      )}

      <CentralPanel className={className}>{children}</CentralPanel>

      {RightPanelComponent && (
        <RightPanel
          openedValue={isRightPanelOpened}
          toggleDrawer={setIsRightPanelOpened}
          width={getRightPanelWidth(currentBreakpoint, isRightPanelWide)}
          minWidth={RightPanelMinWidth}
          rightPanelProps={rightPanelProps}
          isRightPanelWide={isRightPanelWide}
        >
          <ErrorBoundary>{RightPanelComponent}</ErrorBoundary>
        </RightPanel>
      )}
    </StyledGrid>
  )
}

export default GridLayoutDesktop
