import dot from 'dot-object'
import { useEffect } from 'react'
import merge from 'deepmerge'

import { usePrevious } from '@aidsupply/components'

import { DEFAULT_VALUES_DATA } from '../../data/defaultValues'

export const useChangingBlocks = (
  type,
  changingBlocksDependency,
  initialChangingBlocksDependencyValue,
  initialValues,
  formValuesChanged,
  setFormValuesChanged,
  setFormErrors
) => {
  const prevChangingBlocksDependencyValueFromValuesChanged = usePrevious(
    formValuesChanged[changingBlocksDependency]?.id || formValuesChanged[changingBlocksDependency]
  )

  // set new form values when changingBlocksDependency value changed
  useEffect(() => {
    if (!changingBlocksDependency) return
    const changingBlocksDependencyValue =
      formValuesChanged[changingBlocksDependency]?.id || formValuesChanged[changingBlocksDependency]

    if (changingBlocksDependencyValue !== prevChangingBlocksDependencyValueFromValuesChanged) {
      setFormErrors({})
      const defaultCommonValuesDotted = dot.dot(DEFAULT_VALUES_DATA[type])

      const currentCommonValuesChanged = Object.keys(defaultCommonValuesDotted).reduce((acc, curr) => {
        if (typeof formValuesChanged[curr] !== 'undefined') {
          return { ...acc, [curr]: formValuesChanged[curr] }
        }
        return acc
      }, {})

      // TODO: after we write logic of hiding unnecessary columns we should change currentCommonValuesChanged => formValuesChanged to save all the data that user's ever written regardless changing of blocks. now we save only active block data
      setFormValuesChanged(
        changingBlocksDependencyValue
          ? merge(currentCommonValuesChanged, dot.dot(DEFAULT_VALUES_DATA[changingBlocksDependencyValue]))
          : currentCommonValuesChanged
      ) // save values that were changed by user before + initial changing values
    }
  }, [
    changingBlocksDependency,
    formValuesChanged,
    initialValues,
    setFormValuesChanged,
    type,
    prevChangingBlocksDependencyValueFromValuesChanged,
    initialChangingBlocksDependencyValue,
  ])

  return { initialChangingBlocksDependencyValue }
}
