import React, { useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { withTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'
import merge from 'deepmerge'
import { object } from 'dot-object'

import { Button, Typography, useWindowWidth, isObject, isObjectEmpty } from '@aidsupply/components'
import { useMappedState } from '../../../hooks'
import {
  selectAllSystemCollections,
  selectSidebarInitialData,
  selectSidebarUpsertInProgress,
  selectUserOrganizationId,
  selectUserRole,
} from '../../../redux/selectors'
import Form from '../../../components/Form'
import { sidebarFetchById, sidebarUpsert, uploadAvatar } from '../../../redux/actions'
import { formConfig } from './formConfig'
import { StyledOrganizationProfile } from './styled'
import UploadFiles from '../../../components/UploadFiles'
import OrganizationAvatar from './OrganizationAvatar'
import OrganizationBanner from './OrganizationBanner'
import { slugify } from 'transliteration'

const FormBlockWrapper = ({ children }) => {
  return children
}

const OrganizationProfile = ({ theme }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('forms')

  const userRole = useMappedState(selectUserRole)
  const myOrganizationInitial = useMappedState(selectSidebarInitialData)
  const organizationId = useMappedState(selectUserOrganizationId)
  const system = useMappedState(selectAllSystemCollections)
  const sidebarUpsertInProgress = useMappedState(selectSidebarUpsertInProgress)

  const isOrganizationOwner = userRole === 'organization_owner'

  const width = useWindowWidth()

  const userpicSize = width < 1440 ? '85px' : '124px'

  useEffect(() => {
    dispatch(sidebarFetchById(organizationId, 'organizations'))
  }, [organizationId])

  const onFormSubmit = (valuesChanged) => {
    const requestBody = { ...object(valuesChanged) }

    Object.keys(requestBody).map((key) => {
      if (isObject(requestBody[key]) && requestBody[key].id) {
        requestBody[key] = requestBody[key].id
      }
    })

    if ('year_founded' in requestBody && requestBody.year_founded === '') {
      requestBody.year_founded = null
    }

    if (requestBody.description)
      requestBody.description = merge(myOrganizationInitial.description, requestBody.description)

    if (valuesChanged.name) {
      requestBody.slug = slugify(valuesChanged.name)
    }

    const dataToSend = {
      id: myOrganizationInitial.id,
      requestBody,
      type: 'organizations',
    }

    dispatch(sidebarUpsert(dataToSend))
  }

  const onAvatarSet = (newFile) => {
    if (!isObjectEmpty(newFile)) {
      dispatch(
        uploadAvatar(newFile[0], {
          entityId: myOrganizationInitial.id,
          entityType: 'organizations',
          fileType: 'image',
          fileGroup: 'logos',
        })
      )
    }
  }

  const onBannerSet = (newFile) => {
    if (!isObjectEmpty(newFile)) {
      dispatch(
        uploadAvatar(newFile[0], {
          entityId: myOrganizationInitial.id,
          entityType: 'organizations',
          fileType: 'image',
          fileGroup: 'banners',
        })
      )
    }
  }

  if (!isOrganizationOwner) {
    return <Navigate replace to="/404" />
  }
  const { type, name } = myOrganizationInitial || {}
  return (
    <div style={{ overflowY: 'auto' }}>
      <UploadFiles
        entityType="organizations"
        entityId={myOrganizationInitial?.id}
        fileGroup="banners"
        onFnSet={onBannerSet}
        isSimpleButton
        simpleButtonProps={{
          as: OrganizationBanner,
          src: myOrganizationInitial?.banner_url,
          photoId: myOrganizationInitial?.banner_photo_id,
          entityId: myOrganizationInitial?.id,
        }}
      />
      <StyledOrganizationProfile>
        <div className="infoOrganization">
          <div className="container">
            <UploadFiles
              entityType="organizations"
              entityId={myOrganizationInitial?.id}
              fileGroup="logos"
              onFnSet={onAvatarSet}
              isSimpleButton
              simpleButtonProps={{
                as: OrganizationAvatar,
                userpicSize,
                theme,
                name,
                src: myOrganizationInitial?.logo_url,
                photoId: myOrganizationInitial?.logo_photo_id,
                entityId: myOrganizationInitial?.id,
              }}
            />
            <div className="info">
              <Typography type="h2" margin="0 0 6px 0">
                {name ? name.charAt(0).toUpperCase() + name.slice(1) : ''}
              </Typography>
              <Typography type="body1" lineHeight={'16px'} fontWeight={500} color={theme.color.general.gray3}>
                {type ? type.charAt(0).toUpperCase() + type.slice(1) : ''}
              </Typography>
            </div>
          </div>
          <Button
            variant="bordered"
            text={t('viewProfile')}
            iconName="linkExternal"
            color={'rgba(23, 43, 77, 1)'}
          />
        </div>
        <Form
          optionsData={system}
          initialValues={myOrganizationInitial}
          formConfig={formConfig}
          validationRules={formConfig.validationRules}
          FormWrapper={FormBlockWrapper}
          expansionPanelProps={{
            disabled: false,
            withChevronInHeader: true,
          }}
          inProgress={sidebarUpsertInProgress}
          headerType="h4"
          onSubmit={onFormSubmit}
          buttonsAreSticky
          withActions
        />
      </StyledOrganizationProfile>
    </div>
  )
}

export default withTheme(OrganizationProfile)
