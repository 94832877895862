import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { v4 as uuidv4 } from 'uuid'
import merge from 'deepmerge'
import { withTheme } from 'styled-components'
import { useDispatch } from 'react-redux'

import { Button, FlexRow, Icon, Input, ScreenContext, Typography } from '@aidsupply/components'

import { useMappedState } from '../../../hooks'
import { sidebarUpsertTopics } from '../../../redux/actions'
import { selectSystemCollections } from '../../../redux/selectors'
import FormBlockTabs from '../../../components/Form/FormBlockTabs'
import { StyledFormButtons } from '../../../components/Form/styled'
import { StyledTopicsWrapper } from './styled'

const TabInputOption = ({ dataInitial, setFieldsChanged, tabKey, valueObject }) => {
  if (valueObject.state === 'deleted') return null

  const onInputChange = (e) => {
    const { value } = e.target

    setFieldsChanged((prev) => {
      const changedValue = prev[valueObject.id]
      const newValues = { ...prev }

      if (changedValue) {
        newValues[valueObject.id] = {
          ...changedValue,
          translations: {
            ...changedValue.translations,
            [tabKey]: value,
          },
          name: tabKey === 'en' ? value : prev[valueObject.id].name,
        }
      } else {
        newValues[valueObject.id] = {
          ...valueObject,
        }
      }
      return newValues
    })
  }

  return (
    <Input
      rightIconName="trashBin"
      iconRightProps={{
        strokeWidth: 1.5,
        height: 16,
        width: 16,
        onClick: () => {
          setFieldsChanged((prev) => {
            if (prev[valueObject.id] && !dataInitial[valueObject.id]) {
              delete prev[valueObject.id]
              return { ...prev }
            }
            return { ...prev, [valueObject.id]: { ...valueObject, state: 'deleted' } }
          })
        },
      }}
      label={valueObject.name}
      name={valueObject.name}
      required={tabKey === 'en'}
      error={tabKey === 'en' && !valueObject.translations[tabKey] ? 'required' : null}
      onChange={onInputChange}
      value={valueObject.translations[tabKey]}
      withBorder
    />
  )
}

const BlogTopicsRightPanel = ({ theme }) => {
  const { t } = useTranslation('forms')
  const dispatch = useDispatch()
  const { currentBreakpoint } = useContext(ScreenContext) || {}

  const dataInitial = useMappedState(selectSystemCollections('topics')) || {}

  const [fieldsChanged, setFieldsChanged] = React.useState({})
  const [newRow, setNewRow] = React.useState('')

  const onSave = () => {
    if (!Object.values(fieldsChanged).every((field) => field.translations.en)) {
      return
    }
    dispatch(sidebarUpsertTopics(fieldsChanged))
    setFieldsChanged({})
  }

  const onCancel = () => {
    setFieldsChanged({})
  }

  const onAddNewRow = () => {
    if (newRow) {
      let newId = `newId-${uuidv4()}`
      setFieldsChanged((prev) => ({
        ...prev,
        [newId]: { name: newRow, translations: { en: newRow }, id: newId },
      }))
      setNewRow('')
    }
  }

  const getTabContent = (tabKey) => {
    const mergedData = merge(dataInitial, fieldsChanged)
    return Object.values(mergedData).map((field) => (
      <TabInputOption
        key={field.id}
        dataInitial={dataInitial}
        setFieldsChanged={setFieldsChanged}
        valueObject={field}
        tabKey={tabKey}
      />
    ))
  }

  return (
    <StyledTopicsWrapper>
      <div className="stickyAtTheTop">
        <Typography component="div" margin="0 0 5px 0" text={t('systemNameInEnglish')} />
        <FlexRow alignItems="stretch" justifyContent="space-between" gap="10px" margin="0 0 10px 0">
          <Input onChange={(e) => setNewRow(e.target.value)} value={newRow} withBorder />
          <Button variant="bordered" onClick={() => onAddNewRow()}>
            <Icon height={10} width={10} name="checkRounded" fill={theme.color.general.dark} />
          </Button>
        </FlexRow>
      </div>

      <FormBlockTabs
        type="topics"
        blockKey="topics"
        getTabScreenCustom={getTabContent}
        defaultTabsValues={{
          topics: 'en',
        }}
        withTabsFields={Object.values(merge(dataInitial, fieldsChanged)).map((field) => field.name)}
        isLanguageTabs
      />

      {!!Object.keys(fieldsChanged).length && (
        <StyledFormButtons className="sticky" currentBreakpoint={currentBreakpoint}>
          <div className="stickyButtonsWrapper">
            <Button variant="bordered" onClick={() => onCancel()}>
              {t('Discard')}
            </Button>
            <Button fullWidth variant="primary" onClick={() => onSave()}>
              {t('Save')}
            </Button>
          </div>
        </StyledFormButtons>
      )}
    </StyledTopicsWrapper>
  )
}

export default withTheme(BlogTopicsRightPanel)
