import {
  SIGN_IN_ERROR,
  GET_INVITE_DETAILS_SUCCESS,
  GET_CURRENT_USER_SUCCESS,
  CONFIRM_EMAIL_SUCCESS,
  SIGN_UP_SUCCESS,
  GET_CURRENT_USER_ERROR,
  TOKEN_REFRESH_FAILURE,
  TOKEN_REFRESH_SUCCESS,
  SIGN_IN_SUCCESS,
  INITIALIZE_APP,
  SIGN_OUT,
  SIGN_IN,
  SIGN_UP,
  SIGN_UP_ERROR,
  REQUEST_RESET_PASSWORD,
  REQUEST_RESET_PASSWORD_SUCCESS,
  REQUEST_RESET_PASSWORD_ERROR,
} from '../constants'

const defaultState = {
  details: {},
  inviteDetails: {},
  token: '',
  error: false,
  inProgress: false,
  forceInitWs: false,
  isAuthenticated: null,
  // isAppInitialized: false,
  email_verified: undefined,
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case SIGN_IN:
    case SIGN_UP:
    case REQUEST_RESET_PASSWORD:
      return {
        ...state,
        inProgress: true,
        error: false,
      }
    case INITIALIZE_APP:
      return {
        ...state,
        inProgress: true,
        isAppInitialized: true,
      }
    case TOKEN_REFRESH_SUCCESS:
    case SIGN_IN_SUCCESS:
      return {
        ...state,
        token: action.token,
        // forceInitWs: true,
      }
    case SIGN_UP_SUCCESS:
      return {
        ...state,
        token: action.token,
        email_verified: false,
        inProgress: false,
      }
    case CONFIRM_EMAIL_SUCCESS:
      return {
        ...state,
        email_verified: true,
      }
    case REQUEST_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        inProgress: false,
      }
    case REQUEST_RESET_PASSWORD_ERROR:
      return {
        ...state,
        inProgress: false,
        error: true,
      }
    case GET_CURRENT_USER_SUCCESS:
      return {
        ...state,
        details: action.payload,
        isAuthenticated: true,
        inProgress: false,
      }
    case GET_INVITE_DETAILS_SUCCESS:
      return { ...state, inviteDetails: action.payload }
    case SIGN_UP_ERROR:
      return {
        ...state,
        inProgress: false,
        error: true,
      }
    case SIGN_IN_ERROR:
    case GET_CURRENT_USER_ERROR:
    case TOKEN_REFRESH_FAILURE:
      return { ...state, inProgress: false, error: true, isAppInitialized: true, isAuthenticated: false }
    case SIGN_OUT:
      return defaultState
    default:
      return state
  }
}
