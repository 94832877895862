import React from 'react'
import ReactTexty from 'react-texty'
import { useTranslation } from 'react-i18next'

import { StyledInquiryNumber, StyledInquiryStatusBar, StyledInquiryStatusBarItem } from './styled'

const MIN_OPACITY = 10

const InquiryNumberWithStatusBar = ({ cellData, percentages }) => {
  const opacityStep = (100 - MIN_OPACITY) / (percentages.length - 1)

  const getTooltip = () => {
    const tooltipStrings = percentages.map(({ tooltipTitle, percentage }) => {
      const validPercentage = isNaN(percentage) ? 0 : percentage
      return tooltipTitle ? `${tooltipTitle}: ${validPercentage.toFixed(2)}%` : ''
    })

    if (tooltipStrings.length === 2) {
      return tooltipStrings[0]
    }
    return tooltipStrings.join('; ')
  }

  return (
    <StyledInquiryNumber data-tooltip={getTooltip()}>
      {cellData}
      <StyledInquiryStatusBar>
        {percentages.map(({ percentage }, index) => {
          const validPercentage = isNaN(percentage) ? 0 : percentage
          return (
            <StyledInquiryStatusBarItem
              key={index}
              width={validPercentage}
              opacity={100 - opacityStep * index}
            />
          )
        })}
      </StyledInquiryStatusBar>
    </StyledInquiryNumber>
  )
}

export default InquiryNumberWithStatusBar
