import React from 'react'
import {
  InfoRowsBlock,
  Image,
  IMAGEKIT_PARAMS_CONFIG,
  Typography,
  Tag,
  NoImageAvailable,
} from '@aidsupply/components'
import ReactTexty from 'react-texty'
import { withTheme } from 'styled-components'

import { IMAGEKIT_URL } from '../../../constants'
import { DEFAULT_LANGUAGE } from '../../../locales'
import { useMappedState } from '../../../hooks'
import { selectAllSystemCollections } from '../../../redux/selectors'
import { StyledCard } from './styled'

const ItemsTableCard = ({ className, data, initialData, theme }) => {
  const { brand_id, sku, photo_url, translations, category_id, checkbox } = data

  const system = useMappedState(selectAllSystemCollections)
  const brandId = Object.keys(system.brands).find((key) => system.brands[key].id === initialData.brand_id)
  const brandPhoto = system.brands[brandId].photo_url

  return (
    <StyledCard>
      <Typography as={ReactTexty} text={translations} type="h4" />
      <div>
        {photo_url ? (
          <Image
            className="brandLogo"
            src={photo_url}
            height={124}
            width={100}
            maxWidth={100}
            imagekitParams={IMAGEKIT_PARAMS_CONFIG.crm.thumbnail}
            imagekitUrl={IMAGEKIT_URL}
            alt="photo item"
          />
        ) : (
          <NoImageAvailable height={124} width={100} />
        )}
        <InfoRowsBlock
          className="infoRowsBlock"
          data={[
            {
              brand: {
                customElement: (
                  <>
                    {brandPhoto ? (
                      <Image
                        className="brandLogo"
                        radius="25%"
                        src={brandPhoto}
                        height={26}
                        width={26}
                        imagekitParams={IMAGEKIT_PARAMS_CONFIG.components.cardTableItemLogo}
                        imagekitUrl={IMAGEKIT_URL}
                        alt="brand logo"
                      />
                    ) : null}
                    <Typography as={ReactTexty} type="body1">
                      {brand_id}
                    </Typography>
                  </>
                ),
                width: 'calc(100% - 25px)',
                hideBorder: true,
                padding: '7px 10px',
              },
              checkbox: {
                customElement: checkbox,
                width: '20px',
                hideBorder: true,
                padding: '10px 0',
              },
            },
            {
              model: {
                iconProps: {
                  name: 'squares',
                },
                customElement: sku,
                width: '100%',
                hideBorder: true,
                padding: '10px',
              },
            },
            {
              category: {
                iconProps: {
                  name: 'flowchart',
                },
                customElement: <Tag text={category_id} />,
                width: '100%',
                hideBorder: true,
                padding: '9px 10px',
              },
            },
          ]}
        />
      </div>
    </StyledCard>
  )
}

export default withTheme(ItemsTableCard)
