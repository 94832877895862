import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { withTheme } from 'styled-components'
import clsx from 'clsx'

import { Icon, ScreenContext, Table, EmptyTable, ExpansionPanel, Typography } from '@aidsupply/components'

import { StyledMultiSelectTable } from './styled'
import { StyledNavBar } from '../Table/styled'
import SearchForMultiSelectTable from './SearchForMultiSelectTable'
import { transformCharacteristicsFromObjectToArray } from '../../utils/forms'
import { getValuesWithAddedPhotosTab } from '../../utils'
import { getSelectImagesColumns, MULTI_SELECT_TABLE_CONFIG } from '../../config/multiSelectTable'
import { useMappedState } from '../../hooks'
import { selectAllSystemCollections } from '../../redux/selectors'

const MultiSelectTable = ({
  attributes,
  columns,
  customBlockValues,
  data,
  headerHeight: headerHeightProp,
  getPanelHeader,
  keyToUpdate,
  options,
  photos,
  textHeader,
  type,
  updateSelectValue,
  withTextHeader,
}) => {
  const { t, i18n } = useTranslation('table')
  const { currentBreakpoint, width, sm } = useContext(ScreenContext) || {}
  const system = useMappedState(selectAllSystemCollections)
  const tableTypeData = MULTI_SELECT_TABLE_CONFIG(type, i18n.language)

  const initialSelectOptions = options

  const [tableData, setTableData] = useState(data)

  const [itemRemoved, setItemRemoved] = useState(false)

  useEffect(() => {
    setTableData(data)
  }, [data])

  const headerHeight = typeof headerHeightProp === 'undefined' ? 48 : headerHeightProp
  const maxHeight = tableData?.length ? tableData.length * 50 + headerHeight : 45

  function onAddTabToItemPhotos(value, setFormValuesChanged) {
    const propsChosen = transformCharacteristicsFromObjectToArray(
      { [this.attributeSlug]: value },
      attributes,
      this.attributeSlug
    )

    if (propsChosen) {
      setFormValuesChanged((prev) => {
        return getValuesWithAddedPhotosTab(prev, propsChosen, { photos })
      })
    }
  }

  useEffect(() => {
    //supporting function for onItemRemove, deps array is correct here
    if (itemRemoved) {
      updateSelectValue(keyToUpdate || tableTypeData.keyToUpdate)(tableData)
      setItemRemoved(false)
    }
  }, [itemRemoved, keyToUpdate, tableData, tableTypeData.keyToUpdate, updateSelectValue])

  const onItemRemove = (id) => () => {
    setTableData((prev) => {
      return prev.filter((item) => item.id !== id)
    })
    setItemRemoved(true)
  }

  const removeItemCell = {
    key: 'remove',
    width: 40,
    cellRenderer: ({ rowData }) => (
      <Icon name="cross" wrapperColor="transparent" onClick={onItemRemove(rowData.id)} />
    ),
  }

  return (
    <div>
      <ExpansionPanel
        type="light"
        header={getPanelHeader && getPanelHeader(type)}
        size="small"
        initialOpened
        isHidden={withTextHeader}
        className={clsx('multiSelectTable')}
      >
        {withTextHeader && textHeader && (
          <Typography component="div" type="body1" text={textHeader} margin="0 0 7px 0" />
        )}
        <StyledMultiSelectTable maxTableHeight={maxHeight} noHeader={headerHeight === 0}>
          <StyledNavBar currentBreakpoint={currentBreakpoint}>
            <SearchForMultiSelectTable
              noOptionsMessage={t('noItems')}
              banners={type.startsWith('categories') && system.banners}
              options={initialSelectOptions}
              keyToUpdate={keyToUpdate}
              lng={i18n.language}
              onAddTabToItemPhotos={onAddTabToItemPhotos}
              setTableData={setTableData}
              tableData={tableData}
              tableTypeData={tableTypeData}
              type={type}
              updateSelectValue={updateSelectValue}
            />
          </StyledNavBar>
          <Table
            emptyText={t('noItems')}
            // paddingsAndBorders={(width < sm ? 20 : 40) + 2}
            headerRenderer={!tableData?.length ? <EmptyTable text={t('noItems')} /> : null}
            cellProps={{
              system,
              lng: i18n.language,
              custom_block_values: customBlockValues,
            }}
            data={tableData}
            columns={[removeItemCell, ...(columns || getSelectImagesColumns(i18n.language, t)[type])]}
            rowHeight={50}
            headerHeight={headerHeight}
            maxHeight={maxHeight}
          />
        </StyledMultiSelectTable>
      </ExpansionPanel>
    </div>
  )
}

export default withTheme(MultiSelectTable)
