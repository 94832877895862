import en from './en'
import ru from './ru'
import uk from './uk'
import de from './de'
// import pl from './pl'

export const resources = { en, ru, uk, de }

export const DEFAULT_LANGUAGE = 'uk'

export const LANGUAGES_FULL_NAME = {
  en: 'English',
  uk: 'Українська',
  ru: 'Русский',
  de: 'Deutsch',
  // pl: 'Polski',
}

export const SUPPORTED_LNGS = Object.keys(LANGUAGES_FULL_NAME)
