import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import Cookies from 'universal-cookie'

import { Button, Typography } from '@aidsupply/components'

import Form from '../../components/Form'
// import { selectResetPassMetaData, selectUserDetails } from '../../redux-saga/selectors'
import CustomPasswordChecklist from '../../components/IntroScreens/components/CustomPasswordChecklist'
import ProfilePicture from '../../components/ProfilePicture/ProfilePicture'
import { EMAIL, FULL_NAME, LANGUAGE } from '../../config/forms'
import { useMappedState } from '../../hooks'
import { useIsPasswordShown } from '../../hooks/useIsPasswordShown'
import { LANGUAGES_FULL_NAME } from '../../locales'
import { sidebarUpsertUser } from '../../redux/actions'
import { selectUserDetails } from '../../redux/selectors'
import { StyledUserProfile } from './styled'

const passwordsInitialValues = {
  password: '',
  new_password: '',
}

const FormBlockWrapper = ({ children }) => {
  return children
}

const UserProfile = ({ theme }) => {
  const dispatch = useDispatch()
  const {
    t,
    i18n,
    i18n: { language: currentLng },
  } = useTranslation('general')

  // const { inProgress, error } = useMappedState(selectResetPassMetaData)
  // const prevInProgress = usePrevious(inProgress)

  const formDataInitial = useMappedState(selectUserDetails)

  const [password, setPassword] = useState('')
  const [new_password, setNewPassword] = useState('')
  const [isSubmitPressed, setIsSubmitPressed] = useState(false)
  const [isPasswordValid, setIsPasswordValid] = useState(undefined)
  const { type, iconRightProps } = useIsPasswordShown()

  // useEffect(() => {
  //   if (prevInProgress && !inProgress && !error) {
  //     setNewPassword('')
  //     setPassword('')
  //   }
  // }, [inProgress, prevInProgress, error])

  const onFormSubmit = (valuesChanged) => {
    dispatch(
      sidebarUpsertUser({
        id: formDataInitial.id,
        type: 'users',
        requestBody: valuesChanged,
      })
    )
  }

  const isPassButtonDisabled = !(password && new_password && password !== new_password && isPasswordValid)
  const onPasswordChange = (credentials) => {
    // dispatch(userResetPassword(true, { ...credentials, email: initialValues.email }, navigate))
  }

  return (
    <>
      <StyledUserProfile>
        <div className="expansionPanel">
          <Typography type="h4" text={t('profilePicture')} className="panelHeader" />
          <ProfilePicture />
        </div>
        <Form
          initialValues={formDataInitial}
          validationRules={{
            full_name: ['required'],
            phone: ['phone'],
          }}
          formConfig={{
            fields: {
              general: [FULL_NAME, { ...EMAIL, disabled: true }],
              languages: [
                {
                  ...LANGUAGE,
                  key: 'lang',
                  label: 'systemLanguage',
                  noTranslation: true,
                  onSelectValueChange: (value) => {
                    i18n.changeLanguage(Object.values(value))
                    const cookies = new Cookies()
                    cookies.set('lng', value.id, { path: '/' })
                  },
                  defaultValue: { id: currentLng, label: LANGUAGES_FULL_NAME[currentLng] },
                },
              ],
            },
          }}
          onSubmit={onFormSubmit}
          FormWrapper={FormBlockWrapper}
          expansionPanelProps={{
            disabled: true,
            withChevronInHeader: false,
          }}
          isSubmitOnBlur
          withChevronInHeader={false}
          headerType="h4"
        />

        <Form
          // serverError={error}
          // inProgress={inProgress}
          className="rowsForm passForm"
          initialValues={passwordsInitialValues}
          validationRules={{
            password: [
              {
                type: 'requiredIfFieldsNotEmpty',
                fields: ['new_password'],
                values: { new_password },
              },
            ],
            newPassword: [
              {
                type: 'requiredIfFieldsNotEmpty',
                fields: ['password'],
                values: { password },
              },
              { type: 'password', isPasswordValid: !new_password || isPasswordValid },
            ],
          }}
          formConfig={{
            fields: {
              changePassword: [
                {
                  key: 'password',
                  label: 'currentPassword',
                  variant: 'secondary',
                  type,
                  placeholder: 'password',
                  labelType: 'top',
                  // iconLeftProps: { name: 'passwordLocked' },
                  className: 'password currPass',
                  onInputValueChange: (value) => setPassword(value),
                },
                {
                  key: 'new_password',
                  label: 'newPassword',
                  variant: 'secondary',
                  type,
                  placeholder: 'password',
                  labelType: 'top',
                  // iconLeftProps: { name: 'passwordLocked' },
                  // iconRightProps,
                  className: 'password newPass',
                  onInputValueChange: (value) => setNewPassword(value),
                },
              ],
            },
          }}
          onSubmit={onPasswordChange}
          FormWrapper={FormBlockWrapper}
          expansionPanelProps={{
            disabled: true,
            withChevronInHeader: false,
          }}
          headerType="h4"
        >
          <Button
            variant="bordered"
            type="submit"
            className="submitFormButton"
            onClick={() => setIsSubmitPressed(true)}
            disabled={isPassButtonDisabled}
            uppercase={false}
          >
            {t('update')}
          </Button>
          {new_password && (
            <CustomPasswordChecklist
              password={new_password}
              setIsPasswordValid={setIsPasswordValid}
              className={isSubmitPressed && new_password && !isPasswordValid ? 'passError' : ''}
            />
          )}
        </Form>
      </StyledUserProfile>
    </>
  )
}

export default UserProfile
