import React from 'react'

import { Typography, Icon } from '@aidsupply/components'
import { withTheme } from 'styled-components'
import { StyledContainerHeader, StyledLink, StyledTemplate, StyledTypeFile, StyledContainer } from './styled'
import { FILE_TYPES, FILE_TYPES_ICONS } from '../../constants'

const extensionToMimeType = (() => {
  return Object.keys(FILE_TYPES.files).reduce((acc, key) => {
    FILE_TYPES.files[key].forEach((extension) => {
      acc[extension] = key
    })
    return acc
  }, {})
})()

const FilePanel = ({ extension, size, theme: { color }, title, href, children }) => {
  const getIcon = (extension) => {
    const formatExtension = extension.startsWith('.') ? extension : `.${extension}`
    const mimeType = extensionToMimeType[formatExtension]
    return FILE_TYPES_ICONS[mimeType]
  }

  const iconFile = getIcon(extension)

  const formatExtension = (extension) => extension.toUpperCase().replace(/^\./, '')

  const renderFileInfo = (formattedExt, size) => (
    <StyledTemplate>
      <Icon name={iconFile} height={22} width={18} />
      <StyledTypeFile className="template2">
        <Typography type="body1" fontWeight={600}>
          {title}
        </Typography>
        {(formattedExt || size) && (
          <div>
            <Typography type="body1" color={color.general.gray3}>
              {formattedExt}
            </Typography>
            {formattedExt && size && <span className="dot">•</span>}
            {size && (
              <Typography type="body1" color={color.general.gray3}>
                {size}
              </Typography>
            )}
          </div>
        )}
      </StyledTypeFile>
    </StyledTemplate>
  )

  return href ? (
    <StyledLink href={href} target="_blank">
      {renderFileInfo(formatExtension(extension), size)}
      {children}
    </StyledLink>
  ) : (
    <StyledContainer>
      {renderFileInfo(formatExtension(extension), size)}
      {children}
    </StyledContainer>
  )
}

export default withTheme(FilePanel)
