import React, { useEffect } from 'react'
import { Cookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { withTheme } from 'styled-components'

// import Home from './containers/Home'
import PrivateRoute from './PrivateRoute'

import Attributes from './containers/System/Attributes'
import AttributesRightPanel from './containers/System/Attributes/AttributesRightPanel'
import BlogPosts from './containers/Platform/BlogPosts/BlogPosts'
import BlogPostsRightPanel from './containers/Platform/BlogPosts/BlogPostsRightPanel'
import Brands from './containers/System/Brands'
import BrandsRightPanel from './containers/System/Brands/BrandsRightPanel'
import Campaigns from './containers/Platform/Campaigns/Campaigns'
import CampaignsRightPanel from './containers/Platform/Campaigns/CampaignsRightPanel'
import Categories from './containers/System/Categories'
import CategoriesRightPanel from './containers/System/Categories/CategoriesRightPanel'
import CommentsRightPanel from './containers/Documents/Inquiries/CommentsRightPanel'
import Dashboard from './containers/Dashboard'
import Demands from './containers/Demands'
import DemandsRightPanel from './containers/Demands/DemandsRightPanel'
import Enumerations from './containers/System/Enumerations'
import EnumerationsRightPanel from './containers/System/Enumerations/EnumerationsRightPanel'
import FAQs from './containers/Platform/FAQs/FAQs'
import FAQsRightPanel from './containers/Platform/FAQs/FAQsRightPanel'
import Inquiries from './containers/Documents/Inquiries'
import InquiriesRightPanel from './containers/Documents/Inquiries/InquiriesRightPanel'
import Inventories from './containers/Inventory/Inventories/Inventories'
import InventoriesRightPanel from './containers/Inventory/Inventories/InventoriesRightPanel'
import Invoices from './containers/Documents/Invoices'
import InvoicesRightPanel from './containers/Documents/Invoices/InvoicesRightPanel'
import Items from './containers/System/Items'
import ItemsRightPanel from './containers/System/Items/ItemsRightPanel'
import ItemsUploadRightPanel from './containers/System/Items/ItemsUploadRightPanel'
import Orders from './containers/Documents/Orders'
import OrdersRightPanel from './containers/Documents/Orders/OrdersRightPanel'
import OrganizationProfile from './containers/Admin/OrganizationProfile'
import Organizations from './containers/Admin/Organizations'
import OrganizationsRightPanel from './containers/Admin/Organizations/OrganizationsRightPanel'
import Pages from './containers/Platform/Pages'
import PagesRightPanel from './containers/Platform/Pages/PagesRightPanel'
import Reports from './containers/Platform/Reports/Reports'
import ReportsRightPanel from './containers/Platform/Reports/ReportsRightPanel'
import Shipments from './containers/Documents/Shipments'
import ShipmentsRightPanel from './containers/Documents/Shipments/ShipmentsRightPanel'
import Stocks from './containers/Inventory/Stocks'
import StocksRightPanel from './containers/Inventory/Stocks/StocksRightPanel'
import Units from './containers/System/Units'
import UnitsRightPanel from './containers/System/Units/UnitsRightPanel'
import Users from './containers/Admin/Users'
import UserProfile from './containers/UserProfile'
import UsersRightPanel from './containers/Admin/Users/UsersRightPanel'
import Warehouses from './containers/Inventory/Warehouses'
import WarehousesRightPanel from './containers/Inventory/Warehouses/WarehousesRightPanel'

import MainWrapper from './containers/Layout'
import Login from './containers/Login'
import NotFound from './containers/NotFound'
import PasswordRecovery from './containers/PasswordRecovery'
import PasswordRecoverySent from './containers/PasswordRecoverySent'
import RequestSent from './containers/RequestSent'
import RightPanel from './components/RightPanel'
import SignUp from './containers/SignUp'
import { useMappedState } from './hooks'
import { selectUserState } from './redux/selectors'

const App = () => {
  const { i18n } = useTranslation()
  const cookies = new Cookies()

  useEffect(() => {
    const lngCookies = cookies.get('lng')
    if (lngCookies !== undefined) {
      i18n.changeLanguage(lngCookies)
    }
  }, [i18n])

  // we need this after user logged in
  const user = useMappedState(selectUserState)

  const privateRouteProps = {
    isAuthenticated: user.isAuthenticated,
    userInfo: user?.details,
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/password-recovery" element={<PasswordRecovery />} />
        <Route path="/password-request-sent" element={<PasswordRecoverySent />} />
        <Route path="/confirm-email" element={<RequestSent />} />

        <Route
          path="/"
          element={
            <PrivateRoute>
              <MainWrapper />
            </PrivateRoute>
          }
        >
          <Route index element={<Dashboard />} />
          <Route path="/inquiry-items" element={<Demands />}>
            <Route path=":rightPanelId" element={<DemandsRightPanel />} />
          </Route>
          {/*<Route path="/products" element={<Products />}>*/}
          {/*  <Route path=":rightPanelId" element={<ProductsForm />} />*/}
          {/*</Route>*/}

          <Route path="/documents">
            <Route path="inquiries" element={<Inquiries />}>
              <Route path=":rightPanelId" element={<InquiriesRightPanel />} />
              <Route path=":rightPanelId/comments" element={<CommentsRightPanel />} />
            </Route>
            <Route path="orders" element={<Orders />}>
              <Route path=":rightPanelId" element={<OrdersRightPanel />} />
              <Route path=":rightPanelId/comments" element={<CommentsRightPanel />} />
            </Route>
            <Route path="shipments" element={<Shipments />}>
              <Route path=":rightPanelId" element={<ShipmentsRightPanel />} />
              <Route path=":rightPanelId/comments" element={<CommentsRightPanel />} />
            </Route>
            <Route path="invoices" element={<Invoices />}>
              <Route path=":rightPanelId" element={<InvoicesRightPanel />} />
              <Route path=":rightPanelId/comments" element={<CommentsRightPanel />} />
            </Route>
          </Route>

          <Route path="/inventory">
            <Route path="warehouses" element={<Warehouses />}>
              <Route path=":rightPanelId" element={<WarehousesRightPanel />} />
            </Route>
            <Route path="stock-items" element={<Stocks />}>
              <Route path=":rightPanelId" element={<StocksRightPanel />} />
            </Route>
            <Route path="inventories" element={<Inventories />}>
              <Route path=":rightPanelId" element={<InventoriesRightPanel />} />
            </Route>
          </Route>

          <Route path="/system">
            <Route path="brands" element={<Brands />}>
              <Route path=":rightPanelId" element={<BrandsRightPanel />} />
            </Route>
            <Route path="items" element={<Items />}>
              <Route path=":rightPanelId" element={<ItemsRightPanel />} />
              <Route path=":rightPanelId/upload" element={<ItemsUploadRightPanel />} />
            </Route>
            <Route path="categories" element={<Categories />}>
              <Route path=":rightPanelId" element={<CategoriesRightPanel />} />
            </Route>
            <Route path="attributes" element={<Attributes />}>
              <Route path=":rightPanelId" element={<AttributesRightPanel />} />
            </Route>
            <Route path="enumerations" element={<Enumerations />}>
              <Route path=":rightPanelId" element={<EnumerationsRightPanel />} />
            </Route>
            <Route path="units" element={<Units />}>
              <Route path=":rightPanelId" element={<UnitsRightPanel />} />
            </Route>
          </Route>

          <Route path="/admin">
            <Route path="users" element={<Users />}>
              <Route path=":rightPanelId" element={<UsersRightPanel />} />
            </Route>
            <Route path="organizations" element={<Organizations />}>
              <Route path=":rightPanelId" element={<OrganizationsRightPanel />} />
            </Route>
            <Route path="organization-profile" element={<OrganizationProfile />} />
          </Route>

          <Route path="/platform">
            <Route path="posts" element={<BlogPosts />}>
              <Route path=":rightPanelId" element={<BlogPostsRightPanel />} />
            </Route>
            <Route path="faqs" element={<FAQs />}>
              <Route path=":rightPanelId" element={<FAQsRightPanel />} />
            </Route>
            <Route path="campaigns" element={<Campaigns />}>
              <Route path=":rightPanelId" element={<CampaignsRightPanel />} />
            </Route>
            <Route path="reports" element={<Reports />}>
              <Route path=":rightPanelId" element={<ReportsRightPanel />} />
            </Route>
            <Route path="pages" element={<Pages />}>
              <Route path=":rightPanelId" element={<PagesRightPanel />} />
            </Route>
          </Route>

          <Route path="/settings/profile" element={<UserProfile />} />
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  )
}

export default withTheme(App)
