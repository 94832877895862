import {
  INPUT_FILES_ADD,
  INPUT_FILE_REMOVE,
  INPUT_FILES_CLEAR,
  FILE_UPLOAD,
  FILES_UPLOAD_SUCCESS,
  SIGN_OUT_SUCCESS,
  FILE_UPLOAD_FAILURE,
} from '../constants'

const defaultState = {
  errors: {},
  errorLatest: null,
  files: {},
  inProgress: false,
  filesInProgress: {},
  filesType: '', // photos | files
}

const setNewFiles = (oldFiles, newFiles) => {
  const filteredFiles = Object.keys(newFiles).reduce(
    (acc, fileGroup) => ({
      ...acc,
      [fileGroup]:
        oldFiles[fileGroup]?.filter(
          (file) => !newFiles[fileGroup].some((newFile) => newFile.filePreviewId === file.preview)
        ) || [],
    }),
    {}
  )

  return {
    ...oldFiles,
    ...filteredFiles,
  }
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case INPUT_FILES_ADD:
      return {
        ...state,
        files: {
          ...state.files,
          [action.fileGroup]: [...(state.files[action.fileGroup] || []), ...action.files],
        },
        filesType: action.filesType,
      }
    case INPUT_FILE_REMOVE: {
      const fileGroup = action.fileGroup

      return {
        ...state,
        files: {
          ...state.files,
          [fileGroup]: state.files[fileGroup].filter((file) => file.preview !== action.filePreviewId),
        },
        filesInProgress: {
          ...state.filesInProgress,
          [fileGroup]: state.filesInProgress[fileGroup]?.filter((fileId) => action.filePreviewId !== fileId),
        },
        errors: {
          ...state.errors,
          [fileGroup]: state.errors[fileGroup]?.filter((errorFileId) => action.filePreviewId !== errorFileId),
        },
      }
    }
    case FILE_UPLOAD:
      return {
        ...state,
        inProgress: true,
        filesInProgress: {
          ...state.filesInProgress,
          [action.params.fileGroup]: [
            ...(state.filesInProgress[action.params.fileGroup] || []),
            action.file.preview,
          ],
        },
      }
    case FILES_UPLOAD_SUCCESS:
      return {
        ...state,
        inProgress: false,
        files: setNewFiles(state.files, action.files),
        filesInProgress: setNewFiles(state.filesInProgress, action.files),
        errors: setNewFiles(state.errors, action.files),
      }
    case FILE_UPLOAD_FAILURE:
      return {
        ...state,
        inProgress: false,
        errors: {
          ...state.errors,
          [action.fileGroup]: [...(state.errors[action.fileGroup] || []), action.filePreviewId],
        },
        filesInProgress: {
          ...state.filesInProgress,
          [action.fileGroup]: state.filesInProgress[action.fileGroup]?.filter(
            (fileId) => action.filePreviewId !== fileId
          ),
        },
      }
    case INPUT_FILES_CLEAR:
      return defaultState
    case SIGN_OUT_SUCCESS:
      return defaultState
    default:
      return state
  }
}
