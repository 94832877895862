import styled from 'styled-components'

export const StyledLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 10px;

  .logo {
    flex-shrink: 0;
  }

  .textLogo {
    margin-left: 5px;
  }

  .link a {
    display: flex;
    align-items: center;
  }
`
