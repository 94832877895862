import React, { useState, useEffect } from 'react'
import ReactTexty from 'react-texty'
import { Outlet, useLocation } from 'react-router-dom'

import { useTranslation } from 'react-i18next'
import { InfoRowsBlock, Typography } from '@aidsupply/components'
import { useMappedState } from '../../../hooks'
import {
  selectAllSystemCollections,
  selectUserRole,
  selectSidebarInitialData,
} from '../../../redux/selectors'

import GridLayout from '../../Layout/GridLayout'
import TableBlock from '../../../components/Table/TableBlock'
import InventoriesTableCard from './InventoriesTableCard'

const Inventories = () => {
  const { t } = useTranslation('forms')
  const { pathname } = useLocation()

  const inventory = useMappedState(selectSidebarInitialData)
  const userRole = useMappedState(selectUserRole)

  const isOrganizationVisible = userRole === 'administrator' || userRole === 'system'
  let title = pathname.includes('new') ? t('create') : t('inventoryDetails')

  return (
    <GridLayout
      RightPanelComponent={
        <>
          {title === t('inventoryDetails') ? <InventoriesTableCard data={inventory} /> : null}
          <Outlet context={{}} />
        </>
      }
      rightPanelProps={{ title }}
    >
      <TableBlock
        isMainTable
        TableCardContent={InventoriesTableCard}
        tableCardHeight={isOrganizationVisible ? 200 : 163}
      />
    </GridLayout>
  )
}

export default Inventories
