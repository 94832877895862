export default {
  errorPage: {
    goHome: 'Zur Startseite',
    letUsKnow: 'Lass uns wissen',
    needHelp: 'Brauchen Sie Hilfe?',
    somethingsMissing: 'Hoppla! Etwas fehlt',
  },
  footer: {
    allRightsReserved: 'Alle Rechte vorbehalten',
    Feedback: 'Feedback',
  },
  forms: {
    aboutSection: 'Abschnitt  "Über uns"',
    account: '',
    accountNumber: '',
    address: 'Adresse',
    admin: 'Admin',
    amount: 'Menge',
    answer: 'Antwort',
    assets: 'Vermögenswerte',
    attributes: 'Eigenschaften',
    author: 'Autor',
    backgroundColor: 'Hintergrundfarbe',
    backgroundIsDark: 'Hintergrund ist dunkel, Text ist hell',
    bank: '',
    bannerCategory: 'Kategorie (nur auswählen, wenn das Banner für eine Kategorie ist)',
    banners: 'Banner',
    bestsellers: 'Bestsellers',
    brandDescription: 'Markenbeschreibung',
    brandPageHeaderPhrase: 'Header-Phrase der Markenseite',
    brands: 'Marken',
    brandSlogan: 'Markenslogan',
    brandSloganAuthor: 'Markenslogan Autor',
    carriers: 'Spediteur',
    cart: 'Einkaufswagen',
    categories: 'Kategorien',
    categoriesBig: 'Kategorien (große Kacheln)',
    categoriesRecommended: 'Empfohlene Kategorien (klein)',
    characteristics: 'Eigenschaften',
    city: 'Stadt',
    color: 'Farbe',
    colors: 'Farben',
    comments: 'Kommentare',
    components: 'Komponenten',
    content: 'Inhalt',
    counterparties: '',
    cover: 'Abdeckung',
    coverVideo: 'Abdeckungsvideo',
    create: 'Erstellen',
    currencies: 'Währungen',
    custom: 'Brauch',
    customerFullName: 'Vollständiger Name des Kunden',
    dark: 'Dunkel',
    darker: 'Dunkler',
    darkest: 'Am dunkelsten',
    dateModified: 'Datum geändert',
    de: 'Deutsch',
    defaultLanguage: 'Standardsprache',
    deliveryOptions: '',
    description: 'Beschreibung',
    dimensions: 'Maße',
    district: 'Bezirk',
    document: '',
    dropOrSelect: 'Dateien aus dem Speicher löschen oder auswählen',
    edit: 'Bearbeiten',
    en: 'Englisch',
    error: 'Fehler',
    faqSection: 'FAQ-Bereich',
    favicon: 'Favicon',
    featuredProducts: 'Ausgewählte Produkte',
    feedbackSection: 'Feedback-Bereich',
    fillGeneralAndSaveBeforeUploadingPhotos:
      'Bitte füllen Sie alle erforderlichen Felder aus und klicken Sie auf die Schaltfläche Speichern, bevor Sie die Fotos hochladen',
    firstPageImageIsBackground: 'Das Bild der ersten Seite ist der Hintergrund',
    firstPageText: 'Text der ersten Seite',
    firstPageWithOverlay: 'Erste Seite mit einer Auflage',
    firstScreen: 'Erster Bildschirm',
    firstScreenMobile: 'Mobiler erster Bildschirm',
    font: 'Schriftart',
    from: '',
    general: 'Allgemein',
    generateModelNumber: 'Stattdessen Nummer generieren',
    generateNewOrganization: '',
    gray1: 'Grau 1',
    gray2: 'Grau 2',
    gray3: 'Grau 3',
    gray4: 'Grau 4',
    gray5: 'Grau 5',
    gray6: 'Grau 6',
    gray7: 'Grau 7',
    header: '',
    history: 'Geschichte',
    id: 'Indonesisch',
    info: 'Information',
    introSection: 'Einführungsabschnitt',
    isCharacteristic: 'Ist ein Merkmal',
    isComingSoon: 'Kommt bald Seite',
    isCrm: '',
    isPhotoGroup: 'Ist Fotogruppe',
    isUsedForInquiries: '',
    items: 'Artikel',
    key: 'Schlüssel',
    keywords: 'Schlüsselwörter',
    label: 'Name',
    languages: 'Sprachen',
    light: 'Hell',
    lighter: 'Heller',
    lightest: 'Am hellsten',
    logos: 'Logos',
    main: 'Haupt',
    media: 'Medien',
    merchants: 'Händler',
    miniLogos: 'Mini Logos',
    mobile: 'Für Handy',
    modifications: 'Modifikationen',
    name: 'Name',
    inquiry_items: '',
    new: 'Neu',
    noOptionsFound: 'Keine Optionen gefunden',
    optionIconsUploadIsAllowed: '',
    orderInfo: 'Bestellinfo',
    overlay: 'Überlagerung',
    page404: 'Seite 404',
    pageContent: '',
    password: 'Passwort',
    phoneNumber: 'Telefonnummer',
    photos: 'Fotos',
    pickup: 'Selbstabholung',
    pickupAddress: 'Abholadresse',
    pl: 'Polnisch',
    platformConstructor: 'Plattform-Konstrukteur',
    platformIsComingSoon: 'Die Plattform kommt bald',
    primary: 'Primär',
    products: 'Produkte',
    productsDescription: 'Produktbeschreibung',
    productsTitle: 'Produkttitel',
    profilePicture: 'Profilbild',
    properties: 'Eigenschaften',
    ru: 'Russisch',
    scrollDownForModifications: 'Klicken Sie hier, um Artikeländerungen mit Codes unten anzuzeigen',
    search: 'Suche',
    secondary: 'Sekundär',
    secondPageText: 'Text der zweiten Seite',
    secondScreen: 'Zweiter Bildschirm',
    seo: 'SEO-Optimierung',
    sharingImage: 'Bild für Preview',
    shipmentAddress: '',
    shippingDetails: '',
    shortUrl: 'Kurze URL',
    showMore: 'Zeig mehr',
    socialNetworks: 'Soziale Netzwerke',
    sorting: 'Sortierung',
    square: 'Quadrat',
    state: 'Zustand',
    status: 'Status',
    success: 'Erfolg',
    systemNameInEnglish: '',
    team: 'das Team',
    teamDescription: 'Team Beschreibung',
    teamSlogan: 'Team-Slogan',
    textForEveryItem: 'Text für jeden Artikel',
    texts: 'Texte',
    title: 'Titel',
    tokens: 'Token',
    translations: 'Übersetzungen',
    types: 'Typen',
    uk: 'Ukrainisch',
    uploadFailed: 'Hochladen fehlgeschlagen',
    values: 'Werte',
    videoPreviewImage: 'Video-Vorschaubild',
    videos: 'Videos',
    videoSection: 'Videobereich',
    videoUrl: 'Video URL',
    warning: 'Warnung',
    website: 'Webseite',
  },
  general: {
    isIconsUploadAllowed: '',
    fileUpload: 'Datei hochladen',
    pageNotFound: 'Die Seite ist nicht gefunden',
    template: 'Vorlage',
  },
  header: {
    Profile: 'Profilbild',
    quickMenu: 'Schnellmenü',
    searchBar: 'Suchleiste',
    Settings: 'Einstellungen',
  },
  loadingOrNoData: {
    addFirstItem: 'Addieren {{itemType}}',
    attribute: 'Attribut',
    banner: 'Banner',
    brand: 'Marke',
    carrier: 'Träger',
    category: 'Kategorie',
    client: 'Kunde',
    contract: '',
    counterparty: '',
    enumeration: 'Aufzählung',
    FAQ: 'Frage',
    inquiry: '',
    invoice: '',
    item: 'Artikel',
    loading: 'Wird geladen...',
    merchant: 'Händler',
    noDataFound: 'Keine Daten gefunden',
    order: 'Bestellung',
    page: 'Seite',
    platform: 'Plattform',
    serverError: '500 Interner Serverfehler',
    shipment: '',
    warehouse: 'Lager',
    'stock-item': 'Lagerware',
    unit: 'Einheit',
    user: 'Benutzer',
  },
  menu: {
    admin: 'Admin',
    attributes: 'Attribute',
    banners: 'Banner',
    brands: 'Marken',
    carriers: 'Träger',
    categories: 'Kategorien',
    clients: 'Kunden',
    contracts: '',
    counterparties: '',
    dashboard: 'Dashboard',
    enumerations: 'Aufzählungen',
    Help: 'Hilfe',
    inquiries: '',
    invoices: '',
    items: 'Artikeln',
    Logout: 'Ausloggen',
    merchant: 'Händler',
    merchants: 'Händler',
    inquiry_items: '',
    new: 'neu',
    options: 'Optionen',
    orders: 'Bestellungen',
    pages: 'Seiten',
    platforms: 'Plattformen',
    products: '',
    Search: 'Suche',
    Settings: 'Einstelungen',
    shipments: '',
    'stock-items': 'Artikel auf Lager',
    warehouses: 'Lager',
    system: 'System',
    units: 'Messeinheiten',
    users: '(die) Benutzer',
  },
  notifications: {
    attributes: '',
    banners: '',
    brands: '',
    bulkStatusesUpdated: '{{count}} {{type}} war {{status}}',
    carriers: '',
    categories: '',
    clearNotifications: '',
    clients: '',
    contracts: '',
    counterparties: '',
    created: 'erstellt',
    enumerations: '',
    faqs: '',
    inquiries: '',
    invoices: '',
    items: '',
    merchants: '',
    noNotifications: '',
    Notifications: 'Benachrichtigungen',
    orders: 'Bestellung #{{id}} war {{status}}',
    ordersBulk: 'Bestellungen',
    pages: '',
    platforms: '',
    shipments: '',
    'stock-items': 'Lagerartikel war {{status}}',
    'stock-itemsBulk': 'Artikel auf Lager',
    warehouses: '',
    units: '',
    updated: 'geändert',
    uploadItems: '',
    users: '',
  },
  orderButtons: {
    createInvoice: '',
    createShipment: '',
  },
  popupAlerts: {
    dataSuccessfullySaved: 'Daten wurden erfolgreich gespeichert!',
    errorInSavingData: 'Beim Speichern der Daten ist ein Fehler aufgetreten',
    fillModsError: 'Beim Ausfüllen von Produktänderungen ist ein Fehler aufgetreten',
    IDisNotCorrect: 'ID in URL ist nicht korrekt',
    loginError: 'Benutzername oder Passwort falsch',
    loginSocialError: 'Unerwarteter Fehler aufgetreten',
    userNotActive: 'Das Benutzerkonto ist nicht aktiv. Bitte wenden Sie sich an den Administrator',
  },
  productsForm: {
    address: '',
    basketEmpty: '',
    cart: '',
    city: '',
    contacts: '',
    country: '',
    name: '',
    outOfStock: '',
    phone: '',
    preview: '',
    submitOrder: '',
    summary: '',
    total: '',
  },
  signIn: {
    alreadyHaveAccount: 'Haben Sie bereits ein Konto?',
    createAccount: 'Erstelle deinen Account!',
    createAccountAndUse: 'Erstellen Sie Ihr Konto und verwenden Sie es',
    dontHaveAccount: 'Haben Sie kein Konto?',
    firstName: 'Vorname',
    forgotPassword: 'Haben Sie Ihr Passwort vergessen?',
    lastName: 'Nachname',
    orLoginWith: 'Oder melden Sie sich mit an',
    registerWith: 'Registrieren mit',
    rememberMe: 'Erinnere dich an mich',
    signIn: 'Einloggen',
    signInToContinue: 'Melden Sie sich an, um fortzufahren',
    signUp: 'Anmeldung',
    termsAgree: 'Ich akzeptiere die Allgemeinen Geschäftsbedingungen',
  },
  table: {
    actions: 'Aktionen',
    activity: 'Aktivität',
    availability: 'Verfügbarkeit',
    banner: 'Banner',
    block: 'Sperren',
    brand: 'Marke',
    carrier: 'Träger',
    categories: '',
    category: 'Kategorie',
    changeStateTo: '',
    changeStatusTo: '',
    characteristics: 'Eigenschaften',
    clearFilters: 'Filter löschen',
    client: 'Kunde',
    code: 'Code',
    color: 'Farbe',
    copy: '',
    counterparty: '',
    country: 'Land',
    created: 'Erstellt',
    currency: 'Währung',
    customer: 'Kunde',
    date: 'Datum',
    dateCreated: 'Datum der Erstellung',
    delete: 'Löschen',
    deleted: 'Gelöscht',
    discount: 'Rabatt, %',
    drafted: 'Entwurf',
    email: 'E-mail',
    enumeration: 'Aufzählung',
    filters: 'Filter',
    firstName: 'Vorname',
    industries: 'Branchen',
    industry: 'Branchen',
    inquiryNumber: '',
    invoiceNumber: '',
    item: 'Artikel',
    items: 'Artikeln',
    label: 'Übersetzungen',
    language: 'Sprache',
    lastActivity: 'Letzte Aktivität',
    lastLoggedIn: 'zuletzt gesehen',
    lastName: 'Nachname',
    linkToPage: 'Link zur Seite',
    magnitude: 'Größe',
    merchant: 'Händler',
    mobile: 'Telefonnummer',
    model: 'Modell',
    name: 'Name',
    noItems: 'Keine Elemente gefunden',
    options: 'Optionen',
    order: 'Bestellung',
    orderNumber: 'Bestellung #',
    outOfStock: '',
    page: 'Seite',
    parentCategory: 'Top-Kategorie',
    pcs: 'St.',
    platform: 'Plattform',
    platforms: 'Plattformen',
    posted: 'Veröffentlicht',
    price: 'Preis',
    price_retail: 'Preis Einzelhandel',
    price_sale: 'Preis ohne Rabatt',
    provider: 'Anbieter',
    quantity: 'Menge',
    question: 'Frage',
    rank: 'Rang',
    ratio: 'Koeffizient',
    region: '',
    role: 'Rolle',
    roles: 'Rollen',
    saveModifications: 'Änderungen speichern',
    shipmentNumber: '',
    size: 'Größe',
    state: 'Zustand',
    status: 'Status',
    warehouse: 'Lager',
    'stock-item': 'Lagerware',
    sum: 'Summe',
    summary: 'Zusammenfassung',
    systemName: 'Systemname',
    tags: 'Stichworte',
    title: 'Titel',
    total: 'Gesamt',
    translation: 'Übersetzungen',
    type: 'Typ',
    unit: 'Einheit',
    url: 'URL',
  },
  validation: {
    defaultTranslationRequired: 'Die Standardübersetzung darf nicht leer sein',
    email: 'Falsches E-Mail-Format',
    modelAlreadyExists: 'Ein solches Modell existiert bereits',
    nameAlreadyExists: 'Dieser Name existiert bereits',
    required: 'Das Feld darf nicht leer sein',
    requiredIfFieldsEmpty: 'Füllen Sie eines dieser Felder aus: {{fields}}',
    urlAlreadyExists: 'Diese URL existiert bereits',
  },
}
