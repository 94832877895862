import { all, call, put, select, takeLatest } from 'redux-saga/effects'

import { convertArrayToObject, isObjectEmpty } from '@aidsupply/components'

import merge from 'deepmerge'
import { buildRequest, fetchData, showError } from '../../api'
import { DATA_TYPE_TO_SINGULAR_FORM, DATA_TYPES_WITH_ITEMS } from '../../apiConstants'
import { FIELDS_TO_EXCLUDE_WHEN_COPYING } from '../../config/forms'
import { STOCKS_ITEM_DRAFTED_ERROR } from '../../constants'
import { DEFAULT_VALUES_DATA } from '../../data/defaultValues'
import { cleanBasket, setUserRole, sidebarItemSet, updateTableAndSystemData } from '../actions'
import {
  DATA_SET_SYSTEM_DATA,
  FILE_PARSE,
  GET_CURRENT_USER_SUCCESS,
  INSTANCE_CREATE,
  INSTANCE_CREATE_ERROR,
  POPUP_ALERT_SHOW,
  SIDEBAR_FETCH_BY_ID,
  SIDEBAR_FETCH_BY_ID_ERROR,
  SIDEBAR_FETCH_BY_ID_SUCCESS,
  SIDEBAR_ITEM_COPY,
  SIDEBAR_ITEM_PHOTOS_SET,
  SIDEBAR_ITEM_SET,
  SIDEBAR_POST_COMMENT,
  SIDEBAR_POST_COMMENT_ERROR,
  SIDEBAR_POST_COMMENT_SUCCESS,
  SIDEBAR_UPSERT,
  SIDEBAR_UPSERT_ERROR,
  SIDEBAR_UPSERT_SUCCESS,
  SIDEBAR_UPSERT_TOPICS,
  SIDEBAR_UPSERT_USER,
  SIDEBAR_UPSERT_USER_ERROR,
  UPSERT,
  UPSERT_ERROR,
  UPSERT_SUCCESS,
} from '../constants'
import {
  selectApiUrlParam,
  selectCurrentApiUrlParams,
  selectCurrentUserId,
  selectDataType,
  selectSidebarInitialData,
  selectSystemCollections,
  selectUserDetails,
  selectUserOrganizationId,
} from '../selectors'
import { fetchFacets } from './dataFromCommonPackage'
import { getUploadedFilesData } from './fileUpload'

const mapStateToProps = (state) => state

/**
 * Reads data of a certain type by given ID
 * @param {id, type}
 */
function* doFetchById({ payload: { id, type, reload } }) {
  let apiUrlParam
  try {
    if (id) {
      if (id === 'new') {
        yield put({ type: SIDEBAR_ITEM_SET, item: DEFAULT_VALUES_DATA[type] })
      } else {
        if (type === 'stock-items') {
          const userOrganizationId = yield select(selectUserOrganizationId)
          const selectedOrganizationParam = yield select(selectApiUrlParam)

          apiUrlParam = selectedOrganizationParam || userOrganizationId
        }

        const request = yield buildRequest({
          operation: 'read',
          type,
          id,
          apiUrlParam,
        })

        const item = yield fetchData(request)
        yield showError(item)

        if (item?.item || item?.items?.length || item) {
          yield put({
            type: SIDEBAR_FETCH_BY_ID_SUCCESS,
            item: item.item || item.items?.[0] || item,
          })

          return item.item || item.items?.[0] || item
        }
      }
    }
  } catch (error) {
    yield put({ type: SIDEBAR_FETCH_BY_ID_ERROR, error })
  }
}

function* doCopySidebarItem({ payload: { id, type } }) {
  let sidebarItemData = yield select(selectSidebarInitialData)

  try {
    if (!sidebarItemData) {
      sidebarItemData = yield doFetchById({ payload: { id, type }, isCopy: true })
    }

    const itemToCopy = { ...sidebarItemData }
    const itemTransformed = Object.keys(itemToCopy).reduce((acc, curr) => {
      if (!FIELDS_TO_EXCLUDE_WHEN_COPYING.includes(curr)) {
        return { ...acc, [curr]: itemToCopy[curr] }
      }
      return acc
    }, {})

    yield put(sidebarItemSet(itemTransformed))
  } catch (error) {
    yield put({ type: SIDEBAR_FETCH_BY_ID_ERROR, error })
  }
}

export function* doUpsert({ payload }) {
  const { apiUrlParam, id, requestBody, type, platform, operation, withoutPopups, withErrorPopups } = payload

  try {
    const request = yield buildRequest({
      operation: operation || (id ? 'update' : 'create'),
      type: type === 'users' && !id ? 'userInvite' : type,
      id,
      requestBody,
      platform,
      apiUrlParam,
    })

    const res = yield fetchData(request)

    const withoutErrorPopups = !withErrorPopups && withoutPopups && res.detail !== STOCKS_ITEM_DRAFTED_ERROR

    yield showError(res, withoutErrorPopups)

    yield put({ type: UPSERT_SUCCESS })
    if (!withoutPopups) {
      yield put({
        type: POPUP_ALERT_SHOW,
        data: { contentKey: 'dataSuccessfullySaved', type: 'success' },
      })
    }
    return res
  } catch (error) {
    console.log(error)
    yield put({ type: UPSERT_ERROR, error })
    // if (!withoutPopups) {
    //   yield put({
    //     type: POPUP_ALERT_SHOW,
    //     data: { contentKey: 'errorInSavingData', type: 'error' },
    //   })
    // }
  }
}

export function* doFileParse({ payload }) {
  const { type, file, id, is_replace_existing } = payload

  try {
    console.log(file)
    const fd = new FormData()
    fd.append('file', file)
    fd.append('order_id', id)
    fd.append('is_replace_existing', is_replace_existing)

    console.log(fd)
    const request = yield buildRequest({
      operation: 'create',
      type,
      requestBody: fd,
    })

    const res = yield fetchData(request)

    yield showError(res)

    // yield put({ type: UPSERT_SUCCESS })
    // if (!withoutPopups) {
    //   yield put({
    //     type: POPUP_ALERT_SHOW,
    //     data: { contentKey: 'dataSuccessfullySaved', type: 'success' },
    //   })
    // }
    return res
  } catch (error) {
    console.log(error)
    // yield put({ type: UPSERT_ERROR, error })
    // if (!withoutPopups) {
    //   yield put({
    //     type: POPUP_ALERT_SHOW,
    //     data: { contentKey: 'errorInSavingData', type: 'error' },
    //   })
    // }
  }
}

function* upsertCollectionWithItems({ type, id, requestBody }) {
  try {
    let collectionResult
    let collectionWithItemsResult

    const itemsKey = type === 'enumerations' ? 'options' : type === 'shipments' ? 'shipment_items' : 'items'
    const allDataKeys = Object.keys(requestBody)
    const itemsData = requestBody[itemsKey]

    const mainData =
      !(allDataKeys?.length === 1 && itemsData) &&
      Object.keys(requestBody).reduce((mainData, allDataKey) => {
        if (allDataKey !== itemsKey) {
          return { ...mainData, [allDataKey]: requestBody[allDataKey] }
        }
        return mainData
      }, {})

    if (mainData) {
      collectionResult = yield doUpsert({
        payload: {
          id,
          type,
          requestBody: mainData,
        },
      })
    }

    if (type !== 'invoices' && itemsData) {
      collectionWithItemsResult = yield doUpsert({
        payload: {
          apiUrlParam: id || collectionResult.values?.[0]?.id,
          type: `${type}Items`,
          requestBody: itemsData,
          withoutPopups: !!mainData,
        },
      })
    }

    return collectionWithItemsResult || collectionResult
  } catch (error) {
    console.log(error)
  }
}

function* doUpsertFiles({ id, type, requestBody }) {
  // TODO: errors
  const { fileUpload } = yield select(mapStateToProps)
  let filesFormDataObject

  const filesType = fileUpload.filesType || (requestBody.photos ? 'photos' : 'files')

  if (!isObjectEmpty(fileUpload.files)) {
    filesFormDataObject = yield getUploadedFilesData({ type, requestBody, id, filesType })
  }

  yield put({
    type: SIDEBAR_ITEM_PHOTOS_SET,
    [filesType]: filesFormDataObject?.[filesType] || requestBody[filesType],
  })

  if (filesFormDataObject || requestBody.filesNewStates) {
    if (requestBody.filesNewStates) {
      yield all(
        Object.entries(requestBody.filesNewStates).map(([fileId, fileState]) => {
          return call(doUpsert, {
            payload: {
              id: +fileId.replace('id-', ''),
              type: 'filesUpload',
              requestBody: { state: fileState },
              withoutPopups: true,
              withErrorPopups: true,
            },
          })
        })
      )
    }
  }

  delete requestBody.photos
  delete requestBody.files
  delete requestBody.filesNewStates

  return filesFormDataObject?.primaryPhotoObj
}

/**
 * Writes the whole object to the API with postprocessing and reducer update
 * @param { object } { data, type }
 */
export function* doUpsertSidebar({ payload: args, navigate, skipNavigate = false, skipFileUpload = false }) {
  const { id, type, parentType, requestBody: requestBodyArg } = args
  let requestBody
  try {
    if (skipFileUpload) {
      requestBody = { ...requestBodyArg }
    } else {
      const primaryPhotoObj = yield doUpsertFiles({ id, type, requestBody: requestBodyArg })
      requestBody = { ...requestBodyArg, ...(primaryPhotoObj || {}) }
    }

    let result

    if (type === 'stock-items') {
      yield upsertStockItems({ data: args, navigate })
      return
    } else if (isObjectEmpty(requestBody)) {
      yield put({ type: SIDEBAR_UPSERT_SUCCESS })
      return
    } else if (DATA_TYPES_WITH_ITEMS.includes(type)) {
      result = yield upsertCollectionWithItems({ type, id, requestBody })
    } else {
      result = yield doUpsert({
        payload: { ...args, requestBody },
      })
    }

    yield showError(result)

    const itemToSet = result.value || result.values?.[0] || result

    yield put({ type: SIDEBAR_UPSERT_SUCCESS, item: itemToSet })

    yield put(
      updateTableAndSystemData({
        type,
        status: id ? 'update' : 'create',
        itemToSet,
      })
    )

    const queryParams = yield select(selectCurrentApiUrlParams)
    yield fetchFacets({
      type,
      queryParams,
      isRefetch: true,
    })

    if (!id && !skipNavigate) {
      navigate(`/${parentType ? `${parentType}/` : ''}${type}/${itemToSet.id}`)
    }

    return itemToSet
  } catch (error) {
    console.log(error)
    yield put({ type: SIDEBAR_UPSERT_ERROR, error })
  }
}

function* doUpsertUser({ payload, navigate }) {
  try {
    const { id, type, parentType, requestBody } = payload

    const currentUser = yield select(selectUserDetails)
    const result = yield call(doUpsertSidebar, { payload, navigate, skipNavigate: true })

    if (requestBody.role) {
      yield put(setUserRole(requestBody.role, result.id))
    }

    if (id === currentUser.id) {
      yield put({
        type: GET_CURRENT_USER_SUCCESS,
        payload: {
          ...currentUser,
          ...result,
          profile_pic: result.profile_pic || currentUser.profile_pic,
        },
      })
    }
    if (navigate) navigate(`/${parentType ? `${parentType}/` : ''}${type}/${result.id}`)
  } catch (error) {
    console.log(error)
    yield put({ type: SIDEBAR_UPSERT_USER_ERROR, error })
  }
}

function* doUpsertTopics({ topicsObject }) {
  try {
    const result = yield all(
      Object.values(topicsObject).map((topic) => {
        const id = typeof topic.id === 'string' && topic.id.startsWith('newId') ? '' : topic.id

        return call(doUpsert, {
          payload: { id, type: 'topics', requestBody: topic, withoutPopups: true, withErrorPopups: true },
        })
      })
    )

    const resultWithNoErrors = result.filter((resItem) => !!resItem)
    if (resultWithNoErrors.length) {
      const initialData = yield select(selectSystemCollections(['topics']))
      yield put({
        type: DATA_SET_SYSTEM_DATA,
        key: 'topics',
        data: merge(initialData.topics, convertArrayToObject(resultWithNoErrors)),
      })
    }

    if (resultWithNoErrors.length === result.length) {
      yield put({
        type: POPUP_ALERT_SHOW,
        data: { contentKey: 'dataSuccessfullySaved', type: 'success' },
      })
    }
  } catch (error) {
    console.log(error)
  }
}

function* doPostComment({ payload }) {
  const { entity_id, content } = payload
  const entity_type = yield select(selectDataType)
  const user_id = yield select(selectCurrentUserId)

  try {
    const request = yield buildRequest({
      type: 'comments',
      apiMethod: 'POST',
      requestBody: {
        content: content,
        entity_type: DATA_TYPE_TO_SINGULAR_FORM[entity_type],
        entity_id,
        user_id,
      },
    })

    const res = yield fetchData(request)

    yield showError(res)

    yield put({ type: SIDEBAR_POST_COMMENT_SUCCESS, comment: res })
    return res
  } catch (error) {
    console.log(error)
    yield put({ type: SIDEBAR_POST_COMMENT_ERROR, error })
  }
}

function* upsertStockItems({ data: args, navigate }) {
  const { data } = yield select(mapStateToProps)
  const { id, type, parentType, requestBody } = args
  const returnMultipleItems = !id // generate stock items (insert new stock items)

  try {
    const apiUrlParam = requestBody.warehouse_id || requestBody[0]?.warehouse_id

    const result = yield doUpsert({
      payload: {
        ...args,
        apiUrlParam,
        operation: id ? 'update' : 'create',
        id: '',
      },
    })

    const itemToSet = id && result.values?.[0]

    if (returnMultipleItems) {
      const newItems = [...result.values, ...data['stock-items']]
      yield put(updateTableAndSystemData({ type, updatedData: newItems })) // TODO: check
    } else {
      yield put(updateTableAndSystemData({ type, status: 'update', itemToSet }))
    }

    yield put({
      type: SIDEBAR_UPSERT_SUCCESS,
      item: returnMultipleItems ? {} : itemToSet,
    })

    if (!id) {
      navigate(
        `/${parentType ? `${parentType}/` : ''}${type}${returnMultipleItems ? '' : `/${itemToSet.id}`}`
      )
    }
  } catch (error) {
    console.log(error)
    yield put({ type: SIDEBAR_UPSERT_ERROR, error })
  }
}

function* doCreateInstance({ data: args }) {
  const { callback, type } = args

  try {
    let result
    if (DATA_TYPES_WITH_ITEMS.includes(type)) {
      result = yield upsertCollectionWithItems(args)
    } else {
      result = yield doUpsert({ payload: args })
    }

    if (!result || result.detail) {
      throw new Error(result.detail)
    }

    const itemToSet = result.items?.[0] || result.value || result.values?.[0]

    if (itemToSet?._id) {
      itemToSet.id = itemToSet._id
    }

    if (type === 'orders' || type === 'inquiries') {
      yield put(cleanBasket())
    }

    if (type === 'organizations') {
      yield put(
        updateTableAndSystemData({
          itemToSet,
          type,
          status: 'create',
          skipTableDataUpdate: true,
        })
      )
    }

    if (callback) {
      callback(itemToSet)
    }
  } catch (error) {
    console.log(error)
    yield put({ type: INSTANCE_CREATE_ERROR, error })
  }
}

export default function* sidebarSaga() {
  return [
    yield takeLatest(SIDEBAR_FETCH_BY_ID, doFetchById),
    yield takeLatest(SIDEBAR_UPSERT, doUpsertSidebar),
    yield takeLatest(SIDEBAR_UPSERT_USER, doUpsertUser),
    yield takeLatest(SIDEBAR_UPSERT_TOPICS, doUpsertTopics),
    yield takeLatest(SIDEBAR_ITEM_COPY, doCopySidebarItem),
    yield takeLatest(INSTANCE_CREATE, doCreateInstance),
    yield takeLatest(UPSERT, doUpsert),
    yield takeLatest(FILE_PARSE, doFileParse),
    yield takeLatest(SIDEBAR_POST_COMMENT, doPostComment),
  ]
}
