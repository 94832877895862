import styled from 'styled-components'

export const StyledDashboardWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 30px;
  margin-bottom: 30px;
  flex-grow: 1;
  max-height: 100vh;
  overflow-y: auto;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    align-items: stretch;
  }
`

export const StyledDashboardCards = styled.div`
  display: grid;
  grid-template-columns: [start] 1fr 1fr 1fr [end];
  height: auto;
  margin-right: 30px;
  flex-grow: 1;

  .gridItem {
    justify-content: center;
    display: flex;
  }

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    padding-left: 15px;
  }

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
    justify-content: flex-start;
  }

  @media only screen and (max-width: 1285px) {
    grid-template-columns: [start] 1fr 1fr [end];
  }

  @media only screen and (max-width: 890px) {
    grid-template-columns: [start] 1fr [end];
    margin-right: 0;
    order: 2;
  }
`

export const StyledCard = styled.div`
  border: 1px solid ${({ theme }) => theme.color.general.gray2};
  margin: 0 15px 30px;
  padding: 25px;
  border-radius: ${({ theme }) => theme.size.border.radius.bigger};
  text-decoration: none;
  flex-direction: column;
  display: flex;
  min-width: 220px;
  max-width: 350px;
  justify-content: center;
  flex: 1 0 auto;

  .icon {
    overflow: hidden;
  }

  &.inProgress {
    .number.typography {
      color: transparent;
    }
  }

  .column {
    //margin-right: 25px;
  }
  .number {
    margin-top: 22px;
    text-align: center;
  }

  .flexRow {
    display: flex;
    align-items: center;

    .icon {
      margin-right: 10px;
    }
  }
  &:hover {
    border-color: ${({ theme }) => theme.color.primary.main};
    background-color: ${({ theme }) => theme.color.general.light};
  }

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    // margin: 20px;
  }

  @media only screen and (min-width: ${({ theme }) => theme.breakpoints.sm}px) {
    flex-direction: row;
  }
`
