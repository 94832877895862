import React, { useEffect } from 'react'
import { ThemeProvider } from 'styled-components'
import { useTranslation } from 'react-i18next'

import { buildImagePath } from '@aidsupply/components'

import theme from '../theme'
import { AIDSUPPLY_LOGO_LINK } from '../constants'
import { useMappedState } from '../hooks'
import { selectUserRole } from '../redux/selectors'
import { pick } from 'dot-object'
import { USER_ROLES } from '../config'

const ThemeProviderWrapper = ({ children }) => {
  const { i18n, t } = useTranslation('signIn')

  const role = useMappedState(selectUserRole)

  const platforms = []

  const platform = 'aidsupply'

  const platformConfig = platforms && Object.values(platforms)[0] // || CONFIG[platform]
  const platformLogo = platformConfig?.photos?.miniLogos?.active?.[0] // || CONFIG[platform]?.logo

  let logoSrc = AIDSUPPLY_LOGO_LINK
  if ((platforms && Object.values(platforms).length === 1) || platformLogo) {
    logoSrc = buildImagePath('platforms', platformConfig?.id, 'miniLogos', platformLogo)
  }

  theme.title = platformConfig?.seo?.title?.[i18n?.language] || 'Aidsupply' + ' ' + t('inUkraine') //|| CONFIG[platform].pageHead.crmTitle
  theme.logoSrc = logoSrc
  theme.platform = platform

  useEffect(() => {
    theme.title = platformConfig?.seo?.title?.[i18n?.language] || 'Aidsupply' + ' ' + t('inUkraine')
  }, [i18n.language])

  useEffect(() => {
    theme.bordersColor = pick(USER_ROLES[role]?.navMenuLineColor || 'general.gray1', theme.color)
  }, [role])

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}

export default ThemeProviderWrapper
