import React from 'react'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { withTheme } from 'styled-components'
import { useDispatch } from 'react-redux'

import { Typography, UiLink, Button, Icon } from '@aidsupply/components'

import { userRequestResetPassword, userSignIn } from '../../redux/actions'
import IntroScreenWrapper from '../../components/IntroScreens/IntroScreenWrapper'
import Form from '../../components/Form'
import { FORM_CONFIGS } from '../../config/forms'
import { StyledAdditionToInputLink } from '../../components/Form/styled'
import { selectUserInProgressAndError } from '../../redux/selectors'
import { useMappedState } from '../../hooks'
import { useIsPasswordShown } from '../../hooks/useIsPasswordShown'
import {INTRO_PAGES_IMG} from "../../constants";

const initialValues = { email: '', password: '' }

const Login = ({ theme }) => {
  const { t } = useTranslation('signIn')
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [email, setEmail] = React.useState('')
  const [password, setPassword] = React.useState('')

  const { inProgress, error } = useMappedState(selectUserInProgressAndError)

  const { type, iconRightProps } = useIsPasswordShown()

  const onFormSubmit = (credentials) => {
    dispatch(userSignIn({ email, password }, navigate))
  }

  const onRequestPasswordClick = () => {
    dispatch(userRequestResetPassword(email))
  }
  const getForgotPassLink = () => (
    <StyledAdditionToInputLink Link={RouterLink} to="/password-recovery">
      {t('forgotPassword')}
    </StyledAdditionToInputLink>
  )

  const getText = () => (
    <div className="inscriptionWrapper">
      <Typography type="body1">{t('dontHaveAccount')}</Typography>
      <Typography type="body1">
        <UiLink Link={RouterLink} to="/signup" className="auth" themeColor="general.dark">
          {t('signUp')}
        </UiLink>
      </Typography>
    </div>
  )

  return (
    <IntroScreenWrapper type="signIn" theme={theme} imageUrl={INTRO_PAGES_IMG} text={t('signIn')} >
      {/* <SocialButtons
        isBeforeForm
        withSeparator
      //  socialDisabled={socialDisabled}
      /> */}
      <Form
        type="login"
        initialValues={initialValues}
        formValues={{ email, password }}
        // serverError={error !== false}
        onSubmit={onFormSubmit}
        validationRules={{
          email: ['required', 'email'],
          password: ['required'],
        }}
        fields={[
          { ...FORM_CONFIGS.login.fields[0], onChange: (e) => setEmail(e.target.value) },
          {
            additionToInput: getForgotPassLink(),
            ...FORM_CONFIGS.login.fields[1],
            onChange: (e) => setPassword(e.target.value),
            type,
            iconRightProps,
          },
        ]}
      >
        {/*<Switch label={t('rememberMe')} margin="3px 0 25px 0" />*/}
        <Button
          fullWidth
          variant="primary"
          type="submit"
          disabled={inProgress}
          uppercase={false}
          className="loginSubmit"
        >
          <Icon
            name="statusCompleted"
            size={20}
            stroke={theme.color.general.light}
            fill={theme.color.primary.main}
          />
          &nbsp;{t('signIn')}
        </Button>
      </Form>

      {getText()}
    </IntroScreenWrapper>
  )
}

export default withTheme(Login)
