import merge from 'deepmerge'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { FORM_CONFIGS } from '../../config/forms'
import { DEFAULT_VALUES_DATA } from '../../data/defaultValues'
import { useMappedState } from '../../hooks'
import { sidebarPostComment } from '../../redux/actions'
import {
  selectAllSystemCollections,
  selectDataTypeObject,
  selectFileUploadState,
  selectSidebarInitialData,
  selectSidebarState,
  selectUserDetails,
} from '../../redux/selectors'
import Form from '../Form'
import FormBlockWrapperDefault from './FormBlockWrapper'

const overwriteMerge = (destinationArray, sourceArray) => sourceArray

const EditForm = ({
  initialValues,
  initialValuesChanged,
  initialValuesFromApi,
  isReadOnly,
  formConfig,
  FormBlockWrapper,
  onFormSubmit,
  customType,
  expansionPanelProps,
}) => {
  const dispatch = useDispatch()
  const { rightPanelId: editedItemId } = useParams()

  const [comment, setComment] = useState(null)
  const [formSubmitted, setFormSubmitted] = useState(false)

  const fileUpload = useMappedState(selectFileUploadState)
  const sidebar = useMappedState(selectSidebarState)
  const system = useMappedState(selectAllSystemCollections)
  const dataTypeObject = useMappedState(selectDataTypeObject)
  const type = customType || dataTypeObject?.key
  const itemInitial = useMappedState(selectSidebarInitialData)
  const user = useMappedState(selectUserDetails)

  const formData = FORM_CONFIGS[type]

  const handleFormSubmit = (formValuesChanged) => {
    if (onFormSubmit) {
      onFormSubmit(formValuesChanged)
    }

    if (formValuesChanged.comment) {
      setComment(formValuesChanged.comment)
    }
    setFormSubmitted(true)
  }

  useEffect(() => {
    if (formSubmitted && comment && itemInitial.id) {
      const commentData = {
        entity_id: itemInitial.id,
        content: comment,
      }
      dispatch(sidebarPostComment(commentData))
      setComment(null)
      setFormSubmitted(false)
    }
  }, [formSubmitted, comment, dispatch])

  if (!itemInitial && !initialValues && !initialValuesFromApi) {
    return null
  }

  const options = {
    ...system,
    user,
  }

  const initialValuesDefault =
    initialValues || merge(DEFAULT_VALUES_DATA[type], initialValuesFromApi || itemInitial)

  // #roles
  const initialValuesChangedDefault = user.role === 'recipient' &&
    type === 'inquiries' &&
    !initialValuesFromApi.client?.id && { client: { id: user.organization } }

  const formInitialValues = editedItemId === 'copy' ? {} : itemInitial
  const formInitialValuesChanged = editedItemId === 'copy' ? itemInitial : {}

  return (
    <Form
      isDraft={editedItemId === 'copy'}
      isReadOnly={isReadOnly}
      formConfig={formConfig || formData}
      inProgress={sidebar.sidebarUpsertInProgress || fileUpload.inProgress}
      serverError={sidebar.error}
      optionsData={options}
      validationRules={formConfig?.validationRules || formData?.validationRules}
      id={editedItemId}
      initialValues={formInitialValues || initialValues || initialValuesDefault}
      initialValuesChanged={formInitialValuesChanged || initialValuesChanged || initialValuesChangedDefault}
      type={type}
      labelType="top"
      labelKey="label"
      FormWrapper={FormBlockWrapper || FormBlockWrapperDefault}
      withActions
      buttonsAreSticky
      onSubmit={handleFormSubmit}
      // getBeforeFormChildren={}
      expansionPanelProps={expansionPanelProps}
    />
  )
}

export default EditForm
