import React from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { StyledAdditionToInputLink } from '../../Form/styled'
import { INSTANCE_CREATE } from '../../../redux/constants'

const GenerateNewOrganization = ({ formValues, updateSelect }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('forms')

  return (
    <StyledAdditionToInputLink
      disabled={!!formValues.organization?.id || !formValues.first_name || !formValues.last_name}
      onClick={() => {
        dispatch({
          type: INSTANCE_CREATE,
          data: {
            type: 'organizations',
            requestBody: {
              state: 'posted',
              general: {
                first_name: formValues.firstName || '',
                last_name: formValues.lastName || '',
                name: `${formValues.first_name || ''} ${formValues.last_name || ''}`,
                email: formValues.email || '',
                type: { id: 'private' },
              },
            },
            callback: (newItem) => {
              return updateSelect('organization')(newItem)
            },
          },
        })
      }}
      text={t('generateNewOrganization')}
    />
  )
}

export default GenerateNewOrganization
